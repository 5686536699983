import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const PageTemplate = ({ children }) => [<section key="content">{children}</section>];

PageTemplate.propTypes = propTypes;
export default PageTemplate;
