import PropTypes from 'prop-types';
import { compose, getContext, mapProps, pure, withHandlers } from 'recompose';
import * as R from 'ramda';

import FormGroupAdapter from 'components/shared/form/FormGroupAdapter';

import { getIn, setIn } from 'utils';

const enhance = compose(
  getContext({
    components: PropTypes.arrayOf(PropTypes.object),
    setAddressComponents: PropTypes.func,
    unsetAddressComponents: PropTypes.func,
  }),
  withHandlers({
    stateNameChange: ({ components, options, setAddressComponents, unsetAddressComponents }) => (value) => {
      const componentsWithStateNameOnly = R.filter(
        R.pipe(
          getIn('name'),
          R.test(/stateName$/),
        ),
        components,
      );
      if (R.isNil(value)) {
        unsetAddressComponents(componentsWithStateNameOnly);
        return;
      }

      const stateName = R.pipe(
        R.find(
          R.pipe(
            getIn('value'),
            R.equals(value),
          ),
        ),
        R.when(
          R.pipe(
            R.isNil,
            R.not,
          ),
          getIn('label'),
        ),
      )(options);

      if (R.isNil(stateName)) {
        unsetAddressComponents(componentsWithStateNameOnly);
        return;
      }

      const stateNameComponentsWithValue = R.map(setIn(stateName, 'value'), componentsWithStateNameOnly);
      setAddressComponents(stateNameComponentsWithValue);
    },
  }),
  withHandlers({
    enhancedOnChange: ({ input, stateNameChange }) =>
      R.pipe(
        R.tap(stateNameChange),
        input.onChange,
      ),
  }),
  mapProps(({ enhancedOnChange, stateNameChange, ...props }) => setIn(enhancedOnChange, 'input.onChange', props)),
  pure,
);

export default enhance(FormGroupAdapter);
