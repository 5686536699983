import * as actionTypes from '../../constants/actionTypes';
import * as requestTypes from '../../constants/requestTypes';
import setRequestInProcess from '../../actions/request';

import { successNotification, errorNotification } from '../../utils';

import api from '../../utils/api';

export function fetchProcessorNotes(token) {
  return (dispatch) => {
    const requestType = requestTypes.PROCESSOR_NOTES;
    const url = `/applications/${token}/notes`;

    dispatch(setRequestInProcess(true, requestType));

    return api
      .get(url)
      .then(({ data }) => {
        dispatch(setRequestInProcess(false, requestType));
        dispatch(fetchProcessorNotesSuccess(data.collection));
      })
      .catch(() => {
        dispatch(setRequestInProcess(false, requestType));
      });
  };
}

export function updateProcessorNote(token, id, body) {
  return (dispatch) => {
    const url = `/applications/${token}/notes/answer`;

    return api
      .post(url, { id, body })
      .then((response) => {
        const { collection } = response.data;
        successNotification('Answer created');
        dispatch(fetchProcessorNotesSuccess(collection));
      })
      .catch(() => {
        errorNotification('Something went wrong');
      });
  };
}

export function fetchProcessorNotesSuccess(payload) {
  return {
    type: actionTypes.PROCESSOR_NOTES_FETCH_SUCCESS,
    payload,
  };
}
