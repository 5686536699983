import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose, getContext, pure } from 'recompose';
import { useParams } from 'react-router-dom';

import { portfolioImportTemplateUrl } from 'config';
import { getIsUserDealTeamOwner } from 'selectors';
import useImportProperty from 'hooks/useImportProperty';
import useExportProperty from 'hooks/useExportProperty';
import * as fp from 'utils/ramda';

import { Button, Hr, Alert } from 'components/shared';
import { FormFlex, FormBox } from 'components/shared/form';
import Import from 'components/shared/form/PropertyImport';
import UpdateImport from 'components/shared/form/PropertyUpdateImport';
import SubmitErrors from 'components/shared/form/SubmitErrors';

import { APPLICATION_STEP_PATH } from 'constants/routes';
import { getPath } from 'utils';

import Fields from './Fields';
import Loader from './Loader';

const propTypes = {
  fetchInitialValues: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isEditAddress: PropTypes.bool.isRequired,
  isFormDisabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  form: PropTypes.shape({
    mutators: PropTypes.objectOf(PropTypes.func),
  }).isRequired,
  isUserDealTeamOwner: PropTypes.bool,
};

const defaultProps = {
  isUserDealTeamOwner: false,
};

function Form({
  fetchInitialValues,
  handleSubmit,
  isEditAddress,
  isFormDisabled,
  loading,
  submitting,
  form,
  isUserDealTeamOwner,
}) {
  const { token } = useParams();
  const {
    isImporting,
    toImport,
    errors,
    warnings,
  } = useImportProperty('application.properties', fetchInitialValues);

  const onLoadFiles = (type) => (files) => toImport({
    import: {
      asset: files[0],
      type,
    },
  });

  const {
    isExporting,
    toExport,
  } = useExportProperty();

  useEffect(() => {
    if (loading) form.mutators.resetBuffer();
  }, [loading]);

  return (
    <StyledForm>
      {!loading && (
        <FormFlex justifyContent="space-between">
          <FormBox mb="1.25rem">
            {isUserDealTeamOwner && (
              <UpdateImport
                disabled={loading || isImporting}
                isEditAddress={isEditAddress}
                isExporting={isExporting}
                isImporting={isImporting}
                toExport={toExport}
                onLoadFiles={onLoadFiles('update_rental_properties')}
              />
            )}
          </FormBox>
          <FormBox mb="1.25rem">
            <Import
              disabled={loading || isImporting}
              isEditAddress={isEditAddress}
              isImporting={isImporting}
              onLoadFiles={onLoadFiles('rental_properties')}
              importTemplateUrl={portfolioImportTemplateUrl}
            />
          </FormBox>
        </FormFlex>
      )}

      {fp.isDefined(errors) && (
        <FormFlex>
          <FormBox width={1} mb="1.25rem">
            <Alert palette="danger" colorPalette="secondary" size="sm">
              <SubmitErrors errors={errors} />
            </Alert>
          </FormBox>
        </FormFlex>
      )}

      {fp.isDefined(warnings) && (
        <FormFlex>
          <FormBox width={1} mb="1.25rem">
            <Alert palette="warning" colorPalette="warning" colorTone={0} size="sm">
              <SubmitErrors errors={warnings} />
            </Alert>
          </FormBox>
        </FormFlex>
      )}

      {loading && <Loader />}
      {!loading && <Fields fetchInitialValues={fetchInitialValues} />}

      <Actions>
        <FormFlex>
          <FormBox width={1}>
            <Hr />
          </FormBox>
        </FormFlex>
        <FormFlex alignItems="center" justifyContent="flex-start">
          <FormBox pl={3} pr={3}>
            <Button
              to={getPath(APPLICATION_STEP_PATH, { token, step: 1 })}
              transparent
              disabled={isFormDisabled || submitting}
            >
              Back
            </Button>
          </FormBox>
          <FormBox p={[3, 3, 0]} alignSelf={['flex-end', 'flex-end', 'inherit']}>
            <Button
              onClick={handleSubmit}
              dataName="buttonNext"
              rounded
              disabled={loading || isFormDisabled || submitting}
            >
              {submitting ? 'Please wait…' : 'Next'}
            </Button>
          </FormBox>
        </FormFlex>
      </Actions>
    </StyledForm>
  );
}

const StyledForm = styled.form`
  position: relative;
`;

const Actions = styled.div`
  position: sticky;
  bottom: 0;
  margin-top: 1.25rem;
  padding: 0 0 1rem;
  background-color: #FFF;

  hr {
    margin-top: 0;
  }
`;

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  const isUserDealTeamOwner = getIsUserDealTeamOwner(state);

  return {
    isUserDealTeamOwner,
  };
};


const enhance = compose(
  connect(mapStateToProps),
  getContext({ isFormDisabled: PropTypes.bool, isEditAddress: PropTypes.bool }),
  pure,
);

export default enhance(Form);
