import PropTypes from 'prop-types';
import { compose, getContext, withProps, withHandlers, withState, renameProp } from 'recompose';
import { __, all, values, pipe, pick, filter, test, includes, any, equals, prop } from 'ramda';

const nodeProps = compose(
  getContext({
    checkedKeys: PropTypes.arrayOf(PropTypes.string),
    checkKey: PropTypes.func,
    uncheckKey: PropTypes.func,
    keys: PropTypes.arrayOf(PropTypes.string),
    toggleCheck: PropTypes.func,
    statuses: PropTypes.objectOf(PropTypes.string),
  }),
  withProps(({ keys, nodeKey }) => {
    const regExp = new RegExp(`^${nodeKey}-`);
    const subKeys = filter(test(regExp), keys);
    const isAnotherDocs = nodeKey === 'anotherdoc';

    return {
      subKeys,
      count: subKeys.length,
      isAnotherDocs,
    };
  }),
  withProps(({ subKeys, isAnotherDocs }) => {
    const sub = subKeys.length > 0 || isAnotherDocs;

    return {
      sub,
    };
  }),
  withProps(({ sub, subKeys, checkedKeys }) => {
    if (!sub) return {};

    const subCheckedKeys = filter(includes(__, checkedKeys), subKeys);

    return {
      subCheckedKeys,
      checkedCount: subCheckedKeys.length,
    };
  }),
  withProps(({ nodeKey, sub, subKeys, statuses, isAnotherDocs, status: anotherDocStatus }) => {
    if (sub) {
      const subStatuses = pipe(
        pick(subKeys),
        values,
      )(statuses);

      if (isAnotherDocs) {
        return { status: anotherDocStatus || 'pending' };
      }
        
      if (all(equals('success'), subStatuses)) {
        return { status: 'success' };
      }

      if (any(equals('danger'), subStatuses)) {
        return { status: 'danger' };
      }

      if (any(equals('warning'), subStatuses)) {
        return { status: 'warning' };
      }

      return { status: 'pending' };
    }

    const status = prop(nodeKey, statuses);

    return {
      status,
    };
  }),
  renameProp('expanded', 'defaultExpanded'),
  withState('expanded', 'setExpanded', prop('defaultExpanded')),
  withHandlers({
    toggle: ({ setExpanded, expanded }) => () => setExpanded(!expanded),
  }),
);

export default nodeProps;
