import * as R from 'ramda';
import { FORM_ERROR } from 'final-form';

import { authUrl, apiUrl, clientId, clientSecret } from 'config';
import api from 'utils/api';
import {
  decamelizeObjectToFormData,
  getAuthData,
  notInternalRespondErrorNotification,
  removeAuthData,
  removeConfirmEmailBanner,
  setAuthData,
  successNotification,
  throwSubmissionError,
} from 'utils';

import { resetCurrentTime } from 'utils/time';

import { fetchApplications, fetchDrawRequests } from 'actions';

import * as actionTypes from '../constants/actionTypes';
import * as requestTypes from '../constants/requestTypes';
import { INDEX_PATH, SIGN_IN_PATH } from '../constants/routes';

import setRequestInProcess from '../actions/request';

export const fetchUser = () => (dispatch) => {
  const requestType = requestTypes.USER;
  const url = '/me';

  dispatch(setRequestInProcess(true, requestType));

  return api
    .get(url)
    .then(({ data }) => {
      dispatch(setSession({ user: data, authorized: true }));
      dispatch(fetchApplications());
      dispatch(fetchDrawRequests());
      dispatch(setRequestInProcess(false, requestType));
    })
    .catch((response) => {
      dispatch(setRequestInProcess(false, requestType));
      dispatch(logout());
      throwSubmissionError(response);
    });
};

export const updateProfile = (values) => (dispatch) => {
  const url = '/profile';

  const { asset } = values.contact;
  const formData = decamelizeObjectToFormData({ ...values, contact: { ...values.contact, asset: undefined } });
  if (asset && R.is(Array, asset)) {
    formData.append('contact[asset]', asset[0]);
  }

  return api
    .patch(url, formData)
    .then(({ data }) => {
      dispatch(updateProfileSuccess(data.contact));
      successNotification('Profile was successfully updated');
    })
    .catch((response) => throwSubmissionError(response));
};

export function initialAuthRefrash({ accessToken, refreshToken }) {
  return (dispatch) => {
    const url = `${authUrl}/token`;
    const data = { clientId, clientSecret, grantType: 'refresh_token', refreshToken };
    api.setToken(accessToken);

    return api
      .post(url, data)
      .then((res) => {
        setAuthData(res);
        return dispatch(fetchUser());
      })
      .catch(notInternalRespondErrorNotification);
  };
}

export const applicationLogin = (token) => (dispatch) => {
  resetCurrentTime();
  removeAuthData();
  return dispatch(initialAuthRefrash(token));
};

export const updatePassword = (values) => (dispatch) => {
  const url = '/profile/password';

  return api
    .patch(url, values)
    .then(() => {
      dispatch(logout());
      // window.location = SIGN_IN_PATH;
    })
    .catch((response) => throwSubmissionError(response));
};


export const login = (values) => (dispatch) => {
  const url = `${authUrl}/token`;
  const data = { clientId, clientSecret, grantType: 'password', ...values };

  return api
    .post(url, data)
    .then((res) => {
      resetCurrentTime();
      removeAuthData();
      return dispatch(initialAuthRefrash(res));
    })
    .catch(() => ({ [FORM_ERROR]: 'Login is invalid. Please try again.' }));
};

export const refrashAuthToken = () => () => {
  const url = `${authUrl}/token`;
  const { refreshToken } = getAuthData();
  const data = { clientId, clientSecret, grantType: 'refresh_token', refreshToken };

  return api
    .post(url, data)
    .then((res) => {
      setAuthData(res);
    })
    .catch(notInternalRespondErrorNotification);
};

export const resetSetPass = ({ token, ...values }) => () => {
  const url = `/auth/password/reset/${token}`;

  return api
    .patch(url, { ...values })
    .then(() => {
      window.location.href = SIGN_IN_PATH;
    })
    .catch((response) => throwSubmissionError(response));
};

export const logout = () => async () => {
  const data = { clientId, clientSecret };

  try {
    await api.post(`${authUrl}/revoke`, data);
    await api.delete(`${apiUrl}/me/logout`);
    resetCurrentTime();
    removeAuthData();
    removeConfirmEmailBanner();
    window.location.href = INDEX_PATH;
    return undefined;
  } catch (error) {
    return throwSubmissionError(error);
  }
};

export const sendConfirmEmail = () => () => {
  const url = '/me/send_confirm_email';

  return api.patch(url);
};

export const confirmEmail = (token) => () => {
  const url = `/auth/email/confirm/${token}`;

  return api.patch(url);
};

export function setSession(payload) {
  return {
    type: actionTypes.SESSION_SET,
    payload,
  };
}

export function updateProfileSuccess(payload) {
  return {
    type: actionTypes.PROFILE_UPDATE_SUCCESS,
    payload,
  };
}

export function resetSession() {
  return {
    type: actionTypes.SESSION_RESET,
  };
}
