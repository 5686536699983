import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withHandlers, withState } from 'recompose';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Flex, Box } from 'grid-styled';

import * as actions from 'actions';

import { Button, Strong } from 'components/shared';

import { getRemainingTime } from 'utils';

import * as handlers from './handlers';

const propTypes = {
  rest: PropTypes.number.isRequired,
  refresh: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

function Display({ refresh, rest, logout }) {
  return (
    <Container>
      <Flex>
        <Box mb="1.5rem" width={1}>
          <Text>
            You will be logged out in <Strong>{rest}</Strong> seconds.
          </Text>
          <Text>Do you want to stay signed in?</Text>
        </Box>
      </Flex>
      <Flex alignItems="center" justifyContent="center">
        <Box>
          <StyledButton onClick={refresh}>Yes, Keep me signed in</StyledButton>
        </Box>
        <Box m="1rem">OR</Box>
        <Box>
          <StyledButton bordered palette="grayscale" tone={3} onClick={logout}>
            No, Sign me out
          </StyledButton>
        </Box>
      </Flex>
    </Container>
  );
}

const Container = styled.div`
  padding: 1rem;
`;

const StyledButton = styled(Button)`
  min-width: 15rem;

  @media (max-width: 450px) {
    min-width: auto;
  }
`;

const Text = styled.p`
  text-align: center;
  font-size: 1.5rem;
`;

Display.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
  return {
    logoutAction: bindActionCreators(actions.logout, dispatch),
    refrashAction: bindActionCreators(actions.refrashAuthToken, dispatch),
  };
}

const enhance = compose(
  withState('rest', 'setRest', () => {
    const remainingTime = getRemainingTime();
    if (!remainingTime) return -1;
    if (remainingTime < 0) return -1;
    return Math.floor(remainingTime / 1000);
  }),
  withState('timer', 'setTimer', null),
  withState('interval', 'setIntervalState', null),
  connect(
    null,
    mapDispatchToProps,
  ),
  withHandlers({
    decrementRest: handlers.decrementRest,
    logout: handlers.logout,
    refresh: handlers.refresh,
  }),
  lifecycle({
    UNSAFE_componentWillMount() {
      const { rest, closeModal } = this.props;
      if (rest <= 0) closeModal();
    },
    componentDidMount() {
      const { setTimer, closeModal, setIntervalState, rest, decrementRest } = this.props;
      const timer = setTimeout(closeModal, rest * 1000);
      const interval = setInterval(decrementRest, 1000);
      setTimer(timer);
      setIntervalState(interval);
    },
    componentWillUnmount() {
      const { timer, interval } = this.props;
      if (timer) clearTimeout(timer);
      if (interval) clearTimeout(interval);
    },
  }),
  pure,
);

export default enhance(Display);
