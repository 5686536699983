import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import styled, { css } from 'styled-components';
import { ifProp, palette } from 'styled-tools';

const propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  openMenuOnFocus: PropTypes.bool,
  classNamePrefix: PropTypes.string,
  disabled: PropTypes.bool,
};

const defaultProps = {
  classNamePrefix: 'react-select',
  disabled: false,
  openMenuOnFocus: true,
  options: [],
  value: [],
};

const fontSize = ({ height }) => `${height / 35.5555555556}rem`;

function Select({
  options, openMenuOnFocus, onChange, name, value, classNamePrefix, disabled, ...rest
}) {
  return (
    <StyledSelect
      name={name}
      options={options}
      isDisabled={disabled}
      value={value}
      onChange={onChange}
      openMenuOnFocus={openMenuOnFocus}
      classNamePrefix={classNamePrefix}
      {...rest}
      isMulti
    />
  );
}

// TODO MULTI вынести для переиспользования
const style = css`
  font-size: ${fontSize};
  line-height: 1.42857;
  width: 100%;

  & .react-select__control {
    height: 2.5em;
    font-weight: normal;
    color: ${palette('grayscale', 0)};
    background-color: ${palette('white', 0)};
    border: 1px solid ${ifProp('invalid', palette('danger', 0), palette('grayscale', 3))};
    border-radius: 3px;
    box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  }

  & .react-select__control--is-disabled {
    cursor: not-allowed;
    background-color: #dadbdb;
    opacity: 1;
  }

  & .react-select__control--is-focused {
    border: 1px solid ${ifProp('invalid', palette('danger', 0), palette('primary', 0))};
    outline: 0;
  }

  & .react-select__menu {
    margin: 3px 0 0;
    font-weight: normal;
    font-size: 16px;
  }

  & .react-select__control {
    height: auto;
    min-height: 2.5em;
  }
`;

const StyledSelect = styled(ReactSelect)`
  ${style};
`;

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;
