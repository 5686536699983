import setRequestInProcess from '../actions/request';

import { ENTITIES_FETCH_SUCCESS } from '../constants/actionTypes';
import * as requestTypes from '../constants/requestTypes';
import api from '../utils/api';

export default (contactId) => (dispatch, getState) => {
  const requestType = requestTypes.ENTITIES;
  const url = `/entities/${contactId}`;

  const requestInProcess = getState().request[requestType];

  if (requestInProcess && requestInProcess.inProcess) {
    return;
  }

  dispatch(setRequestInProcess(true, requestType));

  api
    .get(url)
    .then((response) => {
      dispatch(fetchEntitiesSuccess(response.data));
      dispatch(setRequestInProcess(false, requestType));
    })
    .catch((errors) => {
      dispatch(setRequestInProcess(false, requestType, errors));
    });
};

const fetchEntitiesSuccess = (entities) => ({
  type: ENTITIES_FETCH_SUCCESS,
  payload: entities,
});
