import qs from 'query-string';
import { pick, mergeRight, pipe, path, omit } from 'ramda';

import { APPLICATION_BASE_PARAMS, APPLICATION_RENTAL_PARAMS, APPLICATION_BRIDGE_PARAMS } from 'constants/searchParams';
import { camelizeKeys } from 'utils/humps';

export const getSearchParams = pipe(
  path(['location', 'search']),
  qs.parse,
  camelizeKeys,
);

export function resetApplicationRentalParams(params) {
  return omit(APPLICATION_RENTAL_PARAMS, params);
}

export function resetApplicationBridgeParams(params) {
  return omit(APPLICATION_BRIDGE_PARAMS, params);
}

export function resetApplicationParams(params) {
  return pipe(
    resetApplicationRentalParams,
    resetApplicationBridgeParams,
  )(params);
}

export function applicationBaseParamsGetter(params) {
  return pick(APPLICATION_BASE_PARAMS, params);
}

export function applicationRentalParamsGetter(params) {
  const applicationParams = pick(APPLICATION_RENTAL_PARAMS, params);
  const baseParams = applicationBaseParamsGetter(params);

  return mergeRight(baseParams, applicationParams);
}

export function applicationBridgeParamsGetter(params) {
  const applicationParams = pick(APPLICATION_BRIDGE_PARAMS, params);
  const baseParams = applicationBaseParamsGetter(params);

  return mergeRight(baseParams, applicationParams);
}
