import styled from 'styled-components';

import star from './star.png';

const Star = styled.div`
  display: inline-block;
  width: 20px;
  height: 13px;
  background: url(${star}) top center no-repeat;
`;

export default Star;
