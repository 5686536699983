import api from '../utils/api';

export const fetchExtensionRequest = (token) => () => {
  const url = `/applications/${token}/extension`;

  return api
    .get(url)
    .then(({ data }) => data)
    .catch((errors) => console.log('errors', errors));
};

export const createExtensionRequest = ({ token, ...values }) => () => {
  const url = `/applications/${token}/extension`;
  return api.post(url, values);
};
