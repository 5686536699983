import React, { Fragment } from 'react';

import { FormFlex, FormBox, FormGroupLoader, FormLegendLoader } from 'components/shared/form';

function Loader() {
  return (
    <Fragment>
      <FormFlex>
        <FormBox width={1}>
          <FormLegendLoader mb="1rem" width="3.375rem" />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <FormGroupLoader labelWidth="2.625rem" />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <FormGroupLoader labelWidth="10rem" />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <FormGroupLoader labelWidth="2.625rem" />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <FormGroupLoader labelWidth="4rem" />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <FormGroupLoader labelWidth="4.5rem" />
        </FormBox>
      </FormFlex>
    </Fragment>
  );
}

export default Loader;
