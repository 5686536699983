import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { Form as FinalForm } from 'react-final-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import createFocusDecorator from 'final-form-focus';

import * as actions from 'actions';
import ScrollToTopOnMount from 'hocs/ScrollToTopOnMount';

import { PageTemplate } from 'components/templates';
import { Wrapper } from 'components/shared';

import Form from './Form';

const propTypes = {
  resetSetPass: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    tokent: PropTypes.string,
  }).isRequired,
};

const defaultProps = {};

const focusOnError = createFocusDecorator();

function ResetSetPassPage({ initialValues, resetSetPass }) {
  return (
    <PageTemplate>
      <Wrapper style={{ marginBottom: 60 }}>
        <FinalForm
          initialValues={initialValues}
          onSubmit={resetSetPass}
          subscription={{ submitting: true, pristine: true }}
          decorators={[focusOnError]}
          component={Form}
        />
      </Wrapper>
    </PageTemplate>
  );
}

function mapStateToProps(state, { match }) {
  const { token } = match.params;

  const initialValues = {
    token,
  };

  return {
    initialValues,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetSetPass: bindActionCreators(actions.resetSetPass, dispatch),
  };
}

ResetSetPassPage.propTypes = propTypes;
ResetSetPassPage.defaultProps = defaultProps;

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  ScrollToTopOnMount,
  pure,
);

export default enhance(ResetSetPassPage);
