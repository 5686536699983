import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { Button, Icon } from 'components/shared';
import { FormFlex, FormBox, FormGroupAdapter, SelectStateAdapter } from 'components/shared/form';

import { getName, validateRequired } from 'utils';
import styled from 'styled-components';

const propTypes = {
  areComponentsRendered: PropTypes.bool.isRequired,
  setAreComponentsRendered: PropTypes.func.isRequired,
  namespace: PropTypes.string,
  refs: PropTypes.shape({
    statesCollection: PropTypes.arrayOf(PropTypes.object),
  }),
  isRequired: PropTypes.bool,
  isFormDisabled: PropTypes.bool,
};

const defaultProps = {
  namespace: null,
  refs: {
    statesCollection: [],
  },
  isRequired: true,
  isFormDisabled: false,
};

function AddressFieldsStep2({ isFormDisabled, namespace, refs, areComponentsRendered, setAreComponentsRendered, isRequired }) {
  if (!areComponentsRendered) {
    return (
      <Fragment>
        <FormFlex>
          <ShowHideComponentsButtonBox width={[1, 1, 1]}>
            <ShowHideComponentsButton onClick={() => setAreComponentsRendered(true)}>
              Show Components
              <AngleDown />
            </ShowHideComponentsButton>
          </ShowHideComponentsButtonBox>
        </FormFlex>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <FormFlex>
        <ShowHideComponentsButtonBox width={[1, 1, 1]}>
          <ShowHideComponentsButton onClick={() => setAreComponentsRendered(false)}>
            Hide Components
            <AngleUp />
          </ShowHideComponentsButton>
        </ShowHideComponentsButtonBox>
      </FormFlex>
      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={getName(namespace, 'streetNumber')}
            required={isRequired}
            label="Street Number"
            disabled={isFormDisabled}
            validate={isRequired && validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={getName(namespace, 'streetName')}
            required={isRequired}
            label="Street Name"
            disabled={isFormDisabled}
            validate={isRequired && validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={getName(namespace, 'premise')}
            disabled={isFormDisabled}
            label="Unit/Apt #"
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={getName(namespace, 'municipality')}
            required={isRequired}
            label="City"
            disabled={isFormDisabled}
            validate={isRequired && validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={getName(namespace, 'stateCode')}
            namespace={namespace}
            required={isRequired}
            type="select"
            isDisabled={isFormDisabled}
            options={refs.statesCollection}
            label="State"
            validate={isRequired && validateRequired()}
            component={SelectStateAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={getName(namespace, 'postalCode')}
            required={isRequired}
            label="ZIP"
            disabled={isFormDisabled}
            validate={isRequired && validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={getName(namespace, 'county')}
            disabled={isFormDisabled}
            label="County"
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={getName(namespace, 'countryCode')}
            required={isRequired}
            label="Country Code (US)"
            disabled={isFormDisabled}
            validate={isRequired && validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={getName(namespace, 'countryName')}
            required={isRequired}
            label="Country Name"
            disabled={isFormDisabled}
            validate={isRequired && validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>
    </Fragment>
  );
}

const ShowHideComponentsButtonBox = styled(FormBox)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
`;

const ShowHideComponentsButton = styled(Button).attrs({
  transparent: true,
})`
  height: initial;
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 0;
  font-size: 12px;
`;

const AngleUp = styled(Icon).attrs({
  icon: 'angle',
})`
  width: 9px;
  height: 9px;
`;
const AngleDown = styled(Icon).attrs({
  icon: 'angle',
})`
  width: 9px;
  height: 9px;
  transform: rotate(180deg);
`;

AddressFieldsStep2.propTypes = propTypes;
AddressFieldsStep2.defaultProps = defaultProps;

export default AddressFieldsStep2;
