import * as actionTypes from 'constants/actionTypes';

import { initialState } from 'selectors/conditions';

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case actionTypes.CONDITIONS_FETCH_SUCCESS:
      return payload;
    default:
      return state;
  }
};
