import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers } from 'recompose';
import { Form as FinalForm } from 'react-final-form';
import createFocusDecorator from 'final-form-focus';

import { PanelRightPortal } from 'components/shared';

import Form from './Form';

const focusOnError = createFocusDecorator();

const propTypes = {
  editable: PropTypes.bool.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleTogglePortal: PropTypes.func.isRequired,
};

function Modal({ initialValues, editable, onSubmit, handleTogglePortal }) {
  return (
    <PanelRightPortal show={editable} onHide={handleTogglePortal} key="Address">
      <FinalForm
        onSubmit={onSubmit}
        decorators={[focusOnError]}
        initialValues={initialValues}
        subscription={{ submitting: true, pristine: true }}
        component={Form}
      />
    </PanelRightPortal>
  );
}

Modal.propTypes = propTypes;

const enhance = compose(
  withHandlers({
    onSubmit: ({ handleAddressChange, handleTogglePortal }) => (values) => {
      handleAddressChange(values).then(handleTogglePortal);
    },
  }),
  pure,
);

export default enhance(Modal);
