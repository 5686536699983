import React from 'react';
import styled from 'styled-components';
import { palette } from 'styled-tools';
import { getOriginalUser } from 'selectors';
import { useSelector } from 'react-redux';

function OriginalUserBanner() {
  const originalUser = useSelector(getOriginalUser);

  if (originalUser) {
    return (
      <Container>
        You are editing the application as {originalUser}
      </Container>
    );
  };
  return null;
}

const Container = styled.section`
  position: relative;
  background: ${palette('warning')};
  padding: 5px 15px;
  text-align: center;
  color: ${palette('white')};
  opacity: 0.8;
`;

export default OriginalUserBanner;
