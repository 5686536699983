import React from 'react';
import PropTypes from 'prop-types';
import { compose, getContext, pure } from 'recompose';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { bridgeImportTemplateUrl } from 'config';
import { APPLICATION_STEP_PATH } from 'constants/routes';
import useImportProperty from 'hooks/useImportProperty';
import { getPath } from 'utils';
import * as fp from 'utils/ramda';

import { Button, Hr, Alert } from 'components/shared';
import { FormFlex, FormBox } from 'components/shared/form';
import SubmitErrors from 'components/shared/form/SubmitErrors';
import Import from 'components/shared/form/PropertyImport';

import Fields from './Fields';
import Loader from './Loader';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isFormDisabled: PropTypes.bool.isRequired,
  isEditAddress: PropTypes.bool.isRequired,
  fetchInitialValues: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

function Form({ isFormDisabled, handleSubmit, match, loading, submitting, fetchInitialValues, isEditAddress }) {
  const { token } = match.params;

  const {
    toImport,
    isImporting,
    errors,
    warnings,
  } = useImportProperty('application.properties', fetchInitialValues);

  const onLoadFiles = (files) => toImport({
    import: {
      asset: files[0],
      type: 'bridge_properties',
    },
  });

  return (
    <form>
      {!loading && (
        <FormFlex justifyContent="flex-end">
          <FormBox mb="1.25rem">
            <Import
              disabled={loading}
              isEditAddress={isEditAddress}
              isImporting={isImporting}
              onLoadFiles={onLoadFiles}
              importTemplateUrl={bridgeImportTemplateUrl}
            />
          </FormBox>
        </FormFlex>
      )}

      {fp.isDefined(errors) && (
        <FormFlex>
          <FormBox width={1} mb="1.25rem">
            <Alert palette="danger" colorPalette="secondary" size="sm">
              <SubmitErrors errors={errors} />
            </Alert>
          </FormBox>
        </FormFlex>
      )}

      {fp.isDefined(warnings) && (
        <FormFlex>
          <FormBox width={1} mb="1.25rem">
            <Alert palette="warning" colorPalette="warning" colorTone={0} size="sm">
              <SubmitErrors errors={warnings} />
            </Alert>
          </FormBox>
        </FormFlex>
      )}

      {loading && <Loader />}
      {!loading && <Fields />}

      <Actions>
        <FormFlex>
          <FormBox width={1}>
            <Hr />
          </FormBox>
        </FormFlex>

        <FormFlex alignItems="center" justifyContent="flex-start">
          <FormBox pl={3} pr={3}>
            <Button
              to={getPath(APPLICATION_STEP_PATH, { token, step: 1 })}
              transparent
              disabled={isFormDisabled || submitting}
            >
              Back
            </Button>
          </FormBox>
          <FormBox p={[3, 3, 0]} alignSelf={['flex-end', 'flex-end', 'inherit']}>
            <Button
              onClick={handleSubmit}
              dataName="buttonNext"
              rounded
              disabled={loading || isFormDisabled || submitting}
            >
              {submitting ? 'Please wait…' : 'Next'}
            </Button>
          </FormBox>
        </FormFlex>
      </Actions>
    </form>
  );
}

const Actions = styled.div`
  position: sticky;
  bottom: 0;
  margin-top: 1.25rem;
  padding: 0 0 1rem;
  background-color: #FFF;

  hr {
    margin-top: 0;
  }
`;

Form.propTypes = propTypes;

const enhance = compose(
  withRouter,
  getContext({
    isFormDisabled: PropTypes.bool,
    isEditAddress: PropTypes.bool,
    fetchInitialValues: PropTypes.func,
  }),
  pure,
);

export default enhance(Form);
