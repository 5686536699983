import styled from 'styled-components';
import PropTypes from 'prop-types';
import { branch, compose, getContext, pure } from 'recompose';
import { space } from 'styled-system';

import { styleUtils } from 'utils';

const Body = styled.div.attrs({
  size: ({ size }) => size || 'defalut',
})`
  margin-top: ${styleUtils.getSizeValue(0.9375)};
  font-size: ${styleUtils.getSizeValue(0.875)};
  ${space};
`;

const enhance = compose(
  branch(({ size }) => !size, getContext({ size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })),
  pure,
);

export default enhance(Body);
