import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Strong, Small } from 'components/shared';

const propTypes = {
  item: PropTypes.shape({
    displayName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    lastContactDate: PropTypes.string,
  }),
  name: PropTypes.string.isRequired,
};

const defaultProps = {
  item: undefined,
};

function ManagerItem(props) {
  const { item, name } = props;
  const {
    displayName,
    email,
    phone,
    lastContactDate,
  } = item;

  return (
    <div>
      <p>
        {name}: <Strong>{displayName}</Strong>
      </p>
      <p>
        <Small>
          Email: <Strong>{email}</Strong>
        </Small>
      </p>
      <p>
        <Small>
          Phone: <Strong>{phone || 'N/A'}</Strong>
        </Small>
      </p>
      {lastContactDate && (
        <p>
          <Small>
            Last Contact Date: <Strong>{moment(lastContactDate).format('MMM DD YYYY')}</Strong>
          </Small>
        </p>
      )}
    </div>
  );
}

ManagerItem.propTypes = propTypes;
ManagerItem.defaultProps = defaultProps;

export default ManagerItem;
