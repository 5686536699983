import { compose, lifecycle, pure, withState } from 'recompose';

const enhance = compose(
  withState('defaultValue', 'setDefaultValue', ({ defaultValue }) => defaultValue || undefined),
  lifecycle({
    componentDidMount() {
      const { meta: { initial }, defaultValue, setDefaultValue } = this.props;
      if (!defaultValue && initial && initial.length > 0) {
        setDefaultValue(initial[0]);
      }
    },
  }),
  pure,
);

export default enhance;
