import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import RoundScale from 'components/shared/RoundScale';
import ProgressBar from 'components/shared/ProgressBar';

import { displayRatio, getRatioPercent } from 'utils/ratio';

const propTypes = {
  borrowerRatio: PropTypes.arrayOf(PropTypes.number),
  className: PropTypes.string,
  entityRatio: PropTypes.arrayOf(PropTypes.number),
  loanRatio: PropTypes.arrayOf(PropTypes.number),
  propertyRatio: PropTypes.arrayOf(PropTypes.number),
  totalRatio: PropTypes.arrayOf(PropTypes.number),
};

const defaultProps = {
  borrowerRatio: undefined,
  className: undefined,
  entityRatio: undefined,
  loanRatio: undefined,
  propertyRatio: undefined,
  totalRatio: undefined,
};

function ApplicationDocumentsHeader({ borrowerRatio, className, entityRatio, loanRatio, propertyRatio, totalRatio }) {
  const complete = getRatioPercent(totalRatio);
  return (
    <Container className={className}>
      <StyledRoundScale percent={complete} />
      <Groups>
        {borrowerRatio && (
          <Item title={`Borrower Documents ${displayRatio(borrowerRatio)}`} width={getRatioPercent(borrowerRatio)} />
        )}
        {propertyRatio && (
          <Item title={`Property Documents ${displayRatio(propertyRatio)}`} width={getRatioPercent(propertyRatio)} />
        )}
        {loanRatio && <Item title={`Loan Documents ${displayRatio(loanRatio)}`} width={getRatioPercent(loanRatio)} />}
        {entityRatio && (
          <Item title={`Entity Documents ${displayRatio(entityRatio)}`} width={getRatioPercent(entityRatio)} />
        )}
      </Groups>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

const StyledRoundScale = styled(RoundScale)`
  flex-shrink: 0;
`;

const Groups = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 1.375rem;
`;

const Item = styled(ProgressBar)`
  margin-tom: 0.5rem;
  margin-right: 2.6875rem;
  margin-bottom: 0.5rem;
  min-width: 13.75rem;
`;

ApplicationDocumentsHeader.propTypes = propTypes;
ApplicationDocumentsHeader.defaultProps = defaultProps;

export default ApplicationDocumentsHeader;
