import PropTypes from 'prop-types';
import { compose, getContext, mapProps, pure, withHandlers } from 'recompose';
import * as R from 'ramda';

import FormGroupAdapter from 'components/shared/form/FormGroupAdapter';

import { getAddressComponentsWithValue, camelizeKeys, getIn, setIn, preparedGeoCodedResults } from 'utils';

const enhance = compose(
  getContext({
    components: PropTypes.arrayOf(PropTypes.object),
    setAddressComponents: PropTypes.func,
    unsetAddressComponents: PropTypes.func,
    isGoogleAvailable: PropTypes.bool,
    areComponentsRendered: PropTypes.bool,
  }),
  withHandlers({
    set: ({ setAddressComponents, components }) => (input) => {
      if (R.isNil(input) || R.isEmpty(input)) return;
      const handler = getAddressComponentsWithValue(components);
      const values = handler(input);
      setAddressComponents(values);
    },
    unset: ({ unsetAddressComponents, components }) => (input) => {
      if (R.isNil(input) || R.isEmpty(input)) unsetAddressComponents(components);
    },
  }),
  withHandlers({
    handleComponentsChange: ({ unset, set }) => (value = {}) =>
      R.pipe(
        camelizeKeys,
        getIn('result'),
        preparedGeoCodedResults,
        R.tap(unset),
        R.tap(set),
      )(value),
  }),
  withHandlers({
    enhancedOnChange: ({ input, handleComponentsChange }) =>
      R.pipe(
        R.tap(handleComponentsChange),
        R.prop('value'),
        input.onChange,
      ),
  }),
  mapProps(({ input, isGoogleAvailable, handleChange, handleComponentsChange, enhancedOnChange, ...rest }) =>
    R.pipe(
      setIn('text', 'type'),
      setIn(input, 'input'),
      R.when(
        R.always(isGoogleAvailable),
        R.pipe(
          setIn('address', 'type'),
          setIn({ value: input.value, label: input.value }, 'input.value'),
          setIn(enhancedOnChange, 'input.onChange'),
        ),
      ),
    )(rest),
  ),
  pure,
);

export default enhance(FormGroupAdapter);
