import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withState, withHandlers, withContext } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import createDecorator from 'final-form-focus';
import * as R from 'ramda';

import * as actions from 'actions';
import { getUserContactId, getApplicationBorrowerScoreByContactId, getApplicationType, getApplicationProductId, getNewConstructionProductList } from 'selectors';
import { holdMutators, warnNotification } from 'utils';
import * as fp from 'utils/ramda';

import Form from './Form';
import { handlers, utils } from './shared';

const propTypes = {
  initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const focusOnErrors = createDecorator();

function Step2({ initialValues, loading, onSubmit, productId, newConstrProductList }) {
  const matchingNewConstrProduct = newConstrProductList.find(product => product.value === productId);
  const title = matchingNewConstrProduct ? 'Apply For New Construction Loan' : 'Apply For Fix/Flip or Bridge Loan';

  return (
    <FinalForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{ ...holdMutators, ...arrayMutators }}
      decorators={[focusOnErrors]}
      subscription={{ submitting: true, pristine: true }}
      loading={loading}
      component={Form}
      title={title}
    />
  );
}

Step2.propTypes = propTypes;

function mapStateToProps(state) {
  const userContactId = getUserContactId(state);
  const borrowerScore = getApplicationBorrowerScoreByContactId(state, { id: userContactId });
  const applicationType = getApplicationType(state);
  const productId = getApplicationProductId(state);
  const newConstrProductList = getNewConstructionProductList(state);
  return {
    applicationType,
    userContactId,
    borrowerScore,
    productId,
    newConstrProductList,
  };
}

function mapDispatchToProps(dispatch, { setInitialValues }) {
  const isPreApprowal = R.pipe(R.pathEq(['application', 'transactionPurpose'], 'pre_approval'));
  const updateApplication = bindActionCreators(actions.updatePropertyApplication, dispatch);

  const onSubmit = (values, form) => {
    if (isPreApprowal(values)) {
      const clearValues = R.evolve({ application: R.pick(['transactionPurpose', 'token', 'legalName']) });
      return R.compose(
        (v) => updateApplication(v, form),
        clearValues,
      )(values);
    }

    const propertiesPath = ['application', 'properties'];
    const visibleProperties = R.compose(
      R.reject(R.prop('_hidden')),
      R.pathOr([], propertiesPath),
    )(values);

    if (fp.isNotDefined(visibleProperties)) {
      warnNotification('You must add at least one property!');
      R.compose(
        setInitialValues,
        utils.setDefaultInitialValues,
        R.assocPath(propertiesPath, [{}]),
      )(values);
      return undefined;
    }

    return R.compose(
      (v) => updateApplication(v, form),
      R.assocPath(propertiesPath, visibleProperties),
    )(values);
  };

  return {
    onSubmit,
    fetchApplicationLeadProperty: bindActionCreators(actions.fetchApplicationLeadProperty, dispatch),
    dataLayerPush: bindActionCreators(actions.dataLayerPush, dispatch),
  };
}

const enhance = compose(
  withRouter,
  withState('initialValues', 'setInitialValues', utils.setDefaultInitialValues()),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('loading', 'setLoading', false),
  withHandlers({
    mergeInitialValues: handlers.mergeInitialValues,
  }),
  withHandlers({
    fetchInitialValues: ({ mergeInitialValues, match, setLoading, fetchApplicationLeadProperty }) => async () => {
      const { token } = match.params;
      setLoading(true);
      await fetchApplicationLeadProperty(token).then(mergeInitialValues);
      setLoading(false);
    },
  }),
  lifecycle({
    async componentDidMount() {
      const {
        applicationType,
        borrowerScore,
        dataLayerPush,
        userContactId,
        fetchInitialValues,
      } = this.props;
      dataLayerPush({ loanType: applicationType, userId: userContactId, bSc: borrowerScore });
      fetchInitialValues();
    },
  }),
  withContext(
    {
      fetchInitialValues: PropTypes.func,
    },
    ({ fetchInitialValues }) => ({ fetchInitialValues }),
  ),
  pure,
);

export default enhance(Step2);
