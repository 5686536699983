import React from 'react';
import { compose, lifecycle, pure, withHandlers } from 'recompose';
import { Switch, Route } from 'react-router-dom';

import { IframeTemplate } from 'components/templates';
import { Home, Rental, Bridge, NotFound } from 'components/blocks';
import PrivateRoute from 'components/PrivateRoute';

import { LEAD_PATH, BRIDGE_PATH, INDEX_PATH, RENTAL_PATH } from 'constants/routes';
import ScrollToTopOnMount from 'hocs/ScrollToTopOnMount';

import ApplicationPage from './Application/ApplicationPage';

function Iframe() {
  return (
    <IframeTemplate>
      <Switch>
        <Route path={INDEX_PATH} component={Home} exact />
        <Route path={BRIDGE_PATH} component={Bridge} exact />
        <Route path={RENTAL_PATH} component={Rental} exact />
        <PrivateRoute path={LEAD_PATH} component={ApplicationPage} />

        <Route component={NotFound} />
      </Switch>
    </IframeTemplate>
  );
}

const enhance = compose(
  ScrollToTopOnMount,
  withHandlers({
    postMessageToResize: () => () => {
      const height = document.getElementsByTagName('html')[0].scrollHeight;
      window.parent.postMessage(['setHeight', height], '*');
    },
  }),
  lifecycle({
    componentDidMount() {
      const { postMessageToResize } = this.props;
      window.addEventListener('load', postMessageToResize, false);
      window.onresize = postMessageToResize;
    },
  }),
  pure,
);

export default enhance(Iframe);
