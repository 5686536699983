import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure, withHandlers, withState } from 'recompose';
import { withRouter } from 'react-router-dom';

import { Wrapper } from 'components/shared';
import { UserHeader } from 'components/blocks/User';

import { UserTemplate } from 'components/templates';

import { ScrollToTopOnMount } from 'hocs';

import { ApplicationHeader, ApplicationSuccess, Title } from '../shared';
import New from './New';

const propTypes = {
  setSuccessTrue: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired,
};

function ExtensionPage({ setSuccessTrue, success }) {
  return (
    <UserTemplate header={<UserHeader />}>
      <ApplicationHeader />
      <StyledWrapper>
        {success && <ApplicationSuccess title="Your Extension Request has been submitted" />}
        {!success && (
          <Fragment>
            <Title>Extension Request</Title>
            <New setSuccessTrue={setSuccessTrue} />
          </Fragment>
        )}
      </StyledWrapper>
    </UserTemplate>
  );
}

const StyledWrapper = styled(Wrapper)`
  width: 100%;
  max-width: 40rem;
`;

ExtensionPage.propTypes = propTypes;

const enhance = compose(
  ScrollToTopOnMount,
  withRouter,
  withState('success', 'setSuccess', false),
  withHandlers({
    setSuccessTrue: ({ setSuccess }) => () => setSuccess(true),
  }),
  pure,
);

export default enhance(ExtensionPage);
