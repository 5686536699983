import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { requestInProcess } from 'selectors';
import * as actions from 'actions';
import * as requestTypes from 'constants/requestTypes';
import Button from 'components/shared/Button';

import { ScheduleCheckImportStatus } from 'components/blocks/Schedule/index';
import { reScheduleImportTemplateUrl } from 'config';

const propTypes = {
  importId: PropTypes.number,
  propertiesImporting: PropTypes.bool,
  checkingImportStatus: PropTypes.bool,
};

const defaultProps = {
  importId: undefined,
  propertiesImporting: false,
  checkingImportStatus: false,
};

// eslint-disable-next-line react/prefer-stateless-function
class ScheduleImport extends Component {
  render() {
    const {
      importId,
      propertiesImporting,
      checkingImportStatus,
      match: { params },
      importApplicationScheduleRequest,
      targetContactId,
    } = this.props;
    const { token } = params;

    return (
      <StyledScheduleImport>
        <StyledScheduleImportBlock>
          <div>
            <Button xs href={reScheduleImportTemplateUrl}>
              Download Template
            </Button>
          </div>
          &nbsp;
          <StyledScheduleImportForm>
            <Downshift>
              {({ isOpen, getToggleButtonProps, toggleMenu }) => (
                <div className="reschedule_import_status">
                  <Button xs {...getToggleButtonProps()} disabled={propertiesImporting || checkingImportStatus}>
                    {propertiesImporting ? 'Importing...' : 'Import'}
                  </Button>
                  <div className="reschedule_import_status__dropdown">
                    {isOpen && (
                      <div className="reschedule_import_status__dropdown_body">
                        <div>
                          Choose file{' '}
                          <input
                            type="file"
                            accept=".xlsx"
                            onChange={(e) => {
                              importApplicationScheduleRequest(e.target.files[0], token, targetContactId);
                              toggleMenu();
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Downshift>
          </StyledScheduleImportForm>
        </StyledScheduleImportBlock>
        {importId && (
          <StyledScheduleImportBlock>
            <span>&nbsp;</span>
            <Downshift>
              {({ isOpen, getToggleButtonProps }) => (
                <div className="reschedule_import_status">
                  <Button xs {...getToggleButtonProps()} disabled={propertiesImporting || checkingImportStatus}>
                    Last Import Status
                  </Button>
                  <div className="reschedule_import_status__dropdown">
                    {isOpen && (
                      <div className="reschedule_import_status__dropdown_body">
                        <ScheduleCheckImportStatus {...this.props} />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Downshift>
          </StyledScheduleImportBlock>
        )}
      </StyledScheduleImport>
    );
  }
}

const StyledScheduleImport = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

const StyledScheduleImportBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledScheduleImportForm = styled.div`
  position: relative;
`;

ScheduleImport.propTypes = propTypes;
ScheduleImport.defaultProps = defaultProps;

function mapStateToProps(state) {
  const propertiesImporting = requestInProcess(state, requestTypes.SCHEDULES_IMPORT);
  const checkingImportStatus = requestInProcess(state, requestTypes.SCHEDULES_CHECK_IMPORT_STATUS);

  return {
    importId: state.schedules.importId,
    propertiesImporting,
    checkingImportStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    importApplicationScheduleRequest: bindActionCreators(actions.importApplicationScheduleRequest, dispatch),
  };
}

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  pure,
);

export default enhance(ScheduleImport);
