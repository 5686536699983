import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, getContext, pure } from 'recompose';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { getBridgeFullFormSecondStepRefs } from 'selectors';

import { FormFlex, FormBox, FormGroupAdapter, Condition } from 'components/shared/form';

import { validateRequired } from 'utils';

import ArrayFields from './ArrayFields';

const propTypes = {
  isFormDisabled: PropTypes.bool.isRequired,
  refs: PropTypes.shape({
    loanTransactionPurposesCollection: PropTypes.arrayOf(PropTypes.object),
  }),
};

const defaultProps = {
  refs: {
    loanTransactionPurposesCollection: [],
  },
};

function Fields({ isFormDisabled, refs }) {
  return (
    <Fragment>
      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.transactionPurpose"
            label="Purchase or Refinance?"
            type="select"
            required
            isDisabled={isFormDisabled}
            options={refs.loanTransactionPurposesCollection}
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.requiredClosingDate"
            label="Required Closing Date"
            type="date"
            required
            disabled={isFormDisabled}
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.description"
            label="Project Description"
            type="textarea"
            disabled={isFormDisabled}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <Condition when="application.transactionPurpose" is="refinance">
        <FormFlex>
          <FormBox width={1}>
            <Field
              isConditional
              name="application.refinanceProceedsPurpose"
              type="textarea"
              disabled={isFormDisabled}
              label="What are you using the refinance proceeds to do?"
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>
      </Condition>

      <FieldArray subscription={{ initial: true }} name="application.properties" component={ArrayFields} />
    </Fragment>
  );
}

Fields.propTypes = propTypes;
Fields.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    refs: getBridgeFullFormSecondStepRefs(state),
  };
}

const enhance = compose(
  connect(mapStateToProps),
  getContext({ isFormDisabled: PropTypes.bool }),
  pure,
);

export default enhance(Fields);
