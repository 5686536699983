export const immutableDelete = (arr, index) => arr.slice(0, index).concat(arr.slice(index + 1));
export const immutablePop = (arr) => arr.slice(0, -1);
export const immutablePush = (arr, newEntry) => [].concat(arr, newEntry);
export const immutableReverse = (arr) => [...arr].reverse();
export const immutableShift = (arr) => arr.slice(1);
export const immutableSort = (arr, compareFunction) => [...arr].sort(compareFunction);
export const immutableSplice = (arr, start, deleteCount, ...items) => [
  ...arr.slice(0, start),
  ...items,
  ...arr.slice(start + deleteCount),
];
export const immutableUnshift = (arr, newEntry) => [newEntry, ...arr];
