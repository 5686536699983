import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from 'styled-tools';

import chevronUp from './chevronUp.png';

const propTypes = {
  title: PropTypes.string.isRequired,
  returnId: PropTypes.func,
  id: PropTypes.number,
  open: PropTypes.bool,
};

const defaultProps = {
  returnId: undefined,
  id: undefined,
  open: false,
};

function Panel({ children, title, returnId, id, open, ...props }) {
  const handleClick = () => (open ? returnId(null) : returnId(id));

  return (
    <StyledPanel {...props}>
      <Heading onClick={handleClick} className={open ? 'header open' : 'header'}>
        <Title>{title}</Title>
      </Heading>
      {open && <Body className="body">{children}</Body>}
    </StyledPanel>
  );
}

const StyledPanel = styled.div`
  &:last-child .body {
    border-bottom: 1px solid #d5dde9;
  }
  &:not(:first-child) .header {
    border-top: 0.0625rem solid transparent;
  }
`;

const Heading = styled.div`
  position: relative;
  cursor: pointer;
  display: block;
  padding: 1.125rem 3.75rem 1.125rem 1.25rem;
  text-decoration: none;
  color: #111111;
  background: #f7f8f8;
  border: 0.0625rem solid #d5dde9;
  transition: all 0.2s;
  &:after {
    content: '';
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -6px;
    width: 20px;
    height: 12px;
    background: url(${chevronUp}) no-repeat 0 0;
    background-size: contain;
    transform: rotate(180deg);
    transition: all 0.2s;
  }
  &.open {
    background: #fff;
    border: 1px solid #d5dde9;
    border-bottom: 1px solid #fff;
    &:after {
      transform: rotate(0deg);
    }
  }
`;

const Body = styled.div`
  padding: 0 1.25rem 1.375rem;
  background: #fff;
  border: 0.0625rem solid #d5dde9;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  font-family: ${theme('fonts.base')};
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.4375rem;
`;

const Title = styled.span`
  margin-top: 0;
  margin-bottom: 0;
  font-family: ${theme('fonts.base')};
  font-size: 1.1875rem;
  color: #111111;
  letter-spacing: 0.018125rem;
  line-height: 1.5625rem;
  text-decoration: none;
`;

Panel.propTypes = propTypes;
Panel.defaultProps = defaultProps;
export default Panel;
