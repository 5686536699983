import { createSelector } from 'reselect';
import * as R from 'ramda';

export const initialState = [];

const contactsSelector = (state = initialState) => state.contacts;

export const getContacts = createSelector(contactsSelector, (contacts) => contacts);

const contactByIdSelector = (state, id) => {
  const { contacts } = state;

  const index = R.findIndex(R.propEq('id', id), contacts);
  return contacts[index];
};

export const getContactById = createSelector(contactByIdSelector, (contact) => {
  if (contact) {
    const firstName = contact.firstName || '';
    const lastName = contact.lastName || '';
    const displayName = `${firstName} ${lastName}`;

    return {
      ...contact,
      displayName,
    };
  }
  return undefined;
});
