import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'styled-media-query';
import { ifProp } from 'styled-tools';

import successIcon from './success.png';
import warningIcon from './warning.png';
import moneyHouseIcon from './moneyHouse.png';
import successHouseIcon from './successHouse.png';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  small: PropTypes.bool,
  status: PropTypes.oneOf(['success', 'warning', 'money', 'successHouse']),
  title: PropTypes.string,
  text: PropTypes.string,
};

const defaultProps = {
  children: undefined,
  className: null,
  small: false,
  status: 'success',
  title: undefined,
  text: undefined,
};

const imgSrc = (status) => {
  switch (status) {
    case 'success':
      return successIcon;
    case 'warning':
      return warningIcon;
    case 'money':
      return moneyHouseIcon;
    case 'successHouse':
      return successHouseIcon;
    default:
      return successIcon;
  }
};

function Congratulation({ className, children, status, small, title, text }) {
  const imgProps = {
    src: imgSrc(status),
    small,
  };

  return (
    <StyledCongratulation className={className}>
      <div>
        <StyledImage {...imgProps} />
      </div>
      <ContentWrapp small={small}>
        {title && <Title small={small}>{title}</Title>}
        {text && <Text>{text}</Text>}
        {children}
      </ContentWrapp>
    </StyledCongratulation>
  );
}

const StyledCongratulation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWrapp = styled.div`
  text-align: left;
  padding-left: ${ifProp('small', '0.625rem', '1.9375rem')};
  ${media.lessThan('large')`
    flex-basis: ${ifProp('small', null, '50%')};
  `};
`;

const StyledImage = styled.img`
  display: block;
  width: ${ifProp('small', '3.75rem', '6.25rem')};
  height: ${ifProp('small', '3.75rem', '6.25rem')};
  min-width: ${ifProp('small', '3.75rem', '6.25rem')};
`;

const Title = styled.div`
  margin-bottom: ${ifProp('small', '0.0625rem', '0.9375rem')};
  font-weight: 300;
  font-size: 1.75rem;
  color: #111111;
  letter-spacing: 0.03125rem;
`;

const Text = styled.div`
  font-weight: 300;
  font-size: 1.125rem;
  color: #666666;
  letter-spacing: 0;
  line-height: 1.8125rem;
`;

Congratulation.propTypes = propTypes;
Congratulation.defaultProps = defaultProps;
export default Congratulation;
