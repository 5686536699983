import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ifProp, prop } from 'styled-tools';

import Icon from 'components/shared/Icon';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  size: PropTypes.number,
  valid: PropTypes.bool,
  withIcon: PropTypes.bool,
};

const defaultProps = {
  className: undefined,
  size: 1,
  valid: false,
  withIcon: false,
};

function DocLegend({ children, className, size, valid, withIcon }) {
  return (
    <legend className={className}>
      <StyledContainer size={size} valid={valid}>
        {withIcon && (
          <IconContainer valid={valid}>
            <Icon icon={valid ? 'check' : 'exclamationMark'} />
          </IconContainer>
        )}
        <ChildrenContainer>{children}</ChildrenContainer>
      </StyledContainer>
    </legend>
  );
}

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  font-size: ${prop('size', 1)}rem;
  border: none;
`;

const IconContainer = styled.div`
  display: flex;
  margin-right: 0.3125em;
  padding: 0;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  font-size: 1em;
  border-radius: 50%;
  background-color: ${ifProp('valid', '#3c763d', '#a94442')};

  ${Icon} {
    display: block;
    margin: auto;
    width: 0.7em;
    height: 0.7em;
    svg {
      fill: #fff;
    }
  }
`;

const ChildrenContainer = styled.div``;

DocLegend.propTypes = propTypes;
DocLegend.defaultProps = defaultProps;

export default DocLegend;
