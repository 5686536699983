import styled from 'styled-components';

const Title = styled.h2`
  padding: 1.25rem 0 0.625rem;
  text-align: center;
  font-size: 1.3125rem;
  font-weight: bold;
`;

export default Title;
