import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {
  children: undefined,
};

function TemplateBanner({ children }) {
  if (children) {
    return (
      <Container>
        {children}
      </Container>
    );
  }
  return null;
}

TemplateBanner.propTypes = propTypes;
TemplateBanner.defaultProps = defaultProps;

const Container = styled.section`
  position: sticky;
  top: 0;
  z-index: 102;
`;


export default TemplateBanner;
