import React from 'react';
import { compose } from 'recompose';

import ScrollToTopOnMount from 'hocs/ScrollToTopOnMount';
import { PageTemplate } from 'components/templates';
import { Home } from 'components/blocks';

function HomePage() {
  return (
    <PageTemplate>
      <Home />
    </PageTemplate>
  );
}

const enhance = compose(ScrollToTopOnMount);

export default enhance(HomePage);
