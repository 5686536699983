// Depreciated! use ConditionNew
import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withHandlers, withProps, withState, defaultProps } from 'recompose';

import { WithFinalFormMutators, WithFinalFormValue } from 'hocs';

import Fields from './Fields';
import { handlers, utils } from './shared';

const propTypes = {
  children: PropTypes.node.isRequired,
  holdValues: PropTypes.func.isRequired,
  unholdValues: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

function Condition({ children, show, holdValues, unholdValues }) {
  if (show) {
    return (
      <Fields unholdValues={unholdValues} holdValues={holdValues}>
        {children}
      </Fields>
    );
  }
  return null;
}

Condition.propTypes = propTypes;

const enhance = compose(
  WithFinalFormMutators,
  WithFinalFormValue('when'),
  defaultProps({ allowNull: true }),
  withProps(({ names, allowNull, ...props }) => ({
    names: names || utils.getNames(props),
  })),
  withState('show', 'setShow', false),
  withHandlers({
    getPredicat: handlers.getPredicat,
  }),
  withHandlers({
    holdValues: handlers.holdValues,
    unholdValues: handlers.unholdValues,
  }),
  withHandlers({
    check: handlers.check,
  }),
  lifecycle({
    UNSAFE_componentWillMount() {
      const { holdValues } = this.props;
      holdValues();
    },
    componentDidMount() {
      const { check } = this.props;
      check();
    },
    componentDidUpdate({ value: prevValue }) {
      const { check, value } = this.props;
      if (value !== prevValue) check();
    },
  }),
  pure,
);

export default enhance(Condition);
