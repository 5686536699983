import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure, withStateHandlers, defaultProps as recomposeDefaultProps } from 'recompose';

const propTypes = {
  activePanel: PropTypes.number,
  children: PropTypes.node.isRequired,
  handleClick: PropTypes.func.isRequired,
};

const defaultProps = {
  activePanel: null,
};

function GroupPanel({ children, activePanel, handleClick, ...props }) {
  const ExtendedChildren = React.Children.map(children, (child, index) => {
    const isOpen = index === activePanel;
    return React.cloneElement(child, { id: index, returnId: handleClick, open: isOpen });
  });

  return <StyledGroupPanel {...props}>{ExtendedChildren}</StyledGroupPanel>;
}

const StyledGroupPanel = styled.div``;

GroupPanel.propTypes = propTypes;
GroupPanel.defaultProps = defaultProps;

const enhance = compose(
  recomposeDefaultProps({ openDefault: 0 }),
  withStateHandlers(
    ({ openDefault }) => ({
      activePanel: openDefault,
    }),
    {
      handleClick: () => (id) => ({
        activePanel: id,
      }),
    },
  ),
  pure,
);

export default enhance(GroupPanel);
