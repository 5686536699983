import setRequestInProcess from '../actions/request';

import { REFS_FETCH_SUCCESS } from '../constants/actionTypes';
import * as requestTypes from '../constants/requestTypes';
import api from '../utils/api';

export default () => (dispatch, getState) => {
  const requestType = requestTypes.REFS;
  const url = '/references';

  const requestInProcess = getState().request[requestType];

  if (requestInProcess) {
    return;
  }

  dispatch(setRequestInProcess(true, requestType));

  api
    .get(url)
    .then((response) => {
      dispatch(fetchRefsSuccess(response.data));
      dispatch(setRequestInProcess(false, requestType));
    })
    .catch((errors) => {
      dispatch(setRequestInProcess(false, requestType, errors));
    });
};

function fetchRefsSuccess(payload) {
  return {
    type: REFS_FETCH_SUCCESS,
    payload,
  };
}
