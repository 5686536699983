import React from 'react';

import { pure, compose, withProps } from 'recompose';

import withScriptjs from 'react-google-maps/lib/withScriptjs';
import withGoogleMap from 'react-google-maps/lib/withGoogleMap';
import GoogleMap from 'react-google-maps/lib/components/GoogleMap';
import Marker from 'react-google-maps/lib/components/Marker';

function MapWithMarker(props) {
  const { lat, lng } = props;

  return (
    <GoogleMap defaultZoom={8} center={{ lat, lng }}>
      <Marker position={{ lat, lng }} />
    </GoogleMap>
  );
}

const enhance = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyAYk0Acksn_i0V47_fBpw7EO7qzEIuqx40&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '400px' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withScriptjs,
  withGoogleMap,
  pure,
);

export default enhance(MapWithMarker);
