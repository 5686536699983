import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { Icon } from 'components/shared';
import IconButton from 'components/shared/IconButton';

const propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
};

const defaultProps = {
  className: undefined,
  isOpen: false,
};

function DropdownButton({ className, isOpen, ...rest }) {
  const props = {
    height: 1,
    right: true,
    ...rest,
  };
  return <IconButton className={className} icon="angle" {...props} />;
}

const StyledDropdownButton = styled(DropdownButton)`
  ${Icon} {
    transition: all 0.2;
    transform: rotate(180deg);
  }
  ${ifProp(
    'isOpen',
    css`
      ${Icon} {
        transform: none;
      }
    `,
  )};
`;

DropdownButton.propTypes = propTypes;
DropdownButton.defaultProps = defaultProps;

export default StyledDropdownButton;
