import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, lifecycle, pure } from 'recompose';
import ScrollToTopOnMount from 'hocs/ScrollToTopOnMount';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ContentLoader from 'react-content-loader';
import { bindActionCreators } from 'redux';

import * as actions from 'actions';
import { getApplicationsSortedCollectionFilteredByStatus } from 'selectors';

import { UserTemplate } from 'components/templates';
import { Link, Tab, Tabs, Wrapper, Button } from 'components/shared';
import { UserHeader } from 'components/blocks/User';

import { MY_APPLICATIONS_PATH, LOAN_HELP } from 'constants/routes';
import { IS_LENDINGONE } from 'constants/views';
import { userDefaultProps, userPropTypes } from 'propTypes';
import { WithIncompleteApp, WithUser } from 'hocs';
import { getPath } from 'utils';

import { ApplicationAddNewDropdown, ApplicationList } from './shared';

const propTypes = {
  ...userPropTypes,
  applications: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

const defaultProps = {
  ...userDefaultProps,
  applications: [],
};

function MyApplicationsPage({ collection, incompleteApp, loading, match }) {
  return (
    <UserTemplate header={<UserHeader />}>
      <StyledWrapper>
        <Header>
          <Tabs>
            <Tab active={match.params && match.params.status === 'active'}>
              <Link to={getPath(MY_APPLICATIONS_PATH, { status: 'active' })}>Active</Link>
            </Tab>
            <Tab active={match.params && match.params.status === 'closed'}>
              <Link to={getPath(MY_APPLICATIONS_PATH, { status: 'closed' })}>Closed</Link>
            </Tab>
            <Tab active={match.params && match.params.status === 'rejected'}>
              <Link to={getPath(MY_APPLICATIONS_PATH, { status: 'rejected' })}>Rejected and Lost</Link>
            </Tab>
            <Tab active={match.params && match.params.status === 'all'}>
              <Link to={getPath(MY_APPLICATIONS_PATH, { status: 'all' })}>All</Link>
            </Tab>
          </Tabs>

          <Actions>
            {match.params && match.params.status === 'closed' && (
              <Button xs external rounded href={LOAN_HELP}>
                Loan Help
              </Button>
            )}

            {IS_LENDINGONE &&
              (loading ? (
                <ContentLoader height={24} speed={3} width={118} style={{ width: '118px' }}>
                  <rect x="0" y="0" rx="5" ry="5" width="118" height="24" />
                </ContentLoader>
              ) : (
                !incompleteApp && <ApplicationAddNewDropdown />
              ))
            }
          </Actions>
        </Header>

        {loading ? <ContentLoader /> : <ApplicationList applications={collection} />}
      </StyledWrapper>
    </UserTemplate>
  );
}

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  border-bottom: 0.0625rem solid #d5dde9;
`;

const StyledWrapper = styled(Wrapper)`
  width: 100%;
  max-width: 90rem;
`;

const Actions = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 1rem;
`;

MyApplicationsPage.propTypes = propTypes;
MyApplicationsPage.defaultProps = defaultProps;

function mapStateToProps(state, props) {
  const { status } = props.match.params;
  const { userIsLoading, applicationsIsLoading } = props;

  return {
    collection: getApplicationsSortedCollectionFilteredByStatus(state, status),
    loading: userIsLoading || applicationsIsLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchApplications: bindActionCreators(actions.fetchApplications, dispatch),
  };
}

const enhance = compose(
  ScrollToTopOnMount,
  WithUser,
  WithIncompleteApp,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  lifecycle({
    componentDidMount() {
      const { fetchApplications } = this.props;
      fetchApplications();
    },
  }),
  pure,
);

export default enhance(MyApplicationsPage);
