export const APPLICATION_CREATE_SUCCESS = 'APPLICATION_CREATE_SUCCESS';
export const APPLICATION_FETCH_SUCCESS = 'APPLICATION_FETCH_SUCCESS';
export const APPLICATION_QUOTE_FETCH_SUCCESS = 'APPLICATION_QUOTE_FETCH_SUCCESS';
export const APPLICATION_BUY_POINTS_GUIDELINE_FETCH_SUCCESS = 'APPLICATION_BUY_POINTS_GUIDELINE_FETCH_SUCCESS';
export const APPLICATION_SCHEDULES_ADD = 'APPLICATION_SCHEDULES_ADD';
export const APPLICATION_SCHEDULES_FETCH_SUCCESS = 'APPLICATION_SCHEDULES_FETCH_SUCCESS';
export const APPLICATION_SCHEDULES_REMOVE = 'APPLICATION_SCHEDULES_REMOVE';
export const APPLICATION_SCHEDULES_RESET = 'APPLICATION_SCHEDULES_RESET';
export const APPLICATION_SCHEDULES_UPDATE = 'APPLICATION_SCHEDULES_UPDATE';
export const APPLICATION_SCHEDULES_UPDATE_IMPORT_ID = 'APPLICATION_SCHEDULES_UPDATE_IMPORT_ID';
export const APPLICATION_SCHEDULES_CHECK_IMPORT_STATUS = 'APPLICATION_SCHEDULES_CHECK_IMPORT_STATUS';

export const APPLICATION_UPDATE_SUCCESS = 'APPLICATION_UPDATE_SUCCESS';
export const APPLICATIONS_FETCH_SUCCESS = 'APPLICATIONS_FETCH_SUCCESS';
export const APPLICATIONS_RESET = 'APPLICATIONS_RESET';

export const CONSTRUCTION_BUDGET_CLEAR = 'CONSTRUCTION_BUDGET_CLEAR';
export const CONSTRUCTION_BUDGET_FETCH_SUCCESS = 'CONSTRUCTION_BUDGET_FETCH_SUCCESS';
export const CONSTRUCTION_BUDGET_TEMPLATE_FETCH_SUCCESS = 'CONSTRUCTION_BUDGET_TEMPLATE_FETCH_SUCCESS';
export const CONSTRUCTION_BUDGET_UPDATE = 'CONSTRUCTION_BUDGET_UPDATE';
export const CONTACT_FETCH_SUCCESS = 'CONTACT_FETCH_SUCCESS';
export const CONTACT_TYPES_FETCH_SUCCESS = 'CONTACT_TYPES_FETCH_SUCCESS';
export const CONTACT_TYPES_RESET = 'CONTACT_TYPES_RESET';
export const CONTACTS_FETCH_SUCCESS = 'CONTACTS_FETCH_SUCCESS';
export const CONTACTS_RESET = 'CONTACTS_RESET';

export const CONDITIONS_FETCH_SUCCESS = 'CONDITIONS_FETCH_SUCCESS';

export const DOCUMENT_CATEGORIES_FETCH_SUCCESS = 'DOCUMENT_CATEGORIES_FETCH_SUCCESS';
export const DOWNLOAD_DOCUMENT_CATEGORIES_FETCH_SUCCESS = 'DOWNLOAD_DOCUMENT_CATEGORIES_FETCH_SUCCESS';
export const DOCUMENT_CATEGORIES_RESET = 'DOCUMENT_CATEGORIES_RESET';
export const DOCUMENTS_FETCH_SUCCESS = 'DOCUMENTS_FETCH_SUCCESS';
export const DOCUMENTS_RESET = 'DOCUMENTS_RESET';

export const DRAW_REQUESTS_FETCH_SUCCESS = 'DRAW_REQUESTS_FETCH_SUCCESS';

export const ENTITIES_FETCH_SUCCESS = 'ENTITIES_FETCH_SUCCESS';

export const ME_FROM_TOKEN = 'ME_FROM_TOKEN';
export const ME_FROM_TOKEN_FAILURE = 'ME_FROM_TOKEN_FAILURE';
export const ME_FROM_TOKEN_SUCCESS = 'ME_FROM_TOKEN_SUCCESS';

export const PAGINATE_LINK_SET = 'PAGINATE_LINK_SET';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';

export const PROCESSOR_NOTES_FETCH_SUCCESS = 'PROCESSOR_NOTES_FETCH_SUCCESS';

export const REFS_FETCH_SUCCESS = 'REFS_FETCH_SUCCESS';
export const REQUEST_IN_PROCESS_SET = 'REQUEST_IN_PROCESS_SET';

export const SEARCH_PARAMS_SET = 'SEARCH_PARAMS_SET';
export const APPLICATION_SEARCH_PARAMS_RESET = 'APPLICATION_SEARCH_PARAMS_RESET';

export const SESSION_RESET = 'SESSION_RESET';
export const SESSION_SET = 'SESSION_SET';
export const SETTINGS_FETCH_SUCCESS = 'SETTINGS_FETCH_SUCCESS';

export const FINAL_TERM_SHEET_FETCH_SUCCESS = 'FINAL_TERM_SHEET_FETCH_SUCCESS';

export const UNDO_WITHDRAW_SUCCESS = 'UNDO_WITHDRAW_SUCCESS';

export const WITHDRAW_SUCCESS = 'WITHDRAW_SUCCESS';
