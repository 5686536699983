import * as actionTypes from 'constants/actionTypes';

import { initialState } from 'selectors/applicationTermSheets';

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.FINAL_TERM_SHEET_FETCH_SUCCESS:
      return {
        ...state,
        detail: payload.termSheet,
      };
    default:
      return state;
  }
};
