import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ifProp, palette } from 'styled-tools';

const propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

const defaultProps = {
  className: undefined,
  onClick: undefined,
};

const fontSize = ({ width, height }) => {
  const size = width || height;
  return size ? `${size}rem` : '1em';
};

function Icon({ icon, className, onClick }) {
  const svg = require(`!raw-loader!./icons/${icon}.svg`);
  let iconButton;

  if (onClick) {
    iconButton = <span onClick={onClick} className={className} dangerouslySetInnerHTML={{ __html: svg }} />;
  } else {
    iconButton = <span className={className} dangerouslySetInnerHTML={{ __html: svg }} />;
  }

  return <>{iconButton}</>;
}

const StyledIcon = styled(Icon)`
  display: inline-block;
  font-size: ${fontSize};
  color: ${ifProp('palette', palette(), 'currentcolor')};
  width: 1em;
  height: 1em;
  margin: 0.1em;
  box-sizing: border-box;

  & > svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: currentcolor;
    stroke: currentcolor;
  }
`;

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default StyledIcon;
