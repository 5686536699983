import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { Hr } from 'components/shared';
import { FormBox, FormGroupAdapter, FormAsterisk, NarrowFormFlex } from 'components/shared/form';

import { validateRequired } from 'utils';

import ArrayFields from './ArrayFields';

function Fields() {
  return (
    <Fragment>
      <FieldArray subscription={{ initial: true }} name="application.properties" component={ArrayFields} />

      <NarrowFormFlex>
        <FormBox width={1}>
          <Hr />
        </FormBox>
      </NarrowFormFlex>

      <NarrowFormFlex>
        <FormBox width={1}>
          <Field
            name="application.requestedAmount"
            required
            label="Requested Loan Amount"
            typeOfMask="currency"
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </NarrowFormFlex>

      <NarrowFormFlex>
        <FormBox width={1}>
          <FormAsterisk>
            All red asterisks are required in order to provide you with an accurate rate quote.
          </FormAsterisk>
        </FormBox>
      </NarrowFormFlex>
    </Fragment>
  );
}

export default Fields;
