import { createSelector } from 'reselect';
import { view, lensPath, prop, propOr } from 'ramda';

import { getIn } from 'utils';
import { alphabeticalSortingCollection, usPriorityCountriesCollectionDecorator } from 'decorators/collections';

export const initialState = {};

function applicationTypeSelector(state, options) {
  return prop('applicationType', options);
}

export const getRefs = (state = initialState) => state.refs;

// contact
const getCreditScoresCollection = createSelector(
  getRefs,
  (refs) => getIn('contact.creditScoresCollection', refs),
);
const getOwnRentCollection = createSelector(
  getRefs,
  (refs) => getIn('contact.ownRentCollection', refs),
);
const getReferredByTypeCollection = createSelector(
  getRefs,
  (refs) => getIn('contact.referredByTypeCollection', refs),
);
const getAvailableToInvestCollection = createSelector(
  getRefs,
  (refs) => getIn('contact.availableToInvestCollection', refs),
);
const getRealEstateTransactionsCountCollection = createSelector(
  getRefs,
  (refs) => getIn('contact.realEstateTransactionsCountCollection', refs),
);

// construction works

const getAvailableConstructionWorks = createSelector(
  getRefs,
  (refs) => getIn('constructionBudget.availableConstructionWorks', refs),
);

// property
const getOccupanciesCollection = createSelector(
  getRefs,
  (refs) => getIn('property.occupanciesCollection', refs),
);
export const getActualTypesCollection = createSelector(
  getRefs,
  (refs) => getIn('property.actualTypesCollection', refs),
);
const getActualRentalTypesCollection = createSelector(
  getRefs,
  (refs) => getIn('property.actualRentalTypesCollection', refs),
);
const getExitStrategiesCollection = createSelector(
  getRefs,
  (refs) => getIn('property.exitStrategiesCollection', refs),
);
const getConstructionBudgetsCollection = createSelector(
  getRefs,
  (refs) => getIn('property.constructionBudgetsCollection', refs),
);
const getSystemTypesCollection = createSelector(
  getRefs,
  (refs) => getIn('property.systemTypesCollection', refs),
);
const getFindTypesCollection = createSelector(
  getRefs,
  (refs) => getIn('property.findTypesCollection', refs),
);
const getContractStatusesCollection = createSelector(
  getRefs,
  (refs) => getIn('property.contractStatusesCollection', refs),
);

// Application

export const getApplicationStatuses = createSelector(
  getRefs,
  view(lensPath(['application', 'statuses'])),
);

export const getApplicationStatusesByType = createSelector(
  [applicationTypeSelector, getApplicationStatuses],
  (type, statuses = {}) => {
    const bridge = propOr([], 'bridge', statuses);
    const rental = propOr([], 'rental', statuses);
    const preApproval = propOr([], 'preApproval', statuses);

    switch (type) {
      case 'bridge':
        return bridge;
      case 'rental':
        return rental;
      case 'pre_approval':
        return preApproval;
      default:
        return [];
    }
  },
);

// othger
const getApplicationTypesCollection = createSelector(
  getRefs,
  (refs) => getIn('other.applicationTransactionPurposeCollection', refs),
);

const getCountriesCollection = createSelector(
  getRefs,
  (refs) => getIn('other.countriesCollection', refs),
);
const getSortingCountriesCollection = createSelector(
  getCountriesCollection,
  alphabeticalSortingCollection,
);
const getSortingCountriesWithUsPriorityCollection = createSelector(
  getSortingCountriesCollection,
  usPriorityCountriesCollectionDecorator,
);

const getEntityCollection = createSelector(
  getRefs,
  (refs) => getIn('other.statesCollection', refs),
);
const getLoanExperienceTransactionPurposesCollection = createSelector(
  getRefs,
  (refs) => getIn('other.loanExperienceTransactionPurposesCollection', refs),
);
const getLoanOfficersCollection = createSelector(
  getRefs,
  (refs) => getIn('other.loanOfficersCollection', refs),
);
const getLoanExtensionTermsCollection = createSelector(
  getRefs,
  (refs) => getIn('other.loanExtensionTermsCollection', refs),
);
const getStatesCollection = createSelector(
  getRefs,
  (refs) => getIn('other.statesCollection', refs),
);
const getLoanTransactionPurposesCollection = createSelector(
  getRefs,
  (refs) => getIn('other.loanTransactionPurposesCollection', refs),
);
const getQuestionRefinanceCollection = createSelector(
  getRefs,
  (refs) => getIn('other.questionRefinanceCollection', refs),
);
const getEntityTypesCollection = createSelector(
  getRefs,
  (refs) => getIn('other.entityTypesCollection', refs),
);
const getBorrowerTitlesList = createSelector(
  getRefs,
  (refs) => getIn('other.borrowerTitlesList', refs),
);
export const getNewConstructionProductList = createSelector(
  getRefs,
  (refs) => getIn('other.newConstructionProductIds', refs),
);

export const getBridgeFirstStepRefs = createSelector(
  [
    getStatesCollection,
    getEntityCollection,
    getReferredByTypeCollection,
    getLoanOfficersCollection,
    getCreditScoresCollection,
  ],
  (statesCollection, entityCollection, referredByTypeCollection, loanOfficersCollection, creditScoresCollection) => ({
    statesCollection,
    entityCollection,
    referredByTypeCollection,
    loanOfficersCollection,

    creditScoresCollection,
  }),
);

export const getRentalFirstStepRefs = createSelector(
  [
    getCreditScoresCollection,
    getEntityCollection,
    getStatesCollection,
    getLoanExperienceTransactionPurposesCollection,
    getLoanOfficersCollection,
    getReferredByTypeCollection,
  ],
  (
    creditScoresCollection,
    entityCollection,
    statesCollection,
    loanExperienceTransactionPurposesCollection,
    loanOfficersCollection,
    referredByTypeCollection,
  ) => ({
    creditScoresCollection,
    entityCollection,
    statesCollection,
    loanExperienceTransactionPurposesCollection,
    loanOfficersCollection,
    referredByTypeCollection,
  }),
);

export const getRentalSecondStepRefs = createSelector(
  [getActualRentalTypesCollection, getOccupanciesCollection],
  (actualRentalTypesCollection, occupanciesCollection) => ({
    actualRentalTypesCollection,
    occupanciesCollection,
  }),
);

export const getRentalThirdStepRefs = createSelector(
  [getCreditScoresCollection, getOwnRentCollection, getSortingCountriesCollection],
  (creditScoresCollection, ownRentCollection, countriesCollection) => ({
    creditScoresCollection,
    ownRentCollection,
    countriesCollection,
  }),
);

export const getBridgeSecondStepRefs = createSelector(
  [getApplicationTypesCollection, getSortingCountriesCollection],
  (applicationTransactionPurposeCollection, countriesCollection) => ({
    applicationTransactionPurposeCollection,
    countriesCollection,
  }),
);

export const getBridgeFullFormFirstStepRefs = createSelector(
  [getAvailableToInvestCollection, getRealEstateTransactionsCountCollection],
  (availableToInvestCollection, realEstateTransactionsCountCollection) => ({
    availableToInvestCollection,
    realEstateTransactionsCountCollection,
  }),
);

export const getBridgeFullFormSecondStepRefs = createSelector(
  [getLoanTransactionPurposesCollection, getQuestionRefinanceCollection],
  (loanTransactionPurposesCollection, questionRefinanceCollection) => ({
    loanTransactionPurposesCollection,
    questionRefinanceCollection,
  }),
);

export const getBridgeFullFormSecondStepPropertiesRefs = createSelector(
  [
    getActualTypesCollection,
    getActualRentalTypesCollection,
    getOccupanciesCollection,
    getExitStrategiesCollection,
    getConstructionBudgetsCollection,
    getSystemTypesCollection,
  ],
  (
    actualTypesCollection,
    actualRentalTypesCollection,
    occupanciesCollection,
    exitStrategiesCollection,
    constructionBudgetsCollection,
    systemTypesCollection,
  ) => ({
    actualTypesCollection,
    actualRentalTypesCollection,
    occupanciesCollection,
    exitStrategiesCollection,
    constructionBudgetsCollection,
    systemTypesCollection,
  }),
);

export const getBridgeFullFormThirdStepRefs = createSelector(
  getSortingCountriesWithUsPriorityCollection,
  getStatesCollection,
  getOwnRentCollection,
  getCreditScoresCollection,
  (countriesCollection, statesCollection, ownRentCollection, creditScoresCollection) => ({
    countriesCollection,
    statesCollection,
    ownRentCollection,
    creditScoresCollection,
  }),
);

export const getBridgeFullFormFourthStepRefs = createSelector(
  getEntityTypesCollection,
  (entityTypesCollection) => ({
    entityTypesCollection,
  }),
);

export const getBridgeFullFormFourthStepBorrowersRefs = createSelector(
  getBorrowerTitlesList,
  (borrowerTitlesList) => ({
    borrowerTitlesList,
  }),
);

export const getBridgeFullFormFifthStepPropertyRefs = createSelector(
  getFindTypesCollection,
  getContractStatusesCollection,
  (findTypesCollection, contractStatusesCollection) => ({
    findTypesCollection,
    contractStatusesCollection,
  }),
);

export const getBridgeSecondStepPropertyRefs = createSelector(
  [getActualTypesCollection],
  (actualTypesCollection) => ({
    actualTypesCollection,
  }),
);

export const getAddressRefs = createSelector(
  getStatesCollection,
  getSortingCountriesWithUsPriorityCollection,
  (statesCollection, countriesCollection) => ({
    statesCollection,
    countriesCollection,
  }),
);

export const getAvailableConstructionWorksRefs = createSelector(
  getAvailableConstructionWorks,
  (constructionWorksCollection) => constructionWorksCollection,
);

export const getActualTypesCollectionRefs = createSelector(
  getActualTypesCollection,
  (actualTypesCollection) => actualTypesCollection,
);

export const getActualRentalTypesCollectionRefs = createSelector(
  getActualRentalTypesCollection,
  (actualRentalTypesCollection) => actualRentalTypesCollection,
);

export const getExpensesCollectionRefs = createSelector(
  getSortingCountriesWithUsPriorityCollection,
  getStatesCollection,
  (countriesCollection, statesCollection) => ({
    countriesCollection,
    statesCollection,
  }),
);

export const getExtensionRequestCollectionRefs = createSelector(
  getLoanExtensionTermsCollection,
  getOccupanciesCollection,
  getLoanTransactionPurposesCollection,
  getExitStrategiesCollection,
  (
    loanExtensionTermsCollection,
    occupanciesCollection,
    loanTransactionPurposesCollection,
    exitStrategiesCollection,
  ) => ({
    loanExtensionTermsCollection,
    occupanciesCollection,
    loanTransactionPurposesCollection,
    exitStrategiesCollection,
  }),
);
