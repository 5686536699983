import ip from 'ip';
import * as R from 'ramda';

import { setInDefaultValue } from 'decorators';

function setDefaultInitialValues(input = {}) {
  return R.pipe(
    setInDefaultValue(ip.address(), 'application.borrower.signedFromIp'),
    setInDefaultValue(new Date(), 'application.borrower.signedDate'),
  )(input);
}

export { setDefaultInitialValues };
