import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withContext, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import createFocusDecorator from 'final-form-focus';
import { withRouter } from 'react-router-dom';

import * as actions from 'actions';
import * as requestTypes from 'constants/requestTypes';
import { requestInProcess } from 'selectors';

import { holdMutators } from 'utils';

import Form from './Form';
import { handlers, utils, decorator } from './shared';

const propTypes = {
  entitiesIsLoading: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    application: PropTypes.shape({
      borrowers: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
      entity: PropTypes.objectOf(PropTypes.any),
      dataEntryStep: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      token: PropTypes.string,
      type: PropTypes.string,
    }),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const focusOnError = createFocusDecorator();

function CompanyInformation({ entitiesIsLoading, initialValues, loading, onSubmit }) {
  return (
    <FinalForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      decorators={[focusOnError, decorator]}
      subscription={{ submitting: true, pristine: true }}
      mutators={{ ...arrayMutators, ...holdMutators }}
      loading={loading || entitiesIsLoading}
      component={Form}
    />
  );
}

CompanyInformation.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    entitiesIsLoading: requestInProcess(state, requestTypes.ENTITIES),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateApplicationFourthStep: bindActionCreators(actions.updateApplicationFourthStep, dispatch),
    fetchApplicationFourthStep: bindActionCreators(actions.fetchApplicationFourthStep, dispatch),
  };
}

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('initialValues', 'setInitialValues', utils.setDefaultInitialValues()),
  withState('loading', 'setLoading', false),
  withState('isFormDisabled', 'setIsFormDisabled', false),
  withHandlers({
    isFormDisabledSetter: handlers.isFormDisabledSetter,
  }),
  withHandlers({
    mergeInitialValues: handlers.mergeInitialValues,
    onSubmit: handlers.onSubmit,
  }),
  lifecycle({
    async componentDidMount() {
      const { match, fetchApplicationFourthStep, mergeInitialValues, setLoading } = this.props;
      const { token } = match.params;
      setLoading(true);
      await fetchApplicationFourthStep(token).then(mergeInitialValues);
      setLoading(false);
    },
  }),
  withContext(
    {
      isFormDisabled: PropTypes.bool,
    },
    ({ isFormDisabled }) => ({ isFormDisabled }),
  ),
  pure,
);

export default enhance(CompanyInformation);
