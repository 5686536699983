import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import ContentLoader from 'react-content-loader';

import { WithUser } from 'hocs';
import { userPropTypes, userDefaultProps } from 'propTypes';

import PrivateRoute from 'components/PrivateRoute';

import { LEAD_BORROWER_PATH, LEAD_PROPERTY_PATH } from 'constants/routes';
import { getPath } from 'utils';

import { NotPreQualified, NotQualified, InReview, Qualified } from '../LeadForm/shared';

import { BridgeStep2 } from './bridge';
import { RentalStep2, RentalStep3 } from './rental';

const propTypes = {
  application: PropTypes.objectOf(PropTypes.any).isRequired,
  ...userPropTypes,
};

const defaultProps = {
  ...userDefaultProps,
};

function LeadForm({ application, userLoading, user }) {
  const { isPreApproval, isBridge, isRental, leadStep, inReview, reject } = application;

  if (!userLoading && user) {
    if (reject && leadStep === 'complete') return <NotQualified />;
    if (reject) return <NotPreQualified />;
    if (inReview) return <InReview application={application} user={user} />;

    if (leadStep === 'complete') return <Qualified application={application} user={user} />;

    if (isBridge || isPreApproval) {
      return <BridgeStep2 application={application} />;
    }

    if (isRental) {
      return (
        <Switch>
          <PrivateRoute exact path={LEAD_PROPERTY_PATH} component={RentalStep2} />
          <PrivateRoute
            exact
            path={LEAD_BORROWER_PATH}
            component={(props) => {
              if (application.leadStep === 'property') {
                return <Redirect to={getPath(LEAD_PROPERTY_PATH, { token: application.token })} />;
              }
              return <RentalStep3 {...props} />;
            }}
          />
          <Route
            render={({
              match: {
                params: { token },
              },
            }) => <Redirect to={getPath(LEAD_BORROWER_PATH, { token })} />}
          />
        </Switch>
      );
    }
  }

  return <ContentLoader />;
}

LeadForm.propTypes = propTypes;
LeadForm.defaultProps = defaultProps;

const enhance = compose(
  withRouter,
  WithUser,
  pure,
);

export default enhance(LeadForm);
