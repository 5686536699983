import { createSelector } from 'reselect';
import * as R from 'ramda';

import { getIn } from 'utils/ramda';
import { getEntities } from 'selectors/entities';

import { displayApplication, decorateBorrower } from 'decorators';
import { sortApplicationCollection, filterApplicationsByStatus } from 'decorators/application';
import { decorateContact } from 'decorators/contacts';
import { getNewConstructionProductList } from 'selectors/refs';

export const initialState = {
  detail: null,
  collection: [],
};

export const filterApplicationByToken = (applications, token) => {
  if (!applications) return undefined;
  const index = R.findIndex(R.propEq('token', token), applications);
  return applications[index];
};

const getStatus = (_, status) => status;
const getToken = (_, token) => token;
const getPolicy = (_, policy) => policy;
const getBorrowerContactId = (_, { id }) => id;
const getPropertyId = (_, props) => props && props.match && props.match.params && props.match.params.propertyId;

const getApplications = (state) => state.applications || initialState;

export const getApplicationsCollection = createSelector(
  getApplications,
  getNewConstructionProductList,
  (applications, newConstructionProductList) => applications.collection.map(application => 
    displayApplication(application, newConstructionProductList)
  ),
);

export const getApplicationsCollectionWithDrawRequestAlowed = createSelector(
  getApplicationsCollection,
  R.filter(getIn('meta.availableToRequestDraw')),
);

export const getApplicationsDownloadLoanDocumentsAlowed = createSelector(
  getApplications,
  getIn('detail.meta.availableToDownloadLoanDocuments'),
);

export const getApplicationsSortedCollection = createSelector(
  getApplicationsCollection,
  sortApplicationCollection,
);

export const getApplicationsCollectionLength = createSelector(
  getApplicationsCollection,
  (collection) => collection.length,
);

export const getApplicationsSortedCollectionWithDrawRequestAlowed = createSelector(
  getApplicationsCollectionWithDrawRequestAlowed,
  sortApplicationCollection,
);

export const getIncompleteApplication = createSelector(
  getApplicationsCollection,
  (applications) => {
    const incompleStatuses = ['incomplete_app', 'lead'];
    const incompleteApplication = R.find(
      (app) => app.withdrawn === false && R.includes(app.status, incompleStatuses) && !app.reject,
      applications,
    );

    return incompleteApplication;
  },
);

export const getApplicationDetail = createSelector(
  getApplications,
  getNewConstructionProductList,
  (applications, newConstructionProductList) => {
    return displayApplication(applications.detail, newConstructionProductList);
  },
);

export const getApplicationsCollectionByStatus = createSelector(
  [getApplicationsCollection, getStatus],
  filterApplicationsByStatus,
);

export const getApplicationsSortedCollectionFilteredByStatus = createSelector(
  [getApplicationsCollectionByStatus, getStatus],
  sortApplicationCollection,
);

export const getApplicationsSortedCollectionByStatus = createSelector(
  [getApplicationsSortedCollection, getStatus],
  (applications, status) => filterApplicationsByStatus(applications, status),
);

export const getApplicationByToken = createSelector(
  [getApplicationsCollection, getToken],
  (applications, token) => filterApplicationByToken(applications, token),
);

// FIXME unite with getApplicationPropertyById. Need global store refactoring using normalizer
const propertyIdSelector = (state, id) => parseInt(id, 10); // FIXME (state, { propertyId }) instead exist
const getAllProperties = createSelector(
  getApplicationsCollection,
  (applications) =>
    R.unnest(applications.filter((application) => application.properties).map((application) => application.properties)),
);

export const getApplicationByPropertyId = createSelector(
  [getApplicationsCollection, propertyIdSelector],
  (applications, propertyId) => {
    const index = R.findIndex(
      R.pipe(
        R.propOr([], 'properties'),
        R.any(R.propEq('id', propertyId)),
      ),
      applications,
    );
    return applications[index];
  },
);

export const getApplicationPropertyByPropertyId = createSelector(
  [getAllProperties, propertyIdSelector],
  (properties, id) => {
    const index = R.findIndex(R.propEq('id', id), properties);
    return properties[index];
  },
);

export const getApplicationPropertyDrawAptNumberRequiredByPropertyId = createSelector(
  getApplicationPropertyByPropertyId,
  R.prop('drawAptNumberRequired'),
);
// end FIXME

// APPLICATION
export const getApplication = createSelector(
  getApplicationDetail,
  (application) => {
    if (application) {
      return application;
    }

    return undefined;
  },
);

export const getApplicationToken = createSelector(
  getApplicationDetail,
  (application) => (application ? application.token : undefined),
);

export const getApplicationProfileId = createSelector(
  getApplicationDetail,
  (application) => (application ? application.profileId : undefined),
);

export const getApplicationEntityId = createSelector(
  getApplicationDetail,
  (application) => (application ? application.entityId : undefined),
);

export const getApplicationEntity = createSelector(
  [getApplicationEntityId, getEntities],
  (id, entities) => R.find(R.propEq('id', id), entities),
);

export const getApplicationStatus = createSelector(
  getApplicationDetail,
  R.prop('status'),
);

export const getApplicationEntityName = createSelector(
  getApplicationEntity,
  R.prop('legalName'),
);

export const getApplicationLoanId = createSelector(
  getApplicationDetail,
  (application) => (application ? application.loanId : undefined),
);

export const getApplicationEntryStep = createSelector(
  getApplicationDetail,
  (application) => (application ? application.dataEntryStep : undefined),
);

export const getApplicationId = createSelector(
  getApplicationDetail,
  (application) => (application ? application.id : undefined),
);

export const getApplicationType = createSelector(
  getApplicationDetail,
  (application) => (application ? application.type : undefined),
);

export const getApplicationProductId = createSelector(
  getApplicationDetail,
  (application) => (application ? application.productId : undefined),
);

export const getIsPreApproval = createSelector(
  getApplicationType,
  R.equals('pre_approval'),
);

export const getApplicationProperties = createSelector(
  getApplicationDetail,
  R.propOr([], 'properties'),
);

export const getHasApplicationProperties = createSelector(
  getApplicationProperties,
  R.complement(R.isEmpty),
);

export const getApplicationPropertiesIds = createSelector(
  getApplicationProperties,
  R.map(R.prop('id')),
);

export const getApplicationPropertiesCollection = createSelector(
  getApplicationProperties,
  (properties) => {
    if (properties) {
      return properties.map(({ id, address }) => ({ label: address, value: id }));
    }
    return [];
  },
);

export const getApplicationActivePropertiesCollection = createSelector(
  getApplicationProperties,
  (properties) => {
    if (properties) {
      return properties
        .filter(({ active }) => active === true)
        .map(({ id, address }) => ({ label: address, value: id }));
    }
    return [];
  },
);

export const getApplicationBorrowers = createSelector(
  getApplicationDetail,
  (application) => {
    if (application && application.borrowers) {
      return application.borrowers.map(decorateBorrower);
    }
    return undefined;
  },
);

export const getApplicationBorrowerByContactId = createSelector(
  [getApplicationBorrowers, getBorrowerContactId],
  (borrowers, borrowerId) => {
    if (borrowers) {
      const index = R.findIndex(R.propEq('contactId', borrowerId), borrowers);
      return borrowers[index];
    }

    return undefined;
  },
);

export const getApplicationBorrowerScoreByContactId = createSelector(
  getApplicationBorrowerByContactId,
  (borrower) => {
    if (borrower) return getIn('score', borrower);
    return undefined;
  },
);

export const getApplicationBorrowersCollection = createSelector(
  getApplicationBorrowers,
  (borrowers) => {
    if (borrowers) {
      return borrowers.map(({ id, displayName }) => ({ label: displayName, value: id }));
    }
    return [];
  },
);

export const getApplicationCurrentBorrower = createSelector(
  getApplicationBorrowers,
  R.ifElse(
    R.isNil,
    R.always(undefined),
    R.pipe(
      R.filter(R.prop('isCurrent')),
      R.head,
    ),
  ),
);

export const getApplicationCurrentBorrowerId = createSelector(
  getApplicationCurrentBorrower,
  R.ifElse(R.isNil, R.always(undefined), R.prop('id')),
);

export const getApplicationCurrentBorrowerContactId = createSelector(
  getApplicationCurrentBorrower,
  R.ifElse(R.isNil, R.always(undefined), R.prop('contactId')),
);

export const getApplicationPropertyById = createSelector(
  [getApplicationDetail, getPropertyId],
  (application, propertyId) => {
    if (application && application.properties && application.properties.length > 0 && propertyId) {
      return R.find(R.propEq('id', parseInt(propertyId, 10)), application.properties);
    }
    return undefined;
  },
);

export const getApplicationPrimaryBorrower = createSelector(
  getApplicationDetail,
  (application) => {
    if (application && application.borrowers && application.borrowers.length > 0) {
      return R.find(R.propEq('isPrimary', true), application.borrowers);
    }
    return undefined;
  },
);

export const getApplicationTransactionPurpose = createSelector(
  getApplicationDetail,
  (application) => {
    if (application) return application.transactionPurpose;
    return undefined;
  },
);

export const getApplicationPolicy = createSelector(
  [getApplicationDetail, getPolicy],
  (application, policy) => getIn(`meta.${policy}`, application),
);

export const getArvLtv = createSelector(
  getApplicationDetail,
  (application) => getIn('arvLtv', application),
);

export const getMaxArvLtv = createSelector(
  getApplicationDetail,
  (application) => getIn('maxArvLtv', application),
);

export const getMinArvLtv = createSelector(
  getApplicationDetail,
  (application) => getIn('minArvLtv', application),
);

export const getApplicationLoanOfficer = createSelector(
  getApplicationDetail,
  R.prop('loanOfficer'),
);

export const getApplicationBuyPoints = createSelector(
  getApplicationDetail,
  R.prop('buyPoints'),
);

export const getApplicationAccountManager = createSelector(
  getApplicationDetail,
  R.prop('accountManager'),
);

export const getDecoratedApplicationLoanOfficer = createSelector(
  getApplicationLoanOfficer,
  decorateContact,
);

export const getDecoratedApplicationAccountManager = createSelector(
  getApplicationAccountManager,
  decorateContact,
);

export const getApplicationProjectCoordinator = createSelector(
  getApplicationDetail,
  R.prop('projectCoordinator'),
);
export const getApplicationCalendlyUrl = createSelector(
  getApplicationLoanOfficer,
  (loanOfficer) => {
    if (R.isNil(loanOfficer)) return undefined;
    return loanOfficer.calendlyUrl;
  },
);

export const getDecoratedApplicationProjectCoordinator = createSelector(
  getApplicationProjectCoordinator,
  decorateContact,
);

export const getApplicationTodo = createSelector(
  [getApplicationDetail, getIsPreApproval],
  (application, isPreApproval) => {
    const collection = R.propOr([], 'todo', application);
    if (isPreApproval) {
      return R.reject(R.equals('title_company'), collection);
    }
    return collection;
  },
);

export const getApplicationQuotes = createSelector(
  getApplicationDetail,
  R.propOr([], 'quotes'),
);

export const getApplicationSelectedQuote = createSelector(
  getApplicationQuotes,
  R.find(R.prop('selected')),
);

export const getApplicationAnotherDocuments = createSelector(
  getApplicationDetail,
  R.propOr([], 'anotherDocuments'),
);
