import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import quotes from './quotes.png';

const propTypes = {
  avatar: PropTypes.string.isRequired,
  cite: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  fromThe: PropTypes.string,
};

const defaultProps = {
  fromThe: undefined,
};

const Blockquote = ({ avatar, cite, fromThe, children, ...props }) => {
  return (
    <React.Fragment>
      <TestimonialAvatar src={avatar} />
      <blockquote>
        <Body>{children}</Body>
        {cite && (
          <Cite>
            {cite}
            {fromThe && <span>, {fromThe}</span>}
          </Cite>
        )}
      </blockquote>
    </React.Fragment>
  );
};

const TestimonialAvatar = styled.img`
  margin-bottom: 12px;
  border-radius: 100%;
`;

const Body = styled.div`
  text-align: left;
  font-size: 1rem;
  background: url(${quotes}) no-repeat left top;
  min-height: 42px;
  width: 100%;
  padding-left: 60px;
  padding-right: 30px;
  box-sizing: border-box;
  margin-bottom: 12px;
`;

const Cite = styled.cite`
  text-align: center;
  font-size: 0.875rem;
  color: #ee3733;
  span {
    color: initial;
  }
`;

Blockquote.propTypes = propTypes;
Blockquote.defaultProps = defaultProps;
export default Blockquote;
