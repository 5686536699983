import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import { space } from 'styled-system';

import Button from 'components/shared/Button';

const propTypes = {
  disabled: PropTypes.bool,
  onLoadFiles: PropTypes.func.isRequired,
  toExport: PropTypes.func.isRequired,
  isImporting: PropTypes.bool,
  isExporting: PropTypes.bool,
  isEditAddress: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
  isImporting: false,
  isExporting: false,
  isEditAddress: true,
};

function Import({ disabled, onLoadFiles, toExport, isImporting, isExporting, isEditAddress }) {
  const isDisabled = isImporting || isExporting || disabled;
  return (
    <div>
      {isEditAddress && (
        <>
          <Action
            disabled={isDisabled}
            bordered
            mr="0.8rem"
            onClick={toExport}
            palette="secondary"
            type="button"
            xs
          >
            Export Properties
          </Action>
          <Dropzone accept=".xlsx" multiple={false} onDrop={onLoadFiles} style={{ display: 'inline-block' }}>
            <Action type="button" bordered xs disabled={isDisabled}>
              {isImporting ? 'Please Wait...' : 'Update Import'}
            </Action>
          </Dropzone>
        </>
      )}
    </div>
  );
}

const Action = styled(Button)`
  ${space}
`;

Import.propTypes = propTypes;
Import.defaultProps = defaultProps;

export default Import;
