import { validateRequired } from 'utils';

function isUnitsCountRequired(value) {
  return value !== 'single_family_residential';
}

function unitsCountValidate(value, allValues, index) {
  if (!allValues.application.properties[index]) return undefined;

  const propertyTypeFieldValue = allValues.application.properties[index].type;

  if (propertyTypeFieldValue === 'single_family_residential') {
    return undefined;
  }
  if (!value) {
    return validateRequired()(value, allValues);
  }
  return undefined;
}

export { isUnitsCountRequired, unitsCountValidate };
