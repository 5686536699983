import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { APPLICATION_NEW_DRAW_REQUESTS_PATH } from 'constants/routes';

import { ToDo, Button } from 'components/shared';

import { getPath } from 'utils';

const propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

function DrawRequestToDo({ match }) {
  const { token } = match.params;
  return (
    <ToDo>
      <ToDo.Title>You can Request Draw</ToDo.Title>
      <ToDo.Actions>
        <Button to={getPath(APPLICATION_NEW_DRAW_REQUESTS_PATH, { token })} sm>
          Request Draw
        </Button>
      </ToDo.Actions>
    </ToDo>
  );
}

DrawRequestToDo.propTypes = propTypes;

export default withRouter(DrawRequestToDo);
