import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getApplication, getApplicationTodo } from 'selectors';

import { Tab, TabLink, Tabs } from 'components/shared';

import {
  APPLICATION_PATH,
  APPLICATION_STEP_PATH,
  APPLICATION_DOCUMENTS_PATH,
  APPLICATION_SCHEDULE_PATH,
  APPLICATION_CONSTRUCTION_BUDGET_PATH,
  APPLICATION_CONSTRUCTION_BUDGET_PROPERTY_PATH,
  APPLICATION_CONTACTS_PATH,
  APPLICATION_DRAW_REQUESTS_PATH,
} from 'constants/routes';
import { getPath } from 'utils';

const propTypes = {
  application: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  applicationTodo: PropTypes.arrayOf(PropTypes.any),
};

const defaultProps = {
  application: undefined,
  applicationTodo: [],
};

function ApplicationTabs({ match, application, applicationTodo }) {
  const { token } = match.params;

  const isApplicationDrawRequestsExist = R.includes('draw_request', applicationTodo);
  return (
    <Tabs>
      <Tab active={match.path === APPLICATION_PATH}>
        <TabLink active={match.path === APPLICATION_PATH} to={getPath(APPLICATION_PATH, { token })}>
          Home
        </TabLink>
      </Tab>

      {application && application.meta && application.meta.availableToEdit && (
        <Tab active={match.path === APPLICATION_STEP_PATH}>
          <TabLink
            active={match.path === APPLICATION_STEP_PATH}
            to={getPath(APPLICATION_STEP_PATH, { token, step: application.dataEntryStep })}
          >
            View/Edit App
          </TabLink>
        </Tab>
      )}

      <Tab active={match.path === APPLICATION_DOCUMENTS_PATH}>
        <TabLink active={match.path === APPLICATION_DOCUMENTS_PATH} to={getPath(APPLICATION_DOCUMENTS_PATH, { token })}>
          Documents
        </TabLink>
      </Tab>

      <Tab active={match.path === APPLICATION_SCHEDULE_PATH}>
        <TabLink active={match.path === APPLICATION_SCHEDULE_PATH} to={getPath(APPLICATION_SCHEDULE_PATH, { token })}>
          Real Estate Experience
        </TabLink>
      </Tab>

      <Tab active={match.path === APPLICATION_CONTACTS_PATH}>
        <TabLink active={match.path === APPLICATION_CONTACTS_PATH} to={getPath(APPLICATION_CONTACTS_PATH, { token })}>
          Deal Contacts
        </TabLink>
      </Tab>

      {application.meta && application.meta.availableToShowConstructionBudget && (
        <Tab
          active={
            match.path === APPLICATION_CONSTRUCTION_BUDGET_PATH ||
            match.path === APPLICATION_CONSTRUCTION_BUDGET_PROPERTY_PATH
          }
        >
          <TabLink
            active={match.path === APPLICATION_CONSTRUCTION_BUDGET_PATH}
            to={getPath(APPLICATION_CONSTRUCTION_BUDGET_PATH, { token })}
          >
            Construction Budget
          </TabLink>
        </Tab>
      )}
      {isApplicationDrawRequestsExist && (
        <Tab active={match.path === APPLICATION_DRAW_REQUESTS_PATH}>
          <TabLink
            active={match.path === APPLICATION_DRAW_REQUESTS_PATH}
            to={getPath(APPLICATION_DRAW_REQUESTS_PATH, { token })}
          >
            Draw Requests
          </TabLink>
        </Tab>
      )}
    </Tabs>
  );
}

ApplicationTabs.propTypes = propTypes;
ApplicationTabs.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  const application = getApplication(state);

  return {
    application,
    applicationTodo: getApplicationTodo(state),
  };
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
  pure,
);

export default enhance(ApplicationTabs);
