import React from 'react';
import ScrollToTopOnMount from 'hocs/ScrollToTopOnMount';
import styled from 'styled-components';

import { PageTemplate } from 'components/templates';
import { FormAuthSignIn } from 'components/blocks/Forms/Auth';
import { Link, Title, Subtitle, Wrapper } from 'components/shared';
import { RESET_PASS } from 'constants/routes';

const SignInPage = () => (
  <PageTemplate>
    <Wrapper>
      <StyledTitle>Hello and welcome back!</StyledTitle>
      <StyledSubtitle>
        You have reached this page because you have an existing account with REI Loans.
        <br />
        Please enter your username and password below and you will be able to proceed with your application.
        <br />
        If you have forgotten your password, please click <StyledLink to={RESET_PASS}>here</StyledLink> to reset it.
      </StyledSubtitle>
      <FormAuthSignIn />
    </Wrapper>
  </PageTemplate>
);

const StyledTitle = styled(Title)`
  margin-top: 30px;
`;

const StyledSubtitle = styled(Subtitle)`
  margin-bottom: 20px;
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

export default ScrollToTopOnMount(SignInPage);

