import { Children } from 'react';

function getNames({ children }) {
  let names = [];
  function getNamesFromChildren(inputChildren) {
    Children.forEach(inputChildren, (child) => {
      if (!child) return;
      if (!child.props) return;
      const { name, isConditional } = child.props;
      if (name && isConditional) {
        names = [...names, name];
        return;
      }
      if (child.props.children) getNamesFromChildren(child.props.children);
    });
  }
  getNamesFromChildren(children);
  return names;
}

export { getNames };
