import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { palette } from 'styled-tools';

const propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: null,
  active: false,
};

function Tab({ active, children, className }) {
  return (
    <Container active={active} className={className}>
      {children}
    </Container>
  );
}

const Container = styled.li`
  display: block;
  font-size: 0.875rem;
  border-bottom: 0.125rem solid ${({ active }) => (active ? palette('primary', 0) : 'transparent')};
  display: inline-block;
  padding: 0.625rem 0;
`;

Tab.propTypes = propTypes;
Tab.defaultProps = defaultProps;

export default styled(Tab)``;
