import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { palette } from 'styled-tools';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  ordered: PropTypes.bool,
  palette: PropTypes.string,
};

const defaultProps = {
  children: undefined,
  ordered: false,
  palette: 'grayscale',
};

const styles = css`
  margin: 1rem 0;
  padding-left: 1.6rem;
  line-height: 1.7rem;
  color: ${palette('grayscale', 1)};
`;

const Ol = styled.ol`
  ${styles};
`;
const Ul = styled.ul`
  ${styles};
`;

const ListLi = styled.li`
  margin: 0 0 1rem;
  line-height: 135%;
`;

const List = ({ ordered, children, ...props }) => React.createElement(ordered ? Ol : Ul, props, children);

List.propTypes = propTypes;
List.defaultProps = defaultProps;

export { ListLi };
export default List;
