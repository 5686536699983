import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { lensPath, view } from 'ramda';

import { getApplicationPropertyDrawAptNumberRequiredByPropertyId } from 'selectors';
import { FormFlex, FormBox, FormGroupAdapter } from 'components/shared/form';

import { WithFinalFormValues } from 'hocs';
import { validateRequired } from 'utils';

const propTypes = {
  name: PropTypes.string.isRequired,
  drawAptNumberRequired: PropTypes.bool,
};

const defaultProps = {
  drawAptNumberRequired: false,
};

function InspectionUnitField({ name, drawAptNumberRequired }) {
  if (drawAptNumberRequired) {
    return (
      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={name}
            label="Please provide the apt./unit number(s) which you would like to be inspected"
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>
    );
  }
  return null;
}

InspectionUnitField.propTypes = propTypes;
InspectionUnitField.defaultProps = defaultProps;

function mapStateToProps(state, props) {
  const propertyIdLens = lensPath(['values', 'drawRequest', 'propertyId']);
  const propertyId = view(propertyIdLens, props);
  return {
    drawAptNumberRequired: getApplicationPropertyDrawAptNumberRequiredByPropertyId(state, propertyId),
  };
}

const enhance = compose(
  WithFinalFormValues,
  connect(mapStateToProps),
  pure,
);

export default enhance(InspectionUnitField);
