import React, { Fragment } from 'react';
import { Field } from 'react-final-form';

import { FormFlex, FormBox, FormGroupAdapter } from 'components/shared/form';

import { validateRequired } from 'utils';

function Fields() {
  return (
    <Fragment>
      <FormFlex>
        <FormBox width={1}>
          <Field
            name="payoffRequest.expectedDate"
            label="Expected Payoff Date"
            type="date"
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="payoffRequest.comment"
            label="Comment"
            type="textarea"
            rows={8}
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>
    </Fragment>
  );
}

export default Fields;
