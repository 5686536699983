import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, pure, getContext } from 'recompose';
import { connect } from 'react-redux';
import { Field, useField } from 'react-final-form';
import { useParams } from 'react-router-dom';
import * as R from 'ramda';

import { bridgeImportTemplateUrl } from 'config';
import * as applicationActions from 'actions/applications';
import { getBridgeSecondStepRefs } from 'selectors/refs';
import useAsyncAction from 'hooks/useAsyncAction';
import * as fp from 'utils/ramda';

import { Alert } from 'components/shared';
import { FormBox, FormGroupAdapter, Condition, FormAsterisk, NarrowFormFlex } from 'components/shared/form';
import SubmitErrors from 'components/shared/form/SubmitErrors';
import Import from 'components/shared/form/PropertyImport';

import { BRIDGE_STEP_2_FIELDS_CONDITION } from 'constants/forms';
import { validateRequired } from 'utils';

import StepFields from './StepFields';

const propTypes = {
  refs: PropTypes.shape({
    applicationTransactionPurposeCollection: PropTypes.arrayOf(PropTypes.object),
  }),
  toImport: PropTypes.func.isRequired,
  isImporting: PropTypes.bool,
  importWarnings: PropTypes.shape({}),
  importErrors: PropTypes.shape({}),

};

const defaultProps = {
  refs: {
    applicationTransactionPurposeCollection: [],
  },
  isImporting: false,
  importWarnings: undefined,
  importErrors: undefined,
};

function Fields({ refs, toImport, isImporting, importWarnings, importErrors }) {
  const transactionPurposeField = useField('application.transactionPurpose');
  const transactionPurpose = R.pathOr({}, ['input', 'value'], transactionPurposeField);
  const { token } = useParams();

  const [updateTransactionPurpose] = useAsyncAction(applicationActions.updateTransactionPurpose, []);

  const onLoadFiles = async (files) => {
    await updateTransactionPurpose(token, transactionPurpose);

    toImport({
      import: {
        asset: files[0],
        type: 'bridge_properties',
      },
    });
  };

  return (
    <Fragment>
      <NarrowFormFlex>
        <FormBox width={1}>
          <Field
            name="application.transactionPurpose"
            label="What is the purpose of the loan?"
            type="select"
            options={refs.applicationTransactionPurposeCollection}
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </NarrowFormFlex>

      <Condition when="application.transactionPurpose" is={BRIDGE_STEP_2_FIELDS_CONDITION}>
        <NarrowFormFlex justifyContent="flex-end">
          <FormBox mt="1.25rem" mb="1rem">
            <Import
              onLoadFiles={onLoadFiles}
              isImporting={isImporting}
              importTemplateUrl={bridgeImportTemplateUrl}
            />
          </FormBox>
        </NarrowFormFlex>

        {fp.isDefined(importErrors) && (
          <NarrowFormFlex>
            <FormBox width={1} mb="1.25rem">
              <Alert palette="danger" colorPalette="secondary" size="sm">
                <SubmitErrors errors={importErrors} />
              </Alert>
            </FormBox>
          </NarrowFormFlex>
        )}

        {fp.isDefined(importWarnings) && (
          <NarrowFormFlex>
            <FormBox width={1} mb="1.25rem">
              <Alert palette="warning" colorPalette="warning" size="sm" colorTone={0}>
                <SubmitErrors errors={importWarnings} />
              </Alert>
            </FormBox>
          </NarrowFormFlex>
        )}

        <StepFields refs={refs} />
      </Condition>

      <Condition when="application.transactionPurpose" is={['', 'pre_approval']}>
        <NarrowFormFlex>
            <FormBox width={1}>
              <Field
                name="application.legalName"
                required
                validate={validateRequired()}
                label="What is the name of your LLC or Company that owns or will own the Property?"
                component={FormGroupAdapter}
              />
            </FormBox>
          </NarrowFormFlex>
      </Condition>

      <NarrowFormFlex>
        <FormBox width={1}>
          <FormAsterisk>
            All red asterisks are required in order to provide you with an accurate rate quote.
          </FormAsterisk>
        </FormBox>
      </NarrowFormFlex>
    </Fragment>
  );
}

Fields.propTypes = propTypes;
Fields.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    refs: getBridgeSecondStepRefs(state),
  };
}

const enhance = compose(
  connect(mapStateToProps),
  getContext({ fetchInitialValues: PropTypes.func }),
  pure,
);

export default enhance(Fields);
