import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'ramda';

const propTypes = {
  title: PropTypes.string,
  width: PropTypes.number,
  className: PropTypes.string,
};

const defaultProps = {
  title: '',
  width: 0,
  className: undefined,
};

function ProgressBar({ title, className, width }) {
  return (
    <div className={className}>
      {title && <Title>{title}</Title>}
      <Bar width={width} />
    </div>
  );
}

const Bar = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0.375rem;
  background: #f5f5f5;
  border-radius: 0.5rem;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: ${prop('width')}%;
    background: #00bd95;
    border-radius: 0.5rem;
  }
`;

const Title = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const StyledProgressBar = styled(ProgressBar)``;

ProgressBar.propTypes = propTypes;
ProgressBar.defaultProps = defaultProps;
ProgressBar.Bar = Bar;

export default StyledProgressBar;
