import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { ifProp, prop } from 'styled-tools';

import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';

const propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  responsive: PropTypes.bool,
  breakpoint: PropTypes.number,
  collapsed: PropTypes.bool,
  right: PropTypes.bool,
  height: PropTypes.number,
  children: PropTypes.node,
};

const defaultProps = {
  className: undefined,
  breakpoint: 420,
  responsive: false,
  collapsed: false,
  right: false,
  height: null,
  children: null,
};

function IconButton({ className, icon, children, collapsed, right, responsive, breakpoint, height, ...props }) {
  const iconElement = <StyledIcon height={height ? height / 2.5 : undefined} icon={icon} />;
  return (
    <Button className={className} {...props}>
      <Wrapper>
        {right || iconElement}
        {children && (
          <Text right={right} className="text" responsive={responsive} breakpoint={breakpoint}>
            {children}
          </Text>
        )}
        {right && iconElement}
      </Wrapper>
    </Button>
  );
}

const fadeIn = keyframes`
  0% { display: none; opacity: 0; }
  1% { display: block; opacity: 0; }
  100% { display: block; opacity: 1; }
`;

const StyledIconButton = styled(IconButton)`
  max-width: ${({ children, collapsed }) => (children && !collapsed ? '100%' : '2.5em')};
  width: ${({ children }) => (children ? 'auto' : '2.5em')};
  flex: 0 0 2.5em;
  box-sizing: border-box;
  ${ifProp(
    'collapsed',
    css`
      overflow: hidden;
      transition: max-width 250ms ease-in-out;
      will-change: max-width;
      & .text {
        display: none;
      }
      &:hover {
        max-width: 100%;
        & .text {
          display: block;
          animation: ${fadeIn} 250ms;
        }
      }
    `,
  )} ${ifProp(
    'responsive',
    css`
      @media screen and (max-width: ${prop('breakpoint')}px) {
        width: auto;
        flex: 0 !important;
      }
    `,
  )};
`;

const Text = styled.span`
  padding-right: ${ifProp('right', '0.4375em', 0)};
  padding-left: ${ifProp('right', 0, '0.4375em')};
  @media screen and (max-width: ${prop('breakpoint')}px) {
    display: ${ifProp('responsive', 'none !important')};
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const StyledIcon = styled(Icon)`
  flex: none;
`;

IconButton.propTypes = propTypes;
IconButton.defaultProps = defaultProps;

export default StyledIconButton;
