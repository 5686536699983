import PropTypes from 'prop-types';

export const applicationBuyPoints = PropTypes.shape({
  points: PropTypes.number,
  maxUpPoints: PropTypes.number,
  maxDownPoints: PropTypes.number,
  maxAvailableDownPoints: PropTypes.number,
  maxAvailableUpPoints: PropTypes.number,
  step: PropTypes.number,
});

export const applicationPropType = PropTypes.shape({
  id: PropTypes.number,
  internalId: PropTypes.string,
  status: PropTypes.string,
  totalAmount: PropTypes.string,
  monthlyPayment: PropTypes.string,
  maximumMonthlyPayment: PropTypes.string,
  udpatedAt: PropTypes.string,
  submittedToInitialUnderwritingAt: PropTypes.string,
  settlementDate: PropTypes.string,
  properties: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  meta: PropTypes.objectOf(PropTypes.bool),
  displayType: PropTypes.string,
  displaySubmittedOn: PropTypes.string,
  displaySubmittedBy: PropTypes.string,
  maturityDate: PropTypes.string,
});

export const applicationPropTypesRequired = {
  application: applicationPropType.isRequired,
};

export const applicationPropTypes = {
  application: applicationPropType,
};

export const applicationDefaultProps = {
  application: null,
};
