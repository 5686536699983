import React from 'react';
import styled from 'styled-components';
import { compose, lifecycle, pure } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import ContentLoader from 'react-content-loader';

import * as actions from 'actions';
import { getApplication, getUserContactId, getApplicationBorrowerScoreByContactId } from 'selectors';

import { FormHeader } from 'components/shared/form';
import { Congratulation, Strong, Link, Paragraph } from 'components/shared';
import Well, { WellHeader, WellBody } from 'components/shared/Well';

import { displayDataLayerEventCompleted } from 'decorators';
import { WithSettings } from 'hocs';
import ScrollToTopOnMount from 'hocs/ScrollToTopOnMount';
import { CONTACT_US, FAQS_URL } from 'constants/routes';
import { decimalCurrencyFormat } from 'utils';

function NotPreQualified(props) {
  const { settings, settingsIsLoading } = props;
  if (settingsIsLoading || !settings) {
    return <ContentLoader />;
  }

  const minBridgeLoanAmount =
    settings && settings.minLoanAmount && decimalCurrencyFormat(settings.minLoanAmount.bridge);
  const minRentalLoanAmount =
    settings && settings.minLoanAmount && decimalCurrencyFormat(settings.minLoanAmount.rental);

  return (
    <Well>
      <WellHeader key="header">
        <FormHeader title="Apply For Fix/Flip or Bridge Loan" />
      </WellHeader>

      <WellBody key="body">
        <StyledNotPreQualified>
          <StyledCongratulation status="warning">
            <Strong>Thank you for submitting your application.</Strong>
            <br />
            <Strong>At this time, you may not qualify for one of the following reasons:</Strong>
          </StyledCongratulation>
          <StyledUl>
            <StyledLi>
              <Strong>Down Payment:</Strong> Typically we require between 15-25% in of the total loan amount in
              available cash
            </StyledLi>
            <StyledLi>
              <Strong>Minimum Loan Amount: </Strong>
              {`Fix/Flip: ${minBridgeLoanAmount} and Rental: ${minRentalLoanAmount}`}
            </StyledLi>
            <StyledLi>
              <Strong>Property/Deal:</Strong> The property or transaction might be outside of our current guidelines
            </StyledLi>
            <StyledLi>
              <Strong>Real Estate Experience:</Strong> We do provide loans to 1st time investors, however we will need a
              higher credit score, cash or income.
            </StyledLi>
            <StyledLi>
              <Strong>Credit Profile:</Strong> Your credit score, income or assets might not meet our current
              requirements.
            </StyledLi>
            <StyledLi>
              <Strong>Out of Lending Territory:</Strong> We currently do not lend in: Alaska, Nevada, North Dakota and South Dakota.
            </StyledLi>
          </StyledUl>
          <Paragraph>
            If you meet the above criteria, please feel free to{' '}
            <Link to={CONTACT_US} target="_blank" method="POST">
              contact us
            </Link>{' '}
            so that we may reevaluate your approval.
          </Paragraph>
          <Paragraph>
            Your business is important to us, so if there's any other way we can assist you, please let us know. In the
            interim, you may review our{' '}
            <Link to={FAQS_URL} target="_blank">
              <Strong>FAQs</Strong>
            </Link>{' '}
            and utilize any of our free online resources that can provide you with valuable information to help you grow
            and expand your real estate ventures and improve your business.
          </Paragraph>
          <Paragraph>
            Best wishes,
            <br />
            <Strong>The LendingOne Team</Strong>
          </Paragraph>
        </StyledNotPreQualified>
      </WellBody>
    </Well>
  );
}

const StyledNotPreQualified = styled.div`
  margin: auto;
  max-width: 660px;
  font-size: 1.125rem;
  line-height: 1.6em;
  color: #666;
`;

const StyledCongratulation = styled(Congratulation)`
  padding: 30px 0 50px;
  line-height: 135%;
`;

const StyledUl = styled.ul`
  maring: 0;
`;

const StyledLi = styled.li`
  margin: 0 0 20px;
  line-height: 135%;
  list-style-type: disk;
`;

function mapStateToProps(state) {
  const userContactId = getUserContactId(state);
  const borrowerScore = getApplicationBorrowerScoreByContactId(state, { id: userContactId });
  const application = getApplication(state);
  return {
    application,
    userContactId,
    borrowerScore,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dataLayerPush: bindActionCreators(actions.dataLayerPush, dispatch),
    instantQuoteCompleteEvent: bindActionCreators(actions.instantQuoteCompleteEvent, dispatch),
  };
}

const enhance = compose(
  withRouter,
  WithSettings,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  lifecycle({
    componentDidMount() {
      const { application, userContactId, borrowerScore, dataLayerPush, instantQuoteCompleteEvent } = this.props;
      dataLayerPush({
        loanType: application.type,
        loanStatus: 'rejected',
        userId: userContactId,
        bSc: borrowerScore,
        event: displayDataLayerEventCompleted(application),
      });
      instantQuoteCompleteEvent({ type: application.type, status: 'Rejected' });
    },
  }),
  ScrollToTopOnMount,
  pure,
);

export default enhance(NotPreQualified);
