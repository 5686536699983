import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { AddressSelect } from 'components/shared/formControls';

class AddressInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    height: PropTypes.number,
  };

  static defaultProps = {
    className: 'ant-input',
    height: 26,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      editable: this.props.editable ? this.props.editable : false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value && !this.props.editable) {
      this.setState({
        ...this.state,
        value: nextProps.value,
        editable: false,
      });
    }
  }
  handleChange = (value) => {
    this.setState({ value });
    this.props.onChange(value);
  };

  check = () => {
    this.setState({ editable: false });
    if (this.props.onChange) {
      this.props.onChange(this.state.value);
    }
  };
  edit = () => {
    this.setState({ editable: true });
  };
  render() {
    const { value } = this.state;
    const { className, type, height } = this.props;
    return (
      <div className={`reschedule_table__cell ${className}`}>
        <div className="editable-cell-input-wrapper">
          <AddressSelect
            value={value}
            onChange={this.handleChange}
            height={height}
            type={type}
            autoFocus
            onPressEnter={this.check}
            simple={false}
          />
        </div>
      </div>
    );
  }
}

export default AddressInput;
