import { css } from 'styled-components';
import { ifProp, palette } from 'styled-tools';

const fontSize = ({ rate }) => `${rate / 35.5555555556}rem`;

const ControlStyles = css`
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.5em;
  padding: ${ifProp('sm', '5px', '0.625rem 10px')};
  font-size: ${ifProp('sm', '14px', fontSize)};
  font-weight: 400;
  line-height: 1.42857;
  color: ${palette('grayscale', 0)};
  outline: 0;
  background-color: ${ifProp('invalid', palette('danger', 3), palette('white', 0))};
  border: 1px solid ${ifProp('invalid', palette('danger', 1), palette('grayscale', 3))};
  border-radius: 3px;
  box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  font-style: ${ifProp('italic', 'italic')};

  ${ifProp(
    'cursive',
    css`
      font-family: 'Seaweed Script', cursive;
      font-size: 1.5em;
    `,
  )}

  &:focus,
  &:active {
    border: 1px solid ${ifProp('invalid', palette('danger', 0), palette('primary', 0))};
    outline: 0;
  }

  ${ifProp(
    'disabled',
    css`
      color: hsl(0, 0%, 60%);
      cursor: not-allowed;
      background-color: #dadbdb;
      opacity: 1;
    `,
  )};
`;

export default ControlStyles;
