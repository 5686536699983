import React from 'react';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { FormBox, FormGroupAdapter, FormFlex } from 'components/shared/form';
import { Textarea } from 'components/shared/formControls';

import { DROW_REQUEST_AGREEMENT } from 'constants/forms';
import { validateRequired } from 'utils';

import ArrayFields from './ArrayFields';

function BankDetails() {
  return (
    <Container>
      <FieldArray subscription={{ initial: true }} name="drawRequest.bankDetails" component={ArrayFields} />

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="drawRequest.agreement"
            type="checkbox"
            label="I Agree"
            inlineGroup
            labelOrder={1}
            errorOrder={2}
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <StyledTextarea rows={8} disabled value={DROW_REQUEST_AGREEMENT} />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="drawRequest.agreedBy"
            label="Agreed and Consented"
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>
    </Container>
  );
}

const Container = styled.div``;

const StyledTextarea = styled(Textarea)`
  margin-bottom: 1.25rem;
`;

export default BankDetails;
