import React from 'react';
import { compose, pure, lifecycle } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from 'actions';

import { ApplicationDashboard } from 'components/blocks';
import { UserHeader } from 'components/blocks/User';

import { UserTemplate } from 'components/templates';

import { ScrollToTopOnMount } from 'hocs';

import { ApplicationHeader } from '../shared';

const propTypes = {};

const defaultProps = {};

function ApplicationDashboardPage() {
  return (
    <UserTemplate header={<UserHeader />}>
      <ApplicationHeader />
      <ApplicationDashboard />
    </UserTemplate>
  );
}

ApplicationDashboardPage.propTypes = propTypes;
ApplicationDashboardPage.defaultProps = defaultProps;

function mapDispatchToProps(dispatch) {
  return {
    fetchConditions: bindActionCreators(actions.fetchConditions, dispatch),
    fetchProcessorNotes: bindActionCreators(actions.fetchProcessorNotes, dispatch),
    fetchApplication: bindActionCreators(actions.fetchApplication, dispatch),
    fetchContactTypes: bindActionCreators(actions.fetchContactTypes, dispatch),
  };
}

const enhance = compose(
  ScrollToTopOnMount,
  withRouter,
  connect(
    null,
    mapDispatchToProps,
  ),
  lifecycle({
    UNSAFE_componentWillMount() {
      const { fetchConditions, fetchApplication, fetchContactTypes, fetchProcessorNotes, match } = this.props;
      const { token } = match.params;
      fetchConditions(token);
      fetchApplication(token);
      fetchContactTypes(token);
      fetchProcessorNotes(token);
    },
  }),
  pure,
);

export default enhance(ApplicationDashboardPage);
