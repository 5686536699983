import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as R from 'ramda';

import { getTargetDocumentsByCategoryId } from 'selectors/documents';

import File from './File';

const propTypes = {
  className: PropTypes.string,
  files: PropTypes.arrayOf(PropTypes.object),
};

const defaultProps = {
  className: undefined,
  files: undefined,
};

function Files({ className, files }) {
  if (R.isNil(files) || R.isEmpty(files)) return null;

  return (
    <Container className={className}>
      {files.map((file) => (
        <StyledFile key={`filename-${file.id}`} file={file} />
      ))}
    </Container>
  );
}

const Container = styled.div``;

const StyledFile = styled(File)`
  padding-bottom: 0.4rem;

  &:last-child {
    padding-bottom: 0;
  }
`;

Files.propTypes = propTypes;
Files.defaultProps = defaultProps;

const mapStateToProps = (state, { categoryId, targetId }) => {
  const files = getTargetDocumentsByCategoryId(state, { categoryId, targetId });

  return {
    files,
  };
};

export default connect(mapStateToProps)(Files);
