import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'react-final-form';

import { Button } from 'components/shared';
import { FormGroupAdapter, FormFlex, FormBox, FormLegend } from 'components/shared/form';

import { validateRequired, validateEmpty, composeValidators } from 'utils';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  metadata: PropTypes.objectOf(PropTypes.any),
  submitting: PropTypes.bool.isRequired,
};

const defaultProps = {
  metadata: undefined,
};

function Form({ handleSubmit, metadata, submitting }) {
  return (
    <form>
      <Container>
        <FormFlex>
          <FormBox width={1}>
            <StyledLegend>Upload your documents here</StyledLegend>
          </FormBox>
        </FormFlex>

        <FormFlex>
          <FormBox width={1}>
            <Field
              name="document.asset"
              label="File"
              type="dropzone"
              required
              validate={composeValidators(validateRequired(), validateEmpty())}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>

        <FormFlex>
          <FormBox width={1}>
            <Field name="document.description" label="Comments (optional)" component={FormGroupAdapter} />
          </FormBox>
        </FormFlex>

        {metadata && metadata.taxYear && (
          <FormFlex>
            <FormBox width={1}>
              <Field
                name="document.metadata.taxYear"
                required
                validate={validateRequired()}
                label={metadata.taxYear.label}
                type="select"
                options={metadata.taxYear.values}
                component={FormGroupAdapter}
              />
            </FormBox>
          </FormFlex>
        )}

        {metadata && metadata.type && (
          <FormFlex>
            <FormBox width={1}>
              <Field
                name="document.metadata.type"
                required
                validate={validateRequired()}
                label={metadata.type.label}
                type="select"
                options={metadata.type.values}
                component={FormGroupAdapter}
              />
            </FormBox>
          </FormFlex>
        )}

        <FormFlex>
          <FormBox width={1}>
            <Button onClick={handleSubmit} dataName="saveButton" rounded disabled={submitting}>
              {submitting ? 'Please wait…' : 'Save'}
            </Button>
          </FormBox>
        </FormFlex>
      </Container>
    </form>
  );
}

const Container = styled.div`
  padding: 1rem;
`;

const StyledLegend = styled(FormLegend)`
  margin-bottom: 1rem;
`;

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

export default Form;
