import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';

import { ScrollToTopOnMount } from 'hocs';

import Alert from '../../Alert';
import FormFlex from '../Flex';
import FormBox from '../Box';
import FormGroup from '../FormGroup';

const propTypes = {
  palette: PropTypes.string,
  size: PropTypes.string,
  error: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  palette: 'danger',
  size: 'sm',
  className: undefined,
};

function SubmitError({ className, palette, size, error }) {
  return (
    <FormFlex className={className}>
      <FormBox width={1}>
        <FormGroup>
          <Alert palette={palette} size={size} message={error} />
        </FormGroup>
      </FormBox>
    </FormFlex>
  );
}

SubmitError.propTypes = propTypes;
SubmitError.defaultProps = defaultProps;

const enhance = compose(
  ScrollToTopOnMount,
  pure,
);

export default enhance(SubmitError);
