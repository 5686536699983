import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, palette as toolPalette } from 'styled-tools';
import * as R from 'ramda';

import Icon from 'components/shared/Icon';

import { styleUtils } from 'utils';

const propTypes = {
  children: PropTypes.node,
  colorPalette: PropTypes.string,
  colorTone: PropTypes.number,
  innerHtml: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  palette: PropTypes.string,
  tone: PropTypes.number,
};

const defaultProps = {
  children: undefined,
  colorPalette: undefined,
  colorTone: 1,
  innerHtml: undefined,
  message: undefined,
  onClose: undefined,
  palette: 'primary',
  tone: 3,
};

const alertStyleSize = R.curry(styleUtils.styleSize)('size');
const alertFontSize = alertStyleSize('font');

function Alert({
  children,
  colorPalette,
  colorTone,
  innerHtml,
  message,
  onClose,
  palette,
  size,
  tone,
}) {
  const html = innerHtml ? (<div dangerouslySetInnerHTML={{ __html: innerHtml }} />) : undefined;
  return (
    <StyledAlert
      size={size}
      palette={palette}
      tone={tone}
      colorTone={colorTone}
      colorPalette={colorPalette || palette}
      withCross={R.not(onClose)}
    >
      {html || children || message || 'Message'}
      {onClose && (
        <Close size={size} onClick={onClose}>
          <StyledIcon tone={colorTone} palette={colorPalette || palette} icon="cross" />
        </Close>
      )}
    </StyledAlert>
  );
}

Alert.propTypes = propTypes;
Alert.defaultProps = defaultProps;

const StyledAlert = styled.div`
  position: relative;
  display: block;
  font-size: ${alertFontSize(1)};
  color: ${({ colorPalette, colorTone }) => toolPalette(colorPalette, colorTone)};
  background-color: ${toolPalette()};
  ${ifProp(
    'onChange',
    css`
      padding: 1em 3em 1em 1em;
    `,
    css`
      padding: 1em;
    `,
  )};
  border-radius: 0.1875em;
  letter-spacing: 0px;
`;

const Close = styled.a`
  position: absolute;
  top: 50%;
  right: 1em;
  display: block;
  margin-top: -0.5em;
  width: 1em;
  height: 1em;
  outline: none;
  border: none;
  cursor: pointer;
`;

const StyledIcon = styled(Icon)`
  display: block;
  margin: 0;
  width: 100%;
  height: 100%;
  color: ${toolPalette()};
`;

export default Alert;
