function decrementRestHandler({ rest, setRest }) {
  return () => {
    if (rest > 0) setRest(rest - 1);
  };
}

function logoutHandler({ logoutAction, closeModal }) {
  return () => {
    logoutAction();
    closeModal();
  };
}

function refreshHandler({ refrashAction, closeModal }) {
  return () => {
    refrashAction();
    closeModal();
  };
}

export { decrementRestHandler as decrementRest, logoutHandler as logout, refreshHandler as refresh };
