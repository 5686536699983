import styled from 'styled-components';

const Hr = styled.hr`
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 0;
  border-top: 0.0625rem solid #e8e8e8;
`;

export default Hr;
