import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers } from 'recompose';
import styled from 'styled-components';
import { components } from 'react-select';
import { palette } from 'styled-tools';
import { view, lensPath, pipe } from 'ramda';

import { Icon } from 'components/shared';

import { copyTextToClipboard } from 'utils/others';

const propTypes = {
  onCopy: PropTypes.func.isRequired,
};

function IndicatorsContainer({ onCopy, ...props }) {
  return (
    <Container>
      <Copy type="button" onClick={onCopy}>
        <StyledIcon palette="grayscale" icon="copy" />
      </Copy>
      <components.IndicatorsContainer {...props} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  display: block;
  cursor: pointer;
  font-size: 1rem;
  color: ${palette(3)};
  transition color 150ms ease;

  &:hover {
    color: ${palette(2)};
  }

  .react-select__control--is-focused & {
    color: ${palette(1)};
  }
`;

const Copy = styled.button`
  display: block;
  padding: 0;
  background: none;
  border: none;
  outline: none;
`;

IndicatorsContainer.propTypes = propTypes;

const enhance = compose(
  withHandlers({
    onCopy: ({ getValue }) =>
      pipe(
        getValue,
        view(lensPath([0, 'value'])),
        copyTextToClipboard,
      ),
  }),
  pure,
);

export default enhance(IndicatorsContainer);
