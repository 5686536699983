import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers, withState } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as actions from 'actions';
import { Dropzone } from 'components/shared/formControls';

import { notInternalRespondErrorNotification, successNotification } from 'utils';

import Action from './Action';

const propTypes = {
  className: PropTypes.string,
  actionHandler: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  token: PropTypes.string.isRequired,
};

const defaultProps = {
  className: undefined,
};

function FileAction({ className, actionHandler, children, uploading, ...props }) {
  return (
    <Dropzone
      onDrop={actionHandler}
      disableClick
      style={{}}
      template={({ open }) => (
        <Action disabled={uploading} onClick={open} className={className} {...props}>
          {uploading ? 'Uploading...' : children}
        </Action>
      )}
    />
  );
}

FileAction.propTypes = propTypes;
FileAction.defaultProps = defaultProps;

function mapDispatchToProps(dispatch) {
  return {
    createDocument: bindActionCreators(actions.createDocument, dispatch),
  };
}

const enhance = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withState('uploading', 'setUploading', false),
  withHandlers({
    action: ({ createDocument, fileData, token }) => (asset) =>
      createDocument({ token, document: { asset, ...fileData } }),
  }),
  withHandlers({
    actionHandler: ({ action, setUploading }) => async (value) => {
      setUploading(true);
      try {
        await action(value);
        successNotification('Document uploaded successfully');
        setUploading(false);
      } catch (e) {
        notInternalRespondErrorNotification(e);
        setUploading(false);
      }
    },
  }),
  pure,
);

export default enhance(FileAction);
