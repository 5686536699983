import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, getContext, pure } from 'recompose';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';

import { getAddressRefs } from 'selectors';

import { Button, Icon } from 'components/shared';
import { FormBox, FormGroupAdapter, FormFlex, SelectStateAdapter } from 'components/shared/form';

import { getName, validateRequired } from 'utils';
import styled from 'styled-components';

const propTypes = {
  areComponentsRendered: PropTypes.bool.isRequired,
  setAreComponentsRendered: PropTypes.func.isRequired,
  namespace: PropTypes.string,
  refs: PropTypes.shape({
    statesCollection: PropTypes.arrayOf(PropTypes.object),
  }),
};

const defaultProps = {
  namespace: null,
  refs: {
    statesCollection: [],
  },
};

function AddressFields({ areComponentsRendered, setAreComponentsRendered, namespace, refs }) {
  if (!areComponentsRendered) {
    return (
      <Fragment>
        <FormFlex>
          <ShowHideComponentsButtonBox width={[1, 1, 1]}>
            <ShowHideComponentsButton onClick={() => setAreComponentsRendered(true)}>
              Show Components
              <AngleDown />
            </ShowHideComponentsButton>
          </ShowHideComponentsButtonBox>
        </FormFlex>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <FormFlex>
        <ShowHideComponentsButtonBox width={[1, 1, 1]}>
          <ShowHideComponentsButton onClick={() => setAreComponentsRendered(false)}>
            Hide Components
            <AngleUp />
          </ShowHideComponentsButton>
        </ShowHideComponentsButtonBox>
      </FormFlex>
      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={getName(namespace, 'streetNumber')}
            required
            label="Street Number"
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={getName(namespace, 'streetName')}
            required
            label="Street Name"
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field name={getName(namespace, 'premise')} label="Unit/Apt #" component={FormGroupAdapter} />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={getName(namespace, 'municipality')}
            required
            label="City"
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={getName(namespace, 'stateCode')}
            namespace={namespace}
            required
            type="select"
            options={refs.statesCollection}
            label="State"
            validate={validateRequired()}
            component={SelectStateAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={getName(namespace, 'postalCode')}
            required
            label="ZIP"
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field name={getName(namespace, 'county')} label="County" component={FormGroupAdapter} />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={getName(namespace, 'countryCode')}
            required
            label="Country Code (US)"
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={getName(namespace, 'countryName')}
            required
            label="Country Name"
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>
    </Fragment>
  );
}

AddressFields.propTypes = propTypes;
AddressFields.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    refs: getAddressRefs(state),
  };
}

const enhance = compose(
  getContext({
    areComponentsRendered: PropTypes.bool,
    setAreComponentsRendered: PropTypes.func,
    namespace: PropTypes.string,
  }),
  connect(mapStateToProps),
  pure,
);

const ShowHideComponentsButtonBox = styled(FormBox)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
`;

const ShowHideComponentsButton = styled(Button).attrs({
  transparent: true,
})`
  height: initial;
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 0;
  font-size: 12px;
`;

const AngleUp = styled(Icon).attrs({
  icon: 'angle',
})`
  width: 9px;
  height: 9px;
`;
const AngleDown = styled(Icon).attrs({
  icon: 'angle',
})`
  width: 9px;
  height: 9px;
  transform: rotate(180deg);
`;

export default enhance(AddressFields);
