import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import styles from '../controlStyles';

const propTypes = {
  className: PropTypes.string,
  rate: PropTypes.number,
};

const defaultProps = {
  className: null,
  rate: 40,
};

function Textarea(props) {
  return <StyledTextarea {...props} />;
}

Textarea.propTypes = propTypes;
Textarea.defaultProps = defaultProps;

const StyledTextarea = styled.textarea`
  ${styles};
  height: auto;
`;

export default Textarea;
