import { pipe, isNil, map, is, set, lensProp } from 'ramda';

import { dateFormat, dateWithTimeFormat } from 'utils';

function documentDecourator(doc) {
  let result = doc;

  function setDisplayCreatedAt() {
    const { createdAt } = doc;
    if (isNil(createdAt)) return;
    result = set(lensProp('displayCreatedAt'), dateFormat(createdAt), result);
  }

  function setDisplayCreatedAtWithTime() {
    const { createdAt } = doc;
    if (isNil(createdAt)) return;
    result = set(lensProp('displayCreatedAtWithTime'), dateWithTimeFormat(createdAt), result);
  }

  pipe(
    setDisplayCreatedAt,
    setDisplayCreatedAtWithTime,
  )();
  return result;
}

export default function (documents) {
  if (isNil(documents)) return documents;
  if (is(Array, documents)) return map(documentDecourator, documents);
  return documentDecourator(documents);
}
