import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as R from 'ramda';

import Property from './Property';

const propTypes = {
  application: PropTypes.objectOf(PropTypes.any).isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: undefined,
};

function Application({ application, className }) {
  const properties = R.propOr([], 'properties', application);
  return (
    <Container className={className}>
      {properties.map((property) => (
        <Property key={`draw-request-property-${property.id}`} application={application} property={property} />
      ))}
    </Container>
  );
}

const Container = styled.div``;

Application.propTypes = propTypes;
Application.defaultProps = defaultProps;

export default Application;
