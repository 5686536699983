import React from 'react';
import { FormSpy } from 'react-final-form';

const WithFinalFormMutatorsHoc = (Component) => {
  function WithFinalFormMutators(props) {
    return <FormSpy subscription={{}} render={({ form }) => <Component mutators={form.mutators} {...props} />} />;
  }

  return WithFinalFormMutators;
};

export default WithFinalFormMutatorsHoc;
