import * as R from 'ramda';

import { getIn } from '../ramda';

function styleSize(key, type, factor) {
  // FIXME remove during redisign
  return (props) => {
    let size = props[key] && typeof props[key] === 'string' && props[key];
    size = size || 'base';
    const value = factor * parseFloat(props.theme.sizes[type][size]);
    return `${value}rem`;
  };
}

function getSizeKey(size) {
  function sizePredicat(variants) {
    return R.includes(R.__, variants);
  }

  const isExtraLarge = sizePredicat(['extralarge', 'xlg', 4]);
  const isLarge = sizePredicat(['large', 'lg', 3]);
  const isDefault = sizePredicat(['default', 'md', 2]);
  const isSmall = sizePredicat(['small', 'sm', 1]);
  const isExtraSmall = sizePredicat(['extraSmall', 'xsm', 0]);

  if (isExtraSmall(size)) return 0;
  if (isSmall(size)) return 1;
  if (isDefault(size)) return 2;
  if (isLarge(size)) return 3;
  if (isExtraLarge(size)) return 4;
  return 2;
}

function transformValue(n, postfix = 'rem') {
  if (postfix === null) return n;
  return `${n}${postfix}`;
}

function getScale(input, baseScale) {
  function createScale(flag, inputScale) {
    const isNumber = R.is(Number, inputScale);
    const isNotArray = R.pipe(
      R.is(Array),
      R.not,
    )(inputScale);
    const isAppend = R.length(inputScale) < 5 && !flag;
    const isPrepend = R.length(inputScale) < 5 && flag;
    if (isNotArray && isNumber && baseScale) return R.map(R.multiply(input), baseScale);
    if (isNotArray) return createScale(false, [input]);
    if (isAppend) return createScale(true, R.append(null, inputScale));
    if (isPrepend) return createScale(false, R.prepend(null, inputScale));
    return R.slice(0, 5, inputScale);
  }

  function fillEmpty(array) {
    const firstIndex = R.findIndex(
      R.pipe(
        R.isNil,
        R.not,
      ),
      array,
    );
    const lastIndex = R.findLastIndex(
      R.pipe(
        R.isNil,
        R.not,
      ),
      array,
    );
    return array.map((item, index) => {
      if (index < firstIndex) return array[firstIndex];
      if (index > lastIndex) return array[lastIndex];
      return item;
    });
  }

  const scale = R.pipe(
    R.curry(createScale)(false),
    fillEmpty,
  )(input);
  return scale;
}

function getSizeValue(input, flug) {
  return ({ size, theme }) => {
    const baseScale = getIn('scale', theme);
    const scale = getScale(input, baseScale);
    const key = getSizeKey(size);
    const value = scale[key];
    return transformValue(value, flug);
  };
}

export { styleSize, getSizeValue, transformValue };
