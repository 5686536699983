import * as R from 'ramda';
import objectToFormData from 'object-to-formdata';
import { fetchApplication } from './index';
import * as actionTypes from '../constants/actionTypes';
import * as requestTypes from '../constants/requestTypes';
import setRequestInProcess from '../actions/request';
import api from '../utils/api';
import { successNotification, notInternalRespondErrorNotification } from '../utils';

const isValid = (property) => property.address && property.address.length > 0;

export const fetchSchedules = (appToken) => (dispatch) => {
  const requestType = requestTypes.SCHEDULES;
  const url = `/applications/${appToken}/re_schedules`;

  dispatch(setRequestInProcess(true, requestType));
  return api
    .get(url)
    .then(({ data }) => {
      dispatch(setRequestInProcess(false, requestType));
      dispatch(fetchApplicationSchedulesSuccess(data.collection));
    })
    .catch((response) => {
      dispatch(setRequestInProcess(false, requestType));
      notInternalRespondErrorNotification(response);
    });
};

export const addApplicationScheduleRequest = (target, property) => (dispatch, getState) => {
  const curProperties = getState().schedules.properties;
  dispatch(addApplicationSchedule([...curProperties, property]));
};

function addApplicationSchedule(data) {
  return {
    type: actionTypes.APPLICATION_SCHEDULES_ADD,
    payload: {
      data,
    },
  };
}

export const removeApplicationScheduleRequest = (target, appToken, property) => (dispatch) => {
  if (!property.id) {
    dispatch(removeApplicationSchedule(property.key));
  } else {
    const url = `/applications/${appToken}/re_schedules/${property.id}`;

    return api
      .delete(url)
      .then(({ data }) => {
        dispatch(removeApplicationSchedule(property.key));
        dispatch(fetchApplication(appToken));
        successNotification('Property was successfully removed');
      })
      .catch((response) => {
        notInternalRespondErrorNotification(response);
      });
  }
};

function removeApplicationSchedule(data) {
  return {
    type: actionTypes.APPLICATION_SCHEDULES_REMOVE,
    payload: {
      data,
    },
  };
}

export const editApplicationScheduleRequest = (target, property, column, value, targetId, targetType, appToken) => (
  dispatch,
) => {
  if (!isValid(property)) return false;

  let columnsToPick = ['id', column];
  if (column === 'address') {
    columnsToPick = [
      ...columnsToPick,
      'latitude',
      'longitude',
      'countryCode',
      'countryName',
      'stateCode',
      'stateName',
      'municipality',
      'county',
      'premise',
      'postalCode',
      'streetName',
      'streetNumber',
    ];
  }
  const preparedProperty = property.id
    ? R.pick([...columnsToPick], property)
    : R.omit(['key', 'valid', '_new', 'editable', 'meta', 'edidable_on_add'], property);
  if (column !== 'address') {
    preparedProperty[column] = value;
  }
  preparedProperty.target_id = targetId;
  preparedProperty.target_type = targetType;
  if (property.id) {
    return api
      .patch(`/applications/${appToken}/re_schedules/${property.id}`, { re_schedule: { ...preparedProperty } })
      .then(({ data }) => {
        dispatch(editApplicationSchedule({ property: { ...data.reSchedule }, key: property.key }));
        dispatch(fetchApplication(appToken));
        successNotification('Property was successfully updated');
      })
      .catch((response) => {
        notInternalRespondErrorNotification(response);
      });
  }
  return api
    .post(`/applications/${appToken}/re_schedules`, { re_schedule: { ...preparedProperty } })
    .then(({ data }) => {
      dispatch(editApplicationSchedule({ property: { ...data.reSchedule }, key: property.key }));
      successNotification('Property was successfully updated');
    })
    .catch((response) => {
      notInternalRespondErrorNotification(response);
    });
};

export function editApplicationSchedule(data) {
  return {
    type: actionTypes.APPLICATION_SCHEDULES_UPDATE,
    payload: {
      data,
    },
  };
}

export function fetchApplicationSchedulesSuccess(payload) {
  return {
    type: actionTypes.APPLICATION_SCHEDULES_FETCH_SUCCESS,
    payload,
  };
}

export function resetSchedules() {
  return {
    type: actionTypes.APPLICATION_SCHEDULES_RESET,
  };
}

function updateImportId(data) {
  return {
    type: actionTypes.APPLICATION_SCHEDULES_UPDATE_IMPORT_ID,
    payload: {
      data,
    },
  };
}

export function importApplicationScheduleRequest(file, appToken, targetContactId) {
  return (dispatch) => {
    const requestType = requestTypes.SCHEDULES_IMPORT;
    const url = `/applications/${appToken}/re_schedules/import`;
    const formData = objectToFormData({ import: { asset: undefined } });
    formData.append('import[asset]', file);
    formData.append('import[target_contact_id]', targetContactId);

    dispatch(setRequestInProcess(true, requestType));

    return api
      .post(url, formData)
      .then(({ data }) => {
        dispatch(setRequestInProcess(false, requestType));
        dispatch(updateImportId(data.import.id));
        dispatch(fetchApplication(appToken));
        successNotification('Your file has been placed to the queue. Please reload this page in a couple of minutes.');
      })
      .catch((response) => {
        dispatch(setRequestInProcess(false, requestType));
        notInternalRespondErrorNotification(response);
      });
  };
}

export function checkApplicationScheduleImportStatusRequest(importId, appToken) {
  return (dispatch) => {
    const requestType = requestTypes.SCHEDULES_CHECK_IMPORT_STATUS;
    const url = `/applications/${appToken}/re_schedules/import/${importId}`;
    dispatch(setRequestInProcess(true, requestType));

    return api
      .get(url)
      .then(({ data }) => {
        dispatch(setRequestInProcess(false, requestType));
        dispatch(checkingImportStatus(data.import));
      })
      .catch((response) => {
        dispatch(setRequestInProcess(false, requestType));
        notInternalRespondErrorNotification(response);
      });
  };
}

function checkingImportStatus(data) {
  return {
    type: actionTypes.APPLICATION_SCHEDULES_CHECK_IMPORT_STATUS,
    payload: {
      data,
    },
  };
}
