import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers } from 'recompose';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { prop } from 'styled-tools';
import { withRouter } from 'react-router-dom';

import * as actions from 'actions';

import { getConditions } from 'selectors/conditions';
import { getProcessorNotes } from 'selectors/processorNotes';
import { getApplicationType, getApplicationTodo } from 'selectors/applications';

import { Wrapper } from 'components/shared';

import ToDos from './ToDos';
import AppraisalStatus from './appraisalStatus';
import Documents from './Documents';
import DownloadDocuments from './DownloadDocuments';
import Conditions from './Conditions';
import ProcessorNotes from './ProcessorNotes';

const propTypes = {
  conditions: PropTypes.arrayOf(PropTypes.object),
  processorNotesOnChange: PropTypes.func.isRequired,
  processorNotes: PropTypes.arrayOf(PropTypes.object),
  applicationType: PropTypes.string,
};

const defaultProps = {
  conditions: [],
  processorNotes: [],
  applicationType: null,
};

function ApplicationDashboard(props) {
  const { conditions, processorNotesOnChange, processorNotes, applicationType } = props;
  const todo = extractTodo(props, applicationType);

  return (
    <StyledWrapper>
      <Container>
        <Left>
          <ToDos collection={todo} />
          <Conditions collection={conditions} />
          <ProcessorNotes onChange={processorNotesOnChange} collection={processorNotes} />
          <AppraisalStatus />
        </Left>
        <Right>
          <Documents />
          <DownloadDocuments />
        </Right>
      </Container>
    </StyledWrapper>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: ${prop('theme.breakpoints.1')}) {
    flex-direction: column;
  }
`;

const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex: 1 0 auto;
`;

const Left = styled.div`
  flex-shirnk: 0;
  width: 60%;

  @media (max-width: ${prop('theme.breakpoints.1')}) {
    width: 100%;
  }
`;

const Right = styled.div`
  flex-shirnk: 0;
  position: relative;
  width: 40%;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    display: block;
    width: 100vh;
    height: 100%;
    background: #fafafa;
  }

  @media (max-width: ${prop('theme.breakpoints.1')}) {
    width: 100%;

    &::after {
      display: none;
    }
  }
`;

ApplicationDashboard.propTypes = propTypes;
ApplicationDashboard.defaultProps = defaultProps;

function mapStateToProps(state, { match }) {
  const { token } = match.params;

  return {
    token,
    todo: getApplicationTodo(state),
    conditions: getConditions(state),
    processorNotes: getProcessorNotes(state),
    applicationType: getApplicationType(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateProcessorNote: bindActionCreators(actions.updateProcessorNote, dispatch),
  };
}

function extractTodo(props, applicationType) {
  if (applicationType === 'pre_approval' || applicationType === 'credit') {
    const index = props.todo.indexOf('appraisal_payment');

    if (index >= 0) {
      const array = props.todo;
      array.splice(index, 1);
      return array;
    }

    return props.todo;
  }

  return props.todo;
}

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    processorNotesOnChange: ({ updateProcessorNote, token }) => (id, answer) => updateProcessorNote(token, id, answer),
  }),
  pure,
);

export default enhance(ApplicationDashboard);
