import React from 'react';
import { Field } from 'react-final-form';

import { getIn } from 'utils';

function WithFinalFormValueHoc(nameProp = 'name') {
  return (Component) => {
    function WithFinalFormValue(props) {
      const name = getIn(nameProp, props);
      return (
        <Field
          subscription={{ value: true }}
          name={name}
          render={({ input: { value } }) => <Component value={value} {...props} />}
        />
      );
    }

    return WithFinalFormValue;
  };
}

export default WithFinalFormValueHoc;
