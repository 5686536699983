import * as R from 'ramda';

import { setInDefaultValue } from 'decorators';
import { throwSubmissionError } from 'utils';

function setDefaultInitialValuesHandler({ match }) {
  const { token } = match.params;
  return R.pipe(setInDefaultValue(token, 'token'));
}

function mergeInitialValuesHandler({ setDefaultInitialValues, setInitialValues }) {
  return R.pipe(
    setDefaultInitialValues,
    setInitialValues,
  );
}

function onSubmitHandler({ createExtensionRequest, setSuccessTrue }) {
  return R.pipe(
    createExtensionRequest,
    R.then(setSuccessTrue),
    R.otherwise(throwSubmissionError),
  );
}

export {
  setDefaultInitialValuesHandler as setDefaultInitialValues,
  mergeInitialValuesHandler as mergeInitialValues,
  onSubmitHandler as onSubmit,
};
