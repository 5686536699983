import { stringify } from 'query-string';
import * as R from 'ramda';

import { authUrl, apiUrl, clientId, clientSecret, environmentToken } from 'config';
import { SIGN_IN_PATH } from 'constants/routes';
import { decamelizeKeys, camelizeKeys } from 'utils/humps';
import { history } from 'store/configureStore';
import { internalRespondErrorNotification, tooManyRequestsNotification } from 'utils/notifications';

import { getAuthData, setAuthData, getRemainingTime, getExpiresInTime, removeAuthData } from 'utils/auth';
import { getCurrentTime } from 'utils/time';
import rollbarErrorTracking from './rollbar';

require('es6-promise').polyfill();
require('isomorphic-fetch');

const changeWindowLocation = (url) => (window.location.href = url); //eslint-disable-line

export const parseJSON = (response) => response.json();
export const camelizeResponse = (response) => camelizeKeys(response);

export const checkStatus = (response) => {
  if (response.ok) {
    const location = response.headers.get('location');

    if (location) changeWindowLocation(location);

    return response;
  }

  if (![400, 401, 403, 404, 422].includes(response.status)) {
    const rollbar = rollbarErrorTracking(environmentToken);
    rollbar.logErrorInRollbar(response);
  }

  // FIXME
  // if (response.status === 404) {
  //   return changeWindowLocation('/404');
  // }

  if (response.status === 401) {
    removeAuthData();
    return history.push({ pathname: SIGN_IN_PATH, state: { from: history.location } });
  }

  if (response.status === 500) {
    internalRespondErrorNotification(response);
  }

  if (response.status === 429) {
    tooManyRequestsNotification(response);
  }

  return parseJSON(response).then((errorMessage) => {
    throw errorMessage;
  });
};

const checkType = (response) => {
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return response.json().then(camelizeResponse);
  }
  return response;
};

export const parseSettings = ({ method = 'GET', data, locale, ...otherSettings } = {}) => {
  const prepearedData = decamelizeKeys(data);
  const headers = {
    'Accept-Language': locale,
  };

  const settings = {
    method,
    headers,
  };

  if (data instanceof FormData) {
    settings.body = data;
  } else {
    settings.body = prepearedData ? JSON.stringify(prepearedData) : undefined;
    settings.headers['Content-Type'] = 'application/json';
  }

  return R.mergeDeepRight(settings, otherSettings);
};

export function parseEndpoint(endpoint, params) {
  const url = endpoint.indexOf('http') === 0 ? endpoint : apiUrl + endpoint;
  const querystring = params ? `?${stringify(params)}` : '';
  return `${url}${querystring}`;
}

const api = {};

api.request = (endpoint, { params, ...settings } = {}) =>
  fetch(parseEndpoint(endpoint, params), parseSettings(settings))
    .then(checkStatus)
    .then(checkType);

[('DELETE', 'GET')].forEach((method) => {
  api[method] = (endpoint, settings) => api.request(endpoint, { method, ...settings });
});
['POST', 'PUT', 'PATCH'].forEach((method) => {
  api[method] = (endpoint, data, settings) => api.request(endpoint, { method, data, ...settings });
});

api.create = (settings = {}) => ({
  settings,

  setToken(token) {
    this.settings.headers = {
      ...this.settings.headers,
      Authorization: `Bearer ${token}`,
    };
  },

  unsetToken() {
    if (R.isNil(this.settings.headers)) return;
    const { Authorization, ...rest } = this.settings.headers;
    this.settings.headers = {
      ...rest,
    };
  },

  setUserActivityTime(time = getCurrentTime()) {
    this.userActivityTime = time;
  },

  getUserActivityTime() {
    return this.userActivityTime;
  },

  refreshRequest() {
    const { refreshToken } = getAuthData();

    if (!refreshToken) return Promise.reject();

    const options = {
      method: 'POST',
      data: {
        grant_type: 'refresh_token',
        refreshToken,
        clientId,
        clientSecret,
      },
    };

    return api.request(`${authUrl}/token`, R.mergeDeepRight(this.settings, options)).then(setAuthData);
  },

  refreshAuthToken() {
    const currentRemainingTime = getRemainingTime();
    const userActivityRemainingTime = getRemainingTime(this.getUserActivityTime());
    const expiresInTime = getExpiresInTime();

    if (currentRemainingTime > 0 && userActivityRemainingTime < expiresInTime / 2) return this.refreshRequest();
    return Promise.resolve();
  },

  async request(endpoint, options) {
    await this.refreshAuthToken();
    this.setUserActivityTime();
    return api.request(endpoint, R.mergeDeepRight(this.settings, options));
  },

  post(endpoint, data, options) {
    return this.request(endpoint, {
      method: 'POST',
      data,
      ...options,
    });
  },

  get(endpoint, options) {
    return this.request(endpoint, { method: 'GET', ...options });
  },

  put(endpoint, data, options) {
    return this.request(endpoint, {
      method: 'PUT',
      data,
      ...options,
    });
  },

  patch(endpoint, data, options) {
    return this.request(endpoint, {
      method: 'PATCH',
      data,
      ...options,
    });
  },

  delete(endpoint, options) {
    return this.request(endpoint, { method: 'DELETE', ...options });
  },
});

const { accessToken } = getAuthData();
const apiInstance = api.create();
apiInstance.setUserActivityTime();

if (accessToken) {
  apiInstance.setToken(accessToken);
}

export default apiInstance;
