import React from 'react';
import styled from 'styled-components';
import { compose, pure, withHandlers } from 'recompose';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import { withRouter } from 'react-router-dom';
import ContentLoader from 'react-content-loader';

import { userPropTypes, userDefaultProps } from 'propTypes';
import { WithUser } from 'hocs';
import { PROFILE_PATH, INDEX_PATH } from 'constants/routes';

import { MenuContainer as Menu, MenuDivider, MenuItem } from 'components/shared/menu';

import UserNavigationName from 'components/shared/User/NavigationName';

const propTypes = {
  ...userPropTypes,
  onLogoutClick: PropTypes.func.isRequired,
};

const defaultProps = {
  ...userDefaultProps,
};

function UserDropdown({ user, userIsLoading, onLogoutClick }) {
  const loading = !user || userIsLoading;

  if (loading) {
    return (
      <ContainerLoader>
        <ContentLoader height={37} speed={3} width={200} style={{ width: '200px' }}>
          <rect x="10" y="0" rx="5" ry="5" width="200" height="37" />
        </ContentLoader>
      </ContainerLoader>
    );
  }

  return (
    <Downshift>
      {({ closeMenu, isOpen, openMenu }) => {
        const handleClick = isOpen ? closeMenu : openMenu;

        return (
          <div>
            <Container>
              <UserNavigationName isOpen={isOpen} user={user} handleClick={handleClick} />
              {isOpen ? (
                <Menu>
                  <MenuItem url={PROFILE_PATH}>Profile</MenuItem>
                  <MenuDivider />
                  <MenuItem action={onLogoutClick}>Sign Out</MenuItem>
                </Menu>
              ) : null}
            </Container>
          </div>
        );
      }}
    </Downshift>
  );
}

const Container = styled.div`
  position: relative;
`;

const ContainerLoader = styled.div`
  ${'' /* padding: 0.78125rem 0.3125rem; */}
  font-size: 1rem;
  line-height: 1.25rem;
`;

UserDropdown.propTypes = propTypes;
UserDropdown.defaultProps = defaultProps;

const enhance = compose(
  withRouter,
  WithUser,
  withHandlers({
    onLogoutClick: ({ history, logout }) => () => {
      logout();
      history.push(INDEX_PATH);
    },
  }),
  pure,
);

export default enhance(UserDropdown);
