import React, { Fragment } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { MoreInfo, Title, SecurityStandards, Subtitle } from 'components/shared';
import { Asterisks, Products, SimplifiedLoans, StepsRepresent, Testimonials } from './shared';

function Home() {
  return (
    <Fragment>
      <Container>
        <Title>Get An Instant Rate Quote in 2 Minutes or Less!</Title>
        <Subtitle>Fix &amp; Flip and 30-Year Fixed Rental Loans at Market Competitive Rates</Subtitle>
      </Container>
      <Products />
      <SimplifiedLoans />
      <StepsRepresent />
      <MoreInfo />
      <Testimonials />
      <SecurityStandards />
      <Asterisks />
    </Fragment>
  );
}

const Container = styled.div`
  padding: 0 10px;
  margin: 0 auto;

  ${media.between('medium', '991px')`
    max-width: 690px;
  `};
`;

export default Home;
