import React, { Fragment } from 'react';
import { Field } from 'react-final-form';

import { TRACK_HIDDEN_FIELDS } from 'constants/forms';

export default function HiddenTrackFields() {
  return (
    <Fragment>
      { TRACK_HIDDEN_FIELDS.map((field) => (
        <Field
          name={field}
          id={field}
          component="input"
          type="hidden"
        />
      ))}
    </Fragment>
  );
}
