import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, getContext, pure } from 'recompose';
import { connect } from 'react-redux';
import { Field, FormSpy } from 'react-final-form';
import * as R from 'ramda';

import { getBridgeFullFormFourthStepBorrowersRefs } from 'selectors';

import { FormGroupAdapter, FormBox, FormFlex, FormLegend } from 'components/shared/form';
import IconLink from 'components/shared/IconLink';

import {
  displayName,
  composeValidators,
  getIn,
  percentParse,
  validateEmail,
  validatePhone,
  validateRequired,
} from 'utils';

import { validateOwnersip, validateBorrowerEmail } from '../../shared';

const propTypes = {
  className: PropTypes.string,
  index: PropTypes.number,
  isFormDisabled: PropTypes.bool.isRequired,
  fields: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired,
  refs: PropTypes.shape({
    borrowerTitlesList: PropTypes.arrayOf(PropTypes.object),
  }),
};

const defaultProps = {
  className: null,
  index: undefined,
  refs: PropTypes.shape({
    borrowerTitlesList: [],
  }),
};

function BridgeArrayFields({ isFormDisabled, className, index, fields, refs, name }) {
  return (
    <Container className={className}>
      <FormSpy
        subscription={{ values: true }}
        render={({ values }) => {
          const isCurrent = getIn(`${fields.name}[${index}].isCurrent`, values);
          const primary = getIn(`${fields.name}[${index}].primary`, values);
          return (
            <FormFlex justifyContent="space-between">
              <FormBox mb="1rem">
                <FormLegend>
                  Owner #{index + 1}
                  {!isCurrent && ' Your partner will receive an email to fill out their own financials.'}
                </FormLegend>
              </FormBox>

              {!(isCurrent || primary) && (
                <FormBox>
                  <IconLink
                    icon="cross"
                    fz={0.8}
                    disabled={isFormDisabled}
                    onClick={() => {
                      fields.remove(index);
                    }}
                  />
                </FormBox>
              )}
            </FormFlex>
          );
        }}
      />

      <FormSpy
        subscription={{ values: true }}
        render={({ values }) => {
          const borrower = getIn(`${fields.name}[${index}]`, values);
          if (R.prop('id', borrower)) {
            return (
              <FormFlex>
                <FormBox width={1} mb="1rem">
                  <span>
                    <Strong>{displayName(R.prop('firstName', borrower), R.prop('lastName', borrower))}</Strong>
                  </span>
                </FormBox>
              </FormFlex>
            );
          }

          return (
            <Fragment>
              <FormFlex>
                <FormBox width={[1, 1, 1 / 2]}>
                  <Field
                    name={`${name}.firstName`}
                    label="First Name"
                    required
                    disabled={isFormDisabled}
                    validate={validateRequired()}
                    component={FormGroupAdapter}
                  />
                </FormBox>
                <FormBox width={[1, 1, 1 / 2]}>
                  <Field
                    name={`${name}.lastName`}
                    label="Last Name"
                    required
                    disabled={isFormDisabled}
                    validate={validateRequired()}
                    component={FormGroupAdapter}
                  />
                </FormBox>
              </FormFlex>

              <FormFlex>
                <FormBox width={[1, 1, 1 / 2]}>
                  <Field
                    name={`${name}.email`}
                    label="Email"
                    type="email"
                    required
                    parse={R.toLower}
                    disabled={isFormDisabled}
                    validate={composeValidators(validateRequired(), validateEmail(), validateBorrowerEmail)}
                    component={FormGroupAdapter}
                  />
                </FormBox>
                <FormBox width={[1, 1, 1 / 3]}>
                  <Field
                    name={`${name}.phone`}
                    label="Phone"
                    type="tel"
                    required
                    typeOfMask="phone"
                    disabled={isFormDisabled}
                    validate={composeValidators(validateRequired(), validatePhone())}
                    component={FormGroupAdapter}
                  />
                </FormBox>
                <FormBox width={[1, 1, 1 / 3]}>
                  <Field
                    name={`${name}.phoneExtension`}
                    label="Extension#"
                    typeOfMask="phoneExtension"
                    component={FormGroupAdapter}
                  />
                </FormBox>
              </FormFlex>
            </Fragment>
          );
        }}
      />

      <FormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name={`${name}.ownershipPercent`}
            label="% Ownership"
            typeOfMask="decimalPercent"
            parse={percentParse}
            required
            disabled={isFormDisabled}
            validate={validateOwnersip}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name={`${name}.title`}
            label="Title"
            type="select"
            isDisabled={isFormDisabled}
            options={refs.borrowerTitlesList}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex justifyContent="space-between">
        <FormBox>
          <Field
            name={`${name}.primary`}
            id={`${name}-primary`}
            type="checkbox"
            disabled={isFormDisabled}
            component={FormGroupAdapter}
            parse={(value) => value || true}
          >
            Primary Borrower?
          </Field>
        </FormBox>
      </FormFlex>
    </Container>
  );
}

const Container = styled.div``;

const Strong = styled.strong`
  font-weight: bold;
`;

BridgeArrayFields.propTypes = propTypes;
BridgeArrayFields.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    refs: getBridgeFullFormFourthStepBorrowersRefs(state),
  };
}

const enhance = compose(
  getContext({ isFormDisabled: PropTypes.bool }),
  connect(mapStateToProps),
  pure,
);

export default enhance(BridgeArrayFields);
