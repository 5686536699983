import React from 'react';
import styled from 'styled-components';

import PortalButton from 'components/shared/PortalButton';

function Action(props) {
  return <PortalButton xs rounded {...props} />;
}

export default styled(Action)``;
