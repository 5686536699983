import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ifProp } from 'styled-tools';

import { Link } from 'components/shared';
import Icon from 'components/shared/Icon';

const propTypes = {
  dataName: PropTypes.string,
  icon: PropTypes.string.isRequired,
  fz: PropTypes.number,
  middle: PropTypes.bool,
  width: PropTypes.number,
  palette: PropTypes.string,
  right: PropTypes.bool,
  responsive: PropTypes.bool,
  children: PropTypes.node,
};

const defaultProps = {
  dataName: null,
  fz: undefined,
  middle: false,
  width: undefined,
  palette: undefined,
  right: false,
  responsive: false,
  children: undefined,
};

const fontSize = ({ fz }) => (fz ? `${fz}rem` : 'inherit');

const margin = ({ hasText, right }) => {
  if (hasText) {
    return right ? '0 0 0 0.25em' : '0 0.25em 0 0';
  }
  return 0;
};

function IconLink({ width, fz, middle, icon, right, responsive, children, dataName, ...props }) {
  const iconElement = (
    <StyledIcon
      width={width}
      icon={icon}
      hasText={!!children}
      right={right}
      responsive={responsive}
      palette={props.palette}
      middle={middle}
    />
  );
  return (
    <Link fz={fz} {...props} data-name={dataName}>
      {right || iconElement}
      <Text fz={fz} middle={middle} responsive={responsive}>
        {children}
      </Text>
      {right && iconElement}
    </Link>
  );
}

const StyledIcon = styled(Icon)`
  margin: ${margin};
  vertical-align: ${ifProp('middle', 'middle')};
  @media screen and (max-width: 420px) {
    margin: ${({ responsive }) => responsive && 0};
  }
`;

const Text = styled.span`
  font-size: ${fontSize};
  vertical-align: ${ifProp('middle', 'middle')};
  @media screen and (max-width: 420px) {
    display: ${({ responsive }) => responsive && 'none'};
  }
`;

IconLink.propTypes = propTypes;
IconLink.defaultProps = defaultProps;

IconLink.Icon = StyledIcon;

export default IconLink;
