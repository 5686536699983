import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { Button } from 'components/shared';
import { FormGroupAdapter } from 'components/shared/form';

import { validateRequired } from 'utils';
import styled from 'styled-components';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const defaultProps = {};

function Form({ handleSubmit, submitting }) {
  return (
    <StyledFormFlex>
      <form>
        <StyledExplanationWrap>
          <Field
            autoFocus
            name="document.explanation"
            label="Provide explanation"
            type="textarea"
            rows={20}
            sm
            noMargin
            required
            component={FormGroupAdapter}
            validate={validateRequired()}
          />
        </StyledExplanationWrap>
        <StyledSignatureWrap>
          <Field
            name="document.metadata.signature_name"
            label="Type Your Signature"
            type="textarea"
            rows={1}
            cursive
            noMargin
            required
            component={FormGroupAdapter}
            validate={validateRequired()}
          />
        </StyledSignatureWrap>
        <StyledButtonWrap>
          <Button sm onClick={handleSubmit} dataName="saveButton" rounded disabled={submitting}>
            {submitting ? 'Please wait…' : 'Save'}
          </Button>
        </StyledButtonWrap>
      </form>
    </StyledFormFlex>
  );
}

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

export default Form;

const StyledFormFlex = styled.div`
  margin: 5px 12px 10px 12px;
`;

const StyledExplanationWrap = styled.div`
  min-width: 600px;
`;

const StyledSignatureWrap = styled.div`
  display: flex;
`;

const StyledButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
`;
