import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withState, withHandlers, withStateHandlers } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import createFocusDecorator from 'final-form-focus';
import * as R from 'ramda';

import * as actions from 'actions/applications';
import { getApplication } from 'selectors';
import { warnNotification } from 'utils';

import ApplicationCurrentStepRedirect from 'components/shared/ApplicationCurrentStepRedirect';

import Form from './Form';
import * as handlers from './shared/handlers';

const propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  application: PropTypes.shape({
    leadStep: PropTypes.string,
    dataEntryStep: PropTypes.number,
  }),
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    appraisalPayment: PropTypes.objectOf(PropTypes.any),
    token: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  redirect: PropTypes.bool,
};

const defaultProps = {
  redirect: false,
  application: undefined,
};

const focusOnError = createFocusDecorator();

function New({ application, redirect, onSubmit, initialValues, loading, match }) {
  const { token } = match.params;

  if (redirect && application) {
    return <ApplicationCurrentStepRedirect application={application} token={token} />;
  }

  return (
    <FinalForm
      onSubmit={onSubmit}
      decorators={[focusOnError]}
      initialValues={initialValues}
      signatureVersion={R.prop('signatureVersion', initialValues)}
      subscription={{ submitError: true, submitting: true, pristine: true }}
      mutators={{ ...arrayMutators }}
      loading={loading}
      component={Form}
    />
  );
}

New.propTypes = propTypes;
New.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    application: getApplication(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchApplicationAgreements: bindActionCreators(actions.fetchApplicationAgreements, dispatch),
    createAgreements: bindActionCreators(actions.createApplicationAgreements, dispatch),
  };
}

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('loading', 'setLoading', false),
  withStateHandlers(() => ({ redirect: false }), {
    setRedirectTrue: () => () => ({ redirect: true }),
  }),
  withHandlers({
    setDefaultInitialValues: handlers.setDefaultInitialValues,
  }),
  withState('initialValues', 'setInitialValues', ({ setDefaultInitialValues }) => setDefaultInitialValues()),
  withHandlers({
    mergeInitialValues: handlers.mergeInitialValues,
    onSubmit: handlers.onSubmit,
  }),
  lifecycle({
    async componentDidMount() {
      const { match, fetchApplicationAgreements, mergeInitialValues, setLoading, setRedirectTrue } = this.props;
      const { token } = match.params;
      setLoading(true);
      const res = await fetchApplicationAgreements(token);
      const initialValues = R.propOr({}, 'agreement', res);

      if (R.prop('checked', initialValues)) {
        warnNotification('Credit/Background Release was already completed.');
        setRedirectTrue();
      } else {
        mergeInitialValues(initialValues);
      }

      setLoading(false);
    },
  }),
  pure,
);

export default enhance(New);
