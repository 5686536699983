import React from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

import { ANIMATE_DURATION } from '../constants';

const propTypes = {
  isOpen: PropTypes.bool,
  canAnimateHeight: PropTypes.bool,
};

const defaultProps = {
  isOpen: false,
  canAnimateHeight: false,
};

function Body({ isOpen, canAnimateHeight, ...props }) {
  if (canAnimateHeight) {
    return <AnimateHeight duplicate={ANIMATE_DURATION} height={isOpen ? 'auto' : 0} {...props} />;
  }

  return <div style={{ display: isOpen ? 'block' : 'none' }} {...props} />;
}

Body.propTypes = propTypes;
Body.defaultProps = defaultProps;

export default Body;
