import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers, withProps } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from 'actions';

import { ToDo, Button } from 'components/shared';
import Drawer from 'components/shared/Drawer';
import New from 'components/Application/ContactsPage/New';

const propTypes = {
  callback: PropTypes.func.isRequired,
};

function TitleCompanyToDo({ callback }) {
  return (
    <ToDo>
      <ToDo.Title>Please confirm your Title Company Information.</ToDo.Title>
      <ToDo.Actions>
        <Drawer
          content={({ togglePanel }) => (
            <New callback={callback(togglePanel)} defaultValues={{ contact: { type: 'title_company' } }} />
          )}
        >
          {({ togglePanel }) => (
            <Button sm onClick={togglePanel}>
              Confirm
            </Button>
          )}
        </Drawer>
      </ToDo.Actions>
    </ToDo>
  );
}

TitleCompanyToDo.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
  return {
    fetchApplication: bindActionCreators(actions.fetchApplication, dispatch),
  };
}

const enhance = compose(
  withRouter,
  withProps(({ match }) => {
    const { token } = match.params;
    return { token };
  }),
  connect(
    null,
    mapDispatchToProps,
  ),
  withHandlers({
    callback: ({ fetchApplication, token }) => (togglePanel) => async () => {
      await fetchApplication(token);
      togglePanel();
    },
  }),
  pure,
);

export default enhance(TitleCompanyToDo);
