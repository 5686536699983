import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field } from 'react-final-form';

import {
  getApplicationEntityName,
  getApplicationActivePropertiesCollection,
  getSupplementingDocumentsDocumentCategoryId,
} from 'selectors';

import { Radio } from 'components/shared/formControls';
import { Condition, FormFlex, FormBox, FormGroupAdapter, RadioGroupAdapter, FormLegend } from 'components/shared/form';

import { composeValidators, validateEmail, validatePhone, validateRequired } from 'utils';

import BankDetails from './BankDetails';
import Documents from './Documents';
import PropertySelectAdapter from './PropertySelectAdapter';
import InspectionUnitField from './InspectionUnitField';

const propTypes = {
  applicationEntityName: PropTypes.string,
  propertiesCollection: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  documentsCategoryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const defaultProps = {
  applicationEntityName: undefined,
  propertiesCollection: [],
  documentsCategoryId: undefined,
};

function Fields({ documentsCategoryId, applicationEntityName, propertiesCollection }) {
  return (
    <Fragment>
      <FormFlex>
        <FormBox width={1}>
          <Field
            name="drawRequest.propertyId"
            label="Property"
            type="select"
            options={propertiesCollection}
            component={PropertySelectAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name="drawRequest.requestedAmount"
            label="Requested Amount"
            typeOfMask="currency"
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name="drawRequest.totalApprovedAmount"
            label="Total $ Draws to Date"
            typeOfMask="decimalCurrency"
            component={FormGroupAdapter}
            disabled
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name="drawRequest.constructionHoldback"
            label="Total Budget"
            typeOfMask="decimalCurrency"
            component={FormGroupAdapter}
            disabled
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="drawRequest.expectedDrawsCompletedAt"
            label="Expected Completion Date"
            type="date"
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="drawRequest.comment"
            label="Are there any specific instructions you would like to provide?"
            type="textarea"
            rows={5}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox>
          <Field
            component={RadioGroupAdapter}
            inline
            label="Is 100% of the work for this draw completed?"
            name="drawRequest.isWorkCompleted"
            required
            type="checkbox"
            validate={validateRequired()}
          >
            <Radio id="drawRequestIsWorkCompletedTrue" value="true" label="Yes" />
            <Radio id="drawRequestIsWorkCompletedFalse" value="false" label="No" />
          </Field>
        </FormBox>
      </FormFlex>

      <Condition when="drawRequest.isWorkCompleted" is="false">
        <FormFlex>
          <FormBox width={1}>
            <Field
              component={FormGroupAdapter}
              isConditional
              label="Please explain the specific items that will not be completed by the time of inspection"
              name="drawRequest.workInkompleteComment"
              required
              rows={8}
              type="textarea"
              validate={validateRequired()}
            />
          </FormBox>
        </FormFlex>
      </Condition>

      <FormFlex>
        <FormBox>
          <Field
            component={RadioGroupAdapter}
            inline
            label="Is any part of the requested draw for materials?"
            name="drawRequest.anyPartForMaterials"
            required
            type="checkbox"
            validate={validateRequired()}
          >
            <Radio id="drawRequestAnyPartForMaterialsTrue" value="true" label="Yes" />
            <Radio id="drawRequestAnyPartForMaterialsFalse" value="false" label="No" />
          </Field>
        </FormBox>
      </FormFlex>

      <Condition when="drawRequest.anyPartForMaterials" is="true">
        <FormFlex>
          <FormBox width={1}>
            <Field
              name="drawRequest.anyPartForMaterialsComment"
              type="textarea"
              rows={8}
              required
              label="We will consider material draws on a case by case basis. Are the materials already on site? Please explain and provide invoice and proof of payment."
              validate={validateRequired()}
              component={FormGroupAdapter}
              isConditional
            />
          </FormBox>
        </FormFlex>
      </Condition>

      <InspectionUnitField name="drawRequest.inspectionUnit" />

      <FormFlex>
        <FormBox mb="1rem" width={1}>
          <FormLegend>Property Inspection Contact</FormLegend>
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name="drawRequest.inspectionContact.firstName"
            label="First Name"
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name="drawRequest.inspectionContact.lastName"
            label="Last Name"
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field name="drawRequest.inspectionContact.legalName" label="Company Name" component={FormGroupAdapter} />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name="drawRequest.inspectionContact.email"
            label="Email"
            required
            validate={composeValidators(validateRequired(), validateEmail())}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox>
          <Field
            name="drawRequest.inspectionContact.phone"
            label="Phone #"
            typeOfMask="phone"
            required
            validate={composeValidators(validateRequired(), validatePhone())}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox>
          <Field
            component={RadioGroupAdapter}
            inline
            label="Are you using a General Contractor form this property?"
            name="drawRequest.isGeneralContractorApplied"
            required
            type="checkbox"
            validate={validateRequired()}
          >
            <Radio id="isGeneralContractorAppliedTrue" value="true" label="Yes" />
            <Radio id="isGeneralContractorAppliedFalse" value="false" label="No" />
          </Field>
        </FormBox>
      </FormFlex>

      <Condition when="drawRequest.isGeneralContractorApplied" is="true">
        <FormFlex>
          <FormBox mb="1rem" width={1}>
            <FormLegend>Contractor Contact</FormLegend>
          </FormBox>
        </FormFlex>

        <FormFlex>
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              name="drawRequest.contractor.firstName"
              label="First Name"
              required
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              name="drawRequest.contractor.lastName"
              label="Last Name"
              required
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              component={FormGroupAdapter}
              label="Legal Name"
              name="drawRequest.contractor.legalName"
              required
              validate={validateRequired()}
            />
          </FormBox>
        </FormFlex>

        <FormFlex>
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              name="drawRequest.contractor.email"
              label="Email"
              required
              validate={composeValidators(validateRequired(), validateEmail())}
              component={FormGroupAdapter}
            />
          </FormBox>
          <FormBox>
            <Field
              name="drawRequest.contractor.phone"
              label="Phone #"
              typeOfMask="phone"
              required
              validate={composeValidators(validateRequired(), validatePhone())}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>
      </Condition>

      {documentsCategoryId && <Documents documentsCategoryId={documentsCategoryId} />}

      <FormFlex>
        <FormBox width={1}>
          <FormLegend>Borrower Entity Wiring Instructions</FormLegend>
        </FormBox>
      </FormFlex>
      <FormFlex>
        <FormBox mb="1rem" width={1}>
          <SubLegend>Entity: {applicationEntityName}</SubLegend>
        </FormBox>
      </FormFlex>

      <BankDetails />

    </Fragment>
  );
}

const SubLegend = styled.p`
  forn-size: 0.9375rem;
  color: #a3a6a8;
`;

Fields.propTypes = propTypes;
Fields.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    propertiesCollection: getApplicationActivePropertiesCollection(state),
    applicationEntityName: getApplicationEntityName(state),
    documentsCategoryId: getSupplementingDocumentsDocumentCategoryId(state),
  };
}

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
  pure,
);

export default enhance(Fields);
