import React from 'react';
import PropTypes from 'prop-types';

import { applicationPropType } from 'propTypes';
import { withRouter } from 'react-router-dom';

import { Heading } from 'components/shared';
import { ApplicationRow } from '../';

const propTypes = {
  applications: PropTypes.arrayOf(applicationPropType),
};

const defaultProps = {
  applications: [],
};

function ApplicationList({ applications, match }) {
  if (applications.length > 0) {
    return applications.map((application) => (
      <ApplicationRow key={`application-row-${application.id}`} application={application} />
    ));
  }

  return (
    <Heading level={2}>
      {`You don't have any ${match.params.status !== 'all' ? match.params.status : ''} applications`}
    </Heading>
  );
}

ApplicationList.propTypes = propTypes;
ApplicationList.defaultProps = defaultProps;

export default withRouter(ApplicationList);
