import React, { Component } from 'react';
import styled from 'styled-components';

import Icon from 'components/shared/Icon';
import Input from 'components/shared/formControls/Input';

class DateInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      initialValue: this.props.value || '',
      editable: this.props.editable ? this.props.editable : false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value && !this.props.editable) {
      this.setState({
        ...this.state,
        value: nextProps.value,
        initialValue: nextProps.value,
        editable: false,
      });
    }
  }

  handleChange = (e) => {
    const { value } = e.target;
    this.setState({ value });
  };

  handleBlur = (e) => {
    const { value } = e.target;
    const { onChange } = this.props;
    const { initialValue } = this.state;
    this.setState({ editable: false });
    if (onChange && value !== initialValue) {
      onChange(value);
    }
  };

  check = () => {
    const { onChange } = this.props;
    const { value } = this.state;
    this.setState({ editable: false });
    if (onChange) {
      onChange(value);
    }
  };

  edit = () => {
    this.setState({ editable: true });
  };

  render() {
    const { value, editable } = this.state;
    const { readOnly, className } = this.props;
    return (
      <div className={`reschedule_table__cell ${className}`}>
        {!readOnly ? (
          <div className="editable-cell">
            {editable ? (
              <div className="editable-cell-input-wrapper">
                <Input
                  value={value}
                  type="text"
                  autoFocus
                  onChange={this.handleChange}
                  onPressEnter={this.check}
                  onBlur={this.handleBlur}
                  typeOfMask="date"
                  rate={26}
                />
                <Icon icon="angle" className="editable-cell-icon-check" onClick={this.check} />
              </div>
            ) : (
              <div className="text-right">
                {value ? (
                  <div>
                    {value}
                    <span className="editable-cell-indicator">Editable</span>
                  </div>
                ) : (
                  <span className="click_to_edit">click to edit</span>
                )}
                <Anchor className="editable-cell-link" onClick={this.edit} />
              </div>
            )}
          </div>
        ) : (
          <div className="text-right">{value || <span className="click_to_edit">N/A</span>}</div>
        )}
      </div>
    );
  }
}

const Anchor = styled.a``;

export default DateInput;
