import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose, defaultProps as recomposeDefaultProps, pure } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import createFocusDecorator from 'final-form-focus';
import ContentLoader from 'react-content-loader';

import * as actions from 'actions';

import { Button, Link } from 'components/shared';
import { FormBox, FormFlex, FormGroupAdapter } from 'components/shared/form';
import Well from 'components/shared/Well';

import { EDIT_PASSWORD_PATH } from 'constants/routes';
import { WithUser } from 'hocs';
import { getIn, holdMutators, composeValidators, validateRequired, validateEmail, validatePhone } from 'utils';

const propTypes = {};

const defaultProps = {};

const focusOnError = createFocusDecorator();

function MeForm({ loading, updateProfile, initialValues }) {
  if (loading) {
    return <ContentLoader />;
  }

  return (
    <Form
      onSubmit={(values) => updateProfile(values)}
      initialValues={initialValues}
      subscription={{ submitting: true, pristine: true }}
      decorators={[focusOnError]}
      mutators={{ ...holdMutators }}
      render={({ handleSubmit, submitting }) => (
        <form>
          <Well>
            <EnganceFormFlex justifyContent="center">
              <FormBox>
                <Field
                  name="contact.asset"
                  title="Upload Avatar"
                  type="dropzone"
                  multiple={false}
                  required
                  component={FormGroupAdapter}
                />
              </FormBox>
            </EnganceFormFlex>

            <EnganceFormFlex>
              <FormBox width={1}>
                <Field
                  name="contact.email"
                  label="Email"
                  type="email"
                  required
                  hint={<Link to={EDIT_PASSWORD_PATH}>Change password</Link>}
                  rightHint
                  validate={composeValidators(validateRequired(), validateEmail())}
                  component={FormGroupAdapter}
                />
              </FormBox>
            </EnganceFormFlex>
            <EnganceFormFlex>
              <FormBox width={1}>
                <Field
                  name="contact.firstName"
                  label="First Name"
                  required
                  validate={validateRequired()}
                  component={FormGroupAdapter}
                />
              </FormBox>
            </EnganceFormFlex>
            <EnganceFormFlex>
              <FormBox width={1}>
                <Field
                  name="contact.lastName"
                  label="Last Name"
                  required
                  validate={validateRequired()}
                  component={FormGroupAdapter}
                />
              </FormBox>
            </EnganceFormFlex>
            <EnganceFormFlex>
              <FormBox width={[1, 1, 1 / 3]}>
                <Field
                  name="contact.phone"
                  label="Phone"
                  type="tel"
                  required
                  typeOfMask="phone"
                  validate={composeValidators(validateRequired(), validatePhone())}
                  component={FormGroupAdapter}
                />
              </FormBox>
              <FormBox width={[1, 1, 1 / 3]}>
                <Field
                  name="contact.phoneExtension"
                  label="Extension#"
                  typeOfMask="phoneExtension"
                  component={FormGroupAdapter}
                />
              </FormBox>
            </EnganceFormFlex>
            <EnganceFormFlex justifyContent="space-between">
              <FormBox mt={2}>
                <Button onClick={handleSubmit} dataName="saveButton" rounded disabled={submitting}>
                  {submitting ? 'Please wait…' : 'Save'}
                </Button>
              </FormBox>
            </EnganceFormFlex>
          </Well>
        </form>
      )}
    />
  );
}

const EnganceFormFlex = recomposeDefaultProps({
  maxWidth: '58rem',
})(FormFlex);

function mapStateToProps(state, props) {
  const { contact, userIsLoading } = props;

  const initialValues = {
    contact: {
      asset: getIn('avatarUrl', contact),
      email: getIn('email', contact),
      firstName: getIn('firstName', contact),
      lastName: getIn('lastName', contact),
      phone: getIn('phone', contact),
      phoneExtension: getIn('phoneExtension', contact),
    },
  };

  return {
    loading: userIsLoading,
    initialValues,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateProfile: bindActionCreators(actions.updateProfile, dispatch),
  };
}

MeForm.propTypes = propTypes;
MeForm.defaultProps = defaultProps;

const enhance = compose(WithUser, withRouter, connect(mapStateToProps, mapDispatchToProps), pure);

export default enhance(MeForm);
