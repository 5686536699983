import styled from 'styled-components';
import media from 'styled-media-query';
import { ifProp } from 'styled-tools';

// {
//   huge: '1440px',
//   large: '1170px',
//   medium: '768px',
//   small: '450px',
// }

const Wrapper = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  padding: ${ifProp('padded', '3.125rem', '0')} 2.5rem;
  width: 100%;

  ${media.between('medium', '991px')`
    max-width: 760px;
  `};

  ${media.greaterThan('large')`
    max-width: 1170px;
  `};
`;

export default Wrapper;
