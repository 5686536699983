import styled from 'styled-components';
import PropTypes from 'prop-types';
import { compose, getContext, pure } from 'recompose';
import { space } from 'styled-system';

import { styleUtils } from 'utils';

const Title = styled.div.attrs({
  size: ({ size }) => size || 'default',
})`
  font-size: ${styleUtils.getSizeValue(1)};
  font-weight: bold;
  ${space};
`;

const enhance = compose(
  getContext({ size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
  pure,
);

export default enhance(Title);
