import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Form as FinalForm } from 'react-final-form';
import createFocusDecorator from 'final-form-focus';

import * as actions from 'actions';

import { ContentLoader, ToDo } from 'components/shared';

import { holdMutators } from 'utils';

import Form from './Form';
import { handlers } from './shared';

const propTypes = {
  initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const focusOnError = createFocusDecorator();

function New({ initialValues, loading, onSubmit }) {
  if (loading) {
    return <ContentLoader height="6.25rem" />;
  }

  if (initialValues.extension && initialValues.extension.submission === 'servicer') {
    return (
      <ToDo>
        <div dangerouslySetInnerHTML={{ __html: initialValues.extension.submissionDescription }} />
      </ToDo>
    );
  }
  return (
    <FinalForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      decorators={[focusOnError]}
      mutators={{ ...holdMutators }}
      subscription={{ submitError: true, submitting: true, pristine: true }}
      component={Form}
    />
  );
}

New.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
  return {
    fetchExtensionRequest: bindActionCreators(actions.fetchExtensionRequest, dispatch),
    createExtensionRequest: bindActionCreators(actions.createExtensionRequest, dispatch),
  };
}

const enhance = compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps,
  ),
  withState('loading', 'setLoading', false),
  withHandlers({
    setDefaultInitialValues: handlers.setDefaultInitialValues,
  }),
  withState('initialValues', 'setInitialValues', ({ setDefaultInitialValues }) => setDefaultInitialValues()),
  withHandlers({
    onSubmit: handlers.onSubmit,
    mergeInitialValues: handlers.mergeInitialValues,
  }),
  lifecycle({
    async componentDidMount() {
      const { match, fetchExtensionRequest, mergeInitialValues, setLoading } = this.props;
      const { token } = match.params;
      setLoading(true);
      await fetchExtensionRequest(token).then(mergeInitialValues);
      setLoading(false);
    },
  }),
  pure,
);

export default enhance(New);
