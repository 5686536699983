import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers, withProps } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from 'actions';

import { ToDo, Button, Strong } from 'components/shared';

const propTypes = {
  yes: PropTypes.func.isRequired,
  no: PropTypes.func.isRequired,
};

function MobileClosingToDo({ yes, no }) {
  return (
    <ToDo>
      <ToDo.Title>Will you need a mobile closing?</ToDo.Title>
      <ToDo.Body>
        <Strong>If you are one of the primary members of the company, you will need to be present at closing.</Strong>{' '}
        If you are not available or traveling for any reason, please make arrangements for a mobile closing with the
        title company and make us aware.
      </ToDo.Body>
      <ToDo.Actions>
        <Button sm onClick={yes}>
          Yes
        </Button>
        <Button sm onClick={no}>
          No
        </Button>
      </ToDo.Actions>
    </ToDo>
  );
}

MobileClosingToDo.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
  return {
    applicationsTodo: bindActionCreators(actions.applicationsTodo, dispatch),
  };
}

const enhance = compose(
  withRouter,
  withProps(({ match }) => {
    const { token } = match.params;
    return { token };
  }),
  connect(
    null,
    mapDispatchToProps,
  ),
  withHandlers({
    yes: ({ applicationsTodo, token }) => () => applicationsTodo(token, { todo: { mobileClosing: true } }),
    no: ({ applicationsTodo, token }) => () => applicationsTodo(token, { todo: { mobileClosing: false } }),
  }),
  pure,
);

export default enhance(MobileClosingToDo);
