import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { reduce, isNil, reject } from 'ramda';

import {
  getContactProfileDocumentsCategoriesRatio,
  getEntityDocumentsCategoriesRatio,
  getLoanDocumentsCategoriesRatio,
  getPropertyDocumentsCategoriesRatio,
} from 'selectors/documentCategories';

import ApplicationDocumentsHeader from 'components/blocks/ApplicationDocumentsHeader';

import { mergeRatios, displayRatio } from 'utils/ratio';

const propTypes = {
  borrowerRatio: PropTypes.arrayOf(PropTypes.number),
  className: PropTypes.string,
  entityRatio: PropTypes.arrayOf(PropTypes.number),
  loanRatio: PropTypes.arrayOf(PropTypes.number),
  propertyRatio: PropTypes.arrayOf(PropTypes.number),
};

const defaultProps = {
  borrowerRatio: undefined,
  className: undefined,
  entityRatio: undefined,
  loanRatio: undefined,
  propertyRatio: undefined,
};

function Header({ borrowerRatio, className, entityRatio, loanRatio, propertyRatio }) {
  const ratios = reject(isNil, [borrowerRatio, entityRatio, propertyRatio, loanRatio]);
  const totalRatio = useMemo(() => reduce(mergeRatios, [0, 0], ratios), [ratios]);
  return (
    <Container className={className}>
      <Title>
        <Total>
          Total Documents
          <br />
          {displayRatio(totalRatio)}
        </Total>
        <Text>
          Please upload documents below. The faster we receive your documents, the quicker we can get you across the
          finish line. If you have any questions, please reach out to your Loan Officer or Project Coordinator.
        </Text>
      </Title>
      <ApplicationDocumentsHeader
        entityRatio={entityRatio}
        borrowerRatio={borrowerRatio}
        loanRatio={loanRatio}
        propertyRatio={propertyRatio}
        totalRatio={totalRatio}
      />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    background: #bdbdbd;
    width: 0.0625rem;
    bottom: -2rem;
    left: 2.875rem;
    height: 2.1875rem;
  }
`;

const Title = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
`;

const Total = styled.div`
  flex-shrink: 0;
  min-width: 7.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: center;
`;

const Text = styled.div`
  margin-left: 2.125rem;
  font-size: 0.8125rem;
  line-height: 1.125rem;
`;

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    borrowerRatio: getContactProfileDocumentsCategoriesRatio(state),
    entityRatio: getEntityDocumentsCategoriesRatio(state),
    loanRatio: getLoanDocumentsCategoriesRatio(state),
    propertyRatio: getPropertyDocumentsCategoriesRatio(state),
  };
}

export default connect(mapStateToProps)(Header);
