import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { getIsInIframe } from 'selectors';

import { applicantCurrentStatusPath, MY_APPLICATIONS_PATH } from 'constants/routes';
import { dateFormat, getPath } from 'utils';

import { Congratulation, Strong, Button, Link, Well } from 'components/shared';
import { FormFlex, FormBox, FormHeader } from 'components/shared/form';
import { WellHeader, WellBody } from 'components/shared/Well';

const propTypes = {
  application: PropTypes.shape({
    name: PropTypes.string,
    date: PropTypes.string,
    userName: PropTypes.string,
    displayType: PropTypes.string,
    createdAt: PropTypes.string,
    token: PropTypes.string,
    status: PropTypes.string,
    leadStep: PropTypes.string,
    dataEntryStep: PropTypes.number,
  }).isRequired,
  isInIframe: PropTypes.bool.isRequired,
};

const defaultProps = {};

function Incomplete({ isInIframe, application }) {
  return (
    <Well>
      <WellHeader key="header">
        <FormHeader title="You have an incomplete application" />
      </WellHeader>

      <WellBody key="body">
        <StyledIncomplete>
          <StyledCongratulation status="warning">
            Before starting a new application, please complete or withdraw your existing application:
            <br />
            <Strong>{application.displayNewConstructionType || application.displayType}</Strong>
            <br />
            Submitted on <nobr>{dateFormat(application.createdAt)}</nobr>
          </StyledCongratulation>
          <Buttons>
            <FormFlex alignItems="center" flexDirection={['column', 'column', 'row']}>
              <FormBox>
                <Button
                  rounded
                  target={isInIframe ? '_blank' : ''}
                  to={applicantCurrentStatusPath(application.status, {
                    token: application.token,
                    leadStep: application.leadStep,
                    step: application.dataEntryStep,
                  })}
                >
                  Proceed to Application
                </Button>
              </FormBox>
              <FormBox>
                <Link
                  fz={0.825}
                  target={isInIframe ? '_blank' : ''}
                  to={getPath(MY_APPLICATIONS_PATH, { status: 'active' })}
                >
                  Existing application list
                </Link>
              </FormBox>
            </FormFlex>
          </Buttons>
        </StyledIncomplete>
      </WellBody>
    </Well>
  );
}

const StyledIncomplete = styled.div`
  margin: auto;
  max-width: 545px;
  font-size: 1.125rem;
  line-height: 1.6em;
  color: #666;
`;

const StyledCongratulation = styled(Congratulation)`
  padding: 30px 0 50px;
`;

const Buttons = styled.div`
  margin: auto;
  padding-bottom: 50px;
  max-width: 460px;
`;

Incomplete.propTypes = propTypes;
Incomplete.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    isInIframe: getIsInIframe(state),
  };
}

const enhance = compose(
  connect(mapStateToProps),
  pure,
);

export default enhance(Incomplete);
