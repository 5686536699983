import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'grid-styled';

import ContentLoader from 'components/shared/ContentLoader';

const propTypes = {
  className: PropTypes.string,
  inputHeight: PropTypes.string,
  hintWidth: PropTypes.string,
  labelWidth: PropTypes.string,
  mb: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    PropTypes.string,
    PropTypes.number,
  ]),
};

const defaultProps = {
  className: undefined,
  inputHeight: '2.8125rem',
  hintWidth: undefined,
  labelWidth: '10rem',
  mb: '1rem',
};

function FromGroupLoader({ className, inputHeight, hintWidth, labelWidth, mb, ...props }) {
  return (
    <Flex mb={mb} flexDirection="column" className={className} {...props}>
      <ContentLoader mb="0.3rem" maxWidth={labelWidth} height="1.3125rem" />
      <ContentLoader maxWidth="100%" height={inputHeight} />
      {hintWidth && <ContentLoader pl="0.5rem" mt="0.3rem" maxWidth={hintWidth} height="0.8rem" />}
    </Flex>
  );
}

FromGroupLoader.propTypes = propTypes;
FromGroupLoader.defaultProps = defaultProps;

export default FromGroupLoader;
