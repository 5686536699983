import Cookies from 'js-cookie';
import { REFERRAL_TOKEN_COOKIE_NAME } from 'constants/forms';

export function getCookie(name) {
  if (!document.cookie) {
    return null;
  }

  const xsrfCookies = document.cookie
    .split(';')
    .map((c) => c.trim())
    .filter((c) => c.startsWith(`${name}=`));

  if (xsrfCookies.length === 0) {
    return null;
  }

  return decodeURIComponent(xsrfCookies[0].split('=')[1]);
}

export const setCookieByName = (name) => (data) => {
  if (data) return Cookies.set(name, data);
  return data;
};
export const getCookieByName = (name) => () => Cookies.get(name);
export const removeCookieByName = (name) => () => Cookies.remove(name);

export function setReferralTokenCookie(token) {
  if (!token) return;
  Cookies.set(REFERRAL_TOKEN_COOKIE_NAME, token, { expires: 7 });
}

export function getReferralTokenCookie() {
  const token = Cookies.get(REFERRAL_TOKEN_COOKIE_NAME);
  return token;
}
