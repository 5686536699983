import React, { Fragment } from 'react';

import { FormFlex, FormBox, FormGroupLoader, FormLegendLoader } from 'components/shared/form';

function Loader() {
  return (
    <Fragment>
      <FormFlex>
        <FormLegendLoader mb="1rem" width="8.25rem" />
        <FormBox width={1}>
          <FormGroupLoader labelWidth="4.75rem" />
        </FormBox>
      </FormFlex>
    </Fragment>
  );
}

export default Loader;
