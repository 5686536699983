import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../actions';
import { getUser, requestInProcess } from '../selectors';

function WithUser(Component) {
  function User(props) {
    return <Component {...props} />;
  }

  function mapStateToProps(state) {
    const user = getUser(state);
    const contact = user && user.contact;
    const userIsLoading = requestInProcess(state, 'USER');
    const applicationsIsLoading = requestInProcess(state, 'APPLICATIONS');
    const entitiesIsLoading = requestInProcess(state, 'ENTITIES');

    return {
      userIsLoading,
      applicationsIsLoading,
      entitiesIsLoading,
      user,
      contact,
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      logout: bindActionCreators(actions.logout, dispatch),
    };
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(User);
}

export default WithUser;
