import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import createFocusDecorator from 'final-form-focus';
import { withRouter } from 'react-router-dom';

import { getTargetTypeByDocumentCategoryId } from 'selectors';

import { throwSubmissionError } from 'utils';

import Form from './Form';

const propTypes = {
  categoryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  explanation: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  onSubmit: PropTypes.func.isRequired,
  targetId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  targetType: PropTypes.string.isRequired,
};

const defaultProps = {
  explanation: undefined,
  id: undefined,
  targetId: undefined,
};

const focusOnError = createFocusDecorator();

function ExplanationForm({ categoryId, explanation, id, match, onSubmit, targetId, targetType }) {
  const { token } = match.params;
  const initialValues = {
    document: {
      categoryId,
      targetId,
      targetType,
      explanation,
      id,
    },
    token,
  };

  return (
    <FinalForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscription={{ submitting: true, pristine: true }}
      decorators={[focusOnError]}
      component={Form}
    />
  );
}

ExplanationForm.propTypes = propTypes;
ExplanationForm.defaultProps = defaultProps;

function mapStateToProps(state, { categoryId }) {
  const targetType = getTargetTypeByDocumentCategoryId(state, categoryId);

  return {
    targetType,
  };
}

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
  withHandlers({
    onSubmit: ({ action, handleTogglePanel }) => (values) =>
      action(values)
        .then(() => handleTogglePanel())
        .catch((response) => throwSubmissionError(response)),
  }),
  pure,
);

export default enhance(ExplanationForm);
