import React from 'react';
import PropTypes from 'prop-types';

import ConfirmEmailBanner from 'components/ConfirmEmail/Banner';

import TemplateBanner from 'components/templates/shared/TemplateBanner';

import { Footer, Header } from './shared';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const PageTemplate = ({ children }) => [
  <TemplateBanner>
    <ConfirmEmailBanner />
  </TemplateBanner>,
  <header key="header">
    <Header />
  </header>,
  <section key="content">{children}</section>,
  <footer key="footer">
    <Footer />
  </footer>,
];

PageTemplate.propTypes = propTypes;
export default PageTemplate;
