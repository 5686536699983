import React from 'react';
import { connect } from 'react-redux';

import { getIncompleteApplication } from '../selectors';

function WithIncompleteApp(Component) {
  function IncompleteApp(props) {
    const { incompleteApp } = props;

    return <Component incompleteApp={incompleteApp} {...props} />;
  }

  function mapStateToProps(state) {
    const incompleteApp = getIncompleteApplication(state);

    return {
      incompleteApp,
    };
  }

  return connect(
    mapStateToProps,
    null,
  )(IncompleteApp);
}

export default WithIncompleteApp;
