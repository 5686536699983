import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from 'components/shared';
import { FormFlex, FormBox, FormLegend } from 'components/shared/form';

import Fields from './Fields';
import Loader from './Loader';

const propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const defaultProps = {
  id: undefined,
};

function Form({ id, loading, submitting, handleSubmit }) {
  return (
    <form>
      <Container>
        <FormFlex>
          <FormBox width={1}>
            <StyledLegend>{id ? 'Edit' : 'New'} Contact</StyledLegend>
          </FormBox>
        </FormFlex>

        {loading && <Loader />}
        {!loading && <Fields id={id} />}

        <FormFlex>
          <FormBox width={1}>
            <Button onClick={handleSubmit} dataName="saveButton" rounded disabled={loading || submitting}>
              {submitting ? 'Please wait…' : 'Save'}
            </Button>
          </FormBox>
        </FormFlex>
      </Container>
    </form>
  );
}

const Container = styled.div`
  padding: 1rem;
`;

const StyledLegend = styled(FormLegend)`
  margin-bottom: 1rem;
`;

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

export default Form;
