import { compose, defaultProps, pure, withProps } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { any, complement, identity } from 'ramda';

import {
  getApplicationAnotherDocuments,
  getApplicationEntityId,
  getApplicationLoanId,
  getApplicationProfileId,
  getApplicationProperties,
  getApplicationTermSheetData,
  getAvailableDownloadContactProfileDocumentCategoriesWithDocuments,
  getAvailableDownloadLoanDocumentCategoriesWithDocuments,
  getContactProfileDocumentCategories,
  getDocumentsTreeCheckedKeys,
  getDocumentsTreeKeys,
  getDocumentsTreeStatuses,
  getEntityDocumentCategories,
  getHasApplicationProperties,
  getHasAvailableDownloadDocumentCategories,
  getHasContactProfileDocumentCategories,
  getHasEntityDocumentCategories,
  getHasLoanDocumentCategories,
  getHasMissingDocumentCategories,
  getHasPropertyDocumentCategories,
  getHasRequestedDocumentCategories,
  getLoanDocumentCategories,
  getPropertyDocumentCategories,
  getRequestedContactProfileDocumentCategories,
  getRequestedEntityDocumentCategories,
  getRequestedLoanDocumentCategories,
  getRequestedPropertyDocumentCategories,
  getMissingContactProfileDocumentCategories,
  getMissingEntityDocumentCategories,
  getMissingLoanDocumentCategories,
  getMissingPropertyDocumentCategories,
} from 'selectors';

const mapStateToProps = (state, { match }) => {
  const { token } = match.params;
  const applicationAnotherDocumentsArray = getApplicationAnotherDocuments(state);
  const applicationTermSheetData = getApplicationTermSheetData(state);
  const contactProfileDocumentCategories = getContactProfileDocumentCategories(state);
  const entityDocumentCategories = getEntityDocumentCategories(state);
  const loanDocumentCategories = getLoanDocumentCategories(state);
  const propertyDocumentCategories = getPropertyDocumentCategories(state);
  const properties = getApplicationProperties(state);
  const profileId = getApplicationProfileId(state, token);
  const entityId = getApplicationEntityId(state, token);
  const loanId = getApplicationLoanId(state, token);
  const defaultCheckedKeys = getDocumentsTreeCheckedKeys(state);
  const keys = getDocumentsTreeKeys(state);
  const hasAvailableDownloadDocumentCategories = getHasAvailableDownloadDocumentCategories(state);
  const availableDownloadLoanDocumentCategoriesWithDocuments = getAvailableDownloadLoanDocumentCategoriesWithDocuments(
    state,
  );
  const availableDownloadContactProfileDocumentCategoriesWithDocuments = getAvailableDownloadContactProfileDocumentCategoriesWithDocuments(
    state,
  );
  const hasRequestedDocumentCategories = getHasRequestedDocumentCategories(state);
  const hasMissingDocumentCategories = getHasMissingDocumentCategories(state);
  const hasContactProfileDocumentCategories = getHasContactProfileDocumentCategories(state);
  const hasEntityDocumentCategories = getHasEntityDocumentCategories(state);
  const hasLoanDocumentCategories = getHasLoanDocumentCategories(state);
  const hasPropertyDocumentCatigories = getHasPropertyDocumentCategories(state);
  const requestedContactProfileDocumentCategories = getRequestedContactProfileDocumentCategories(state);
  const requestedEntityDocumentCategories = getRequestedEntityDocumentCategories(state);
  const requestedLoanDocumentCategories = getRequestedLoanDocumentCategories(state);
  const requestedPropertyDocumentCategories = getRequestedPropertyDocumentCategories(state);
  const missingContactProfileDocumentCategories = getMissingContactProfileDocumentCategories(state);
  const missingEntityDocumentCategories = getMissingEntityDocumentCategories(state);
  const missingLoanDocumentCategories = getMissingLoanDocumentCategories(state);
  const missingPropertyDocumentCategories = getMissingPropertyDocumentCategories(state);
  const hasApplicationProperties = getHasApplicationProperties(state);
  const defaultDocumentsTreeStatuses = getDocumentsTreeStatuses(state);


  return {
    applicationAnotherDocumentsArray,
    applicationTermSheetData,
    availableDownloadContactProfileDocumentCategoriesWithDocuments,
    availableDownloadLoanDocumentCategoriesWithDocuments,
    contactProfileDocumentCategories,
    defaultCheckedKeys,
    defaultDocumentsTreeStatuses,
    entityDocumentCategories,
    entityId,
    hasApplicationProperties,
    hasAvailableDownloadDocumentCategories,
    hasContactProfileDocumentCategories,
    hasEntityDocumentCategories,
    hasLoanDocumentCategories,
    hasMissingDocumentCategories,
    hasPropertyDocumentCatigories,
    hasRequestedDocumentCategories,
    keys,
    loanDocumentCategories,
    loanId,
    missingContactProfileDocumentCategories,
    missingEntityDocumentCategories,
    missingLoanDocumentCategories,
    missingPropertyDocumentCategories,
    profileId,
    properties,
    propertyDocumentCategories,
    requestedContactProfileDocumentCategories,
    requestedEntityDocumentCategories,
    requestedLoanDocumentCategories,
    requestedPropertyDocumentCategories,
  };
};

const enhance = compose(
  defaultProps({
    withContactProfileDocuments: true,
    withDownLoadDocuments: true,
    withEntityDocuments: true,
    withLoanDocuments: true,
    withMissingDocuments: false,
    withPropertyDocuments: true,
    withRequestedDocuments: true,
  }),
  withRouter,
  connect(mapStateToProps),
  withProps(
    ({
      applicationAnotherDocumentsArray,
      applicationTermSheetData,
      hasApplicationProperties,
      hasAvailableDownloadDocumentCategories,
      hasContactProfileDocumentCategories,
      hasEntityDocumentCategories,
      hasLoanDocumentCategories,
      hasMissingDocumentCategories,
      hasPropertyDocumentCatigories,
      hasRequestedDocumentCategories,
      withContactProfileDocuments,
      withDownLoadDocuments,
      withEntityDocuments,
      withLoanDocuments,
      withMissingDocuments,
      withPropertyDocuments,
      withRequestedDocuments,
    }) => {
      const isEmpty = complement(any)(identity)([
        withRequestedDocuments && hasRequestedDocumentCategories,
        withMissingDocuments && hasMissingDocumentCategories,
        withDownLoadDocuments && (hasAvailableDownloadDocumentCategories || applicationTermSheetData),
        withContactProfileDocuments && hasContactProfileDocumentCategories,
        withEntityDocuments && hasEntityDocumentCategories,
        withLoanDocuments && hasLoanDocumentCategories,
        withPropertyDocuments && hasApplicationProperties && hasPropertyDocumentCatigories,
        applicationAnotherDocumentsArray && applicationAnotherDocumentsArray.length > 0,
      ]);

      return {
        isEmpty,
      };
    },
  ),
  pure,
);

export default enhance;
