import React from 'react';
import PropTypes from 'prop-types';
import { compose, getContext, pure } from 'recompose';

import { Button, Hr } from 'components/shared';
import { FormFlex, FormBox } from 'components/shared/form';

import Fields from './Fields';
import Loader from './Loader';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isFormDisabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

function Form({ isFormDisabled, handleSubmit, loading, submitting }) {
  return (
    <form>
      {loading && <Loader />}
      {!loading && <Fields />}
      <FormFlex>
        <FormBox width={1}>
          <Hr />
        </FormBox>
      </FormFlex>
      <FormFlex>
        <FormBox>
          <Button
            onClick={handleSubmit}
            dataName="buttonNext"
            rounded
            disabled={loading || isFormDisabled || submitting}
          >
            {submitting ? 'Please wait…' : 'Next'}
          </Button>
        </FormBox>
      </FormFlex>
    </form>
  );
}

Form.propTypes = propTypes;

const enhance = compose(getContext({ isFormDisabled: PropTypes.bool }), pure);

export default enhance(Form);
