import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'react-final-form';

import { Button } from 'components/shared';
import { FormFlex, FormBox, SubmitError } from 'components/shared/form';

import Fields from './Fields';
import Loader from './Loader';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  refs: PropTypes.shape({
    countriesCollection: PropTypes.arrayOf(PropTypes.object),
    statesCollection: PropTypes.arrayOf(PropTypes.object),
  }),
  loading: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitError: PropTypes.string,
};

const defaultProps = {
  refs: {
    statesCollection: [],
    countriesCollection: [],
  },
  submitError: undefined,
};

function Form({ submitError, handleSubmit, loading, submitting, isAllPropertiesMultiFamily }) {
  return (
    <form>
      {submitError && <SubmitError error={submitError} />}
      {loading && <Loader />}
      {!loading && <Fields isAllPropertiesMultiFamily={isAllPropertiesMultiFamily} />}
      <FooterFormFlex justifyContent="space-between" alignItems="center">
        <FormBox>
          <Field name="total">
            {({ input }) => (
              <Total>
                <Strong>TOTAL</Strong> <span>${input.value}</span>
              </Total>
            )}
          </Field>
        </FormBox>
        <FormBox>
          <Button sm onClick={handleSubmit} rounded disabled={loading || submitting}>
            {submitting ? 'Please wait…' : 'Pay'}
          </Button>
        </FormBox>
      </FooterFormFlex>
    </form>
  );
}

const Strong = styled.strong`
  font-weight: bold;
`;

const Total = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-size: 1.125rem;
  text-align: left;

  strong {
    font-weight: bold;
  }
`;

const FooterFormFlex = styled(FormFlex)`
  padding: 2rem;
`;

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

export default Form;
