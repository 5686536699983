import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withState, withHandlers } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form as FinalForm } from 'react-final-form';
import createFocusDecorator from 'final-form-focus';
import * as R from 'ramda';

import * as actions from 'actions';

import { holdMutators } from 'utils';

import Form from './Form';
import { decorator, handlers } from './shared';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    appraisalPayment: PropTypes.objectOf(PropTypes.any),
    token: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
};

const focusOnError = createFocusDecorator();

function New({ onSubmit, initialValues, loading }) {
  const isAllPropertiesMultiFamily = R.path(['appraisalPayment', 'isAllPropertiesMultiFamily'], initialValues);

  return (
    <FinalForm
      onSubmit={onSubmit}
      decorators={[focusOnError, decorator]}
      initialValues={initialValues}
      isAllPropertiesMultiFamily={isAllPropertiesMultiFamily}
      mutators={{ ...holdMutators }}
      subscription={{ submitError: true, submitting: true, pristine: true }}
      loading={loading}
      component={Form}
    />
  );
}

New.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
  return {
    fetchAppraisalPayment: bindActionCreators(actions.fetchAppraisalPayment, dispatch),
    createAppraisalPayment: bindActionCreators(actions.createAppraisalPayment, dispatch),
  };
}

const enhance = compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps,
  ),
  withState('loading', 'setLoading', false),
  withHandlers({
    setDefaultInitialValues: handlers.setDefaultInitialValues,
  }),
  withState('initialValues', 'setInitialValues', ({ setDefaultInitialValues }) => setDefaultInitialValues()),
  withHandlers({
    mergeInitialValues: handlers.mergeInitialValues,
    onSubmit: handlers.onSubmit,
  }),
  lifecycle({
    async componentDidMount() {
      const { match, fetchAppraisalPayment, mergeInitialValues, setLoading } = this.props;
      const { token } = match.params;
      setLoading(true);
      await fetchAppraisalPayment(token).then(mergeInitialValues);
      setLoading(false);
    },
  }),
  pure,
);

export default enhance(New);
