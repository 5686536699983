import React from 'react';
import PropTypes from 'prop-types';

import { Button, Hr } from 'components/shared';
import { FormFlex, FormBox } from 'components/shared/form';

import Fields from './Fields';
import Loader from './Loader';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  signatureVersion: PropTypes.string,
};

const defaultProps = {
  signatureVersion: 'v1',
};

function Form({ handleSubmit, submitting, loading, signatureVersion }) {
  return (
    <form>
      {loading && <Loader />}
      {!loading && <Fields signatureVersion={signatureVersion} />}

      <FormFlex>
        <FormBox width={1}>
          <Hr />
        </FormBox>
      </FormFlex>

      <FormFlex alignItems="center" justifyContent="flex-start">
        <FormBox>
          <Button onClick={handleSubmit} dataName="buttonSave" rounded disabled={loading || submitting}>
            {submitting ? 'Please wait…' : 'Save'}
          </Button>
        </FormBox>
      </FormFlex>
    </form>
  );
}

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

export default Form;
