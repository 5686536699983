import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ifProp, palette } from 'styled-tools';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  inline: PropTypes.bool,
  fz: PropTypes.number,
  label: PropTypes.node,
};

const defaultProps = {
  children: null,
  className: null,
  name: null,
  id: null,
  inline: false,
  fz: 1.125,
  label: null,
};

const fontSize = ({ fz }) => `${fz}rem`;

const Radio = ({ children, className, id, inline, name, label, ...props }) => (
  <Container inline={inline} className={className}>
    <StyledInput name={name} id={id || name} type="radio" {...props} />
    <StyledLabel htmlFor={id || name}>{label || children || ''}</StyledLabel>
  </Container>
);

const StyledInput = styled.input`
  &:not(:checked),
  &:checked {
    position: absolute;
    left: -9999px;
    transition: all 0.2s ease-in-out;
  }
  &:not(:checked) + label,
  &:checked + label {
    position: relative;
    display: block;
    padding-left: 30px;
    line-height: 32px;
    font-size: ${fontSize};
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-weight: normal;
  }
  &:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 4px;
    width: 20px;
    height: 20px;
    border: 2px solid #a3a6a8;
    background: #fff;
    border-radius: 30px;
    box-shadow: none;
    background-repeat: no-repeat;
    background-position: 0px 12px;
    animation-duration: 0.2s;
  }
  &:checked + label {
    color: ${palette('primary', 0)};
  }
  &:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 4px;
    width: 20px;
    height: 20px;
    border: 2px solid ${palette('primary', 0)};
    transition: border cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    color: ${palette('primary', 0)};
    background: #fff;
    border-radius: 30px;
    box-shadow: none;
    background-repeat: no-repeat;
    background-position: 0px 12px;
    animation-duration: 0.2s;
  }
  &:not(:checked) + label:after,
  &:checked + label:after {
    content: '';
    position: absolute;
    display: block;
    top: 10px;
    left: 6px;
    font-size: 1.125rem;
    width: 12px;
    height: 12px;
    border: none;
    background: ${palette('primary', 0)};
    border-radius: 30px;
    transition: all 0.2s;
  }
  &:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }
  &:checked + label:after {
    opacity: 1;
    color: #111111;
    transform: scale(1);
    animation-duration: 0.2s;
  }
  &:disabled {
    &:not(:checked) + label:before {
      box-shadow: none;
      border-color: #bbb;
      background-color: #ddd;
    }
    &:checked + label {
      &:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #ddd;
      }
      &:after {
        color: #999;
      }
    }
    + label {
      color: #aaa;
    }
  }
`;

const StyledLabel = styled.label``;

const Container = styled.div`
  position: relative;
  display: ${ifProp('inline', 'inline-block', 'block')};
  & + & {
    margin-top: ${ifProp('inline', '0', '1.25rem')};
    margin-left: ${ifProp('inline', '1.25rem', '0')};
  }
`;

Radio.propTypes = propTypes;
Radio.defaultProps = defaultProps;
export default Radio;
