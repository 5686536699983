import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { IconLink } from 'components/shared';
import { FormBox, FormGroupAdapter, FormFlex } from 'components/shared/form';
import { getAddressRefs } from 'selectors/refs';
import { getApplication } from 'selectors/applications';
import { validateRequired } from 'utils';

const propTypes = {
  index: PropTypes.number,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  name: PropTypes.string.isRequired,
};

const defaultProps = {
  index: undefined,
};

function Fields({ index, fields, name }) {
  const { statesCollection } = useSelector(getAddressRefs);
  const application = useSelector(getApplication);

  const displayBankCityAndStateFields = application
    ? moment(application.settlementDate).isSameOrAfter(moment('2023-12-28'))
    : false;

  return (
    <Fragment>
      {fields.length > 1 && (
        <LegendFormFlex justifyContent="flex-end">
          <FormBox>
            <IconLink
              icon="cross"
              width={0.6}
              onClick={() => {
                fields.remove(index);
              }}
            />
          </FormBox>
        </LegendFormFlex>
      )}

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={`${name}.bankName`}
            required
            label="Bank Name"
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={`${name}.accountName`}
            required
            label="Account Name"
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={`${name}.routingNumber`}
            required
            label="Routing #"
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={`${name}.accountNumber`}
            required
            label="Account Number"
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        {displayBankCityAndStateFields ? (
          <>
            <FormBox width={[1, 1, 1 / 3]}>
              <Field
                name={`${name}.bankCity`}
                required
                label="Bank City"
                validate={validateRequired()}
                component={FormGroupAdapter}
              />
            </FormBox>
            <FormBox width={[1, 1, 1 / 3]}>
              <Field
                name={`${name}.bankState`}
                required
                label="Bank State"
                validate={validateRequired()}
                component={FormGroupAdapter}
                type="select"
                options={statesCollection}
              />
            </FormBox>
          </>
        ) : (
          <FormBox width={1}>
            <Field
              name={`${name}.mailingAddress`}
              required
              label="Mailing Address"
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        )}
      </FormFlex>
    </Fragment>
  );
}

const LegendFormFlex = styled(FormFlex)`
  margin-bottom: 1rem;
`;

Fields.propTypes = propTypes;
Fields.defaultProps = defaultProps;

export default Fields;
