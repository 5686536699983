import { createSelector } from 'reselect';
import * as R from 'ramda';

import {
  getApplicationPropertiesIds,
  getApplicationProperties,
  getApplicationProfileId,
  getApplicationEntityId,
  getApplicationLoanId,
  getApplicationsDownloadLoanDocumentsAlowed,
} from 'selectors/applications';
import { getDocuments, getСategoriesWithDocumentsIds } from 'selectors/documents';

import {
  documentsCategoriesRatioGetter,
  documentsPropertyRequestedTreeCheckedKeysGetter,
  documentsPropertyRequestedTreeKeysGetter,
  documentsPropertyMissingTreeCheckedKeysGetter,
  documentsPropertyMissingTreeKeysGetter,
  documentsTreeCheckedKeysGetter,
  documentsCategoriesRatioTargetIdsGetter,
  documentsTreeKeysGetter,
  documentsTreeStatusesGetter,
} from 'utils/documentCategories';

const filterDocumentsCategoriesBy = (pred, categories) => {
  if (categories && categories.length > 0) {
    return R.filter(pred, categories);
  }
  return [];
};

const getBy = (pred, selector) =>
  createSelector(
    selector,
    (categories) => filterDocumentsCategoriesBy(pred, categories),
  );

const getByTarget = (selector, target) => getBy(R.propEq('target', target), selector);
const getByStatus = (selector, requirementStatus) => getBy(R.propEq('requirementStatus', requirementStatus), selector);

export const initialState = [];

// const documentCategoriesSelector = (state = initialState) => state.documentCategories;

function documentCategoriesSelector(state) {
  return R.filter(R.prop('requiredSinceStatusPresent'), state.documentCategories) || initialState;
}

function documentUnfilteredCategoriesSelector(state) {
  return state.documentCategories || initialState;
}

export const getDocumentCategories = createSelector(
  documentCategoriesSelector,
  (documentCategories) => documentCategories,
);

export const getUnfilteredDocumentCategories = createSelector(
  documentUnfilteredCategoriesSelector,
  (documentCategories) => documentCategories,
);

const categoryIdSelector = (state, categoryId) => categoryId;

export const getDocumentCategoryById = createSelector(
  [getUnfilteredDocumentCategories, categoryIdSelector],
  (categories, id) => R.find(R.propEq('id', id), categories),
);

export const getTargetTypeByDocumentCategoryId = createSelector(
  getDocumentCategoryById,
  (category) => category.target,
);

export const getContactProfileDocumentCategories = getByTarget(getDocumentCategories, 'Contact::Profile');
export const getEntityDocumentCategories = getByTarget(getDocumentCategories, 'Entity');
export const getLoanDocumentCategories = getByTarget(getDocumentCategories, 'Loan');
export const getPropertyDocumentCategories = getByTarget(getDocumentCategories, 'Property');

export const getContactProfileUnfilteredDocumentCategories = getByTarget(getUnfilteredDocumentCategories, 'Contact::Profile');
export const getEntityUnfilteredDocumentCategories = getByTarget(getUnfilteredDocumentCategories, 'Entity');
export const getLoanUnfilteredDocumentCategories = getByTarget(getUnfilteredDocumentCategories, 'Loan');
export const getPropertyUnfilteredDocumentCategories = getByTarget(getUnfilteredDocumentCategories, 'Property');

export const getNowContactProfileDocumentCategories = getByStatus(getContactProfileDocumentCategories, 'now');
export const getNowEntityDocumentCategories = getByStatus(getEntityDocumentCategories, 'now');
export const getNowLoanDocumentCategories = getByStatus(getLoanDocumentCategories, 'now');
export const getNowPropertyDocumentCategories = getByStatus(getPropertyDocumentCategories, 'now');

export const getBeforeClosingContactProfileDocumentCategories = getByStatus(
  getContactProfileDocumentCategories,
  'before_closing',
);
export const getBeforeClosingEntityDocumentCategories = getByStatus(getEntityDocumentCategories, 'before_closing');
export const getBeforeClosingLoanDocumentCategories = getByStatus(getLoanDocumentCategories, 'before_closing');
export const getBeforeClosingPropertyDocumentCategories = getByStatus(getPropertyDocumentCategories, 'before_closing');

export const getSupplementingContactProfileDocumentCategories = getByStatus(
  getContactProfileDocumentCategories,
  'supplementing',
);
export const getSupplementingEntityDocumentCategories = getByStatus(getEntityDocumentCategories, 'supplementing');
export const getSupplementingLoanDocumentCategories = getByStatus(getLoanDocumentCategories, 'supplementing');
export const getSupplementingPropertyDocumentCategories = getByStatus(getPropertyDocumentCategories, 'supplementing');

export const getRequestedContactProfileDocumentCategories = createSelector(
  getContactProfileUnfilteredDocumentCategories,
  R.filter(R.prop('isRequested')),
);

export const getRequestedEntityDocumentCategories = createSelector(
  getEntityUnfilteredDocumentCategories,
  R.filter(R.prop('isRequested')),
);

export const getRequestedLoanDocumentCategories = createSelector(
  getLoanUnfilteredDocumentCategories,
  R.filter(R.prop('isRequested')),
);

export const getRequestedPropertyDocumentCategories = createSelector(
  [getPropertyUnfilteredDocumentCategories, getApplicationPropertiesIds],
  R.ifElse(R.flip(R.isEmpty), R.always([]), R.filter(R.prop('isRequested'))),
);

export const getMissingContactProfileDocumentCategories = createSelector(
  getContactProfileUnfilteredDocumentCategories,
  R.filter(R.prop('isMissing')),
);

export const getMissingEntityDocumentCategories = createSelector(
  getEntityUnfilteredDocumentCategories,
  R.filter(R.prop('isMissing')),
);

export const getMissingLoanDocumentCategories = createSelector(
  getLoanUnfilteredDocumentCategories,
  R.filter(R.prop('isMissing')),
);

export const getMissingPropertyDocumentCategories = createSelector(
  [getPropertyUnfilteredDocumentCategories, getApplicationPropertiesIds],
  R.ifElse(R.flip(R.isEmpty), R.always([]), R.filter(R.prop('isMissing'))),
);

export const getAvailableDownloadContactProfileDocumentCategories = createSelector(
  getContactProfileDocumentCategories,
  R.filter(R.prop('availableToDownload')),
);

export const getAvailableDownloadEntityDocumentCategories = createSelector(
  getEntityDocumentCategories,
  R.filter(R.prop('availableToDownload')),
);

export const getAvailableDownloadLoanDocumentCategories = createSelector(
  getLoanDocumentCategories,
  R.filter(R.prop('availableToDownload')),
);

export const getAvailableDownloadLoanDocumentCategoriesWithDocuments = createSelector(
  [
    getAvailableDownloadLoanDocumentCategories,
    getСategoriesWithDocumentsIds,
    getApplicationsDownloadLoanDocumentsAlowed,
  ],
  (categories, categoriesWithDocumentsIds, downloadLoanDocumentsAlowed) => {
    const categoriesCollection = categories.filter((cat) => R.includes(cat.id, categoriesWithDocumentsIds));
    if (downloadLoanDocumentsAlowed && categoriesCollection.length > 0) {
      return categoriesCollection;
    }
    return [];
  },
);

export const getAvailableDownloadContactProfileDocumentCategoriesWithDocuments = createSelector(
  [getAvailableDownloadContactProfileDocumentCategories, getСategoriesWithDocumentsIds],
  (categories, categoriesWithDocumentsIds) =>
    categories.filter((cat) => R.includes(cat.id, categoriesWithDocumentsIds)),
);

export const getAvailableDownloadPropertyDocumentCategories = createSelector(
  [getPropertyDocumentCategories, getApplicationPropertiesIds],
  R.ifElse(R.flip(R.isEmpty), R.always([]), R.filter(R.prop('availableToDownload'))),
);

const getContactProfileDocumentsTreeCheckedKeys = createSelector(
  [getContactProfileDocumentCategories, getApplicationProfileId, getDocuments],
  documentsTreeCheckedKeysGetter('borrower'),
);

export const getContactProfileDocumentsCategoriesRatio = createSelector(
  [getContactProfileDocumentCategories, getApplicationProfileId, getDocuments],
  documentsCategoriesRatioGetter,
);

const getContactProfileDocumentsTreeKeys = createSelector(
  [getContactProfileDocumentCategories, getApplicationProfileId],
  documentsTreeKeysGetter('borrower'),
);

const getEntityDocumentsTreeCheckedKeys = createSelector(
  [getEntityDocumentCategories, getApplicationEntityId, getDocuments],
  documentsTreeCheckedKeysGetter('entity'),
);

export const getEntityDocumentsCategoriesRatio = createSelector(
  [getEntityDocumentCategories, getApplicationEntityId, getDocuments],
  documentsCategoriesRatioGetter,
);

const getEntityDocumentsTreeKeys = createSelector(
  [getEntityDocumentCategories, getApplicationEntityId],
  documentsTreeKeysGetter('entity'),
);

const getLoanDocumentsTreeCheckedKeys = createSelector(
  [getLoanDocumentCategories, getApplicationLoanId, getDocuments],
  documentsTreeCheckedKeysGetter('loan'),
);

export const getLoanDocumentsCategoriesRatio = createSelector(
  [getLoanDocumentCategories, getApplicationLoanId, getDocuments],
  documentsCategoriesRatioGetter,
);

const getLoanDocumentsTreeKeys = createSelector(
  [getLoanDocumentCategories, getApplicationLoanId],
  documentsTreeKeysGetter('loan'),
);

const getPropertyDocumentsTreeCheckedKeys = createSelector(
  [getPropertyDocumentCategories, getApplicationPropertiesIds, getDocuments],
  documentsTreeCheckedKeysGetter('property'),
);

export const getPropertyDocumentsCategoriesRatio = createSelector(
  [getPropertyDocumentCategories, getApplicationPropertiesIds, getDocuments],
  documentsCategoriesRatioTargetIdsGetter,
);

const getPropertyDocumentsTreeKeys = createSelector(
  [getPropertyDocumentCategories, getApplicationPropertiesIds],
  documentsTreeKeysGetter('property'),
);

const getRequestedContactProfileDocumentCategoriesCheckedKeys = createSelector(
  [getRequestedContactProfileDocumentCategories, getApplicationProfileId, getDocuments],
  documentsTreeCheckedKeysGetter('requested-borrower'),
);

const getRequestedContactProfileDocumentCategoriesKeys = createSelector(
  [getRequestedContactProfileDocumentCategories, getApplicationProfileId],
  documentsTreeKeysGetter('requested-borrower'),
);

const getRequestedEntityDocumentCategoriesCheckedKeys = createSelector(
  [getRequestedEntityDocumentCategories, getApplicationEntityId, getDocuments],
  documentsTreeCheckedKeysGetter('requested-entity'),
);

const getRequestedEntityDocumentCategoriesKeys = createSelector(
  [getRequestedEntityDocumentCategories, getApplicationEntityId],
  documentsTreeKeysGetter('requested-entity'),
);

const getRequestedLoanDocumentCategoriesCheckedKeys = createSelector(
  [getRequestedLoanDocumentCategories, getApplicationLoanId, getDocuments],
  documentsTreeCheckedKeysGetter('requested-loan'),
);

const getRequestedLoanDocumentCategoriesKeys = createSelector(
  [getRequestedLoanDocumentCategories, getApplicationLoanId],
  documentsTreeKeysGetter('requested-loan'),
);

const getMissingContactProfileDocumentCategoriesCheckedKeys = createSelector(
  [getMissingContactProfileDocumentCategories, getApplicationProfileId, getDocuments],
  documentsTreeCheckedKeysGetter('missing-borrower'),
);

const getMissingContactProfileDocumentCategoriesKeys = createSelector(
  [getMissingContactProfileDocumentCategories, getApplicationProfileId],
  documentsTreeKeysGetter('missing-borrower'),
);

const getMissingEntityDocumentCategoriesCheckedKeys = createSelector(
  [getMissingEntityDocumentCategories, getApplicationEntityId, getDocuments],
  documentsTreeCheckedKeysGetter('missing-entity'),
);

const getMissingEntityDocumentCategoriesKeys = createSelector(
  [getMissingEntityDocumentCategories, getApplicationEntityId],
  documentsTreeKeysGetter('missing-entity'),
);

const getMissingLoanDocumentCategoriesCheckedKeys = createSelector(
  [getMissingLoanDocumentCategories, getApplicationLoanId, getDocuments],
  documentsTreeCheckedKeysGetter('missing-loan'),
);

const getMissingLoanDocumentCategoriesKeys = createSelector(
  [getMissingLoanDocumentCategories, getApplicationLoanId],
  documentsTreeKeysGetter('missing-loan'),
);

const getAvailableDownloadLoanDocumentCategoriesKeys = createSelector(
  [getAvailableDownloadLoanDocumentCategories, getApplicationLoanId],
  documentsTreeKeysGetter('download-loan'),
);

const getAvailableDownloadContactProfileDocumentCategoriesCheckedKeys = createSelector(
  [getAvailableDownloadContactProfileDocumentCategories, getApplicationProfileId, getDocuments],
  documentsTreeCheckedKeysGetter('download-borrower'),
);

const getAvailableDownloadContactProfileDocumentCategoriesKeys = createSelector(
  [getAvailableDownloadContactProfileDocumentCategories, getApplicationProfileId],
  documentsTreeKeysGetter('download-borrower'),
);

const getRequestedPropertyDocumentCategoriesCheckedKeys = createSelector(
  [getApplicationProperties, getDocuments],
  documentsPropertyRequestedTreeCheckedKeysGetter('requested-property'),
);

const getRequestedPropertyDocumentCategoriesKeys = createSelector(
  getApplicationProperties,
  documentsPropertyRequestedTreeKeysGetter('requested-property'),
);

const getMissingPropertyDocumentCategoriesCheckedKeys = createSelector(
  [getApplicationProperties, getDocuments],
  documentsPropertyMissingTreeCheckedKeysGetter('missing-property'),
);

const getMissingPropertyDocumentCategoriesKeys = createSelector(
  getApplicationProperties,
  documentsPropertyMissingTreeKeysGetter('missing-property'),
);

export const getRequestedDocumentsTreeCheckedKeys = createSelector(
  [
    getRequestedContactProfileDocumentCategoriesCheckedKeys,
    getRequestedEntityDocumentCategoriesCheckedKeys,
    getRequestedLoanDocumentCategoriesCheckedKeys,
    getRequestedPropertyDocumentCategoriesCheckedKeys,
  ],
  R.unapply(R.reduce(R.concat, [])),
);

export const getRequestedDocumentsTreeKeys = createSelector(
  [
    getRequestedContactProfileDocumentCategoriesKeys,
    getRequestedEntityDocumentCategoriesKeys,
    getRequestedLoanDocumentCategoriesKeys,
    getRequestedPropertyDocumentCategoriesKeys,
  ],
  R.unapply(R.reduce(R.concat, [])),
);

export const getMissingDocumentsTreeCheckedKeys = createSelector(
  [
    getMissingContactProfileDocumentCategoriesCheckedKeys,
    getMissingEntityDocumentCategoriesCheckedKeys,
    getMissingLoanDocumentCategoriesCheckedKeys,
    getMissingPropertyDocumentCategoriesCheckedKeys,
  ],
  R.unapply(R.reduce(R.concat, [])),
);

export const getMissingDocumentsTreeKeys = createSelector(
  [
    getMissingContactProfileDocumentCategoriesKeys,
    getMissingEntityDocumentCategoriesKeys,
    getMissingLoanDocumentCategoriesKeys,
    getMissingPropertyDocumentCategoriesKeys,
  ],
  R.unapply(R.reduce(R.concat, [])),
);

export const getDownloadDocumentsTreeCheckedKeys = createSelector(
  [getAvailableDownloadContactProfileDocumentCategoriesCheckedKeys],
  R.unapply(R.reduce(R.concat, [])),
);

export const getDownloadDocumentsTreeKeys = createSelector(
  [getAvailableDownloadLoanDocumentCategoriesKeys, getAvailableDownloadContactProfileDocumentCategoriesKeys],
  R.unapply(R.reduce(R.concat, [])),
);

export const getDocumentsTreeKeys = createSelector(
  [
    getContactProfileDocumentsTreeKeys,
    getEntityDocumentsTreeKeys,
    getLoanDocumentsTreeKeys,
    getPropertyDocumentsTreeKeys,
    getRequestedDocumentsTreeKeys,
    getMissingDocumentsTreeKeys,
    getDownloadDocumentsTreeKeys,
  ],
  R.unapply(R.reduce(R.concat, [])),
);

export const getDocumentsTreeCheckedKeys = createSelector(
  [
    getContactProfileDocumentsTreeCheckedKeys,
    getEntityDocumentsTreeCheckedKeys,
    getLoanDocumentsTreeCheckedKeys,
    getPropertyDocumentsTreeCheckedKeys,
    getRequestedDocumentsTreeCheckedKeys,
    getMissingDocumentsTreeCheckedKeys,
    getDownloadDocumentsTreeCheckedKeys,
  ],
  R.unapply(R.reduce(R.concat, [])),
);

const getContactProfileDocumentsTreeStatuses = createSelector(
  [getContactProfileDocumentCategories, getApplicationProfileId, getDocuments],
  documentsTreeStatusesGetter('borrower'),
);

const getEntityDocumentsTreeStatuses = createSelector(
  [getEntityDocumentCategories, getApplicationEntityId, getDocuments],
  documentsTreeStatusesGetter('entity'),
);

const getLoanDocumentsTreeStatuses = createSelector(
  [getLoanDocumentCategories, getApplicationLoanId, getDocuments],
  documentsTreeStatusesGetter('loan'),
);

const getPropertyDocumentsTreeStatuses = createSelector(
  [getPropertyDocumentCategories, getApplicationPropertiesIds, getDocuments],
  documentsTreeStatusesGetter('property'),
);

const getRequestedContactProfileDocumentCategoriesStatuses = createSelector(
  [getRequestedContactProfileDocumentCategories, getApplicationProfileId, getDocuments],
  documentsTreeStatusesGetter('requested-borrower'),
);

const getRequestedEntityDocumentCategoriesStatuses = createSelector(
  [getRequestedEntityDocumentCategories, getApplicationEntityId, getDocuments],
  documentsTreeStatusesGetter('requested-entity'),
);

const getRequestedLoanDocumentCategoriesStatuses = createSelector(
  [getRequestedLoanDocumentCategories, getApplicationLoanId, getDocuments],
  documentsTreeStatusesGetter('requested-loan'),
);

const getRequestedPropertyDocumentCategoriesStatuses = createSelector(
  [getRequestedPropertyDocumentCategories, getApplicationPropertiesIds, getDocuments],
  documentsTreeStatusesGetter('requested-property'),
);

const getMissingContactProfileDocumentCategoriesStatuses = createSelector(
  [getMissingContactProfileDocumentCategories, getApplicationProfileId, getDocuments],
  documentsTreeStatusesGetter('missing-borrower'),
);

const getMissingEntityDocumentCategoriesStatuses = createSelector(
  [getMissingEntityDocumentCategories, getApplicationEntityId, getDocuments],
  documentsTreeStatusesGetter('missing-entity'),
);

const getMissingLoanDocumentCategoriesStatuses = createSelector(
  [getMissingLoanDocumentCategories, getApplicationLoanId, getDocuments],
  documentsTreeStatusesGetter('missing-loan'),
);

const getMissingPropertyDocumentCategoriesStatuses = createSelector(
  [getMissingPropertyDocumentCategories, getApplicationPropertiesIds, getDocuments],
  documentsTreeStatusesGetter('missing-property'),
);

const getAvailableDownloadLoanDocumentCategoriesStatuses = createSelector(
  [getAvailableDownloadLoanDocumentCategories, getApplicationLoanId, getDocuments],
  documentsTreeStatusesGetter('download-loan'),
);

const getAvailableDownloadContactProfileDocumentCategoriesStatuses = createSelector(
  [getAvailableDownloadContactProfileDocumentCategories, getApplicationProfileId, getDocuments],
  documentsTreeStatusesGetter('download-borrower'),
);

const getRequestedDocumentsTreeStatuses = createSelector(
  [
    getRequestedContactProfileDocumentCategoriesStatuses,
    getRequestedEntityDocumentCategoriesStatuses,
    getRequestedLoanDocumentCategoriesStatuses,
    getRequestedPropertyDocumentCategoriesStatuses,
  ],
  R.unapply(R.reduce(R.mergeRight, {})),
);

const getMissingDocumentsTreeStatuses = createSelector(
  [
    getMissingContactProfileDocumentCategoriesStatuses,
    getMissingEntityDocumentCategoriesStatuses,
    getMissingLoanDocumentCategoriesStatuses,
    getMissingPropertyDocumentCategoriesStatuses,
  ],
  R.unapply(R.reduce(R.mergeRight, {})),
);

const getAvailableDownloadDocumentsTreeStatuses = createSelector(
  [getAvailableDownloadLoanDocumentCategoriesStatuses, getAvailableDownloadContactProfileDocumentCategoriesStatuses],
  R.unapply(R.reduce(R.mergeRight, {})),
);

export const getDocumentsTreeStatuses = createSelector(
  [
    getContactProfileDocumentsTreeStatuses,
    getEntityDocumentsTreeStatuses,
    getLoanDocumentsTreeStatuses,
    getPropertyDocumentsTreeStatuses,
    getRequestedDocumentsTreeStatuses,
    getMissingDocumentsTreeStatuses,
    getAvailableDownloadDocumentsTreeStatuses,
  ],
  R.unapply(R.reduce(R.mergeRight, {})),
);

export const getRequestedDocumentCategories = createSelector(
  [
    getRequestedContactProfileDocumentCategories,
    getRequestedEntityDocumentCategories,
    getRequestedLoanDocumentCategories,
    getRequestedPropertyDocumentCategories,
  ],
  R.unapply(R.reduce(R.concat, [])),
);

export const getMissingDocumentCategories = createSelector(
  [
    getMissingContactProfileDocumentCategories,
    getMissingEntityDocumentCategories,
    getMissingLoanDocumentCategories,
    getMissingPropertyDocumentCategories,
  ],
  R.unapply(R.reduce(R.concat, [])),
);

export const getAvailableDownloadDocumentCategories = createSelector(
  [
    getAvailableDownloadLoanDocumentCategoriesWithDocuments,
    getAvailableDownloadContactProfileDocumentCategoriesWithDocuments,
  ],
  R.unapply(R.reduce(R.concat, [])),
);

export const getHasRequestedDocumentCategories = createSelector(
  getRequestedDocumentCategories,
  R.complement(R.isEmpty),
);

export const getHasMissingDocumentCategories = createSelector(
  getMissingDocumentCategories,
  R.complement(R.isEmpty),
);

export const getHasAvailableDownloadDocumentCategories = createSelector(
  getAvailableDownloadDocumentCategories,
  R.complement(R.isEmpty),
);

export const getHasContactProfileDocumentCategories = createSelector(
  getContactProfileDocumentCategories,
  R.complement(R.isEmpty),
);

export const getHasEntityDocumentCategories = createSelector(
  getEntityDocumentCategories,
  R.complement(R.isEmpty),
);

export const getHasLoanDocumentCategories = createSelector(
  getLoanDocumentCategories,
  R.complement(R.isEmpty),
);

export const getHasPropertyDocumentCategories = createSelector(
  getPropertyDocumentCategories,
  R.complement(R.isEmpty),
);

export const getConstructionBudgetDocumentCategory = createSelector(
  getUnfilteredDocumentCategories,
  (categories) => {
    if (categories && categories.length > 0) {
      return R.find(R.propEq('key', 'construction_budget'), categories);
    }
    return undefined;
  },
);

export const getOperatingAgreementDocumentCategory = createSelector(
  getUnfilteredDocumentCategories,
  (categories) => {
    if (categories && categories.length > 0) {
      return R.find(R.propEq('key', 'operating_agreement'), categories);
    }
    return undefined;
  },
);

export const getCurrentInvestmentPropertiesDocumentCategory = createSelector(
  getUnfilteredDocumentCategories,
  (categories) => {
    if (categories && categories.length) {
      return R.find(R.propEq('key', 'current_investment_properties'), categories);
    }
    return undefined;
  },
);

export const getSaleSignedAgreementDocumentCategory = createSelector(
  getUnfilteredDocumentCategories,
  (categories) => {
    if (categories && categories.length) {
      return R.find(R.propEq('key', 'sale_signed_agreement'), categories);
    }
    return undefined;
  },
);

export const getSupplementingDocumentsDocumentCategory = createSelector(
  getUnfilteredDocumentCategories,
  (categories) => {
    if (categories && categories.length) {
      return R.find(R.propEq('key', 'supplementing_documents'), categories);
    }
    return undefined;
  },
);

export const getSupplementingDocumentsDocumentCategoryId = createSelector(
  getSupplementingDocumentsDocumentCategory,
  R.prop('id'),
);
