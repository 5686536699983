import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure, withContext } from 'recompose';

import { styleUtils } from 'utils';

import Actions from './Actions';
import Body from './Body';
import Title from './Title';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  content: PropTypes.node,
  size: PropTypes.string,
};

const defaultProps = {
  className: undefined,
  children: undefined,
  content: 'nothing to render',
  size: 'default',
};

function ToDo({ children, content, className, size }) {
  if (children) {
    return (
      <StyledToDo size={size} className={className}>
        {children}
      </StyledToDo>
    );
  }
  return (
    <StyledToDo size={size} className={className}>
      {content}
    </StyledToDo>
  );
}

const StyledToDo = styled.div`
  background: #ffffff;
  border: ${styleUtils.getSizeValue(0.0625)} solid #ebebeb;
  padding: ${styleUtils.getSizeValue(1.25)};
  box-shadow: 0 ${styleUtils.getSizeValue(0.25)} ${styleUtils.getSizeValue(0.375)} 0 rgba(234, 234, 234, 0.5);
  font-size: ${styleUtils.getSizeValue(1)};
`;

ToDo.propTypes = propTypes;
ToDo.defaultProps = defaultProps;

export { Actions, Body, Title };

const enhance = compose(
  withContext(
    {
      size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    },
    ({ size }) => ({ size }),
  ),
  pure,
);

const EnhancedToDo = enhance(ToDo);

EnhancedToDo.Actions = Actions;
EnhancedToDo.Body = Body;
EnhancedToDo.Title = Title;

export default EnhancedToDo;
