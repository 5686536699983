import { toast, cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { COMMON_ERROR_MESSAGE, INTERNAL_ERROR_MESSAGE, TOO_MANY_REQUESTS_MESSAGE } from 'constants/errors';

import './style.css';
import { createElement } from 'react';

const Zoom = cssTransition({
  enter: 'zoomIn',
  exit: 'zoomOut',
  duration: 200,
});

const commonStyle = {
  hideProgressBar: true,
  autoClose: 3000,
  transition: Zoom,
};

export function successNotification(message, position = 'top-right') {
  toast.success(message, {
    position,
    ...commonStyle,
  });
}

export function errorNotification(message, position = 'top-right') {
  const fn = () => createElement('div', { dangerouslySetInnerHTML: { __html: message } });

  toast.error(fn, {
    position,
    ...commonStyle,
  });
}

export function warnNotification(message, position = 'top-right') {
  toast.warn(message, {
    position,
    ...commonStyle,
  });
}

export function infoNotification(message, position = 'top-right') {
  toast.info(message, {
    position,
    ...commonStyle,
  });
}

export function internalRespondErrorNotification(res = {}, message = INTERNAL_ERROR_MESSAGE, position = 'top-right') {
  if (res.status !== 500) return;
  errorNotification(message, position);
}

export function notInternalRespondErrorNotification(res = {}, message = COMMON_ERROR_MESSAGE, position = 'top-right') {
  if (res.status === 500) return;
  errorNotification(message, position);
}

export function tooManyRequestsNotification(res = {}, message = TOO_MANY_REQUESTS_MESSAGE, position = 'top-right') {
  if (res.status !== 429) return;
  errorNotification(message, position);
}
