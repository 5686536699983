import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withState } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Form as FinalForm } from 'react-final-form';
import createFocusDecorator from 'final-form-focus';
import * as R from 'ramda';

import * as actions from 'actions';

import { setIn } from 'utils';

import Form from './Form';

const propTypes = {
  createPayoffRequest: PropTypes.func.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
};

const defaultProps = {};

const focusOnError = createFocusDecorator();

function New({ createPayoffRequest, initialValues }) {
  return (
    <FinalForm
      onSubmit={createPayoffRequest}
      decorators={[focusOnError]}
      initialValues={initialValues}
      subscription={{ submitting: true, pristine: true }}
      component={Form}
    />
  );
}

New.propTypes = propTypes;
New.defaultProps = defaultProps;

function mapDispatchToProps(dispatch) {
  return {
    createPayoffRequest: bindActionCreators(actions.createPayoffRequest, dispatch),
  };
}

const enhance = compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps,
  ),
  withState('initialValues', 'setInitialValues', ({ match }) => {
    const { token } = match.params;
    return R.pipe(setIn(token, 'token'))({});
  }),
  pure,
);

export default enhance(New);
