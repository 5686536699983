import objectToFormData from 'object-to-formdata';

import api from '../utils/api';
import { decamelizeKeys, downloadResponseFile, errorNotification } from '../utils';

import * as actionTypes from '../constants/actionTypes';
import * as requestTypes from '../constants/requestTypes';
import setRequestInProcess from '../actions/request';
import { fetchApplication } from './applications';

export const createDocument = ({ token, ...values }) => (dispatch) => {
  const { asset } = values.document;
  const formData = objectToFormData(decamelizeKeys({ ...values, document: { ...values.document, asset: undefined } }));
  formData.append('document[asset]', asset[0]);

  const url = `/applications/${token}/documents`;
  return api.post(url, formData).then((response) => {
    dispatch(fetchDocuments(token));
  });
};

export const createExplanationDocument = ({ token, ...values }) => (dispatch) => {
  const formData = objectToFormData(decamelizeKeys(values));

  const url = `/applications/${token}/documents`;
  return api.post(url, formData).then((response) => {
    dispatch(fetchDocuments(token));
  });
};

export const updateExplanationDocument = ({ token, ...values }) => (dispatch) => {
  const formData = objectToFormData(decamelizeKeys(values));

  const url = `/applications/${token}/documents/${values.document.id}`;
  return api.patch(url, formData).then((response) => {
    dispatch(fetchDocuments(token));
  });
};

export const deleteDocument = (token, id) => (dispatch) => {
  const url = `/applications/${token}/documents/${id}`;
  return api.delete(url).then((response) => {
    dispatch(fetchDocuments(token));
  });
};

export const downloadOrOpenDocument = (token, docData, requestTypeName) => (dispatch) => {
  const requestType = requestTypeName || requestTypes.DOWNLOAD_DOCUMENT_PDF;
  const url = `/applications/${token}/pdf/download/${docData.id}`;

  dispatch(setRequestInProcess(true, requestType));
  return api
    .get(url)
    .then((response) => {
      dispatch(setRequestInProcess(false, requestType));
      if (response.headers && response.headers.get('Content-Type') && response.headers.get('Content-Type') === 'application/pdf') {
        downloadResponseFile(`${docData.name}.pdf`, response);
      } else if (response.type && response.type === 'url' && response.data) {
        window.open(response.data, '_blank');
      }
      dispatch(fetchApplication(token));
    })
    .catch(() => {
      dispatch(setRequestInProcess(false, requestType));
      errorNotification('Document has been changed. Please refresh the page.');
    });
};

export const fetchDocuments = (token) => (dispatch) => {
  const requestType = requestTypes.DOCUMENTS;
  const url = `/applications/${token}/documents`;

  dispatch(setRequestInProcess(true, requestType));

  return api
    .get(url)
    .then(({ data }) => {
      dispatch(setRequestInProcess(false, requestType));
      dispatch(fetchDocumentsSuccess(data));
    })
    .catch((response) => console.log('response', response)); // FIXME to think about how to handle the error correctly
};

export function fetchDocumentsSuccess(payload) {
  return {
    type: actionTypes.DOCUMENTS_FETCH_SUCCESS,
    payload,
  };
}

export function resetDocuments() {
  return {
    type: actionTypes.DOCUMENTS_RESET,
  };
}
