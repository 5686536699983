import Rollbar from 'rollbar';

export default function rollbarErrorTracking(accessToken) {
  const rollbarObj = new Rollbar({
    accessToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: (process.env.REACT_APP_ENV !== 'development'),
    payload: {
      environment: process.env.REACT_APP_ENV,
    },
  });

  const logErrorInfo = (info) => {
    rollbarObj.info(info);
  };

  const logErrorInRollbar = (error) => {
    rollbarObj.error(error);
  };

  return { logErrorInfo, logErrorInRollbar };
}
