import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';

import { getRentalThirdStepRefs } from 'selectors';

import { Radio } from 'components/shared/formControls';
import {
  Condition,
  FormAsterisk,
  FormLegend,
  FormBox,
  NarrowFormFlex,
  FormGroupAdapter,
  RadioGroupAdapter,
} from 'components/shared/form';

import { validateRequired } from 'utils';

const propTypes = {
  refs: PropTypes.shape({
    creditScoresCollection: PropTypes.arrayOf(PropTypes.object),
    ownRentCollection: PropTypes.arrayOf(PropTypes.object),
    countriesCollection: PropTypes.arrayOf(PropTypes.object),
  }),
};

const defaultProps = {
  refs: {
    creditScoresCollection: [],
    ownRentCollection: [],
    countriesCollection: [],
  },
};

function Fields({ refs }) {
  return (
    <Fragment>
      <NarrowFormFlex>
        <FormBox width={1}>
          <Field
            name="application.rentalPropertiesCount"
            required
            typeOfMask="number"
            label="Number of Rental Properties you Currently Own"
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </NarrowFormFlex>

      <NarrowFormFlex>
        <FormBox width={1}>
          <Field
            name="application.creditScore"
            type="select"
            options={refs.creditScoresCollection}
            required
            label="Your Credit Score"
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </NarrowFormFlex>

      <LegendFormFlex>
        <FormBox width={1}>
          <FormLegend>Cash/Liquid Investments</FormLegend>
        </FormBox>
      </LegendFormFlex>

      <NarrowFormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="application.checkingSavingsAccounts"
            required
            label="Checking/Savings Accounts"
            typeOfMask="currency"
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="application.retirementAccounts"
            label="Retirement Accounts"
            typeOfMask="currency"
            component={FormGroupAdapter}
          />
        </FormBox>
      </NarrowFormFlex>

      <NarrowFormFlex>
        <FormBox>
          <Field
            component={RadioGroupAdapter}
            inline
            label="Primary Residence"
            name="application.owner"
            required
            type="checkbox"
            validate={validateRequired()}
          >
            {refs.ownRentCollection.map(({ label, value }) => (
              <Radio
                key={`own-rent-collection-${value}`}
                value={value}
                id={`primary-residence-${value}`}
                label={label}
              />
            ))}
          </Field>
        </FormBox>
      </NarrowFormFlex>

      <NarrowFormFlex>
        <FormBox width={1}>
          <Field
            name="application.legalName"
            required
            validate={validateRequired()}
            label="What is the name of your LLC or Company that owns or will own the Property?"
            component={FormGroupAdapter}
          />
        </FormBox>
      </NarrowFormFlex>

      <NarrowFormFlex>
        <FormBox width={1}>
          <Field
            component={RadioGroupAdapter}
            inline
            label="Have you filed for bankruptcy or had any properties foreclosed upon in the past 36 months?"
            name="application.bankruptcyProceedings"
            required
            type="checkbox"
            validate={validateRequired({ message: 'is not included in the list' })}
          >
            <Radio id="bankruptcyProceedingsTrue" value="true" label="Yes" />
            <Radio id="bankruptcyProceedingsFalse" value="false" label="No" />
          </Field>
        </FormBox>
      </NarrowFormFlex>

      <NarrowFormFlex>
        <FormBox width={1}>
          <Field
            component={RadioGroupAdapter}
            inline
            label="Are you a U.S. citizen?"
            name="application.usCitizen"
            required
            type="checkbox"
            validate={validateRequired({ message: 'is not included in the list' })}
          >
            <Radio value="true" id="usCitizenTrue" label="Yes" />
            <Radio value="false" id="usCitizenFalse" label="No" />
          </Field>
        </FormBox>
      </NarrowFormFlex>

      <Condition when="application.usCitizen" is="false">
        <NarrowFormFlex>
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              component={FormGroupAdapter}
              isConditional
              label="Country of Citizenship"
              name="application.countryOfCitizenship"
              options={refs.countriesCollection}
              required
              type="select"
              validate={validateRequired()}
            />
          </FormBox>
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              name="application.visaStatus"
              required
              isConditional
              label="Name or Code"
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              component={RadioGroupAdapter}
              inline
              isConditional
              label="Are you a permanent resident?"
              name="application.permanentResident"
              required
              type="checkbox"
              validate={validateRequired({ message: 'is not included in the list' })}
            >
              <Radio value="true" id="permanentResidentTrue" label="Yes" />
              <Radio value="false" id="permanentResidentFalse" label="No" />
            </Field>
          </FormBox>
        </NarrowFormFlex>
      </Condition>

      <NarrowFormFlex>
        <FormBox width={1}>
          <FormAsterisk>
            All red asterisks are required in order to provide you with an accurate rate quote.
          </FormAsterisk>
        </FormBox>
      </NarrowFormFlex>
    </Fragment>
  );
}

const LegendFormFlex = styled(NarrowFormFlex)`
  margin-bottom: 1rem;
`;

Fields.propTypes = propTypes;
Fields.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    refs: getRentalThirdStepRefs(state),
  };
}

const enhance = compose(
  connect(mapStateToProps),
  pure,
);

export default enhance(Fields);
