import * as R from 'ramda';

function alphabeticalSortingCollection(collection) {
  if (R.isNil(collection)) return collection;
  return R.sortBy(R.prop('label'), collection);
}

function usPriorityCountriesCollectionDecorator(collection = []) {
  const withUS = R.filter(R.propEq('value', 'US'), collection);
  const withoutUS = R.filter(R.pipe(R.propEq('value', 'US'), R.not), collection);
  return [
    {
      options: withUS,
    },
    {
      label: '--------------------------------',
      options: withoutUS,
    },
  ];
}

export { alphabeticalSortingCollection, usPriorityCountriesCollectionDecorator };
