import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { Field, useField } from 'react-final-form';

import { getApplicationTransactionPurpose, getRentalSecondStepRefs } from 'selectors';

import {
  AddressFormGroupAdapter,
  FormBox,
  FormGeocoderProvider,
  FormGroupAdapter,
  NarrowFormFlex,
  RadioGroupAdapter,
} from 'components/shared/form';
import Condition from 'components/shared/form/ConditionNew';
import { Radio } from 'components/shared/formControls';
import { AddressFields } from 'components/blocks/LeadForm/shared';
import { utils } from 'components/shared';
import { STEP_2_UNITS_COUNT_CONDITION } from 'constants/forms';

import { validateRequired } from 'utils';

const propTypes = {
  transactionPurpose: PropTypes.string,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  refs: PropTypes.shape({
    actualRentalTypesCollection: PropTypes.arrayOf(PropTypes.object),
    occupanciesCollection: PropTypes.arrayOf(PropTypes.object),
  }),
};

const defaultProps = {
  refs: {
    actualRentalTypesCollection: [],
  },
  transactionPurpose: undefined,
};

function Property({ name, refs, transactionPurpose, index }) {
  const propertyTypeField = useField(`${name}.type`);

  return (
    <Fragment>
      <FormGeocoderProvider namespace={name}>
        <NarrowFormFlex>
          <FormBox width={1}>
            <Field
              name={`${name}.address`}
              required
              label="Address"
              validate={validateRequired()}
              component={AddressFormGroupAdapter}
            />
          </FormBox>
        </NarrowFormFlex>

        <AddressFields />
      </FormGeocoderProvider>

      <NarrowFormFlex>
        <FormBox width={1}>
          <Field
            name={`${name}.type`}
            label="Property Type"
            type="select"
            options={refs.actualRentalTypesCollection}
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </NarrowFormFlex>

      <Condition
        when={`${name}.type`}
        is={STEP_2_UNITS_COUNT_CONDITION}
        names={[`${name}.unitsCount`]}
      >
        <NarrowFormFlex>
          <FormBox width={1}>
            <Field
              name={`${name}.unitsCount`}
              required={utils.isUnitsCountRequired(propertyTypeField.input.value)}
              typeOfMask="number"
              label="# of Units"
              validate={(value, allValues) => utils.unitsCountValidate(value, allValues, index)}
              component={FormGroupAdapter}
            />
          </FormBox>
        </NarrowFormFlex>
      </Condition>

      {transactionPurpose === 'purchase' && (
        <NarrowFormFlex>
          <FormBox width={1}>
            <Field
              name={`${name}.purchasePrice`}
              required
              label="Purchase Price"
              typeOfMask="decimalCurrency"
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </NarrowFormFlex>
      )}

      {transactionPurpose === 'refinance' && (
        <Fragment>
          <NarrowFormFlex>
            <FormBox width={[1, 1, 1 / 2]}>
              <Field
                name={`${name}.asIsValue`}
                required
                label="As-Is Value"
                typeOfMask="decimalCurrency"
                validate={validateRequired()}
                component={FormGroupAdapter}
              />
            </FormBox>
            <FormBox width={[1, 1, 1 / 2]}>
              <Field
                name={`${name}.occupancy`}
                type="select"
                options={refs.occupanciesCollection}
                label="Is the Property Leased?"
                component={FormGroupAdapter}
              />
            </FormBox>
          </NarrowFormFlex>

          <NarrowFormFlex>
            <FormBox width={1}>
              <Field
                component={RadioGroupAdapter}
                inline
                label="Was the property purchased within the past year?"
                name={`${name}.purchasedWithinPastYear`}
                type="checkbox"
              >
                <Radio value="true" id={`${name}.purchasedWithinPastYearYes`} label="Yes" />
                <Radio value="false" id={`${name}.purchasedWithinPastYearNo`} label="No" />
              </Field>
            </FormBox>
          </NarrowFormFlex>

          <Condition
            when={`${name}.purchasedWithinPastYear`}
            is="true"
            names={[
              `${name}.purchaseDate`,
              `${name}.purchasePrice`,
              `${name}.completedRepairsValue`,
            ]}
          >
            <Fragment>
              <NarrowFormFlex>
                <FormBox width={[1, 1, 1 / 2]}>
                  <Field
                    name={`${name}.purchaseDate`}
                    required
                    type="date"
                    label="Purchase Date"
                    validate={validateRequired()}
                    component={FormGroupAdapter}
                  />
                </FormBox>
                <FormBox width={[1, 1, 1 / 2]}>
                  <Field
                    name={`${name}.purchasePrice`}
                    required
                    label="Purchase Price"
                    typeOfMask="currency"
                    validate={validateRequired()}
                    component={FormGroupAdapter}
                    isConditional
                  />
                </FormBox>
              </NarrowFormFlex>

              <NarrowFormFlex>
                <FormBox width={[1, 1, 1 / 2]}>
                  <Field
                    name={`${name}.completedRepairsValue`}
                    required
                    label="Repairs Completed"
                    typeOfMask="currency"
                    validate={validateRequired()}
                    component={FormGroupAdapter}
                    isConditional
                  />
                </FormBox>
              </NarrowFormFlex>
            </Fragment>
          </Condition>
        </Fragment>
      )}

      <NarrowFormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name={`${name}.totalRent`}
            required
            label="Rent"
            typeOfMask="decimalCurrency"
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
          <Field inline name={`${name}.totalRentPeriod`} type="checkbox" component={RadioGroupAdapter}>
            <Radio value="monthly" id={`${name}.totalRentPeriodMonthly`} label="Monthly" />
            <Radio value="annual" id={`${name}.totalRentPeriodAnnual`} label="Annual" />
          </Field>
        </FormBox>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name={`${name}.reTaxes`}
            required
            label="Real Estate Taxes"
            typeOfMask="decimalCurrency"
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
          <Field inline name={`${name}.reTaxesPeriod`} type="checkbox" component={RadioGroupAdapter}>
            <Radio value="monthly" id={`${name}.reTaxesPeriodMonthly`} label="Monthly" />
            <Radio value="annual" id={`${name}.reTaxesPeriodAnnual`} label="Annual" />
          </Field>
        </FormBox>
      </NarrowFormFlex>

      <NarrowFormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name={`${name}.associationFee`}
            required
            label="HOA/Condo Fees"
            typeOfMask="decimalCurrency"
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
          <Field inline name={`${name}.associationFeePeriod`} type="checkbox" component={RadioGroupAdapter}>
            <Radio value="monthly" id={`${name}.associationFeePeriodMonthly`} label="Monthly" />
            <Radio value="annual" id={`${name}.associationFeePeriodAnnual`} label="Annual" />
          </Field>
        </FormBox>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name={`${name}.insurance`}
            required
            label="Insurance"
            typeOfMask="decimalCurrency"
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
          <Field inline name={`${name}.insurancePeriod`} type="checkbox" component={RadioGroupAdapter}>
            <Radio value="monthly" id={`${name}.insurancePeriodMonthly`} label="Monthly" />
            <Radio value="annual" id={`${name}.insurancePeriodAnnual`} label="Annual" />
          </Field>
        </FormBox>
      </NarrowFormFlex>

      {transactionPurpose === 'refinance' && (
        <NarrowFormFlex>
          <FormBox width={1}>
            <Field
              name={`${name}.existingLoanAmount`}
              required
              label="Existing Loan Amount"
              typeOfMask="currency"
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </NarrowFormFlex>
      )}
    </Fragment>
  );
}

Property.propTypes = propTypes;
Property.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    refs: getRentalSecondStepRefs(state),
    transactionPurpose: getApplicationTransactionPurpose(state),
  };
}

const enhance = compose(connect(mapStateToProps), pure);

export default enhance(Property);
