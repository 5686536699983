import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from 'components/shared';
import { FormFlex, FormBox } from 'components/shared/form';

import Fields from './Fields';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

function Form({ handleSubmit, submitting }) {
  return (
    <form>
      <Fields />

      <Footer justifyContent="flex-end">
        <FormBox>
          <Button onClick={handleSubmit} rounded disabled={submitting}>
            {submitting ? 'Please wait…' : 'Continue'}
          </Button>
        </FormBox>
      </Footer>
    </form>
  );
}

const Footer = styled(FormFlex)`
  padding: 1.25rem 0;
`;

Form.propTypes = propTypes;

export default Form;
