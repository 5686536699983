import { errorNotification } from 'utils';
import api from '../../utils/api';

export const fetchAppraisalPayment = (token) => () => {
  const url = `/applications/${token}/appraisal_payment`;

  return api
    .get(url)
    .then(({ data }) => data)
    .catch((res) => res.satus === 500 && errorNotification());
};

export const createAppraisalPayment = ({ token, ...values }) => () => {
  const url = `/applications/${token}/appraisal_payment`;
  return api.post(url, values);
};
