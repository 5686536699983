import React from 'react';
import { compose, lifecycle, pure } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../actions';
import { getSettings, requestInProcess } from '../selectors';

function WithSettings(Component) {
  const propTypes = {
    fetchSettings: PropTypes.func.isRequired,
  };

  function Settings(props) {
    return <Component {...props} />;
  }

  function mapStateToProps(state) {
    const settings = getSettings(state);
    const settingsIsLoading = requestInProcess(state, 'SETTINGS');

    return {
      settings,
      settingsIsLoading,
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      fetchSettings: bindActionCreators(actions.fetchSettings, dispatch),
    };
  }

  Settings.propTypes = propTypes;

  const enhance = compose(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    ),
    lifecycle({
      UNSAFE_componentWillMount() {
        this.props.fetchSettings();
      },
    }),
    pure,
  );
  return enhance(Settings);
}

export default WithSettings;
