// a b c d e f g h i j k l m n o p q r s t u v w x y z

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ifProp, theme } from 'styled-tools';

import WellBody from './Body';
import WellFooter from './Footer';
import WellHeader from './Header';
import WellSubtitle from './Subtitle';
import WellTitle from './Title';

const propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

const defaultProps = {
  children: undefined,
  title: undefined,
  subtitle: undefined,
};

function Well({ title, subtitle, children, ...props }) {
  let body;
  let header;
  let footer;

  React.Children.forEach(children, (child) => {
    switch (child.key) {
      case 'body':
        body = child;
        break;
      case 'header':
        header = child;
        break;
      case 'footer':
        footer = child;
        break;
      default:
        break;
    }
  });

  if (!body && !header && !footer) {
    return (
      <StyledWell {...props}>
        <StyledWellBody>
          {title && <StyledWellTitle subtitle={!!subtitle}>{title}</StyledWellTitle>}
          {subtitle && <StyledWellSubtitle>{subtitle}</StyledWellSubtitle>}
          {children}
        </StyledWellBody>
      </StyledWell>
    );
  }
  return (
    <StyledWell {...props}>
      {header && header}
      {body && body}
      {footer && footer}
    </StyledWell>
  );
}

const StyledWellBody = styled(WellBody)`
  padding: 40px 55px 25px;
`;

const StyledWellTitle = styled(WellTitle)`
  margin-bottom: ${ifProp('subtitle', '10px', '30px')};
`;

const StyledWellSubtitle = styled(WellSubtitle)`
  margin-bottom: 30px;
`;

const StyledWell = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: ${theme('fonts.base')};

  letter-spacing: 0px;
  background-color: #f7f8f8;
  border: 1px solid #d5dde9;
  box-sizing: border-box;
`;

Well.propTypes = propTypes;
Well.defaultProps = defaultProps;

export { WellBody, WellFooter, WellHeader, WellSubtitle, WellTitle };
export default Well;
