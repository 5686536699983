import React, { Fragment } from 'react';
import { FieldArray } from 'react-final-form-arrays';

import Fields from './Fields';

function Properties() {
  return (
    <FieldArray name="application.properties">
      {({ fields }) => (
        <Fragment>
          {fields && fields.map((name, index) => <Fields index={index} fields={fields} key={name} name={name} />)}
        </Fragment>
      )}
    </FieldArray>
  );
}

export default Properties;
