import { getIn as finalGetIn, setIn as finalSetIn } from 'final-form';
import * as R from 'ramda';

export const setIn = R.curry((value, key, state) => finalSetIn(state || {}, key, value));
export const getIn = R.curry((complexKey, state) => finalGetIn(state || {}, complexKey));

export const isNotDefined = R.anyPass([R.isEmpty, R.isNil]);
export const isDefined = R.complement(isNotDefined);

export const rSetCopyIn = R.curry((from, to, state) => {
  const value = getIn(from, state);
  return setIn(value, to, state);
});

export const renameKeys = R.curry((keysMap, obj) =>
  R.reduce((acc, key) => R.assoc(keysMap[key] || key, obj[key], acc), {}, R.keys(obj)));

export const propIsNotDefined = R.curry((p, obj) =>
  R.compose(
    isNotDefined,
    R.prop(p),
  )(obj));

export const propIsDefined = R.curry((p, obj) =>
  R.compose(
    isDefined,
    R.prop(p),
  )(obj));

export const pathIsNotDefined = R.curry((p, obj) =>
  R.compose(
    isNotDefined,
    R.path(p),
  )(obj));

export const pathIsDefined = R.curry((p, obj) =>
  R.compose(
    isDefined,
    R.path(p),
  )(obj));
