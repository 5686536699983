import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { isEmpty } from 'ramda';

import { getAddressRefs } from 'selectors';

import { FormFlex, FormBox, FormGroupAdapter, SelectStateAdapter } from 'components/shared/form';

import { getName, validateRequired } from 'utils';

const propTypes = {
  namespace: PropTypes.string,
  refs: PropTypes.shape({
    statesCollection: PropTypes.arrayOf(PropTypes.object),
  }),
  isRequired: PropTypes.bool,
};

const defaultProps = {
  namespace: null,
  refs: {
    statesCollection: [],
  },
  isRequired: true,
};

function AddressFields({ namespace, refs, isRequired }) {
  return (
    <Fragment>
      <FormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name={getName(namespace, 'municipality')}
            required={isRequired}
            label="City"
            validate={isRequired && validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>

        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name={getName(namespace, 'stateCode')}
            namespace={namespace}
            required={isRequired}
            type="select"
            options={refs.statesCollection}
            label="State"
            validate={isRequired && validateRequired()}
            component={SelectStateAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name={getName(namespace, 'postalCode')}
            required={isRequired}
            label="ZIP"
            validate={isRequired && validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>

        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name={getName(namespace, 'premise')}
            required={isRequired}
            label="Unit"
            component={FormGroupAdapter}
            parse={(v) => (isEmpty(v) ? null : v)}
          />
        </FormBox>
      </FormFlex>
    </Fragment>
  );
}

AddressFields.propTypes = propTypes;
AddressFields.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    refs: getAddressRefs(state),
  };
}

const enhance = compose(
  connect(mapStateToProps),
  pure,
);

export default enhance(AddressFields);
