import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import createDecorator from 'final-form-focus';
import { withRouter } from 'react-router-dom';

import * as actions from 'actions';
import {
  getApplicationToken,
  getUserContactId,
  getApplicationBorrowerScoreByContactId,
  getApplicationType,
  requestInProcess,
} from 'selectors';
import * as requestTypes from 'constants/requestTypes';

import { WithUser } from 'hocs';
import ScrollToTopOnMount from 'hocs/ScrollToTopOnMount';
import { holdMutators } from 'utils';

import Form from './Form';
import { handlers, utils } from './shared';

const propTypes = {
  initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  updateApplication: PropTypes.func.isRequired,
};

const focusOnErrors = createDecorator();

function Step3({ initialValues, loading, updateApplication }) {
  return (
    <FinalForm
      onSubmit={updateApplication}
      initialValues={initialValues}
      mutators={{ ...holdMutators }}
      decorators={[focusOnErrors]}
      subscription={{ submitting: true, pristine: true }}
      loading={loading}
      component={Form}
    />
  );
}

Step3.propTypes = propTypes;

function mapStateToProps(state, props) {
  const { userIsLoading, applicationsIsLoading, loadingInitialValues } = props;
  const refsIsLoading = requestInProcess(state, requestTypes.REFS);
  const userContactId = getUserContactId(state);
  const borrowerScore = getApplicationBorrowerScoreByContactId(state, { id: userContactId });
  const applicationType = getApplicationType(state);
  return {
    loading: userIsLoading || applicationsIsLoading || refsIsLoading || loadingInitialValues,
    token: getApplicationToken(state),
    applicationType,
    userContactId,
    borrowerScore,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchApplicationLeadBorrower: bindActionCreators(actions.fetchApplicationLeadBorrower, dispatch),
    updateApplication: bindActionCreators(actions.updateBorrowerApplication, dispatch),
    dataLayerPush: bindActionCreators(actions.dataLayerPush, dispatch),
  };
}

const enhance = compose(
  withRouter,
  WithUser,
  withState('initialValues', 'setInitialValues', utils.setDefaultInitialValues()),
  withState('loadingInitialValues', 'setLoadingInitialValues', false),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withHandlers({
    mergeInitialValues: handlers.mergeInitialValues,
  }),
  withHandlers({
    getProps: (props) => () => props,
  }),
  lifecycle({
    async componentDidMount() {
      const {
        applicationType,
        borrowerScore,
        dataLayerPush,
        fetchApplicationLeadBorrower,
        match,
        mergeInitialValues,
        setLoadingInitialValues,
        userContactId,
      } = this.props;
      const { token } = match.params;
      dataLayerPush({ loanType: applicationType, userId: userContactId, bSc: borrowerScore });
      setLoadingInitialValues(true);
      await fetchApplicationLeadBorrower(token).then(mergeInitialValues);
      setLoadingInitialValues(false);
    },
  }),
  ScrollToTopOnMount,
  pure,
);

export default enhance(Step3);
