import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import createDecorator from 'final-form-focus';
import arrayMutators from 'final-form-arrays';
import { Form as FinalForm } from 'react-final-form';
import * as R from 'ramda';

import * as actions from 'actions';
import { getUserContactId, getApplicationBorrowerScoreByContactId, getApplicationType } from 'selectors';

import { holdMutators, warnNotification } from 'utils';
import ScrollToTopOnMount from 'hocs/ScrollToTopOnMount';
import * as fp from 'utils/ramda';

import Form from './Form';
import { handlers, utils } from './shared';

const focusOnErrors = createDecorator();

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.func.isRequired,
  fetchInitialValues: PropTypes.func.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
};

function Step2({ initialValues, loading, onSubmit, fetchInitialValues }) {
  return (
    <FinalForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      decorators={[focusOnErrors]}
      loading={loading}
      fetchInitialValues={fetchInitialValues}
      subscription={{ submitting: true, pristine: true }}
      mutators={{ ...holdMutators, ...arrayMutators }}
      component={Form}
    />
  );
}

Step2.propTypes = propTypes;

function mapStateToProps(state) {
  const userContactId = getUserContactId(state);
  const borrowerScore = getApplicationBorrowerScoreByContactId(state, { id: userContactId });
  const applicationType = getApplicationType(state);
  return {
    applicationType,
    borrowerScore,
    userContactId,
  };
}

function mapDispatchToProps(dispatch, { setInitialValues }) {
  const fetchApplicationLeadProperty = bindActionCreators(actions.fetchApplicationLeadProperty, dispatch);
  const updateApplication = bindActionCreators(actions.updatePropertyApplication, dispatch);
  const dataLayerPush = bindActionCreators(actions.dataLayerPush, dispatch);

  const onSubmit = (values, form) => {
    const propertiesPath = ['application', 'properties'];
    const visibleProperties = R.compose(
      R.reject(R.prop('_hidden')),
      R.pathOr([], propertiesPath),
    )(values);

    if (fp.isNotDefined(visibleProperties)) {
      warnNotification('You must add at least one property!');
      R.compose(
        setInitialValues,
        utils.setDefaultInitialValues,
        R.assocPath(propertiesPath, [{}]),
      )(values);
      return undefined;
    }

    return R.compose(
      (v) => updateApplication(v, form),
      R.tap(setInitialValues),
      R.assocPath(propertiesPath, visibleProperties),
    )(values);
  };

  return {
    onSubmit,
    fetchApplicationLeadProperty,
    dataLayerPush,
  };
}

const enhance = compose(
  withRouter,
  withState('initialValues', 'setInitialValues', utils.setDefaultInitialValues()),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('loading', 'setLoading', false),
  withHandlers({
    mergeInitialValues: handlers.mergeInitialValues,
  }),
  withHandlers({
    fetchInitialValues: ({ mergeInitialValues, match, setLoading, fetchApplicationLeadProperty }) => async () => {
      const { token } = match.params;
      setLoading(true);
      await fetchApplicationLeadProperty(token).then(mergeInitialValues);
      setLoading(false);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { applicationType, borrowerScore, dataLayerPush, fetchInitialValues, userContactId } = this.props;
      dataLayerPush({ loanType: applicationType, userId: userContactId, bSc: borrowerScore });
      fetchInitialValues();
    },
  }),
  ScrollToTopOnMount,
  pure,
);

export default enhance(Step2);
