import * as R from 'ramda';

import { getIn } from 'utils';

function validateOwnersip(value, values) {
  if (!value) return 'must be filled';
  const message = 'The sum of % Ownership should be less than or equal 100%';
  const { borrowers } = values.application;
  const sum = borrowers.reduce((acc, { ownershipPercent }) => acc + parseFloat(ownershipPercent || 0, 10), 0);
  return sum <= 100 ? undefined : message;
}

function validateBorrowerEmail(value, values) {
  const borrowers = getIn('application.borrowers', values) || [];
  const emails = R.pipe(
    R.map(R.prop('email')),
    R.filter(
      R.pipe(
        R.isNil,
        R.not,
      ),
    ),
  )(borrowers);
  const emailsIsUniq = R.equals(R.length(emails), R.length(R.uniq(emails)));
  if (emailsIsUniq) return undefined;
  return 'Email of each borrower should be unique in the current application';
}

export { validateOwnersip, validateBorrowerEmail };
