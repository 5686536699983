import * as R from 'ramda';

import { setInDefaultValue } from 'decorators';

function setDefaultInitialValues(input = {}) {
  return R.pipe(setInDefaultValue('US', 'application.borrower.countryOfResidence'))(input);
}

export { setDefaultInitialValues };

