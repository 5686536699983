import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';

import Asterisk from 'components/shared/Asterisk';
import { Hint, Label } from 'components/shared/formControls';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hint: PropTypes.node,
  inline: PropTypes.bool,
  invalid: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  rightHint: PropTypes.bool,
  noMargin: PropTypes.bool,
  // Orders
  labelOrder: PropTypes.number,
  inputOrder: PropTypes.number,
  hintOrder: PropTypes.number,
  errorOrder: PropTypes.number,
};

const defaultProps = {
  className: null,
  error: null,
  inline: false,
  invalid: false,
  name: null,
  required: false,
  label: null,
  hint: null,
  rightHint: false,
  noMargin: false,
  // Orders
  errorOrder: 0,
  hintOrder: 0,
  labelOrder: 0,
  inputOrder: 0,
};

function FormGroup({
  className,
  children,
  error,
  name,
  inline,
  invalid,
  label,
  required,
  hint,
  rightHint,
  noMargin,
  // Orders
  errorOrder,
  hintOrder,
  inputOrder,
  labelOrder,
}) {
  const childrenWithName = Children.map(children, (child) => cloneElement(child, { invalid, name }));

  return (
    <Container inline={inline} className={className} noMargin={noMargin}>
      {label && (
        <ItemContainer order={labelOrder}>
          <StyledLabel htmlFor={name}>
            {required && <StyledAsterisk />}
            {label}
          </StyledLabel>
        </ItemContainer>
      )}
      <ItemContainer order={inputOrder}>{childrenWithName}</ItemContainer>
      {invalid && error && (
        <ItemContainer order={errorOrder}>
          <Hint error id={`${name}Error`}>
            {error}
          </Hint>
        </ItemContainer>
      )}
      {hint && (
        <ItemContainer order={errorOrder}>
          <Hint order={hintOrder} right={rightHint}>
            {hint}
          </Hint>
        </ItemContainer>
      )}
    </Container>
  );
}

const StyledLabel = styled(Label)`
  position: relative;
  display: block;
  box-sizing: border-box;
  margin: 0 0 3px 3px;
  font-size: 0.9375rem;
  color: #6d6b69;
  order: ${prop('order', 0)};
`;

const StyledAsterisk = styled(Asterisk)`
  position: absolute;
  top: 0;
  left: -10px;
  font-size: 65%;
`;

const ItemContainer = styled.div`
  display: flex;
  order: ${prop('order', 0)};
`;

const Container = styled.div`
  margin-bottom: ${ifProp('noMargin', '5px', '15px')};
  display: flex;
  flex-direction: ${({ inline }) => (inline ? 'row' : 'column')};
  ${ifProp(
    'inline',
    css`
      ${ItemContainer} {
        margin-right: 0.5rem;
      }
    `,
  )};
`;

FormGroup.propTypes = propTypes;
FormGroup.defaultProps = defaultProps;

export default FormGroup;
