import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, getContext } from 'recompose';

import useImportProperty from 'hooks/useImportProperty';

import { Button } from 'components/shared';
import { FormBox, NarrowFormFlex, FormStepHeader } from 'components/shared/form';
import Well, { WellBody, WellHeader, WellFooter } from 'components/shared/Well';

import Fields from './Fields';
import Loader from './Loader';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  fetchInitialValues: PropTypes.isRequired,
};

function Form({ handleSubmit, loading, submitting, fetchInitialValues, title }) {
  const {
    toImport,
    isImporting,
    errors,
    warnings,
  } = useImportProperty('application.properties', fetchInitialValues);

  return (
    <form onSubmit={handleSubmit}>
      <Well>
        <WellHeader key="header">
          <FormStepHeader
            title={title}
            subtitle="Your Instant Rate Quote is Only 1 Step Away"
            current={2}
            total={2}
          />
        </WellHeader>

        <WellBody key="body">
          {loading && <Loader />}
          {!loading && (
            <Fields
              toImport={toImport}
              isImporting={isImporting}
              importErrors={errors}
              importWarnings={warnings}
            />
          )}
        </WellBody>

        <WellFooter sticky key="footer">
          <NarrowFormFlex alignItems="center" justifyContent="flex-end">
            <FormBox p={[3, 3, 0]} alignSelf={['flex-end', 'flex-end', 'inherit']}>
              <Button type="submit" dataName="buttonNext" rounded disabled={loading || submitting}>
                {submitting ? 'Please wait…' : 'Next'}
              </Button>
            </FormBox>
          </NarrowFormFlex>
        </WellFooter>
      </Well>
    </form>
  );
}

Form.propTypes = propTypes;

const enhance = compose(
  getContext({ fetchInitialValues: PropTypes.func }),
  pure,
);


export default enhance(Form);
