import React from 'react';
import { compose, pure } from 'recompose';
import ScrollToTopOnMount from 'hocs/ScrollToTopOnMount';

import { PageTemplate } from 'components/templates';
import { Application } from 'components/blocks';

function ApplicationsPage() {
  return (
    <PageTemplate>
      <Application />
    </PageTemplate>
  );
}

const enhance = compose(
  ScrollToTopOnMount,
  pure,
);

export default enhance(ApplicationsPage);
