import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Form as FinalForm } from 'react-final-form';
import createFocusDecorator from 'final-form-focus';

import * as actions from 'actions';
import { WithUser } from 'hocs';
import ScrollToTopOnMount from 'hocs/ScrollToTopOnMount';

import { UserTemplate } from 'components/templates';
import { UserHeader } from 'components/blocks/User';
import { Wrapper } from 'components/shared';

import Form from './Form';

const focusOnError = createFocusDecorator();

const propTypes = {
  updatePassword: PropTypes.func.isRequired,
};

const defaultProps = {};

function EditPasswordPage({ updatePassword }) {
  return (
    <UserTemplate header={<UserHeader />}>
      <StyledWrapper>
        <FinalForm
          onSubmit={updatePassword}
          subscription={{ submitting: true, pristine: true }}
          decorators={[focusOnError]}
          component={Form}
        />
      </StyledWrapper>
    </UserTemplate>
  );
}

const StyledWrapper = styled(Wrapper)`
  max-width: 70rem;
`;

function mapStateToProps(state, props) {
  const { contact } = props;
  const initialValues = {
    contact,
  };

  return {
    initialValues,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePassword: bindActionCreators(actions.updatePassword, dispatch),
  };
}

EditPasswordPage.propTypes = propTypes;
EditPasswordPage.defaultProps = defaultProps;

const enhance = compose(
  WithUser,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  ScrollToTopOnMount,
  pure,
);

export default enhance(EditPasswordPage);
