export const APPLICATION_BASE_PARAMS = [
  'referralToken',
  'utmContent',
  'utmSource',
  'utmMedium',
  'utmCampaign',
  'utmTerm',
];
export const APPLICATION_RENTAL_PARAMS = [
  'firstName',
  'lastName',
  'email',
  'phone',
  'stateCode',
  'transactionPurpose',
  'referredByType',
  'referredByComment',
  'loanOfficerId',
];
export const APPLICATION_BRIDGE_PARAMS = [
  'firstName',
  'lastName',
  'email',
  'phone',
  'stateCode',
  'investmentPropertiesCountSoldLast36Months',
  'rentalPropertiesCount',
  'referredByType',
  'referredByComment',
  'loanOfficerId',
  'creditScore',
];
