import * as R from 'ramda';

import { setInDefaultValue } from 'decorators';
import { getIn, setIn } from 'utils';

function setDefaultInitialValues(input = {}) {
  return R.pipe(setInDefaultValue([{}], 'application.properties'))(input);
}

function valuesSubmitPreparation(values) {
  const properties = R.pipe(
    getIn('application.properties'),
    R.when(
      R.pipe(
        R.isNil,
        R.not,
      ),
      R.map(setIn(undefined, 'files')),
    ),
  )(values);

  return setIn(properties, 'application.properties', values);
}

export { setDefaultInitialValues, valuesSubmitPreparation };
