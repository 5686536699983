import { useState, useMemo } from 'react';
import { useForm, useField } from 'react-final-form';
import * as R from 'ramda';

function usePortfolioFormProperties({
  isFormDisabled,
  defaultActiveName = null,
  propertiesName = 'application.properties',
}) {
  const form = useForm();
  const getProperty = (name) => R.pathOr({}, ['input', 'value'], form.getFieldState(name));
  const propertiesField = useField(propertiesName);
  const properties = R.pathOr([], ['input', 'value'], propertiesField);

  const lessThanTwoVisibleProperties = useMemo(() => R.compose(
    R.gt(2),
    R.length,
    R.reject(R.prop('_hidden')),
  )(properties), [properties]);

  const [activeName, setActiveName] = useState(defaultActiveName);
  const toOpen = (name) => setActiveName(name);
  const toClose = () => setActiveName(null);
  const toToggle = (name) => (R.equals(name, activeName) ? toClose() : toOpen(name));
  const onDelete = (name) => form.change(name, { ...getProperty(name), _hidden: true });

  const canSwap = !isFormDisabled;
  const canDelete = !isFormDisabled && !lessThanTwoVisibleProperties;

  return {
    activeName,
    canDelete,
    canSwap,
    onDelete,
    toClose,
    toOpen,
    toToggle,
  };
}

export default usePortfolioFormProperties;
