import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import * as R from 'ramda';

export const phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
export const ssnMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

export const currencyMask = (value) => {
  const mask = createNumberMask({ integerLimit: 10 });
  if (R.test(/\$/, value)) {
    return mask(value);
  }
  return mask(`${parseInt(value, 10)}`);
};

export const decimalCurrencyMask = (value) => {
  const mask = createNumberMask({ integerLimit: 10, allowDecimal: true });
  if (R.test(/\$/, value)) {
    return mask(value);
  }
  return mask(`${parseFloat(value, 10)}`);
};

export const percentMask = (value) => {
  const mask = createNumberMask({
    prefix: '',
    suffix: '%',
    integerLimit: 3,
    allowDecimal: true,
  });
  if (R.test(/%/, value)) {
    return mask(value);
  }
  return mask(`${parseInt(value, 10)}`);
};

export const decimalPercentMask = (value) => {
  const mask = createNumberMask({
    prefix: '',
    suffix: '%',
    integerLimit: 3,
    allowDecimal: true,
  });
  if (R.test(/%/, value)) {
    return mask(value);
  }
  return mask(`${parseFloat(value)}`);
};

export const cvvMask = createNumberMask({
  prefix: '',
  integerLimit: 4,
  includeThousandsSeparator: false,
  allowLeadingZeroes: true,
});

export const numberMask = createNumberMask({
  prefix: '',
  integerLimit: 13,
});

export const decimalNumberMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  integerLimit: 13,
});

export const phoneExtensionMask = createNumberMask({
  prefix: '',
  integerLimit: 8,
  includeThousandsSeparator: false,
  allowLeadingZeroes: true,
});
