import React from 'react';

import { PageTemplate } from 'components/templates';
import { Rental } from 'components/blocks';

import { ScrollToTopOnMount } from 'hocs';

function RentalPage() {
  return (
    <PageTemplate>
      <Rental />
    </PageTemplate>
  );
}

export default ScrollToTopOnMount(RentalPage);
