import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContentLoader from 'react-content-loader';
import * as R from 'ramda';

import * as actions from 'actions';
import { getUserContactId } from 'selectors';

import { WithIncompleteApp, WithUser } from 'hocs';

import { GroupPanel, List, ListLi, Panel, SecurityStandards, Wrapper } from 'components/shared';
import { FormWrapper } from 'components/shared/form';

import { AccessDenied, Incomplete } from 'components/blocks/LeadForm/shared';
import BridgeStep1 from 'components/blocks/LeadForm/bridge/Step1';

const propTypes = {
  isAccessDenied: PropTypes.bool,
  incompleteApp: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool,
};

const defaultProps = {
  isAccessDenied: false,
  incompleteApp: undefined,
  loading: false,
};

const Content = (props) => {
  const { isAccessDenied, incompleteApp, loading } = props;

  if (loading) {
    return <ContentLoader />;
  }

  if (isAccessDenied) {
    return <AccessDenied />;
  }

  if (incompleteApp) {
    return <Incomplete application={incompleteApp} />;
  }

  return <BridgeStep1 />;
};

function BridgePage(props) {
  return (
    <Fragment>
      <FormWrapper>
        <Content {...props} />
      </FormWrapper>

      <Wrapper padded>
        <GroupPanel>
          <Panel title="Who needs a Fix-n-Flip/Bridge Loan?">
            Get an instant rate in 2 minutes or less! If you are an investor who is looking to buy discounted
            properties, fix them up, and sell/flip them within 12 months, then consider our fix and flip bridge loans
            for the capital you need. With attractive rates and up to 90% Purchase & Rehab Costs, these loans are a
            great way to start expanding your real estate projects.
          </Panel>

          <Panel title="Advantages of LendingOne Bridge Loans:">
            <List>
              <ListLi>Up to 90% of Purchase & Rehab Costs</ListLi>
              <ListLi>12-month interest only loans with no prepayment penalty</ListLi>
              <ListLi>No hurdles- a very user-friendly and streamlined process</ListLi>
            </List>
          </Panel>
        </GroupPanel>
      </Wrapper>

      <SecurityStandards />
    </Fragment>
  );
}

function mapStateToProps(state, props) {
  const { incompleteApp, contact, userIsLoading, applicationsIsLoading } = props;

  return {
    userContactId: getUserContactId(state),
    incompleteApp,
    loading: userIsLoading || applicationsIsLoading,
    isAccessDenied: contact && (contact.isAdmin || contact.isCrestar),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchEntities: bindActionCreators(actions.fetchEntities, dispatch),
  };
}

Content.propTypes = propTypes;
Content.defaultProps = defaultProps;

const enhance = compose(
  WithUser,
  WithIncompleteApp,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withHandlers({
    contactListener: ({ fetchEntities, userContactId }) => (prevProps) => {
      if (R.equals(userContactId, prevProps.userContactId)) return;
      if (R.isNil(userContactId)) return;
      fetchEntities(userContactId);
    },
  }),
  lifecycle({
    UNSAFE_componentWillMount() {
      const { userContactId, fetchEntities } = this.props;
      if (userContactId) fetchEntities(userContactId);
    },
    componentDidUpdate(prevProps) {
      const { contactListener } = this.props;
      contactListener(prevProps);
    },
  }),
  pure,
);

export default enhance(BridgePage);
