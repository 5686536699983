import createDecorator from 'final-form-calculate';
import numeral from 'numeral';

const totalCalc = (ignoredValue, { appraisalPayment }) => {
  let total = numeral(appraisalPayment.totalAmount).value();
  if (appraisalPayment.rush) {
    total += appraisalPayment.rushAmount;
  }
  if (appraisalPayment.otherItemsAmount) {
    const otherItemsAmount = numeral(appraisalPayment.otherItemsAmount).value();
    total += otherItemsAmount;
  }
  return numeral(total).format('0.00');
};

export const decorator = createDecorator(
  {
    field: 'appraisalPayment.rush',
    updates: {
      total: totalCalc,
    },
  },
  {
    field: 'appraisalPayment.otherItemsAmount',
    updates: {
      total: totalCalc,
    },
  },
);

