import React from 'react';
import PropTypes from 'prop-types';
import { compose, getContext, pure } from 'recompose';
import { withRouter } from 'react-router-dom';

import { Button, Hr } from 'components/shared';
import { FormFlex, FormBox } from 'components/shared/form';

import { APPLICATION_STEP_PATH } from 'constants/routes';
import { getPath } from 'utils';

import Fields from './Fields';
import Loader from './Loader';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isFormDisabled: PropTypes.bool.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

function Form({ isFormDisabled, handleSubmit, match, loading, submitting }) {
  const { token } = match.params;
  return (
    <form>
      {loading && <Loader />}
      {!loading && <Fields />}
      <FormFlex>
        <FormBox width={1}>
          <Hr />
        </FormBox>
      </FormFlex>
      <FormFlex alignItems="center" justifyContent="flex-start">
        <FormBox pl={3} pr={3}>
          <Button
            to={getPath(APPLICATION_STEP_PATH, { token, step: 2 })}
            transparent
            disabled={isFormDisabled || submitting}
          >
            Back
          </Button>
        </FormBox>
        <FormBox p={[3, 3, 0]} alignSelf={['flex-end', 'flex-end', 'inherit']}>
          <Button
            dataName="buttonNext"
            onClick={handleSubmit}
            rounded
            disabled={loading || isFormDisabled || submitting}
          >
            {submitting ? 'Please wait…' : 'Next'}
          </Button>
        </FormBox>
      </FormFlex>
    </form>
  );
}

Form.propTypes = propTypes;

const enhance = compose(withRouter, getContext({ isFormDisabled: PropTypes.bool }), pure);

export default enhance(Form);
