import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, useForm } from 'react-final-form';
import * as R from 'ramda';

import {
  getContactTypes,
  getApplicationBorrowersCollection,
  getApplicationPropertiesCollection,
} from 'selectors';

import { FormFlex, FormBox, Condition } from 'components/shared/form';

import { validateRequired } from 'utils';

import {
  AccessContact,
  BorrowerAttorney,
  BuyerAgent,
  Contractor,
  ContactTypeAdapter,
  CurrentLender,
  InsuranceCompany,
  InsuranceCarrier,
  ListingAgent,
  OutsideLawFirm,
  TitleCompany,
  InspectionContact,
} from './shared';

const propTypes = {
  contactTypesCollection: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  propertiesCollection: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
  })),
  borrowersCollection: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
  })),
};

const defaultProps = {
  contactTypesCollection: [],
  propertiesCollection: [],
  borrowersCollection: [],
  id: undefined,
};

function Fields({ contactTypesCollection, borrowersCollection, propertiesCollection, id }) {
  const form = useForm();

  const onChange = (e) => {
    form.mutators.setError([
      'contact.firstName',
      'contact.lastName',
      'contact.title',
      'contact.legalName',
      'contact.phone',
      'contact.email',
      'contact.targetId',
      'contact.targetIds',
    ], null);
    form.batch(() => {
      form.change('contact.appliedToAll', false);
      form.change('contact.type', e);

      switch (e) {
        case 'outside_law_firm':
        case 'insurance_company':
        case 'insurance_carrier':
          form.change('contact.targetIds', undefined);
          if (borrowersCollection.length === 1) {
            form.change('contact.targetId', R.path([0, 'value'], borrowersCollection));
          }
          break;
        case 'access_contact':
        case 'title_company':
        case 'contractor':
        case 'current_lender':
        case 'buyer_agent':
        case 'listing_agent':
        case 'inspection_contact':
          form.change('contact.targetId', undefined);
          form.change('contact.targetIds', null);
          if (propertiesCollection.length === 1) {
            form.change('contact.targetIds', [R.path([0, 'value'], propertiesCollection)]);
          }
          break;
        default:
          form.change('contact.targetId', null);
          form.change('contact.targetIds', undefined);
      }
    });
  };

  return (
    <Fragment>
      <FormFlex>
        <FormBox width={1}>
          <Field
            name="contact.type"
            label="Type"
            isDisabled={!!id}
            onChange={onChange}
            type="select"
            required
            options={contactTypesCollection}
            validate={validateRequired()}
            component={ContactTypeAdapter}
          />
        </FormBox>
      </FormFlex>

      <Condition when="contact.type" is="outside_law_firm">
        <OutsideLawFirm />
      </Condition>

      <Condition when="contact.type" is="insurance_company">
        <InsuranceCompany />
      </Condition>

      <Condition when="contact.type" is="insurance_carrier">
        <InsuranceCarrier />
      </Condition>

      <Condition when="contact.type" is="borrower_attorney">
        <BorrowerAttorney />
      </Condition>

      <Condition when="contact.type" is="title_company">
        <TitleCompany />
      </Condition>

      <Condition when="contact.type" is="contractor">
        <Contractor />
      </Condition>

      <Condition when="contact.type" is="access_contact">
        <AccessContact />
      </Condition>

      <Condition when="contact.type" is="current_lender">
        <CurrentLender />
      </Condition>

      <Condition when="contact.type" is="buyer_agent">
        <BuyerAgent />
      </Condition>

      <Condition when="contact.type" is="listing_agent">
        <ListingAgent />
      </Condition>

      <Condition when="contact.type" is="inspection_contact">
        <InspectionContact />
      </Condition>
    </Fragment>
  );
}

Fields.propTypes = propTypes;
Fields.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    contactTypesCollection: getContactTypes(state),
    propertiesCollection: getApplicationPropertiesCollection(state),
    borrowersCollection: getApplicationBorrowersCollection(state),
  };
}

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
  pure,
);

export default enhance(Fields);
