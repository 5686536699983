import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withHandlers } from 'recompose';
import styled, { injectGlobal, ThemeProvider } from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Switch, Redirect, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import Helmet from 'react-helmet';
import reset from 'styled-reset';
import { ToastContainer } from 'react-toastify';
import * as R from 'ramda';

import * as actions from 'actions';
import { history } from 'store/configureStore';
import { getAuthStatus } from 'selectors';

import PrivateRoute from 'components/PrivateRoute';
import theme from 'components/themes/default';

import {
  APPLICATION_AUTH_PATH,
  LOGIN_AS_PATH,
  APPLICATION_PATH,
  APPLICATION_ROOT_PATH,
  CONFIRMATION_EMAIL_PATH,
  DRAW_REQUESTS_PATH,
  EDIT_PASSWORD_PATH,
  INDEX_PATH,
  MY_APPLICATIONS_PATH,
  PROFILE_PATH,
  RESET_PASS,
  RESET_SET_PASS,
  SIGN_IN_PATH,
} from 'constants/routes';
import {
  Application,
  ApplicationAuth,
  ApplicationRoot,
  ConfirmEmailPage,
  LoginAsAuth,
  DrawRequestsPage,
  EditPasswordPage,
  MePage,
  MyApplicationsPage,
  NotFoundPage,
  ResetPassPage,
  ResetSetPassPage,
  SignInPage,
} from 'components';
import { LockOutListener } from 'components/blocks';
import { getAuthData, getRemainingTime, removeAuthData, getPath } from 'utils';
import rollbarErrorTracking from 'utils/rollbar';

const propTypes = {
  authStatus: PropTypes.bool.isRequired,
};

class ReiloansApp extends PureComponent {
  constructor(props) {
    super(props);
    resetStyles();
  }

  componentDidCatch(error, errorInfo) {
    const rollbar = rollbarErrorTracking('7e7e65ab72e94134a5689f50cc701a16');
    rollbar.logErrorInfo(errorInfo);
    rollbar.logErrorInRollbar(error);
  }

  render() {
    const { authStatus } = this.props;
    return (
      <Container>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          titleTemplate="REILoans - %s"
          title="REILoans"
          link={[
            {
              rel: 'icon',
              type: 'image/png',
              href: `${process.env.PUBLIC_URL}/reiloans/favicon.png`,
            },
          ]}
        />

        <ThemeProvider theme={theme}>
          <Fragment>
            <ConnectedRouter history={history}>
              <Switch>
                <Route
                  exact
                  path={INDEX_PATH}
                  render={() =>
                    (authStatus ? (
                      <Redirect to={getPath(MY_APPLICATIONS_PATH, { status: 'active' })} />
                    ) : (
                      <Redirect to={SIGN_IN_PATH} />
                    ))
                  }
                />
                <Route path={SIGN_IN_PATH} component={SignInPage} exact />
                <Route path={RESET_PASS} component={ResetPassPage} exact />
                <Route path={RESET_SET_PASS} component={ResetSetPassPage} exact />

                <Route path={APPLICATION_AUTH_PATH} component={ApplicationAuth} />
                <Route path={CONFIRMATION_EMAIL_PATH} component={ConfirmEmailPage} />
                <Route path={LOGIN_AS_PATH} component={LoginAsAuth} />

                <PrivateRoute path={APPLICATION_ROOT_PATH} component={ApplicationRoot} />
                <PrivateRoute path={EDIT_PASSWORD_PATH} component={EditPasswordPage} exact />

                <PrivateRoute path={APPLICATION_PATH} component={Application} />
                <PrivateRoute path={MY_APPLICATIONS_PATH} component={MyApplicationsPage} />

                <PrivateRoute path={PROFILE_PATH} component={MePage} />
                <PrivateRoute path={DRAW_REQUESTS_PATH} component={DrawRequestsPage} />

                <Route component={NotFoundPage} />
              </Switch>
            </ConnectedRouter>
            {authStatus && <LockOutListener />}
          </Fragment>
        </ThemeProvider>
        <ToastContainer />
      </Container>
    );
  }
}

const resetStyles = () => injectGlobal`
  ${reset}

  html{
    overflow-x: hidden;
    position: relative;
    height: 100%;
  }

  #root{
    position: relative;
    height: 100%;
  }

  body {
    position: relative;
    height: 100%;
    margin: 0;
    left: 0;
    text-align: left;
    line-height: 1.42857;
    font-size: 16px;
    font-family: "Open Sans","Helvetica Neue","Helvetica","Arial",sans-serif;
    color: #504f4d;
    background: #FFF;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

ReiloansApp.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    authStatus: getAuthStatus(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchRefs: bindActionCreators(actions.fetchRefs, dispatch),
    initialAuthRefrash: bindActionCreators(actions.initialAuthRefrash, dispatch),
    setSearchParams: bindActionCreators(actions.setSearchParams, dispatch),
  };
}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    initialLogin: ({ initialAuthRefrash }) => () => {
      const data = getAuthData();
      const remainingTime = getRemainingTime();
      if (R.isNil(data)) return;
      if (R.isEmpty(data)) return;
      if (remainingTime < 0) {
        removeAuthData();
        return;
      }
      initialAuthRefrash(data);
    },
  }),
  lifecycle({
    componentWillMount() {
      this.props.fetchRefs();
      this.props.setSearchParams();
      this.props.initialLogin();
    },
  }),
  pure,
);

export default enhance(ReiloansApp);
