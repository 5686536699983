import * as R from 'ramda';

import { setInDefaultValue, setInDefaultNotEmptyValue } from 'decorators';

function setDefaultInitialValues(input = {}) {
  return R.pipe(
    setInDefaultNotEmptyValue([{}], 'application.properties'),
    setInDefaultValue('false', 'application.bankruptcyProceedings'),
    setInDefaultValue('true', 'application.usCitizen'),
  )(input);
}

export { setDefaultInitialValues };
