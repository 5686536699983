import { LOCK_OUT_SHOW_MODAL_EVENT } from 'constants/auth';
import { getRemainingTime } from 'utils';

const showModalEvent = new Event(LOCK_OUT_SHOW_MODAL_EVENT);

function startTimerHandler({ closeModal, setModalTimer, setTimer, timer, modalTimer, showModal, logout }) {
  return () => {
    const remainingTime = getRemainingTime();
    // console.log('remainingTime', remainingTime);

    closeModal();
    if (timer) clearTimeout(timer);
    if (modalTimer) clearTimeout(modalTimer);
    if (!remainingTime) return;

    const newTimer = setTimeout(logout, remainingTime);
    setTimer(newTimer);
    if (remainingTime > 60000) {
      const newModalTimer = setTimeout(showModal, remainingTime - 60000);
      setModalTimer(newModalTimer);
    } else {
      showModal();
    }
  };
}

function toggleModalHandler({ show, setShow }) {
  return () => {
    if (!show) window.dispatchEvent(showModalEvent);
    setShow(!show);
  };
}

function closeModalHandler({ setShow }) {
  return () => setShow(false);
}

function showModalHandler({ setShow }) {
  return () => {
    window.dispatchEvent(showModalEvent);
    setShow(true);
  };
}

export {
  startTimerHandler as startTimer,
  toggleModalHandler as toggleModal,
  showModalHandler as showModal,
  closeModalHandler as closeModal,
};
