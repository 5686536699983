import jwt from 'jsonwebtoken';
import Cookies from 'js-cookie';

import { clientSecret } from 'config';

import { AUTH_TOKEN, AUTH_TOKEN_EVENT, CONFIRM_EMAIL_BANNER } from 'constants/auth';

import api from 'utils/api';
import { getCurrentTime } from 'utils/time';

const event = new Event(AUTH_TOKEN_EVENT);

function setAuthData(data) {
  const token = jwt.sign(data, clientSecret);

  localStorage.setItem(AUTH_TOKEN, token);
  window.dispatchEvent(event);

  api.setToken(data.accessToken);
}

function getAuthData() {
  const token = localStorage.getItem(AUTH_TOKEN);
  try {
    if (token) {
      const result = jwt.verify(token, clientSecret);
      return result;
    }
  } catch (error) {
    localStorage.removeItem(AUTH_TOKEN);
  }

  return {};
}

function getConfirmEmailBanner() {
  return Cookies.get(CONFIRM_EMAIL_BANNER);
}

function setConfirmEmailBanner(input) {
  Cookies.set(CONFIRM_EMAIL_BANNER, input, { expires: 1 });
}

function removeConfirmEmailBanner() {
  Cookies.remove(CONFIRM_EMAIL_BANNER);
}

function removeAuthData() {
  localStorage.removeItem(AUTH_TOKEN);
  window.dispatchEvent(event);

  api.unsetToken();
}

function getExpiresInTime() {
  const { expiresIn } = getAuthData();
  if (expiresIn) return parseInt(expiresIn, 10) * 1000;
  return undefined;
}

function getCreatedAtTime() {
  const { createdAt } = getAuthData();
  if (createdAt) return parseInt(createdAt, 10) * 1000;
  return undefined;
}

function getRemainingTime(time = getCurrentTime()) {
  const expiresInTime = getExpiresInTime();
  const createdAtTime = getCreatedAtTime();
  if (expiresInTime && createdAtTime) {
    const expirationTime = createdAtTime + expiresInTime;
    return expirationTime - time;
  }
  return undefined;
}

export {
  getAuthData,
  getRemainingTime,
  setAuthData,
  removeAuthData,
  getExpiresInTime,
  getCreatedAtTime,
  getConfirmEmailBanner,
  setConfirmEmailBanner,
  removeConfirmEmailBanner,
};
