import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { importApplicationPortfolioRequest } from 'actions/applications';
import useAsyncAction from 'hooks/useAsyncAction';

function useImportProperty(_, callback) {
  const { token } = useParams();
  const [errors, setErrors] = useState(null);
  const [warnings, setWarnings] = useState(null);

  const onSuccess = (res) => {
    callback();
    setErrors(res.errors);
    setWarnings(res.warnings);
  };
  const onError = (res) => {
    callback();
    setErrors(res.errors);
    setWarnings(res.warnings);
  };

  const [toImport, isImporting] = useAsyncAction(
    (data) => importApplicationPortfolioRequest(token, data),
    [token],
    { onSuccess, onError },
  );

  return { toImport, isImporting, errors, warnings };
}

export default useImportProperty;
