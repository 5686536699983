import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure, withHandlers } from 'recompose';
import * as R from 'ramda';

import { currencyFormat, dateFormat, getPath } from 'utils';
import { APPLICATION_EDIT_DRAW_REQUESTS_PATH } from 'constants/routes';

import { Badge } from 'components/shared';
import { Action, FileAction } from '../../../DrawRequestsPage/shared';
import { Header, Row, Column1, Column2, Column3, Column4, Column5 } from '../../../DrawRequestsPage/shared/grid';

const propTypes = {
  applicationToken: PropTypes.string.isRequired,
  className: PropTypes.string,
  draws: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      applicationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      property_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      createdAt: PropTypes.string,
      approvedAt: PropTypes.string,
      requestedAmount: PropTypes.string,
      approvedAmount: PropTypes.string,
      meta: PropTypes.objectOf(PropTypes.bool),
    }),
  ),
  getLienWaiverFileData: PropTypes.func.isRequired,
  getAppealFormFileData: PropTypes.func.isRequired,
};

const defaultProps = {
  className: undefined,
  draws: [],
};

function Draws({ applicationToken, className, draws, getLienWaiverFileData, getAppealFormFileData }) {
  return (
    <Container className={className}>
      <Header>
        <Column1 />
        <Column2>Requested Amount</Column2>
        <Column3>Approved Amount</Column3>
        <Column4 />
        <Column5 />
      </Header>
      {draws.map((draw, index) => (
        <Row key={`draw-request-${draw.id}`}>
          <Column1>
            <Strong>
              Draw Request #{index + 1}{' '}
              {draw.label && draw.label.text && (
                <Badge size="xs" palette="drawLabel" tone={draw.label.tone}>
                  {draw.label.text}
                </Badge>
              )}
            </Strong>

            <Small>Request Date: {dateFormat(draw.createdAt)}</Small>
          </Column1>
          <Column2>{draw.requestedAmount ? <Small>{currencyFormat(draw.requestedAmount)}</Small> : <Empty />}</Column2>
          <Column3>{draw.approvedAmount ? <Small>{currencyFormat(draw.approvedAmount)}</Small> : <Empty />}</Column3>
          <Column4>
            {draw.meta.availableToEdit && (
              <Action to={getPath(APPLICATION_EDIT_DRAW_REQUESTS_PATH, { token: applicationToken, id: draw.id })}>
                Edit
              </Action>
            )}
          </Column4>
          <Column5>
            <FileAction mb={1} token={applicationToken} fileData={getLienWaiverFileData(draw)}>
              Upload Lien Waiver
            </FileAction>
            <FileAction draw={draw} token={applicationToken} fileData={getAppealFormFileData(draw)}>
              Upload Appeal Form
            </FileAction>
          </Column5>
        </Row>
      ))}
    </Container>
  );
}

const Container = styled.div``;

const Strong = styled.strong`
  display: block;
  font-weight: bold;
`;

const Small = styled.small`
  font-size: 0.85em;
`;

const Empty = styled.span.attrs({
  children: ({ children }) => children || 'N/A',
})`
  opacity: 0.5;
`;

Draws.propTypes = propTypes;
Draws.defaultProps = defaultProps;

const enhance = compose(
  withHandlers({
    getFileData: () => (draw, key) => {
      const category = R.find(R.propEq('key', key), R.prop('documentCategories', draw));
      const targetType = R.prop('target', category);
      const targetId = R.prop('id', draw);
      const categoryId = R.prop('id', category);
      return {
        targetType,
        targetId,
        categoryId,
      };
    },
  }),
  withHandlers({
    getAppealFormFileData: ({ getFileData }) => (draw) => {
      const data = getFileData(draw, 'appeal_form');
      return data;
    },
    getLienWaiverFileData: ({ getFileData }) => (draw) => {
      const data = getFileData(draw, 'lien_waiver');
      return data;
    },
  }),
  pure,
);

export default enhance(Draws);
