import * as actionTypes from 'constants/actionTypes';

import { initialState } from 'selectors/documents';

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.DOCUMENTS_FETCH_SUCCESS:
      return [...payload.collection];
    case actionTypes.DOCUMENTS_RESET:
      return initialState;
    default:
      return state;
  }
};
