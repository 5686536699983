import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { palette } from 'styled-tools';
import { compose, getContext, pure } from 'recompose';

import Preview from './components/Preview/AnimateAdapter';
import Swap from './components/Swap';
import Cross from './components/Cross';
import Body from './components/Body';

const propTypes = {
  canDelete: PropTypes.bool,
  canSwap: PropTypes.bool,
  colorScheme: PropTypes.oneOf(['gray', 'white']),
  children: PropTypes.node,
  className: PropTypes.string,
  render: PropTypes.PropTypes.func,
  details: PropTypes.arrayOf(PropTypes.shape({})),
  isInvalid: PropTypes.bool,
  isOpen: PropTypes.bool,
  onDelete: PropTypes.func,
  property: PropTypes.shape({
    address: PropTypes.string,
    purchasePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.string]),
    repairValue: PropTypes.oneOfType([PropTypes.string, PropTypes.string]),
    type: PropTypes.string,
  }),
  size: PropTypes.oneOf(['default', 'small', 'large']),
  toClose: PropTypes.func.isRequired,
  toOpen: PropTypes.func.isRequired,
  toToggle: PropTypes.func.isRequired,
  isEditAddress: PropTypes.bool,
  canAnimateHeight: PropTypes.bool,
};

const defaultProps = {
  canDelete: false,
  canSwap: false,
  colorScheme: 'gray',
  children: undefined,
  className: undefined,
  details: [],
  isInvalid: false,
  isOpen: false,
  onDelete: undefined,
  property: undefined,
  size: 'default',
  isEditAddress: true,
  canAnimateHeight: false,
  render: undefined,
};

function Property({
  canDelete,
  canSwap,
  children,
  colorScheme,
  className,
  render,
  details,
  isInvalid,
  isOpen,
  onDelete,
  property,
  size,
  toClose,
  toOpen,
  toToggle,
  isEditAddress,
  canAnimateHeight,
}) {
  return (
    <Container colorScheme={colorScheme} isInvalid={isInvalid} size={size} isOpen={isOpen} className={className}>
      <Controls>
        {canSwap && <Swap onClick={toToggle} />}
        {isEditAddress && (
          canDelete && onDelete && <Cross onClick={onDelete} />
        )}
      </Controls>
      <Preview
        canAnimateHeight={canAnimateHeight}
        isInvalid={isInvalid}
        isOpen={isOpen}
        property={property}
        details={details}
      />
      <Body canAnimateHeight={canAnimateHeight} isOpen={isOpen}>
        {render ? render({ isOpen, toClose, toOpen, toToggle }) : children}
      </Body>
    </Container>
  );
}

const Controls = styled.div`
  display: flex;

  & > button {
    margin-right: 0.8em;
  }

  & > button:last-child {
    margin-right: 0;
  }
`;

const baseStyle = css`
  position: relative;
  padding: 1.85em 1.25em 1.5625em;
  box-shadow: 0 0.0625em 0.1875em 0 rgba(0, 0, 0, 0.2);
  transition: all 0.2s;

  ${Controls} {
    position: absolute;
    top: 0.85em;
    right: 0.85em;
  }

  ${Swap} {
    ${Swap.Angle} {
      transform: rotate(180deg);
      transition: all 0.2s;
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      padding-right: 0;
      padding-left: 0;
      box-shadow: none;
      border-bottom: 0.0625em solid #d7d7d7;

      ${Swap} {
        ${Swap.Angle} {
          transform: rotate(0deg);
        }
      }
    `}
`;

const sizes = {
  default: css`
    font-size: 1rem;
  `,
  small: css`
    font-size: 0.85rem;
  `,
  large: css`
    font-size: 1.25rem;
  `,
};

const colorSchemes = {
  gray: css`
    background: #f7f8f8;
    ${({ isInvalid }) =>
    isInvalid &&
      css`
        background: ${palette('danger', -1)};
      `}

    ${({ isOpen }) =>
    isOpen &&
      css`
        background: none;
      `}
  `,
  white: css`
    background: #fff;
    ${({ isInvalid }) =>
    isInvalid &&
      css`
        background: ${palette('danger', -1)};
      `}

    ${({ isOpen }) =>
    isOpen &&
      css`
        background: none;
      `}
  `,
};

const Container = styled.div`
  ${baseStyle}
  ${({ size }) => sizes[size]}
  ${({ colorScheme }) => colorSchemes[colorScheme]}
`;

Property.propTypes = propTypes;
Property.defaultProps = defaultProps;

const enhance = compose(
  getContext({ isEditAddress: PropTypes.bool }),
  pure,
);

export default enhance(Property);
