import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, getContext, pure } from 'recompose';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';

import { getBridgeFullFormFifthStepPropertyRefs } from 'selectors';

import { FormBox, FormGroupAdapter, FormFlex } from 'components/shared/form';

import { validateRequired } from 'utils';

import PurchaseFields from './PurchaseFields';
import DefaultFields from './DefaultFields';

const propTypes = {
  index: PropTypes.number.isRequired,
  isFormDisabled: PropTypes.bool.isRequired,
  fields: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired,
  refs: PropTypes.shape({
    findTypesCollection: PropTypes.arrayOf(PropTypes.object),
    contractStatusesCollection: PropTypes.arrayOf(PropTypes.object),
  }),
};

const defaultProps = {
  refs: {
    findTypesCollection: [],
    contractStatusesCollection: [],
  },
};

function BridgeArrayFields({ isFormDisabled, index, fields, refs, name }) {
  return (
    <Fragment>
      <FormFlex>
        <FormBox width={1}>
          <Field
            name={`${name}.address`}
            required
            disabled
            label="Address"
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <Field
        name="application.transactionPurpose"
        render={({ input: { value } }) => {
          if (value === 'purchase') {
            return <PurchaseFields name={name} index={index} fields={fields} />;
          }
          return <DefaultFields name={name} />;
        }}
      />
    </Fragment>
  );
}

BridgeArrayFields.propTypes = propTypes;
BridgeArrayFields.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    refs: getBridgeFullFormFifthStepPropertyRefs(state),
  };
}

const enhance = compose(
  getContext({ isFormDisabled: PropTypes.bool }),
  connect(mapStateToProps),
  pure,
);

export default enhance(BridgeArrayFields);
