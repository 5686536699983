import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import { APP_NAME } from 'constants/views';

import { FormBox, FormFlex, FormGroupAdapter, FormLegend } from 'components/shared/form';

import { SIGNATURE_INITIAL_VALUES } from 'constants/forms';
import { validateAccepted } from 'utils';

import { LegendFormFlex, StyledTextarea } from '.';

const propTypes = {
  disabled: PropTypes.bool,
  signatureVersion: PropTypes.string,
};

const defaultProps = {
  disabled: true,
  signatureVersion: 'v1',
};

function Disclaimer({ disabled, signatureVersion }) {
  return (
    <Fragment>
      {signatureVersion === 'v2' && APP_NAME === 'LendingOne' && (
        <Fragment>
          <LegendFormFlex justifyContent="space-between">
            <FormBox>
              <FormLegend>Equal Credit Opportunity Act</FormLegend>
            </FormBox>
          </LegendFormFlex>

          <FormFlex>
            <FormBox width={1}>
              <StyledTextarea
                rows={6}
                disabled
                value={SIGNATURE_INITIAL_VALUES[signatureVersion].disclaimer.equalCredit}
              />
            </FormBox>
          </FormFlex>
        </Fragment>
      )}

      {APP_NAME === 'LendingOne' && (
        <Fragment>
          <FormFlex>
            <FormBox width={1}>
              <StyledH4>Denial Notice:</StyledH4>
            </FormBox>
          </FormFlex>

          <FormFlex>
            <FormBox width={1}>
              <StyledTextarea
                rows={6}
                disabled
                value={SIGNATURE_INITIAL_VALUES[signatureVersion].disclaimer.denialNotice}
              />
            </FormBox>
          </FormFlex>
        </Fragment>
      )}

      <FormFlex>
        <FormBox width={1}>
          <StyledH4>Anti-Discrimination Notice:</StyledH4>
        </FormBox>
      </FormFlex>
      <FormFlex>
        <FormBox width={1}>
          <StyledTextarea
            rows={8}
            disabled
            value={SIGNATURE_INITIAL_VALUES[signatureVersion].disclaimer.antiDiscriminationNotice}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.borrower.disclaimer"
            type="checkbox"
            label="I Reviewed"
            inlineGroup
            labelOrder={1}
            errorOrder={2}
            required
            disabled={disabled}
            validate={validateAccepted()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>
    </Fragment>
  );
}

Disclaimer.propTypes = propTypes;
Disclaimer.defaultProps = defaultProps;

const StyledH4 = styled.h4`
  margin: 0.6rem 0;
  color: #a3a6a8;
  font-weight: bold;
`;

export default Disclaimer;
