import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Link from 'react-router-dom/Link';
import { ifProp, palette as toolPalette } from 'styled-tools';

const propTypes = {
  bordered: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  dataName: PropTypes.string,
  rounded: PropTypes.bool,
  palette: PropTypes.string,
  sm: PropTypes.bool,
  transparent: PropTypes.bool,
  type: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  xs: PropTypes.bool,
  external: PropTypes.bool,
};

const defaultProps = {
  bordered: false,
  children: undefined,
  className: undefined,
  disabled: false,
  dataName: null,
  rounded: false,
  transparent: false,
  palette: 'primary',
  sm: false,
  type: 'button',
  to: undefined,
  href: undefined,
  xs: false,
  external: false,
};

function backgroundColor({ disabled, palette, transparent }) {
  if (!palette) return undefined;
  if (transparent) return 'transparent';
  return toolPalette(palette, disabled ? 2 : 0);
}

function borderColor({ bordered, disabled, palette, transparent }) {
  if (!palette) return undefined;
  if (bordered) return toolPalette(palette, disabled ? 2 : 1);
  if (transparent) return 'transparent';
  return toolPalette(palette, disabled ? 2 : 0);
}

function foregroundColor({ disabled, palette, transparent }) {
  if (!palette) return undefined;
  if (transparent) return toolPalette(palette, disabled ? 2 : 1);
  return toolPalette('white', 0);
}

function hoverBackgroundColor({ disabled, transparent, palette }) {
  if (!palette) return undefined;
  return !disabled && !transparent && toolPalette(palette, 1);
}

function hoverForegroundColor({ disabled, transparent, palette }) {
  if (!palette) return undefined;
  return !disabled && transparent && toolPalette(palette, 0);
}

function Button({
  bordered,
  children,
  className,
  dataName,
  disabled,
  href,
  palette,
  rounded,
  sm,
  to,
  transparent,
  type,
  xs,
  external,
  ...props
}) {
  if (to) {
    return (
      <Link className={className} to={to} {...props}>
        {children}
      </Link>
    );
  }
  if (href) {
    return (
      <a className={className} href={href} target={external ? "_blank" : "_self"} {...props}>
        {children}
      </a>
    );
  }
  return (
    <button className={className} data-name={dataName} {...props} type={type}>
      {children}
    </button>
  );
}

const borderedStyle = css`
  box-shadow: none;
  color: ${backgroundColor};
  background-color: transparent;
  border: 1px solid ${borderColor};

  &:hover {
    background-color: ${backgroundColor};
    border-color: ${backgroundColor};
    color: ${foregroundColor};
  }
`;

const styles = css`
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  padding: 0.625em 2em;
  align-items: center;
  white-space: nowrap;
  height: 2.75em;
  appearance: none;
  text-decoration: none;
  font-size: 1rem;
  line-height: 1.42857;
  color: #fff;
  border: none;
  border-radius: ${ifProp('rounded', '3.125em', '3px')};
  pointer-events: ${ifProp('disabled', 'none', 'auto')};

  background-color: ${backgroundColor};
  cursor: ${ifProp('disabled', 'default', 'pointer')};
  box-shadow: ${ifProp(
    'transparent',
    'none',
    '0 1px 1px 0 rgba(0, 0, 0, 0.5), inset 0 2px 0 0 rgba(255, 255, 255, 0.5)',
  )};
  color: ${foregroundColor};

  &:hover {
    background-color: ${hoverBackgroundColor};
    color: ${hoverForegroundColor};
  }

  &:focus {
    outline: none;
  }

  ${ifProp('bordered', borderedStyle)};
  opacity: ${ifProp('disabled', 0.5, 1)};
`;

const xsStyle = css`
  padding: 2px 10px;
  height: 2em;
  font-size: 12px;
  line-height: 1.5;
  box-shadow: none;
`;

const smStyle = css`
  padding: 0.375em 1.5em;
  font-size: 0.8125em;
`;

const StyledButton = styled(Button).attrs({
  palette: ({ palette }) => palette || 'primary',
  type: ({ type }) => type || 'button',
})`
  ${styles};
  ${ifProp('xs', xsStyle)};
  ${ifProp('sm', smStyle)};
`;

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default StyledButton;
