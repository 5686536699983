import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { palette } from 'styled-tools';

const propTypes = {
  title: PropTypes.string,
};

const defaultProps = {
  title: 'All red asterisks are required in order to provide you with an accurate rate quote.',
};

function Asterisk({ title, ...props }) {
  return (
    <StyledAsterisk title={title} {...props}>
      *
    </StyledAsterisk>
  );
}

const StyledAsterisk = styled.abbr`
  color: ${palette('danger', 0)};
  font-size: 1rem;
  line-height: 1rem;
`;

Asterisk.propTypes = propTypes;
Asterisk.defaultProps = defaultProps;
export default Asterisk;
