import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, lifecycle, pure, withState, withHandlers } from 'recompose';

import Portal from './Portal';

const propTypes = {
  className: PropTypes.string,
  edit: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  handleAddressChange: PropTypes.func.isRequired,
  handleTogglePortal: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    meta: PropTypes.objectOf(PropTypes.bool),
  }).isRequired,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
};

const defaultProps = {
  className: undefined,
  readOnly: false,
  value: undefined,
};

function AddressCell({
  className,
  edit,
  editable,
  handleAddressChange,
  handleTogglePortal,
  initialValues,
  readOnly,
  value,
}) {
  const {
    meta: { availableToEdit },
  } = initialValues;

  return (
    <div className={`reschedule_table__cell ${className}`}>
      {!readOnly ? (
        <div className="editable-cell">
          <Portal
            initialValues={initialValues}
            availableToEdit={availableToEdit}
            editable={editable}
            handleTogglePortal={handleTogglePortal}
            handleAddressChange={handleAddressChange}
          />
          {editable ? (
            <div className="editable-cell-input-wrapper">{value || <span className="click_to_edit">N/A</span>}</div>
          ) : (
            <div>
              {value ? (
                <div>
                  {value}
                  <span className="editable-cell-indicator">Editable</span>
                </div>
              ) : (
                <span className="click_to_edit">click to edit</span>
              )}
              <Anchor type="edit" className="editable-cell-link" onClick={edit} />
            </div>
          )}
        </div>
      ) : (
        <div>{value || <span className="click_to_edit">N/A</span>}</div>
      )}
    </div>
  );
}

const Anchor = styled.a``;

AddressCell.propTypes = propTypes;
AddressCell.defaultProps = defaultProps;

const enhance = compose(
  withState('value', 'setValue', ({ value }) => value),
  withState('editable', 'setEditable', ({ editable }) => editable || false),
  withHandlers({
    edit: ({ setEditable }) => () => setEditable(true),
    handleTogglePortal: ({ setEditable }) => () => setEditable(false),
  }),
  withHandlers({
    addresListener: ({ value, setValue }) => (nextProps) => {
      if (!nextProps.initialValues) return;
      const { address } = nextProps.initialValues;

      if (address === value) return;

      setValue(address);
    },
  }),
  lifecycle({
    UNSAFE_componentWillReceiveProps(nextProps) {
      const { addresListener } = this.props;
      addresListener(nextProps);
    },
  }),
  pure,
);

export default enhance(AddressCell);
