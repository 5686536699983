import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';

import Asterisk from 'components/shared/Asterisk';

function FormAsterisk({ children, ...props }) {
  return (
    <AsteriskContainer {...props}>
      <StyledAsterisk />
      {children}
    </AsteriskContainer>
  );
}

const StyledAsterisk = styled(Asterisk)`
  position: absolute;
  top: 0;
  left: 0;
  font-size: 65%;
`;

const AsteriskContainer = styled.div`
  position: relative;
  display: block;
  padding: 0 0 0 0.625rem;
  font-family: ${theme('fonts.base')};
  font-style: italic;
  font-size: 0.875rem;
  color: #908c8b;
`;

export default FormAsterisk;
