import * as R from 'ramda';

import { getIn } from 'utils';

import { utils } from '../';

function isFormDisabledSetterHandler({ setIsFormDisabled }) {
  const setter = R.pipe(
    getIn('meta.isDisabled'),
    R.unless(R.isNil, setIsFormDisabled),
  );

  return R.tap(setter);
}

function mergeInitialValuesHandler({ setInitialValues, isFormDisabledSetter }) {
  return R.pipe(
    isFormDisabledSetter,
    utils.setDefaultInitialValues,
    setInitialValues,
  );
}

function onSubmitHandler({ updateApplicationFourthStep }) {
  return (values, form) => updateApplicationFourthStep(utils.valuesSubmitPreparation(values), form);
}

export {
  mergeInitialValuesHandler as mergeInitialValues,
  onSubmitHandler as onSubmit,
  isFormDisabledSetterHandler as isFormDisabledSetter,
};
