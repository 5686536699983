import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from 'actions';
import { getTargetDocumentsByCategoryId } from 'selectors';

import { DocAction, DocActions, DocContainer, DocFile, DocFiles, DocLegend } from 'components/blocks';
import { DocumentsUploadForm, ExplanationForm } from 'components/Application/StepsPage/shared';

const propTypes = {
  category: PropTypes.shape({
    allowExplanation: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    key: PropTypes.string,
    name: PropTypes.string,
    target: PropTypes.stirng,
  }).isRequired,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  createDocument: PropTypes.func.isRequired,
  createExplanationDocument: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  targetId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  withIcon: PropTypes.bool,
};

const defaultProps = {
  isDisabled: false,
  className: undefined,
  files: undefined,
  targetId: undefined,
  withIcon: true,
};

function DocCategory({
  isDisabled,
  category,
  className,
  createDocument,
  createExplanationDocument,
  files,
  targetId,
  withIcon,
}) {
  const explanationFile = files.find((f) => f.explanation);
  const explanation = explanationFile && explanationFile.explanation;
  return (
    <DocContainer className={className}>
      <DocLegend valid={files.length > 0} withIcon={withIcon}>
        {category.name}
      </DocLegend>
      {explanation && <Explanation>{explanation}</Explanation>}
      {files && files.length > 0 && (
        <DocFiles>
          {files.map((file) => (
            <DocFile isDisabled={isDisabled} key={`file-${file.id}`} file={file} />
          ))}
        </DocFiles>
      )}
      {!isDisabled && (
        <DocActions>
          <DocAction disabled={isDisabled} palette="primary" text="Add new Document">
            <DocumentsUploadForm
              metadata={category.metadata}
              action={createDocument}
              targetId={targetId}
              categoryId={category.id}
            />
          </DocAction>
          {category.allowExplanation && !explanation && (
            <DocAction bordered palette="grayscale" dataName="notApplicable" text="Not Applicable">
              <ExplanationForm action={createExplanationDocument} targetId={targetId} categoryId={category.id} />
            </DocAction>
          )}
        </DocActions>
      )}
    </DocContainer>
  );
}

const Explanation = styled.div`
  margin-top: 0.3rem;
  font-style: italic;
  font-size: 0.85em;
`;

DocCategory.propTypes = propTypes;
DocCategory.defaultProps = defaultProps;

function mapStateToProps(state, { targetId, category }) {
  const files = getTargetDocumentsByCategoryId(state, { categoryId: category.id, targetId });

  return {
    files,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createDocument: bindActionCreators(actions.createDocument, dispatch),
    createExplanationDocument: bindActionCreators(actions.createExplanationDocument, dispatch),
  };
}

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), pure);

export default enhance(DocCategory);
