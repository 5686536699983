import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import styled from 'styled-components';
import { compose, pure } from 'recompose';
import { Field } from 'react-final-form';

import { getEntitiesCollectionWithEmptyItem, getIsInIframe, getRentalFirstStepRefs } from 'selectors';
import { WithUser } from 'hocs';

import Link from 'components/shared/Link';

import { FormLegend, FormBox, Condition, FormAsterisk, FormGroupAdapter, NarrowFormFlex } from 'components/shared/form';
import HiddenTrackFields from 'components/blocks/LeadForm/shared/HiddenTrackFields';

import { LOAN_OFFICER_ID_CONDITION, REFERRED_BY_COMMENT_CONDITION } from 'constants/forms';
import { SIGN_IN_PATH } from 'constants/routes';

import { composeValidators, validateRequired, validateRequiredNotStrict, validateEmail, validatePhone } from 'utils';
import { userDefaultProps, userPropTypes } from 'propTypes';

const propTypes = {
  ...userPropTypes,
  refs: PropTypes.shape({
    creditScoresCollection: PropTypes.arrayOf(PropTypes.object),
    entityCollection: PropTypes.arrayOf(PropTypes.object),
    statesCollection: PropTypes.arrayOf(PropTypes.object),
    loanOfficersCollection: PropTypes.arrayOf(PropTypes.object),
    loanExperienceTransactionPurposesCollection: PropTypes.arrayOf(PropTypes.object),
    referredByTypeCollection: PropTypes.arrayOf(PropTypes.object),
  }),
  location: PropTypes.objectOf(PropTypes.any),
};

const defaultProps = {
  ...userDefaultProps,
  refs: {
    creditScoresCollection: [],
    entityCollection: [],
    statesCollection: [],
    loanOfficersCollection: [],
    loanExperienceTransactionPurposesCollection: [],
    referredByTypeCollection: [],
  },
};

function Fields({ user, entitiesCollection, contact, isInIframe, refs, location }) {
  return (
    <Fragment>
      <NarrowFormFlex alignItems="center" justifyContent="space-between">
        <FormBox mb="1rem" width={[1, 1, 'auto']}>
          <FormLegend>General Info</FormLegend>
        </FormBox>
        {!user && !isInIframe && (
          <FormBox mb="1rem" p={[3, 3, 0]} alignSelf={['flex-end', 'flex-end', 'inherit']}>
            <SignInLegend>
              Do you have a LendingOne account? Please{' '}
              <Link to={{ pathname: SIGN_IN_PATH, state: { from: location } }}>Sign In</Link>
            </SignInLegend>
          </FormBox>
        )}
      </NarrowFormFlex>

      <HiddenTrackFields />
      {!(contact && contact.firstName && contact.lastName) && (
        <NarrowFormFlex>
          <FormBox width={[1, 1, 1 / 2]}>
            <Field
              name="application.firstName"
              label="First Name"
              required
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
          <FormBox width={[1, 1, 1 / 2]}>
            <Field
              name="application.lastName"
              label="Last Name"
              required
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </NarrowFormFlex>
      )}

      {!(contact && contact.email && contact.phone) && (
        <NarrowFormFlex>
          <FormBox width={[1, 1, 5 / 12]}>
            <Field
              name="application.email"
              type="email"
              label="Email"
              required
              validate={composeValidators(validateRequired(), validateEmail())}
              component={FormGroupAdapter}
            />
          </FormBox>
          <FormBox width={[1, 1, 4 / 12]}>
            <Field
              name="application.phone"
              type="tel"
              label="Phone"
              required
              typeOfMask="phone"
              validate={composeValidators(validateRequired(), validatePhone())}
              component={FormGroupAdapter}
            />
          </FormBox>
          <FormBox width={[1, 1, 3 / 12]}>
            <Field
              name="application.phoneExtension"
              label="Extension#"
              typeOfMask="phoneExtension"
              component={FormGroupAdapter}
            />
          </FormBox>
        </NarrowFormFlex>
      )}

      {!(contact && contact.stateCode) && (
        <NarrowFormFlex>
          <FormBox width={1}>
            <Field
              name="application.stateCode"
              type="select"
              options={refs.statesCollection}
              label="Your State"
              required
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </NarrowFormFlex>
      )}
      <NarrowFormFlex>
        <FormBox width={1}>
          <Field
            name="application.rentalPropertiesCount"
            label="Number of Rental Properties you Currently Own"
            component={FormGroupAdapter}
            typeOfMask="number"
            required
            validate={validateRequired()}
          />
        </FormBox>
      </NarrowFormFlex>

      <NarrowFormFlex>
        <FormBox width={1}>
          <Field
            name="application.creditScore"
            type="select"
            options={refs.creditScoresCollection}
            required
            label="Your Credit Score"
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </NarrowFormFlex>

      {!(contact && contact.investmentPropertiesCountSoldLast36Months) && (
        <NarrowFormFlex>
          <FormBox width={1}>
            <Field
              name="application.investmentPropertiesCountSoldLast36Months"
              label="Number of Fix/Flip or New Construction Properties Sold in the Last 36 Months"
              component={FormGroupAdapter}
              typeOfMask="number"
            />
          </FormBox>
        </NarrowFormFlex>
      )}
      <NarrowFormFlex>
        <FormBox width={1}>
          <Field
            name="application.transactionPurpose"
            type="select"
            options={refs.loanExperienceTransactionPurposesCollection}
            required
            validate={validateRequired()}
            label="Purchase or Refinance?"
            component={FormGroupAdapter}
          />
        </FormBox>
      </NarrowFormFlex>

      {user && entitiesCollection && (
        <NarrowFormFlex>
          <FormBox width={1}>
            <Field
              name="application.entityId"
              type="select"
              options={entitiesCollection}
              required
              allowNull
              validate={validateRequiredNotStrict()}
              label="Entity"
              component={FormGroupAdapter}
            />
          </FormBox>
        </NarrowFormFlex>
      )}

      {!user && (
        <NarrowFormFlex>
          <FormBox width={[1, 1, 1 / 2]}>
            <Field
              name="application.referredByType"
              type="select"
              options={refs.referredByTypeCollection}
              label="How did you find us?"
              component={FormGroupAdapter}
            />
          </FormBox>
          <FormBox width={[1, 1, 1 / 2]}>
            <Condition when="application.referredByType" is={REFERRED_BY_COMMENT_CONDITION}>
              <div>
                <Field
                  name="application.referredByComment"
                  label="Name or Code"
                  component={FormGroupAdapter}
                  isConditional
                />
              </div>
            </Condition>
            <Condition when="application.referredByType" is={LOAN_OFFICER_ID_CONDITION}>
              <div>
                <Field
                  name="application.loanOfficerId"
                  type="select"
                  options={refs.loanOfficersCollection}
                  label="LendingOne Loan Officer"
                  component={FormGroupAdapter}
                  isConditional
                />
              </div>
            </Condition>
          </FormBox>
        </NarrowFormFlex>
      )}

      <NarrowFormFlex>
        <FormBox width={1}>
          <FormAsterisk>
            All red asterisks are required in order to provide you with an accurate rate quote.
          </FormAsterisk>
        </FormBox>
      </NarrowFormFlex>
    </Fragment>
  );
}

const SignInLegend = styled.div`
  display: block;
  font-size: 0.825rem;
  color: #666666;
  letter-spacing: 0px;
`;

Fields.propTypes = propTypes;
Fields.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    refs: getRentalFirstStepRefs(state),
    entitiesCollection: getEntitiesCollectionWithEmptyItem(state),
    isInIframe: getIsInIframe(state),
  };
}

const enhance = compose(
  withRouter,
  WithUser,
  connect(mapStateToProps),
  pure,
);

export default enhance(Fields);
