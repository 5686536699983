import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure } from 'recompose';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import { withRouter } from 'react-router-dom';

import { SIGNATURE_INITIAL_VALUES } from 'constants/forms';

import { FormFlex, FormBox, FormGroupAdapter, FormLegend } from 'components/shared/form';
import { Textarea } from 'components/shared/formControls';

import { validateAccepted, validateRequired } from 'utils/validates';

import Entities from './Entities';

const propTypes = {
  signatureVersion: PropTypes.string,
};

const defaultProps = {
  signatureVersion: 'v1',
};

function Fields({ signatureVersion }) {
  return (
    <>
      <FormFlex>
        <FormBox width={1}>
          <StyledTextarea
            rows={5}
            disabled
            value={SIGNATURE_INITIAL_VALUES[signatureVersion].creditAndBackgroundRealease}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="checked"
            type="checkbox"
            label="I Agree"
            inlineGroup
            labelOrder={1}
            errorOrder={2}
            required
            validate={validateAccepted()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox mt="1rem" width={1}>
          <FormLegend>Which company name/entity have you closed your properties under?</FormLegend>
        </FormBox>
      </FormFlex>
      <FieldArray subscription={{ initial: true }} name="entityName" component={Entities} />

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="signedByName"
            label="Full Legal Name"
            required
            cursive
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="signedDate"
            label="Date"
            type="date"
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>
    </>
  );
}

const StyledTextarea = styled(Textarea)`
  margin-bottom: 0.3rem;
`;

Fields.propTypes = propTypes;
Fields.defaultProps = defaultProps;

const enhance = compose(withRouter, pure);

export default enhance(Fields);
