import React from 'react';
import styled from 'styled-components';
import { compose, pure } from 'recompose';
import { withRouter } from 'react-router-dom';

import { Wrapper } from 'components/shared';
import { UserHeader } from 'components/blocks/User';

import { UserTemplate } from 'components/templates';

import { ScrollToTopOnMount } from 'hocs';

import { ApplicationHeader, Title } from '../shared';
import New from './New';

function PayoffPage() {
  return (
    <UserTemplate header={<UserHeader />}>
      <ApplicationHeader />
      <StyledWrapper>
        <Title>Payoff Request</Title>
        <New />
      </StyledWrapper>
    </UserTemplate>
  );
}

const StyledWrapper = styled(Wrapper)`
  width: 100%;
  max-width: 40rem;
`;

const enhance = compose(
  ScrollToTopOnMount,
  withRouter,
  pure,
);

export default enhance(PayoffPage);
