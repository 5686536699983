import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { APP_NAME } from 'constants/views';

import { FormBox, FormFlex, FormGroupAdapter } from 'components/shared/form';

import { validateRequired } from 'utils';

import { AccurateInformation, Disclaimer, CreditAndBackgroundRealease, InvestmentPurpose, Waiver } from './shared';

const propTypes = {
  disabled: PropTypes.bool,
  signatureVersion: PropTypes.string,
};

const defaultProps = {
  disabled: true,
  signatureVersion: 'v1',
};

function Fields({ disabled, signatureVersion }) {
  if (APP_NAME === 'LendingOne') {
    return (
      <Fragment>
        <CreditAndBackgroundRealease disabled={disabled} signatureVersion={signatureVersion} />
        <InvestmentPurpose disabled={disabled} signatureVersion={signatureVersion} />
        <AccurateInformation disabled={disabled} signatureVersion={signatureVersion} />
        <Disclaimer disabled={disabled} signatureVersion={signatureVersion} />
        <Waiver disabled={disabled} signatureVersion={signatureVersion} />

        <FormFlex>
          <FormBox width={1}>
            <Field
              name="application.borrower.signedByName"
              label="Full Legal Name"
              required
              cursive
              disabled={disabled}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>

        <FormFlex>
          <FormBox width={1}>
            <Field
              name="application.borrower.signedDate"
              label="Date"
              type="date"
              required
              disabled
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <AccurateInformation disabled={disabled} signatureVersion={signatureVersion} />
      <InvestmentPurpose disabled={disabled} signatureVersion={signatureVersion} />
      <Disclaimer disabled={disabled} signatureVersion={signatureVersion} />

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.borrower.signedByName"
            label="Full Legal Name"
            required
            cursive
            disabled={disabled}
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.borrower.signedDate"
            label="Date"
            type="date"
            required
            disabled={disabled}
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>
    </Fragment>
  );
}

Fields.propTypes = propTypes;
Fields.defaultProps = defaultProps;

export default Fields;
