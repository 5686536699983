import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import pluralize from 'pluralize';
import { withRouter } from 'react-router-dom';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from 'actions';
import { requestInProcess } from 'selectors';
import * as requestTypes from 'constants/requestTypes';
import './style.css';

const propTypes = {
  importId: PropTypes.number,
  checkingImportStatus: PropTypes.bool,
  importStatus: PropTypes.shape({}),
};

const defaultProps = {
  importId: undefined,
  checkingImportStatus: false,
  importStatus: undefined,
};

// eslint-disable-next-line react/prefer-stateless-function
class ScheduleCheckImportStatus extends Component {
  componentDidMount() {
    const { importId, checkApplicationScheduleImportStatusRequest, match } = this.props;
    const { token } = match.params;
    checkApplicationScheduleImportStatusRequest(importId, token);
  }

  render() {
    const { checkingImportStatus, importStatus } = this.props;

    return (
      <div className="reschedule_import_status__body">
        {checkingImportStatus && (
          <div className="text-centered">
            Loading Import Status...
          </div>
        )}
        {!checkingImportStatus && importStatus && (
          <div>
            <div className="reschedule_import_status__body__header">
              <div className={`reschedule_import_status__status ${importStatus.status}`}>{importStatus.displayStatus}</div>
              {importStatus.createdAt && (
                <div className="reschedule_import_status__created_at">{importStatus.createdAt}</div>
              )}
            </div>
            {(importStatus.propertiesCount || typeof importStatus.propertiesCount === "number") && (
              <p>
                <b>{importStatus.propertiesCount}</b> {pluralize('property', importStatus.propertiesCount)} imported
              </p>
            )
            }

            {importStatus.errors &&
              importStatus.errors.length > 0 && (
                <div className="reschedule_import_status__errors">
                  <b>Import Errors:</b>
                  {importStatus.errors.map((error) => (
                    <span key={error.row}>
                      {error.type === 'row' && (
                        <span>
                          <b>Line #: </b> {error.row} - {error.errors && error.errors[0]}
                        </span>
                      )}
                      {error.type === 'header' && (
                        <span>
                          <b>{error.row}</b> - {error.errors && error.errors[0]}
                        </span>
                      )}
                    </span>
                  ))}
                </div>
              )}
          </div>
        )}
        {!checkingImportStatus && !importStatus && (
          <div>No data available</div>
        )}
      </div>

    );
  }
}

ScheduleCheckImportStatus.propTypes = propTypes;
ScheduleCheckImportStatus.defaultProps = defaultProps;

function mapStateToProps(state) {
  const checkingImportStatus = requestInProcess(state, requestTypes.SCHEDULES_CHECK_IMPORT_STATUS);
  return {
    importId: state.schedules.importId,
    importStatus: state.schedules.importStatus,
    checkingImportStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    checkApplicationScheduleImportStatusRequest: bindActionCreators(actions.checkApplicationScheduleImportStatusRequest, dispatch),
  };
}

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  pure,
);

export default enhance(ScheduleCheckImportStatus);
