import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure, withHandlers, withProps } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import { space } from 'styled-system';
import { curry, ifElse, lensProp, map, pipe, prop, propEq, set, isNil } from 'ramda';

import * as actions from 'actions';
import { history } from 'store/configureStore';

import {
  getUserId,
  getApplication,
  getApplicationCurrentBorrower,
  getIsUserDealTeamOwner,
  getApplicationStatusesByType,
  requestInProcess,
} from 'selectors';

import {
  getDecoratedApplicationLoanOfficer,
  getDecoratedApplicationProjectCoordinator,
  getDecoratedApplicationAccountManager,
} from 'selectors/applications';

import { Wrapper, Badge, Icon, Link, Strong, Small, Button } from 'components/shared';
import ApplicationProgressBar from 'components/blocks/ApplicationProgressBar';

import { MY_APPLICATIONS_PATH, APPLICATION_DRAW_REQUESTS_PATH, LOAN_HELP } from 'constants/routes';

import { applicationDefaultProps, applicationPropTypes } from 'propTypes';
import { getPath } from 'utils';
import { renameKeys } from 'utils/ramda';
import { statusGreatThan, statusThisOrGreatThan } from 'utils/applications';
import { dateFormat } from 'utils/format';

import Tabs from './Tabs';
import Title from './Title';
import ManagerItem from './ManagerItem';

const propTypes = {
  ...applicationPropTypes,
  path: PropTypes.string,
  className: PropTypes.string,
  currentBorrowerName: PropTypes.string,
  isUserDealTeamOwner: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  loanOfficer: PropTypes.objectOf(PropTypes.any),
  projectCoordinator: PropTypes.objectOf(PropTypes.any),
  steps: PropTypes.arrayOf(PropTypes.any),
  token: PropTypes.string.isRequired,
  withDraw: PropTypes.func.isRequired,
};

const defaultProps = {
  ...applicationDefaultProps,
  className: null,
  currentBorrowerName: undefined,
  loading: true,
  loanOfficer: undefined,
  projectCoordinator: undefined,
  steps: [],
  userId: null,
};

function Header({
  application,
  path,
  className,
  currentBorrowerName,
  isUserDealTeamOwner,
  loading,
  loanOfficer,
  projectCoordinator,
  steps,
  accountManager,
}) {
  if (!application || loading) {
    return (
      <ContentLoader height={50} speed={3} style={{ margin: '-0.625rem 0 0' }}>
        <rect x="0" y="0" width="100%" height="50" />
      </ContentLoader>
    );
  }

  const { status, maturityDate } = application;
  const greateThenPreliminaryReview = curry(statusGreatThan)('preliminary_review');
  const isStatusSetupServicingOrFurther = curry(statusThisOrGreatThan)('setup_servicing');

  return (
    <Wrapper>
      <Container className={className}>
        <Content>
          <Info>
            <Subtitle mb="0.25rem">
              {application.displayShortType}
              {isUserDealTeamOwner && (
                <span>
                  {' / '}
                  <DealTeamOwnerLabel name={currentBorrowerName} />
                </span>
              )}
            </Subtitle>

            <Title properties={application.properties} />
            <StyledProgressBar mb="2.875rem" mt="1.25rem" steps={steps} maturityDatePresent={!isNil(maturityDate)} />
            {maturityDate && (
              <StyledMaturityDate>
                <Small>
                  Maturity date: <Strong>{dateFormat(maturityDate)}</Strong>
                </Small>
              </StyledMaturityDate>
            )}
          </Info>
          <RightContent>
            {path === APPLICATION_DRAW_REQUESTS_PATH ? (
              <div>
                <p>
                  Draw Request Contact Information:
                </p>
                <p>
                  <Small>
                    Email:&nbsp;
                    <Strong>
                      <Link to="mailto: draws@lendingone.com">
                        draws@lendingone.com
                      </Link>
                    </Strong>
                  </Small>
                </p>
              </div>
            ) : (
              <React.Fragment>
                {loanOfficer && <ManagerItem name="Your Loan Officer" item={loanOfficer} />}
                {accountManager && <ManagerItem name="Your Account Manager" item={accountManager} />}
                {projectCoordinator && greateThenPreliminaryReview(status) && (
                  <ManagerItem name="Your Project Coordinator" item={projectCoordinator} />
                )}

                {isStatusSetupServicingOrFurther(status) && (
                  <Button xs external rounded href={LOAN_HELP}>
                    Loan Help
                  </Button>
                )}
              </React.Fragment>
            )}
          </RightContent>
        </Content>

        <Tabs />

        <BackLink to={getPath(MY_APPLICATIONS_PATH, { status: 'active' })} underline={false} width={0.45}>
          <Icon icon="circleArrow" />
        </BackLink>
      </Container>
    </Wrapper>
  );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const Subtitle = styled.span`
  ${space}
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.35);
  text-align: left;
`;

const StyledProgressBar = styled(ApplicationProgressBar)`
  ${space}
  margin-bottom: ${(props) => (props.maturityDatePresent ? '1rem' : '2.8rem')};;
  max-width: 34rem;
`;

const StyledMaturityDate = styled.p`
  margin-bottom: 2.8rem;
`;

const BackLink = styled(Link)`
  position: absolute;
  left: -4.1875rem;
  top: 0.625rem;
  opacity: 0.45;
  color: #979797;
  font-size: 2rem;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.65;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Info = styled.div`
  flex-grow: 1;
`;

const RightContent = styled.div`
  margin-left: 1rem;
  padding-top: 1.125rem;

  & > div {
    margin-bottom: 1rem;
  }
`;

const DealTeamOwnerLabel = styled(({ name, children, ...props }) => (
  <Badge size="sm" palette="alert" tone={3} colorPalette="grayscale" colorTone={1} {...props}>
    {children || `You are editing the application as ${name || '...'}!`}
  </Badge>
))`
  font-weight: normal;
  white-space: normal;
`;

const mapStateToProps = (state, { match }) => {
  const { token } = match.params;
  const { path } = match;
  const userId = getUserId(state);
  const application = getApplication(state);
  const applicationIsLoading = requestInProcess(state, 'APPLICATION');
  const isUserDealTeamOwner = getIsUserDealTeamOwner(state);
  const currentBorrower = getApplicationCurrentBorrower(state);
  const currentBorrowerName = currentBorrower && currentBorrower.displayName;
  const statuses = getApplicationStatusesByType(state, { applicationType: prop('type', application) });
  const loanOfficer = getDecoratedApplicationLoanOfficer(state);
  const projectCoordinator = getDecoratedApplicationProjectCoordinator(state);
  const accountManager = getDecoratedApplicationAccountManager(state);

  return {
    application,
    path,
    currentBorrowerName,
    isUserDealTeamOwner,
    loading: applicationIsLoading,
    loanOfficer,
    projectCoordinator,
    statuses,
    token,
    userId,
    accountManager,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    withDrawAction: bindActionCreators(actions.withDraw, dispatch),
  };
}

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withProps(({ statuses, application }) => {
    if (!application) {
      return {};
    }

    const { status } = application;
    return {
      steps: pipe(
        map(renameKeys({ value: 'key', label: 'title' })),
        map(ifElse(propEq('key', status), set(lensProp('current'), true), set(lensProp('current'), false))),
      )(statuses),
    };
  }),
  withHandlers({
    withDraw: ({ withDrawAction, token }) => () => {
      withDrawAction(token).then(() => history.push(getPath(MY_APPLICATIONS_PATH, { status: 'active' })));
    },
  }),
  pure,
);

export default enhance(Header);
