import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import { propOr } from 'ramda';

import { Button, Well } from 'components/shared';
import { FormBox, FormFlex, FormGroupAdapter, FormHeader } from 'components/shared/form';
import { WellBody, WellHeader, WellFooter } from 'components/shared/Well';

import { validateRequired } from 'utils';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    login: PropTypes.string,
  }),
};

const defaultProps = {
  values: undefined,
};

function Form({ values, handleSubmit, submitting, success }) {
  const login = propOr('', 'login', values);
  return (
    <form>
      <Well>
        <WellHeader key="header">
          <FormHeader title="Password Recovery" />
        </WellHeader>

        <WellBody key="body">
          <StyledFormFlex>
            {success && <Success>To recover your password check this email{login && `: ${login}`}</Success>}
            {!success && (
              <FormBox width={1}>
                <Field
                  name="login"
                  label="Email"
                  required
                  validate={validateRequired()}
                  render={(props) => {
                    if (success) {
                      return <Success />;
                    }
                    return <FormGroupAdapter {...props} />;
                  }}
                />
              </FormBox>
            )}
          </StyledFormFlex>
        </WellBody>

        <WellFooter key={!success && 'footer'}>
          <StyledFormFlex flexDirection="column">
            <FormBox alignSelf="flex-end">
              <Button type="submit" onClick={handleSubmit} rounded disabled={submitting}>
                Recover Password
              </Button>
            </FormBox>
          </StyledFormFlex>
        </WellFooter>
      </Well>
    </form>
  );
}

const StyledFormFlex = styled(FormFlex)`
  max-width: 585px;
`;

const Success = styled.p``;

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

export default Form;
