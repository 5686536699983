import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { defaultProps as recomposeDefaultProps } from 'recompose';
import { Flex } from 'grid-styled';
import theme from 'components/themes/default';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  maxWidth: PropTypes.string,
};

const defaultProps = {
  className: null,
  maxWidth: null,
};

const { breakpoints } = theme;

function FormFlex({ children, className, maxWidth, ...props }) {
  return (
    <StyledFormFlex className={className} maxwidth={maxWidth} {...props}>
      {children}
    </StyledFormFlex>
  );
}

const ExtendedFlex = recomposeDefaultProps({
  flexDirection: ['column', 'culumn', 'row'],
})(Flex);

const StyledFormFlex = styled(ExtendedFlex)`
  margin: auto;
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  @media (max-width: ${breakpoints[2]}) {
    width: 100%;
    max-width: 100%;
  }
`;

FormFlex.propTypes = propTypes;
FormFlex.defaultProps = defaultProps;

export default FormFlex;
