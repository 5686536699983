import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, defaultProps as recomposeDefaultProps, mapProps, pure, withHandlers, withState } from 'recompose';

import DayPickerSingleDateController from 'react-dates/lib/components/DayPickerSingleDateController';
import moment from 'moment';
import * as R from 'ramda';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { dateFormat, dateIsValid } from 'utils';

import Input from 'components/shared/formControls/Input';

const propTypes = {
  withDatepicker: PropTypes.bool,
  focused: PropTypes.bool.isRequired,
  changeHandler: PropTypes.func.isRequired,
  date: PropTypes.objectOf(PropTypes.any),
  onFocusChange: PropTypes.func.isRequired,
  onOutsideClick: PropTypes.func.isRequired,
};

const defaultProps = {
  withDatepicker: true,
  date: null,
};

function DateInput({ focused, value, changeHandler, date, onFocusChange, onOutsideClick, withDatepicker, ...props }) {
  return (
    <Container>
      <Input {...props} value={value} />
      {withDatepicker && focused && (
        <DayPickerContainer>
          <DayPickerSingleDateController
            date={date}
            onFocusChange={onFocusChange}
            onDateChange={changeHandler}
            onOutsideClick={onOutsideClick}
            focused={focused}
          />
        </DayPickerContainer>
      )}
    </Container>
  );
}

const enhance = compose(
  withState('focused', 'setFocused', false),
  recomposeDefaultProps({ typeOfMask: 'date' }),
  withHandlers({
    open: ({ setFocused }) => (e) => {
      setFocused(true);
      return e;
    },
    onOutsideClick: ({ setFocused }) => () => setFocused(false),
    onFocusChange: ({ setFocused }) => (p) => setFocused(p.focused),
    changeHandler: ({ onChange }) => (d) => onChange(d._d),
  }),
  mapProps(({ setFocused, open, value, onFocus, ...props }) => ({
    ...props,
    value: dateIsValid(value) ? dateFormat(value) : value,
    onFocus: R.pipe(
      open,
      onFocus,
    ),
    date: dateIsValid(value) ? moment(value) : null,
  })),
  pure,
);

const Container = styled.div`
  position: relative;
`;

const DayPickerContainer = styled.div`
  position: absolute;
  z-index: 100;
  top: 100%;
  margin-top: 1rem;
`;

DateInput.propTypes = propTypes;
DateInput.defaultProps = defaultProps;

export default enhance(DateInput);
