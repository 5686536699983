import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';

import { getApplicationPropertiesCollection, getExtensionRequestCollectionRefs } from 'selectors';

import { Button } from 'components/shared';
import { FormFlex, FormBox, FormGroupAdapter, RadioGroupAdapter, SubmitError } from 'components/shared/form';
import { Radio } from 'components/shared/formControls';
import { Field } from 'react-final-form';
import { APP_NAME } from 'constants/views';

import { validateRequired } from 'utils';

import Expenses from './Expenses';
import PropertySelectAdapter from './PropertySelectAdapter';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  propertiesCollection: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  refs: PropTypes.shape({
    loanExtensionTermsCollection: PropTypes.objectOf(PropTypes.any),
    occupanciesCollection: PropTypes.objectOf(PropTypes.any),
    loanTransactionPurposesCollection: PropTypes.objectOf(PropTypes.any),
    exitStrategiesCollection: PropTypes.objectOf(PropTypes.any),
  }),
  submitError: PropTypes.string,
};

const defaultProps = {
  propertiesCollection: [],
  refs: {
    loanExtensionTermsCollection: [],
    occupanciesCollection: [],
    loanTransactionPurposesCollection: [],
    exitStrategiesCollection: [],
  },
  submitError: undefined,
};

function Form({ submitError, handleSubmit, propertiesCollection, refs, submitting }) {
  return (
    <form>
      {submitError && <SubmitError error={submitError} />}
      <FormFlex>
        <FormBox width={1}>
          <Field
            name="extension.propertyId"
            label="Property"
            type="select"
            options={propertiesCollection}
            component={PropertySelectAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="extension.initialAmount"
            label="Initial amount"
            typeOfMask="currency"
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="extension.remainingAmount"
            label="Remaining amount"
            typeOfMask="currency"
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="extension.term"
            label="Term"
            type="select"
            options={refs.loanExtensionTermsCollection}
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Text>
            We typically charge a minimum of 1% of the loan amount for 3-month extensions, and 2% of the loan amount for
            6-month extensions. To consider your extension request, the borrower will be required to pay a
            non-refundable fee of $200 to update the valuation of the Property.
          </Text>
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Text>
            Disclaimer: If the loan is delinquent or in any way non-performing, {APP_NAME} will not consider the
            extension request until the loan is brought current and any other applicable default cured. All extension
            requests are subject to lender's credit, legal and investment approval process and is not intended to and
            does not create a legally binding commitment or obligation on the part of lender.
          </Text>
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="extension.propertyCondition"
            label="Property condition"
            type="textarea"
            rows={4}
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name="extension.occupancy"
            label="Is the Property Leased?"
            type="select"
            options={refs.occupanciesCollection}
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name="extension.transactionPurpose"
            label="Transaction purpose"
            type="select"
            options={refs.loanTransactionPurposesCollection}
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name="extension.exitStrategy"
            label="Exit strategy"
            type="select"
            options={refs.exitStrategiesCollection}
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            component={RadioGroupAdapter}
            inline
            label="Repairs completed"
            name="extension.repairsCompleted"
            required
            type="checkbox"
            validate={validateRequired()}
          >
            <Radio id="extensionRepairsCompletedTrue" value="true" label="Yes" />
            <Radio id="extensionRepairsCompletedFalse" value="false" label="No" />
          </Field>
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="extension.explanation"
            label="Explanation"
            type="textarea"
            rows={4}
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <Expenses />

      <Footer justifyContent="flex-end">
        <FormBox>
          <Button onClick={handleSubmit} rounded disabled={submitting}>
            {submitting ? 'Please wait…' : 'Continue'}
          </Button>
        </FormBox>
      </Footer>
    </form>
  );
}

const Footer = styled(FormFlex)`
  padding: 1.25rem 0;
`;

const Text = styled.p`
  margin-bottom: 1.25rem;
`;

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    propertiesCollection: getApplicationPropertiesCollection(state),
    refs: getExtensionRequestCollectionRefs(state),
  };
}

const enhance = compose(
  connect(mapStateToProps),
  pure,
);

export default enhance(Form);
