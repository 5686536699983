import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { APPLICATION_SCHEDULE_PATH } from 'constants/routes';

import { ToDo, Button } from 'components/shared';

import { getPath } from 'utils';

const propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

function RealEstateExperienceToDo({ match }) {
  const { token } = match.params;
  return (
    <ToDo>
      <ToDo.Title>Provide your Real Estate Experience</ToDo.Title>
      <ToDo.Actions>
        <Button to={getPath(APPLICATION_SCHEDULE_PATH, { token })} sm>
          Provide
        </Button>
      </ToDo.Actions>
    </ToDo>
  );
}

RealEstateExperienceToDo.propTypes = propTypes;

export default withRouter(RealEstateExperienceToDo);
