import ip from 'ip';
import * as R from 'ramda';

import { setInDefaultValue } from 'decorators';
import { throwSubmissionError, successNotification } from 'utils';

function setDefaultInitialValuesHandler({ match }) {
  const { token } = match.params;
  return R.pipe(
    setInDefaultValue(token, 'token'),
    setInDefaultValue('background_release', 'type'),
    setInDefaultValue([''], 'entityName'),
    setInDefaultValue(ip.address(), 'signedFromIp'),
  );
}

function mergeInitialValuesHandler({ setDefaultInitialValues, setInitialValues }) {
  return R.pipe(
    setDefaultInitialValues,
    setInitialValues,
  );
}

function onSubmitHandler({ createAgreements, setRedirectTrue }) {
  return R.pipe(
    createAgreements,
    R.then(() => {
      successNotification('Credit/Background Release was successfully completed.');
      setRedirectTrue();
    }),
    R.otherwise(throwSubmissionError),
  );
}

export {
  setDefaultInitialValuesHandler as setDefaultInitialValues,
  mergeInitialValuesHandler as mergeInitialValues,
  onSubmitHandler as onSubmit,
};
