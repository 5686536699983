import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import NavLink from 'react-router-dom/NavLink';

import { Wrapper } from 'components/shared';
import { INDEX_PATH } from 'constants/routes';
import { APP_NAME } from 'constants/views';

import logo from 'components/shared/LogoImage/logo.png';

function Header() {
  return (
    <Wrapper>
      <HeaderContainer>
        <HeaderLogo to={INDEX_PATH}>{APP_NAME}</HeaderLogo>
      </HeaderContainer>
    </Wrapper>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  ${media.lessThan('medium')`
    display: block;
    padding: 10px 0;
  `};
`;

const HeaderLogo = styled(NavLink).attrs({})`
  position: relative;
  display: block;
  box-sizing: border-box;
  width: 150px;
  height: 44px;
  line-height: 0;
  font-size: 0;
  color: transparent;
  background: url(${logo}) no-repeat 0 0;
  background-size: contain;

  ${media.lessThan('medium')`
    margin: 0 auto;
    width: 163px;
    height: 46px;
  `};
`;

export default Header;
