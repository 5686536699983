import * as actionTypes from '../constants/actionTypes';
import * as requestTypes from '../constants/requestTypes';
import setRequestInProcess from '../actions/request';

import api from '../utils/api';

export const fetchContacts = (token) => (dispatch) => {
  const requestType = requestTypes.CONTACTS;
  const url = `/applications/${token}/contacts`;

  dispatch(setRequestInProcess(true, requestType));
  return api
    .get(url)
    .then(({ data }) => {
      dispatch(setRequestInProcess(false, requestType));
      dispatch(fetchContactsSuccess(data));
    })
    .catch((response) => {
      dispatch(setRequestInProcess(false, requestType));
      console.log('response', response);
    }); // FIXME to think about how to handle the error correctly
};

export const fetchContact = (token, id) => () => {
  const url = `/applications/${token}/contacts/${id}/edit`;

  return api
    .get(url)
    .then(({ data }) => data)
    .catch((response) => {
      console.log('response', response);
    }); // FIXME to think about how to handle the error correctly
};

export const createContact = ({ token, ...values }) => (dispatch) => {
  const url = `/applications/${token}/contacts`;

  return api.post(url, values).then(() => {
    dispatch(fetchContacts(token));
  });
};

export const updateContact = ({ token, ...values }) => (dispatch) => {
  const url = `/applications/${token}/contacts/${values.contact.id}`;

  return api.patch(url, values).then(() => {
    dispatch(fetchContacts(token));
  });
};

export const deleteContact = ({ token, id }) => (dispatch) => {
  const url = `/applications/${token}/contacts/${id}`;

  return api
    .delete(url)
    .then(() => {
      dispatch(fetchContacts(token));
    })
    .catch((error) => console.log('error', error));
};

export function fetchContactsSuccess(payload) {
  return {
    type: actionTypes.CONTACTS_FETCH_SUCCESS,
    payload,
  };
}

export function resetContacts() {
  return {
    type: actionTypes.CONTACTS_RESET,
  };
}
