import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { Flex, Box } from 'grid-styled';
import * as R from 'ramda';

import { getDrawRequestsByPropertyId } from 'selectors';

import { Badge, Strong, Link } from 'components/shared';

import { getPath, dateHumanFormat } from 'utils';
import { APPLICATION_NEW_DRAW_REQUESTS_PATH, APPLICATION_PATH } from 'constants/routes';

import Draws from '../Draws';

import { Action } from '../../../DrawRequestsPage/shared';

const propTypes = {
  application: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.string,
  draws: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  property: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    applicationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    property_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    createdAt: PropTypes.string,
    approvedAt: PropTypes.string,
    requestedAmount: PropTypes.string,
    approvedAmount: PropTypes.string,
    active: PropTypes.bool,
    meta: PropTypes.objectOf(PropTypes.bool),
  }),
};

const defaultProps = {
  application: undefined,
  className: undefined,
  draws: [],
  property: undefined,
};

function Property({ application, className, draws, property }) {
  return (
    <Container className={className}>
      <Header>
        <Flex>
          <StyledBox width={1 / 5}>
            {application && application.label && <ApplicationLabel label={application.label} />}
            {application && application.internalId && (
              <StyledStrong>
                <Link to={getPath(APPLICATION_PATH, { token: application.token })}>{application.internalId}</Link>
              </StyledStrong>
            )}
          </StyledBox>
          <StyledBox flex="1">
            {property && property.address && <StyledStrong>{property.address}</StyledStrong>}
          </StyledBox>
          <StyledBox width={1 / 8}>{property && property.createdAt && dateHumanFormat(property.createdAt)}</StyledBox>
        </Flex>
      </Header>
      {application && <StyledDraws draws={draws} applicationToken={application.token} />}
      <Footer>
        {!application && <Action disabled>Request Draw</Action>}
        {application && application.meta.availableToRequestDraw && property.active && (
          <Action
            to={getPath(APPLICATION_NEW_DRAW_REQUESTS_PATH, { token: application.token })}
            disabled={!application}
          >
            Request Draw
          </Action>
        )}
      </Footer>
    </Container>
  );
}

const Container = styled.div``;
const Header = styled.div``;

const StyledDraws = styled(Draws)`
  margin-bottom: 1rem;
`;

const Footer = styled.div`
  text-align: right;
`;

const StyledBox = styled((props) => <Box p="0.5rem" {...props} />)``;

const ApplicationLabel = styled(({ label, ...props }) => (
  <Badge size="sm" palette="alert" {...props}>
    {label}
  </Badge>
))``;

const StyledStrong = styled(Strong)`
  display: block;
`;

Property.propTypes = propTypes;
Property.defaultProps = defaultProps;

const mapStateToProps = (state, { property }) => ({
  draws: getDrawRequestsByPropertyId(state, R.prop('id', property)),
});

const enhance = compose(
  connect(mapStateToProps),
  pure,
);

export default enhance(Property);
