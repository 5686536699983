import React from 'react';
import PropTypes from 'prop-types';

import { FormField, FormGroup } from 'components/shared/form';

const propTypes = {
  className: PropTypes.string,
  errorOrder: PropTypes.number,
  inline: PropTypes.bool,
  inlineGroup: PropTypes.bool,
  input: PropTypes.objectOf(PropTypes.any).isRequired,
  hint: PropTypes.node,
  hintOrder: PropTypes.number,
  inputOrder: PropTypes.number,
  meta: PropTypes.objectOf(PropTypes.any).isRequired,
  label: PropTypes.node,
  labelOrder: PropTypes.number,
  required: PropTypes.bool,
  rightHint: PropTypes.bool,
};

const defaultProps = {
  className: null,
  errorOrder: undefined,
  inline: false,
  inlineGroup: false,
  hint: null,
  hintOrder: undefined,
  inputOrder: undefined,
  label: null,
  labelOrder: undefined,
  required: false,
  rightHint: false,
};

function FormGroupAdapter({
  input,
  meta: { error, touched, submitError, dirtySinceLastSubmit },
  label,
  hint,
  className,
  inline,
  inlineGroup,
  required,
  rightHint,
  errorOrder,
  hintOrder,
  inputOrder,
  labelOrder,
  ...rest
}) {
  const inputProps = {
    ...input,
    ...rest,
  };

  const formGroupProps = {
    name: input.name,
    error: error || submitError,
    label,
    hint,
    invalid: !!((error && touched) || (submitError && !dirtySinceLastSubmit)),
    inline: inlineGroup,
    className,
    required,
    rightHint,
    errorOrder,
    hintOrder,
    inputOrder,
    labelOrder,
  };
  return (
    <FormGroup {...formGroupProps}>
      <FormField {...inputProps} />
    </FormGroup>
  );
}

FormGroupAdapter.propTypes = propTypes;
FormGroupAdapter.defaultProps = defaultProps;

export default FormGroupAdapter;
