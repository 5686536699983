import { PAGINATE_LINK_SET } from 'constants/actionTypes';

const initialState = {};

function setPaginateLink(state, nextHref, paginateType) {
  const paginateObject = {};
  paginateObject[paginateType] = nextHref;
  return Object.assign({}, state, paginateObject);
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PAGINATE_LINK_SET:
      return setPaginateLink(state, action.nextHref, action.paginateType);
    default:
      return state;
  }
};
