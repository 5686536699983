import React from 'react';
import PropTypes from 'prop-types';
import { compose, getContext, lifecycle, pure, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';

import { getTargetDocumentsByCategoryId } from 'selectors';

import { FormBox, FormFlex } from 'components/shared/form';
import { DocCategory } from 'components/blocks';

import { getIn, validateRequired, validateEmpty, composeValidators } from 'utils';

import Hoc from './Hoc';
import FilesField from './FilesField';
import * as handlers from './handlers';

const propTypes = {
  category: PropTypes.objectOf(PropTypes.any),
  isFormDisabled: PropTypes.bool.isRequired,
  targetId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  withIcon: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};

const defaultProps = {
  targetId: undefined,
  category: undefined,
};

function Files({ isFormDisabled, category, targetId, withIcon, name }) {
  if (!targetId) return null;
  if (!category) return null;
  return (
    <FormFlex>
      <FormBox mb="1rem" width={1}>
        <DocCategory isDisabled={isFormDisabled} withIcon={withIcon} targetId={targetId} category={category} />
        {withIcon && (
          <FilesField validate={composeValidators(validateRequired(), validateEmpty())} name={`${name}.files`} />
        )}
        {!withIcon && <FilesField name={`${name}.files`} />}
      </FormBox>
    </FormFlex>
  );
}

Files.propTypes = propTypes;
Files.defaultProps = defaultProps;

function mapStateToProps(state, { targetId, categoryId }) {
  const files = getTargetDocumentsByCategoryId(state, { categoryId, targetId });

  return {
    files,
  };
}

const enhance = compose(
  getContext({ isFormDisabled: PropTypes.bool }),
  Hoc,
  withProps(({ values, name, category }) => {
    const categoryId = (category && category.id) || undefined;
    const targetId = getIn(`${name}.id`, values);
    const saleSignedAgreement = getIn(`${name}.saleSignedAgreement`, values);
    const withIcon = saleSignedAgreement === 'true' || saleSignedAgreement === true;
    return { targetId, withIcon, categoryId };
  }),
  connect(mapStateToProps),
  withHandlers({
    setFilesValue: handlers.setFilesValue,
  }),
  lifecycle({
    componentDidMount() {
      const { setFilesValue } = this.props;
      setFilesValue();
    },
    componentDidUpdate({ files }) {
      const { setFilesValue } = this.props;
      setFilesValue(files);
    },
  }),
  pure,
);

export default enhance(Files);
