import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { ifProp } from 'styled-tools';

import * as actions from 'actions';

import { getContactById } from 'selectors';

import { Link, IconButton, Button } from 'components/shared';
import Drawer from 'components/shared/Drawer';

import Edit from '../Edit';

const propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.string,
    target: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    displayName: PropTypes.string,
    title: PropTypes.string,
    legalName: PropTypes.string,
    phone: PropTypes.string,
    phoneExtension: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.string,
    meta: PropTypes.shape({
      availableToEdit: PropTypes.bool,
      availableToDestroy: PropTypes.bool,
    }),
  }).isRequired,
  deleteCotact: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

function Contact({ contact, deleteCotact, id, match }) {
  const { token } = match.params;
  return (
    <Tr>
      <Td>
        <TextMuted className="small text-muted">{contact.type}</TextMuted>
        <br />
        {contact.displayName}
        {contact.legalName && `, ${contact.legalName}`}
      </Td>
      <Td>
        <Link to={`mailto:${contact.email}`}>{contact.email}</Link>
        <br />
        {contact.phone}
        {contact.phoneExtension && (
          <span> Extension# {contact.phoneExtension}</span>
        )}
      </Td>
      <Td right>
        <Actions>
          {contact.meta.availableToEdit && (
            <Drawer content={({ togglePanel }) => <Edit id={contact.id} callback={togglePanel} />}>
              {({ togglePanel }) => (
                <Button rounded xs onClick={togglePanel}>
                  Edit
                </Button>
              )}
            </Drawer>
          )}
          {contact.meta.availableToDestroy && (
            <Fragment>
              &nbsp;
              <IconButton xs rounded palette="danger" icon="trash" onClick={() => deleteCotact({ id, token })} />
            </Fragment>
          )}
        </Actions>
      </Td>
    </Tr>
  );
}

const TextMuted = styled.span`
  color: #a3a6a8;
  font-size: 0.85em;
`;

const Tr = styled.tr`
  &:hover {
    background-color: #f5f5f5;
  }
`;

const Td = styled.td`
  padding: 0.3125rem;
  text-align: ${ifProp('right', 'right', 'left')};
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

Contact.propTypes = propTypes;

function mapStateToProps(state, { id }) {
  return {
    contact: getContactById(state, id),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteCotact: bindActionCreators(actions.deleteContact, dispatch),
  };
}

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  pure,
);

export default enhance(Contact);
