import * as R from 'ramda';

import { setInDefaultValue, setInDefaultNotEmptyValue } from 'decorators';

function setDefaultInitialValuesHandler({ match }) {
  const { token } = match.params;
  return R.pipe(
    setInDefaultValue(token, 'token'),
    setInDefaultNotEmptyValue([{}], 'drawRequest.bankDetails'),
  );
}

function mergeInitialValuesHandler({ setDefaultInitialValues, setInitialValues }) {
  return R.pipe(
    setDefaultInitialValues,
    setInitialValues,
  );
}

export { setDefaultInitialValuesHandler as setDefaultInitialValues, mergeInitialValuesHandler as mergeInitialValues };
