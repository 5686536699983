import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContentLoader from 'react-content-loader';
import * as R from 'ramda';

import * as actions from 'actions';
import { getUserContactId } from 'selectors';

import { WithIncompleteApp, WithUser } from 'hocs';

import { GroupPanel, List, ListLi, Panel, SecurityStandards, Wrapper } from 'components/shared';
import { FormWrapper } from 'components/shared/form';

import RentalStep1 from 'components/blocks/LeadForm/rental/Step1';
import { AccessDenied, Incomplete } from 'components/blocks/LeadForm/shared';

const propTypes = {
  isAccessDenied: PropTypes.bool,
  incompleteApp: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool,
};

const defaultProps = {
  isAccessDenied: false,
  incompleteApp: undefined,
  loading: false,
};

const Content = (props) => {
  const { isAccessDenied, incompleteApp, loading } = props;

  if (loading) {
    return <ContentLoader />;
  }

  if (isAccessDenied) {
    return <AccessDenied />;
  }

  if (incompleteApp) {
    return <Incomplete application={incompleteApp} />;
  }

  return <RentalStep1 />;
};

function Rental(props) {
  return (
    <Fragment>
      <FormWrapper>
        <Content {...props} />
      </FormWrapper>
      <Wrapper padded>
        <GroupPanel>
          <Panel title="About RentalOne Loans">
            Our RentalOne loan product provides our customers a reliable source of financing through a simple and
            efficient online loan process. Our 30-year fixed-rate rental loan product was designed to help savvy
            investors capitalize on the rapidly growing rental market. With attractive rates and up to 75% loan-to-value
            (LTV) on fixed-rate loans, these loans are a great way to get cash out from your existing rental properties.
            Interest-only loans are also available.
          </Panel>

          <Panel title="Advantages of RentalOne Loans:">
            <List>
              <ListLi>30-Year fixed-rate available</ListLi>
              <ListLi>Closings in weeks not months</ListLi>
              <ListLi>No hurdles- Fast Pre-Approvals Online</ListLi>
              <ListLi>Stated Income</ListLi>
            </List>
          </Panel>
        </GroupPanel>
      </Wrapper>
      <SecurityStandards />
    </Fragment>
  );
}

function mapStateToProps(state, props) {
  const { incompleteApp, contact, userIsLoading, applicationsIsLoading } = props;

  return {
    userContactId: getUserContactId(state),
    incompleteApp,
    loading: userIsLoading || applicationsIsLoading,
    isAccessDenied: contact && (contact.isAdmin || contact.isCrestar),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchEntities: bindActionCreators(actions.fetchEntities, dispatch),
  };
}

Content.propTypes = propTypes;
Content.defaultProps = defaultProps;

const enhance = compose(
  WithUser,
  WithIncompleteApp,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withHandlers({
    contactListener: ({ fetchEntities, userContactId }) => (prevProps) => {
      if (R.equals(userContactId, prevProps.userContactId)) return;
      if (R.isNil(userContactId)) return;
      fetchEntities(userContactId);
    },
  }),
  lifecycle({
    UNSAFE_componentWillMount() {
      const { userContactId, fetchEntities } = this.props;
      if (userContactId) fetchEntities(userContactId);
    },
    componentDidUpdate(prevProps) {
      const { contactListener } = this.props;
      contactListener(prevProps);
    },
  }),
  pure,
);

export default enhance(Rental);
