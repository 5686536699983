import React from 'react';

import { BRIDGE_PATH } from 'constants/routes';

import {
  Product,
  ProductBody,
  ProductButton,
  ProductList,
  ProductThumb,
  ProductTitle,
} from '../styles';
import thumb from './thumb.jpg';

function FixNFlip() {
  const advantages = [
    '12-Month Interest Only (with no prepayment penalty)',
    'Up to 90% of Purchase Price & 100% of Repairs',
    'Up to 75% ARV ',
    'No Interest on Unused Rehab Funds',
  ];

  return (
    <Product color="bridge" to={BRIDGE_PATH}>
      <ProductThumb src={thumb} />
      <ProductBody>
        <ProductTitle>Fix-n-Flip Loans</ProductTitle>
        <ProductList>{advantages}</ProductList>
        <ProductButton>Apply now</ProductButton>
      </ProductBody>
    </Product>
  );
}

export default FixNFlip;
