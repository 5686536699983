import { createSelector } from 'reselect';
import { identity, find, prop, propEq } from 'ramda';

import { decorateContact } from 'decorators';

export const initialState = [];

function processorNotesSelector(state) {
  return state.processorNotes || initialState;
}

function processorNoteIdSelector(state, options) {
  return prop('processorNoteId', options);
}

export const getProcessorNotes = createSelector(
  processorNotesSelector,
  identity,
);

export const getProcessorNote = createSelector(
  [processorNoteIdSelector, getProcessorNotes],
  (id, notes) => find(propEq('id', id), notes),
);

export const getProcessorNoteAuthor = createSelector(
  getProcessorNote,
  prop('author'),
);

export const getProcessorNotesDecoratedAuthor = createSelector(
  getProcessorNoteAuthor,
  decorateContact,
);

export const getProcessorNotesAuthorName = createSelector(
  getProcessorNotesDecoratedAuthor,
  prop('displayName'),
);
