import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { compose, pure } from 'recompose';

import { getAuthStatus, requestInProcess } from 'selectors';
import { SIGN_IN_PATH } from 'constants/routes';

import { getRemainingTime } from 'utils';

const propTypes = {
  isAvailable: PropTypes.bool,
};

const defaultProps = {
  isAvailable: false,
};

function PrivateRoute({ component: Component, isAvailable, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAvailable) return <Component {...props} />;
        return <Redirect to={{ pathname: SIGN_IN_PATH, state: { from: props.location } }} />;
      }}
    />
  );
}

function mapStateToProps(state) {
  const authorized = getAuthStatus(state);
  const userIsLoading = requestInProcess(state, 'USER');

  const remainingTime = getRemainingTime();

  return {
    isAvailable: authorized || userIsLoading || remainingTime > 0,
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    null,
  ),
  pure,
);

PrivateRoute.propTypes = propTypes;
PrivateRoute.defaultProps = defaultProps;

export default enhance(PrivateRoute);
