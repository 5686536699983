import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MaskedInput from 'react-text-mask';

import { getMask, getPlaceholder } from 'utils';

import styles from '../controlStyles';

const propTypes = {
  mask: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.func, PropTypes.string]),
  guide: PropTypes.bool,
  placeholder: PropTypes.string,
  rate: PropTypes.number,
  type: PropTypes.string,
  typeOfMask: PropTypes.oneOf(['phone', 'currency', 'decimalCurrency', 'percent', 'number', 'date', 'ssn', 'cvv']),
};

const defaultProps = {
  guide: true,
  mask: undefined,
  placeholder: undefined,
  rate: 40,
  type: 'text',
  typeOfMask: undefined,
};

function Input({ mask, typeOfMask, guide, ...props }) {
  const resultMask = getMask(mask, typeOfMask);
  const placeholder = getPlaceholder(props.placeholder, typeOfMask);

  const maskedProps = {
    guide,
    mask: resultMask,
    ...props,
    placeholder,
  };

  if (resultMask) {
    return <MaskedInput {...maskedProps} render={(ref, p) => <StyledInput innerRef={ref} {...p} />} />;
  }
  return <StyledInput {...props} />;
}

const StyledInput = styled.input`
  ${styles};
`;

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
