import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Downshift from 'downshift';

import { BRIDGE_PATH, RENTAL_PATH } from 'constants/routes';
import { MenuContainer as Menu, MenuItem } from 'components/shared/menu';
import { DropdownButton } from 'components/blocks';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: null,
};

function ApplicationAddNewDropdown({ className }) {
  return (
    <Container className={className}>
      <Downshift>
        {({ closeMenu, isOpen, openMenu }) => {
          const handleClick = isOpen ? closeMenu : openMenu;
          return (
            <div>
              <Container>
                <DropdownButton xs rounded isOpen={isOpen} onClick={handleClick}>
                  New Application
                </DropdownButton>
                {isOpen ? (
                  <Menu right>
                    <MenuItem url={BRIDGE_PATH}>Submit New Fix/Flip Bridge Loan</MenuItem>
                    <MenuItem url={RENTAL_PATH}>Submit New Rental Loan</MenuItem>
                  </Menu>
                ) : null}
              </Container>
            </div>
          );
        }}
      </Downshift>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

ApplicationAddNewDropdown.propTypes = propTypes;
ApplicationAddNewDropdown.defaultProps = defaultProps;

export default ApplicationAddNewDropdown;
