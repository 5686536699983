import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { is, map, toPairs } from 'ramda';

import Strong from 'components/shared/Strong';

const propTypes = {
  errors: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object])),
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object])),
  ]),
};

const defaultProps = {
  errors: null,
};

function SubmitErrors({ errors }) {
  if (is(String, errors)) return <span>{errors}</span>;
  if (is(Object, errors)) {
    return (
      <List>
        {map(
          ([key, error]) => (
            <Item key={key}>
              <StyledStrong>{key}:&nbsp;</StyledStrong>
              <SubmitErrors errors={error} />
            </Item>
          ),
          toPairs(errors),
        )}
      </List>
    );
  }
  return null;
}

const List = styled.ul`
  & & {
    padding-left: 1rem;
  }
`;

const Item = styled.li`
  display: flex;
  margin-bottom: 0.5rem;
`;

const StyledStrong = styled(Strong)`
  white-space: nowrap;
`;

SubmitErrors.propTypes = propTypes;
SubmitErrors.defaultProps = defaultProps;

export default SubmitErrors;
