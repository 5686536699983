import React, { Component } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  className: PropTypes.string,
  onClick: PropTypes.func,
};

const defaultProps = {
  className: '',
  onClick: () => {
    return false;
  },
};

class SimpleCell extends Component {
  constructor(props) {
    super(props);

    this.handleCellClick = this.handleCellClick.bind(this);
  }

  handleCellClick() {
    const { onClick } = this.props;
    if (onClick) {
      onClick();
    }
  }

  render() {
    const { value, className } = this.props;
    return (
      <div className={`reschedule_table__cell ${className}`} onClick={this.handleCellClick}>
        {value || 'N/A'}
      </div>
    );
  }
}

SimpleCell.propTypes = propTypes;
SimpleCell.defaultProps = defaultProps;

export default SimpleCell;
