import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'react-final-form';

import { Button } from 'components/shared';
import { FormGroupAdapter, FormFlex, FormBox, FormLegend } from 'components/shared/form';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

function Form({ handleSubmit, submitting }) {
  return (
    <form>
      <Container>
        <FormFlex>
          <FormBox width={1}>
            <StyledLegend>Provide your explanation here</StyledLegend>
          </FormBox>
        </FormFlex>

        <FormFlex>
          <FormBox width={1}>
            <Field name="document.explanation" label="Explanation" type="textarea" component={FormGroupAdapter} />
          </FormBox>
        </FormFlex>

        <FormFlex>
          <FormBox width={1}>
            <Button onClick={handleSubmit} dataName="saveButton" rounded disabled={submitting}>
              {submitting ? 'Please wait…' : 'Save'}
            </Button>
          </FormBox>
        </FormFlex>
      </Container>
    </form>
  );
}

const Container = styled.div`
  padding: 1rem;
`;

const StyledLegend = styled(FormLegend)`
  margin-bottom: 1rem;
`;

Form.propTypes = propTypes;

export default Form;
