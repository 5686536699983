import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { defaultProps } from 'recompose';
import { Box } from 'grid-styled';
import theme from 'components/themes/default';

const propTypes = {
  alignSelf: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

const { breakpoints } = theme;

const alignSelfStyle = ({ alignSelf: align }) => {
  if (!align) return;
  if (typeof align === 'string' || align instanceof String) {
    return css`
      align-self: ${align};
    `;
  }

  const mediaCss = align.reduce((sum, current, index) => {
    if (align[index] && breakpoints[index - 1]) {
      return `${sum} @media (min-width: ${breakpoints[index - 1]}) {align-self: ${current};}`;
    }
    return sum;
  }, `align-self: ${align[0]};`);

  return css`
    ${mediaCss};
  `;
};

function FormBox({ children, ...props }) {
  return <StyledFormBox {...props}>{children}</StyledFormBox>;
}

const ExtendedBox = defaultProps({ pl: 2, pr: 2 })(Box);

const StyledFormBox = styled(ExtendedBox)`
  ${alignSelfStyle};
`;

FormBox.propTypes = propTypes;
export default FormBox;
