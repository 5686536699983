import * as R from 'ramda';

function getOptimizedResize() {
  let callbacks = [];

  let running = false;

  function resize() {
    if (!running) {
      running = true;

      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(runCallbacks);
      } else {
        setTimeout(runCallbacks, 66);
      }
    }
  }

  function runCallbacks() {
    R.forEach((callback) => callback(), callbacks);
    running = false;
  }

  function addCallback(callback) {
    if (callback) {
      callbacks = R.append(callback, callbacks);
    }
  }

  function removeCallback(callback) {
    if (callback) {
      callbacks = R.filter(
        R.pipe(
          R.equals(callback),
          R.not,
        ),
        callbacks,
      );
    }
  }

  function clearCallbacks() {
    callbacks = [];
  }

  return {
    add(callback) {
      if (!callbacks.length) {
        window.addEventListener('resize', resize);
      }
      addCallback(callback);
    },
    remove(callback) {
      removeCallback(callback);
    },
    clear() {
      window.removeEventListener('resize', resize);
      clearCallbacks();
    },
  };
}

function copyTextToClipboard(text, onCopy = R.identity, onError = R.identity) {
  function fallbackCopyTextToClipboard(localText) {
    const textArea = document.createElement('textarea');
    textArea.value = localText;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      onCopy(successful);
    } catch (err) {
      onError(err);
    }

    document.body.removeChild(textArea);
  }

  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }

  navigator.clipboard.writeText(text).then(onCopy, onError);
}

export { getOptimizedResize, copyTextToClipboard };
