import React from 'react';
import PropTypes from 'prop-types';
import media from 'styled-media-query';
import styled from 'styled-components';

import { Button } from 'components/shared';

const StyledLink = styled.a`
  text-decoration: none;
  font-weight: 300;
  font-size: 1.9375rem;
  color: #000000;
  letter-spacing: 0;
  line-height: 1.9375rem;
`;

const Delimiter = styled.div`
  margin: 0 20px;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 1.375rem;
  color: #d7d7d7;
  letter-spacing: 0;
  ${media.lessThan('medium')`
    margin: 20px 0;
  `};
`;

const MakeCallContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.lessThan('medium')`
    flex-direction: column;
  `};
`;

const MakeCall = ({ phoneUrl, buttonUrl, phoneText, buttonText }) => (
  <MakeCallContainer>
    <div>
      <Button href={buttonUrl} rounded target="_blank">
        {buttonText}
      </Button>
    </div>
    <div>
      <Delimiter>Or</Delimiter>
    </div>
    <div>
      <StyledLink href={phoneUrl}>
        {phoneText}
      </StyledLink>
    </div>
  </MakeCallContainer>
);

MakeCall.propTypes = {
  phoneUrl: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string.isRequired,
  phoneText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default MakeCall;
