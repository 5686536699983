import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router';

import { getApplicationEntryStep, requestInProcess } from 'selectors';

import { Spinner } from 'components/shared';

import * as requestTypes from 'constants/requestTypes';
import { APPLICATION_STEP_PATH } from 'constants/routes';
import { getPath } from 'utils';

import {
  CompanyInformation,
  InvestmentExperience,
  ProjectOverview,
  PersonalFinancials,
  Signature,
} from './steps';

const propTypes = {
  applicationEntryStep: PropTypes.number,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
};

const defaultProps = {
  applicationEntryStep: undefined,
};

function Rental({ applicationEntryStep, match, loading }) {
  const { token } = match.params;
  if (!applicationEntryStep) return <Spinner />;
  const step = parseInt(match.params.step, 10);

  useEffect(() => {
    window.scroll(0, 0);
  }, [step]);

  if (step === 1 && step <= applicationEntryStep) return <InvestmentExperience />;
  if (step === 2 && step <= applicationEntryStep) return <ProjectOverview />;
  if (step === 3 && step <= applicationEntryStep) return <PersonalFinancials />;
  if (step === 4 && step <= applicationEntryStep) return <CompanyInformation />;
  if (step === 5 && step <= applicationEntryStep) return <Signature />;
  return <Redirect to={getPath(APPLICATION_STEP_PATH, { token, step: applicationEntryStep })} />;
}

Rental.propTypes = propTypes;
Rental.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  const loading = requestInProcess(state, requestTypes.APPLICATION);
  const applicationEntryStep = getApplicationEntryStep(state);
  return {
    loading,
    applicationEntryStep,
  };
};

const enhance = compose(withRouter, connect(mapStateToProps), pure);

export default enhance(Rental);
