import { createSelector } from 'reselect';

export const initialState = [];

const drawRequestsSelector = (state = initialState) => state.drawRequests;

const propertyIdSelector = (state, id) => id;

export const getDrawRequests = createSelector(
  drawRequestsSelector,
  (draws) => draws,
);

export const getIsDrawRequestsExist = createSelector(
  getDrawRequests,
  (draws) => draws && draws.length > 0,
);

export const getDrawRequestsByPropertyId = createSelector(
  [getDrawRequests, propertyIdSelector],
  (draws, id) => draws.filter((draw) => draw.propertyId === id),
);
