import { createSelector } from 'reselect';
import * as R from 'ramda';

import {
  applicationRentalParamsGetter,
  applicationBridgeParamsGetter,
  applicationBaseParamsGetter,
} from 'utils/searchParams';

export const initialState = {};

const searchParamsSelector = (state) => state.searchParams || initialState;

export const getSearchParams = createSelector(
  searchParamsSelector,
  R.identity,
);

export const getIsInIframe = createSelector(
  getSearchParams,
  R.pipe(
    R.prop('iframe'),
    R.equals('true'),
  ),
);

export const getReferralToken = createSelector(
  getSearchParams,
  R.prop('referralToken'),
);

export const getAuthenticationToken = createSelector(
  getSearchParams,
  R.prop('authenticationToken'),
);

export const getNavigateToSearchParams = createSelector(
  getSearchParams,
  R.prop('navigateTo'),
);

export const getOridinalUserId = createSelector(
  getSearchParams,
  R.prop('originalUserId'),
);

export const getUtmSource = createSelector(
  getSearchParams,
  R.prop('utmSource'),
);

export const getApplicationBaseParams = createSelector(
  getReferralToken,
  applicationBaseParamsGetter,
);

export const getApplicationRentalParams = createSelector(
  getSearchParams,
  applicationRentalParamsGetter,
);

export const getApplicationBridgeParams = createSelector(
  getSearchParams,
  applicationBridgeParamsGetter,
);
