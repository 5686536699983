import React from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

import { ANIMATE_DURATION } from '../../constants';
import Preview from './';

const propTypes = {
  isOpen: PropTypes.bool,
  canAnimateHeight: PropTypes.bool,
};

const defaultProps = {
  isOpen: false,
  canAnimateHeight: false,
};

function AnimatePreview({ isOpen, canAnimateHeight, ...props }) {
  if (canAnimateHeight) {
    return (
      <AnimateHeight duplicate={ANIMATE_DURATION} height={isOpen ? 0 : 'auto'}>
        <Preview {...props} />
      </AnimateHeight>
    );
  }

  return (
    <div style={{ display: isOpen ? 'none' : 'block' }}>
      <Preview {...props} />
    </div>
  );
}

AnimatePreview.propTypes = propTypes;
AnimatePreview.defaultProps = defaultProps;

export default AnimatePreview;
