import * as R from 'ramda';

import { getIn } from 'utils';

function isFormDisabledSetterHandler({ setIsFormDisabled }) {
  const setter = R.pipe(
    getIn('meta.isDisabled'),
    R.unless(R.isNil, setIsFormDisabled),
  );

  return R.tap(setter);
}

function mergeInitialValuesHandler({ isFormDisabledSetter, setInitialValues }) {
  return R.pipe(
    isFormDisabledSetter,
    setInitialValues,
  );
}

export { mergeInitialValuesHandler as mergeInitialValues, isFormDisabledSetterHandler as isFormDisabledSetter };
