import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const propTypes = {
  children: PropTypes.node.isRequired,
};

function Item({ children }) {
  return <StyledItem>{children}</StyledItem>;
}
const StyledItem = styled.li`
  display: block;
  position: relative;
  & + li {
    padding-left: 30px;
    &:before {
      content: '';
      position: absolute;
      left: 15px;
      top: 50%;
      margin-top: -8px;
      border-left: 1px solid #d7d7d7;
      width: 1px;
      height: 16px;
    }
  }
`;

Item.propTypes = propTypes;

export default Item;
