import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { fetchApplicationAuthToken } from 'actions';
import AccessDenied from 'components/ApplicationAuth/shared/AccessDenied';
import { INDEX_PATH } from 'constants/routes';
import { getAuthenticationToken, getOridinalUserId } from 'selectors';

export default function LoginAsAuth() {
  const dispatch = useDispatch();
  const [authorized, setAuthorized] = useState(null);

  const authenticationToken = useSelector(getAuthenticationToken);
  const originalUserId = useSelector(getOridinalUserId);

  const initialize = async () => { 
    try {
      await dispatch(fetchApplicationAuthToken(authenticationToken, originalUserId));
      setAuthorized('Success');
    } catch (e) {
      setAuthorized('Reject');
    }
  };

  useEffect(() => { initialize(); }, []);

  if (authorized === 'Success') {
    return <Redirect to={INDEX_PATH} />;
  }

  if (authorized === 'Reject') {
    return <AccessDenied />;
  }

  return null;
}