import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { palette } from 'styled-tools';

import { sendConfirmEmail } from 'actions/session';
import { getEmailConfirmed } from 'selectors/session';
import { successNotification, errorNotification } from 'utils';
import { getConfirmEmailBanner, setConfirmEmailBanner } from 'utils/auth';
import * as fp from 'utils/ramda';

import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';

function Banner() {
  const dispatch = useDispatch();
  const [showBanner, setShowBanner] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const emailConfirmed = useSelector(getEmailConfirmed);

  const onClose = () => {
    setConfirmEmailBanner(true);
    setShowBanner(false);
  };

  const onSendEmail = async () => {
    try {
      setIsLoading(true);
      await dispatch(sendConfirmEmail());
      successNotification('The letter has been successfully sent to your email.');
      onClose();
      setIsLoading(false);
    } catch {
      errorNotification('Something went wrong!');
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    function effect() {
      if (fp.isNotDefined(emailConfirmed)) {
        setShowBanner(false);
        return;
      }

      if (emailConfirmed) return;
      if (getConfirmEmailBanner()) return;
      setShowBanner(true);
    }

    effect();
  }, [emailConfirmed]);

  if (showBanner) {
    return (
      <Container>
        <Close type="button" onClick={onClose}>
          <Icon width={0.7} palette="white" icon="cross" />
        </Close>
        Please confirm email
        <StyledButton disabled={isLoading} palette="white" xs onClick={onSendEmail}>
          <span style={{ color: '#000' }}>
            {isLoading ? 'Please wait...' : 'Send email' }
          </span>
        </StyledButton>
      </Container>
    );
  }

  return null;
}

const Container = styled.section`
  position: relative;
  background: ${palette('info')};
  padding: 5px 15px;
  text-align: center;
  color: ${palette('white')}
`;

const Close = styled.button`
  position: absolute;
  paddin: 0;
  margin: 0;
  background: none;
  outline: none;
  border: none;
  right: 10px;
  top: 8px;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
  color: ${palette('grayscale')}
  &:hover {
    color: ${palette('grayscale')}
  }
`;

export default Banner;
