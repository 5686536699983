import * as actionTypes from 'constants/actionTypes';

import { initialState } from 'selectors/documentCategories';

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.DOCUMENT_CATEGORIES_FETCH_SUCCESS:
      return [...state, ...payload.collection];
    case actionTypes.DOWNLOAD_DOCUMENT_CATEGORIES_FETCH_SUCCESS:
      return [...state, ...payload.borrowerDownloadCategories];
    case actionTypes.DOCUMENT_CATEGORIES_RESET:
      return initialState;
    default:
      return state;
  }
};
