import React from 'react';
import styled from 'styled-components';

function Header({ children, ...props }) {
  return <StyledHeader {...props}>{children}</StyledHeader>;
}

const StyledHeader = styled.div`
  padding: 30px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #d5dde9;
`;

export default Header;
