import React from 'react';
import styled from 'styled-components';

import { Wrapper } from 'components/shared';
import { APP_NAME } from 'constants/views';

function Asterisks() {
  return (
    <Container>
      <Wrapper padded>
        <Text>
          {APP_NAME}, LLC serves as the originating entity for all loans and is licensed under NMLS ID # 1508627,
          Arizona Mortgage Banker License # BK-0944181, California Finance Lender License # 60DBO-58915, Minnesota
          Residential Mortgage Originator License # MN-MO-1508627, Oregon Mortgage Lenders License # 5529 and Vermont
          Commercial Lender License # 1508627 CLL. {APP_NAME}, LLC currently does not lend in the following states:
          Alaska, Nevada, North Dakota, South Dakota and Utah. {APP_NAME}, LLC is licensed or exempt from licensing in
          all other states. Loans only apply to residential, non-owner occupied properties. Rates, terms and conditions
          offered only to qualified borrowers, may vary upon loan product, deal structure, property state or other
          applicable considerations, and are subject to change at any time without notice, shall only constitute a
          general, non-binding expression of interest on the part of {APP_NAME}, LLC, do not create any legally binding
          commitment or obligation on the part of {APP_NAME}, LLC, and are expressly subject to {APP_NAME}, LLC’s
          credit, legal and investment approval process. Closing times are in business days and commence upon receipt of
          appraisal payment and satisfaction of borrower conditions. Closing times may be delayed due to appraiser
          property access limitations or availability in certain markets. Rates are as of 01/15/2019.
        </Text>
      </Wrapper>
    </Container>
  );
}

const Container = styled.section`
  width: 100%;
  border-top: 1px solid #d5dde9;
`;

const Text = styled.span`
  display: block;
  font-size: 13px;
`;

export default Asterisks;
