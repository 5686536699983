import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose, pure, withHandlers } from 'recompose';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import * as actions from 'actions';
import { requestInProcess } from 'selectors';

import * as requestTypes from 'constants/requestTypes';

import { Link } from 'components/shared';

const propTypes = {
  applicationIsLoading: PropTypes.bool.isRequired,
  fetchApplication: PropTypes.func.isRequired,
  title: PropTypes.string,
};

const defaultProps = {
  title: 'Appraisal Fee was successfully paid.',
};

function Success({ applicationIsLoading, fetchApplication, title }) {
  return (
    <Container>
      <Title>{title}</Title>
      <Desc disabled={applicationIsLoading} onClick={fetchApplication}>
        {applicationIsLoading ? 'Please wait…' : 'continue...'}
      </Desc>
    </Container>
  );
}

const Container = styled.div`
  box-sizing: border-box;
  margin: 0.625rem auto;
  padding: 5rem 3.75rem;
  max-width: 46rem;
  text-align: center;
  border-radius: 0.375rem;
  border: 0.125rem dashed #dadbdb;
`;

const Title = styled.h1`
  margin: 0 0 2.5rem;
  font-size: 1.5625rem;
  font-weight: 600;
  line-height: 1.1;
`;

const Desc = styled(Link).attrs({
  palette: 'primary',
})`
  display: block;
  text-align: center;
  text-transform: uppercase;
  padding: 0.625rem 2rem;
  font-size: 1.125rem;
  line-height: 1.33;
  border-radius: 0.375rem;
`;

Success.propTypes = propTypes;
Success.defaultProps = defaultProps;

function mapStateToProps(state) {
  const applicationIsLoading = requestInProcess(state, requestTypes.APPLICATION);

  return {
    applicationIsLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchApplicationAction: bindActionCreators(actions.fetchApplication, dispatch),
  };
}

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withHandlers({
    fetchApplication: ({ fetchApplicationAction, match }) => () => {
      const { token } = match.params;
      fetchApplicationAction(token);
    },
  }),
  pure,
);

export default enhance(Success);
