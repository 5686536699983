import { percentFormat } from 'utils';

function validateRange(minArvLtv, maxArvLtv) {
  return (value) => {
    const parsedValue = parseFloat(value, 10);
    const formatedMin = percentFormat(minArvLtv);
    const formatedMax = percentFormat(maxArvLtv);
    const parsedMin = parseFloat(formatedMin);
    const parsedMax = parseFloat(formatedMax);
    const message = `The value must be between ${formatedMin} and ${formatedMax}`;
    if (parsedValue < parsedMin || parsedValue > parsedMax) return message;
    return undefined;
  };
}

export default validateRange;
