import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { equals } from 'ramda';

import { Link } from 'components/shared';

const propTypes = {
  file: PropTypes.objectOf(PropTypes.any).isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: undefined,
};

function File({ className, file }) {
  return (
    <Container className={className}>
      {file.asset && (
        <div>
          <StyledLink underline href={file.asset.url} download target="_blank">
            {file.asset.filename}
          </StyledLink>
          {'  '}
          {file.contact && (
            <span>
              uploaded by <span>{file.contact.displayName}</span>
              {' • '}
            </span>
          )}
          <span>{file.displayCreatedAtWithTime}</span>
          {file.notes && <Addition>Notes: {file.notes}</Addition>}
          {equals('unacceptable', file.reviewStatus) && file.reviewComment && (
            <Addition>Comments: {file.reviewComment}</Addition>
          )}
        </div>
      )}
      {!file.asset && <span>{file.explanation}</span>}
    </Container>
  );
}

const Container = styled.div``;

const Addition = styled.div`
  font-size: 0.85rem;
`;

const StyledLink = styled(Link)`
  color: inherit;
`;

File.propTypes = propTypes;
File.defaultProps = defaultProps;

export default File;
