import React from 'react';
import styled from 'styled-components';
import { compose, pure } from 'recompose';
import { withRouter, Switch, Route } from 'react-router-dom';

import { Wrapper } from 'components/shared';
import { UserHeader } from 'components/blocks/User';
import { UserTemplate } from 'components/templates';
import PrivateRoute from 'components/PrivateRoute';

import { ScrollToTopOnMount } from 'hocs';
import { APPLICATION_NEW_DRAW_REQUESTS_PATH, APPLICATION_EDIT_DRAW_REQUESTS_PATH } from 'constants/routes';

import { ApplicationHeader, Title } from '../shared';
import DrawRequestApplication from './DrawRequestApplication';
import New from './New';
import Edit from './Edit';

function DrawRequestsPage() {
  return (
    <UserTemplate header={<UserHeader />}>
      <ApplicationHeader />
      <StyledWrapper>
        <Title>Draw Request</Title>
        <Switch>
          <PrivateRoute exact path={APPLICATION_NEW_DRAW_REQUESTS_PATH} component={New} />
          <PrivateRoute exact path={APPLICATION_EDIT_DRAW_REQUESTS_PATH} component={Edit} />
          <Route component={DrawRequestApplication} />
        </Switch>
      </StyledWrapper>
    </UserTemplate>
  );
}

const StyledWrapper = styled(Wrapper)`
  width: 100%;
  max-width: 75rem;
`;

const enhance = compose(
  ScrollToTopOnMount,
  withRouter,
  pure,
);

export default enhance(DrawRequestsPage);
