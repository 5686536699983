import React from 'react';
import PropTypes from 'prop-types';
import { flatten } from 'ramda';
import styled from 'styled-components';

import { DocumentsTree } from 'components/shared';
import Icon from 'components/shared/Icon';

import CategoryTreeNode from './CategoryTreeNode';
import DownLoadTreeNode from './DownLoadTreeNode';

const propTypes = {
  applicationAnotherDocumentsArray: PropTypes.arrayOf(PropTypes.object),
  applicationTermSheetData: PropTypes.arrayOf(PropTypes.object),
  availableDownloadLoanDocumentCategoriesWithDocuments: PropTypes.arrayOf(PropTypes.object),
  availableDownloadContactProfileDocumentCategoriesWithDocuments: PropTypes.arrayOf(PropTypes.object),
  contactProfileDocumentCategories: PropTypes.arrayOf(PropTypes.object),
  defaultCheckedKeys: PropTypes.arrayOf(PropTypes.string),
  defaultDocumentsTreeStatuses: PropTypes.objectOf(PropTypes.string),
  entityDocumentCategories: PropTypes.arrayOf(PropTypes.object),
  entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hasAvailableDownloadDocumentCategories: PropTypes.bool,
  hasRequestedDocumentCategories: PropTypes.bool,
  hasMissingDocumentCategories: PropTypes.bool,
  isEmpty: PropTypes.bool,
  keys: PropTypes.arrayOf(PropTypes.string),
  loanDocumentCategories: PropTypes.arrayOf(PropTypes.object),
  loanId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  profileId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  properties: PropTypes.arrayOf(PropTypes.object),
  propertyDocumentCategories: PropTypes.arrayOf(PropTypes.object),
  requestedContactProfileDocumentCategories: PropTypes.arrayOf(PropTypes.object),
  requestedEntityDocumentCategories: PropTypes.arrayOf(PropTypes.object),
  requestedLoanDocumentCategories: PropTypes.arrayOf(PropTypes.object),
  requestedPropertyDocumentCategories: PropTypes.arrayOf(PropTypes.object),
  missingContactProfileDocumentCategories: PropTypes.arrayOf(PropTypes.object),
  missingEntityDocumentCategories: PropTypes.arrayOf(PropTypes.object),
  missingLoanDocumentCategories: PropTypes.arrayOf(PropTypes.object),
  missingPropertyDocumentCategories: PropTypes.arrayOf(PropTypes.object),
  withContactProfileDocuments: PropTypes.bool,
  withEntityDocuments: PropTypes.bool,
  withLoanDocuments: PropTypes.bool,
  withPropertyDocuments: PropTypes.bool,
  withRequestedDocuments: PropTypes.bool,
  withDownLoadDocuments: PropTypes.bool,
  withMissingDocuments: PropTypes.bool,
  withAnotherDocuments: PropTypes.bool,
};

const defaultProps = {
  applicationAnotherDocumentsArray: [],
  applicationTermSheetData: [],
  availableDownloadLoanDocumentCategoriesWithDocuments: [],
  availableDownloadContactProfileDocumentCategoriesWithDocuments: [],
  contactProfileDocumentCategories: [],
  defaultCheckedKeys: [],
  entityDocumentCategories: [],
  defaultDocumentsTreeStatuses: {},
  entityId: undefined,
  hasAvailableDownloadDocumentCategories: false,
  hasRequestedDocumentCategories: false,
  hasMissingDocumentCategories: false,
  isEmpty: true,
  keys: [],
  loanDocumentCategories: [],
  loanId: undefined,
  profileId: undefined,
  properties: [],
  propertyDocumentCategories: [],
  requestedContactProfileDocumentCategories: [],
  requestedEntityDocumentCategories: [],
  requestedLoanDocumentCategories: [],
  requestedPropertyDocumentCategories: [],
  missingContactProfileDocumentCategories: [],
  missingEntityDocumentCategories: [],
  missingLoanDocumentCategories: [],
  missingPropertyDocumentCategories: [],
  withContactProfileDocuments: false,
  withEntityDocuments: true,
  withLoanDocuments: true,
  withPropertyDocuments: true,
  withRequestedDocuments: true,
  withDownLoadDocuments: true,
  withMissingDocuments: false,
  withAnotherDocuments: false,
};

function ApplicationDocumentsTree({
  applicationAnotherDocumentsArray,
  applicationTermSheetData,
  availableDownloadLoanDocumentCategoriesWithDocuments,
  availableDownloadContactProfileDocumentCategoriesWithDocuments,
  contactProfileDocumentCategories,
  defaultCheckedKeys,
  defaultDocumentsTreeStatuses,
  entityDocumentCategories,
  entityId,
  hasAvailableDownloadDocumentCategories,
  hasRequestedDocumentCategories,
  hasMissingDocumentCategories,
  isEmpty,
  keys,
  loanDocumentCategories,
  loanId,
  profileId,
  properties,
  propertyDocumentCategories,
  requestedContactProfileDocumentCategories,
  requestedEntityDocumentCategories,
  requestedLoanDocumentCategories,
  requestedPropertyDocumentCategories,
  missingContactProfileDocumentCategories,
  missingEntityDocumentCategories,
  missingLoanDocumentCategories,
  missingPropertyDocumentCategories,
  withContactProfileDocuments,
  withEntityDocuments,
  withLoanDocuments,
  withPropertyDocuments,
  withRequestedDocuments,
  withDownLoadDocuments,
  withMissingDocuments,
  withAnotherDocuments,
}) {
  if (isEmpty) {
    return <span>No documents</span>;
  }

  return (
    <DocumentsTree
      keys={keys}
      defaultStatuses={defaultDocumentsTreeStatuses}
      defaultCheckedKeys={defaultCheckedKeys}
      size="sm"
    >
      {withAnotherDocuments && applicationAnotherDocumentsArray && applicationAnotherDocumentsArray.length && (
        <DocumentsTree.Node 
          expanded 
          nodeKey="anotherdoc" 
          title="Prequalification Letter & Term Sheets"
          isAnotherDoc 
          status={applicationAnotherDocumentsArray.every((docData) => docData.downloaded) ? 'success' : 'pending' }
          >
            {applicationAnotherDocumentsArray.map((docData) => (
              <DownLoadTreeNode 
                docData={docData}
                isAnotherDoc
                nodeKey="anotherdoc"
                status={docData.downloaded ? 'success' : 'pending'}
                key={`anotherdoc-${docData.id}`}
              />
            ))}
        </DocumentsTree.Node>
      )}
      {withRequestedDocuments && hasRequestedDocumentCategories && (
        <DocumentsTree.Node expanded nodeKey="requested" withCounter title="Requested Documents">
          {flatten([
            requestedContactProfileDocumentCategories.map((category) => (
              <CategoryTreeNode
                nodeKey={`requested-borrower-${profileId}-${category.id}`}
                key={`requested-borrower-${profileId}-${category.id}`}
                targetId={profileId}
                categoryId={category.id}
              />
            )),
            requestedEntityDocumentCategories.map((category) => (
              <CategoryTreeNode
                nodeKey={`requested-entity-${entityId}-${category.id}`}
                key={`requested-entity-${entityId}-${category.id}`}
                targetId={entityId}
                categoryId={category.id}
              />
            )),
            requestedLoanDocumentCategories.map((category) => (
              <CategoryTreeNode
                nodeKey={`requested-loan-${loanId}-${category.id}`}
                key={`requested-loan-${loanId}-${category.id}`}
                targetId={loanId}
                categoryId={category.id}
              />
            )),
            requestedPropertyDocumentCategories.length > 0 &&
              properties.map((property) => {
                if (property.requestedDocumentCategories && property.requestedDocumentCategories.length > 0) {
                  return (
                    <DocumentsTree.Node
                      withCounter
                      expanded
                      nodeKey={`requested-property-${property.id}`}
                      key={`requested-property-${property.id}`}
                      title={`Property Documents for ${property.address}`}
                    >
                      {property.requestedDocumentCategories.map((category) => (
                        <CategoryTreeNode
                          nodeKey={`requested-property-${property.id}-${category}`}
                          key={`requested-property-${property.id}-${category}`}
                          targetId={property.id}
                          categoryId={category}
                        />
                      ))}
                    </DocumentsTree.Node>
                  );
                }
                return null;
              }),
          ])}
        </DocumentsTree.Node>
      )}
      {withMissingDocuments && hasMissingDocumentCategories && (
        <DocumentsTree.Node expanded nodeKey="missing" withCounter title="Missing Documents">
          {flatten([
            missingContactProfileDocumentCategories.map((category) => (
              <CategoryTreeNode
                nodeKey={`missing-borrower-${profileId}-${category.id}`}
                key={`missing-borrower-${profileId}-${category.id}`}
                targetId={profileId}
                categoryId={category.id}
              />
            )),
            missingEntityDocumentCategories.map((category) => (
              <CategoryTreeNode
                nodeKey={`missing-entity-${entityId}-${category.id}`}
                key={`missing-entity-${entityId}-${category.id}`}
                targetId={entityId}
                categoryId={category.id}
              />
            )),
            missingLoanDocumentCategories.map((category) => (
              <CategoryTreeNode
                nodeKey={`missing-loan-${loanId}-${category.id}`}
                key={`missing-loan-${loanId}-${category.id}`}
                targetId={entityId}
                categoryId={category.id}
              />
            )),
            missingPropertyDocumentCategories.length > 0 &&
              properties.map((property) => {
                if (property.missingDocumentCategories && property.missingDocumentCategories.length > 0) {
                  return (
                    <DocumentsTree.Node
                      withCounter
                      expanded
                      nodeKey={`missing-property-${property.id}`}
                      key={`missing-property-${property.id}`}
                      title={`Property Documents for ${property.address}`}
                    >
                      {property.missingDocumentCategories.map((category) => (
                        <CategoryTreeNode
                          nodeKey={`missing-property-${property.id}-${category}`}
                          key={`missing-property-${property.id}-${category}`}
                          targetId={property.id}
                          categoryId={category}
                        />
                      ))}
                    </DocumentsTree.Node>
                  );
                }
                return null;
              }),
          ])}
        </DocumentsTree.Node>
      )}
      {withDownLoadDocuments && (hasAvailableDownloadDocumentCategories || applicationTermSheetData) && (
        <DocumentsTree.Node expanded nodeKey="download" title="Download Documents">
          {flatten([
            availableDownloadContactProfileDocumentCategoriesWithDocuments.map((category) => (
              <DownLoadTreeNode
                nodeKey={`download-borrower-${profileId}-${category.id}`}
                key={`download-borrower-${profileId}-${category.id}`}
                renderIcon={() => <DownLoadIcon />}
                targetId={profileId}
                categoryId={category.id}
              />
            )),
            availableDownloadLoanDocumentCategoriesWithDocuments.map((category) => (
              <DownLoadTreeNode
                nodeKey={`download-loan-${loanId}-${category.id}`}
                key={`download-loan-${loanId}-${category.id}`}
                renderIcon={() => <DownLoadIcon />}
                targetId={loanId}
                categoryId={category.id}
              />
            )),
            applicationTermSheetData && applicationTermSheetData.id && (
              <DownLoadTreeNode
                nodeKey={`download-termsheet-${applicationTermSheetData.id}`}
                key={`download-termsheet-${applicationTermSheetData.id}`}
                renderIcon={() => <DownLoadIcon />}
                applicationTermSheetData={applicationTermSheetData}
                isTermSheetData
              />
            ),
          ])}
        </DocumentsTree.Node>
      )}
      {withContactProfileDocuments && (
        <DocumentsTree.Node expanded nodeKey="borrower" withCounter title="Borrower Documents">
          {contactProfileDocumentCategories.map((category) => (
            <CategoryTreeNode
              nodeKey={`borrower-${profileId}-${category.id}`}
              key={`borrower-${profileId}-${category.id}`}
              targetId={profileId}
              categoryId={category.id}
            />
          ))}
        </DocumentsTree.Node>
      )}
      {withEntityDocuments && (
        <DocumentsTree.Node expanded nodeKey="entity" withCounter title="Entity Documents">
          {entityDocumentCategories.map((category) => (
            <CategoryTreeNode
              nodeKey={`entity-${entityId}-${category.id}`}
              key={`entity-${entityId}-${category.id}`}
              targetId={entityId}
              categoryId={category.id}
            />
          ))}
        </DocumentsTree.Node>
      )}
      {withLoanDocuments && (
        <DocumentsTree.Node nodeKey="loan" withCounter title="Loan Documents">
          {loanDocumentCategories.map((category) => (
            <CategoryTreeNode
              nodeKey={`loan-${loanId}-${category.id}`}
              key={`loan-${loanId}-${category.id}`}
              targetId={loanId}
              categoryId={category.id}
            />
          ))}
        </DocumentsTree.Node>
      )}
      {withPropertyDocuments &&
        properties.length > 0 &&
        properties.map((property) => (
          <DocumentsTree.Node
            withCounter
            nodeKey={`property-${property.id}`}
            key={`property-${property.id}`}
            title={`Property Documents for ${property.address}`}
          >
            {propertyDocumentCategories.map((category) => (
              <CategoryTreeNode
                nodeKey={`property-${property.id}-${category.id}`}
                key={`property-${property.id}-${category.id}`}
                targetId={property.id}
                categoryId={category.id}
              />
            ))}
          </DocumentsTree.Node>
        ))}
    </DocumentsTree>
  );
}

const DownLoadIcon = styled((props) => <Icon width={1} icon="downloadIcon" {...props} />)`
  position: relative;
  display: block;
  background: #ffffff;
  margin: 0;
  font-size: 1.375rem;
  color: #9e9e9e;
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    background: #bdbdbd;
    height: 0.0625rem;
    top: 50%;
    left: -1.3125rem;
    width: 2rem;
  }
`;

ApplicationDocumentsTree.propTypes = propTypes;
ApplicationDocumentsTree.defaultProps = defaultProps;

export default ApplicationDocumentsTree;
