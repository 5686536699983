import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';
import * as R from 'ramda';

const propTypes = {
  properties: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    address: PropTypes.string,
  })),
};

const defaultProps = {
  properties: [],
};

function Title({
  properties,
}) {
  const list = R.take(5, properties);
  return (
    <StyledTitle>
      {list.map((property, idx) => (
        <div key={`application-property-address-${property.id}`}>
          {property.domesticAddress || property.address}
          {R.length(properties) > 0 && R.inc(idx) !== R.length(properties) && (
            <span>
              {idx === 4 ? ' …' : ','}
            </span>
          )}
        </div>
      ))}
    </StyledTitle>
  );
}

Title.propTypes = propTypes;
Title.defaultProps = defaultProps;

const StyledTitle = styled.h1`
  ${space}
  color: rgba(0, 0, 0, 0.85);

  & div:first-child {
    font-size: 1.75rem;
    font-weight: 600;
  }
`;

export default Title;
