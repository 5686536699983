import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useField } from 'react-final-form';
import * as R from 'ramda';

import useFormPropertyScroll from 'hooks/useFormPropertyScroll';

import Property from 'components/blocks/Property';
import { FormFlex, FormBox } from 'components/shared/form';

const propTypes = {
  activeName: PropTypes.string,
  canDelete: PropTypes.bool,
  canSwap: PropTypes.bool,
  children: PropTypes.node.isRequired,
  colorScheme: PropTypes.string,
  render: PropTypes.func,
  details: PropTypes.arrayOf(PropTypes.shape({})),
  name: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  toClose: PropTypes.func.isRequired,
  toOpen: PropTypes.func.isRequired,
  toToggle: PropTypes.func.isRequired,
};

const defaultProps = {
  activeName: null,
  canDelete: false,
  canSwap: false,
  colorScheme: undefined,
  render: undefined,
  details: [],
};

function FormProperty({
  activeName,
  canDelete,
  canSwap,
  children,
  colorScheme,
  render,
  details,
  name,
  onDelete,
  toClose,
  toOpen,
  toToggle,
}) {
  const isOpen = R.equals(name, activeName);

  const {
    el,
    toToggleProperty,
    toOpenProperty,
  } = useFormPropertyScroll({ name, toOpen, toToggle, isOpen });

  const propertyField = useField(name);
  const property = R.pathOr({}, ['input', 'value'], propertyField);
  const isHidden = R.prop('_hidden', property);
  const isInvalid = R.path(['meta', 'invalid'], propertyField);

  if (isHidden) return null;

  return (
    <div ref={el}>
      <FormFlex key={name}>
        <FormBox pr={0} pl={0} mb="1.25rem" width={1}>
          <StyledProperty
            canDelete={canDelete}
            canSwap={canSwap}
            colorScheme={colorScheme}
            details={details}
            isInvalid={isInvalid}
            isOpen={isOpen}
            onDelete={() => onDelete(name)}
            property={property}
            toClose={() => toClose(name)}
            toOpen={toOpenProperty}
            toToggle={toToggleProperty}
            render={render}
          >
            {children}
          </StyledProperty>
        </FormBox>
      </FormFlex>
    </div>
  );
}

const StyledProperty = styled(Property)`
  ${({ isOpen }) =>
    !isOpen &&
    css`
      margin-right: 0.625rem;
      margin-left: 0.625rem;
    `}
`;

FormProperty.propTypes = propTypes;
FormProperty.defaultProps = defaultProps;

export default FormProperty;
