import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ConfirmEmailBanner from 'components/ConfirmEmail/Banner';
import OriginalUserBanner from 'components/OriginalUserBanner';
import TemplateBanner from 'components/templates/shared/TemplateBanner';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  header: PropTypes.node.isRequired,
};

const defaultProps = {
  children: null,
};

const UserTemplate = ({ header, children }) => (
  <Container>
    <Content key="content">
      <TemplateBanner>
        <ConfirmEmailBanner />
        <OriginalUserBanner />
      </TemplateBanner>
      <Header key="header">{header}</Header>
      {children}
    </Content>
  </Container>
);

const Container = styled.section`
  display: flex;
  width: 100vw;
  min-height: 100vh;
`;

const Content = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
  padding-left: 13.75rem;
  width: 100%;
  &:first-child {
    padding-left: 0;
  }
`;

const Header = styled.header`
  margin-bottom: 0.625rem;
`;

UserTemplate.propTypes = propTypes;
UserTemplate.defaultProps = defaultProps;

export default UserTemplate;
