import { createSelector } from 'reselect';

export const initialState = {
  detail: null,
  collection: [],
};

function applicationTermSheetSelector(state) {
  return state.applicationTermSheet || initialState;
}

export const getApplicationTermSheetData = createSelector(
  applicationTermSheetSelector,
  (applicationTermSheet) => applicationTermSheet.detail,
);

// export const getApplicationsCollectionWithDrawRequestAlowed = createSelector(
//   getApplicationsCollection,
//   R.filter(getIn('meta.availableToRequestDraw')),
// );
