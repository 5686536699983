import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { FormBox, FormFlex, FormGroupAdapter, FormLegend } from 'components/shared/form';

import { SIGNATURE_INITIAL_VALUES } from 'constants/forms';
import { validateAccepted } from 'utils';

import { LegendFormFlex, StyledTextarea } from '.';

const propTypes = {
  disabled: PropTypes.bool,
  signatureVersion: PropTypes.string,
};

const defaultProps = {
  disabled: true,
  signatureVersion: 'v1',
};

function InvestmentPurpose({ disabled, signatureVersion }) {
  return (
    <Fragment>
      <LegendFormFlex justifyContent="space-between">
        <FormBox>
          <FormLegend>Investment Purpose</FormLegend>
        </FormBox>
      </LegendFormFlex>

      <FormFlex>
        <FormBox width={1}>
          <StyledTextarea rows={3} disabled value={SIGNATURE_INITIAL_VALUES[signatureVersion].investmentPurpose} />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.borrower.investmentPurpose"
            type="checkbox"
            label="Yes"
            inlineGroup
            labelOrder={1}
            errorOrder={2}
            required
            disabled={disabled}
            validate={validateAccepted()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>
    </Fragment>
  );
}

InvestmentPurpose.propTypes = propTypes;
InvestmentPurpose.defaultProps = defaultProps;

export default InvestmentPurpose;
