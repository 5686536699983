import React, { Fragment } from 'react';
import { Field } from 'react-final-form';

import {
  FormFlex,
  FormBox,
  FormGroupAdapter,
} from 'components/shared/form';

import { validateEmail, validatePhone } from 'utils';

function BorrowerAttorney() {
  return (
    <Fragment>
      <Field name="contact.targetId" render={() => null} />
      <FormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field name="contact.firstName" label="First Name" component={FormGroupAdapter} />
        </FormBox>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field name="contact.lastName" label="Last Name" component={FormGroupAdapter} />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field name="contact.title" label="Title" component={FormGroupAdapter} />
        </FormBox>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field name="contact.legalName" label="Company Name" component={FormGroupAdapter} />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 4]}>
          <Field
            name="contact.phone"
            label="Phone #"
            typeOfMask="phone"
            validate={validatePhone()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 4]}>
          <Field
            name="contact.phoneExtension"
            label="Extension#"
            typeOfMask="phoneExtension"
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="contact.email"
            label="Email"
            component={FormGroupAdapter}
            validate={validateEmail()}
          />
        </FormBox>
      </FormFlex>
    </Fragment>
  );
}

export default BorrowerAttorney;
