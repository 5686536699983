import * as R from 'ramda';

function setFilesValueHandler({ files, form }) {
  const { change } = form;
  return (prevFiles) => {
    if (R.equals(prevFiles, files)) return;
    change('application.entity.files', files);
  };
}

export { setFilesValueHandler as setFilesValue };
