import React from 'react';
import PropTypes from 'prop-types';

import { Star } from 'components/shared';

const propTypes = {
  stars: PropTypes.number.isRequired,
};

const defaultProps = {
  stars: 5,
};

function Rating({ stars, ...props }) {
  let i = 0;
  let starsArr = [];
  for (i; i < stars; i++) {
    starsArr.push(<Star key={i} />);
  }
  return starsArr;
}

Rating.propTypes = propTypes;
Rating.defaultProps = defaultProps;
export default Rating;
