import { SETTINGS_FETCH_SUCCESS } from 'constants/actionTypes';
import { initialState } from '../selectors/settings';

export default (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_FETCH_SUCCESS:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
