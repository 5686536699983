import React from 'react';
import { FormSpy } from 'react-final-form';

const WithFinalFormValuesHoc = (Component) => {
  function WithFinalFormValues(props) {
    return (
      <FormSpy subscription={{ values: true }} render={({ values }) => <Component values={values} {...props} />} />
    );
  }

  return WithFinalFormValues;
};

export default WithFinalFormValuesHoc;
