import React from 'react';

import { RENTAL_PATH } from 'constants/routes';

import {
  Product,
  ProductBody,
  ProductButton,
  ProductList,
  ProductThumb,
  ProductTitle,
} from '../styles';
import thumb from './thumb.jpg';

function RentalLoans() {
  const advantages = [
    '5/1 & 30-Year Fixed-Rates',
    'Up to 80% LTV Purchases & Refinances',
    'Up to 75% LTV Cash-Outs',
    'Refinances, Cash-Out & Purchases',
  ];

  return (
    <Product color="rental" to={RENTAL_PATH}>
      <ProductThumb src={thumb} />
      <ProductBody>
        <ProductTitle>Rental Loans</ProductTitle>
        <ProductList>{advantages}</ProductList>
        <ProductButton>Apply now</ProductButton>
      </ProductBody>
    </Product>
  );
}

export default RentalLoans;
