import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as actions from 'actions';
import { getApplicationToken, getDocumentCategoryById, requestInProcess } from 'selectors';
import * as requestTypes from 'constants/requestTypes'; 

import Button from 'components/shared/Button';

const propTypes = {
  isTermSheetData: PropTypes.bool,
  isAnotherDoc: PropTypes.bool,
  category: PropTypes.shape({ name: PropTypes.string }),
  docData: PropTypes.shape({ name: PropTypes.string, id: PropTypes.number }),
  token: PropTypes.string,
  downloadOrOpenDocument: PropTypes.func.isRequired,
  isDownloading: PropTypes.bool,
};

const defaultProps = {
  isTermSheetData: false,
  isAnotherDoc: false,
  category: undefined,
  docData: {},
  token: undefined,
  isDownloading: false,
};

const defineRequestType = (docData) => {
  if (docData && docData.name) {
    return requestTypesNames[docData.name] || requestTypes.DOWNLOAD_DOCUMENT_PDF;
  } else {
    return requestTypes.DOWNLOAD_DOCUMENT_PDF;
  }
}

const requestTypesNames = {
  'Prequalification Letter': requestTypes.PRE_QUALIFICATION_LETTER_PDF,
  'Quote Sheet': requestTypes.QUOTE,
  'Initial Term Sheet': requestTypes.INITIAL_TERM_SHEET,
  'Final Term Sheet': requestTypes.FINAL_TERM_SHEET,
};

function Title({ isTermSheetData, category, docData, isAnotherDoc, token, downloadOrOpenDocument, isDownloading }) {
  if (category) {
    return <h2>{category.name}</h2>;
  }
  if (isTermSheetData) {
    return <h2>Term Sheet</h2>;
  }
  if (isAnotherDoc && docData) {
    return (
      <Container>
        <h2>{docData.name}</h2>
        <Button onClick={() => downloadOrOpenDocument(token, docData, defineRequestType(docData))} disabled={isDownloading} xs>
          { isDownloading ? 'Downloading...' : 'Download' }
        </Button>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
`;

Title.propTypes = propTypes;
Title.defaultProps = defaultProps;

function mapStateToProps(state, { categoryId, docData }) {
  return {
    category: getDocumentCategoryById(state, categoryId),
    token: getApplicationToken(state),
    isDownloading: requestInProcess(state, defineRequestType(docData)),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createDocument: bindActionCreators(actions.createDocument, dispatch),
    downloadOrOpenDocument: bindActionCreators(actions.downloadOrOpenDocument, dispatch),
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  pure,
);

export default enhance(Title);
