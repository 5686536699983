import { createSelector } from 'reselect';
import * as R from 'ramda';

import { getIn } from 'utils';

import { getApplicationCurrentBorrowerContactId } from './applications';

const initialState = {};

export const getSession = (state = initialState) => state.session;

export const getUser = createSelector(
  getSession,
  (session) => getIn('user', session),
);

export const getUserContact = createSelector(
  getUser,
  (user) => getIn('contact', user),
);

export const getOriginalUserContact = createSelector(
  getUser,
  (user) => getIn('originalUserContact', user),
);

export const getOriginalUser = createSelector(
  getOriginalUserContact,
  (contact) => {
    if (contact) {
      return `${getIn('firstName', contact)} ${getIn('lastName', contact)}`
    } else {
      return null;
    }
  },
);

export const getUserContactId = createSelector(
  getUserContact,
  (contact) => getIn('id', contact),
);

export const getEmailConfirmed = createSelector(
  getUserContact,
  R.prop('emailConfirmed'),
);

export const getIsUserDealTeamOwner = createSelector(
  [getUserContactId, getApplicationCurrentBorrowerContactId],
  R.pipe(
    R.equals,
    R.not,
  ),
);

export const getAuthStatus = createSelector(
  getSession,
  (session) => getIn('authorized', session),
);

export const getUserId = createSelector(
  getUser,
  (user) => getIn('id', user),
);

