import React from 'react';
import PropTypes from 'prop-types';
import { isNil, isEmpty, is } from 'ramda';

import Button from 'components/shared/Button';
import Link from 'components/shared/Link';

const propTypes = {
  desc: PropTypes.string,
  open: PropTypes.func.isRequired,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.any)]),
};

const defaultProps = {
  desc: undefined,
  title: undefined,
  value: undefined,
};

function Template({ open, title, desc, value }) {
  if (isNil(value) || isEmpty(value)) {
    return (
      <React.Fragment>
        <div className="dropzone__container">
          <h3 className="dropzone__title">{title || 'Send us your file'}</h3>
          {desc && <p className="dropzone__text">{desc}</p>}

          <Button xs onClick={() => open()}>
            Choose File
          </Button>

          <p className="dropzone__hint">or just drag and drop on the area</p>
        </div>
      </React.Fragment>
    );
  }

  if (is(String, value)) {
    return (
      <Link onClick={() => open()} className="dropzone__preview">
        <img alt="dropzone__preview" src={value} width={200} />
      </Link>
    );
  }

  const PreviewImg = value.map((file) => {
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      return (
        <Link onClick={() => open()} className="dropzone__preview">
          <img alt="dropzone__preview" src={file.preview} width={200} />
        </Link>
      );
    }

    return (
      <p className="dropzone__file_name">
        {file.name}
        {'. ('}
        <Link onClick={() => open()}>change</Link>
        {')'}
      </p>
    );
  });

  return <React.Fragment>{PreviewImg}</React.Fragment>;
}

Template.propTypes = propTypes;
Template.defaultProps = defaultProps;

export default Template;
