import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { Hr } from 'components/shared';
import { Radio } from 'components/shared/formControls';
import {
  Condition,
  FormLegend,
  FormBox,
  NarrowFormFlex,
  FormGroupAdapter,
  RadioGroupAdapter,
} from 'components/shared/form';
import { validateRequired } from 'utils';

import ArrayFields from './ArrayFields';

const propTypes = {
  refs: PropTypes.shape({
    countriesCollection: PropTypes.arrayOf(PropTypes.object),
  }),
};

const defaultProps = {
  refs: {
    countriesCollection: [],
  },
};

function StepFields({ refs }) {
  return (
    <Fragment>
      <FieldArray
        subscription={{ initial: true }}
        name="application.properties"
        component={ArrayFields}
      />

      <NarrowFormFlex>
        <FormBox width={1}>
          <Hr />
        </FormBox>
      </NarrowFormFlex>

      <NarrowFormFlex>
        <FormBox width={1}>
          <Field
            name="application.legalName"
            required
            validate={validateRequired()}
            label="What is the name of your LLC or Company that owns or will own the Property?"
            component={FormGroupAdapter}
          />
        </FormBox>
      </NarrowFormFlex>

      <LegendFormFlex>
        <FormBox>
          <FormLegend>Borrower</FormLegend>
        </FormBox>
      </LegendFormFlex>

      <NarrowFormFlex>
        <FormBox>
          <Field
            component={RadioGroupAdapter}
            inline
            isConditional
            label="Have you filed for bankruptcy or had any properties foreclosed upon in the past 36 months?"
            name="application.bankruptcyProceedings"
            required
            type="checkbox"
          >
            <Radio id="bankruptcyProceedingsYes" value="true" label="Yes" />
            <Radio id="bankruptcyProceedingsNo" value="false" label="No" />
          </Field>
        </FormBox>
      </NarrowFormFlex>

      <NarrowFormFlex>
        <FormBox>
          <Field
            component={RadioGroupAdapter}
            inline
            isConditional
            label="Are you a U.S. citizen?"
            name="application.usCitizen"
            required
            type="checkbox"
            validate={validateRequired({ message: 'is not included in the list' })}
          >
            <Radio id="usCitizenYes" value="true" label="Yes" />
            <Radio id="usCitizenNo" value="false" label="No" />
          </Field>
        </FormBox>
      </NarrowFormFlex>

      <Condition when="application.usCitizen" is="false">
        <NarrowFormFlex>
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              name="application.countryOfCitizenship"
              type="select"
              options={refs.countriesCollection}
              required
              label="Country of Citizenship"
              validate={validateRequired()}
              component={FormGroupAdapter}
              isConditional
            />
          </FormBox>
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              name="application.visaStatus"
              required
              label="Name or Code"
              validate={validateRequired()}
              component={FormGroupAdapter}
              isConditional
            />
          </FormBox>
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              name="application.permanentResident"
              label="Are you a permanent resident?"
              inline
              required
              validate={validateRequired({ message: 'is not included in the list' })}
              component={RadioGroupAdapter}
              type="checkbox"
              isConditional
            >
              <Radio value="true" id="permanentResidentTrue" label="Yes" />
              <Radio value="false" id="permanentResidentFalse" label="No" />
            </Field>
          </FormBox>
        </NarrowFormFlex>
      </Condition>
    </Fragment>
  );
}

const LegendFormFlex = styled(NarrowFormFlex)`
  margin-bottom: 1rem;
`;

StepFields.propTypes = propTypes;
StepFields.defaultProps = defaultProps;

export default StepFields;
