import * as R from 'ramda';

function setDocumentsCategoryIdHandler({ documentsCategoryId, value, form, name }) {
  const { change } = form;
  return () => {
    if (R.isNil(documentsCategoryId)) return;
    if (value) return;
    change(name, documentsCategoryId);
  };
}

export { setDocumentsCategoryIdHandler as setDocumentsCategoryId };
