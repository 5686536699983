import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, getContext, pure } from 'recompose';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';

import { getBridgeFullFormFifthStepPropertyRefs, getSaleSignedAgreementDocumentCategory } from 'selectors';

import { FormBox, FormGroupAdapter, RadioGroupAdapter, FormFlex, Condition } from 'components/shared/form';
import { Radio } from 'components/shared/formControls';

import { validateRequired } from 'utils';

import Files from './Files';

const propTypes = {
  isFormDisabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  refs: PropTypes.shape({
    findTypesCollection: PropTypes.arrayOf(PropTypes.object),
    contractStatusesCollection: PropTypes.arrayOf(PropTypes.object),
  }),
  saleSignedAgreementDocumentCategory: PropTypes.objectOf(PropTypes.any),
};

const defaultProps = {
  refs: {
    findTypesCollection: [],
    contractStatusesCollection: [],
  },
  saleSignedAgreementDocumentCategory: undefined,
};

function PurchaseFields({ isFormDisabled, name, refs, saleSignedAgreementDocumentCategory }) {
  return (
    <Fragment>
      <Condition when={`${name}.contractStatus`} is="under_contract">
        <FormFlex>
          <FormBox width={1}>
            <Field
              component={RadioGroupAdapter}
              disabled={isFormDisabled}
              inline
              isConditional
              label="Do you have a Sales Contract to Upload?"
              name={`${name}.saleSignedAgreement`}
              required
              type="checkbox"
              validate={validateRequired()}
            >
              <Radio value="true" id={`${name}.saleSignedAgreementTrue`} label="Yes" />
              <Radio value="false" id={`${name}.saleSignedAgreementFalse`} label="No" />
            </Field>
          </FormBox>
        </FormFlex>

        <Files category={saleSignedAgreementDocumentCategory} name={name} />
      </Condition>

      <Condition when={`${name}.saleSignedAgreement`} is="false">
        <FormFlex>
          <FormBox width={1}>
            <Field
              isConditional
              name={`${name}.buyerNameOnAgreement`}
              label="Buyer's Name on Agreement"
              required
              disabled={isFormDisabled}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>

        <FormFlex>
          <FormBox width={1}>
            <Field
              isConditional
              name={`${name}.sellerName`}
              label="Seller's Name"
              required
              disabled={isFormDisabled}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>

        <FormFlex>
          <FormBox width={[1, 1, 1 / 2]}>
            <Field
              isConditional
              name={`${name}.contractSigned`}
              label="Date Contract Signed"
              type="date"
              required
              disabled={isFormDisabled}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
          <FormBox width={[1, 1, 1 / 2]}>
            <Field
              isConditional
              name={`${name}.contractClosingDate`}
              label="Contract Closing Date"
              type="date"
              required
              disabled={isFormDisabled}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>

        <FormFlex>
          <FormBox width={[1, 1, 1 / 2]}>
            <Field
              isConditional
              name={`${name}.contractDeposit`}
              label="Contract Deposit"
              typeOfMask="currency"
              disabled={isFormDisabled}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>
      </Condition>
    </Fragment>
  );
}

PurchaseFields.propTypes = propTypes;
PurchaseFields.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    saleSignedAgreementDocumentCategory: getSaleSignedAgreementDocumentCategory(state),
    refs: getBridgeFullFormFifthStepPropertyRefs(state),
  };
}

const enhance = compose(
  getContext({ isFormDisabled: PropTypes.bool }),
  connect(mapStateToProps),
  pure,
);

export default enhance(PurchaseFields);
