import { DRAW_REQUESTS_FETCH_SUCCESS } from 'constants/actionTypes';
import { initialState } from '../selectors/drawRequests';

export default (state = initialState, action) => {
  switch (action.type) {
    case DRAW_REQUESTS_FETCH_SUCCESS:
      return action.payload.collection;
    default:
      return state;
  }
};
