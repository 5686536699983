import React from 'react';
import styled from 'styled-components';
import { compose, pure } from 'recompose';
import { withRouter } from 'react-router-dom';

import { Wrapper } from 'components/shared';
import { UserHeader } from 'components/blocks/User';

import { UserTemplate } from 'components/templates';

import { ScrollToTopOnMount } from 'hocs';

import Contacts from './Contacts';
import Menu from './Menu';
import { ApplicationHeader } from '../shared';

function ApplicationContactsPage() {
  return (
    <UserTemplate header={<UserHeader />}>
      <ApplicationHeader />
      <StyledWrapper>
        <Menu />
        <Contacts />
      </StyledWrapper>
    </UserTemplate>
  );
}

const StyledWrapper = styled(Wrapper)`
  width: 100%;
  max-width: 75rem;
`;

const enhance = compose(
  ScrollToTopOnMount,
  withRouter,
  pure,
);

export default enhance(ApplicationContactsPage);
