import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'grid-styled';

import ContentLoader from 'components/shared/ContentLoader';

const propTypes = {
  className: PropTypes.string,
  labelWidth: PropTypes.string,
};

const defaultProps = {
  className: undefined,
  labelWidth: '10rem',
};

function FromLegendLoader({ className, width, ...props }) {
  return (
    <Flex className={className}>
      <ContentLoader maxWidth={width} height="1.75rem" {...props} />
    </Flex>
  );
}

FromLegendLoader.propTypes = propTypes;
FromLegendLoader.defaultProps = defaultProps;

export default FromLegendLoader;
