import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import * as actions from 'actions';

import { PortalIcon } from 'components/shared';
import { ExplanationForm } from 'components/Application/StepsPage/shared';

import ConfirmationLink from 'components/shared/ConfirmationLink';
import Link from 'components/shared/Link';
import Icon from 'components/shared/Icon';

import { dateWithTimeFormat } from 'utils';

const propTypes = {
  createExplanationDocument: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  updateExplanationDocument: PropTypes.func.isRequired,
  file: PropTypes.shape({
    asset: PropTypes.objectOf(PropTypes.any),
    category: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      key: PropTypes.string,
    }),
    contact: PropTypes.objectOf(
      PropTypes.shape({
        displayName: PropTypes.string,
        firstName: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        lastName: PropTypes.string,
      }),
    ),
    createdAt: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    meta: PropTypes.objectOf(PropTypes.bool),
    target: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      type: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  isDisabled: PropTypes.bool,
};

const defaultProps = {
  isDisabled: false,
};

function DocFile({ isDisabled, createExplanationDocument, deleteDocument, file, match, updateExplanationDocument }) {
  const { asset, category, contact, createdAt, description, explanation, id, meta, metadata, target } = file;
  const { token } = match.params;
  return (
    <Container>
      <Check />
      <Info>
        {asset && (
          <Item>
            <StyledLink href={asset.url}>{asset.filename}</StyledLink>
          </Item>
        )}
        <Item small>{contact.displayName}</Item>
        <Item small>{dateWithTimeFormat(createdAt)}</Item>
        {metadata && <Item small>metadata</Item>}
        {description && <Description>{description}</Description>}
      </Info>
      {asset && asset.size && <Size>{asset.size}</Size>}
      {!isDisabled && (
        <Actions>
          {!asset && (
            <Edit>
              <ExplanationForm
                action={id ? updateExplanationDocument : createExplanationDocument}
                explanation={explanation}
                id={id}
                targetId={target && target.id}
                categoryId={category.id}
              />
            </Edit>
          )}
          {meta.availableToDestroy && <Destroy data-name="removeProperty" onClick={() => deleteDocument(token, id)} />}
        </Actions>
      )}
    </Container>
  );
}

const linkStyle = css`
  color: #2a97c6;
  &:hover {
    color: #1d6787;
  }
`;

const actionStyle = css`
  ${linkStyle};
  padding: 0.1875rem 0.2rem;
  font-size: 0.75rem;
  line-height: 1.42857rem;
  border-radius: 0.1875rem;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  padding-top: 0.3125rem;
  line-height: 1.42857;
  border-top: 0.0625rem solid #ddd;
`;

const Check = styled((props) => <Icon width={0.8} icon="check" {...props} />)`
  position: absolute;
  top: 0.4rem;
  left: -1.2rem;
  display: block;
  color: #3c763d;
`;

const Info = styled.div`
  flex: 1;
`;

const Size = styled.div``;

const Small = styled.small`
  font-size: 0.85em;
`;

const Item = styled(({ small, ...props }) => (small ? <Small {...props} /> : <span {...props} />))`
  padding-left: 0.625rem;
  &:first-child {
    padding-left: 0;
  }
`;

const Description = styled.div`
  margin-bottom: 0.625rem;
`;

const StyledLink = styled((props) => <Link underline={false} {...props} />)`
  ${linkStyle};
`;

const Destroy = styled((props) => (
  <ConfirmationLink {...props}>
    <Icon width={0.6} icon="cross" />
  </ConfirmationLink>
))`
  ${actionStyle};
`;

const Edit = styled((props) => <PortalIcon width={0.6} icon="pen" {...props} />)`
  ${actionStyle};
  cursor: pointer;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
`;

DocFile.propTypes = propTypes;
DocFile.defaultProps = defaultProps;

function mapDispatchToProps(dispatch) {
  return {
    createExplanationDocument: bindActionCreators(actions.createExplanationDocument, dispatch),
    deleteDocument: bindActionCreators(actions.deleteDocument, dispatch),
    updateExplanationDocument: bindActionCreators(actions.updateExplanationDocument, dispatch),
  };
}

const enhance = compose(withRouter, connect(null, mapDispatchToProps), pure);

export default enhance(DocFile);
