import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { palette } from 'styled-tools';

import Icon from 'components/shared/Icon';
import { userDefaultProps, userPropTypes } from 'propTypes';

import defaultAvatar from './default_avatar.svg';

const propTypes = {
  ...userPropTypes,
  className: PropTypes.string,
  handleClick: PropTypes.func,
  isOpen: PropTypes.bool,
  dataName: PropTypes.string,
};

const defaultProps = {
  ...userDefaultProps,
  className: null,
  handleClick: null,
  open: false,
  dataName: null,
};

function UserNavigationName({ className, handleClick, isOpen, user, dataName }) {
  return (
    <Container className={className} onClick={handleClick} data-name={dataName}>
      <LogoImg src={defaultAvatar} />
      {user.contact.firstName} {user.contact.lastName}
      <StyledIcon icon="angle" width={0.65} isOpen={isOpen} />
    </Container>
  );
}

const Container = styled.a`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 17px;
  color: #898e95;
  font-family: helvetica;
  letter-spacing: 0.4px;
  cursor: pointer;
  &:hover {
    color: ${palette('primary', 0)};
  }
`;

const StyledIcon = styled(Icon)`
  position: relative;
  top: 1px;
  display: block;
  margin-left: 0.5rem;
  transform: ${({ isOpen }) => (isOpen ? null : 'rotate(180deg)')};
  transition: all 0.2s;
  ${Container}:hover & {
    color: ${palette('primary', 0)};
  }
`;

const LogoImg = styled.img`
  width: 16px;
  height: 18px;
  margin-bottom: 6px;
  margin-right: 5px;
`;

UserNavigationName.propTypes = propTypes;
UserNavigationName.defaultProps = defaultProps;

export default UserNavigationName;
