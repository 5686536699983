import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withContext, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import createFocusDecorator from 'final-form-focus';
import { withRouter } from 'react-router-dom';

import * as actions from 'actions';

import Form from './Form';
import { handlers } from './shared';

const propTypes = {
  initialValues: PropTypes.shape({
    application: PropTypes.shape({
      borrower: PropTypes.objectOf(PropTypes.any),
      dataEntryStep: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      token: PropTypes.string,
      type: PropTypes.string,
    }),
  }),
  loading: PropTypes.bool.isRequired,
  updateApplicationFirstStep: PropTypes.func.isRequired,
};

const defaultProps = {
  initialValues: {},
};

const focusOnError = createFocusDecorator();

function FullFormInvestmentExperience({ initialValues, loading, updateApplicationFirstStep }) {
  return (
    <FinalForm
      onSubmit={updateApplicationFirstStep}
      initialValues={initialValues}
      subscription={{ submitting: true, pristine: true }}
      decorators={[focusOnError]}
      loading={loading}
      component={Form}
    />
  );
}

FullFormInvestmentExperience.propTypes = propTypes;
FullFormInvestmentExperience.defaultProps = defaultProps;

function mapDispatchToProps(dispatch) {
  return {
    fetchApplicationFirstStep: bindActionCreators(actions.fetchApplicationFirstStep, dispatch),
    updateApplicationFirstStep: bindActionCreators(actions.updateApplicationFirstStep, dispatch),
  };
}

const enhance = compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps,
  ),
  withState('initialValues', 'setInitialValues', {}),
  withState('loading', 'setLoading'),
  withState('isFormDisabled', 'setIsFormDisabled', false),
  withHandlers({
    isFormDisabledSetter: handlers.isFormDisabledSetter,
  }),
  withHandlers({
    mergeInitialValues: handlers.mergeInitialValues,
  }),
  lifecycle({
    async componentDidMount() {
      const { match, fetchApplicationFirstStep, mergeInitialValues, setLoading } = this.props;
      const { token } = match.params;
      setLoading(true);
      await fetchApplicationFirstStep(token).then(mergeInitialValues);
      setLoading(false);
    },
  }),
  withContext(
    {
      isFormDisabled: PropTypes.bool,
    },
    ({ isFormDisabled }) => ({ isFormDisabled }),
  ),
  pure,
);

export default enhance(FullFormInvestmentExperience);
