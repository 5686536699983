import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { palette as toolPalette } from 'styled-tools';
import * as R from 'ramda';

import { styleUtils } from 'utils';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  colorPalette: PropTypes.string,
  colorTone: PropTypes.number,
  palette: PropTypes.string,
  size: PropTypes.string,
  tone: PropTypes.number,
};

const defaultProps = {
  className: undefined,
  colorPalette: 'grayscale',
  colorTone: -1,
  palette: 'primary',
  size: 'base',
  tone: 0,
};

const badgeStyleSize = R.curry(styleUtils.styleSize)('size');
const badgeFontSize = badgeStyleSize('font');

function Badge({ children, className, size, palette, tone, colorPalette, colorTone }) {
  return (
    <Container
      className={className}
      colorPalette={colorPalette}
      colorTone={colorTone}
      palette={palette}
      size={size}
      tone={tone}
    >
      {children}
    </Container>
  );
}

Badge.propTypes = propTypes;
Badge.defaultProps = defaultProps;

const Container = styled.div`
  position: relative;
  display: inline-block;
  text-align: center;
  font-size: ${badgeFontSize(0.75)};
  font-weight: bold;
  line-height: 1;
  color: ${({ colorPalette, colorTone }) => toolPalette(colorPalette, colorTone)};
  white-space: nowrap;
  background-color: ${toolPalette()};
  padding: 0.5em 0.6em 0.5em;
  border-radius: 0.3em;
  vertical-align: baseline;
  border-radius: 3px;
`;

export default Badge;
