import { getPath } from 'utils';
import { parseEndpoint } from 'utils/api';

export const INDEX_PATH = '/';

export const BRIDGE_PATH = '/applications/bridge';
export const BRIDGE_NEW_CONSTRUCTION_PATH = BRIDGE_PATH + '?type=new_construction';
export const RENTAL_PATH = '/applications/rental';
export const LEAD_PATH = '/applications/:token/lead';
export const LEAD_PROPERTY_PATH = '/applications/:token/lead/property';
export const LEAD_BORROWER_PATH = '/applications/:token/lead/borrower';

export const APPLICATION_PATH = '/applications/:token';

export const MY_APPLICATIONS_PATH = '/my/applications/:status';
export const PROFILE_PATH = '/me';
export const EDIT_PASSWORD_PATH = '/password/edit';
export const REFERRAL_PATH = '/referral_link';

export const APPLICATION_AUTH_PATH = '/applications/:token/auth';
export const APPLICATION_ROOT_PATH = '/applications/:token/root';
export const APPLICATION_STEP_PATH = '/applications/:token/steps/:step';
export const APPLICATION_DOCUMENTS_PATH = '/applications/:token/documents';
export const APPLICATION_SCHEDULE_PATH = '/applications/:token/schedule';
export const APPLICATION_CONSTRUCTION_BUDGET_PATH = '/applications/:token/construction_budget';
export const APPLICATION_CONSTRUCTION_BUDGET_PROPERTY_PATH = '/applications/:token/construction_budget/:propertyId';
export const APPLICATION_CONTACTS_PATH = '/applications/:token/contacts';
export const APPLICATION_NEW_EXPENSES_PATH = '/applications/:token/expenses/new';
export const APPLICATION_DRAW_REQUESTS_PATH = '/applications/:token/draw_requests';
export const APPLICATION_NEW_DRAW_REQUESTS_PATH = '/applications/:token/draw_requests/new';
export const APPLICATION_EDIT_DRAW_REQUESTS_PATH = '/applications/:token/draw_requests/edit/:id';
export const APPLICATION_PAYOFF_PATH = '/applications/:token/payoff';
export const APPLICATION_EXTENSION_PATH = '/applications/:token/extension';
export const APPLICATION_CREDIT_AND_BACKGROUND_DISCLAIMER_PATH =
  '/applications/:token/credit_and_background_disclaimer';

const APPLICATION_QUOTE_PDF_PATH = '/applications/:token/quote.pdf';
export const APPLICATION_QUOTE_PDF_URL = parseEndpoint(APPLICATION_QUOTE_PDF_PATH);

export const DRAW_REQUESTS_PATH = '/draw_requests';

export const SIGN_IN_PATH = '/auth/sign_in';
export const SIGN_OUT_PATH = '/auth/sign_out';
export const RESET_PASS = '/auth/password/reset';
export const RESET_SET_PASS = '/auth/password/reset/:token';
export const CONFIRMATION_EMAIL_PATH = '/auth/email/confirm/:token';
export const LOGIN_AS_PATH = '/auth/login_as';
// Extented
export const FAQS_URL = '//www.lendingone.com/faqs/';
export const ABOUT_US_URL = '//www.lendingone.com';

export const OUR_LOANS = '//www.lendingone.com/our-loans';
export const LENDING_CRITERIA = '//www.lendingone.com/lending-criteria';
export const LEARNING_CENTER = '//www.lendingone.com/learning-center/';
export const CONTACT_US = '//www.lendingone.com/contact-us';

export const CALENDLY = 'https://calendly.com/lendingone';
export const PHONE = 'tel: 1-866-942-4366';

export const PRIVACY_POLICY = '//www.lendingone.com/privacy-policy';

export const LOAN_HELP = 'https://lp.lendingone.com/loan-help'

export const INITIAL_ROUTE = {
  params: {},
  pathname: '',
};

export const applicantCurrentStatusPath = (status, params = {}) => {
  if (status === 'lead') {
    return getPath(LEAD_PATH, params);
  }

  if (status === 'incomplete_app') {
    return getPath(APPLICATION_STEP_PATH, params);
  }

  return getPath(APPLICATION_PATH, params);
};

export const isActive = (to) => (match, location) => location.pathname.includes(to);

export const HEADER_LINKS = [
  {
    url: ABOUT_US_URL,
    text: 'About Us',
    blank: true,
  },
  {
    url: FAQS_URL,
    text: 'FAQs',
    blank: true,
  },
];

export const FOOTER_LINKS = [
  {
    url: ABOUT_US_URL,
    text: 'About Us',
    blank: true,
  },
  {
    url: OUR_LOANS,
    text: 'Our Loans',
    blank: true,
  },
  {
    url: LENDING_CRITERIA,
    text: 'Why Us',
    blank: true,
  },
  {
    url: LEARNING_CENTER,
    text: 'Learning center',
    blank: true,
  },
  {
    url: CONTACT_US,
    text: 'Contact Us',
    blank: true,
  },
];
