import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import createFocusDecorator from 'final-form-focus';
import { withRouter } from 'react-router-dom';

import * as actions from 'actions';

import Form from './Form';
import { handlers, utils } from './shared';

const propTypes = {
  disabled: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    application: PropTypes.shape({
      borrower: PropTypes.objectOf(PropTypes.any),
      dataEntryStep: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      token: PropTypes.string,
      type: PropTypes.string,
      signatureVersion: PropTypes.string,
    }),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  updateApplicationFourthStep: PropTypes.func.isRequired,
};

const focusOnError = createFocusDecorator();

function Signature({ disabled, initialValues, loading, updateApplicationFourthStep }) {
  const { signatureVersion } = initialValues.application;
  return (
    <FinalForm
      disabled={disabled}
      signatureVersion={signatureVersion}
      onSubmit={updateApplicationFourthStep}
      initialValues={initialValues}
      subscription={{ submitting: true, pristine: true }}
      decorators={[focusOnError]}
      loading={loading}
      component={Form}
    />
  );
}

Signature.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
  return {
    fetchApplicationFourthStep: bindActionCreators(actions.fetchApplicationFourthStep, dispatch),
    updateApplicationFourthStep: bindActionCreators(actions.updateApplicationFourthStep, dispatch),
  };
}

const enhance = compose(
  withRouter,
  connect(null, mapDispatchToProps),
  withState('loading', 'setLoading', false),
  withState('disabled', 'setDisabled', false),
  withState('initialValues', 'setInitialValues', utils.setDefaultInitialValues()),
  withHandlers({
    setDisabledByInitValues: handlers.setDisabledByInitValues,
    setCheck: handlers.setCheck,
  }),
  withHandlers({
    mergeInitialValues: handlers.mergeInitialValues,
  }),
  lifecycle({
    async componentDidMount() {
      const { match, fetchApplicationFourthStep, mergeInitialValues, setLoading } = this.props;
      const { token } = match.params;
      setLoading(true);
      await fetchApplicationFourthStep(token).then(mergeInitialValues);
      setLoading(false);
    },
  }),
  pure,
);

export default enhance(Signature);
