import { defaultProps } from 'recompose';
import { Field } from 'react-final-form';
import { identity, isNil } from 'ramda';

function parse(value) {
  if (isNil(value)) {
    return null;
  }

  return identity(value);
}

export default defaultProps({ parse })(Field);
