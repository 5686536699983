import React from 'react';
import PropTypes from 'prop-types';
import { compose, getContext, pure } from 'recompose';
import { connect } from 'react-redux';
import { getAddressRefs } from 'selectors';
import { useParams } from 'react-router-dom';
import AddressFieldsStep2 from './step2';
import AddressFieldsStep4 from './step4';


const propTypes = {
  areComponentsRendered: PropTypes.bool.isRequired,
  setAreComponentsRendered: PropTypes.func.isRequired,
  namespace: PropTypes.string,
  refs: PropTypes.shape({
    statesCollection: PropTypes.arrayOf(PropTypes.object),
    countriesCollection: PropTypes.arrayOf(PropTypes.object),
  }),
  isRequired: PropTypes.bool,
  isFormDisabled: PropTypes.bool,
};

const defaultProps = {
  namespace: null,
  refs: {
    statesCollection: [],
    countriesCollection: [],
  },
  isRequired: true,
  isFormDisabled: false,
};

function AddressFields({ isFormDisabled, namespace, refs, areComponentsRendered, setAreComponentsRendered, isRequired }) {
  const { step } = useParams();

  if (parseInt(step, 10) === 4) {
    return (
      <AddressFieldsStep4
        isFormDisabled={isFormDisabled}
        namespace={namespace}
        refs={refs}
        areComponentsRendered={areComponentsRendered}
        setAreComponentsRendered={setAreComponentsRendered}
        isRequired={isRequired}
      />
    );
  }
  return (
    <AddressFieldsStep2
      isFormDisabled={isFormDisabled}
      namespace={namespace}
      refs={refs}
      areComponentsRendered={areComponentsRendered}
      setAreComponentsRendered={setAreComponentsRendered}
      isRequired={isRequired}
    />
  );
}

AddressFields.propTypes = propTypes;
AddressFields.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    refs: getAddressRefs(state),
  };
}

const enhance = compose(
  getContext({
    areComponentsRendered: PropTypes.bool,
    setAreComponentsRendered: PropTypes.func,
    namespace: propTypes.string,
    isFormDisabled: PropTypes.bool,
  }),
  connect(mapStateToProps),
  pure,
);

export default enhance(AddressFields);
