import NavLink from 'react-router-dom/NavLink';
import styled from 'styled-components';
import { theme } from 'styled-tools';

const Product = styled(NavLink)`
  position: relative;
  display: block;
  overflow: hidden;
  height: 100%;
  text-decoration: none;
  font-family: ${theme('fonts.base')};
  color: #fff;
  background: ${({ color }) => {
    switch (color) {
      case 'bridge':
        return '#665c56';
      case 'rental':
        return '#005B6F';
      case 'newconstruction':
        return '#648594';
      default:
        return '#005B6F';
    }
  }};
  `;

export default Product;
