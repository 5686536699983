import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ProductItem } from './';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

function List({ children }) {
  return (
    <StyledList>
      {children.map((value, index) => (
        <ProductItem key={index}>{value}</ProductItem>
      ))}
    </StyledList>
  );
}

const StyledList = styled.ul`
  display: block;
  margin-top: 20px;
  padding-left: 20px;
  list-style-type: disc;
`;

List.propTypes = propTypes;
export default List;
