import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field } from 'react-final-form';

import { getApplicationPropertiesCollection } from 'selectors';

import { FormFlex, FormBox, FormGroupAdapter, Condition } from 'components/shared/form';

import { validateEmail, validatePhone, validateEmpty } from 'utils';

const propTypes = {
  propertiesCollection: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

const defaultProps = {
  propertiesCollection: [],
};

function BuyerAgent({ propertiesCollection }) {
  return (
    <Fragment>
      <Condition when="contact.appliedToAll" is="true" reverse>
        <FormFlex>
          <FormBox width={1}>
            <Field
              isConditional
              name="contact.targetIds"
              label="Property"
              type="select"
              isMulti
              options={propertiesCollection}
              required
              validate={validateEmpty()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>
      </Condition>

      <FormFlex>
        <FormBox>
          <Field
            name="contact.appliedToAll"
            id="appliedToAll"
            type="checkbox"
            component={FormGroupAdapter}
          >
            Apply to all
          </Field>
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field name="contact.firstName" label="First Name" component={FormGroupAdapter} />
        </FormBox>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field name="contact.lastName" label="Last Name" component={FormGroupAdapter} />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field name="contact.title" label="Title" component={FormGroupAdapter} />
        </FormBox>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field name="contact.legalName" label="Company Name" component={FormGroupAdapter} />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 4]}>
          <Field
            name="contact.phone"
            label="Phone #"
            typeOfMask="phone"
            validate={validatePhone()}
            component={FormGroupAdapter}
          />
        </FormBox>

        <FormBox width={[1, 1, 1 / 4]}>
          <Field
            name="contact.phoneExtension"
            label="Extension#"
            typeOfMask="phoneExtension"
            component={FormGroupAdapter}
          />
        </FormBox>

        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="contact.email"
            label="Email"
            component={FormGroupAdapter}
            validate={validateEmail()}
          />
        </FormBox>
      </FormFlex>
    </Fragment>
  );
}

BuyerAgent.propTypes = propTypes;
BuyerAgent.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    propertiesCollection: getApplicationPropertiesCollection(state),
  };
}

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
  pure,
);

export default enhance(BuyerAgent);
