import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure, withHandlers, withState } from 'recompose';
import { is } from 'ramda';

import PanelRightPortal from '../PanelRightPortal';
import Link from '../Link';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
  text: PropTypes.string,
  to: PropTypes.string,
  togglePanel: PropTypes.func.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  content: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
};

const defaultProps = {
  component: undefined,
  children: undefined,
  className: undefined,
  text: undefined,
  to: undefined,
};

function render({ component: Component, children, ...props }) {
  if (is(Function, children)) {
    return children(props);
  }

  if (Component) {
    return <Component {...props}>{children}</Component>;
  }

  return <Link onClick={props.togglePanel}>{children}</Link>;
}

function Panel({ component, content: Content, className, children, ...props }) {
  const { open, togglePanel } = props;

  return (
    <Container className={className}>
      {render({ component, children, ...props })}
      <PanelRightPortal show={open} onHide={togglePanel} key="documentsChecklistPanel">
        <Content {...props} />
      </PanelRightPortal>
    </Container>
  );
}

const Container = styled.div`
  display: inline-block;
`;

Panel.propTypes = propTypes;
Panel.defaultProps = defaultProps;

const enhance = compose(
  withState('open', 'setOpen', false),
  withHandlers({
    togglePanel: ({ open, setOpen }) => () => {
      setOpen(!open);
    },
  }),
  pure,
);

export default enhance(Panel);
