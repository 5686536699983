import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, getContext, pure } from 'recompose';
import { connect } from 'react-redux';
import { curry, equals } from 'ramda';

import { getActualTypesCollection } from 'selectors/refs';
import usePortfolioFormProperties from 'hooks/usePortfolioFormProperties';

import IconLink from 'components/shared/IconLink';
import { FormBox, NarrowFormFlex } from 'components/shared/form';

import { ArrayFieldsWithDefaultValue } from 'hocs';
import { currencyFormat } from 'utils/format';
import { getOptionLabelByValue } from 'utils/form';

import Fields from './Fields';
import Property from './Property';
import { utils } from '../../shared';

const rGetOptionLabelByValue = curry(getOptionLabelByValue);

function getDetails(types) {
  return [
    {
      title: 'Property Type',
      key: 'type',
      propertyKey: 'type',
      format: rGetOptionLabelByValue(types),
    },
    {
      title: 'Purchase Price',
      key: 'purchasePrice',
      propertyKey: 'purchasePrice',
      format: currencyFormat,
    },
    {
      title: 'Repair Cost',
      key: 'repairValue',
      propertyKey: 'repairValue',
      format: currencyFormat,
    },
  ];
}

const propTypes = {
  isFormDisabled: PropTypes.bool.isRequired,
  isEditAddress: PropTypes.bool.isRequired,
  fields: PropTypes.objectOf(PropTypes.any).isRequired,
  types: PropTypes.arrayOf(PropTypes.object),
};

const defaultProps = {
  types: [],
};

function ArrayFields({ isFormDisabled, isEditAddress, fields, types }) {
  const {
    activeName,
    canDelete,
    canSwap,
    onDelete,
    toClose,
    toOpen,
    toToggle,
  } = usePortfolioFormProperties({
    isFormDisabled,
    defaultActiveName: 'application.properties[0]',
  });

  function addProperty() {
    fields.push(utils.setDefultPropertyValues());
    toOpen(`application.properties[${fields.length}]`);
  }

  return (
    <Fragment>
      {fields.map((name, index) => (
        <Property
          activeName={activeName}
          canDelete={canDelete}
          canSwap={canSwap}
          onDelete={onDelete}
          toClose={toClose}
          toOpen={toOpen}
          toToggle={toToggle}
          key={name}
          defaultOpen={equals(index + 1, fields.length)}
          details={getDetails(types)}
          index={index}
          isFormDisabled={isFormDisabled}
          name={name}
          fields={fields}
          render={({ isOpen }) => isOpen && (
            <Fields index={index} fields={fields} name={name} isFormDisabled={isFormDisabled} />
          )}
        />
      ))}

      {isEditAddress && (
        <NarrowFormFlex justifyContent="center">
          <FormBox>
            <IconLink
              fz={0.825}
              icon="plus"
              middle
              bs="dashed"
              disabled={isFormDisabled}
              dataName="addProperty"
              onClick={addProperty}
            >
              Add another property
            </IconLink>
          </FormBox>
        </NarrowFormFlex>
      )}
    </Fragment>
  );
}

ArrayFields.propTypes = propTypes;
ArrayFields.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    types: getActualTypesCollection(state),
  };
}

const enhance = compose(
  ArrayFieldsWithDefaultValue,
  connect(mapStateToProps),
  getContext({ isFormDisabled: PropTypes.bool, isEditAddress: PropTypes.bool }),
  pure,
);

export default enhance(ArrayFields);
