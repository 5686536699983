import * as R from 'ramda';

import useFieldValue from 'hooks/useFieldValue';

function useIsOwner(name) {
  const owner = useFieldValue(name);
  return R.equals('own', owner);
}

export default useIsOwner;
