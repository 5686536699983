import React, { Fragment } from 'react';

import { NarrowFormFlex, FormBox, FormGroupLoader, FormLegendLoader, RadioGroupLoader } from 'components/shared/form';

function Loader() {
  return (
    <Fragment>
      <NarrowFormFlex>
        <FormBox width={1}>
          <FormGroupLoader labelWidth="21.5rem" />
        </FormBox>
      </NarrowFormFlex>

      <NarrowFormFlex>
        <FormBox width={1}>
          <FormGroupLoader labelWidth="8rem" />
        </FormBox>
      </NarrowFormFlex>

      <NarrowFormFlex>
        <FormBox width={1}>
          <FormLegendLoader mb="1rem" width="14rem" />
        </FormBox>
      </NarrowFormFlex>

      <NarrowFormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <FormGroupLoader labelWidth="121rem" />
        </FormBox>
        <FormBox width={[1, 1, 1 / 2]}>
          <FormGroupLoader labelWidth="10rem" />
        </FormBox>
      </NarrowFormFlex>

      <NarrowFormFlex>
        <FormBox width={1}>
          <RadioGroupLoader inline labelWidth="8.125rem" />
        </FormBox>
      </NarrowFormFlex>

      <NarrowFormFlex>
        <FormBox width={1}>
          <FormGroupLoader labelWidth="34.125rem" />
        </FormBox>
      </NarrowFormFlex>

      <NarrowFormFlex>
        <FormBox width={1}>
          <RadioGroupLoader inline labelWidth="40.625rem" />
        </FormBox>
      </NarrowFormFlex>
    </Fragment>
  );
}

export default Loader;
