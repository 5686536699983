import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { isEmpty } from 'ramda';

import { Title } from '../shared';
import Note from './Note';

const propTypes = {
  collection: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  collection: [],
};

function ProcessorNotes({ collection, onChange }) {
  if (isEmpty(collection)) {
    return (
      <Container>
        <Title>Deal Communication</Title>
        <Em>No Notes</Em>
      </Container>
    );
  }

  return (
    <Container>
      <Title>Deal Communication</Title>
      {collection.map((p) => (
        <StyledNote onChange={onChange} key={p.id} {...p} />
      ))}
    </Container>
  );
}

const Container = styled.div`
  padding: 2.1875rem 5.1875rem 2.1875rem 0;

  @media (max-width: ${prop('theme.breakpoints.1')}) {
    padding: 2.1875rem 0;
  }
`;

const Em = styled.em`
  font-style: italic;
`;

const StyledNote = styled(Note)`
  margin-bottom: 1.25rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

ProcessorNotes.propTypes = propTypes;
ProcessorNotes.defaultProps = defaultProps;

export default ProcessorNotes;
