import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import NavLink from 'react-router-dom/NavLink';

const propTypes = {
  action: PropTypes.func,
  className: PropTypes.bool,
  children: PropTypes.node.isRequired,
  url: PropTypes.string,
  dataName: PropTypes.string,
};

const defaultProps = {
  action: null,
  className: false,
  url: null,
  dataName: null,
};

function MenuItem({ action, children, className, url, dataName }) {
  if (url) {
    return (
      <Item className={className}>
        <StyledNavLink to={url}>{children}</StyledNavLink>
      </Item>
    );
  }
  if (action) {
    return (
      <Item className>
        <StyledLink onClick={action} data-name={dataName}>
          {children}
        </StyledLink>
      </Item>
    );
  }
  return (
    <Item className={className}>
      <StyledSpan>{children}</StyledSpan>
    </Item>
  );
}

const baseStyle = css`
  display: block;
  padding: 0.2875rem 1.25rem;
  text-decoration: none;
  font-weight: normal;
  line-height: 1.42857;
  color: #504f4d;
  white-space: nowrap;
`;

const linkStyle = css`
  transition: color cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s,
    background-color cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: #434240;
    background-color: #f5f5f5;
  }
`;

const Item = styled.li`
  display: block;
`;

const StyledNavLink = styled(NavLink).attrs({})`
  ${baseStyle};
  ${linkStyle};
`;

const StyledLink = styled.a`
  ${baseStyle};
  ${linkStyle};
`;

const StyledSpan = styled.span`
  ${baseStyle};
`;

MenuItem.propTypes = propTypes;
MenuItem.defaultProps = defaultProps;

export default MenuItem;
