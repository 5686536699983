import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, useForm } from 'react-final-form';
import * as R from 'ramda';

import { conditionalPredicat } from 'utils/form';

const valueType = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
  PropTypes.bool,
]);

const propTypes = {
  when: PropTypes.string.isRequired,
  names: PropTypes.arrayOf(PropTypes.string),
  reverse: PropTypes.bool,
  is: PropTypes.oneOfType([PropTypes.arrayOf(valueType), valueType]).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  allowNull: PropTypes.bool,
};

const defaultProps = {
  names: [],
  reverse: false,
  allowNull: true,
};

function Fields({ children, value, predicat, allowNull, names }) {
  const [show, setShow] = useState(false);
  const { mutators } = useForm();
  const { hold, unhold } = mutators;

  function check(v) {
    const newState = predicat(v);
    setShow(newState);
    if (newState) {
      R.forEach(unhold, names);
    } else {
      R.forEach((name) => hold(name, allowNull), names);
    }
  }

  useEffect(() => {
    check(value);
  }, [value]);

  if (show) {
    return children;
  }

  return null;
}

function Condition({ allowNull, when, children, names, reverse, is }) {
  return (
    <Field
      name={when}
      subscription={{ value: true }}
      render={({ input }) => (
        <Fields
          value={input.value}
          predicat={conditionalPredicat(when, is, reverse)}
          names={names}
          allowNull={allowNull}
        >
          {children}
        </Fields>
      )}
    />
  );
}

Condition.propTypes = propTypes;
Condition.defaultProps = defaultProps;

export default Condition;
