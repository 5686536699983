import React from 'react';
import PropTypes from 'prop-types';

import AddressSelect from 'components/shared/formControls/AddressSelect';
import Checkbox from 'components/shared/formControls/Checkbox';
import Date from 'components/shared/formControls/Date';
import Dropzone from 'components/shared/formControls/Dropzone';
import Files from 'components/shared/formControls/Files';
import Input from 'components/shared/formControls/Input';
import Radio from 'components/shared/formControls/Radio';
import Select from 'components/shared/formControls/Select';
import MultiSelect from 'components/shared/formControls/MultiSelect/SimpleValueAdapter';
import Textarea from 'components/shared/formControls/Textarea';

const propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  inputComponent: PropTypes.element,
  isMulti: PropTypes.bool,
};

const defaultProps = {
  type: 'text',
  inputComponent: undefined,
  isMulti: false,
};

function FormField({ name, type, inputComponent: Component, isMulti, ...props }) {
  const inputProps = {
    name,
    id: name,
    'aria-describedby': `${name}Error`,
    ...props,
  };

  if (Component) {
    return <Component {...inputProps} />;
  }

  if (type === 'address') {
    return <AddressSelect {...inputProps} />;
  }

  if (type === 'date') {
    return <Date {...inputProps} />;
  }

  if (type === 'checkbox') {
    return <Checkbox {...inputProps} />;
  }

  if (type === 'dropzone') {
    return <Dropzone {...inputProps} />;
  }

  if (type === 'files') {
    return <Files {...inputProps} />;
  }

  if (type === 'radio') {
    return <Radio {...inputProps} />;
  }

  if (type === 'select' && isMulti) {
    return <MultiSelect {...inputProps} />;
  }

  if (type === 'select') {
    return <Select {...inputProps} />;
  }

  if (type === 'textarea') {
    return <Textarea {...inputProps} />;
  }

  return <Input type={type} {...inputProps} />;
}

FormField.propTypes = propTypes;
FormField.defaultProps = defaultProps;

export default FormField;
