import * as R from 'ramda';

function setDefaultPropertyIdHandler({ form, properties, input }) {
  const { change } = form;
  const { name, value } = input;
  return () => {
    const defaultPropertyId = properties && properties[0] && properties[0].id;
    if (R.isNil(defaultPropertyId)) return;
    if (value) return;
    change(name, defaultPropertyId);
  };
}

export { setDefaultPropertyIdHandler as setDefaultPropertyId };
