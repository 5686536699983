import * as R from 'ramda';

import { initialState } from 'selectors/applications';

import * as actionTypes from 'constants/actionTypes';
import { immutablePush } from 'utils';

export const updateReducer = (state, payload) => {
  const { collection } = state;
  const index = R.findIndex(R.propEq('token', payload.token), collection);

  if (index < 0) {
    return {
      detail: payload,
      collection: immutablePush(collection, payload),
    };
  }

  return {
    detail: payload,
    collection: [...collection.slice(0, index), { ...collection[index], ...payload }, ...collection.slice(index + 1)],
  };
};

export default (state = initialState, { type, payload }) => {
  const { collection, detail } = state;

  switch (type) {
    case actionTypes.APPLICATIONS_FETCH_SUCCESS:
      return {
        detail,
        collection: payload,
      };

    // Application
    case actionTypes.APPLICATION_CREATE_SUCCESS: {
      return {
        detail: payload,
        collection: immutablePush(collection, payload),
      };
    }

    case actionTypes.APPLICATION_FETCH_SUCCESS:
    case actionTypes.WITHDRAW_SUCCESS:
    case actionTypes.UNDO_WITHDRAW_SUCCESS:
    case actionTypes.APPLICATION_UPDATE_SUCCESS:
      return updateReducer(state, payload);

    case actionTypes.APPLICATION_QUOTE_FETCH_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...payload,
        },
      };

    case actionTypes.APPLICATION_BUY_POINTS_GUIDELINE_FETCH_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          buyPoints: payload,
        },
      };

    case actionTypes.APPLICATIONS_RESET:
      return initialState;

    default:
      return state;
  }
};
