import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex } from 'grid-styled';
import { prop } from 'styled-tools';

import ContentLoader from 'components/shared/ContentLoader';

const propTypes = {
  className: PropTypes.string,
  labelWidth: PropTypes.string,
};

const defaultProps = {
  className: undefined,
  labelWidth: '10rem',
};

function CheckboxLoader({ className, labelWidth }) {
  return (
    <Flex className={className} alignItems="center">
      <ContentLoader maxWidth="1.5rem" height="1.5rem" />
      <ContentLoader ml="0.375rem" maxWidth={labelWidth} height="1rem" />
    </Flex>
  );
}

const StyledCheckboxLoader = styled(CheckboxLoader)`
  margin-bottom: ${prop('marginBottom')};
`;

CheckboxLoader.propTypes = propTypes;
CheckboxLoader.defaultProps = defaultProps;

export default StyledCheckboxLoader;
