import * as R from 'ramda';

import { conditionalPredicat } from 'utils';

function getPredicatHandler({ when, is, reverse }) {
  return conditionalPredicat(when, is, reverse);
}

function holdValuesHandler({ names, mutators, allowNull }) {
  return () => {
    const { hold } = mutators;
    R.forEach((name) => hold(name, allowNull), names);
  };
}

function unholdValuesHandler({ names, mutators }) {
  return () => {
    const { unhold } = mutators;
    R.forEach(unhold, names);
  };
}

function checkHandler({ value, show, getPredicat, setShow }) {
  return () => {
    const predicat = getPredicat(value);
    if (predicat && show) return;
    if (!predicat && !show) return;
    if (predicat) {
      setShow(true);
      return;
    }
    setShow(false);
  };
}

export {
  checkHandler as check,
  getPredicatHandler as getPredicat,
  holdValuesHandler as holdValues,
  unholdValuesHandler as unholdValues,
};
