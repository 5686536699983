import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';

import { getApplicationBorrowersCollection } from 'selectors';

import { FormFlex, FormBox, FormGroupAdapter, Condition } from 'components/shared/form';

import { validateEmail, validatePhone, validateRequired } from 'utils';

const propTypes = {
  borrowersCollection: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

const defaultProps = {
  borrowersCollection: [],
};

function InsuranceCarrier({ borrowersCollection }) {
  return (
    <Fragment>
      <Condition when="contact.appliedToAll" is="true" reverse>
        <FormFlex>
          <FormBox width={1}>
            <Field
              isConditional
              name="contact.targetId"
              label="Borrower"
              type="select"
              options={borrowersCollection}
              required
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>
      </Condition>

      <FormFlex>
        <FormBox>
          <Field
            name="contact.appliedToAll"
            id="appliedToAll"
            type="checkbox"
            component={FormGroupAdapter}
          >
            Apply to all
          </Field>
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field name="contact.firstName" label="First Name" component={FormGroupAdapter} />
        </FormBox>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field name="contact.lastName" label="Last Name" component={FormGroupAdapter} />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field name="contact.title" label="Title" component={FormGroupAdapter} />
        </FormBox>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field name="contact.legalName" label="Company Name" component={FormGroupAdapter} />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 4]}>
          <Field
            name="contact.phone"
            label="Phone #"
            typeOfMask="phone"
            validate={validatePhone()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 4]}>
          <Field
            name="contact.phoneExtension"
            label="Extension#"
            typeOfMask="phoneExtension"
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="contact.email"
            label="Email"
            component={FormGroupAdapter}
            validate={validateEmail()}
          />
        </FormBox>
      </FormFlex>
    </Fragment>
  );
}

InsuranceCarrier.propTypes = propTypes;
InsuranceCarrier.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    borrowersCollection: getApplicationBorrowersCollection(state),
  };
}

const enhance = compose(
  connect(mapStateToProps),
  pure,
);

export default enhance(InsuranceCarrier);
