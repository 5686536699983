import React from 'react';

import { BRIDGE_NEW_CONSTRUCTION_PATH } from 'constants/routes';

import {
  Product,
  ProductBody,
  ProductButton,
  ProductList,
  ProductThumb,
  ProductTitle,
} from '../styles';
import thumb from './thumb.jpg';

function NewConstruction() {
  const advantages = [
    'Up to 85% LTC (land and construction)',
    'Up to 70% of After-Build Value',
    '12-24 Months, Interest-Only ',
    'Loan Amounts from $200K to $2M',
  ];

  return (
    <Product color="newconstruction" to={BRIDGE_NEW_CONSTRUCTION_PATH}>
      <ProductThumb src={thumb} />
      <ProductBody>
        <ProductTitle>New Construction</ProductTitle>
        <ProductList>{advantages}</ProductList>
        <ProductButton>Apply now</ProductButton>
      </ProductBody>
    </Product>
  );
}

export default NewConstruction;
