import objectToFormData from 'object-to-formdata';

import setRequestInProcess from '../actions/request';
import { history } from '../store/configureStore';

import { DRAW_REQUESTS_FETCH_SUCCESS } from '../constants/actionTypes';

import * as requestTypes from '../constants/requestTypes';
import api from '../utils/api';
import { decamelizeKeys, throwSubmissionError } from '../utils';

export const fetchNewDrawRequest = (token) => () => {
  const url = `/applications/${token}/draw_requests`;

  return api
    .get(url)
    .then(({ data }) => data)
    .catch((errors) => console.log('errors', errors));
};

export const fetchEditDrawRequest = (token, id) => () => {
  const url = `/applications/${token}/draw_requests/${id}`;

  return api
    .get(url)
    .then(({ data }) => data)
    .catch((errors) => console.log('errors', errors));
};

export const createDrawRequest = ({ token, ...values }) => (dispatch) => {
  const { documents, documentsCategoryId } = values.drawRequest;
  const formData = objectToFormData(
    decamelizeKeys({
      ...values,
      drawRequest: { ...values.drawRequest, documents: undefined, documentsCategoryId: undefined },
    }),
  );
  if (documents) {
    documents.forEach((doc) => {
      formData.append('draw_request[documents][][asset]', doc);
      formData.append('draw_request[documents][][category_id]', documentsCategoryId);
    });
  }

  const url = `/applications/${token}/draw_requests`;

  return api
    .post(url, formData)
    .then(() => {
      dispatch(fetchDrawRequests());
      history.push('/draw_requests');
    })
    .catch(throwSubmissionError);
};

export const updateDrawRequest = ({ token, ...values }) => (dispatch) => {
  const { documents, documentsCategoryId } = values.drawRequest;
  const formData = objectToFormData(
    decamelizeKeys({
      ...values,
      drawRequest: { ...values.drawRequest, documents: undefined, documentsCategoryId: undefined },
    }),
  );
  if (documents) {
    documents.forEach((doc) => {
      if (doc.id) {
        formData.append('draw_request[documents][][id]', doc.id);
        formData.append('draw_request[documents][][asset]', doc);
        formData.append('draw_request[documents][][category_id]', documentsCategoryId);
        return;
      }
      formData.append('draw_request[documents][][asset]', doc);
      formData.append('draw_request[documents][][category_id]', documentsCategoryId);
    });
  }

  const url = `/applications/${token}/draw_requests/${values.drawRequest.id}`;

  return api
    .patch(url, formData)
    .then(() => {
      dispatch(fetchDrawRequests());
      history.push('/draw_requests');
    })
    .catch(throwSubmissionError);
};

export const fetchDrawRequests = () => (dispatch, getState) => {
  const requestType = requestTypes.DRAW_REQUESTS;
  const url = '/draw_requests';

  const requestInProcess = getState().request[requestType];

  if (requestInProcess && requestInProcess.inProcess) {
    return;
  }

  dispatch(setRequestInProcess(true, requestType));

  api
    .get(url)
    .then((response) => {
      dispatch(fetchNewDrawRequestSuccess(response.data));
      dispatch(setRequestInProcess(false, requestType));
    })
    .catch((errors) => {
      dispatch(setRequestInProcess(false, requestType, errors));
    });
};

const fetchNewDrawRequestSuccess = (entities) => ({
  type: DRAW_REQUESTS_FETCH_SUCCESS,
  payload: entities,
});
