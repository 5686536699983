import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers, withState } from 'recompose';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { space } from 'styled-system';
import { isNil, isEmpty, propOr } from 'ramda';

import { getProcessorNotesAuthorName } from 'selectors/processorNotes';

import { ToDo, Button, Strong } from 'components/shared';
import { Textarea } from 'components/shared/formControls';

const propTypes = {
  answerBody: PropTypes.string,
  authorName: PropTypes.string,
  body: PropTypes.string.isRequired,
  className: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  toClose: PropTypes.func.isRequired,
  toOpen: PropTypes.func.isRequired,
  value: PropTypes.string,
};

const defaultProps = {
  answerBody: undefined,
  authorName: undefined,
  className: undefined,
  isLoading: false,
  value: undefined,
};

function Note({
  authorName,
  answerBody,
  body,
  className,
  id,
  isLoading,
  isOpen,
  onChangeValue,
  onSave,
  toClose,
  toOpen,
  value,
}) {
  return (
    <ToDo className={className} size="sm" key={id}>
      <Body size="default">
        <div>
          <P>
            <Strong>{authorName || 'Loan officer'}</Strong>: <span style={{ whiteSpace: 'pre-line' }}>{body}</span>
          </P>
          {isOpen && (
            <P mt={10}>
              <Textarea value={value} onChange={onChangeValue} autoFocus />
            </P>
          )}
          {!isOpen && !isNil(answerBody) && (
            <P mt={10}>
              <Strong>You:</Strong> <span style={{ whiteSpace: 'pre-line' }}>{answerBody}</span>
            </P>
          )}
        </div>
      </Body>
      {!answerBody && (
        <ToDo.Actions align="right">
          {isOpen && (
            <Fragment>
              <Button xs disabled={isLoading} onClick={toClose}>
                Cancel
              </Button>
              <Button xs disabled={isNil(value) || isLoading || isEmpty(value)} onClick={onSave}>
                {isLoading ? 'Please wait...' : 'Save'}
              </Button>
            </Fragment>
          )}
          {!isOpen && (
            <Button xs onClick={toOpen}>
              Answer
            </Button>
          )}
        </ToDo.Actions>
      )}
    </ToDo>
  );
}

const P = styled.p`
  ${space}
`;

const Body = styled(ToDo.Body)`
  margin-top: 0;
`;

Note.propTypes = propTypes;
Note.defaultProps = defaultProps;

function mapStateToProps(state, { id }) {
  return {
    authorName: getProcessorNotesAuthorName(state, { processorNoteId: id }),
  };
}

const enhance = compose(
  connect(mapStateToProps),
  withState('isOpen', 'setIsOpen', false),
  withState('value', 'setValue', propOr(undefined, 'answerBody')),
  withState('isLoading', 'setIsLoading', false),
  withHandlers({
    toOpen: ({ setIsOpen }) => () => setIsOpen(true),
    toClose: ({ setIsOpen }) => () => setIsOpen(false),
    onChangeValue: ({ setValue }) => (e) => setValue(e.target.value),
    onSave: ({ id, setIsOpen, setIsLoading, value, onChange }) => async () => {
      setIsLoading(true);
      if (onChange) onChange(id, value);
      setIsOpen(false);
      setIsLoading(false);
    },
  }),
  pure,
);

export default enhance(Note);
