import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure, withStateHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';

import { Wrapper } from 'components/shared';
import { UserHeader } from 'components/blocks/User';

import { UserTemplate } from 'components/templates';

import { ScrollToTopOnMount } from 'hocs';

import { ApplicationHeader, Title, ApplicationSuccess } from '../shared';
import New from './New';

const propTypes = {
  success: PropTypes.bool.isRequired,
  setSuccessTrue: PropTypes.func.isRequired,
};

function ExpensesPage({ success, setSuccessTrue }) {
  return (
    <UserTemplate header={<UserHeader />}>
      <ApplicationHeader />
      <StyledWrapper>
        {success && <ApplicationSuccess />}
        {!success && (
          <Fragment>
            <Title>Pay Appraisal Fee</Title>
            <New setSuccessTrue={setSuccessTrue} />
          </Fragment>
        )}
      </StyledWrapper>
    </UserTemplate>
  );
}

const StyledWrapper = styled(Wrapper)`
  width: 100%;
  max-width: 75rem;
`;

ExpensesPage.propTypes = propTypes;

const enhance = compose(
  ScrollToTopOnMount,
  withRouter,
  withStateHandlers(() => ({ success: false }), {
    setSuccessTrue: () => () => ({ success: true }),
  }),
  pure,
);

export default enhance(ExpensesPage);
