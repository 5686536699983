import styled from 'styled-components';
import PropTypes from 'prop-types';
import { branch, compose, getContext, pure } from 'recompose';
import { space } from 'styled-system';
import { prop as styledProp } from 'styled-tools';

import { styleUtils } from 'utils';

const Actions = styled.div.attrs({
  size: ({ size }) => size || 'defalut',
})`
  margin-top: ${styleUtils.getSizeValue(1.6875)};
  text-align: ${styledProp('align', 'left')};
  ${space};

  & > * {
    margin-left: ${styleUtils.getSizeValue(0.8)};
    &:first-child {
      margin-bottom: 0;
    }
  }
`;

const enhance = compose(
  branch(({ size }) => !size, getContext({ size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })),
  pure,
);

export default enhance(Actions);
