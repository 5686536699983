import { successNotification, throwSubmissionError } from '../utils';
import api from '../utils/api';
import { history } from '../store/configureStore';

const createPayoffRequest = ({ token, ...values }) => () => {
  const url = `/applications/${token}/payoff`;

  return api
    .post(url, values)
    .then(() => {
      successNotification('Payoff request created successfully');
      history.push('/my/applications/closed');
    })
    .catch(throwSubmissionError);
};

export default createPayoffRequest;
