import * as R from 'ramda';

import { getIn, setIn } from 'utils';

export function decorateSelectInitialValue(name) {
  return (values) => {
    const value = getIn(name, values);
    if (R.isNil(value)) {
      return setIn({ label: null, value: null }, name, values);
    }
    return values;
  };
}

export function toStringInitialValue(name) {
  return (values) => {
    const value = getIn(name, values);
    if (R.isNil(value)) return values;
    if (R.is(String, value)) return values;
    return setIn(R.toString(value), name, values);
  };
}

export function setInDefaultValue(defaultValue, name) {
  return (values) => {
    const value = getIn(name, values);
    if (R.isNil(value)) return setIn(defaultValue, name, values);
    return setIn(value, name, values);
  };
}

export function setInDefaultNotEmptyValue(defaultValue, name) {
  return (values) => {
    const value = getIn(name, values);
    if (R.isNil(value) || R.isEmpty(value)) return setIn(defaultValue, name, values);
    return setIn(value, name, values);
  };
}
