import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { palette } from 'styled-tools';
import * as R from 'ramda';

import ConfirmationLink from 'components/shared/ConfirmationLink';
import Link from 'components/shared/Link';

const propTypes = {
  file: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
};

const getSize = (size) => {
  const mb = Math.floor(size / 100000) / 10;
  if (mb < 1) return `${mb * 1000} KB`;
  return `${mb} MB`;
};

function File({ file, value, onChange }) {
  if (file.id) {
    return (
      <Container>
        <Name href={file.url}>{file.filename}</Name>
        <Actions>
          <Action icon="cross" onClick={() => onChange(R.without([file], value))} />
        </Actions>
      </Container>
    );
  }
  return (
    <Container>
      <Name href={file.preview}>{file.name}</Name>
      <Size>{getSize(file.size)}</Size>
      <Actions>
        <Action icon="cross" onClick={() => onChange(R.without([file], value))} />
      </Actions>
    </Container>
  );
}

const Container = styled.li`
  display: flex;
  align-items: center;
  padding: 0.1875rem 0.625rem;
  border-top: 0.0625rem solid ${palette('grayscale', 5)};
`;

const Name = styled(Link).attrs({ target: '_blank' })`
  flex: 1;
  font-size: 0.85rem;
`;

const Size = styled.div`
  padding-left: 0.625rem;
`;

const Actions = styled.div`
  padding-left: 0.625rem;
`;

const Action = styled((props) => <ConfirmationLink palette="primary" as="button" width={0.5} {...props} />)`
  display: block;
`;

File.propTypes = propTypes;

export default File;
