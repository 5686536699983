import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Field } from 'react-final-form';
import createFocusDecorator from 'final-form-focus';
import { Redirect, withRouter } from 'react-router-dom';

import * as actions from 'actions';
import { getAuthStatus } from 'selectors';

import { Button, Paragraph, Well } from 'components/shared';
import { WellBody, WellHeader, WellFooter } from 'components/shared/Well';
import { FormFlex, FormBox, FormGroupAdapter, FormHeader, SubmitError } from 'components/shared/form';

import { validateRequired, getFrom } from 'utils';
import { EMAIL_FROM } from 'constants/views';

const propTypes = {
  authorized: PropTypes.bool,
  login: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

const defaultProps = {
  authorized: false,
};

const focusOnError = createFocusDecorator();

function FormAuthSignIn({ authorized, login, location }) {
  return (
    <StyledSignIn>
      {authorized && <Redirect to={getFrom(location)} />}
      <Form
        onSubmit={login}
        subscription={{ submitting: true, pristine: true, submitError: true }}
        decorators={[focusOnError]}
        render={({ handleSubmit, submitError, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Well>
              <WellHeader key="header">
                <FormHeader title="Sign in with your account" />
              </WellHeader>

              <WellBody key="body">
                {submitError && <StyledSubmitError error={submitError} />}

                <StyledFormFlex>
                  <FormBox width={1}>
                    <Field
                      autoFocus
                      name="username"
                      label="Login"
                      required
                      validate={validateRequired()}
                      component={FormGroupAdapter}
                    />
                  </FormBox>
                </StyledFormFlex>

                <StyledFormFlex>
                  <FormBox width={1}>
                    <Field
                      name="password"
                      label="Password"
                      type="password"
                      required
                      rightHint
                      validate={validateRequired()}
                      component={FormGroupAdapter}
                    />
                  </FormBox>
                </StyledFormFlex>
              </WellBody>

              <WellFooter key="footer">
                <StyledFormFlex flexDirection="column">
                  <FormBox width={1}>
                    <StyledParagraph fz={0.875}>
                      Your username is your email address and your password was sent to you in the welcome email when
                      you created your account. The email would have come from {EMAIL_FROM}. To reset your password
                      please click the forgot password link.
                    </StyledParagraph>
                  </FormBox>
                  <FormBox alignSelf="flex-end">
                    <Button type="submit" disabled={submitting} rounded>
                      Login
                    </Button>
                  </FormBox>
                </StyledFormFlex>
              </WellFooter>
            </Well>
          </form>
        )}
      />
    </StyledSignIn>
  );
}

const StyledFormFlex = styled(FormFlex)`
  max-width: 36rem;
`;

const StyledSubmitError = styled(SubmitError)`
  max-width: 36rem;
`;

const StyledParagraph = styled(Paragraph)`
  margin-top: 1em;
  margin-bottom: 1em;
  color: #504f4d;
`;

const StyledSignIn = styled.section`
  padding-top: 20px;
  padding-bottom: 40px;
`;

function mapStateToProps(state) {
  const authorized = getAuthStatus(state);

  return {
    authorized,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: bindActionCreators(actions.login, dispatch),
  };
}

FormAuthSignIn.propTypes = propTypes;
FormAuthSignIn.defaultProps = defaultProps;

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  pure,
);

export default enhance(FormAuthSignIn);
