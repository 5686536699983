import * as actionTypes from 'constants/actionTypes';

import { initialState } from 'selectors/processorNotes';

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case actionTypes.PROCESSOR_NOTES_FETCH_SUCCESS:
      return payload;
    default:
      return state;
  }
};
