import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ContentLoader from 'react-content-loader';

import { confirmEmail } from 'actions/session';
import { setConfirmEmailBanner } from 'utils/auth';

import ScrollToTopOnMount from 'hocs/ScrollToTopOnMount';
import { PageTemplate } from 'components/templates';
import { Congratulation, Strong, Wrapper } from 'components/shared';
import Well, { WellHeader, WellBody } from 'components/shared/Well';
import { FormHeader } from 'components/shared/form';

function ConfirmEmailPage() {
  const dispatch = useDispatch();
  const { token } = useParams();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);

  const onSuccess = () => {
    setIsLoading(false);
    setIsError(false);
    setConfirmEmailBanner(true);
  };

  const onError = () => {
    setIsLoading(false);
    setIsError(true);
  };

  React.useEffect(() => {
    dispatch(confirmEmail(token)).then(onSuccess).catch(onError);
  }, []);

  const render = () => {
    if (isLoading) {
      return <ContentLoader />;
    }
    if (isError) {
      return (
        <Well>
          <WellHeader key="header">
            <FormHeader title="Access Denied" />
          </WellHeader>

          <WellBody key="body">
            <Congratulation status="warning">
              <Strong>Something went wrong, try to repeat the confirmation request</Strong>
            </Congratulation>
          </WellBody>
        </Well>
      );
    }

    return (
      <Well>
        <WellHeader key="header">
          <FormHeader title="Congratulations!" />
        </WellHeader>

        <WellBody key="body">
          <Congratulation status="success">
            <Strong>Email has been successfully confirmed</Strong>
          </Congratulation>
        </WellBody>
      </Well>
    );
  };

  return (
    <PageTemplate>
      <Wrapper>
        {render()}
        <br />
      </Wrapper>
    </PageTemplate>
  );
}

export default ScrollToTopOnMount(ConfirmEmailPage);
