const theme = {};

theme.palette = {
  alert: ['#ffa000'],
  danger: ['#d32f2f', '#f44336', '#f8877f', '#fef5f6'],
  warning: ['#ffa000', '#fda81a', '#fdcd7d', '#fdf9f3'],
  grayscale: ['#212121', '#414141', '#616161', '#9e9e9e', '#bdbdbd', '#e0e0e0', '#eeeeee'],
  info: ['#5bc0de'],
  primary: ['#333', '#555'],
  secondary: ['#c2185b'],
  success: ['#388e3c'],
  white: ['#FFF'],
};

theme.fonts = {
  base: 'Open Sans, Helvetica Neue, Arial, sans-serif',
  // accent: 'Roboto, Georgia, Times New Roman, Times, serif',
  // pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
  // quote: 'Georgia, serif',
};

theme.sizes = {
  headings: {
    h1: '2.5rem',
    h2: '2rem',
    h3: '1.75rem',
    h4: '1.5rem',
    h5: '1.25rem',
    h6: '1rem',
  },
  font: {
    base: '1rem',
    lg: '1.25rem',
    sm: '0.875rem',
    xs: '0.75rem',
  },
};

theme.scale = [0.6, 0.875, 1, 1.25, 1.75];

theme.breakpoints = ['480px', '992px', '1200px', '1440px'];

theme.space = [0, 5, 10, 15, 20, 30];

export default theme;
