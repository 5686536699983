import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { compose, getContext, pure } from 'recompose';
import { connect } from 'react-redux';

import { FormBox, FormFlex, FormGroupAdapter } from 'components/shared/form';

import { getBridgeFullFormFirstStepRefs } from 'selectors';
import { validateRequired } from 'utils';

const propTypes = {
  isFormDisabled: PropTypes.bool.isRequired,
  refs: PropTypes.shape({
    availableToInvestCollection: PropTypes.arrayOf(PropTypes.object),
    realEstateTransactionsCountCollection: PropTypes.arrayOf(PropTypes.object),
  }),
};

const defaultProps = {
  refs: {
    availableToInvestCollection: [],
    realEstateTransactionsCountCollection: [],
  },
};

function Fields({ isFormDisabled, refs }) {
  return (
    <Fragment>
      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.borrower.realEstateTransactionsCount"
            label="Total Number of Prior RE Investments Completed"
            type="select"
            isDisabled={isFormDisabled}
            required
            options={refs.realEstateTransactionsCountCollection}
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.borrower.realEstateTransactionsCountLast12Months"
            label="# of Real Estate Investment Properties Purchased in the Last 12 Months"
            type="select"
            isDisabled={isFormDisabled}
            required
            options={refs.realEstateTransactionsCountCollection}
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.borrower.investmentPropertiesCountSoldLast36Months"
            label="Number of Fix/Flip or New Construction Properties Sold in the Last 36 Months"
            disabled={isFormDisabled}
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
            typeOfMask="number"
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.borrower.rentalPropertiesCount"
            label="Number of Rental Properties you Currently Own"
            disabled={isFormDisabled}
            component={FormGroupAdapter}
            typeOfMask="number"
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="application.borrower.availableToInvest"
            label="Available to Invest"
            type="select"
            isDisabled={isFormDisabled}
            required
            options={refs.availableToInvestCollection}
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.borrower.realEstateExperience"
            label="Please describe your investment experience and what markets are you active in?"
            type="textarea"
            disabled={isFormDisabled}
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>
    </Fragment>
  );
}

Fields.propTypes = propTypes;
Fields.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    refs: getBridgeFullFormFirstStepRefs(state),
  };
}

const enhance = compose(
  connect(mapStateToProps),
  getContext({ isFormDisabled: PropTypes.bool }),
  pure,
);

export default enhance(Fields);
