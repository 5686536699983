import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withHandlers, withProps, withState } from 'recompose';
import styled from 'styled-components';
import { space } from 'styled-system';
import { prop } from 'styled-tools';

import { getOptimizedResize, styleUtils } from 'utils';

import Icon from '../../Icon';
import { utils } from '../shared';

const propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node.isRequired,
  checkedIcon: PropTypes.string,
  uncheckedIcon: PropTypes.string,
  registerContent: PropTypes.func.isRequired,
  registerIcon: PropTypes.func.isRequired,
  className: PropTypes.string,
  iconHeight: PropTypes.number.isRequired,
  contentHeight: PropTypes.number.isRequired,
};

const defaultProps = {
  className: undefined,
  checked: false,
  checkedIcon: 'circleCheck',
  uncheckedIcon: 'circle',
};

function CheckListItem({
  checked,
  children,
  className,
  checkedIcon,
  uncheckedIcon,
  iconHeight,
  registerIcon,
  contentHeight,
  registerContent,
}) {
  return (
    <StyledCheckListItem alignItems={contentHeight >= iconHeight ? 'flex-start' : 'center'} className={className}>
      <div ref={registerIcon}>
        <Icon {...utils.getIconPalette(checked)} icon={checked ? checkedIcon : uncheckedIcon} />
      </div>
      <div ref={registerContent}>
        <ItemBody>{children}</ItemBody>
      </div>
    </StyledCheckListItem>
  );
}

const StyledCheckListItem = styled.li`
  display: flex;
  align-items: ${prop('alignItems', 'center')};
  margin-top: ${styleUtils.getSizeValue([1.328125, 1.5625, 1.953125])};
  &:first-child {
    margin-top: 0;
  }
  ${space};

  ${Icon} {
    flex-shrink: 0;
    display: block;
    margin: 0;
  }
`;

const ItemBody = styled.div``;

CheckListItem.propTypes = propTypes;
CheckListItem.defaultProps = defaultProps;

const enhance = compose(
  withProps({ optimizedResize: getOptimizedResize() }),
  withState('iconHeight', 'setIconHeight', 0),
  withState('contentHeight', 'setContentHeight', 0),
  withHandlers(() => {
    let resizeIconHandler;
    let resizeContentHandler;
    return {
      registerIcon: ({ optimizedResize, setIconHeight }) => (ref) => {
        if (ref) {
          resizeIconHandler = () => {
            if (!ref) return;
            setIconHeight(ref.clientHeight);
          };

          resizeIconHandler();
          optimizedResize.add(resizeIconHandler);
        } else {
          if (resizeIconHandler) optimizedResize.remove(resizeIconHandler);
          resizeIconHandler = undefined;
        }
      },
      registerContent: ({ optimizedResize, setContentHeight }) => (ref) => {
        if (ref) {
          resizeContentHandler = () => {
            if (!ref) return;
            setContentHeight(ref.clientHeight);
          };

          resizeContentHandler();
          optimizedResize.add(resizeContentHandler);
        } else {
          if (resizeContentHandler) optimizedResize.remove(resizeContentHandler);
          resizeContentHandler = undefined;
        }
      },
    };
  }),
  lifecycle({
    componentWillUnmount() {
      this.props.optimizedResize.clear();
    },
  }),
  pure,
);

export { ItemBody };
export default styled(enhance(CheckListItem))``;
