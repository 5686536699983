import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import applications from './applications';
import applicationTermSheet from './applicationTermSheet';
import conditions from './conditions';
import constructionBudget from './constructionBudget';
import contactTypes from './contactTypes';
import contacts from './contacts';
import documentCategories from './documentCategories';
import documents from './documents';
import drawRequests from './drawRequests';
import entities from './entities';
import paginate from './paginate';
import processorNotes from './processorNotes';
import refs from './refs';
import request from './request';
import schedules from './schedules';
import searchParams from './searchParams';
import session from './session';
import settings from './settings';

export default (history) =>
  combineReducers({
    applicationTermSheet,
    applications,
    conditions,
    constructionBudget,
    contactTypes,
    contacts,
    documentCategories,
    documents,
    drawRequests,
    entities,
    paginate,
    processorNotes,
    refs,
    request,
    router: connectRouter(history),
    schedules,
    searchParams,
    session,
    settings,
  });
