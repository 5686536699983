import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, palette } from 'styled-tools';
import ReactSelect, { AsyncCreatable as AsyncSelect } from 'react-select';
import * as R from 'ramda';

import SimpleValue from 'components/shared/SimpleValue';

const propTypes = {
  handlerChange: PropTypes.func,
  height: PropTypes.number,
  isClearable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  loadOptions: PropTypes.func,
  returnAll: PropTypes.bool,
  simple: PropTypes.bool,
};

const defaultProps = {
  handlerChange: undefined,
  height: 40,
  isClearable: true,
  options: [],
  returnAll: false,
  loadOptions: undefined,
  simple: true,
};

const fontSize = ({ height }) => `${height / 35.5555555556}rem`;

const parse = (option, simple) => (simple ? option.value : option);

function Select({
  defaultValue, value, loadOptions, onChange, options, returnAll, simple, ...rest
}) {
  const props = {
    classNamePrefix: 'react-select',
    onChange: (option) => onChange(option ? parse(option, simple) : undefined),
    ...rest,
  };

  if (simple) {
    return (
      <SimpleValue options={options} value={value} defaultValue={defaultValue}>
        {(simpleProps) => {
          if (loadOptions) {
            return <StyledAsycnSelect {...props} {...simpleProps} />;
          }
          return (
            <StyledSelect {...props} {...simpleProps} value={R.isNil(simpleProps.value) ? null : simpleProps.value} />
          );
        }}
      </SimpleValue>
    );
  }
  if (loadOptions) {
    return (
      <StyledAsycnSelect
        options={options}
        value={value}
        loadOptions={loadOptions}
        defaultValue={defaultValue}
        {...props}
      />
    );
  }
  return <StyledSelect options={options} value={value} defaultValue={defaultValue} {...props} />;
}

// TODO MULTI вынести для переиспользования
const style = css`
  font-size: ${fontSize};
  line-height: 1.42857;
  width: 100%;

  & .react-select__control {
    height: 2.5em;
    font-weight: normal;
    color: ${palette('grayscale', 0)};
    background-color: ${palette('white', 0)};
    border: 1px solid ${ifProp('invalid', palette('danger', 0), palette('grayscale', 3))};
    border-radius: 3px;
    box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  }

  & .react-select__control--is-disabled {
    cursor: not-allowed;
    background-color: #dadbdb;
    opacity: 1;
  }

  & .react-select__control--is-focused {
    border: 1px solid ${ifProp('invalid', palette('danger', 0), palette('primary', 0))};
    outline: 0;
  }

  & .react-select__menu {
    margin: 3px 0 0;
    font-weight: normal;
    font-size: 16px;
  }
`;

const StyledSelect = styled(ReactSelect)`
  ${style};
`;

const StyledAsycnSelect = styled(AsyncSelect)`
  ${style};
`;

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;
