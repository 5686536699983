import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import styled from 'styled-components';
import { ifProp } from 'styled-tools';
import { length, inc } from 'ramda';

import ProgressBar from 'components/shared/ProgressBar';

import withCalculatedProps from './hocs/withCalculatedProps';

const stepShape = PropTypes.shape({
  title: PropTypes.string,
  key: PropTypes.string,
  current: PropTypes.bool,
});

const propTypes = {
  barWidth: PropTypes.number,
  className: PropTypes.string,
  currentIdx: PropTypes.number,
  currentStep: stepShape,
  nextIdx: PropTypes.number,
  nextStep: stepShape,
  showNext: PropTypes.bool,
  showSteps: PropTypes.bool,
  steps: PropTypes.arrayOf(stepShape),
};

const defaultProps = {
  barWidth: 0,
  className: undefined,
  currentIdx: -1,
  currentStep: undefined,
  nextIdx: -1,
  nextStep: undefined,
  showNext: false,
  showSteps: false,
  steps: [],
};

function ApplicationProgressBar({
  barWidth,
  className,
  currentIdx,
  currentStep,
  nextIdx,
  nextStep,
  showNext,
  showSteps,
  steps,
}) {
  return (
    <div className={className}>
      <ProgressBar width={barWidth} />
      {currentStep && (
        <Info>
          <div>
            <Step>{steps[currentIdx].title}</Step>{' '}
            {showSteps && (
              <Minor>
                {inc(currentIdx)} stap of {length(steps)}
              </Minor>
            )}
          </div>
          {showNext && nextStep && (
            <div>
              <Minor>Pending</Minor> <Step next>{steps[nextIdx].title}</Step>
            </div>
          )}
        </Info>
      )}
    </div>
  );
}

const Step = styled.span`
  font-size: 1rem;
  font-weight: lighter;

  color: ${ifProp('next', '#D88E00', '#00BD95')};
`;

const Minor = styled.span`
  opacity: 0.45;
  font-size: 0.75rem;
  color: #000;
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.6875rem;
`;

const StyledApplicationProgressBar = styled(ApplicationProgressBar)``;

ApplicationProgressBar.propTypes = propTypes;
ApplicationProgressBar.defaultProps = defaultProps;
ApplicationProgressBar.Bar = ProgressBar;
ApplicationProgressBar.Step = Step;
ApplicationProgressBar.Minor = Minor;
ApplicationProgressBar.Info = Info;

const enhance = compose(
  withCalculatedProps,
  pure,
);

export default enhance(StyledApplicationProgressBar);
