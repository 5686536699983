import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { compose, getContext, pure } from 'recompose';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getBridgeFullFormThirdStepRefs, getCurrentInvestmentPropertiesDocumentCategory } from 'selectors';

import { Hr } from 'components/shared';
import {
  Condition,
  FormBox,
  FormGroup,
  FormGroupAdapter,
  FormFlex,
  FormLegend,
  RadioGroupAdapter,
} from 'components/shared/form';
import { Radio } from 'components/shared/formControls';
import { DocCategory } from 'components/blocks';

import {
  composeValidators,
  validateDate,
  validateRequired,
  yearsRangeCollection,
  validateRequiredByConditon,
} from 'utils';

import useIsOwner from 'components/Application/StepsPage/hooks/useIsOwner';

const propTypes = {
  currentInvestmentPropertiesDocumentCategory: PropTypes.objectOf(PropTypes.any),
  isFormDisabled: PropTypes.bool.isRequired,
  refs: PropTypes.shape({
    countriesCollection: PropTypes.arrayOf(PropTypes.object),
    creditScoresCollection: PropTypes.arrayOf(PropTypes.object),
    ownRentCollection: PropTypes.arrayOf(PropTypes.object),
    statesCollection: PropTypes.arrayOf(PropTypes.object),
  }),
};

const defaultProps = {
  currentInvestmentPropertiesDocumentCategory: undefined,
  refs: {
    countriesCollection: [],
    creditScoresCollection: [],
    ownRentCollection: [],
    statesCollection: [],
  },
};

const yearsCollection = yearsRangeCollection(-50);

function Fields({ isFormDisabled, currentInvestmentPropertiesDocumentCategory, refs }) {
  const isOwner = useIsOwner('application.borrower.owner');

  return (
    <Fragment>
      <FormFlex>
        <FormBox mb="1rem">
          <FormLegend>Primary Residence</FormLegend>
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.borrower.countryOfResidence"
            type="select"
            options={refs.countriesCollection}
            required
            isDisabled={isFormDisabled}
            label="Country of Residence"
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="application.borrower.street1"
            required
            label="Address 1"
            disabled={isFormDisabled}
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="application.borrower.street2"
            label="Address 2"
            disabled={isFormDisabled}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name="application.borrower.city"
            required
            label="City"
            disabled={isFormDisabled}
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <Condition when="application.borrower.countryOfResidence" is="US">
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              isConditional
              name="application.borrower.stateCode"
              label="Your State"
              type="select"
              required
              isDisabled={isFormDisabled}
              options={refs.statesCollection}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </Condition>
        <Condition when="application.borrower.countryOfResidence" reverse is="US">
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              isConditional
              name="application.borrower.province"
              label="State/Province"
              disabled={isFormDisabled}
              component={FormGroupAdapter}
            />
          </FormBox>
        </Condition>
        <Condition when="application.borrower.countryOfResidence" is="US">
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              name="application.borrower.postalCode"
              label="ZIP"
              required
              disabled={isFormDisabled}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </Condition>
        <Condition when="application.borrower.countryOfResidence" reverse is="US">
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              name="application.borrower.postalCode"
              label="Postal Code"
              required
              disabled={isFormDisabled}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </Condition>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.borrower.owner"
            type="select"
            options={refs.ownRentCollection}
            required
            isDisabled={isFormDisabled}
            label="Own or Rent?"
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.borrower.birthDate"
            type="date"
            required
            label="Date Of Birth"
            disabled={isFormDisabled}
            validate={composeValidators(validateRequired(), validateDate())}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.borrower.ssn"
            typeOfMask="ssn"
            required
            hint={<TextDanger>We will not run your credit until you have spoken to a loan officer.</TextDanger>}
            label="SSN"
            disabled={isFormDisabled}
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.borrower.creditScore"
            type="select"
            options={refs.creditScoresCollection}
            required
            label="Credit Score"
            isDisabled={isFormDisabled}
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox mb="1rem">
          <FormLegend>Income</FormLegend>
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            component={RadioGroupAdapter}
            disabled={isFormDisabled}
            inline
            label="Are You Self Employed?"
            name="application.borrower.selfEmployed"
            required
            type="checkbox"
            validate={validateRequired()}
          >
            <Radio value="true" id="application.borrower.selfEmployedTrue" label="Yes" />
            <Radio value="false" id="application.borrower.selfEmployedFalse" label="No" />
          </Field>
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.borrower.employerName"
            required
            label="Employer Name"
            disabled={isFormDisabled}
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.borrower.businessPhone"
            typeOfMask="phone"
            label="Business Phone"
            disabled={isFormDisabled}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.borrower.numberOfYearsWithEmployer"
            label="Number of Years with Employer"
            component={FormGroupAdapter}
            disabled={isFormDisabled}
            typeOfMask="number"
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.borrower.occupation"
            required
            validate={validateRequired()}
            label="Occupation"
            disabled={isFormDisabled}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name="application.borrower.totalAnnualIncome"
            required
            typeOfMask="currency"
            validate={validateRequired()}
            label="Annual Total Income"
            disabled={isFormDisabled}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox mb="1rem">
          <FormLegend>Cash/Liquid Investments</FormLegend>
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name="application.borrower.checkingSavingsAccounts"
            required
            typeOfMask="currency"
            validate={validateRequired()}
            label="Checking/Savings Accounts"
            disabled={isFormDisabled}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name="application.borrower.retirementAccounts"
            typeOfMask="currency"
            label="Retirement Accounts"
            disabled={isFormDisabled}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox mb="1rem">
          <FormLegend>Investment Properties</FormLegend>
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name="application.borrower.investmentPropertiesMarketValue"
            required
            typeOfMask="currency"
            validate={validateRequired()}
            disabled={isFormDisabled}
            label="Current value of Investment Properties Owned"
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name="application.borrower.investmentPropertiesMortgageBalance"
            required
            typeOfMask="currency"
            validate={validateRequired()}
            disabled={isFormDisabled}
            label="Existing mortgage(s) on investment properties"
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name="application.borrower.investmentPropertiesAnnualGrossRent"
            typeOfMask="currency"
            label="Annual Gross Rent"
            disabled={isFormDisabled}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name="application.borrower.investmentPropertiesAnnualExpenses"
            typeOfMask="currency"
            label="Annual Expenses (Taxes, Ins, Etc)"
            disabled={isFormDisabled}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name="application.borrower.investmentPropertiesAnnualMortgagePayments"
            typeOfMask="currency"
            label="Annual Mortgage Payments"
            disabled={isFormDisabled}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name="application.borrower.investmentPropertiesMortgagedCount"
            typeOfMask="number"
            label="# of Properties with mortgages"
            disabled={isFormDisabled}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      {currentInvestmentPropertiesDocumentCategory && (
        <Field name="application.borrower.profileId" subscription={{ value: true }}>
          {({ input: { value } }) =>
            value && (
              <FormFlex>
                <FormBox width={1}>
                  <FormGroup>
                    <DocCategory
                      isDisabled={isFormDisabled}
                      targetId={value}
                      category={currentInvestmentPropertiesDocumentCategory}
                    />
                  </FormGroup>
                </FormBox>
              </FormFlex>
            )
          }
        </Field>
      )}

      <FormFlex>
        <FormBox width={1} mb="1rem">
          <Hr />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox mb="1rem">
          <FormLegend>Primary Residence and Secondary Homes (Not Investments)</FormLegend>
        </FormBox>
      </FormFlex>

      <Condition when="application.borrower.owner" is="rent">
        <FormFlex>
          <FormBox width={1}>
            <Field
              isConditional
              name="application.borrower.notInvestmentPropertiesMonthlyRent"
              label="Monthly Rent"
              typeOfMask="currency"
              required
              disabled={isFormDisabled}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>
      </Condition>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.borrower.notInvestmentPropertiesMarketValue"
            typeOfMask="currency"
            label="Current Value of House(s)"
            required={isOwner}
            validate={validateRequiredByConditon({ isRequired: isOwner })}
            disabled={isFormDisabled}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.borrower.notInvestmentPropertiesMortgageBalance"
            typeOfMask="currency"
            label="Existing Mortgage(s)"
            disabled={isFormDisabled}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.borrower.notInvestmentPropertiesMonthlyMortgagePayment"
            typeOfMask="currency"
            label="Monthly Mortgage Payment(s)"
            disabled={isFormDisabled}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox mb="1rem">
          <FormLegend>Personal Debt</FormLegend>
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.borrower.totalDebtAmount"
            typeOfMask="currency"
            required
            validate={validateRequired()}
            disabled={isFormDisabled}
            label="Total amount of Personal Debt (Credit card, student loans, auto, personal loans, etc). Do not include mortgages."
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.borrower.monthlyDebtPayment"
            typeOfMask="currency"
            required
            validate={validateRequired()}
            disabled={isFormDisabled}
            label="Monthly Payment(s) on Personal Debt (excluding mortgage)"
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.borrower.monthlyOtherDebtsPayment"
            typeOfMask="currency"
            label="Total Combined Monthly Payment of Other Debts"
            disabled={isFormDisabled}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox mb="1rem">
          <FormLegend>Misc</FormLegend>
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            component={RadioGroupAdapter}
            disabled={isFormDisabled}
            inline
            label="Are you a defendant in any suits, legal actions or judgments?"
            name="application.borrower.defendantLegalActions"
            required
            type="checkbox"
            validate={validateRequired()}
          >
            <Radio value="true" id="application.borrower.defendantLegalActionsTrue" label="Yes" />
            <Radio value="false" id="application.borrower.defendantLegalActionsFalse" label="No" />
          </Field>
        </FormBox>
      </FormFlex>

      <Condition when="application.borrower.defendantLegalActions" is="true">
        <FormFlex>
          <FormBox width={1}>
            <Field
              isConditional
              name="application.borrower.defendantLegalActionsDescription"
              label="Description"
              type="textarea"
              required
              disabled={isFormDisabled}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>
      </Condition>

      <FormFlex>
        <FormBox width={1} mb="1rem">
          <Hr />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            component={RadioGroupAdapter}
            disabled={isFormDisabled}
            inline
            label="Have you ever defaulted on a loan or financial obligation?"
            name="application.borrower.defaultedLoanObligation"
            required
            type="checkbox"
            validate={validateRequired()}
          >
            <Radio value="true" id="application.borrower.defaultedLoanObligationTrue" label="Yes" />
            <Radio value="false" id="application.borrower.defaultedLoanObligationFalse" label="No" />
          </Field>
        </FormBox>
      </FormFlex>

      <Condition when="application.borrower.defaultedLoanObligation" is="true">
        <FormFlex>
          <FormBox width={1}>
            <Field
              isConditional
              name="application.borrower.defaultedLoanObligationDescription"
              label="Description"
              type="textarea"
              required
              disabled={isFormDisabled}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>
      </Condition>

      <FormFlex>
        <FormBox width={1} mb="1rem">
          <Hr />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            component={RadioGroupAdapter}
            disabled={isFormDisabled}
            inline
            label="Have you or your spouse ever been the subject of bankruptcy proceedings?"
            name="application.borrower.bankruptcyProceedings"
            required
            type="checkbox"
            validate={validateRequired()}
          >
            <Radio value="true" id="application.borrower.bankruptcyProceedingsTrue" label="Yes" />
            <Radio value="false" id="application.borrower.bankruptcyProceedingsFalse" label="No" />
          </Field>
        </FormBox>
      </FormFlex>

      <Condition when="application.borrower.bankruptcyProceedings" is="true">
        <FormFlex>
          <FormBox width={1}>
            <Field
              isConditional
              name="application.borrower.bankruptcyProceedingsYear"
              label="Year"
              type="select"
              required
              isDisabled={isFormDisabled}
              options={yearsCollection}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>
        <FormFlex>
          <FormBox width={1}>
            <Field
              isConditional
              name="application.borrower.bankruptcyProceedingsDescription"
              label="Description"
              type="textarea"
              required
              disabled={isFormDisabled}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>
      </Condition>

      <FormFlex>
        <FormBox width={1} mb="1rem">
          <Hr />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            component={RadioGroupAdapter}
            disabled={isFormDisabled}
            inline
            label="Have you entered into a forbearance program in the last 2 years?"
            name="application.borrower.forbearanceProgram"
            required
            type="checkbox"
            validate={validateRequired()}
          >
            <Radio value="true" id="application.borrower.forbearanceProgramTrue" label="Yes" />
            <Radio value="false" id="application.borrower.forbearanceProgramFalse" label="No" />
          </Field>
        </FormBox>
      </FormFlex>

      <Condition when="application.borrower.forbearanceProgram" is="true">
        <FormFlex>
          <FormBox width={1}>
            <Field
              isConditional
              name="application.borrower.forbearanceProgramYear"
              label="Year"
              type="select"
              required
              isDisabled={isFormDisabled}
              options={yearsCollection}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>
        <FormFlex>
          <FormBox width={1}>
            <Field
              isConditional
              name="application.borrower.forbearanceProgramDescription"
              label="Description"
              type="textarea"
              required
              disabled={isFormDisabled}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>
      </Condition>

      <FormFlex>
        <FormBox width={1} mb="1rem">
          <Hr />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            component={RadioGroupAdapter}
            disabled={isFormDisabled}
            inline
            label="Have you had a property foreclosed or short-saled?"
            name="application.borrower.foreclosedProperty"
            required
            type="checkbox"
            validate={validateRequired()}
          >
            <Radio value="true" id="application.borrower.foreclosedPropertyTrue" label="Yes" />
            <Radio value="false" id="application.borrower.foreclosedPropertyFalse" label="No" />
          </Field>
        </FormBox>
      </FormFlex>

      <Condition when="application.borrower.foreclosedProperty" is="true">
        <FormFlex>
          <FormBox width={1}>
            <Field
              isConditional
              name="application.borrower.foreclosedPropertyYear"
              label="Year"
              type="select"
              required
              isDisabled={isFormDisabled}
              options={yearsCollection}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>
        <FormFlex>
          <FormBox width={1}>
            <Field
              isConditional
              name="application.borrower.foreclosedPropertyDescription"
              label="Description"
              type="textarea"
              required
              disabled={isFormDisabled}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>
      </Condition>

      <FormFlex>
        <FormBox width={1} mb="1rem">
          <Hr />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            component={RadioGroupAdapter}
            disabled={isFormDisabled}
            inline
            label="Have you ever been convicted of a crime?"
            name="application.borrower.convictedOfCrime"
            required
            type="checkbox"
            validate={validateRequired()}
          >
            <Radio value="true" id="application.borrower.convictedOfCrimeTrue" label="Yes" />
            <Radio value="false" id="application.borrower.convictedOfCrimeFalse" label="No" />
          </Field>
        </FormBox>
      </FormFlex>

      <Condition when="application.borrower.convictedOfCrime" is="true">
        <FormFlex>
          <FormBox width={1}>
            <Field
              isConditional
              name="application.borrower.convictedOfCrimeDescription"
              label="Description"
              type="textarea"
              required
              disabled={isFormDisabled}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>
      </Condition>

      <FormFlex>
        <FormBox width={1} mb="1rem">
          <Hr />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            component={RadioGroupAdapter}
            disabled={isFormDisabled}
            inline
            label="Are you a U.S. citizen?"
            name="application.borrower.usCitizen"
            required
            type="checkbox"
            validate={validateRequired({ message: 'is not included in the list' })}
          >
            <Radio value="true" id="application.borrower.usCitizenTrue" label="Yes" />
            <Radio value="false" id="application.borrower.usCitizenFalse" label="No" />
          </Field>
        </FormBox>
      </FormFlex>

      <Condition when="application.borrower.usCitizen" is="false">
        <FormFlex>
          <FormBox width={1}>
            <Field
              isConditional
              name="application.borrower.countryOfCitizenship"
              type="select"
              options={refs.countriesCollection}
              required
              label="Country of Citizenship"
              isDisabled={isFormDisabled}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>
        <FormFlex>
          <FormBox width={1}>
            <Field
              isConditional
              name="application.borrower.visaStatus"
              required
              label="Visa Status"
              disabled={isFormDisabled}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>
        <FormFlex>
          <FormBox width={1}>
            <Field
              component={RadioGroupAdapter}
              disabled={isFormDisabled}
              inline
              isConditional
              label="Are you a permanent resident?"
              name="application.borrower.permanentResident"
              required
              type="checkbox"
              validate={validateRequired({ message: 'is not included in the list' })}
            >
              <Radio value="true" id="application.borrower.permanentResidentTrue" label="Yes" />
              <Radio value="false" id="application.borrower.permanentResidentFalse" label="No" />
            </Field>
          </FormBox>
        </FormFlex>
      </Condition>

      <FormFlex>
        <FormBox width={1} mb="1rem">
          <Hr />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            component={RadioGroupAdapter}
            disabled={isFormDisabled}
            inline
            label="Is there any explanation of your income / credit / assets you would like to provide?"
            name="application.borrower.explanationOfIncome"
            required
            type="checkbox"
            validate={validateRequired()}
          >
            <Radio value="true" id="application.borrower.explanationOfIncomeTrue" label="Yes" />
            <Radio value="false" id="application.borrower.explanationOfIncomeFalse" label="No" />
          </Field>
        </FormBox>
      </FormFlex>

      <Condition when="application.borrower.explanationOfIncome" is="true">
        <FormFlex>
          <FormBox width={1}>
            <Field
              isConditional
              name="application.borrower.explanationOfIncomeDescription"
              label="Description"
              type="textarea"
              required
              disabled={isFormDisabled}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>
      </Condition>

      <FormFlex>
        <FormBox width={1} mb="1rem">
          <Hr />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            component={RadioGroupAdapter}
            disabled={isFormDisabled}
            inline
            label="Are any of your assets held in a different name or trust?"
            name="application.borrower.assetsHeldDifferentName"
            required
            type="checkbox"
            validate={validateRequired()}
          >
            <Radio value="true" id="application.borrower.assetsHeldDifferentNameTrue" label="Yes" />
            <Radio value="false" id="application.borrower.assetsHeldDifferentNameFalse" label="No" />
          </Field>
        </FormBox>
      </FormFlex>

      <Condition when="application.borrower.assetsHeldDifferentName" is="true">
        <FormFlex>
          <FormBox width={1}>
            <Field
              isConditional
              name="application.borrower.assetsHeldDifferentNameDescription"
              label="Description"
              type="textarea"
              required
              disabled={isFormDisabled}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>
      </Condition>
    </Fragment>
  );
}

Fields.propTypes = propTypes;
Fields.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    currentInvestmentPropertiesDocumentCategory: getCurrentInvestmentPropertiesDocumentCategory(state),
    refs: getBridgeFullFormThirdStepRefs(state),
  };
}

const TextDanger = styled.span`
  color: #d94045;
  font-weight: 700;
`;

const enhance = compose(
  withRouter,
  getContext({ isFormDisabled: PropTypes.bool }),
  connect(mapStateToProps),
  pure,
);

export default enhance(Fields);
