import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Tab from 'components/shared/Tab';

const propTypes = {
  center: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  right: PropTypes.bool,
};

const defaultProps = {
  center: false,
  className: null,
  right: false,
};

function Tabs({ center, children, className, right }) {
  return (
    <Container center={center} right={right} className={className}>
      {children}
    </Container>
  );
}

const Container = styled.ul`
  display: flex;
  justify-content: ${({ center, right }) => {
    if (center) return 'center';
    if (right) return 'flex-end';
    return 'flex-start';
  }};
  margin: 0;
  padding: 0;
  list-style-type: none;
  border-bottom: 0.0625rem solid #EBEBEB;

  ${Tab} {
    margin-left: 1.875rem;
    &:first-child {
      margin-left: 0;
    }
  }
`;

Tabs.propTypes = propTypes;
Tabs.defaultProps = defaultProps;

export default Tabs;
