import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers } from 'recompose';
import debounce from 'lodash.debounce';

import Select from 'components/shared/formControls/Select';

import IndicatorsContainer from './IndicatorsContainer';

const propTypes = {
  loadOptions: PropTypes.func.isRequired,
};

const debounced = debounce(
  (fn, query, callback) => {
    fn(query, callback);
  },
  400,
  { leading: false, trailing: true },
);

function AddressSelect({ loadOptions, ...rest }) {
  return (
    <Select
      {...rest}
      openMenuOnFocus={false}
      openMenuOnClick={false}
      simple={false}
      loadOptions={(query, callback) => debounced(loadOptions, query, callback)}
      components={{ IndicatorsContainer }}
    />
  );
}

AddressSelect.propTypes = propTypes;

const enhance = compose(
  withHandlers({
    loadOptions: () => (query, callback) => {
      if (window.google && window.google.maps) {
        const geocoder = new window.google.maps.Geocoder();
        geocoder
          .geocode({
            address: query,
            componentRestrictions: {
              country: 'US',
            },
          })
          .then(({ results }) => {
            if (!results) return;
            const options = results.map((result) => {
              result.partial_match = true;
              return { label: result.formatted_address, value: result.formatted_address, result };
            });
            callback(options);
          })
          .catch(() => callback([]));
      }
    },
  }),
  pure,
);

export default enhance(AddressSelect);
