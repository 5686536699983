import moment from 'moment';
import * as R from 'ramda';

import { dateFormat } from 'utils';

export const applicationDisplayType = (application) => {
  if (R.isNil(application)) return undefined;
  switch (application.type) {
    case 'bridge':
      return 'Fix/Flip or Bridge Loan';
    case 'credit':
      return 'Establish a Line Credit (For clients working on more than 5 transactions a year)';
    case 'pre_approval':
      return 'Become Pre-Approved';
    case 'rental':
      return 'Rental Loan';
    case 'infomration':
      return 'Seeking more information';
    case 'broker':
      return 'Broker looking for more information';
    default:
      return application.type;
  }
};

export const applicationDisplayShortType = (application) => {
  if (R.isNil(application)) return undefined;
  switch (application.type) {
    case 'bridge':
      return 'Bridge';
    case 'credit':
      return 'Credit';
    case 'pre_approval':
      return 'Pre Approval';
    case 'rental':
      return 'Rental';
    case 'infomration':
      return 'Information';
    case 'broker':
      return 'Broker';
    default:
      return application.type;
  }
};

export const applicationDisplayNewConstructionType = (application, newConstructionProductList) => {
  if (Array.isArray(newConstructionProductList) && newConstructionProductList.length > 0) {
    const matchingNewConstrProduct = newConstructionProductList.find(product => product.value === application.productId);
    return matchingNewConstrProduct ? "New Construction" : null;
  }
  return null;
}

export const applicationDisplaySubmittedOn = (a) => (a ? dateFormat(a.createdAt) : undefined);

export const applicationDisplaySubmittedBy = (a) => (a ? dateFormat(a.createdAt) : undefined);

export function displayApplication(application, newConstructionProductList) {
  if (application) {
    return {
      ...application,
      isBridge: application.type === 'bridge',
      isRental: application.type === 'rental',
      isPreApproval: application.type === 'pre_approval',
      isCredit: application.type === 'credit',
      displayNewConstructionType: applicationDisplayNewConstructionType(application, newConstructionProductList),
      displayType: applicationDisplayType(application),
      displayShortType: applicationDisplayShortType(application),
      displaySubmittedBy: applicationDisplaySubmittedBy(application),
      displaySubmittedOn: applicationDisplaySubmittedOn(application),
    };
  }

  return undefined;
}

export function filterApplicationsByStatus(applications, status) {
  switch (status) {
    case 'all':
      return applications;
    case 'active':
      return applications.filter((application) => application.label === 'current');
    default:
      return applications.filter((application) => application.label === status);
  }
}

export function sortApplicationCollection(collection) {
  function byUdpatedAt(application) {
    const { udpatedAt } = application;
    return moment(udpatedAt).valueOf();
  }

  function byPaidOff(application) {
    const { paymentStatus } = application;
    const isPaidOff = paymentStatus === 'paid_off';
    return isPaidOff ? 1 : 0;
  }

  return R.sortWith([
    R.ascend(byPaidOff),
    R.ascend(byUdpatedAt),
  ])(collection);
}
