import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';
import * as R from 'ramda';

import { FormFlex, FormBox } from 'components/shared/form';
import styled from 'styled-components';
import {
  getActualTypesCollectionRefs,
  getApplicationByToken,
  getApplicationPrimaryBorrower,
  getApplicationPropertyById,
} from 'selectors';

import { getIn } from 'utils';

const propTypes = {
  data: PropTypes.shape({}),
  availablePropertyTypes: PropTypes.arrayOf(PropTypes.shape({})),
};

const defaultProps = {
  data: {},
  availablePropertyTypes: [],
};

function ConstructionBudgetShow(props) {
  const { data, availablePropertyTypes, primaryBorrower, property } = props;
  const propertyType = R.find(R.pipe(getIn('value'), R.equals(data.type)), availablePropertyTypes);
  const proposedPropertyType = R.find(
    R.pipe(getIn('value'), R.equals(data.proposedType)),
    availablePropertyTypes,
  );
  return (
    <FormFlex alignItems="center" justifyContent="flex-start">
      <FormBox pl={3} pr={3}>
        <p>
          <b>Property Address:</b> {property.address || 'N/A'}
        </p>
        <p>
          <b>Borrower Name:</b>{' '}
          {(primaryBorrower && `${primaryBorrower.firstName || ''} ${primaryBorrower.lastName || ''}`) || 'N/A'}
        </p>
        <br />
        <StyledConstructionWorksTable>
          <thead>
            <tr>
              <th />
              <th>Current Property</th>
              <th>Proposed</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Property Type</td>
              <td>{data.type && propertyType && propertyType.label}</td>
              <td>
                <div className="form__group">
                  {(data.proposedType && proposedPropertyType && proposedPropertyType.label) || 'N/A'}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <nobr>Square footage</nobr>
              </td>
              <td>{data.displaySize || 'N/A'}</td>
              <td>
                <div className="form__group">{data.proposedSize || 'N/A'}</div>
              </td>
            </tr>
            <tr>
              <td>
                <nobr># of Bedrooms</nobr>
              </td>
              <td>{data.displayBedroomsCount || 'N/A'}</td>
              <td>
                <div className="form__group">{data.proposedBedroomsCount || 'N/A'}</div>
              </td>
            </tr>
            <tr>
              <td>
                <nobr># of Baths</nobr>
              </td>
              <td>{data.displayBathroomsCount || 'N/A'}</td>
              <td>
                <div className="form__group">{data.proposedBathroomsCount || 'N/A'}</div>
              </td>
            </tr>
          </tbody>
        </StyledConstructionWorksTable>
        <br />
        <div>
          <b>Total Budget:</b> {data.totalConstructionBudget || 'N/A'}
        </div>
        <br />

        <div className="form__group">
          <label className="form__label">Overall Description of the Work: </label>
          {data.constructionBudgetDescription || 'N/A'}
        </div>
        <br />
        {(!data.constructionWorks || data.constructionWorks.length === 0) && <div>No Construction Works Added</div>}
        {data.constructionWorks &&
          data.constructionWorks.length > 0 && (
            <StyledConstructionWorksTable className="table table-striped repair_budget_form__works">
              <thead>
                <tr>
                  <th>Construction Item</th>
                  <th>
                    <nobr>Description of Work</nobr>
                  </th>
                  <th className="text-right">Total</th>
                </tr>
              </thead>
              <tbody>
                {data.constructionWorks.map((work, index) => (
                  <tr key={index}>
                    <td>
                      <div className="form__group">{work.categoryTitle}</div>
                    </td>
                    <td>
                      <div className="form__group">{work.description || 'N/A'}</div>
                    </td>
                    <td className="text-right">{work.amount}</td>
                  </tr>
                ))}
              </tbody>
            </StyledConstructionWorksTable>
          )}
      </FormBox>
    </FormFlex>
  );
}

const StyledConstructionWorksTable = styled.table`
  width: 100%;
  & th {
    padding: 5px 10px;
    vertical-align: top;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  & td {
    padding: 5px 10px;
    vertical-align: top;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
`;

ConstructionBudgetShow.propTypes = propTypes;
ConstructionBudgetShow.defaultProps = defaultProps;

const mapStateToProps = (state, props) => {
  const { token } = props.match.params;
  const application = getApplicationByToken(state, token);
  return {
    availablePropertyTypes: getActualTypesCollectionRefs(state),
    primaryBorrower: getApplicationPrimaryBorrower(state),
    property: getApplicationPropertyById(state, props),
    application,
  };
};

const enhance = compose(withRouter, connect(mapStateToProps), pure);

export default enhance(ConstructionBudgetShow);
