import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure, withHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dimensions from 'react-dimensions';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';

import * as actions from 'actions';
import { getSchedulesCollectionByGroup, isAtLeastOneAvailableToDeleteByGroup } from 'selectors';

import Button from 'components/shared/Button';

import { getIn } from 'utils';
import ScrollToTopOnMount from 'hocs/ScrollToTopOnMount';

import { CurrencyInput, PercentInput, TextInput, DateInput, AddressCell } from '../shared';

const propTypes = {
  collection: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  containerWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  group: PropTypes.string.isRequired,
  handleAdd: PropTypes.func.isRequired,
  handleAddressChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isAtLeastOneAvailableToDeleteByGroupCollection: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  onDelete: PropTypes.func.isRequired,
  targetId: PropTypes.string.isRequired,
  targetType: PropTypes.string.isRequired,
  title: PropTypes.string,
  meta: PropTypes.objectOf(PropTypes.bool),
};

const defaultProps = {
  collection: [],
  isAtLeastOneAvailableToDeleteByGroupCollection: [],
  title: undefined,
  meta: {
    availableToAdd: true,
  },
};

function ScheduleTable({
  collection,
  containerWidth,
  group,
  handleAdd,
  handleAddressChange,
  handleChange,
  isAtLeastOneAvailableToDeleteByGroupCollection,
  onDelete,
  targetId,
  targetType,
  title,
  meta,
}) {
  const { availableToAdd } = meta;
  const basicColumns = [
    <Column
      header={
        <Cell className="reschedule_table__hcell">
          <div>
            <div>
              <span className="required_field_badge" /> Street Address
            </div>
          </div>
        </Cell>
      }
      columnKey="address"
      key="address"
      cell={({ rowIndex }) => {
        const {
          editable,
          edidableOnAdd,
          meta: { availableToEdit },
        } = collection[rowIndex];
        const text = collection[rowIndex].address;
        return (
          <AddressCell
            className="left"
            editable={editable || edidableOnAdd === 'address'}
            value={text}
            handleAddressChange={handleAddressChange}
            initialValues={collection[rowIndex]}
            readOnly={!availableToEdit}
          />
        );
      }}
      width={350}
    />,
    <Column
      header={<Cell className="reschedule_table__hcell">Owner on Title</Cell>}
      columnKey="title_name"
      key="title_name"
      cell={({ rowIndex }) => {
        const {
          key,
          editable,
          meta: { availableToEdit },
        } = collection[rowIndex];
        const text = collection[rowIndex].titleName;
        return (
          <TextInput
            readOnly={!availableToEdit}
            value={text}
            type="text"
            editable={editable}
            className="left"
            onChange={(value) => handleChange(value, key, 'title_name', collection[rowIndex])}
          />
        );
      }}
      width={250}
    />,
    <Column
      header={<Cell className="reschedule_table__hcell right">%</Cell>}
      columnKey="ownership_percent"
      key="ownership_percent"
      cell={({ rowIndex }) => {
        const {
          key,
          editable,
          meta: { availableToEdit },
        } = collection[rowIndex];
        const text = collection[rowIndex].ownershipPercent;
        return (
          <PercentInput
            readOnly={!availableToEdit}
            value={text}
            type="text"
            editable={editable}
            className="right"
            onChange={(value) => handleChange(value, key, 'ownership_percent', collection[rowIndex])}
          />
        );
      }}
      width={110}
    />,
    <Column
      header={<Cell className="reschedule_table__hcell">Purchase Date</Cell>}
      columnKey="purchase_date"
      key="purchase_date"
      cell={({ rowIndex }) => {
        const {
          key,
          editable,
          meta: { availableToEdit },
        } = collection[rowIndex];
        const text = collection[rowIndex].purchaseDate;
        return (
          <DateInput
            readOnly={!availableToEdit}
            value={text ? moment(text).format('MM/DD/YYYY') : undefined}
            editable={editable}
            placeholder="Select date"
            onChange={(value) => handleChange(value, key, 'purchase_date', collection[rowIndex])}
          />
        );
      }}
      width={180}
    />,
  ];

  const sharedColumns = [
    <Column
      header={<Cell className="reschedule_table__hcell right">Market Value</Cell>}
      columnKey="current_market_value"
      key="current_market_value"
      cell={({ rowIndex }) => {
        const {
          key,
          editable,
          meta: { availableToEdit },
        } = collection[rowIndex];
        const text = collection[rowIndex].currentMarketValue;
        return (
          <CurrencyInput
            readOnly={!availableToEdit}
            value={text}
            editable={editable}
            className="right"
            onChange={(value) => handleChange(value, key, 'current_market_value', collection[rowIndex])}
          />
        );
      }}
      width={150}
    />,
    <Column
      header={<Cell className="reschedule_table__hcell">Lender</Cell>}
      columnKey="lender_name"
      key="lender_name"
      cell={({ rowIndex }) => {
        const {
          key,
          editable,
          meta: { availableToEdit },
        } = collection[rowIndex];
        const text = collection[rowIndex].lenderName;
        return (
          <TextInput
            readOnly={!availableToEdit}
            value={text}
            type="text"
            editable={editable}
            className="left"
            onChange={(value) => handleChange(value, key, 'lender_name', collection[rowIndex])}
          />
        );
      }}
      width={100}
    />,
    <Column
      header={<Cell className="reschedule_table__hcell right">Original Mortgage Balance</Cell>}
      columnKey="total_mortgage_amount"
      key="total_mortgage_amount"
      cell={({ rowIndex }) => {
        const {
          key,
          editable,
          meta: { availableToEdit },
        } = collection[rowIndex];
        const text = collection[rowIndex].totalMortgageAmount;
        return (
          <CurrencyInput
            readOnly={!availableToEdit}
            value={text}
            editable={editable}
            className="right"
            onChange={(value) => handleChange(value, key, 'total_mortgage_amount', collection[rowIndex])}
          />
        );
      }}
      width={220}
    />,
  ];

  const appFixNFlipsColumns = [
    ...basicColumns,
    <Column
      header={<Cell className="reschedule_table__hcell right">Purchase ($)</Cell>}
      columnKey="purchase_price"
      key="purchase_price"
      cell={({ rowIndex }) => {
        const {
          key,
          editable,
          meta: { availableToEdit },
        } = collection[rowIndex];
        const text = collection[rowIndex].purchasePrice;
        return (
          <CurrencyInput
            readOnly={!availableToEdit}
            value={text}
            editable={editable}
            className="right"
            onChange={(value) => handleChange(value, key, 'purchase_price', collection[rowIndex])}
          />
        );
      }}
      width={150}
    />,
    <Column
      header={<Cell className="reschedule_table__hcell right">Rehab ($)</Cell>}
      columnKey="rehab_budget"
      key="rehab_budget"
      cell={({ rowIndex }) => {
        const {
          key,
          editable,
          meta: { availableToEdit },
        } = collection[rowIndex];
        const text = collection[rowIndex].rehabBudget;
        return (
          <CurrencyInput
            readOnly={!availableToEdit}
            value={text}
            editable={editable}
            className="right"
            onChange={(value) => handleChange(value, key, 'rehab_budget', collection[rowIndex])}
          />
        );
      }}
      width={150}
    />,
    <Column
      header={<Cell className="reschedule_table__hcell right">Sale Price</Cell>}
      columnKey="sale_price"
      key="sale_price"
      cell={({ rowIndex }) => {
        const {
          key,
          editable,
          meta: { availableToEdit },
        } = collection[rowIndex];
        const text = collection[rowIndex].salePrice;
        return (
          <CurrencyInput
            readOnly={!availableToEdit}
            value={text}
            editable={editable}
            className="right"
            onChange={(value) => handleChange(value, key, 'sale_price', collection[rowIndex])}
          />
        );
      }}
      width={150}
    />,
    <Column
      header={<Cell className="reschedule_table__hcell">Date Sold</Cell>}
      columnKey="sale_date"
      key="sale_date"
      cell={({ rowIndex }) => {
        const {
          key,
          editable,
          meta: { availableToEdit },
        } = collection[rowIndex];
        const text = collection[rowIndex].saleDate;
        return (
          <DateInput
            readOnly={!availableToEdit}
            value={text ? moment(text).format('MM/DD/YYYY') : undefined}
            editable={editable}
            placeholder="Select date"
            onChange={(value) => handleChange(value, key, 'sale_date', collection[rowIndex])}
          />
        );
      }}
      width={180}
    />,
  ];

  const appInvestmentPropertiesOwnedColumns = [
    ...basicColumns,
    ...sharedColumns,
    <Column
      header={<Cell className="reschedule_table__hcell right">Mortgage Payment</Cell>}
      columnKey="mortgage_payment"
      key="mortgage_payment"
      cell={({ rowIndex }) => {
        const {
          key,
          editable,
          meta: { availableToEdit },
        } = collection[rowIndex];
        const text = collection[rowIndex].mortgagePayment;
        return (
          <CurrencyInput
            readOnly={!availableToEdit}
            value={text}
            editable={editable}
            className="right"
            onChange={(value) => handleChange(value, key, 'mortgage_payment', collection[rowIndex])}
          />
        );
      }}
      width={220}
    />,
    <Column
      header={<Cell className="reschedule_table__hcell right">Rent Amount</Cell>}
      columnKey="rent_amount"
      key="rent_amount"
      cell={({ rowIndex }) => {
        const {
          key,
          editable,
          meta: { availableToEdit },
        } = collection[rowIndex];
        const text = collection[rowIndex].rentAmount;
        return (
          <CurrencyInput
            readOnly={!availableToEdit}
            value={text}
            editable={editable}
            className="right"
            onChange={(value) => handleChange(value, key, 'rent_amount', collection[rowIndex])}
          />
        );
      }}
      width={220}
    />,
  ];

  const appPrimaryResidenceAndSecondHomesColumns = [
    ...basicColumns,
    ...sharedColumns,
  ];

  const appActionsColumns =
    isAtLeastOneAvailableToDeleteByGroupCollection.length > 0 ?
      [<Column
        header={<Cell />}
        columnKey="operation"
        key="operation"
        fixedRight
        cell={({ rowIndex }) => {
          const {
            meta: { availableToDestroy },
          } = collection[rowIndex];
          return (
            <div className="reschedule_table__cell">
              {availableToDestroy && (
                <StyledRemoveButton xs palette="danger" onClick={() => onDelete(collection[rowIndex])}>
                  &times;
                </StyledRemoveButton>
              )}
            </div>
          );
        }}
        width={40}
      />] :
      [];

  let columns = [];

  if (group === 'fix_and_flips') {
    columns = [...appFixNFlipsColumns, ...appActionsColumns];
  } else if (group === 'investment_properties_owned') {
    columns = [...appInvestmentPropertiesOwnedColumns, ...appActionsColumns];
  } else if (group === 'primary_residence') {
    columns = [...appPrimaryResidenceAndSecondHomesColumns, ...appActionsColumns];
  }

  const empty = collection && collection.length === 0;
  return (
    <div>
      <div className="reschedule_table">
        <div className="row">
          <div className="col-xs-6">
            <h3 className={`schedule_table__title ${empty && 'empty'}`} style={{ marginTop: 0 }}>
              {title}
            </h3>
          </div>
        </div>

        {!empty && (
          <Table
            rowHeight={60}
            rowsCount={collection.length}
            headerHeight={40}
            overflowY="auto"
            width={containerWidth}
            maxHeight={420}
          >
            {columns}
          </Table>
        )}

        <StyleScheduleUnderTable>
          {empty && (
            <div style={{ marginBottom: '5px' }}>
              <span>No properties found</span>
            </div>
          )}
          {availableToAdd && targetId && targetType && (
            <Button xs onClick={() => handleAdd()}>
              Add New Property
            </Button>
          )}
        </StyleScheduleUnderTable>
      </div>
    </div>
  );
}

ScheduleTable.propTypes = propTypes;
ScheduleTable.defaultProps = defaultProps;

function mapStateToProps(state, props) {
  return {
    collection: getSchedulesCollectionByGroup(state, props),
    isAtLeastOneAvailableToDeleteByGroupCollection: isAtLeastOneAvailableToDeleteByGroup(state, props),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    removeApplicationScheduleRequest: bindActionCreators(actions.removeApplicationScheduleRequest, dispatch),
    addApplicationScheduleRequest: bindActionCreators(actions.addApplicationScheduleRequest, dispatch),
    editApplicationScheduleRequest: bindActionCreators(actions.editApplicationScheduleRequest, dispatch),
  };
}

const StyleScheduleUnderTable = styled.div`
  width: 100%;
  margin-top: 20px;
  text-align: center;
  margin-bottom: 30px;
`;

const StyledRemoveButton = styled(Button)``;

const enhance = compose(
  withRouter,
  Dimensions({
    getHeight() {
      return window.innerHeight - 200;
    },
    getWidth() {
      return document.getElementById('rescheduleWrapper').offsetWidth;
    },
  }),
  ScrollToTopOnMount,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withHandlers({
    onDelete: ({ target, removeApplicationScheduleRequest, match }) => (record) => {
      const { token } = match.params;
      const confirmPopup = window.confirm('Are you sure you want to delete this property?');
      if (confirmPopup) {
        removeApplicationScheduleRequest(target, token, record);
      }
    },
    handleChange: ({ target, editApplicationScheduleRequest, targetType, targetId, match }) => (
      value,
      key,
      column,
      record,
    ) => {
      const { token } = match.params;
      const property = record;
      if (!record.id) {
        property[column] = value;
      }
      editApplicationScheduleRequest(target, property, column, value, targetId, targetType, token);
    },
    handleAddressChange: ({ target, editApplicationScheduleRequest, targetType, targetId, match }) => (values) => {
      const { token } = match.params;
      const value = getIn('address', values);

      return editApplicationScheduleRequest(target, values, 'address', value, targetId, targetType, token);
    },
    handleAdd: ({ target, targetType, targetId, group, collection, addApplicationScheduleRequest }) => () => {
      const count = collection.length;
      const property = {
        key: `${group}-${targetId}-${count + 1}`,
        _new: true,
        target_id: targetId,
        target_type: targetType,
        valid: false,
        editable: false,
        group,
        edidableOnAdd: 'address',
        meta: {
          availableToDestroy: true,
          availableToEdit: true,
        },
      };
      addApplicationScheduleRequest(target, property);
    },
  }),
  pure,
);

export default enhance(ScheduleTable);
