import * as actionTypes from '../constants/actionTypes';
import * as requestTypes from '../constants/requestTypes';
import setRequestInProcess from '../actions/request';

import api from '../utils/api';

export const fetchApplicationTermSheet = (token) => (dispatch) => {
  const requestType = requestTypes.FINAL_TERM_SHEET;
  const url = `/applications/${token}/term_sheet/final`;

  dispatch(setRequestInProcess(true, requestType));
  return api
    .get(url)
    .then(({ data }) => {
      dispatch(setRequestInProcess(false, requestType));
      dispatch(fetchApplicationTermSheetSuccess(data));
    })
    .catch((response) => {
      dispatch(setRequestInProcess(false, requestType));
      console.log('response', response);
    }); // FIXME to think about how to handle the error correctly
};

export function fetchApplicationTermSheetSuccess(payload) {
  return {
    type: actionTypes.FINAL_TERM_SHEET_FETCH_SUCCESS,
    payload,
  };
}
