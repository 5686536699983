import React from 'react';
import { FormSpy } from 'react-final-form';

const WithFinalFormHoc = (Component) => {
  function WithFinalForm(props) {
    return <FormSpy subscription={{}} render={({ form }) => <Component form={form} {...props} />} />;
  }

  return WithFinalForm;
};

export default WithFinalFormHoc;
