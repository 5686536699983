import React from 'react';
import styled from 'styled-components';
import { theme } from 'styled-tools';

function FormLegend({ ...props }) {
  return <Legend {...props} />;
}

const Legend = styled.legend`
  display: block;
  font-family: ${theme('fonts.base')};
  font-weight: 300;
  font-size: 1.25rem;
`;

export default FormLegend;
