import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { palette } from 'styled-tools';

const propTypes = {
  palette: PropTypes.string,
  number: PropTypes.number,
  textPosition: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const defaultProps = {
  palette: 'primary',
  number: 1,
  textPosition: 'bottom',
};

const opposites = {
  top: 'bottom',
  right: 'left',
  bottom: 'top',
  left: 'right',
};

const isReverse = (textPosition) => textPosition === 'right' || textPosition === 'bottom';
const isColumn = (textPosition) => textPosition === 'top' || textPosition === 'bottom';

const order = ({ textPosition }) => (isReverse(textPosition) ? 1 : 0);
const flexDirection = ({ textPosition }) => (isColumn(textPosition) ? 'column' : 'row');
const spaceSide = ({ textPosition }) => opposites[textPosition];

export const func = () => false;

function RoundNumber({ number, children, ...props }) {
  if (!children) {
    return <Circle {...props}>{number}</Circle>;
  }
  return (
    <Container {...props}>
      <Body {...props}>{children}</Body>
      <Circle {...props}>{number}</Circle>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: ${flexDirection};
  align-items: center;
`;

const Circle = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 100px;
  text-align: center;
  font-size: 60px;
  color: ${palette('grayscale', -1)};
  letter-spacing: 0px;
  line-height: 100px;
  background: ${palette('primary', 0)};
  box-shadow: 0px 1px 0px 0px rgba(0, 96, 203, 0.5), 0px 1px 1px 0px rgba(0, 0, 0, 0.2),
    inset 0px 2px 0px 0px rgba(255, 255, 255, 0.41);
`;

const Body = styled.div`
  order: ${order};
  display: block;
  margin-${spaceSide}: 17px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 300;
  color: ${palette('grayscale', 1)};
  letter-spacing: 0px;
`;

RoundNumber.propTypes = propTypes;
RoundNumber.defaultProps = defaultProps;
export default RoundNumber;
