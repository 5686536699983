import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';

import { getApplication } from 'selectors';

import Application from '../../blocks/ApplicationDrawRequests';

const propTypes = {
  application: PropTypes.objectOf(PropTypes.any),
};

const defaultProps = {
  application: undefined,
};

function DrawRequestApplication({ application }) {
  return <Application application={application} />;
}

DrawRequestApplication.propTypes = propTypes;
DrawRequestApplication.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  const application = getApplication(state);

  return {
    application,
  };
};

const enhance = compose(
  connect(mapStateToProps),
  pure,
);

export default enhance(DrawRequestApplication);
