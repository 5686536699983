import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Progress } from 'components/shared';

import FormBox from '../Box';
import FormFlex from '../Flex';

const propTypes = {
  className: PropTypes.string,
  current: PropTypes.number,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number,
};

const defaultProps = {
  className: null,
  current: 1,
  subtitle: undefined,
  total: 2,
};

function StepHeader({ className, current, title, subtitle, total }) {
  return (
    <StyledFormFlex alignItems="center" justifyContent="space-between" className={className}>
      <FormBox alignSelf={['flex-start', 'flex-start', 'inherit']}>
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </FormBox>
      <FormBox pt={[4, 4, 0]}>
        <Progress current={current} count={total} />
      </FormBox>
    </StyledFormFlex>
  );
}

const StyledFormFlex = styled(FormFlex)`
  margin: auto;
  max-width: 43rem;
`;

const Title = styled.div`
  text-align: left;
  font-size: 1.375rem;
  font-weight: 300;
  color: #111;
`;

const Subtitle = styled.div`
  margin: 5px 0 0 0;
  text-align: left;
  font-size: 1rem;
  font-weight: 300;
  color: #666;
`;

StepHeader.propTypes = propTypes;
StepHeader.defaultProps = defaultProps;
export default StepHeader;
