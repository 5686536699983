import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, lifecycle, pure, withHandlers } from 'recompose';
import { Switch, Route } from 'react-router-dom';
import * as R from 'ramda';

import { applicationPropTypes } from 'propTypes';

import * as actions from 'actions';
import { getApplicationCurrentBorrowerContactId } from 'selectors';

import { NotFoundPage } from 'components';
import PrivateRoute from 'components/PrivateRoute';

import {
  APPLICATION_PATH,
  APPLICATION_STEP_PATH,
  APPLICATION_DOCUMENTS_PATH,
  APPLICATION_SCHEDULE_PATH,
  APPLICATION_CONSTRUCTION_BUDGET_PROPERTY_PATH,
  APPLICATION_CONSTRUCTION_BUDGET_PATH,
  APPLICATION_CONTACTS_PATH,
  APPLICATION_NEW_EXPENSES_PATH,
  APPLICATION_DRAW_REQUESTS_PATH,
  APPLICATION_PAYOFF_PATH,
  APPLICATION_EXTENSION_PATH,
  LEAD_PATH,
  APPLICATION_CREDIT_AND_BACKGROUND_DISCLAIMER_PATH,
} from 'constants/routes';

import ApplicationPage from './ApplicationPage';
import ApplicationDashboardPage from './DashboardPage';
import ApplicationDocumentsPage from './DocumentsPage';
import ApplicationStepsPage from './StepsPage';
import ApplicationSchedulePage from './SchedulePage';
import ApplicationConstructionBudgetsPage from './ConstructionBudgetsPage';
import ApplicationConstructionBudgetPage from './ConstructionBudgetPage';
import ApplicationContactsPage from './ContactsPage';
import ExpensesPage from './ExpensesPage';
import DrawRequestsPage from './DrawRequestsPage/route';
import PayoffPage from './PayoffPage';
import ExtensionPage from './ExtensionPage';
import CreditAndBackgroundDisclaimerPage from './CreditAndBackgroundDisclaimerPage';

import { ApplicationPrivateRoute } from './shared';

const propTypes = {
  ...applicationPropTypes,
};

function Application() {
  return (
    <Switch>
      <Route path={LEAD_PATH} component={ApplicationPage} />

      <ApplicationPrivateRoute policy="availableToEdit" path={APPLICATION_STEP_PATH} component={ApplicationStepsPage} />

      <Route path={APPLICATION_SCHEDULE_PATH} component={ApplicationSchedulePage} />

      <Route exact path={APPLICATION_CONSTRUCTION_BUDGET_PROPERTY_PATH} component={ApplicationConstructionBudgetPage} />

      <Route exact path={APPLICATION_CONSTRUCTION_BUDGET_PATH} component={ApplicationConstructionBudgetsPage} />

      <Route exact path={APPLICATION_CONTACTS_PATH} component={ApplicationContactsPage} />

      <ApplicationPrivateRoute
        policy="availableToEdit"
        exact
        path={APPLICATION_CREDIT_AND_BACKGROUND_DISCLAIMER_PATH}
        component={CreditAndBackgroundDisclaimerPage}
      />

      <ApplicationPrivateRoute
        policy="availableToPayAppraisal"
        exact
        path={APPLICATION_NEW_EXPENSES_PATH}
        component={ExpensesPage}
      />
      <ApplicationPrivateRoute
        policy="availableToRequestDraw"
        path={APPLICATION_DRAW_REQUESTS_PATH}
        component={DrawRequestsPage}
      />
      <ApplicationPrivateRoute
        policy="availableToRequestPayoff"
        exact
        path={APPLICATION_PAYOFF_PATH}
        component={PayoffPage}
      />
      <ApplicationPrivateRoute
        policy="availableToRequestExtension"
        exact
        path={APPLICATION_EXTENSION_PATH}
        component={ExtensionPage}
      />

      <PrivateRoute path={APPLICATION_DOCUMENTS_PATH} component={ApplicationDocumentsPage} />

      <PrivateRoute route={APPLICATION_PATH} component={ApplicationDashboardPage} />

      <Route component={NotFoundPage} />
    </Switch>
  );
}

Application.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    currentContactId: getApplicationCurrentBorrowerContactId(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchEntities: bindActionCreators(actions.fetchEntities, dispatch),
    fetchApplication: bindActionCreators(actions.fetchApplication, dispatch),
    fetchDocuments: bindActionCreators(actions.fetchDocuments, dispatch),
    fetchDocumentCategories: bindActionCreators(actions.fetchDocumentCategories, dispatch),
    fetchDownlodDocumentCategories: bindActionCreators(actions.fetchDownlodDocumentCategories, dispatch),
    fetchApplicationTermSheet: bindActionCreators(actions.fetchApplicationTermSheet, dispatch),
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withHandlers({
    contactListener: ({ fetchEntities, currentContactId }) => (prevProps) => {
      if (R.equals(currentContactId, prevProps.currentContactId)) return;
      if (R.isNil(currentContactId)) return;
      fetchEntities(currentContactId);
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { contactListener } = this.props;
      contactListener(prevProps);
    },
    UNSAFE_componentWillMount() {
      const {
        currentContactId,
        fetchEntities,
        fetchApplication,
        fetchDocuments,
        fetchDocumentCategories,
        fetchDownlodDocumentCategories,
        match,
      } = this.props;
      const { token } = match.params;
      if (currentContactId) fetchEntities(currentContactId);
      fetchApplication(token);
      fetchDocumentCategories(token);
      fetchDownlodDocumentCategories(token);
      fetchDocuments(token);
    },
  }),
  pure,
);

export default enhance(Application);
