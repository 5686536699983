import React, { PureComponent } from 'react';

function ScrollToTopOnMount(WrappedComponent) {
  return class extends PureComponent {
    componentDidMount() {
      window.scrollTo(0, 0);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default ScrollToTopOnMount;
