import React from 'react';
import { compose, withProps } from 'recompose';

import { DocumentsTree } from 'components/shared';

import Body from './Body';
import Title from './Title';

const enhance = compose(
  withProps((props) => ({
    body: <Body {...props} />,
    title: <Title {...props} />,
  })),
);

export default enhance(DocumentsTree.Node);
