import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { APPLICATION_NEW_EXPENSES_PATH } from 'constants/routes';

import { ToDo, Button } from 'components/shared';

import { getPath } from 'utils';

const propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

function AppraisalPaymentToDo({ match }) {
  const { token } = match.params;
  return (
    <ToDo>
      <ToDo.Title>Pay Appraisal Fee</ToDo.Title>
      <ToDo.Actions>
        <Button to={getPath(APPLICATION_NEW_EXPENSES_PATH, { token })} sm>
          Pay
        </Button>
      </ToDo.Actions>
    </ToDo>
  );
}

AppraisalPaymentToDo.propTypes = propTypes;

export default withRouter(AppraisalPaymentToDo);
