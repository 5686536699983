import api from '../utils/api';

import * as actionTypes from '../constants/actionTypes';
import * as requestTypes from '../constants/requestTypes';
import setRequestInProcess from '../actions/request';

export const fetchContactTypes = (token) => (dispatch) => {
  const requestType = requestTypes.CONTACT_TYPES;
  const url = `/applications/${token}/contacts/types.json`;

  dispatch(setRequestInProcess(true, requestType));
  return api
    .get(url)
    .then(({ data }) => {
      dispatch(setRequestInProcess(false, requestType));
      dispatch(fetchContactTypesSuccess(data));
    })
    .catch((response) => {
      dispatch(setRequestInProcess(false, requestType));
      console.log('response', response);
    }); // FIXME to think about how to handle the error correctly
};

export function fetchContactTypesSuccess(payload) {
  return {
    type: actionTypes.CONTACT_TYPES_FETCH_SUCCESS,
    payload,
  };
}

export function resetContactTypes() {
  return {
    type: actionTypes.CONTACT_TYPES_RESET,
  };
}
