import * as actionTypes from 'constants/actionTypes';

import { initialState } from 'selectors/constructionBudget';

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.CONSTRUCTION_BUDGET_FETCH_SUCCESS:
      return { ...state, ...payload };
    case actionTypes.CONSTRUCTION_BUDGET_UPDATE:
      return { ...state, ...payload.data };
    case actionTypes.CONSTRUCTION_BUDGET_CLEAR:
      return { ...initialState };
    default:
      return state;
  }
};
