import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/shared/Link';
import IconLink from 'components/shared/IconLink';

const propTypes = {
  message: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string,
};

const defaultProps = {
  message: 'Are you shure?',
  icon: undefined,
};

const confirmationOnClick = (onClick, message) => (e) => window.confirm(message) && onClick(e);

function ConfirmationLink({ icon, message, onClick, ...props }) {
  if (icon) return <IconLink icon={icon} onClick={confirmationOnClick(onClick, message)} {...props} />;
  return <Link onClick={confirmationOnClick(onClick, message)} {...props} />;
}

ConfirmationLink.propTypes = propTypes;
ConfirmationLink.defaultProps = defaultProps;

export default ConfirmationLink;
