import * as R from 'ramda';

import { getIn } from 'utils';

import { utils } from '../';

function isFormDisabledSetterHandler({ setIsFormDisabled }) {
  const setter = R.pipe(
    getIn('meta.isDisabled'),
    R.unless(R.isNil, setIsFormDisabled),
  );

  return R.tap(setter);
}

function isEditAddressSetterHandler({ setIsEditAddress }) {
  const setter = R.pipe(
    getIn('meta.isEditAddress'),
    R.unless(R.isNil, setIsEditAddress),
  );

  return R.tap(setter);
}

function mergeInitialValuesHandler({ setInitialValues, isFormDisabledSetter, isEditAddressSetter }) {
  return R.pipe(
    isFormDisabledSetter,
    isEditAddressSetter,
    utils.setDefaultInitialValues,
    setInitialValues,
  );
}

export {
  mergeInitialValuesHandler as mergeInitialValues,
  isFormDisabledSetterHandler as isFormDisabledSetter,
  isEditAddressSetterHandler as isEditAddressSetter,
};
