import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Redirect } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import * as R from 'ramda';

import * as actions from 'actions';
import { getApplication, getAuthenticationToken, getUser, getNavigateToSearchParams, getOridinalUserId } from 'selectors';

import { PageTemplate } from 'components/templates';
import { Wrapper } from 'components/shared';
import AccessDenied from './shared/AccessDenied';

import { ScrollToTopOnMount } from 'hocs';
import {
  APPLICATION_CREDIT_AND_BACKGROUND_DISCLAIMER_PATH,
  APPLICATION_NEW_EXPENSES_PATH,
  APPLICATION_PATH,
  APPLICATION_STEP_PATH,
  LEAD_PATH,
} from 'constants/routes';
import { getPath } from 'utils';

const propTypes = {
  application: PropTypes.objectOf(PropTypes.any),
  checkPending: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  checkAppraisalPayment: PropTypes.func.isRequired,
  checkDashboard: PropTypes.func.isRequired,
  checkCreditAndBackgroundDisclaimer: PropTypes.func.isRequired,
  originalUserId: PropTypes.string,
};

const defaultProps = {
  application: undefined,
};

function Content({
  application,
  checkAppraisalPayment,
  checkCreditAndBackgroundDisclaimer,
  checkDashboard,
  checkPending,
  match,
}) {
  const { token } = match.params;
  if (checkPending()) {
    return <ContentLoader />;
  }

  if (checkCreditAndBackgroundDisclaimer()) {
    return <Redirect to={getPath(APPLICATION_CREDIT_AND_BACKGROUND_DISCLAIMER_PATH, { token })} />;
  }

  if (checkDashboard()) {
    return <Redirect to={getPath(APPLICATION_PATH, { token })} />;
  }

  if (checkAppraisalPayment()) {
    return <Redirect to={getPath(APPLICATION_NEW_EXPENSES_PATH, { token })} />;
  }

  if (application) {
    const { leadStep, dataEntryStep } = application;
    if (leadStep === 'complete') {
      return <Redirect to={getPath(APPLICATION_STEP_PATH, { token, step: dataEntryStep })} />;
    }
    return <Redirect to={getPath(LEAD_PATH, { token })} />;
  }

  return <AccessDenied />;
}

function ApplicationAuth(props) {
  return (
    <PageTemplate>
      <Wrapper>
        <Content {...props} />
      </Wrapper>
    </PageTemplate>
  );
}

function mapStateToProps(state) {
  return {
    originalUserId: getOridinalUserId(state),
    authenticationToken: getAuthenticationToken(state),
    navigateTo: getNavigateToSearchParams(state),
    user: getUser(state),
    application: getApplication(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchApplicationAuthToken: bindActionCreators(actions.fetchApplicationAuthToken, dispatch),
    fetchApplication: bindActionCreators(actions.fetchApplication, dispatch),
  };
}

const enhance = compose(
  ScrollToTopOnMount,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isLoading', 'setIsLoading', true),
  withState('status', 'setStatus', 'pending'),
  withHandlers({
    setSuccess: ({ setStatus }) => () => setStatus('success'),
    setReject: ({ setStatus }) => () => setStatus('reject'),
    checkStatus: ({ status }) => R.equals(status),
  }),
  withHandlers({
    checkPending: ({ checkStatus }) => () => checkStatus('pending'),
    checkSuccess: ({ checkStatus }) => () => checkStatus('success'),
    checkAppraisalPayment: ({ navigateTo }) => () => R.equals('appraisal_payment', navigateTo),
    checkDashboard: ({ navigateTo }) => () => R.equals('dashboard', navigateTo),
    checkCreditAndBackgroundDisclaimer: ({ navigateTo }) => () => R.equals('credit_and_background_disclaimer', navigateTo),
  }),
  lifecycle({
    async componentDidMount() {
      const {
        user,
        originalUserId,
        authenticationToken,
        setSuccess,
        setReject,
        fetchApplication,
        fetchApplicationAuthToken,
        match,
        history,
        location,
      } = this.props;
      history.replace(location.pathname);
      const { token } = match.params;
      if (user) {
        setSuccess();
        return;
      }
      if (R.isNil(authenticationToken)) {
        setReject();
        return;
      }

      try {
        await fetchApplicationAuthToken(authenticationToken, originalUserId);
        await fetchApplication(token);
        setSuccess();
      } catch (e) {
        setReject();
      }
    },
  }),
  pure,
);

Content.propTypes = propTypes;
Content.defaultProps = defaultProps;

export default enhance(ApplicationAuth);
