import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';

import { getIsInIframe } from 'selectors';

import { Button, Congratulation, LoanOfficer } from 'components/shared';
import { FormBox, FormFlex } from 'components/shared/form';
import Well, { WellBody, WellHeader } from 'components/shared/Well';

import { MY_APPLICATIONS_PATH } from 'constants/routes';
import { userPropTypes, userDefaultProps } from 'propTypes/user';
import ScrollToTopOnMount from 'hocs/ScrollToTopOnMount';
import { getPath } from 'utils';

const propTypes = {
  application: PropTypes.objectOf(PropTypes.any).isRequired,
  ...userPropTypes,
};

const defaultProps = {
  ...userDefaultProps,
};

function InReview({ application, user }) {
  const { contact } = user;

  return (
    <Well>
      <WellHeader key="header">
        <HeaderFormFlex justifyContent="space-between">
          <FormBox>
            {/* FIXME */}
            <HeaderTitle>Apply For {application.displayType}</HeaderTitle>
          </FormBox>
        </HeaderFormFlex>
      </WellHeader>

      <WellBody key="body">
        <ContentWrapper>
          <StyledCongratulation status="money">
            <CongratulationTitle>Congratulations!</CongratulationTitle>
            <CongratulationSubtitle>
              {`There are multiple options for ${application.type}s, please schedule a call with your loan officer.`}
            </CongratulationSubtitle>
          </StyledCongratulation>

          <FormFlex justifyContent="center">
            <FormBox>
              <Button fz={1.125} rounded to={getPath(MY_APPLICATIONS_PATH, { status: 'active' })}>
                Please continue to the Full Application
              </Button>
            </FormBox>
          </FormFlex>

          <StyledLoanOfficer contact={contact} application={application} />
        </ContentWrapper>
      </WellBody>
    </Well>
  );
}

const HeaderFormFlex = styled(FormFlex)`
  max-width: 43.125rem;
`;

const StyledCongratulation = styled(Congratulation)`
  padding: 1.875rem 0 3.125rem;
`;

const StyledLoanOfficer = styled(LoanOfficer)`
  margin: 3.125rem 0;
`;

const HeaderTitle = styled.div`
  text-align: left;
  font-size: 1.375rem;
  font-weight: 300;
  color: #111;
`;

const CongratulationTitle = styled.div`
  font-weight: 300;
  font-size: 1.75rem;
  color: #111111;
  letter-spacing: 0.03125rem;
  margin-bottom: 0.9375rem;
`;

const CongratulationSubtitle = styled.div`
  font-weight: 300;
  font-size: 1.125rem;
  color: #666666;
  letter-spacing: 0;
  line-height: 1.8125rem;
`;

const ContentWrapper = styled.div`
  margin: auto;
  max-width: 43.125rem;
  font-size: 0.875rem;
  color: #504f4d;
`;

InReview.propTypes = propTypes;
InReview.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    isInIframe: getIsInIframe(state),
  };
}

const enhance = compose(
  connect(mapStateToProps),
  ScrollToTopOnMount,
  pure,
);

export default enhance(InReview);
