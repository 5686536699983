import {
  append,
  apply,
  concat,
  descend,
  equals,
  evolve,
  flatten,
  head,
  is,
  isEmpty,
  isNil,
  length,
  lensProp,
  map,
  mergeRight,
  objOf,
  pick,
  pipe,
  prop,
  reduce,
  reject,
  set,
  sort,
  unnest,
  values,
} from 'ramda';

import { documentsFilterByTargetAndCategory } from 'utils/selectors';

function lastIsUnacceptable(docs) {
  return pipe(
    sort(descend(prop('createdAt'))),
    head,
    prop('reviewStatus'),
    equals('unacceptable'),
  )(docs);
}

export function documentsTreeCheckedKeysGetter(key) {
  function getter(categories = [], ids, documents) {
    let keys = [];

    if (is(Array, ids)) {
      keys = pipe(
        map((id) => getter(categories, id, documents)),
        flatten,
        concat(keys),
      )(ids);
    }

    if (is(String, ids) || is(Number, ids)) {
      categories.forEach((category) => {
        const files = pipe(documentsFilterByTargetAndCategory)(documents, { categoryId: category.id, targetId: ids });
        if (lastIsUnacceptable(files)) return;
        if (isNil(files)) return;
        if (isEmpty(files)) return;
        keys = [...keys, `${key}-${ids}-${category.id}`];
      });
    }

    return keys;
  }

  return getter;
}

export function documentsTreeStatusesGetter(key) {
  function getter(categories = [], ids, documents) {
    let statuses = {};

    if (is(Array, ids)) {
      statuses = pipe(
        map((id) => getter(categories, id, documents)),
        reduce(mergeRight, {}),
      )(ids);
    }

    if (is(String, ids) || is(Number, ids)) {
      categories.forEach((category) => {
        const localKey = `${key}-${ids}-${category.id}`;
        const files = documentsFilterByTargetAndCategory(documents, { categoryId: category.id, targetId: ids });
        if (isNil(files) || isEmpty(files)) {
          statuses = set(lensProp(localKey), 'pending', statuses);
          return;
        }

        if (lastIsUnacceptable(files)) {
          statuses = set(lensProp(localKey), 'danger', statuses);
          return;
        }

        statuses = set(lensProp(localKey), 'success', statuses);
      });
    }

    return statuses;
  }

  return getter;
}

export function documentsTreeKeysGetter(key) {
  function getter(categories = [], ids) {
    let keys = [];

    if (is(Array, ids)) {
      keys = pipe(
        map((id) => getter(categories, id)),
        flatten,
        concat(keys),
      )(ids);
    }

    if (is(String, ids) || is(Number, ids)) {
      categories.forEach((category) => {
        keys = [...keys, `${key}-${ids}-${category.id}`];
      });
    }

    return keys;
  }

  return getter;
}

export function documentsPropertyRequestedTreeKeysGetter(key) {
  return pipe(
    map(
      pipe(
        pick(['requestedDocumentCategories', 'id']),
        evolve({ requestedDocumentCategories: map(objOf('id')) }),
        values,
        apply(documentsTreeKeysGetter(key)),
      ),
    ),
    flatten,
  );
}

export function documentsPropertyRequestedTreeCheckedKeysGetter(key) {
  return (properties, documents) =>
    pipe(
      map(
        pipe(
          pick(['requestedDocumentCategories', 'id']),
          evolve({ requestedDocumentCategories: map(objOf('id')) }),
          values,
          append(documents),
          apply(documentsTreeCheckedKeysGetter(key)),
        ),
      ),
      flatten,
    )(properties);
}

export function documentsPropertyMissingTreeKeysGetter(key) {
  return pipe(
    map(
      pipe(
        pick(['missingDocumentCategories', 'id']),
        evolve({ missingDocumentCategories: map(objOf('id')) }),
        values,
        apply(documentsTreeKeysGetter(key)),
      ),
    ),
    flatten,
  );
}

export function documentsPropertyMissingTreeCheckedKeysGetter(key) {
  return (properties, documents) =>
    pipe(
      map(
        pipe(
          pick(['missingDocumentCategories', 'id']),
          evolve({ missingDocumentCategories: map(objOf('id')) }),
          values,
          append(documents),
          apply(documentsTreeCheckedKeysGetter(key)),
        ),
      ),
      flatten,
    )(properties);
}

export function documentsCategoriesRatioGetter(categories = [], targetId, documents) {
  const dividend = length(categories);

  if (dividend === 0) {
    return undefined;
  }

  const divisor = pipe(
    map(({ id: categoryId }) => documentsFilterByTargetAndCategory(documents, { categoryId, targetId })),
    reject(lastIsUnacceptable),
    reject(isEmpty),
    length,
  )(categories);

  return [divisor, dividend];
}

export function documentsCategoriesRatioTargetIdsGetter(categories = [], targetIds, documents) {
  const dividend = length(categories) * length(targetIds);

  if (dividend === 0) {
    return undefined;
  }

  const divisor = pipe(
    map((targetId) =>
      pipe(
        map(({ id: categoryId }) => documentsFilterByTargetAndCategory(documents, { categoryId, targetId })),
        reject(lastIsUnacceptable),
        reject(isEmpty),
      )(categories)),
    reject(isEmpty),
    unnest,
    length,
  )(targetIds);

  return [divisor, dividend];
}
