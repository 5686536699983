import { createSelector } from 'reselect';
import { any, propEq } from 'ramda';

import { documentsFilterByTargetAndCategory } from 'utils/selectors';
import { decorateContact } from 'decorators';
import documentDecourator from 'decorators/documents';

export const initialState = [];

// const documentsSelector = (state) => state.documents || initialState;

function documentsSelector(state) {
  return state.documents || initialState;
}

const getProps = (state, props = {}) => props;

export const getDocuments = createSelector(
  documentsSelector,
  (documents) => documents.map((doc) => ({ ...doc, contact: decorateContact(doc.contact) })),
);

export const getDecoratedDocuments = createSelector(
  getDocuments,
  documentDecourator,
);

// export const getTargetDocumentsByCategoryId = createSelector(
//   [getDecoratedDocuments, getProps],
//   identity,
// );

export const getTargetDocumentsByCategoryId = createSelector(
  [getDecoratedDocuments, getProps],
  (documents, props) => {
    return documentsFilterByTargetAndCategory(documents, props);
  },
);

export const getСategoriesWithDocumentsIds = createSelector(
  [getDecoratedDocuments],
  (documents) => documents.map((doc) => doc.category.id),
);

export const getHasUnacceptableInTargetDocuments = createSelector(
  getTargetDocumentsByCategoryId,
  any(propEq('reviewStatus', 'reviewed')),
);
