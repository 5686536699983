import styled from 'styled-components';

const Subtitle = styled.p`
  text-align: center;
  font-weight: 300;
  font-size: 1.4rem;
  color: #666;
`;

export default Subtitle;
