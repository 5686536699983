import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { Wrapper } from 'components/shared';
import { APP_NAME } from 'constants/views';

const FooterContainer = styled.div`
  padding: 20px 0 60px;
  width: 100%;
  border-top: 1px solid #d5dde9;
`;

const FooterCopyright = styled.p`
  color: #77808b;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
`;

function Footer(props) {
  return (
    <FooterContainer {...props}>
      <Wrapper>
        <FooterCopyright>
          &copy; 2015 &mdash; {moment().format('YYYY')} {APP_NAME}, LLC. All Rights Reserved.
        </FooterCopyright>
      </Wrapper>
    </FooterContainer>
  );
}

export default Footer;
