import { useRef } from 'react';

const toScroll = (el) => {
  if (!el) return;
  window.requestAnimationFrame(() => {
    el.scrollIntoView();
  });
};

function useFormPropertyScroll({ name, toOpen, toToggle, isOpen }) {
  const el = useRef();

  const toOpenProperty = () => {
    toOpen(name);
    toScroll(el.current);
  };

  const toToggleProperty = () => {
    toToggle(name);
    if (!isOpen) toScroll(el.current);
  };

  return { el, toOpenProperty, toToggleProperty };
}


export default useFormPropertyScroll;
