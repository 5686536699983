import * as R from 'ramda';

import { utils } from '../';

function mergeInitialValuesHandler({ setInitialValues }) {
  return R.pipe(
    utils.setDefaultInitialValues,
    setInitialValues,
  );
}

export { mergeInitialValuesHandler as mergeInitialValues };
