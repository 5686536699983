import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose, pure } from 'recompose';
import { Form, Field } from 'react-final-form';
import createFocusDecorator from 'final-form-focus';
import { withRouter } from 'react-router-dom';
import { Box, Flex } from 'grid-styled';

import * as actions from 'actions';
import { getArvLtv, getMinArvLtv, getMaxArvLtv } from 'selectors';

import { Button, Paragraph } from 'components/shared';
import { FormGroupAdapter } from 'components/shared/form';

import { validateRequired, composeValidators, percentFormat } from 'utils';

import validateRange from './validates';

const propTypes = {
  discussQuote: PropTypes.func.isRequired,
  arvLtv: PropTypes.string.isRequired,
  maxArvLtv: PropTypes.string.isRequired,
  minArvLtv: PropTypes.string.isRequired,
};

const defaultProps = {};

const focusOnError = createFocusDecorator();

function UpdateLTVForm({ arvLtv, maxArvLtv, minArvLtv, discussQuote }) {
  return (
    <Form
      onSubmit={discussQuote}
      decorators={[focusOnError]}
      initialValues={{ quote: { arvLtv } }}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Flex justifyContent="center" mb="10px">
            <Paragraph>
              You may change your rate by adjusting the LTV. Input LTV in range {percentFormat(minArvLtv)} to{' '}
              {percentFormat(maxArvLtv)}
            </Paragraph>
          </Flex>
          <Flex justifyContent="center" mb="20px">
            <Box pr="10px">
              <Field
                name="quote.arvLtv"
                typeOfMask="percent"
                require
                validate={composeValidators(validateRequired(), validateRange(minArvLtv, maxArvLtv))}
                component={FormGroupAdapter}
              />
            </Box>
            <Box>
              <Button rounded type="submit" disabled={submitting}>
                Update LTV
              </Button>
            </Box>
          </Flex>
        </form>
      )}
    />
  );
}

UpdateLTVForm.propTypes = propTypes;
UpdateLTVForm.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    arvLtv: getArvLtv(state),
    minArvLtv: getMinArvLtv(state),
    maxArvLtv: getMaxArvLtv(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    discussQuote: bindActionCreators(actions.discussQuote, dispatch),
  };
}

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  pure,
);

export default enhance(UpdateLTVForm);
