import React from 'react';
import { Field } from 'react-final-form';

import { Hint } from 'components/shared/formControls';

function FilesField(props) {
  return (
    <Field
      render={({ meta }) => {
        const { touched, error } = meta;
        if (touched && error) {
          return <Hint error>{error}</Hint>;
        }
        return null;
      }}
      {...props}
    />
  );
}

export default FilesField;
