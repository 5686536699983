import { always, when, then, otherwise, pipe } from 'ramda';

import { setInDefaultValue } from 'decorators';
import { throwSubmissionError } from 'utils';

function setDefaultInitialValuesHandler({ match }) {
  const { token } = match.params;
  return pipe(
    setInDefaultValue(token, 'token'),
    setInDefaultValue({}, 'contact'),
  );
}

function mergeInitialValuesHandler({ setDefaultInitialValues, setInitialValues }) {
  return pipe(
    setDefaultInitialValues,
    setInitialValues,
  );
}

function onSubmitHandler({ onSubmitAction, callback }) {
  return pipe(
    onSubmitAction,
    then(when(always(callback), callback)),
    otherwise(throwSubmissionError),
  );
}

export {
  setDefaultInitialValuesHandler as setDefaultInitialValues,
  mergeInitialValuesHandler as mergeInitialValues,
  onSubmitHandler as onSubmit,
};
