import React from 'react';
import { compose, pure } from 'recompose';
import { withRouter } from 'react-router-dom';

import { UserHeader } from 'components/blocks/User';

import { UserTemplate } from 'components/templates';
import { Wrapper } from 'components/shared';

import { ScrollToTopOnMount } from 'hocs';

import { ApplicationHeader } from '../shared';
import Documents from './Documents';
import Header from './Header';

const propTypes = {};

const defaultProps = {};

function ApplicationDashboardPage() {
  return (
    <UserTemplate header={<UserHeader />}>
      <ApplicationHeader />

      <Wrapper>
        <Header />
        <Documents />
      </Wrapper>
    </UserTemplate>
  );
}

ApplicationDashboardPage.propTypes = propTypes;
ApplicationDashboardPage.defaultProps = defaultProps;

const enhance = compose(
  ScrollToTopOnMount,
  withRouter,
  pure,
);

export default enhance(ApplicationDashboardPage);
