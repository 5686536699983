import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import { spring, Motion } from 'react-motion';
import media from 'styled-media-query';

import Icon from 'components/shared/Icon';

const modalRoot = document.getElementById('panel-root');

const propTypes = {
  children: PropTypes.node.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  width: PropTypes.string,
};

const defaultProps = {
  width: '50%',
};

class PanelRightPortal extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    const { children, show, onHide, width } = this.props;

    return ReactDOM.createPortal(
      <StyledMotion show={show}>
        {(currentStyles) => (
          <div>
            {show && <Backdrop tabIndex={0} onClick={onHide} opacity={currentStyles.opacity} />}
            <Panel width={width} currentStyles={currentStyles}>
              <Body>
                <Close role="button" tabIndex={0} onClick={onHide}>
                  <CloseIcon onClick={onHide} icon="cross" />
                </Close>
                {show && children}
              </Body>
            </Panel>
          </div>
        )}
      </StyledMotion>,
      this.el,
    );
  }
}

const StyledMotion = styled(Motion).attrs({
  style: ({ show }) => ({
    x: spring(show ? 0 : -110),
    opacity: spring(show ? 1 : 0),
  }),
})``;

const Panel = styled.div.attrs({
  style: ({ currentStyles, width }) => ({
    opacity: currentStyles.opacity,
    width,
    transform: `translate3d(${-currentStyles.x}%, 0, 0)`,
  }),
})`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  z-index: 103;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 22rem;
  height: 100vh;
  background: #fff;

  ${media.lessThan('70rem')`
    min-width: 60%;
  `};
`;

const Backdrop = styled.div.attrs({
  style: ({ opacity }) => ({
    opacity,
  }),
})`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
`;

const Body = styled.div`
  box-sizing: border-box;
  overflow-y: auto;
  width: 100%;
  height: 100%;
`;

const Close = styled.button`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  left: -1em;
  padding: 0;
  width: 1.8em;
  height: 1.8em;
  font-size: 2rem;
  color: #c7c7c7;
  outline: none;
  background: #fff;
  cursor: pointer;
  border: none;
  border-radius: 1em 0 0 1em;
  &:hover {
    color: #666;
  }
`;

const CloseIcon = styled(Icon).attrs({
  icon: 'cross',
  width: 1,
})`
  display: block;
  margin: auto;
`;

PanelRightPortal.propTypes = propTypes;
PanelRightPortal.defaultProps = defaultProps;
PanelRightPortal.scrollPositions = {};
PanelRightPortal.hasVScroll = {};

export default PanelRightPortal;
