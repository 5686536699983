import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import FormFlex from 'components/shared/form/Flex';
import FormBox from 'components/shared/form/Box';

import avatar from './avatar.png';

const propTypes = {
  loanOfficer: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
};

function LoanOfficer(props) {
  const { loanOfficer } = props;

  const { firstName, lastName, email, phone } = loanOfficer;

  return (
    <ContactInfoFormFlex alignItems="center" justifyContent="center">
      <FormBox>
        <Img src={avatar} />
      </FormBox>
      <FormBox>
        <Name>{`${firstName} ${lastName}`}</Name>
        <Phone>{phone}</Phone>
        <Mail href={`mailto::${email}`}>{email}</Mail>
      </FormBox>
    </ContactInfoFormFlex>
  );
}

const ContactInfoFormFlex = styled(FormFlex)`
  padding: 1.1875rem 0;
`;

const Img = styled.img`
  display: block;
  width: 3.125rem;
  height: 3.125rem;
  min-width: 3.125rem;
  border-radius: 100%;
`;

const textStyle = css`
  color: #666666;
  display: block;
  text-align: left;
  font-size: 1.0625rem;
`;

const Name = styled.span`
  ${textStyle};
`;

const Phone = styled.span`
  ${textStyle};
`;

const Mail = styled.a`
  ${textStyle};
  text-decoration: none;
`;

LoanOfficer.propTypes = propTypes;

export default LoanOfficer;
