import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CALENDLY, PHONE } from 'constants/routes';
import { MakeCall, Wrapper } from 'components/shared';
import Heading from 'components/shared/Heading';

const propTypes = {
  calendlyUrl: PropTypes.string,
};

const defaultProps = {
  calendlyUrl: null,
};

function MoreInfo({ calendlyUrl }) {
  return (
    <MoreInfoContainer>
      <Wrapper>
        <StyledHeading level={2}>Need more information?</StyledHeading>
        <Text>
          Whether you are an investor or broker, contact us anytime to request more information. Need to speak with
          someone?
        </Text>
        <MakeCall
          phoneUrl={PHONE}
          buttonUrl={calendlyUrl || CALENDLY}
          phoneText="(866) 942 4366"
          buttonText="Schedule a call"
        />
      </Wrapper>
    </MoreInfoContainer>
  );
}

MoreInfo.propTypes = propTypes;
MoreInfo.defaultProps = defaultProps;

const MoreInfoContainer = styled.section`
  padding: 60px 0;
  width: 100%;
  background-color: #f7f8f8;
`;

const StyledHeading = styled(Heading)`
  margin: 0;
  text-align: center;
  font-weight: 300;
  font-size: 2.75rem;
  color: #000000;
  letter-spacing: 0.91px;
`;

const Text = styled.p`
  text-align: center;
  font-weight: 300;
  font-size: 1.1875rem;
  color: #77808b;
  letter-spacing: 0;
  line-height: 1.625;
  max-width: 534px;
  margin: 20px auto;
`;

export default MoreInfo;
