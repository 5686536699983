import { compose, defaultProps, withProps } from 'recompose';
import { findIndex, prop, length, pipe, divide, multiply, inc } from 'ramda';

const withCalculatedProps = compose(
  defaultProps({
    steps: [],
  }),
  withProps(({ steps }) => {
    const currentIdx = findIndex(prop('current'), steps);
    return {
      currentIdx,
      currentStep: steps[currentIdx],
    };
  }),
  withProps(({ currentStep, currentIdx, steps }) => {
    if (!currentStep) {
      return { nextIdx: -1 };
    }
    if (steps.length === inc(currentIdx)) {
      return { nextIdx: -1 };
    }
    return { nextIdx: inc(currentIdx) };
  }),
  withProps(({ nextIdx, steps }) => ({
    nextStep: steps[nextIdx],
  })),
  withProps(({ steps, currentIdx }) => {
    if (currentIdx === -1) {
      return { barWidth: 0 };
    }

    const barWidth = pipe(
      length,
      divide(100),
      multiply(inc(currentIdx)),
      Math.ceil,
    )(steps);

    return {
      barWidth,
    };
  }),
);

export default withCalculatedProps;
