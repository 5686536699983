import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Heading, RoundNumber, Wrapper } from 'components/shared';
import arrow from './arrow.png';

function StepsRepresent() {
  return (
    <StepsContainer>
      <Wrapper>
        <StyledHeading level={2}>Apply today. It’s an easy 3-step process.</StyledHeading>
        <List>
          <Item>
            <RoundNumber number={1}>Choose a loan type</RoundNumber>
          </Item>
          <Item>
            <RoundNumber number={2}>Apply in Minutes</RoundNumber>
          </Item>
          <Item>
            <RoundNumber number={3}>A dedicated loan specialist will contact you</RoundNumber>
          </Item>
        </List>
      </Wrapper>
    </StepsContainer>
  );
}

const StepsContainer = styled.div`
  padding: 50px 0;
`;

const StyledHeading = styled(Heading)`
  marging: 0;
  font-weight: 300;
  font-size: 2.375rem;
  text-align: center;
  color: #000000;
  letter-spacing: 0.79px;
`;

const List = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 52px 0;
  ${media.lessThan('medium')`
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `};
`;

const Item = styled.li`
  position: relative;
  max-width: 211px;
  flex: 1 0 33%;
  & + li {
    &:before {
      content: '';
      position: absolute;
      width: 103px;
      height: 52px;
      background: url(${arrow}) no-repeat 0 0;
      background-size: contain;
      top: 26px;
      left: -180px;
      ${media.lessThan('large')`
        top: 26px;
        left: -75px;
      `};
      ${media.lessThan('medium')`
        top: -98px;
        left: 54px;
        transform: rotate(90deg);
      `};
    }
  }
  ${media.lessThan('medium')`
    width: 100%;
    margin-bottom: 135px;
  `};
`;

export default StepsRepresent;
