import React from 'react';
import styled from 'styled-components';

import { Wrapper } from 'components/shared';
import LogoImage from 'components/shared/LogoImage';

import lock from './lock.png';

function SecurityStandards() {
  return (
    <Wrapper padded>
      <Container>
        <LogoWrap>
          <StyledLogoImage />
        </LogoWrap>
        <div>
          <Title>Your Online Security is Important to us</Title>
          <Text>
            <IconImg src={lock} />
            We follow the same security standards as banks by keeping your data private and protected using standard
            SHA-256 SSL encryption technology stored on a secure third-party partner server.
          </Text>
        </div>
      </Container>
    </Wrapper>
  );
}

const StyledLogoImage = styled(LogoImage)`
  display: block;
  max-width: 100%;
  height: auto;
`;

const Title = styled.div`
  font-weight: 300;
  font-size: 2rem;
  color: #000000;
  line-height: 2.25rem;
  margin-bottom: 15px;
  letter-spacing: 0.66px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconImg = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
`;

const LogoWrap = styled.div`
  max-width: 323px;
  margin-right: 25px;
`;

const Text = styled.div`
  position: relative;
  padding-left: 30px;
  font-weight: 300;
  font-size: 1.1875rem;
  letter-spacing: 0;
  line-height: 1.5625rem;
  color: #77808b;
`;

export default SecurityStandards;
