import * as R from 'ramda';

import Cookies from 'js-cookie';

import { applicantCurrentStatusPath } from 'constants/routes';
import { app, clientId, clientSecret } from 'config';

import { history } from '../../store/configureStore';

import { applicationLogin } from '../../actions';
import setRequestInProcess from '../../actions/request';
import { resetApplicationSearchParams } from '../../actions/searchParams';
import {
  createInstantQuoteEvent, updateInstantQuoteEvent,
  instantQuoteErrorEvent, applicationFlowEvent, appplicationCompleteEvent,
  applicationErrorEvent,
} from '../gtm';
import { getApplication, getApplicationToken, getIsInIframe } from '../../selectors';
import { requestInProcess } from '../../selectors/request';
import * as actionTypes from '../../constants/actionTypes';
import * as requestTypes from '../../constants/requestTypes';
import api from '../../utils/api';
import {
  camelizeKeys,
  decamelizeObjectToFormData,
  downloadResponseFile,
  errorNotification,
  notInternalRespondErrorNotification,
  successNotification,
  throwSubmissionError,
  warnNotification,
} from '../../utils';
import { resolveServerImportErrors } from '../../utils/form';
import * as fp from '../../utils/ramda';

export const fetchApplications = () => (dispatch, getState) => {
  const requestType = requestTypes.APPLICATIONS;
  const url = `/applications?app=${app}`;

  const processing = requestInProcess(getState(), requestType);

  if (processing) {
    return Promise.resolve();
  }

  dispatch(setRequestInProcess(true, requestType));

  return api
    .get(url)
    .then(({ data }) => {
      dispatch(fetchApplicationsSuccess(data.collection));
      dispatch(setRequestInProcess(false, requestType));
    })
    .catch(() => {
      dispatch(setRequestInProcess(false, requestType));
      // FIXME to think about how to handle the error correctly
      // console.log('response', response);
    });
};

export function fetchApplicationsSuccess(payload) {
  return {
    type: actionTypes.APPLICATIONS_FETCH_SUCCESS,
    payload,
  };
}

export function resetApplications() {
  return {
    type: actionTypes.APPLICATIONS_RESET,
  };
}

// APPLICATION
const createApplication = (type) => (values, form) => (dispatch) => {
  const applicationType = type === 'rental' ? 'rental' : 'bridge';
  const url = `/applications/${applicationType}?app=${app}`;

  const hubspotutk = Cookies.get('hubspotutk')

  return api
    .post(url, { ...values, application: { ...values.application, hubspotutk } })
    .then(async (response) => {
      const { application, token } = response.data;
      dispatch(createApplicationSuccess(application));
      dispatch(resetApplicationSearchParams());
      dispatch(createInstantQuoteEvent({ type }));
      if (token) await dispatch(applicationLogin(token));
      history.push(`/applications/${application.token}/lead/${application.leadStep}`);
    })
    .catch((error) => {
      dispatch(instantQuoteErrorEvent({ type, form, error }));
      return throwSubmissionError(error);
    });
};

const updateApplication = (step) => (values, form) => (dispatch) => {
  const url = `/applications/${values.application.token}/lead/${step}?app=${app}`;

  return api
    .patch(url, values)
    .then((response) => {
      const { application } = response.data;
      dispatch(updateApplicationSuccess(application));
      dispatch(updateInstantQuoteEvent({ type: application.type, step }));
      history.push(`/applications/${application.token}/lead/${application.leadStep}`);
    })
    .catch((error) => {
      dispatch(instantQuoteErrorEvent({ step, type: values.application.type, error, form }));
      return throwSubmissionError(error);
    });
};

export const fetchApplicationLeadProperty = (token) => () => {
  const url = `/applications/${token}/lead/property?app=${app}`;

  return api
    .get(url)
    .then(({ data }) => data)
    .catch((response) => {
      // FIXME to think about how to handle the error correctly
      console.log('response', response);
    });
};

export const fetchApplicationLeadBorrower = (token) => () => {
  const url = `/applications/${token}/lead/borrower?app=${app}`;

  return api
    .get(url)
    .then(({ data }) => data)
    .catch((response) => {
      // FIXME to think about how to handle the error correctly
      console.log('response', response);
    });
};

export const applyPromoCode = (values) => (dispatch, getState) => {
  const state = getState();
  const token = getApplicationToken(state);

  const url = `/applications/${token}/promo_codes/apply?app=${app}`;

  return api
    .patch(url, values)
    .then(({ data }) => {
      successNotification('Promo Code was applied.');
      dispatch(fetchApplicationQuoteSuccess(data));
    })
    .catch(throwSubmissionError);
};

export function fetchApplication(token) {
  return (dispatch, getState) => {
    const requestType = requestTypes.APPLICATION;
    const url = `/applications/${token}?app=${app}`;

    const processing = requestInProcess(getState(), requestType);

    if (processing) {
      return Promise.resolve();
    }

    dispatch(setRequestInProcess(true, requestType));

    return api
      .get(url)
      .then((response) => {
        dispatch(setRequestInProcess(false, requestType));
        const { application } = response.data;
        dispatch(fetchApplicationSuccess(application));
        return application;
        // history.push(`/applications/${response.data.application.token}/${application.leadStep}`);
      })
      .catch((res) => {
        dispatch(setRequestInProcess(false, requestType));
        if (res.status === 500) errorNotification();
      });
  };
}

export const completeLead = () => (dispatch, getState) => {
  const state = getState();
  const application = getApplication(state);

  const isInIframe = getIsInIframe(state);
  const redirectPath = applicantCurrentStatusPath(application.status, {
    token: application.token,
    leadStep: application.leadStep,
    step: application.dataEntryStep,
  });
  const redirect = () => {
    if (!isInIframe) {
      history.push(redirectPath);
      return;
    }
    window.open(redirectPath, '_blank');
  };

  const url = `/applications/${application.token}/lead/complete?app=${app}`;

  return api.get(url).then(redirect);
};

export const fetchApplicationQuote = (token) => (dispatch) => {
  const requestType = requestTypes.QUOTE;
  dispatch(setRequestInProcess(true, requestType));

  const url = `/applications/${token}/quote?app=${app}`;

  return api
    .get(url)
    .then(({ data }) => {
      dispatch(fetchApplicationQuoteSuccess(data));
      dispatch(fetchApplicationBuyPointsGuideline(token));
      dispatch(setRequestInProcess(false, requestType));
    })
    .catch((response) => dispatch(setRequestInProcess(false, requestType))); // FIXME to think about how to handle the error correctly
};

export const fetchApplicationBuyPointsGuideline = (token) => (dispatch) => {
  const requestType = requestTypes.BUY_POINTS_GUIDELINE;
  dispatch(setRequestInProcess(true, requestType));

  const url = `/applications/${token}/rate/guideline/buy_points`;

  return api
    .get(url)
    .then(({ data }) => {
      dispatch(fetchApplicationBuyPointsGuidelineSuccess(data));
      dispatch(setRequestInProcess(false, requestType));
    })
    .catch((response) => dispatch(setRequestInProcess(false, requestType)));
};

export const getTermSheet = (token) => (dispatch) => {
  const requestType = requestTypes.TERM_SHEET;
  const url = `/applications/${token}/term_sheet?app=${app}`;

  dispatch(setRequestInProcess(true, requestType));
  return api
    .get(url)
    .then((response) => {
      dispatch(setRequestInProcess(false, requestType));
      downloadResponseFile('term_sheet.pdf', response);
    })
    .catch((response) => {
      dispatch(setRequestInProcess(false, requestType));
      notInternalRespondErrorNotification(response);
    });
};

export const getPreliminaryTermSheet = (token) => (dispatch) => {
  const requestType = requestTypes.PRELIMINARY_TERM_SHEET;
  const url = `/applications/${token}/term_sheet/preliminary?app=${app}`;

  dispatch(setRequestInProcess(true, requestType));
  return api
    .get(url)
    .then((response) => {
      dispatch(setRequestInProcess(false, requestType));
      downloadResponseFile('quote.pdf', response);
    })
    .catch((response) => {
      dispatch(setRequestInProcess(false, requestType));
      notInternalRespondErrorNotification(response);
    });
};

export const getQuotePdf = (token) => (dispatch) => {
  const requestType = requestTypes.QUOTE_PDF;
  const url = `/applications/${token}/quote/pdf?app=${app}`;

  dispatch(setRequestInProcess(true, requestType));
  return api
    .get(url)
    .then((response) => {
      dispatch(setRequestInProcess(false, requestType));
      downloadResponseFile('quote.pdf', response);
    })
    .catch((response) => {
      dispatch(setRequestInProcess(false, requestType));
      notInternalRespondErrorNotification(response);
    });
};

export const getPreQualificationLetterPdf = (token) => (dispatch) => {
  const requestType = requestTypes.PRE_QUALIFICATION_LETTER_PDF;
  const url = `/applications/${token}/pdf/pre_qualification_letter?app=${app}`;

  dispatch(setRequestInProcess(true, requestType));
  return api
    .get(url)
    .then((response) => {
      dispatch(setRequestInProcess(false, requestType));
      downloadResponseFile('pre_qualification_letter.pdf', response);
    })
    .catch((response) => {
      dispatch(setRequestInProcess(false, requestType));
      notInternalRespondErrorNotification(response);
    });
};

export const selectQuote = ({ token, ...values }) => (dispatch) => {
  const url = `/applications/${token}/quote/select?app=${app}`;

  return api.patch(url, values).then(() => {
    dispatch(fetchApplicationQuote(token));
    dispatch(fetchApplicationBuyPointsGuideline(token));
  });
  // .catch((response) => console.log('response', response)); // FIXME to think about how to handle the error correctly
};

export const buyPoints = (values) => (dispatch, getState) => {
  const state = getState();
  const token = getApplicationToken(state);

  const url = `/applications/${token}/quote/buy_points`;

  return api.patch(url, values).then(() => {
    // dispatch(fetchApplications);
    dispatch(fetchApplicationQuote(token));
  });
  // .catch((response) => console.log('response', response)); // FIXME to think about how to handle the error correctly
};

export const discussQuote = (values) => (dispatch, getState) => {
  const state = getState();
  const token = getApplicationToken(state);

  const url = `/applications/${token}/quote/discuss?app=${app}`;

  return api.patch(url, values).then(() => {
    dispatch(fetchApplicationQuote(token));
  });
  // .catch((response) => console.log('response', response)); // FIXME to think about how to handle the error correctly
};

export const APPLICATION_QUOTE_PDF_PATH = '/applications/:token/quote.pdf';

const fetchApplicationStep = (step) => (token) => () => {
  const url = `/applications/${token}/steps/${step}?app=${app}`;

  return api
    .get(url)
    .then(({ data }) => data)
    .catch((response) => {
      console.log('response', response);
    }); // FIXME to think about how to handle the error correctly
};

const updateApplicationStep = (step) => (values, form) => (dispatch) => {
  const url = `/applications/${values.application.token}/steps/${step}?app=${app}`;
  const lastStep = (type) => (type === 'pre_approval' ? 4 : 5);

  return api
    .patch(url, values)
    .then((response) => {
      const { application } = response.data;
      const nextUrl = `/applications/${application.token}${lastStep(application.type) === step ? '' : `/steps/${step + 1}`}`;
      dispatch(fetchApplicationSuccess(application));
      dispatch(applicationFlowEvent({ type: application.type, step }));
      if (lastStep(application.type) === step) {
        dispatch(appplicationCompleteEvent({ type: application.type, transactionPurpose: application.transactionPurpose }));
      }
      history.push(nextUrl);
    })
    .catch((error) => {
      dispatch(applicationErrorEvent({ step, type: values.application.type, form, error }));
      return throwSubmissionError(error);
    });
};

export const fetchApplicationAuthToken = (authenticationToken, originalUserId) => (dispatch) => {
  const url = `/auth/token?app=${app}`;
  const fetchData = { clientId, clientSecret, authenticationToken, originalUserId };

  return api.post(url, fetchData).then(({ data }) => dispatch(applicationLogin(data.token)));
};

export const withDraw = (token) => (dispatch) => {
  const url = `/applications/${token}/withdraw?app=${app}`;

  return api.patch(url).then((response) => {
    // const requestType = requestTypes.APPLICATION;
    // dispatch(setRequestInProcess(false, requestType));
    const { application } = response.data;
    dispatch(withDrawSuccess(application));
  });
};

export const undoWithDraw = (token) => (dispatch) => {
  const url = `/applications/${token}/undo_withdraw?app=${app}`;

  return api.patch(url).then((response) => {
    const { application } = response.data;
    dispatch(undoWithDrawSuccess(application));
  });
};

export function applicationsTodo(token, data) {
  return (dispatch) => {
    const url = `/applications/${token}/todo?app=${app}`;

    return api
      .patch(url, data)
      .then((response) => {
        const { application } = response.data;
        dispatch(fetchApplicationSuccess(application));
      })
      .catch((res) => {
        if (res.status === 500) errorNotification();
      });
  };
}

export function importApplicationPortfolioRequest(appToken, data) {
  function resolveImportValidateResponse(res) {
    const addPostfix = (postfix, obj) => R.compose(
      R.fromPairs,
      R.map(([key, value]) => ([`${key}${postfix}`, value])),
      R.toPairs,
    )(obj);
    const resolveHeaders = fp.renameKeys({ headers: 'file' });
    const resolveRows = ({ rows = {}, ...rest }) =>
      ({ ...rest, ...addPostfix(' row', rows) });

    const resolveErrors = R.compose(
      resolveHeaders,
      resolveRows,
      resolveServerImportErrors,
    );

    return R.evolve({
      errors: resolveErrors,
      warnings: resolveErrors,
    })(res);
  }

  const mergeWithValidateWarnings = R.compose(
    R.mergeDeepRight,
    R.propOr({}, 'warnings'),
  );

  return async () => {
    const type = R.path(['import', 'type'], data);
    const method = type === 'update_rental_properties' ? 'PATCH' : 'POST';
    const importUrl = `/applications/${appToken}/properties/import?app=${app}`;
    const validateUrl = `/applications/${appToken}/properties/import/validate`;

    const formData = decamelizeObjectToFormData(R.evolve({ import: R.omit(['asset']) }, data));

    formData.append('import[asset]', R.path(['import', 'asset'], data));

    try {
      const validateResponse = await api.post(validateUrl, formData)
        .then(resolveImportValidateResponse);

      if (fp.isDefined(validateResponse.errors)) {
        errorNotification('Issue with import tape. Please double check the property data.');
        return validateResponse;
      }

      const response = await api.request(
        importUrl,
        { data: formData, method },
      ).then(R.compose(
        R.evolve({ warnings: mergeWithValidateWarnings(validateResponse) }),
        resolveImportValidateResponse,
        R.propOr({}, 'data'),
      ));

      const { message } = response;

      if (fp.isDefined(response.warnings)) {
        warnNotification(message);
        return response;
      }

      successNotification(message);
      return response;
    } catch (response) {
      return R.compose(
        resolveImportValidateResponse,
        camelizeKeys,
        R.pick(['warnings', 'errors']),
        R.propOr({}, 'source'),
      )(response);
    }
  };
}

export function updateTransactionPurpose(appToken, transactionPurpose) {
  return () => {
    const url = `/applications/${appToken}/properties/transaction_purpose?app=${app}`;

    return api.post(url, { data: transactionPurpose });
  };
}

export const exportApplicationPortfolioRequest = (appToken) => (dispatch) => {
  const requestType = requestTypes.EXPORT_PROPERTIES;
  const url = `/applications/${appToken}/properties/export?app=${app}`;

  dispatch(setRequestInProcess(true, requestType));
  return api
    .get(url)
    .then((response) => {
      dispatch(setRequestInProcess(false, requestType));
      downloadResponseFile('export_properties.xlsx', response);
    })
    .catch(() => {
      dispatch(setRequestInProcess(false, requestType));
    });
};

export const fetchApplicationAgreements = (token) => () => {
  const url = `/applications/${token}/agreements`;

  return api
    .get(url)
    .then(({ data }) => data)
    .catch((res) => res.satus === 500 && errorNotification());
};

export const createApplicationAgreements = ({ token, ...values }) => () => {
  const url = `/applications/${token}/agreements`;
  return api.post(url, values);
};

export function createApplicationSuccess(payload) {
  return {
    type: actionTypes.APPLICATION_CREATE_SUCCESS,
    payload,
  };
}

export function fetchApplicationSuccess(payload) {
  return {
    type: actionTypes.APPLICATION_FETCH_SUCCESS,
    payload,
  };
}

export function updateApplicationSuccess(payload) {
  return {
    type: actionTypes.APPLICATION_UPDATE_SUCCESS,
    payload,
  };
}

export function fetchApplicationQuoteSuccess(payload) {
  return {
    type: actionTypes.APPLICATION_QUOTE_FETCH_SUCCESS,
    payload,
  };
}

export function fetchApplicationBuyPointsGuidelineSuccess(payload) {
  return {
    type: actionTypes.APPLICATION_BUY_POINTS_GUIDELINE_FETCH_SUCCESS,
    payload,
  };
}

export function withDrawSuccess(payload) {
  return {
    type: actionTypes.WITHDRAW_SUCCESS,
    payload,
  };
}

export function undoWithDrawSuccess(payload) {
  return {
    type: actionTypes.UNDO_WITHDRAW_SUCCESS,
    payload,
  };
}

export const createBridgeApplication = createApplication('bridge');
export const createRentalApplication = createApplication('rental');
export const updatePropertyApplication = updateApplication('property');
export const updateBorrowerApplication = updateApplication('borrower');
export const fetchApplicationFirstStep = fetchApplicationStep(1);
export const updateApplicationFirstStep = updateApplicationStep(1);
export const fetchApplicationSecondStep = fetchApplicationStep(2);
export const updateApplicationSecondStep = updateApplicationStep(2);
export const fetchApplicationThirdStep = fetchApplicationStep(3);
export const updateApplicationThirdStep = updateApplicationStep(3);
export const fetchApplicationFourthStep = fetchApplicationStep(4);
export const updateApplicationFourthStep = updateApplicationStep(4);
export const fetchApplicationFifthStep = fetchApplicationStep(5);
export const updateApplicationFifthStep = updateApplicationStep(5);
