import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, lifecycle, withContext, withHandlers, withState } from 'recompose';
import styled from 'styled-components';

import { styleUtils } from 'utils';

import DocumentsTreeNode from './DocumentsTreeNode';

import * as handlers from './handlers';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const defaultProps = {
  children: null,
  className: undefined,
  size: 'default',
};

function DocumentsTree({ children, className, size }) {
  return (
    <StyledTree size={size} className={className}>
      {children}
    </StyledTree>
  );
}

const StyledTree = styled.ul`
  font-size: ${styleUtils.getSizeValue(1)};
  margin: 0;
  padding: 0;
`;

DocumentsTree.propTypes = propTypes;
DocumentsTree.defaultProps = defaultProps;

const EnhancedDocumentsTree = compose(
  withState('checkedKeys', 'setCheckedKeys', ({ defaultCheckedKeys }) => defaultCheckedKeys || []),
  withState('statuses', 'setStatuses', ({ defaultStatuses }) => defaultStatuses || {}),
  withHandlers({
    checkKey: handlers.checkKey,
    defaultCheckedKeysListener: handlers.defaultCheckedKeysListener,
    defaultStatusesListener: handlers.defaultStatusesListener,
    uncheckKey: handlers.uncheckKey,
    toggleCheck: handlers.toggleCheck,
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { defaultCheckedKeysListener, defaultStatusesListener } = this.props;
      defaultStatusesListener(prevProps);
      defaultCheckedKeysListener(prevProps);
    },
  }),
  withContext(
    {
      checkKey: PropTypes.func,
      checkedKeys: PropTypes.arrayOf(PropTypes.string),
      keys: PropTypes.arrayOf(PropTypes.string),
      statuses: PropTypes.objectOf(PropTypes.string),
      toggleCheck: PropTypes.func,
      uncheckKey: PropTypes.func,
    },
    ({ toggleCheck, checkedKeys, checkKey, uncheckKey, keys, statuses }) => ({
      checkKey,
      checkedKeys,
      keys,
      statuses,
      toggleCheck,
      uncheckKey,
    }),
  ),
  pure,
)(DocumentsTree);

EnhancedDocumentsTree.Node = DocumentsTreeNode;

export default EnhancedDocumentsTree;
