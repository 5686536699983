import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withContext, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Form as FinalForm } from 'react-final-form';
import createFocusDecorator from 'final-form-focus';

import * as actions from 'actions';

import { holdMutators } from 'utils';

import Form from './Form';
import { handlers, utils } from './shared';

const propTypes = {
  initialValues: PropTypes.shape({
    application: PropTypes.shape({
      borrower: PropTypes.objectOf(PropTypes.any),
      dataEntryStep: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      token: PropTypes.string,
      type: PropTypes.string,
    }),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  updateApplicationThirdStep: PropTypes.func.isRequired,
};

const focusOnError = createFocusDecorator();

function PersonalFinancials({ initialValues, loading, updateApplicationThirdStep }) {
  return (
    <FinalForm
      onSubmit={updateApplicationThirdStep}
      initialValues={initialValues}
      subscription={{ submitting: true, pristine: true }}
      mutators={{ ...holdMutators }}
      decorators={[focusOnError]}
      loading={loading}
      component={Form}
    />
  );
}

PersonalFinancials.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
  return {
    fetchApplicationThirdStep: bindActionCreators(actions.fetchApplicationThirdStep, dispatch),
    updateApplicationThirdStep: bindActionCreators(actions.updateApplicationThirdStep, dispatch),
  };
}

const enhance = compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps,
  ),
  withState('loading', 'setLoading', false),
  withState('initialValues', 'setInitialValues', utils.setDefaultInitialValues()),
  withState('isFormDisabled', 'setIsFormDisabled', false),
  withHandlers({
    isFormDisabledSetter: handlers.isFormDisabledSetter,
  }),
  withHandlers({
    mergeInitialValues: handlers.mergeInitialValues,
  }),
  lifecycle({
    async componentDidMount() {
      const { match, fetchApplicationThirdStep, mergeInitialValues, setLoading } = this.props;
      const { token } = match.params;
      setLoading(true);
      await fetchApplicationThirdStep(token).then(mergeInitialValues);
      setLoading(false);
    },
  }),
  withContext(
    {
      isFormDisabled: PropTypes.bool,
    },
    ({ isFormDisabled }) => ({ isFormDisabled }),
  ),
  pure,
);

export default enhance(PersonalFinancials);
