import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ifProp } from 'styled-tools';
import { inc, curry } from 'ramda';

import { Link, CheckList } from 'components/shared';

import { getApplicationEntryStep, getApplicationType } from 'selectors';
import { APPLICATION_STEP_PATH } from 'constants/routes';
import { BRIDGE_FULL_FORM_STEPS, PRE_APPROVAL_FULL_FORM_STEPS, RENTAL_FULL_FORM_STEPS } from 'constants/forms';
import { getPath } from 'utils';

const propTypes = {
  applicationEntryStep: PropTypes.number,
  applicationType: PropTypes.string,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

const defaultProps = {
  applicationEntryStep: 1,
  applicationType: undefined,
};

const getSteps = (type) => {
  if (type === 'pre_approval') return PRE_APPROVAL_FULL_FORM_STEPS;
  if (type === 'credit') return PRE_APPROVAL_FULL_FORM_STEPS;
  if (type === 'rental') return RENTAL_FULL_FORM_STEPS;
  if (type === 'bridge') return BRIDGE_FULL_FORM_STEPS;
  return [];
};

const getStepPath = curry((path, token, step) => getPath(path, { token, step }));
const isActive = curry((current, to) => current.toString() === to.toString());
const isAvailable = curry((appCurrent, to) => appCurrent.toString() >= to.toString());

function Menu({ applicationEntryStep, applicationType, match }) {
  const { token, step } = match.params;
  const getStepPathWithToken = getStepPath(APPLICATION_STEP_PATH, token);
  const isActiveWithCurrent = isActive(step);
  const isAvailableWithAppCurrent = isAvailable(applicationEntryStep);
  const steps = getSteps(applicationType);

  return (
    <div>
      <MenuMessage>
        Please continue with your Full Application. All your prior information has been pre-filled and saved to your
        account.
      </MenuMessage>
      <CheckList size="small">
        {steps.map(({ key, text }, idx) => (
          <CheckList.Item key={key} checked={isAvailableWithAppCurrent(inc(idx))}>
            <MenuLink
              key={`${key}-link`}
              disabled={!isAvailableWithAppCurrent(inc(idx))}
              active={isActiveWithCurrent(inc(idx))}
              to={getStepPathWithToken(inc(idx))}
            >
              {text}
            </MenuLink>
          </CheckList.Item>
        ))}
      </CheckList>
    </div>
  );
}

const MenuLink = styled((props) => <Link underline={false} {...props} />)`
  font-size: 15px;
  line-height: 28px;
  color: ${ifProp('active', '#111111', '#888888')};
  &:hover {
    color: #111111;
  }
`;

const MenuMessage = styled.span`
  display: block;
  margin-bottom: 1.125rem;
  padding-right: 2.8125rem;
  font-size: 0.75rem;
  color: #7d7d7d;
`;

Menu.propTypes = propTypes;
Menu.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  const applicationEntryStep = getApplicationEntryStep(state);
  const applicationType = getApplicationType(state);
  return { applicationEntryStep, applicationType };
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
  pure,
);

export default enhance(Menu);
