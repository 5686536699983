import { find, prop, map, pipe, curry } from 'ramda';

const safeMap = curry((fn, value) => {
  if (value) return map(fn, value);
  return [];
});

function useMultiSimpleValueSelect({ onChange, value, options, getOptionValue = prop('value') }) {
  const fn = (v) => find((opt) => getOptionValue(opt) === v, options);
  const multiValue = safeMap(fn, value);

  const multiOnChange = pipe(
    safeMap(getOptionValue),
    onChange,
  );

  return [multiValue, multiOnChange];
}

export default useMultiSimpleValueSelect;
