import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure } from 'recompose';
import ScrollToTopOnMount from 'hocs/ScrollToTopOnMount';
import { connect } from 'react-redux';
import Wrapper from 'components/shared/Wrapper';
import { Redirect } from 'react-router-dom';
import { length } from 'ramda';

import { getApplication } from 'selectors';

import { UserHeader } from 'components/blocks/User';
import { UserTemplate } from 'components/templates';
import { Spinner, Link, Button } from 'components/shared';

import { APPLICATION_CONSTRUCTION_BUDGET_PROPERTY_PATH } from 'constants/routes';
import { userDefaultProps, userPropTypes } from 'propTypes';
import { WithUser } from 'hocs';
import { getPath } from 'utils';

import { ApplicationHeader } from '../shared';

const propTypes = {
  ...userPropTypes,
  properties: PropTypes.arrayOf(PropTypes.shape({})),
};

const defaultProps = {
  ...userDefaultProps,
  properties: [],
};

function ConstructionBudgetPage(props) {
  const { application } = props;

  if (application) {
    const { properties, token } = application;

    if (length(properties) === 1) {
      return (
        <Redirect
          to={getPath(APPLICATION_CONSTRUCTION_BUDGET_PROPERTY_PATH, { token, propertyId: properties[0].id })}
        />
      );
    }

    return (
      <UserTemplate header={<UserHeader />}>
        <ApplicationHeader />
        <StyledWrapper>
          <StyledConstructionBudgetPage id="constructionBudgetWrapper">
            {properties &&
              properties.length > 0 &&
              properties.map((property) => (
                <StyledConstructionBudgetLink
                  to={getPath(APPLICATION_CONSTRUCTION_BUDGET_PROPERTY_PATH, { token, propertyId: property.id })}
                >
                  <StyledConstructionBudgetRow>
                    <div>{property.address}</div>
                    <div>
                      <Button xs rounded bordered>
                        Complete Construction Budget
                      </Button>
                    </div>
                  </StyledConstructionBudgetRow>
                </StyledConstructionBudgetLink>
              ))}
          </StyledConstructionBudgetPage>
        </StyledWrapper>
      </UserTemplate>
    );
  }

  return <Spinner />;
}

const StyledWrapper = styled(Wrapper)`
  margin-top: 1.25rem;
`;

const StyledConstructionBudgetPage = styled.div`
  width: 100%;
`;

const StyledConstructionBudgetLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

const StyledConstructionBudgetRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dce1ec;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #504f4d;
  &:hover {
    background: #f7f8f8;
  }
`;

ConstructionBudgetPage.propTypes = propTypes;
ConstructionBudgetPage.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  application: getApplication(state),
});

const enhance = compose(
  ScrollToTopOnMount,
  WithUser,
  connect(mapStateToProps),
  pure,
);

export default enhance(ConstructionBudgetPage);
