import styled from 'styled-components';

import DocAction from '../DocAction';

const DocActions = styled.div`
  display: flex;

  ${DocAction} {
    display: block;
    margin-left: 0.5rem;

    &:first-child {
      margin-left: 0;
    }
  }
`;

export default DocActions;
