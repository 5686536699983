export const STATUSES = [
  'lend',
  'incomplete_app',
  'application',
  'quote ',
  'preliminary_review',
  'initial_underwriting',
  'pending_approval',
  'initial_terms',
  'final_terms',
  'issued',
  'initial_signed_term_sheet',
  'final_signed_term_sheet',
  'clear_to_close',
  'funding_requested',
  'funding_reviewed',
  'funding_submitted',
  'funding_sent',
  'funding_authorized',
  'setup_servicing',
  'closed',
];
