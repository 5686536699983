import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import { space } from 'styled-system';

import Button from 'components/shared/Button';

const propTypes = {
  disabled: PropTypes.bool,
  onLoadFiles: PropTypes.func.isRequired,
  isImporting: PropTypes.bool,
  isEditAddress: PropTypes.bool,
  importTemplateUrl: PropTypes.string.isRequired,
};

const defaultProps = {
  disabled: false,
  isImporting: false,
  isEditAddress: true,
};

function Import({ disabled, onLoadFiles, isImporting, isEditAddress, importTemplateUrl }) {
  return (
    <div>
      {isEditAddress && (
        <>
          <Action bordered palette="secondary" mr="0.8rem" xs href={importTemplateUrl}>
            Download Template
          </Action>
          <Dropzone accept=".xlsx" multiple={false} onDrop={onLoadFiles} style={{ display: 'inline-block' }}>
            <Action bordered xs disabled={isImporting || disabled}>
              {isImporting ? 'Please Wait...' : 'Import'}
            </Action>
          </Dropzone>
        </>
      )}
    </div>
  );
}

const Action = styled(Button)`
  ${space}
`;

Import.propTypes = propTypes;
Import.defaultProps = defaultProps;

export default Import;
