import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'grid-styled';
import { ifProp, palette } from 'styled-tools';

export const Row = styled(Flex)`
  border-top: 0.0625rem solid ${palette('grayscale', 5)};
`;

export const Header = styled(Row)`
  font-weight: bold;
  border-top: none;
  border-bottom: 0.0625rem solid ${palette('grayscale', 5)};
`;

const StyledBox = styled(({ textRight, ...props }) => <Box {...props} />)``;

const Cell = styled(StyledBox).attrs({
  textRight: ({ textRight }) => textRight || false,
  p: ({ p }) => p || '0.5rem',
})`
  text-align: ${ifProp('textRight', 'right', 'left')};
`;

export const Column1 = styled((props) => <Cell flex="1" {...props} />)``;
export const Column2 = styled((props) => <Cell textRight width={1 / 5} {...props} />)``;
export const Column3 = styled((props) => <Cell textRight width={1 / 5} {...props} />)``;
export const Column4 = styled((props) => <Cell textRight width={1 / 12} {...props} />)``;
export const Column5 = styled((props) => <Cell textRight width={1 / 6} {...props} />)``;
