import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

const propTypes = {
  reverse: PropTypes.bool,
  fz: PropTypes.number,
};

const defaultProps = {
  reverse: false,
  fz: undefined,
};

const fontSize = ({ fz }) => (fz ? `${fz}rem` : 'inherit');

const Paragraph = styled.p`
  font-size: ${fontSize};
  line-height: 1.3em;
  margin: 1rem 0 0;
  ${ifProp(
    'center',
    css`
      text-align: center;
    `,
  )};
`;

Paragraph.propTypes = propTypes;
Paragraph.defaultProps = defaultProps;
export default Paragraph;
