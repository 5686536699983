import React from 'react';
import PropTypes from 'prop-types';

import useMultiSimpleValueSelect from './useMultiSimpleValueSelect';

import ReactSelect from './index';

const valuePropType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(valuePropType),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: valuePropType,
      label: PropTypes.string,
    }),
  ),
};

const defaultProps = {
  value: [],
  options: [],
};

function SimpleValueAdapter(props) {
  const [value, onChange] = useMultiSimpleValueSelect(props);
  return <ReactSelect {...props} onChange={onChange} value={value} />;
}

SimpleValueAdapter.propTypes = propTypes;
SimpleValueAdapter.defaultProps = defaultProps;

export default SimpleValueAdapter;

