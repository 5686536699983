import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';

import { Title } from '../shared';

import ToDo from './ToDo';

const propTypes = {
  collection: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
  collection: [],
};

function ToDos({ collection }) {
  return (
    <Container data-name="toDo">
      <Title>To Do</Title>
      {collection.length > 0 ? (
        <ToDoContainer>
          {collection.map((key) => (
            <div className="to_do">
              <ToDo key={key} toDoKey={key} />
            </div>
          ))}
        </ToDoContainer>
      ) : (
        <div>No Tasks</div>
      )}
    </Container>
  );
}

const ToDoContainer = styled.div`
  position: relative;

  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Container = styled.div`
  padding: 2.1875rem 5.1875rem 2.1875rem 0;

  @media (max-width: ${prop('theme.breakpoints.1')}) {
    padding: 2.1875rem 0;
  }
`;

ToDos.propTypes = propTypes;
ToDos.defaultProps = defaultProps;

export default ToDos;
