import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { palette } from 'styled-tools';

const propTypes = {
  level: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  palette: PropTypes.string,
};

const defaultProps = {
  level: 1,
  palette: 'grayscale',
};

const fontSize = ({ level }) => `${0.75 + 1 * (1 / level)}rem`;

const styles = css`
  margin: 0.85714em 0 0.57142em;
  font-weight: 500;
  font-size: ${fontSize};
  color: ${palette('grayscale', 2)};
`;

const Heading = styled(({ level, children, palette, theme, ...props }) =>
  React.createElement(`h${level}`, props, children),
)`
  ${styles};
`;

Heading.propTypes = propTypes;
Heading.defaultProps = defaultProps;
export default Heading;
