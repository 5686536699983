import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, theme, palette } from 'styled-tools';

const propTypes = {
  count: PropTypes.number.isRequired,
  current: PropTypes.number,
  done: PropTypes.bool,
  palette: PropTypes.string,
  height: PropTypes.number,
};

const defaultProps = {
  current: 1,
  done: false,
  palette: 'primary',
  height: 40,
};

const isCurrent = (index, current) => index + 1 === current;
const isDone = (index, current, done) => index + 1 < current || done;

function Progress({ current, count, done, ...props }) {
  const items = [];

  for (let i = 0; i < count; i++) {
    items.push(
      <Item current={isCurrent(i, current)} done={isDone(i, current, done)} key={`item${i}`} {...props}>
        {i + 1}
      </Item>,
    );
  }
  return <Container {...props}>{items}</Container>;
}

const Container = styled.ol`
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: ${theme('fonts.base')};
`;

const currentStyle = css`
  background: #fff;
  border-color: ${palette('primary', 1)};
`;

const doneStyle = css`
  font-weight: bold;
  color: #fff;
  background: ${palette('primary', 1)};
  border-color: ${palette('primary', 1)};
  &:after {
    background-color: ${palette('primary', 1)};
  }
`;

const Item = styled.li`
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  margin: 0 15px 0 0;
  padding: 0;
  width: 30px;
  height: 30px;
  color: #504f4d;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  border: 4px solid #e8e8e8;
  border-radius: 50%;
  background-color: #e8e8e8;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: -19px;
    margin-top: -2px;
    width: 15px;
    height: 4px;
    background: #e8e8e8;
  }
  &:last-child {
    &:after {
      display: none;
    }
  }
  ${ifProp('current', currentStyle)};
  ${ifProp('done', doneStyle)};
`;

Progress.propTypes = propTypes;
Progress.defaultProps = defaultProps;
export default Progress;
