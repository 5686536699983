import React, { Component } from 'react';
import styled from 'styled-components';

import Icon from 'components/shared/Icon';
import Input from 'components/shared/formControls/Input';

class PercentInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      initialValue: this.props.value || '',
      editable: this.props.editable ? this.props.editable : false,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value && !this.props.editable) {
      this.setState({
        ...this.state,
        value: nextProps.value,
        initialValue: nextProps.value,
        editable: false,
      });
    }
  }
  handleChange = (e) => {
    let { value } = e.target;
    value = value.replace(/[^0-9.,]/, '');
    value = value > 100 ? 100 : value;
    this.setState({ value });
  };
  handleBlur = (e) => {
    const { value } = e.target;
    const { onChange } = this.props;
    const { initialValue } = this.state;
    this.setState({ editable: false });
    if (onChange && value !== initialValue) {
      onChange(value);
    }
  };
  check = () => {
    this.setState({ editable: false });
    if (this.props.onChange) {
      this.props.onChange(this.state.value);
    }
  };
  edit = () => {
    this.setState({ editable: true });
  };
  render() {
    const { value, editable } = this.state;
    const { readOnly, className } = this.props;
    return (
      <div className={`reschedule_table__cell ${className}`}>
        {!readOnly ? (
          <div className="editable-cell">
            {editable ? (
              <div className="editable-cell-input-wrapper">
                <Input
                  value={value && parseFloat(value)}
                  onChange={this.handleChange}
                  rate={26}
                  type="text"
                  autoFocus
                  onPressEnter={this.check}
                  onBlur={this.handleBlur}
                  typeOfMask="percent"
                />
                <Icon icon="angle" className="editable-cell-icon-check" onClick={this.check} />
              </div>
            ) : (
              <div className="text-right">
                {value ? (
                  <div>
                    {`${parseFloat(value).toLocaleString('en-US')}%`}
                    <span className="editable-cell-indicator">Editable</span>
                  </div>
                ) : (
                  <span className="click_to_edit">click to edit</span>
                )}
                <Anchor className="editable-cell-link" onClick={this.edit} />
              </div>
            )}
          </div>
        ) : (
          <div className="text-right">
            {value ? `${parseFloat(value).toLocaleString('en-US')}%` : <span className="click_to_edit">N/A</span>}
          </div>
        )}
      </div>
    );
  }
}

const Anchor = styled.a``;

export default PercentInput;
