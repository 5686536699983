import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { UserDropdown } from 'components/shared/User';
import { UserNavigation } from 'components/blocks/User';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: null,
};

function UserHeader(props) {
  const { className } = props;
  return (
    <Header className={className}>
      <HeaderContainer>
        <HeaderNavigation>
          <UserNavigation />
          <UserDropdown />
        </HeaderNavigation>
      </HeaderContainer>
    </Header>
  );
}

const Header = styled.div`
  position: relative;
  box-shadow: none;
  border-bottom: 1px solid #d7dde9;
  box-sizing: border-box;
  background: #fff;
  padding: 5px 0;
`;

const HeaderContainer = styled.div`
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
`;

const HeaderNavigation = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.625rem;
  box-sizing: border-box;
`;

UserHeader.propTypes = propTypes;
UserHeader.defaultProps = defaultProps;

export default UserHeader;
