import styled from 'styled-components';

const Title = styled.strong`
  display: block;
  margin-bottom: 25px;
  margin-top: 4px;
  text-align: center;
  line-height: 30px;
  color: #fff;
  font-weight: 300;
  font-size: 30px;
`;

export default Title;
