import React from 'react';
import { compose, pure } from 'recompose';
import ScrollToTopOnMount from 'hocs/ScrollToTopOnMount';

import { IframeTemplate } from 'components/templates';
import { Application } from 'components/blocks';

function ApplicationsPage() {
  return (
    <IframeTemplate>
      <Application />
    </IframeTemplate>
  );
}

const enhance = compose(
  ScrollToTopOnMount,
  pure,
);

export default enhance(ApplicationsPage);
