import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, pure, getContext } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, useField } from 'react-final-form';
import { withRouter } from 'react-router-dom';

import * as actions from 'actions';
import { getBridgeFullFormSecondStepPropertiesRefs, getConstructionBudgetDocumentCategory } from 'selectors';

import {
  AddressFormGroupAdapter,
  Condition,
  FormBox,
  FormFlex,
  FormGeocoderProvider,
  FormGroup,
  FormGroupAdapter,
  RadioGroupAdapter,
} from 'components/shared/form';
import { Radio } from 'components/shared/formControls';
import { DocCategory } from 'components/blocks';
import { AddressFields } from 'components/Application/StepsPage/shared';
import { utils } from 'components/shared';

import { validateRequired } from 'utils';

const propTypes = {
  constructionBudgetDocumentCategory: PropTypes.objectOf(PropTypes.any),
  fields: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
  isFormDisabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  refs: PropTypes.shape({
    actualTypesCollection: PropTypes.arrayOf(PropTypes.object),
    occupanciesCollection: PropTypes.arrayOf(PropTypes.object),
    exitStrategiesCollection: PropTypes.arrayOf(PropTypes.object),
    constructionBudgetsCollection: PropTypes.arrayOf(PropTypes.object),
    systemTypesCollection: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  isEditAddress: PropTypes.bool,
};

const defaultProps = {
  constructionBudgetDocumentCategory: undefined,
  isEditAddress: false,
};

function BridgeArrayFields({
  isFormDisabled,
  constructionBudgetDocumentCategory,
  index,
  fields,
  refs,
  name,
  isEditAddress,
}) {
  const propertyTypeField = useField(`${name}.type`);

  return (
    <Fragment>
      <FormGeocoderProvider namespace={name}>
        <FormFlex>
          <FormBox width={1}>
            <Field
              name={`${name}.address`}
              required
              disabled={isFormDisabled || !isEditAddress}
              isDisabled={isFormDisabled || !isEditAddress}
              label="Address"
              validate={validateRequired()}
              component={AddressFormGroupAdapter}
            />
          </FormBox>
        </FormFlex>

        <AddressFields />
      </FormGeocoderProvider>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={`${name}.type`}
            label="Property Type"
            type="select"
            options={refs.actualTypesCollection}
            required
            isDisabled={isFormDisabled}
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={`${name}.occupancy`}
            label="Is the Property Leased?"
            type="select"
            options={refs.occupanciesCollection}
            required
            isDisabled={isFormDisabled}
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name={`${name}.exitStrategy`}
            label="Exit Strategy"
            type="select"
            options={refs.exitStrategiesCollection}
            required
            isDisabled={isFormDisabled}
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <Condition when={`${name}.type`} reverse is={['land']}>
        <FormFlex>
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              name={`${name}.unitsCount`}
              label="# of Units"
              typeOfMask="number"
              required={utils.isUnitsCountRequired(propertyTypeField.input.value)}
              disabled={isFormDisabled}
              validate={(value, allValues) => utils.unitsCountValidate(value, allValues, index)}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>
      </Condition>

      <Condition when="application.transactionPurpose" is={['purchase']}>
        <FormFlex>
          <FormBox width={1}>
            <Field
              component={RadioGroupAdapter}
              disabled={isFormDisabled}
              inline
              label="Is the property located within 30 minutes of your home or another investment you own/have owned?"
              name={`${name}.nearLocated`}
              type="checkbox"
            >
              <Radio value="true" id={`${name}.nearLocatedTrue`} label="Yes" />
              <Radio value="false" id={`${name}.nearLocatedFalse`} label="No" />
            </Field>
          </FormBox>
        </FormFlex>

        <FormFlex>
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              name={`${name}.purchasePrice`}
              label="Purchase Price"
              typeOfMask="currency"
              required
              disabled={isFormDisabled}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              name={`${name}.repairValue`}
              label="Repair Cost"
              typeOfMask="currency"
              required
              disabled={isFormDisabled}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              name={`${name}.completedRepairsValue`}
              label="Repairs Completed"
              typeOfMask="currency"
              required
              disabled={isFormDisabled}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>

        <FormFlex>
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              name={`${name}.afterRepairValue`}
              label="Market Value After Repairs (ARV)"
              typeOfMask="currency"
              required
              disabled={isFormDisabled}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>
      </Condition>

      <Condition when="application.transactionPurpose" is={['refinance']}>
        <FormFlex>
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              name={`${name}.purchasePrice`}
              label="Purchase Price"
              typeOfMask="currency"
              required
              disabled={isFormDisabled}
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              name={`${name}.purchaseDate`}
              label="Purchase Date"
              type="date"
              disabled={isFormDisabled}
              component={FormGroupAdapter}
            />
          </FormBox>
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              name={`${name}.existingLoanAmount`}
              label="Existing Loan Amount"
              typeOfMask="currency"
              disabled={isFormDisabled}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>

        <FormFlex>
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              component={RadioGroupAdapter}
              disabled={isFormDisabled}
              inline
              label="Are repairs 100% complete?"
              name={`${name}.repairsCompleted`}
              required
              type="checkbox"
              validate={validateRequired()}
            >
              <Radio value="true" id={`${name}.nearLocatedTrue`} label="Yes" />
              <Radio value="false" id={`${name}.nearLocatedFalse`} label="No" />
            </Field>
          </FormBox>

          <Condition allowNull={false} when={`${name}.repairsCompleted`} is={['false']}>
            <FormBox width={[1, 1, 1 / 3]}>
              <Field
                name={`${name}.repairValue`}
                label="Repair Cost"
                typeOfMask="currency"
                required
                disabled={isFormDisabled}
                validate={validateRequired()}
                component={FormGroupAdapter}
              />
            </FormBox>
          </Condition>
        </FormFlex>

        <Condition allowNull={false} when={`${name}.repairsCompleted`} is={['false']}>
          <FormFlex>
            <FormBox width={[1, 1, 1 / 3]}>
              <Field
                name={`${name}.completedRepairsValue`}
                label="Repairs Completed"
                typeOfMask="currency"
                required
                disabled={isFormDisabled}
                validate={validateRequired()}
                component={FormGroupAdapter}
              />
            </FormBox>
            <FormBox width={[1, 1, 1 / 3]}>
              <Field
                name={`${name}.afterRepairValue`}
                label="Market Value After Repairs (ARV)"
                typeOfMask="currency"
                required
                disabled={isFormDisabled}
                validate={validateRequired()}
                component={FormGroupAdapter}
              />
            </FormBox>
          </FormFlex>
        </Condition>
      </Condition>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name={`${name}.repairsDescription`}
            label="Can you please describe what repairs you are going to complete? Are you adding SF?"
            type="textarea"
            disabled={isFormDisabled}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name={`${name}.constructionBudget`}
            label="How did you arrive at your construction budget?"
            type="select"
            options={refs.constructionBudgetsCollection}
            isDisabled={isFormDisabled}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      {constructionBudgetDocumentCategory && (
        <Field name={`${fields.name}[${index}].id`} subscription={{ value: true }}>
          {({ input: { value } }) =>
            value && (
              <FormFlex>
                <FormBox width={1}>
                  <FormGroup>
                    <DocCategory
                      isDisabled={isFormDisabled}
                      targetId={value}
                      category={constructionBudgetDocumentCategory}
                    />
                  </FormGroup>
                </FormBox>
              </FormFlex>
            )
          }
        </Field>
      )}

      <Condition when="application.transactionPurpose" is={['purchase']}>
        <FormFlex>
          <FormBox width={1}>
            <Field
              component={RadioGroupAdapter}
              disabled={isFormDisabled}
              inline
              label="Have you been inside the property?"
              name={`${name}.beenInside`}
              type="checkbox"
            >
              <Radio value="true" id={`${name}.beenInsideTrue`} label="Yes" />
              <Radio value="false" id={`${name}.beenInsideFalse`} label="No" />
            </Field>
          </FormBox>
        </FormFlex>

        <FormFlex>
          <FormBox width={1}>
            <Field
              component={RadioGroupAdapter}
              disabled={isFormDisabled}
              inline
              label="Can we obtain access to the home to conduct an appraisal or inspection?"
              name={`${name}.accessible`}
              type="checkbox"
            >
              <Radio value="true" id={`${name}.accessibleTrue`} label="Yes" />
              <Radio value="false" id={`${name}.accessibleFalse`} label="No" />
            </Field>
          </FormBox>
        </FormFlex>
      </Condition>
    </Fragment>
  );
}

BridgeArrayFields.propTypes = propTypes;
BridgeArrayFields.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    constructionBudgetDocumentCategory: getConstructionBudgetDocumentCategory(state),
    refs: getBridgeFullFormSecondStepPropertiesRefs(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createDocument: bindActionCreators(actions.createDocument, dispatch),
    createExplanationDocument: bindActionCreators(actions.createExplanationDocument, dispatch),
  };
}

const enhance = compose(
  withRouter,
  getContext({
    isEditAddress: PropTypes.bool,
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  pure,
);

export default enhance(BridgeArrayFields);
