import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { defaultProps as recomposeDefaultProps } from 'recompose';
import { Field } from 'react-final-form';
import * as R from 'ramda';

import { Alert, Button, Paragraph, Icon, Small, Strong } from 'components/shared';
import { FormGroup, FormBox, FormFlex, FormGroupAdapter } from 'components/shared/form';
import Well, { WellBody } from 'components/shared/Well';

import { validateRequired } from 'utils';

import usePasswordErrors from '../hooks';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitError: PropTypes.string,
  submitting: PropTypes.bool,
};

const defaultProps = {
  submitError: undefined,
  submitting: false,
};

function Form({ handleSubmit, submitError, submitting }) {
  const passwordErrors = usePasswordErrors();

  return (
    <form>
      <Well>
        <WellBody key="body">
          {submitError && (
            <EnganceFormFlex>
              <FormBox width={1}>
                <FormGroup>
                  <Alert palette="danger" size="sm" message={submitError} />
                </FormGroup>
              </FormBox>
            </EnganceFormFlex>
          )}

          <PasswordRequirementsFormFlex flexDirection="column">
            <FormBox mb="1rem">
              <Strong>Password requirements:</Strong>
            </FormBox>
            {passwordErrors.map(({ name, passed, text }) => (
              <FormBox pb="0.5rem" key={`password-error-${name}`} width={1}>
                <FormFlex alignItems="center">
                  <FormBox pl="0" pr="0">
                    <Icon width={0.6} icon={passed ? 'check' : 'cross'} palette={passed ? 'success' : 'danger'} />
                  </FormBox>
                  <FormBox flex="1">
                    <Small>{text}</Small>
                  </FormBox>
                </FormFlex>
              </FormBox>
            ))}
          </PasswordRequirementsFormFlex>

          <EnganceFormFlex>
            <FormBox width={1}>
              <Field
                name="password"
                label="Password"
                type="password"
                required
                rightHint
                validate={validateRequired()}
                component={FormGroupAdapter}
              />
            </FormBox>
          </EnganceFormFlex>
          <EnganceFormFlex>
            <FormBox width={1}>
              <Field
                name="passwordConfirmation"
                label="Password confirmation"
                type="password"
                required
                rightHint
                validate={validateRequired()}
                component={FormGroupAdapter}
              />
              <Paragraph>After successful password change, you will be redirected to Sign In page.</Paragraph>
            </FormBox>
          </EnganceFormFlex>

          <EnganceFormFlex justifyContent="space-between">
            <FormBox mt={2}>
              <Button
                onClick={handleSubmit}
                type="submit"
                rounded
                disabled={submitting || R.not(R.all(R.prop('passed'), passwordErrors))}
              >
                {submitting ? 'Please wait…' : 'Update Password'}
              </Button>
            </FormBox>
          </EnganceFormFlex>
        </WellBody>
      </Well>
    </form>
  );
}

const EnganceFormFlex = recomposeDefaultProps({
  maxWidth: '58rem',
})(FormFlex);

const PasswordRequirementsFormFlex = styled(EnganceFormFlex)`
  margin-bottom: 1rem;
`;

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

export default Form;
