import { INDEX_PATH, MY_APPLICATIONS_PATH } from 'constants/routes';

export function getPath(path, params) {
  let result = path;
  Object.keys(params).forEach((key) => {
    result = result.replace(`:${key}`, params[key]);
  });
  return result;
}

export function getFrom(location) {
  const { from } = location.state || { from: { pathname: INDEX_PATH } };
  /** redirect from homepage to active applications */
  if (from.pathname === '/') {
    return { ...from, pathname: getPath(MY_APPLICATIONS_PATH, { status: 'active' }) };
  }
  return from;
}
