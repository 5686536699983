import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'ramda';

import { percentFormat } from 'utils/format';

const propTypes = {
  className: PropTypes.string,
  percent: PropTypes.number,
};

const defaultProps = {
  className: undefined,
  percent: 0,
};

function RoundScale({ className, percent }) {
  return (
    <Container percent={percent} className={className}>
      <Content>
        <Percent>{percentFormat(percent)}</Percent>
        <Label>Complete</Label>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  width: 5.75rem;
  height: 5.75rem;
  border-radius: 50%;
  background: #f5f5f5;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    z-index: 0;
    width: 100%;
    height: ${prop('percent')}%;
    background: #00bd95;
  }
`;

const Content = styled.div`
  z-index: 1;
  margin: auto;
  text-align: center;
`;

const Percent = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.6875rem;
`;

const Label = styled.div`
  font-size: 0.75rem;
`;

RoundScale.propTypes = propTypes;
RoundScale.defaultProps = defaultProps;

export default RoundScale;
