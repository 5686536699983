import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { Redirect, withRouter, Route } from 'react-router-dom';
import * as R from 'ramda';

import { getApplicationPolicy } from 'selectors';

import { MY_APPLICATIONS_PATH } from 'constants/routes';
import { getPath } from 'utils';

const propTypes = {
  isAvailable: PropTypes.bool,
};

const defaultProps = {
  isAvailable: false,
};

function ApplicationPrivateRoute({ component: Component, isAvailable, ...rest }) {
  if (isAvailable) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
  return <Route {...rest} render={() => <Redirect to={getPath(MY_APPLICATIONS_PATH, { status: 'active' })} />} />;
}

ApplicationPrivateRoute.propTypes = propTypes;
ApplicationPrivateRoute.defaultProps = defaultProps;

function mapStateToProps(state, { policy }) {
  const policyValue = getApplicationPolicy(state, policy);

  return {
    isAvailable: R.isNil(policyValue) || policyValue,
  };
}

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
  pure,
);

export default enhance(ApplicationPrivateRoute);
