import { useParams } from 'react-router-dom';

import { exportApplicationPortfolioRequest } from 'actions/applications';
import useAsyncAction from 'hooks/useAsyncAction';

function useExportProperty() {
  const { token } = useParams();

  const [toExport, isExporting] = useAsyncAction(
    () => exportApplicationPortfolioRequest(token),
    [token],
  );

  return {
    isExporting,
    toExport,
  };
}

export default useExportProperty;
