import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

const Footer = styled.div`
  padding: 30px 20px;
  box-sizing: border-box;
  border-top: 1px solid #d5dde9;

  ${ifProp('sticky', css`
    position: sticky;
    bottom: 0;
    background-color: inherit;
  `)};

`;

export default Footer;
