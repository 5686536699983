import { compose, lifecycle, pure, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { getApplicationId, getApplicationBorrowers, getApplicationProperties } from 'selectors';

import { FormGroupAdapter } from 'components/shared/form';

import { WithFinalForm } from 'hocs';

import * as handlers from './handlers';

function mapStateToProps(state) {
  return {
    applicationId: getApplicationId(state),
    borrowers: getApplicationBorrowers(state),
    properties: getApplicationProperties(state),
  };
}

const enhance = compose(
  WithFinalForm,
  connect(mapStateToProps),
  withHandlers({
    resetScope: handlers.resetScope,
    setApplicationScope: handlers.setApplicationScope,
    setBorrowerScope: handlers.setBorrowerScope,
    setPropertyScope: handlers.setPropertyScope,
  }),
  withHandlers({
    contactTypeDecorator: handlers.contactTypeDecorator,
  }),
  withHandlers({
    contactTypeListener: handlers.contactTypeListener,
  }),
  lifecycle({
    componentDidMount() {
      const { contactTypeDecorator } = this.props;
      contactTypeDecorator();
    },
    componentDidUpdate(prevProps) {
      const { contactTypeListener } = this.props;
      contactTypeListener(prevProps);
    },
  }),
  pure,
);

export default enhance(FormGroupAdapter);
