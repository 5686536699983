import * as R from 'ramda';

function displayDataLayerEventCompleted(application) {
  if (R.isNil(application)) return undefined;
  const { type } = application;
  if (type === 'pre_approval') return 'pre_approval completed';
  if (type === 'bridge') return 'bridge completed';
  if (type === 'rental') return 'rental completed';
  return 'fix-n-flip completed';
}

export { displayDataLayerEventCompleted };
