import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { getTargetDocumentsByCategoryId } from 'selectors';

import File from './File';
import Files from './Files';
import Description from './Description';

const propTypes = {
  files: PropTypes.arrayOf(PropTypes.object),
  isTermSheetData: PropTypes.bool,
  isAnotherDoc: PropTypes.bool,
  applicationTermSheetData: PropTypes.objectOf(PropTypes.any),
};

const defaultProps = {
  files: undefined,
  isTermSheetData: false,
  isAnotherDoc: false,
  applicationTermSheetData: {},
};

function Body(props) {
  const { isTermSheetData, applicationTermSheetData, isAnotherDoc } = props;

  if (isAnotherDoc) {
    return null;
  }

  return (
    <Container>
      {isTermSheetData ? (
        <Fragment>
          <StyledFile key={`filename-${applicationTermSheetData.id}`} file={applicationTermSheetData} />
        </Fragment>
      ) : (
        <Fragment>
          <Description {...props} />
          <StyledFiles {...props} />
        </Fragment>
      )}
    </Container>
  );
}

const Container = styled.div``;

const StyledFiles = styled(Files)`
  padding-top: 0.6rem;
`;

const StyledFile = styled(File)``;

Body.propTypes = propTypes;
Body.defaultProps = defaultProps;

const mapStateToProps = (state, { categoryId, targetId }) => {
  const files = getTargetDocumentsByCategoryId(state, { categoryId, targetId });

  return {
    files,
  };
};

const enhance = compose(connect(mapStateToProps));

export default enhance(Body);
