import * as R from 'ramda';

function setKey(key, keys, keySetter) {
  if (R.isNil(key) || R.isEmpty(key)) return;
  R.pipe(
    R.unless(R.includes(key), R.append(key)),
    keySetter,
  )(keys);
}

function unsetKey(key, keys, keySetter) {
  if (R.isNil(key) || R.isEmpty(key)) return;

  R.pipe(
    R.reject(R.equals(key)),
    keySetter,
  )(keys);
}

function toggleKey(key, keys, keySetter) {
  if (R.isNil(key) || R.isEmpty(key)) return;
  if (R.includes(key, keys)) {
    unsetKey(key, keys, keySetter);
    return;
  }

  setKey(key, keys, keySetter);
}

function checkKeyHandler({ setCheckedKeys, checkedKeys }) {
  return (key) => {
    setKey(key, checkedKeys, setCheckedKeys);
  };
}

function uncheckKeyHandler({ setCheckedKeys, checkedKeys }) {
  return (key) => {
    unsetKey(key, checkedKeys, setCheckedKeys);
  };
}

function toggleCheckHandler({ checkedKeys, setCheckedKeys }) {
  return (key) => {
    toggleKey(key, checkedKeys, setCheckedKeys);
  };
}

function expandKeyHandler({ setExpandedKeys, expandedKeys }) {
  return (key) => {
    setKey(key, expandedKeys, setExpandedKeys);
  };
}

function unexpandKeyHandler({ setExpandedKeys, expandedKeys }) {
  return (key) => {
    unsetKey(key, expandedKeys, setExpandedKeys);
  };
}

function toggleExpandHandler({ expandedKeys, setExpandedKeys }) {
  return (key) => {
    toggleKey(key, expandedKeys, setExpandedKeys);
  };
}

function defaultCheckedKeysListenerHandler({ setCheckedKeys, defaultCheckedKeys }) {
  return (prevProps) => {
    if (R.equals(defaultCheckedKeys, prevProps.defaultCheckedKeys)) return;
    setCheckedKeys(defaultCheckedKeys);
  };
}

function defaultExpandedKeysListenerHandler({ setExpandedKeys, defaultExpandedKeys }) {
  return (prevProps) => {
    if (R.equals(defaultExpandedKeys, prevProps.defaultExpandedKeys)) return;
    setExpandedKeys(defaultExpandedKeys);
  };
}

function defaultStatusesListenerHandler({ defaultStatuses, setStatuses }) {
  return (prevProps) => {
    if (R.equals(defaultStatuses, prevProps.defaultStatuses)) return;
    setStatuses(defaultStatuses);
  };
}

export {
  checkKeyHandler as checkKey,
  defaultCheckedKeysListenerHandler as defaultCheckedKeysListener,
  defaultExpandedKeysListenerHandler as defaultExpandedKeysListener,
  defaultStatusesListenerHandler as defaultStatusesListener,
  expandKeyHandler as expandKey,
  toggleCheckHandler as toggleCheck,
  toggleExpandHandler as toggleExpand,
  uncheckKeyHandler as uncheckKey,
  unexpandKeyHandler as unexpandKey,
};
