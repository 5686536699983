import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { userDefaultProps, userPropTypes } from 'propTypes';

const propTypes = {
  ...userPropTypes,
  className: PropTypes.string,
};

const defaultProps = {
  ...userDefaultProps,
  className: null,
};

function UserCircle({ className, user }) {
  const initials =
    user.contact.firstName.substring(1, 0).toUpperCase() + user.contact.lastName.substring(1, 0).toUpperCase();
  return <Container className={className}>{initials}</Container>;
}

UserCircle.propTypes = propTypes;
UserCircle.defaultProps = defaultProps;

const Container = styled.div`
  overflow: hidden;
  width: 2.1875rem;
  height: 2.1875rem;
  font-size: 1.0625rem;
  color: #898e95;
  line-height: 2.1875rem;
  min-width: 2.1875rem;
  border: 1px solid #d5dde9;
  border-radius: 100%;
  text-align: center;
`;

export default UserCircle;
