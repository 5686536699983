import React from 'react';
import { connect } from 'react-redux';

import { getApplicationsCollection } from 'selectors';

function CheckApplicationsForExistence(Component) {
  function Wrapp(props) {
    return <Component {...props} />;
  }

  function mapStateToProps(state) {
    const applications = getApplicationsCollection(state);
    if (!applications) return { applicationsExist: false };
    const applicationsExist = applications.length > 0;

    return {
      applicationsExist,
    };
  }

  return connect(mapStateToProps)(Wrapp);
}

export default CheckApplicationsForExistence;
