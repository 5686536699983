import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import IconButton from 'components/shared/IconButton';

import File from './File';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  open: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

function Template({ onChange, open, name, value }) {
  return (
    <Container>
      {value &&
        value.length > 0 && (
          <Files>
            {value.map((file) => (
              <File value={value} onChange={onChange} file={file} key={`file-${name}-${file.id || file.name}`} />
            ))}
          </Files>
        )}
      <IconButton xs rounded onClick={open} icon="plus">
        Add file
      </IconButton>
    </Container>
  );
}

const Container = styled.div``;

const Files = styled.ul`
  margin: 0 0 0.5rem;
  padding: 0;
  list-style-type: none;
`;

Template.propTypes = propTypes;

export default Template;
