import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withState, withHandlers } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form as FinalForm } from 'react-final-form';
import createFocusDecorator from 'final-form-focus';
import arrayMutators from 'final-form-arrays';

import * as actions from 'actions';

import { holdMutators } from 'utils';

import Form from './Form';
import { handlers } from './shared';

const propTypes = {
  initialValues: PropTypes.shape({
    drawRequest: PropTypes.objectOf(PropTypes.any),
    token: PropTypes.stirng,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  createDrawRequest: PropTypes.func.isRequired,
};

const focusOnError = createFocusDecorator();

function New({ initialValues, loading, createDrawRequest }) {
  return (
    <FinalForm
      onSubmit={createDrawRequest}
      initialValues={initialValues}
      decorators={[focusOnError]}
      mutators={{ ...holdMutators, ...arrayMutators }}
      subscription={{ submitting: true, pristine: true }}
      loading={loading}
      component={Form}
    />
  );
}

New.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
  return {
    fetchNewDrawRequest: bindActionCreators(actions.fetchNewDrawRequest, dispatch),
    createDrawRequest: bindActionCreators(actions.createDrawRequest, dispatch),
  };
}

const enhance = compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps,
  ),
  withHandlers({
    setDefaultInitialValues: handlers.setDefaultInitialValues,
  }),
  withState('loading', 'setLoading', false),
  withState('initialValues', 'setInitialValues', ({ setDefaultInitialValues }) => setDefaultInitialValues()),
  withHandlers({
    mergeInitialValues: handlers.mergeInitialValues,
  }),
  lifecycle({
    async componentDidMount() {
      const { match, fetchNewDrawRequest, mergeInitialValues, setLoading } = this.props;
      const { token } = match.params;
      setLoading(true);
      await fetchNewDrawRequest(token).then(mergeInitialValues);
      setLoading(false);
    },
  }),
  pure,
);

export default enhance(New);
