import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { getTargetDocumentsByCategoryId } from 'selectors';

import Files from './Files';
import Description from './Description';

const propTypes = {
  files: PropTypes.arrayOf(PropTypes.object),
};

const defaultProps = {
  files: undefined,
};

function Body(props) {
  return (
    <Container>
      <Description {...props} />
      <StyledFiles {...props} />
    </Container>
  );
}

const Container = styled.div``;

const StyledFiles = styled(Files)`
  padding-top: 0.6rem;
`;

Body.propTypes = propTypes;
Body.defaultProps = defaultProps;

const mapStateToProps = (state, { categoryId, targetId }) => {
  const files = getTargetDocumentsByCategoryId(state, { categoryId, targetId });

  return {
    files,
  };
};

const enhance = compose(connect(mapStateToProps));

export default enhance(Body);
