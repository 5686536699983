import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import { compose, getContext, pure } from 'recompose';
import { connect } from 'react-redux';

import { getOperatingAgreementDocumentCategory } from 'selectors';

import { Radio } from 'components/shared/formControls';
import { FormBox, FormFlex, FormLegend, RadioGroupAdapter } from 'components/shared/form';

import { validateRequired } from 'utils';

import ApplicationEntity from './Entity';
import Borrowers from './Borrowers';
import Files from './Files';

const propTypes = {
  operatingAgreementDocumentCategory: PropTypes.objectOf(PropTypes.any),
  isFormDisabled: PropTypes.bool.isRequired,
};

const defaultProps = {
  operatingAgreementDocumentCategory: undefined,
};

function Fields({ isFormDisabled, operatingAgreementDocumentCategory }) {
  return (
    <Fragment>
      <LegendFormFlex>
        <FormBox>
          <FormLegend>Entity</FormLegend>
        </FormBox>
      </LegendFormFlex>

      <ApplicationEntity />

      <StyledBorrowers />

      <FormFlex>
        <FormBox width={1}>
          <Field
            component={RadioGroupAdapter}
            disabled={isFormDisabled}
            inline
            label="Do you have an operating agreement?"
            name="application.entity.operatingAgreement"
            required
            type="checkbox"
            validate={validateRequired()}
          >
            <Radio value="true" id="application.entity.operatingAgreementTrue" label="Yes" />
            <Radio value="false" id="application.entity.operatingAgreementFalse" label="No" />
          </Field>
        </FormBox>
      </FormFlex>

      <Files category={operatingAgreementDocumentCategory} />
    </Fragment>
  );
}

const StyledBorrowers = styled(Borrowers)`
  margin-bottom: 1rem;
`;

const LegendFormFlex = styled(FormFlex)`
  margin-bottom: 1rem;
`;

Fields.propTypes = propTypes;
Fields.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    operatingAgreementDocumentCategory: getOperatingAgreementDocumentCategory(state),
  };
}

const enhance = compose(
  getContext({ isFormDisabled: PropTypes.bool }),
  connect(mapStateToProps),
  pure,
);

export default enhance(Fields);
