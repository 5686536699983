import * as actionTypes from 'constants/actionTypes';

const initialState = {
  authorized: false,
  user: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_SET:
      return { ...state, ...action.payload };
    case actionTypes.SESSION_RESET:
      return initialState;
    case actionTypes.PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
