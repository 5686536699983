import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme, palette } from 'styled-tools';

const Wrapper = styled.div`
  font-family: ${theme('fonts.base')};
  color: ${palette('grayscale', 0)};
`;

const Organism = (props) => <Wrapper {...props}>content</Wrapper>;

Organism.propTypes = {
  reverse: PropTypes.bool,
};

export default Organism;
