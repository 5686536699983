import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, lifecycle, pure } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from 'actions';
import { getApplicationsSortedCollection } from 'selectors';

import ScrollToTopOnMount from 'hocs/ScrollToTopOnMount';

import { UserTemplate } from 'components/templates';
import { UserHeader } from 'components/blocks/User';
import { PageTitle, Wrapper } from 'components/shared';

import Application from '../blocks/ApplicationDrawRequests';

const propTypes = {
  applications: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

const defaultProps = {
  applications: [],
};

function DrawRequestsPage({ applications }) {
  return (
    <UserTemplate header={<UserHeader />}>
      <StyledWrapper>
        <PageTitle>Draw Requests</PageTitle>
        {applications.map((application) => (
          <Application key={`draw-request-application-${application.id}`} application={application} />
        ))}
      </StyledWrapper>
    </UserTemplate>
  );
}

const StyledWrapper = styled(Wrapper)`
  max-width: 70rem;
  padding-bottom: 2rem;
`;

DrawRequestsPage.propTypes = propTypes;
DrawRequestsPage.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    applications: getApplicationsSortedCollection(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchDrawRequests: bindActionCreators(actions.fetchDrawRequests, dispatch),
  };
}

const enhance = compose(
  ScrollToTopOnMount,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  lifecycle({
    componentDidMount() {
      const { fetchDrawRequests } = this.props;
      fetchDrawRequests();
    },
  }),
  pure,
);

export default enhance(DrawRequestsPage);
