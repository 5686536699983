import React from 'react';
import PropTypes from 'prop-types';

import { PageTemplate } from 'components/templates';
import { NotFound } from 'components/blocks';

const propTypes = {
  staticContext: PropTypes.objectOf(PropTypes.any),
};

const defaultProps = {
  staticContext: {},
};

const NotFoundPage = ({ staticContext }) => (
  <PageTemplate>
    <NotFound staticContext={staticContext} />
  </PageTemplate>
);

NotFoundPage.propTypes = propTypes;
NotFoundPage.defaultProps = defaultProps;

export default NotFoundPage;
