import * as R from 'ramda';

import { setInDefaultValue } from 'decorators';
import { setIn } from 'utils';

function setDefaultInitialValues(input = {}) {
  return R.pipe(setInDefaultValue([{}], 'application.borrowers'))(input);
}

function valuesSubmitPreparation(values) {
  return setIn(undefined, 'application.entity.files', values);
}

export { setDefaultInitialValues, valuesSubmitPreparation };
