const R = require('ramda');

const config = {
  all: {
    app: 'reiloans',
    env: process.env.REACT_APP_ENV || 'development',
    isDev: process.env.NODE_ENV !== 'production',
    basename: process.env.PUBLIC_PATH,
    host: process.env.HOST || 'localhost',
    port: process.env.PORT || 3001,
    isBrowser: typeof window !== 'undefined',
    isServer: typeof window === 'undefined',
    apiUrl: 'http://api.reiloans.local:3000/v2',
    authUrl: 'http://api.reiloans.local:3000/oauth',
    fbAppId: '991453140998882',
    clientId: process.env.REACT_APP_CLIENT_ID || '',
    clientSecret: process.env.REACT_APP_CLIENT_SECRET || ',',
    environmentToken: '7e7e65ab72e94134a5689f50cc701a16',
  },
  test: {
    isProd: false,
  },
  development: {
    isProd: false,
    portfolioImportTemplateUrl: 'http://api.reiloans.local:3000/imports/examples/reiloans/portfolio.xlsx',
    bridgeImportTemplateUrl: 'http://api.lendingone.local:3000/imports/examples/bridge_properties.xlsx',
    reScheduleImportTemplateUrl: 'http://api.reiloans.local:3000/imports/examples/reiloans/re_schedule.xlsx',
  },
  staging: {
    isProd: false,
    host: process.env.HOST || 'localhost',
    port: process.env.PORT || 8080,
    apiUrl: 'https://api.staging.lendingone.com/v2',
    authUrl: 'https://api.staging.lendingone.com/oauth',
    portfolioImportTemplateUrl: 'https://api.staging.lendingone.com/imports/examples/reiloans/portfolio.xlsx',
    bridgeImportTemplateUrl: 'https://api.staging.lendingone.com/imports/examples/bridge_properties.xlsx',
    reScheduleImportTemplateUrl: 'https://api.staging.lendingone.com/imports/examples/reiloans/re_schedule.xlsx',
  },
  integration: {
    isProd: false,
    host: process.env.HOST || 'localhost',
    port: process.env.PORT || 8080,
    apiUrl: 'https://api.integration.lendingone.com/v2',
    authUrl: 'https://api.integration.lendingone.com/oauth',
    portfolioImportTemplateUrl: 'https://api.integration.lendingone.com/imports/examples/reiloans/portfolio.xlsx',
    bridgeImportTemplateUrl: 'https://api.integration.lendingone.com/imports/examples/bridge_properties.xlsx',
    reScheduleImportTemplateUrl: 'https://api.integration.lendingone.com/imports/examples/reiloans/re_schedule.xlsx',
  },
  production: {
    isProd: true,
    host: process.env.HOST || 'localhost',
    port: process.env.PORT || 8080,
    apiUrl: 'https://api.reiloans.com/v2',
    authUrl: 'https://api.reiloans.com/oauth',
    portfolioImportTemplateUrl: 'https://api.reiloans.com/imports/examples/reiloans/portfolio.xlsx',
    bridgeImportTemplateUrl: 'https://api.lendingone.com/imports/examples/bridge_properties.xlsx',
    reScheduleImportTemplateUrl: 'https://api.reiloans.com/imports/examples/reiloans/re_schedule.xlsx',
  },
  training: {
    isProd: true,
    host: process.env.HOST || 'localhost',
    port: process.env.PORT || 8080,
    apiUrl: 'https://api.training.lendingone.com/v2',
    authUrl: 'https://api.training.lendingone.com/oauth',
    portfolioImportTemplateUrl: 'https://api.training.lendingone.com/imports/examples/reiloans/portfolio.xlsx',
    bridgeImportTemplateUrl: 'https://api.training.lendingone.com/imports/examples/bridge_properties.xlsx',
    reScheduleImportTemplateUrl: 'https://api.training.lendingone.com/imports/examples/reiloans/re_schedule.xlsx',
  },
};

module.exports = R.mergeDeepRight(config.all, config[config.all.env]);
