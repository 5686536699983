import * as actionTypes from 'constants/actionTypes';
import { initialState } from 'selectors/schedules';
import * as R from 'ramda';

const immutablePropertyUpdate = (fields, field, key) => {
  const index = R.findIndex(R.propEq('key', key), fields);

  return [...fields.slice(0, index), field, ...fields.slice(index + 1)];
};

const immutablePropertyRemove = (fields, key) => {
  const index = R.findIndex(R.propEq('key', key), fields);

  return [...fields.slice(0, index).concat(fields.slice(index + 1))];
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.APPLICATION_SCHEDULES_FETCH_SUCCESS:
      return { ...state, ...payload };
    case actionTypes.APPLICATION_SCHEDULES_RESET:
      return initialState;
    case actionTypes.APPLICATION_SCHEDULES_ADD:
      return {
        ...state,
        properties: payload.data,
      };
    case actionTypes.APPLICATION_SCHEDULES_UPDATE:
      return {
        ...state,
        properties: immutablePropertyUpdate(state.properties, payload.data.property, payload.data.key),
      };
    case actionTypes.APPLICATION_SCHEDULES_REMOVE:
      return {
        ...state,
        properties: immutablePropertyRemove(state.properties, payload.data),
      };
    case actionTypes.APPLICATION_SCHEDULES_UPDATE_IMPORT_ID:
      return {
        ...state,
        importId: payload.data,
      };
    case actionTypes.APPLICATION_SCHEDULES_CHECK_IMPORT_STATUS:
      return {
        ...state,
        importStatus: payload.data,
      };

    default:
      return state;
  }
};
