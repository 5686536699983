import { ENTITIES_FETCH_SUCCESS } from 'constants/actionTypes';
import { initialState } from '../selectors/entities';

export default (state = initialState, action) => {
  switch (action.type) {
    case ENTITIES_FETCH_SUCCESS:
      return action.payload;

    default:
      return state;
  }
};
