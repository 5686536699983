import React from 'react';
import PropTypes from 'prop-types';

import CommonButton from '../Button';
import IconButton from '../IconButton';

const propTypes = {
  icon: PropTypes.string,
};

const defaultProps = {
  icon: undefined,
};

function Button({ icon, ...props }) {
  return icon ? <IconButton icon={icon} {...props} /> : <CommonButton {...props} />;
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
