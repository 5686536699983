import { useState, useEffect } from 'react';
import { useField } from 'react-final-form';
import * as R from 'ramda';

import { PASSWORD_ERRORS_LIST } from 'constants/forms';
import {
  hasCapitalLetterPred,
  hasDigitPred,
  hasLowerCaseLetterPred,
  hasSpecialCharacterPred,
  passwordsMustBeTheSamePred,
  properLengthPred,
} from 'utils';

function usePasswordErrors({
  passwordFieldName = 'password',
  passwordConfirmationFieldName = 'passwordConfirmation',
} = {}) {
  const [passwordErrors, setPasswordErrors] = useState(PASSWORD_ERRORS_LIST);
  const passwordField = useField(passwordFieldName);
  const passwordConfirmationField = useField(passwordConfirmationFieldName);
  const password = R.path(['input', 'value'], passwordField);
  const passwordConfirmation = R.path(['input', 'value'], passwordConfirmationField);

  useEffect(() => {
    setPasswordErrors(
      R.map((error) => {
        if (R.propEq('name', 'properLength', error)) return { ...error, passed: properLengthPred(password) };
        if (R.propEq('name', 'hasCapitalLetter', error)) return { ...error, passed: hasCapitalLetterPred(password) };
        if (R.propEq('name', 'hasLowerCaseLetter', error)) {
          return { ...error, passed: hasLowerCaseLetterPred(password) };
        }
        if (R.propEq('name', 'hasSpecialCharacter', error)) {
          return { ...error, passed: hasSpecialCharacterPred(password) };
        }
        if (R.propEq('name', 'hasDigit', error)) return { ...error, passed: hasDigitPred(password) };
        if (password && passwordConfirmation && R.propEq('name', 'passwordsMustBeTheSame', error)) {
          return { ...error, passed: passwordsMustBeTheSamePred(passwordConfirmation, password) };
        }
        return error;
      }, passwordErrors),
    );
  }, [password, passwordConfirmation]);

  return passwordErrors;
}

export default usePasswordErrors;
