import React from 'react';
import { Strong, Small, Paragraph } from 'components/shared';

function Disclaimer() {
  return (
    <React.Fragment>
      <Paragraph>
        <Strong>The following quote relies on the information you provided being true and accurate. </Strong>
        Both the borrower and property are subject verification and completion of underwriting that meets our lending
        parameters, LTV and underwriting requirements.
      </Paragraph>
      <Paragraph>
        <Strong>
          <Small>Disclaimer:</Small>
        </Strong>
        <br />
        <Small>
          This quote constitutes only a general, non-binding expression of interest on part of the Lender. THIS QUOTE IS
          SUBJECT TO LENDER’S CREDIT, LEGAL, AND INVESTMENT APPROVAL PROCESS AND IS NOT INTENDED TO AND DOES NOT CREATE
          A LEGALLY BINDING COMMITMENT OR OBLIGATION ON PART OF LENDER. The creation of such a legally binding
          commitment or obligation is subject to, among other things, the completion by Lender of an in-depth
          investigation of the proposed investment and Borrower, the results of which are deemed satisfactory by Lender
          and the negotiation, execution and delivery of definitive documents which are mutually agreed upon by Borrower
          and Lender and no occurrence of a material adverse change in business, financial condition, or prospect of
          borrower or any guarantor. This proposal is provided solely for your benefit and shall not be reproduced,
          distributed, quoted, or otherwise made reference to except between the management, officers and legal counsel
          of the borrower.
        </Small>
      </Paragraph>
    </React.Fragment>
  );
}

export default Disclaimer;
