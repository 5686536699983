import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Link from 'components/shared/Link';

const propTypes = {
  active: PropTypes.bool,
  to: PropTypes.string,
};

const defaultProps = {
  active: false,
  to: undefined,
};

function TabLink({ active, to, ...props }) {
  if (active) return <StyledSpan {...props} />;
  return <StyledLink to={to} {...props} />;
}

const style = css`
  text-decoration: none;
  font-size: 0.875rem;
  color: #959494;
  transition: all 0.2s;
  &:hover {
    text-decoration: none;
    color: #111111;
  }
`;

const StyledLink = styled(Link)`
  ${style};
`;

const StyledSpan = styled.span`
  ${style};
  color: #111111;
`;

TabLink.propTypes = propTypes;
TabLink.defaultProps = defaultProps;

export default TabLink;
