import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

export const Table = styled.table`
  margin-bottom: 2.5rem;
  width: 100%;
  table-layout: fixed;
  font-size: 0.875rem;
  color: #504f4d;
  border-collapse: collapse;
  border-spacing: 0;
`;

export const Tr = styled.tr`
  ${ifProp(
    'selected',
    css`
      background: #e6f7ff;
    `,
  )};
`;

export const Th = styled.th`
  padding: 0.4375rem 0.8125rem;
  vertical-align: bottom;
  font-size: 12px;
  text-align: center;
  border-bottom: 2px solid #d5dde9;
`;

export const Td = styled.td`
  padding: 0.4375rem 0.8125rem;
  vertical-align: middle;
  text-align: center;
  border-bottom: 0.0625rem solid #d5dde9;

  ${ifProp(
    'highlight',
    css`
      background: #e6f7ff;
      border-left: 0.0625rem solid #d5dde9;
      border-right: 0.0625rem solid #d5dde9;
    `,
  )};
`;
