import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { updateTransactionPurpose } from 'actions/applications';
import useAsyncAction from 'hooks/useAsyncAction';

import Select from 'components/shared/formControls/Select';

const propTypes = {
  fetchInitialValues: PropTypes.func.isRequired,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
};

const defaultProps = {
  value: undefined,
  isDisabled: false,
  options: [],
};

function TransactionPurpose({ value, isDisabled, fetchInitialValues, options, onFocus, onBlur, ...props }) {
  const [selected, setValue] = useState(value);
  const { token } = useParams();

  const [update, inPropcess] = useAsyncAction(
    (v) => updateTransactionPurpose(token, v),
    [token],
  );

  const onChange = (newValue) => {
    setValue(newValue);
    update(newValue).then(fetchInitialValues);
  };

  return (
    <Select
      {...props}
      isClearable={false}
      isDisabled={isDisabled || inPropcess}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      options={options}
      value={selected}
    />
  );
}

TransactionPurpose.propTypes = propTypes;
TransactionPurpose.defaultProps = defaultProps;

export default TransactionPurpose;
