import React from 'react';
import ScrollToTopOnMount from 'hocs/ScrollToTopOnMount';

import { PageTemplate } from 'components/templates';
import { FormAuthResetPass } from 'components/blocks/Forms/Auth';
import { Wrapper } from 'components/shared';

const ResetPage = () => (
  <PageTemplate>
    <Wrapper>
      <FormAuthResetPass />
    </Wrapper>
  </PageTemplate>
);

export default ScrollToTopOnMount(ResetPage);
