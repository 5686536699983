import React from 'react';
import { FormSpy } from 'react-final-form';

const hoc = (Component) => {
  function WithFinalForm(props) {
    return (
      <FormSpy
        subscription={{ submitErrors: true }}
        render={({ form, submitErrors }) => <Component form={form} submitErrors={submitErrors} {...props} />}
      />
    );
  }

  return WithFinalForm;
};

export default hoc;
