import React from 'react';
import styled from 'styled-components';
import { LogoImage } from 'components/shared';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

// const Logo = styled(LogoImage)`
//   display: block;
// `;

function Loader() {
  return (
    <Container>
      <Wrapper>
        <LogoImage width="200" />
      </Wrapper>
    </Container>
  );
}

export default Loader;
