import styled from 'styled-components';

import DocActions from 'components/blocks/Documents/DocActions';
import DocFiles from 'components/blocks/Documents/DocFiles';

const DocContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1.25rem;
  border-bottom: 0.0625rem solid #dadbdb;

  & ${DocActions} {
    margin-top: 0.625rem;
  }

  & ${DocFiles} {
    margin-top: 0.625rem;
  }
`;

export default DocContainer;
