import React from 'react';
import styled from 'styled-components';

import Icon from 'components/shared/Icon';

function Cross(props) {
  return (
    <button data-name="property-delete" type="button" {...props}>
      <Icon icon="cross" />
    </button>
  );
}

const StyledCross = styled(Cross)`
  margin: 0;
  padding: 0;
  font-size: 1em;
  color: #979797;
  outline: none;
  background: none;
  cursor: pointer;
  border: none;
`;

export default StyledCross;
