import { createSelector } from 'reselect';
import { propOr, prop } from 'ramda';

export const initialState = {};

function requestSelector(state) {
  return state.request;
}

function requestTypeSelector(state, requestType) {
  return requestType;
}

export const getRequest = createSelector([requestTypeSelector, requestSelector], propOr(initialState));
export const requestInProcess = createSelector(getRequest, prop('inProcess'));
export const requestWithErrors = createSelector(getRequest, prop('errors'));

