import { compose, lifecycle, pure } from 'recompose';

function Fields({ children }) {
  return children;
}

const enhance = compose(
  lifecycle({
    componentWillMount() {
      const { unholdValues } = this.props;
      unholdValues();
    },
    componentWillUnmount() {
      const { holdValues } = this.props;
      holdValues();
    },
  }),
  pure,
);

export default enhance(Fields);
