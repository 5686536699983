import React from 'react';
import styled from 'styled-components';

import { Congratulation, Strong } from 'components/shared';
import Well, { WellHeader, WellBody } from 'components/shared/Well';
import { FormHeader } from 'components/shared/form';

function AccessDenied() {
  return (
    <Well>
      <WellHeader key="header">
        <FormHeader title="Access Denied" />
      </WellHeader>

      <WellBody key="body">
        <StyledIncomplete>
          <Congratulation status="warning">
            <Strong>Your user account does not permit applying for a personal loan.</Strong>
          </Congratulation>
        </StyledIncomplete>
      </WellBody>
    </Well>
  );
}

const StyledIncomplete = styled.div`
  margin: auto;
  padding: 30px 0 50px;
  max-width: 545px;
  font-size: 1.125rem;
  line-height: 1.6em;
  color: #666;
`;

export default AccessDenied;
