import { equals } from 'ramda';

function setPropertyScopeHandler({ form, properties }) {
  const { batch, change } = form;
  return () => {
    batch(() => {
      change('contact.targetType', 'Property');
    });
  };
}

function setBorrowerScopeHandler({ form, borrowers }) {
  const { batch, change } = form;
  return () => {
    batch(() => {
      change('contact.targetType', 'Borrower');
    });
  };
}

function setApplicationScopeHandler({ form, applicationId }) {
  const { batch, change } = form;
  return () => {
    batch(() => {
      change('contact.targetType', 'Application');
      change('contact.targetId', applicationId);
    });
  };
}

function resetScopeHandler({ form }) {
  const { batch, change } = form;
  return () => {
    batch(() => {
      change('contact.targetType', null);
      change('contact.targetId', null);
    });
  };
}

function contactTypeListenerHandler({ input, contactTypeDecorator }) {
  return ({ input: prevInput }) => {
    if (equals(prevInput, input)) return;
    contactTypeDecorator();
  };
}

function contactTypeDecoratorHandler({ resetScope, setApplicationScope, setBorrowerScope, setPropertyScope, input }) {
  return () => {
    const { value } = input;

    switch (value) {
      case 'access_contact':
        setPropertyScope();
        break;
      case 'outside_law_firm':
        setBorrowerScope();
        break;
      case 'borrower_attorney':
        setApplicationScope();
        break;
      case 'insurance_company':
        setBorrowerScope();
        break;
      case 'insurance_carrier':
        setBorrowerScope();
        break;
      case 'title_company':
        setPropertyScope();
        break;
      case 'contractor':
        setPropertyScope();
        break;
      case 'current_lender':
        setPropertyScope();
        break;
      case 'buyer_agent':
        setPropertyScope();
        break;
      case 'listing_agent':
        setPropertyScope();
        break;
      case 'inspection_contact':
        setPropertyScope();
        break;
      default:
        resetScope();
    }
  };
}

export {
  contactTypeListenerHandler as contactTypeListener,
  contactTypeDecoratorHandler as contactTypeDecorator,
  resetScopeHandler as resetScope,
  setApplicationScopeHandler as setApplicationScope,
  setBorrowerScopeHandler as setBorrowerScope,
  setPropertyScopeHandler as setPropertyScope,
};
