import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withState } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Redirect } from 'react-router-dom';
import ContentLoader from 'react-content-loader';

import * as actions from 'actions';
import { getApplication, getAuthenticationToken } from 'selectors';

import { PageTemplate } from 'components/templates';
import { Wrapper } from 'components/shared';
import { AccessDenied } from 'components/blocks/LeadForm/shared';

import { ScrollToTopOnMount } from 'hocs';
import { getApplicationPath } from 'utils/applications';

const propTypes = {
  application: PropTypes.objectOf(PropTypes.any),
  isError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const defaultProps = {
  application: undefined,
};

function Content({ application, isLoading, isError }) {
  if (isLoading) {
    return <ContentLoader />;
  }

  if (isError) {
    return <AccessDenied />;
  }

  if (application) {
    const path = getApplicationPath(application);
    return <Redirect to={path} />;
  }

  return <AccessDenied />;
}

function ApplicationAuth(props) {
  return (
    <PageTemplate>
      <Wrapper>
        <Content {...props} />
      </Wrapper>
    </PageTemplate>
  );
}

function mapStateToProps(state) {
  return {
    authenticationToken: getAuthenticationToken(state),
    application: getApplication(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchApplication: bindActionCreators(actions.fetchApplication, dispatch),
  };
}

const enhance = compose(
  ScrollToTopOnMount,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isLoading', 'setIsLoading', true),
  withState('isError', 'setIsError', false),
  lifecycle({
    async componentDidMount() {
      const { fetchApplication, match, setIsLoading, setIsError } = this.props;
      const { token } = match.params;

      try {
        setIsLoading(true);
        await fetchApplication(token);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        setIsError(true);
      }
    },
  }),
  pure,
);

Content.propTypes = propTypes;
Content.defaultProps = defaultProps;

export default enhance(ApplicationAuth);
