import React from 'react';
import styled from 'styled-components';
import { compose, pure } from 'recompose';

import ScrollToTopOnMount from 'hocs/ScrollToTopOnMount';

import { UserTemplate } from 'components/templates';
import { UserHeader } from 'components/blocks/User';
import { Wrapper } from 'components/shared';

import MeForm from './Form';

function MePage() {
  return (
    <UserTemplate header={<UserHeader />}>
      <StyledWrapper>
        <MeForm />
      </StyledWrapper>
    </UserTemplate>
  );
}

const StyledWrapper = styled(Wrapper)`
  max-width: 70rem;
`;

const enhance = compose(
  ScrollToTopOnMount,
  pure,
);

export default enhance(MePage);
