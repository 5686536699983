import React from 'react';
import PropTypes from 'prop-types';
import NavLink from 'react-router-dom/NavLink';
import styled, { css } from 'styled-components';
import { palette } from 'styled-tools';

const propTypes = {
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]),
  children: PropTypes.node.isRequired,
  blank: PropTypes.bool,
  handleClick: PropTypes.func,
  dataName: PropTypes.string,
};

const defaultProps = {
  blank: false,
  url: null,
  handleClick: () => {},
  dataName: null,
};

function NavigationLink({ blank, children, handleClick, url, dataName }) {
  const target = blank ? { target: 'blank' } : false;

  if (url) {
    return (
      <StyledNavLink to={url} {...target}>
        {children}
      </StyledNavLink>
    );
  }

  return (
    <StyledLink onClick={handleClick} data-name={dataName}>
      {children}
    </StyledLink>
  );
}

const style = css`
  text-decoration: none;
  font-size: 0.85em;
  line-height: 14px;
  color: ${palette('grayscale', 3)};
  cursor: pointer;
  -webkit-transition: color cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
  transition: color cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;

  &:hover {
    color: ${palette('grayscale', 3)};
  }

  &.active {
    color: ${palette('grayscale', 3)};
  }
`;

const StyledNavLink = styled(NavLink).attrs({})`
  ${style};
`;

const StyledLink = styled.a`
  ${style};
`;

NavigationLink.propTypes = propTypes;
NavigationLink.defaultProps = defaultProps;

export default NavigationLink;
