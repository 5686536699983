import api from 'utils/api';

import { SETTINGS_FETCH_SUCCESS } from '../constants/actionTypes';
import * as requestTypes from '../constants/requestTypes';

import setRequestInProcess from '../actions/request';

export default () => (dispatch, getState) => {
  const requestType = requestTypes.SETTINGS;
  const url = '/settings';

  const requestInProcess = getState().request[requestType];

  if (requestInProcess) {
    return;
  }

  dispatch(setRequestInProcess(true, requestType));

  api
    .get(url)
    .then((response) => {
      dispatch(fetchSettingsSuccess(response));
      dispatch(setRequestInProcess(false, requestType));
    })
    .catch((errors) => {
      dispatch(setRequestInProcess(false, requestType, errors));
    });
};

function fetchSettingsSuccess(payload) {
  return {
    type: SETTINGS_FETCH_SUCCESS,
    payload,
  };
}
