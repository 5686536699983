import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { applicationPropTypesRequired } from 'propTypes';
import { displayTerm, percentFormat } from 'utils';

import { Table, Td } from './Table';

const propTypes = {
  ...applicationPropTypesRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: null,
};

function MainInfo({ application, className }) {
  return (
    <Table className={className}>
      <tbody>
        {application.type && (
          <tr>
            <Td>Loan Type</Td>
            <Td>
              <span>{application.displayNewConstructionType || application.displayType}</span>
            </Td>
          </tr>
        )}
        {application.properties &&
          application.properties[0] &&
          application.properties[0].address && (
            <tr>
              <Td>Property Address</Td>
              <Td>{application.properties[0].address}</Td>
            </tr>
          )}
        {application.term &&
          !application.isRental && (
            <tr>
              <Td>Term</Td>
              <Td>
                <Strong>{displayTerm(application.term)}</Strong>
              </Td>
            </tr>
          )}
        {application.originationFee &&
          !application.isRental && (
            <tr>
              <Td>Origination Fee</Td>
              <Td>
                <Strong>{percentFormat(application.originationFee)}</Strong>
              </Td>
            </tr>
          )}
      </tbody>
    </Table>
  );
}

const Strong = styled.strong`
  font-weight: bold;
`;

MainInfo.propTypes = propTypes;
MainInfo.defaultProps = defaultProps;

export default MainInfo;
