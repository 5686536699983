import { zipWith, sum, pipe, prop, divide, multiply, propOr } from 'ramda';

export function mergeRatios(ratio1, ratio2) {
  const merge = (a, b) => sum([a, b]);
  return zipWith(merge, ratio1, ratio2);
}

export function displayRatio(ratio) {
  return `${propOr(0, 0, ratio)}/${propOr(0, 1, ratio)}`;
}

export function getRatioPercent(ratio) {
  const divisor = propOr(0, 0, ratio);
  const dividend = prop(1, ratio);
  if (dividend) {
    return pipe(
      divide,
      multiply(100),
    )(divisor, dividend);
  }
  return 0;
}
