import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { validateRequired } from 'utils';

import { FormFlex, FormBox, FormGroupAdapter, FormLegend } from 'components/shared/form';
import IconLink from 'components/shared/IconLink';

const propTypes = {
  fields: PropTypes.objectOf(PropTypes.any).isRequired,
};

const defaultProps = { };
function Entities({ fields }) {
  function addEntity() {
    fields.push('');
  }

  function removeEntity(idx) {
    fields.remove(idx);
  }

  return (
    <Fragment>
      {fields.map((name, idx) => (
        <div key={name}>
          <FormFlex alignItems="center" justifyContent="space-between">
            <FormBox mb="0.5rem" mt="0.8rem">
              <FormLegend>
                Entity #{idx + 1}
              </FormLegend>
            </FormBox>

            {fields.length > 1 && (
              <FormBox>
                <IconLink
                  icon="cross"
                  fz={0.8}
                  onClick={() => removeEntity(idx)}
                />
              </FormBox>
            )}
          </FormFlex>
          <FormFlex alignItems="center">
            <FormBox width={1}>
              <Field
                name={name}
                required
                validate={validateRequired()}
                component={FormGroupAdapter}
              />
            </FormBox>
          </FormFlex>
        </div>
      ))}

      <FormFlex justifyContent="center">
        <FormBox>
          <IconLink fz={0.825} icon="plus" middle bs="dashed" dataName="addEntity" onClick={addEntity}>
            Add another entity
          </IconLink>
        </FormBox>
      </FormFlex>
    </Fragment>
  );
}

Entities.propTypes = propTypes;
Entities.defaultProps = defaultProps;

export default Entities;
