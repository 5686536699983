import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure, lifecycle } from 'recompose';
import ScrollToTopOnMount from 'hocs/ScrollToTopOnMount';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Spinner } from 'components/shared';
import Wrapper from 'components/shared/Wrapper';

import { ScheduleTable, ScheduleImport } from 'components/blocks/Schedule';
import { UserHeader } from 'components/blocks/User';
import { UserTemplate } from 'components/templates';

import { getSchedules, getApplicationPolicy } from 'selectors';
import { userDefaultProps, userPropTypes } from 'propTypes';
import { WithUser } from 'hocs';
import * as actions from 'actions';

import { ApplicationHeader } from '../shared';

import './style.css';

const propTypes = {
  ...userPropTypes,
  applications: PropTypes.shape({}),
  availableToImportReschedule: PropTypes.bool,
};

const defaultProps = {
  ...userDefaultProps,
  applications: {},
  availableToImportReschedule: false,
};

function UserApplicationSchedulePage(props) {
  const { schedule, user, availableToImportReSchedule } = props;
  if (!user) return <Spinner />;
  const schedulesGroups = [
    { label: 'FIX & FLIPS', value: 'fix_and_flips' },
    { label: 'INVESTMENT PROPERTIES OWNED', value: 'investment_properties_owned' },
    { label: 'PRIMARY RESIDENCE & SECOND HOMES', value: 'primary_residence' },
  ];
  return (
    <UserTemplate header={<UserHeader />}>
      <ApplicationHeader />
      <StyledWrapper>
        {availableToImportReSchedule && <ScheduleImport targetContactId={schedule.targetContactId} />}
        <div id="rescheduleWrapper">
          <br />
          {schedulesGroups.map((group) => (
            <ScheduleTable
              key={group.value}
              title={group.label}
              group={group.value}
              target={schedule.target}
              targetType={schedule.targetType}
              targetId={schedule.targetId}
              meta={schedule.meta}
            />
          ))}
        </div>
      </StyledWrapper>
      <div id="modalPortal" />
    </UserTemplate>
  );
}

const StyledWrapper = styled(Wrapper)`
  width: 100%;
  max-width: 90rem;
`;

UserApplicationSchedulePage.propTypes = propTypes;
UserApplicationSchedulePage.defaultProps = defaultProps;

function mapStateToProps(state, props) {
  const availableToImportReSchedule = getApplicationPolicy(state, 'availableToImportReSchedule');
  return { schedule: getSchedules(state, props), availableToImportReSchedule };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchSchedules: bindActionCreators(actions.fetchSchedules, dispatch),
  };
}

const enhance = compose(
  ScrollToTopOnMount,
  WithUser,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  lifecycle({
    componentDidMount() {
      const { fetchSchedules, match } = this.props;
      const { token } = match.params;
      fetchSchedules(token);
    },
  }),

  pure,
);

export default enhance(UserApplicationSchedulePage);
