import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure } from 'recompose';
import { Field } from 'react-final-form';

import { Button, Hr } from 'components/shared';
import { AddressFormGroupAdapter, FormBox, FormFlex, FormLegend, FormGeocoderProvider } from 'components/shared/form';

import AddressFields from 'components/blocks/Schedule/shared/AddressFields';

import { validateRequired } from 'utils';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

function Form({ handleSubmit, submitting }) {
  return (
    <form>
      <Content>
        <FormFlex>
          <FormBox mb="1rem" width={1}>
            <FormLegend>Address</FormLegend>
          </FormBox>
        </FormFlex>

        <FormGeocoderProvider>
          <FormFlex>
            <FormBox width={1}>
              <Field
                name="address"
                required
                label="Address"
                validate={validateRequired()}
                component={AddressFormGroupAdapter}
              />
            </FormBox>
          </FormFlex>

          <AddressFields isRequired={false} />
        </FormGeocoderProvider>
        <FormFlex>
          <FormBox width={1}>
            <Hr />
          </FormBox>
        </FormFlex>
        <FormFlex justifyContent="flex-end">
          <FormBox p={[3, 3, 0]} alignSelf={['flex-end', 'flex-end', 'inherit']}>
            <Button sm onClick={handleSubmit} dataName="saveButton" isabled={submitting}>
              {submitting ? 'Please wait…' : 'Save'}
            </Button>
          </FormBox>
        </FormFlex>
      </Content>
    </form>
  );
}

const Content = styled.div`
  padding: 1rem;
`;

Form.propTypes = propTypes;

const enhance = compose(pure);

export default enhance(Form);
