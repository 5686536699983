import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { Rating, Wrapper } from 'components/shared';

import { APP_NAME } from 'constants/views';

import Blockquote from './Blockquote';
import susanAvatar from './SusanAvatar.png';
import folagiAvatar from './FolagiAvatar.png';
import NorthCarolinaAvatar from './NorthCarolinaAvatar.png';
import GaryAAvatar from './GaryAAvatar.png';
import JodyStephenAvatar from './JodyStephenAvatar.png';
import './index.css';

function Testimonials() {
  return (
    <TestimonialsContainer>
      <Wrapper>
        <Title>Testimonials</Title>
        <StyledTestimonials>
          <Slider
            dots
            arrows={false}
            infinite={false}
            slidesToShow={2}
            slidesToScroll={2}
            navs={false}
            responsive={[
              {
                breakpoint: 900,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}
          >
            <div>
              <TestimonialContainer>
                <Blockquote avatar={susanAvatar} cite="Susan E" fromThe="Colorado Broker/Investors">
                  Before I found {APP_NAME}, I contacted up to 50 lenders and was very disappointing with their results
                  and legitimacy.Working with {APP_NAME} has been great; it's been more than a year and I have
                  successfully referred about a dozen clients.
                </Blockquote>
                <Rating />
              </TestimonialContainer>
            </div>
            <div>
              <TestimonialContainer>
                <Blockquote avatar={folagiAvatar} cite="Folagi" fromThe="New Jersey Investor">
                  “Having the entire process online is very convenient and user friendly. I would definitely recommend{' '}
                  {APP_NAME}. The knowledge base of the loan specialist, his professional guidance on the project from
                  pre-approval to closing went very smoothly...
                </Blockquote>
                <Rating />
              </TestimonialContainer>
            </div>
            <div>
              <TestimonialContainer>
                <Blockquote avatar={NorthCarolinaAvatar} cite="North Carolina Investors ">
                  The one-on conversations with my loan specialist versus a number cruncher who has no true insights of
                  what a investor really needs and is going through, made a huge difference.Spot-on appraisals and no
                  low ball BPOs allowed for smooth closing.
                </Blockquote>
                <Rating />
              </TestimonialContainer>
            </div>
            <div>
              <TestimonialContainer>
                <Blockquote avatar={GaryAAvatar} cite="Gary A." fromThe="Illinois">
                  “Four closings in less than a month. I’m delighted with my lender”
                </Blockquote>
                <Rating />
              </TestimonialContainer>
            </div>
            <div>
              <TestimonialContainer>
                <Blockquote avatar={JodyStephenAvatar} cite="Jody and Stephen" fromThe="Indiana Investors">
                  “We felt that they were real knowledgeable people, it wasn’t just an online lending portal. We closed
                  two weeks earlier than our contract. We anticipate doing 7-10 fix-n-flips a year with {APP_NAME}.”
                </Blockquote>
                <Rating />
              </TestimonialContainer>
            </div>
          </Slider>
        </StyledTestimonials>
      </Wrapper>
    </TestimonialsContainer>
  );
}

const TestimonialsContainer = styled.section`
  padding: 31px 0 40px;
`;

const StyledTestimonials = styled.div`
  margin: 0 auto;
  padding: 40px 0;
`;

const TestimonialContainer = styled.section`
  text-align: center;
`;

const Title = styled.span`
  margin-bottom: 30px;
  display: block;
  text-align: center;
  font-size: 1.9375rem;
  font-weight: 600;
`;

export default Testimonials;
