import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: null,
};

function MenuDivider({ className }) {
  return <Divider className={className} />;
}

const Divider = styled.li`
  height: 0.0625rem;
  margin: 0.5625rem 0;
  overflow: hidden;
  background-color: #e5e5e5;
`;

MenuDivider.propTypes = propTypes;
MenuDivider.defaultProps = defaultProps;

export default MenuDivider;
