import * as actionTypes from '../../constants/actionTypes';
import * as requestTypes from '../../constants/requestTypes';
import setRequestInProcess from '../../actions/request';

import api from '../../utils/api';

function fetchConditions(token) {
  return (dispatch) => {
    const requestType = requestTypes.CONDITIONS;
    const url = `/applications/${token}/conditions`;

    dispatch(setRequestInProcess(true, requestType));

    return api
      .get(url)
      .then(({ data }) => {
        dispatch(setRequestInProcess(false, requestType));
        dispatch(fetchConditionsSuccess(data.collection));
      })
      .catch(() => {
        dispatch(setRequestInProcess(false, requestType));
      });
  };
}

export default fetchConditions;

export function fetchConditionsSuccess(payload) {
  return {
    type: actionTypes.CONDITIONS_FETCH_SUCCESS,
    payload,
  };
}
