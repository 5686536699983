import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContentLoader from 'react-content-loader';
import { Flex, Box } from 'grid-styled';

import * as actions from 'actions';
import { requestInProcess } from 'selectors';

import { Link, Strong } from 'components/shared';

import * as requestTypes from 'constants/requestTypes';
import { applicationPropTypesRequired } from 'propTypes';
import { percentFormat, decimalCurrencyFormat } from 'utils';

import { Table, Th, Tr, Td } from './Table';

const propTypes = {
  ...applicationPropTypesRequired,
  className: PropTypes.string,
  selectQuote: PropTypes.func.isRequired,
};

const defaultProps = {
  className: null,
};

function Quotes({ application, className, selectQuote, loading }) {
  if (loading) {
    return (
      <ContentLoader speed={3}>
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="10" />
        <rect x="0" y="14" rx="5" ry="5" width="100%" height="10" />
        <rect x="0" y="28" rx="5" ry="5" width="100%" height="10" />
        <rect x="0" y="42" rx="5" ry="5" width="100%" height="10" />
      </ContentLoader>
    );
  }

  return (
    <Fragment>
      <QuotesHeader>Rate Quote prior to Completed Application</QuotesHeader>
      <Table className={className}>
        <thead>
          <tr>
            <Th>
              <Strong>Loan to Cost (LTC)</Strong>
            </Th>
            <Th>
              <Strong>Interest Rate</Strong>
            </Th>
            {application.isBridge && (
              <Th>
                <Strong>Amount Needed to Close This Loan</Strong>
              </Th>
            )}
            <Th />
          </tr>
        </thead>
        <tbody>
          {application.quotes &&
            application.quotes.map((quote, index) => (
              <Tr selected={quote.selected} key={`application-${application.id}-quote-${index}`}>
                <Td>{percentFormat(quote.ltc)}</Td>
                <Td>{percentFormat(quote.interestRate)}</Td>
                {application.isBridge && <Td>{decimalCurrencyFormat(quote.amountToClose)}</Td>}
                <Td>
                  {quote.selected && <span>Selected</span>}
                  {quote.selected || (
                    <Link onClick={() => selectQuote({ token: application.token, quote })}>Select</Link>
                  )}
                </Td>
              </Tr>
            ))}
        </tbody>
      </Table>
      <Remark flexDirection="column">
        <Box>
          <p>
            <Strong>
              Allowable maximum loan to cost and loan to after repaired value may vary based on amount of renovation to
              property purchase price.
            </Strong>
          </p>
        </Box>
        <Box py="1rem">
          <p>
            The amounts above include LendingOne origination fee, document preparation, processing and appraisal fees.
            It does not include your other costs such as title and property insurance.
          </p>
        </Box>
      </Remark>
    </Fragment>
  );
}

const Remark = styled(Flex)`
  text-align: center;
`;

const QuotesHeader = styled.div`
  text-align: center;
  font-weight: 700;
  padding-bottom: 5px;
  border-bottom: 1px solid #d5dde9;
`;

Quotes.propTypes = propTypes;
Quotes.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  const loading = requestInProcess(state, requestTypes.QUOTE);

  return {
    loading,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    selectQuote: bindActionCreators(actions.selectQuote, dispatch),
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  pure,
);

export default enhance(Quotes);
