import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, defaultProps } from 'recompose';
import styled from 'styled-components';

import ApplicationDocumentsTree from 'components/blocks/ApplicationDocumentsTree';
import reduxConnect from 'components/blocks/ApplicationDocumentsTree/hocs/reduxConnect';

const propTypes = {
  isEmpty: PropTypes.bool.isRequired,
};

function Documents(props) {
  const { isEmpty } = props;

  if (isEmpty) return null;

  return (
    <Container>
      <ApplicationDocumentsTree {...props} />
    </Container>
  );
}

const Container = styled.div`
  margin-left: 2.25rem;
`;

Documents.propTypes = propTypes;

const enhance = compose(
  defaultProps({
    withRequestedDocuments: false,
    withContactProfileDocuments: true,
    withEntityDocuments: true,
    withLoanDocuments: true,
    withPropertyDocuments: true,
    withDownLoadDocuments: false,
    withAnotherDocuments: true,
  }),
  reduxConnect,
  pure,
);

export default enhance(Documents);
