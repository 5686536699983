import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, getContext, pure } from 'recompose';
import { FieldArray } from 'react-final-form-arrays';

import IconLink from 'components/shared/IconLink';
import { FormFlex, FormBox } from 'components/shared/form';

import Fields from './Fields';

const propTypes = {
  className: PropTypes.string,
  isFormDisabled: PropTypes.bool.isRequired,
};

const defaultProps = {
  className: null,
};

function Borrowers({ isFormDisabled, className }) {
  return (
    <FieldArray subscription={{ initial: true, submitting: true, pristine: true }} name="application.borrowers">
      {({ fields }) => (
        <Fragment>
          {fields &&
            fields.map((name, index) => (
              <Fields className={className} index={index} fields={fields} key={name} name={name} />
            ))}
          <FormFlex justifyContent="center">
            <FormBox>
              <IconLink
                fz={0.825}
                icon="plus"
                middle
                bs="dashed"
                disabled={isFormDisabled}
                onClick={() => {
                  const value = { primary: false };
                  fields.push(value);
                }}
              >
                Add another owner
              </IconLink>
            </FormBox>
          </FormFlex>
        </Fragment>
      )}
    </FieldArray>
  );
}

Borrowers.propTypes = propTypes;
Borrowers.defaultProps = defaultProps;

const enhance = compose(
  getContext({ isFormDisabled: PropTypes.bool }),
  pure,
);

export default enhance(Borrowers);
