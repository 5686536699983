import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { isEmpty, equals } from 'ramda';
import { palette } from 'styled-tools';

import Detail from './Detail';

const propTypes = {
  className: PropTypes.string,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      key: PropTypes.string,
      propertyKey: PropTypes.string,
      render: PropTypes.func,
      format: PropTypes.func,
    }),
  ),
  isInvalid: PropTypes.bool.isRequired,
  property: PropTypes.shape({}).isRequired,
};

const defaultProps = {
  className: undefined,
  details: [],
};

function Details({ className, details, property, isInvalid }) {
  const isLast = equals(details.length);

  if (isEmpty(details)) return null;
  return (
    <Container calssName={className}>
      {details.map(({ key, ...detail }, index) => (
        <Fragment key={key}>
          <Detail isInvalid={isInvalid} index={index} property={property} {...detail} />
          {!isLast(index + 1) && <Divider isInvalid={isInvalid} />}
        </Fragment>
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: stretch;
  align-content: stretch;
  overflow-x: scroll;
`;

const Divider = styled.div`
  margin: 0 1.625em;
  width: 0.125em;
  background: #d8d8d8;
  flex-shrink: 0;
  ${({ isInvalid }) =>
    isInvalid &&
    css`
      background: ${palette('danger', 2)};
    `}
`;

Details.propTypes = propTypes;
Details.defaultProps = defaultProps;

export default Details;
