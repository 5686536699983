import React, { Fragment } from 'react';

import { ContentLoader } from 'components/shared';
import { NarrowFormFlex, FormBox, FormGroupLoader } from 'components/shared/form';

function Loader() {
  return (
    <Fragment>
      <NarrowFormFlex>
        <FormBox width={1}>
          <ContentLoader mb="1rem" height="4.3rem" width="100%" />
        </FormBox>
      </NarrowFormFlex>

      <NarrowFormFlex>
        <FormBox width={1}>
          <FormGroupLoader labelWidth="14.5rem" />
        </FormBox>
      </NarrowFormFlex>
    </Fragment>
  );
}

export default Loader;
