import * as R from 'ramda';

import { getIn } from 'utils/ramda';

export function documentsFilterByTargetAndCategory(documents, { targetId, categoryId }) {
  function filterHandler(doc) {
    const docCategoryId = getIn('category.id', doc);
    const docTargetId = getIn('target.id', doc);
    return docCategoryId === categoryId && docTargetId === targetId;
  }

  return R.filter(filterHandler, documents);
}
