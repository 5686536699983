import React from 'react';
import { Flex, Box } from 'grid-styled';

import { Wrapper } from 'components/shared';

import FixNFlip from './FixNFlip';
import Rental from './Rental';
import NewConstruction from './NewConstruction';

function Products() {
  return (
    <Wrapper padded>
      <Flex flexWrap={['wrap', 'wrap', 'nowrap']} justify="center" items="stretch">
        <Box width={[1, 1, 1 / 3]} mb={[40, 40, 20]} mr={[0, 0, 40]}>
          <FixNFlip />
        </Box>

        <Box width={[1, 1, 1 / 3]} mb={[40, 40, 20]} mr={[0, 0, 40]}>
          <Rental />
        </Box>

        <Box width={[1, 1, 1 / 3]} mb={[40, 40, 20]}>
          <NewConstruction />
        </Box>
      </Flex>
    </Wrapper>
  );
}

export default Products;
