import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, defaultProps } from 'recompose';
import styled from 'styled-components';
import { prop } from 'styled-tools';

import ApplicationDocumentsTree from 'components/blocks/ApplicationDocumentsTree';
import reduxConnect from 'components/blocks/ApplicationDocumentsTree/hocs/reduxConnect';

import { Title } from '../shared';

const propTypes = {
  isEmpty: PropTypes.bool.isRequired,
};

function Documents(props) {
  const { isEmpty } = props;

  if (isEmpty) return null;

  return (
    <Container>
      <Title>Documents</Title>
      <ApplicationDocumentsTree {...props} />
    </Container>
  );
}

const Container = styled.div`
  padding: 2.1875rem 0.5rem 2.1875rem 0.5rem;

  @media (max-width: ${prop('theme.breakpoints.1')}) {
    padding: 2.1875rem 0;
  }
`;

Documents.propTypes = propTypes;

const enhance = compose(
  defaultProps({
    withContactProfileDocuments: false,
    withDownLoadDocuments: false,
    withEntityDocuments: false,
    withLoanDocuments: false,
    withMissingDocuments: true,
    withPropertyDocuments: false,
    withRequestedDocuments: true,
    withAnotherDocuments: false,
  }),
  reduxConnect,
  pure,
);

export default enhance(Documents);
