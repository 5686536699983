import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

import * as fp from 'utils/ramda';

import FormGroup from 'components/shared/form/FormGroup';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  inline: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
};

const defaultProps = {
  className: null,
  inline: false,
  onBlur: null,
  onChange: null,
  onFocus: null,
  value: null,
};

function RadioGroup(props) {
  const {
    children, className, inline, name, onBlur, onChange, onFocus, value, disabled, ...rest
  } = props;

  const childrenRadioButtons = Children.map(children, (child) => {
    const { value: childValue } = child.props;

    return cloneElement(child, {
      name,
      onBlur,
      onChange,
      onFocus,
      inline,
      type: 'radio',
      disabled,
      checked: fp.isDefined(value) && fp.isDefined(childValue) && value.toString() === childValue.toString(),
    });
  });

  return (
    <div>
      <FormGroup name={name} className={className} {...rest}>
        {childrenRadioButtons}
      </FormGroup>
    </div>
  );
}

RadioGroup.propTypes = propTypes;
RadioGroup.defaultProps = defaultProps;

export default RadioGroup;
