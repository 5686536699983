import React from 'react';
import PropTypes from 'prop-types';

import { portfolioImportTemplateUrl } from 'config';
import useImportProperty from 'hooks/useImportProperty';
import * as fp from 'utils/ramda';

import { Button, Well, Alert } from 'components/shared';
import { FormBox, FormStepHeader, NarrowFormFlex } from 'components/shared/form';
import { WellBody, WellHeader, WellFooter } from 'components/shared/Well';
import Import from 'components/shared/form/PropertyImport';
import SubmitErrors from 'components/shared/form/SubmitErrors';

import Fields from './Fields';
import Loader from './Loader';

const propTypes = {
  fetchInitialValues: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

function Form({ fetchInitialValues, handleSubmit, loading, submitting }) {
  const {
    toImport,
    isImporting,
    errors,
    warnings,
  } = useImportProperty('application.properties', fetchInitialValues);

  const onLoadFiles = (files) => toImport({
    import: {
      asset: files[0],
      type: 'rental_properties',
    },
  });
  return (
    <form onSubmit={handleSubmit}>
      <Well>
        <WellHeader key="header">
          <FormStepHeader
            title="Apply For Rental Loan"
            subtitle="Your Instant Rate Quote is Only 2 Step Away"
            current={2}
            total={3}
          />
        </WellHeader>

        <WellBody key="body">
          <NarrowFormFlex justifyContent="flex-end">
            <FormBox mb="1.25rem">
              <Import
                onLoadFiles={onLoadFiles}
                isImporting={isImporting}
                disabled={loading}
                importTemplateUrl={portfolioImportTemplateUrl}
              />
            </FormBox>
          </NarrowFormFlex>

          {fp.isDefined(errors) && (
            <NarrowFormFlex>
              <FormBox width={1} mb="1.25rem">
                <Alert palette="danger" colorPalette="secondary" size="sm">
                  <SubmitErrors errors={errors} />
                </Alert>
              </FormBox>
            </NarrowFormFlex>
          )}

          {fp.isDefined(warnings) && (
            <NarrowFormFlex>
              <FormBox width={1} mb="1.25rem">
                <Alert palette="warning" colorPalette="warning" size="sm" colorTone={0}>
                  <SubmitErrors errors={warnings} />
                </Alert>
              </FormBox>
            </NarrowFormFlex>
          )}

          {loading && <Loader />}
          {!loading && <Fields fetchInitialValues={fetchInitialValues} />}
        </WellBody>

        <WellFooter sticky key="footer">
          <NarrowFormFlex alignItems="center" justifyContent="flex-end">
            <FormBox p={[3, 3, 0]} alignSelf={['flex-end', 'flex-end', 'inherit']}>
              <Button type="submit" dataName="buttonNext" rounded disabled={loading || submitting}>
                {submitting ? 'Please wait…' : 'Next'}
              </Button>
            </FormBox>
          </NarrowFormFlex>
        </WellFooter>
      </Well>
    </form>
  );
}

Form.propTypes = propTypes;

export default Form;
