import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { palette } from 'styled-tools';

const propTypes = {
  palette: PropTypes.string,
  reverse: PropTypes.bool,
};

const defaultProps = {
  palette: 'primary',
};

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 0.2em solid ${palette('grayscale', -2)};
  border-bottom-color: ${palette(1)};
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation: ${spin} 1s linear infinite;
`;

Spinner.propTypes = propTypes;
Spinner.defaultProps = defaultProps;
export default Spinner;
