import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, getContext, lifecycle, pure, withHandlers, withProps } from 'recompose';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';

import { getEntities, getEntitiesCollectionWithEmptyItem, getBridgeFullFormFourthStepRefs } from 'selectors';

import { AddressFields } from 'components/Application/StepsPage/shared';
import {
  AddressFormGroupAdapter,
  FormBox,
  FormFlex,
  FormGroupAdapter,
  FormGeocoderProvider,
} from 'components/shared/form';

import { WithFinalForm, WithFinalFormValue } from 'hocs';
import { validateRequired } from 'utils';

import { handlers } from './shared';

const propTypes = {
  entitiesCollection: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  isFormDisabled: PropTypes.bool.isRequired,
  refs: PropTypes.shape({
    entityTypesCollection: PropTypes.arrayOf(PropTypes.object),
  }),
};

const defaultProps = {
  entitiesCollection: [],
  refs: {
    entityTypesCollection: [],
  },
};

function ApplicationEntity({ isFormDisabled, entitiesCollection, refs }) {
  return (
    <Fragment>
      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.entity.id"
            label="Entity"
            type="select"
            allowNull
            isDisabled={isFormDisabled}
            options={entitiesCollection}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.entity.legalName"
            required
            label="Company Legal Name"
            disabled={isFormDisabled}
            validate={validateRequired()}
            hint="If you do not yet have a LLC or Corporation put “TBD”"
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.entity.type"
            type="select"
            options={refs.entityTypesCollection}
            required
            label="Type"
            isDisabled={isFormDisabled}
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormGeocoderProvider namespace="application.entity">
        <FormFlex>
          <FormBox width={1}>
            <Field
              name="application.entity.address"
              label="Address"
              disabled={isFormDisabled}
              isDisabled={isFormDisabled}
              component={AddressFormGroupAdapter}
            />
          </FormBox>
        </FormFlex>

        <AddressFields isRequired={false} />
      </FormGeocoderProvider>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.entity.tinNumber"
            label="EIN/TIN #"
            disabled={isFormDisabled}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>
    </Fragment>
  );
}

ApplicationEntity.propTypes = propTypes;
ApplicationEntity.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    entities: getEntities(state),
    entitiesCollection: getEntitiesCollectionWithEmptyItem(state),
    refs: getBridgeFullFormFourthStepRefs(state),
  };
}

const enhance = compose(
  getContext({ isFormDisabled: PropTypes.bool }),
  WithFinalForm,
  withProps({ entityName: 'application.entity.id' }),
  WithFinalFormValue('entityName'),
  connect(mapStateToProps),
  withHandlers({
    rejectEntity: handlers.rejectEntity,
  }),
  withHandlers({
    decorateEntity: handlers.decorateEntity,
  }),
  lifecycle({
    componentDidUpdate({ value: prevValue }) {
      const { decorateEntity, value } = this.props;
      if (value !== prevValue) decorateEntity(value);
    },
  }),
  pure,
);

export default enhance(ApplicationEntity);
