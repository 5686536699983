import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { CheckApplicationsForExistence, WithUser } from 'hocs';
import { userDefaultProps, userPropTypes } from 'propTypes';
import { getIsDrawRequestsExist } from 'selectors';
import { withRouter } from 'react-router-dom';
import { getPath } from 'utils';
import NavLink from 'react-router-dom/NavLink';
import {
  isActive,
  INDEX_PATH,
  DRAW_REQUESTS_PATH,
  PROFILE_PATH,
  MY_APPLICATIONS_PATH,
  APPLICATION_SCHEDULE_PATH,
} from 'constants/routes';

import logo from './logo.svg';

const propTypes = {
  ...userPropTypes,
  isDrawRequestsExist: PropTypes.bool.isRequired,
};

const defaultProps = {
  ...userDefaultProps,
  className: null,
};

function UserNavigation(props) {
  const { isDrawRequestsExist, user, match } = props;
  const { token } = match.params;
  return (
    <Container>
      <Logo>
        <MenuLink exact to={INDEX_PATH}>
          <LogoImg src={logo} />
        </MenuLink>
      </Logo>
      <Item>
        <MenuLink exact to={INDEX_PATH}>
          <MenuText>Home</MenuText>
        </MenuLink>
      </Item>
      {user && (
        <Item>
          <MenuLink
            isActive={isActive('applications')}
            to={getPath(MY_APPLICATIONS_PATH, { id: user.id, status: 'active' })}
          >
            <MenuText>Applications</MenuText>
          </MenuLink>
        </Item>
      )}
      {user && isDrawRequestsExist && (
        <Item>
          <MenuLink to={DRAW_REQUESTS_PATH}>
            <MenuText>Draw Requests</MenuText>
          </MenuLink>
        </Item>
      )}

      {user && (
        <Item>
          <MenuLink to={getPath(PROFILE_PATH, { id: user.id })}>
            <MenuText>Profile</MenuText>
          </MenuLink>
        </Item>
      )}
    </Container>
  );
}

const Container = styled.ul`
  display: flex;
  align-items: center;
`;

const Item = styled.li`
  display: block;
  margin: 0;
  padding: 0 0.625rem;
  &:first-child {
    padding-left: 0;
  }
`;

const MenuLink = styled(NavLink).attrs({})`
  position: relative;
  display: block;
  outline: 0;
  text-decoration: none;
  line-height: 12px;
  font-size: 12px;
  font-weight: 700;
  color: #878e98;
  text-transform: uppercase;
  transition: color cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
  &:hover {
    color: #504f4d;
    text-decoration: none;
  }
  &.active {
    color: #504f4d;
    &:before {
      position: absolute;
      bottom: -5px;
      content: '';
      width: 100%;
      height: 2px;
      background: #504f4d;
    }
  }
`;

const MenuText = styled.span`
  display: block;
  line-height: 35px;
  width: max-content;
`;

const Logo = styled.div`
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  margin-right: 20px;
`;

const LogoImg = styled.img`
  max-width: 6.6875rem;
  vertical-align: middle;
`;

UserNavigation.propTypes = propTypes;
UserNavigation.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  isDrawRequestsExist: getIsDrawRequestsExist(state),
});

const enhance = compose(
  withRouter,
  WithUser,
  CheckApplicationsForExistence,
  connect(mapStateToProps),
  pure,
);

export default enhance(UserNavigation);
