import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Downshift from 'downshift';
import { ifProp } from 'styled-tools';

import * as actions from 'actions';
import { getDocumentCategoryById } from 'selectors/documentCategories';

import Drawer from 'components/shared/Drawer';
import Link from 'components/shared/Link';
import Button from 'components/shared/Button';
import { DocumentsUploadForm } from 'components/Application/StepsPage/shared';

import ExplanationForm from '../ExplanationForm';

const propTypes = {
  category: PropTypes.objectOf(PropTypes.any).isRequired,
  createDocument: PropTypes.func.isRequired,
  createExplanationDocument: PropTypes.func.isRequired,
  targetId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

const defaultProps = {};

function Title({ category, createDocument, targetId, createExplanationDocument }) {
  const isExplanation = category && category.onlyExplanation;
  const btnText = isExplanation ? 'Provide explanation' : 'Upload Document';

  if (isExplanation) {
    return (
      <Downshift>
        {({ isOpen, getToggleButtonProps, closeMenu, openMenu }) => {
          const handleClick = isOpen ? closeMenu : openMenu;

          return (
            <div>
              <Container>
                <Link onClick={handleClick}>
                  {category.name}
                </Link>{' '}
                <Button onClick={handleClick} xs>
                  {btnText}
                </Button>
                {isOpen && (
                  <Dropdown lf>
                    <ExplanationForm
                      action={createExplanationDocument}
                      targetId={targetId}
                      categoryId={category.id}
                      handleTogglePanel={closeMenu}
                    />
                  </Dropdown>
                )}
              </Container>
            </div>
          );
        }}
      </Downshift>
    );
  }

  return (
    <Drawer
      content={({ togglePanel }) => (
        <DocumentsUploadForm
          metadata={category.metadata}
          action={createDocument}
          targetId={targetId}
          categoryId={category.id}
          handleTogglePanel={togglePanel}
        />
      )}
    >
      {({ togglePanel }) => (
        <Fragment>
          <Link onClick={togglePanel}>
            {category.name}
          </Link>{' '}
          <Button onClick={togglePanel} xs>
            {btnText}
          </Button>
        </Fragment>
      )}
    </Drawer>
  );
}

Title.propTypes = propTypes;
Title.defaultProps = defaultProps;

function mapStateToProps(state, { categoryId }) {
  return {
    category: getDocumentCategoryById(state, categoryId),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createDocument: bindActionCreators(actions.createDocument, dispatch),
    createExplanationDocument: bindActionCreators(actions.createExplanationDocument, dispatch),
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  pure,
);

const Container = styled.div`
  position: relative;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 30px;
  ${ifProp('lf', 'left: 0', 'right: 0')};
  z-index: 1000;
  display: block;
  padding: 0.3125rem;
  min-width: 300px;
  background-color: #fff;
  border: 0.06225rem solid rgba(0, 0, 0, 0.15);
  border-radius: 0.1875rem;
  box-shadow: 0.125rem 0.125rem 0.3125rem rgba(0, 0, 0, 0.2);
`;

export default enhance(Title);
