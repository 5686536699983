import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withState } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus';
import * as R from 'ramda';

import { clientId, clientSecret } from 'config';
import { getUserContactId } from 'selectors';

import { Button, Link } from 'components/shared';
import { FormBox, FormGroupAdapter, FormAsterisk, FormStepHeader, NarrowFormFlex } from 'components/shared/form';
import Well, { WellBody, WellHeader, WellFooter } from 'components/shared/Well';

import { WithIncompleteApp, WithUser } from 'hocs';
import * as actions from 'actions';
import { PRIVACY_POLICY } from 'constants/routes';
import { holdMutators, validateAccepted, setIn } from 'utils';

import { AccessDenied, Incomplete } from '../../shared';
import Fields from './Fields';

const propTypes = {
  sendFirstStep: PropTypes.func.isRequired,
  isAccessDenied: PropTypes.bool,
  incompleteApp: PropTypes.shape({}),
  initialValues: PropTypes.shape({}),
};

const defaultProps = {
  isAccessDenied: false,
  incompleteApp: undefined,
  initialValues: {},
};

const focusOnErrors = createDecorator();

function Step1({ isAccessDenied, incompleteApp, initialValues, sendFirstStep }) {
  if (isAccessDenied) {
    return <AccessDenied />;
  }

  if (incompleteApp) {
    return <Incomplete application={incompleteApp} />;
  }

  return (
    <Form
      onSubmit={sendFirstStep}
      initialValues={initialValues}
      subscription={{ submitting: true, pristine: true }}
      decorators={[focusOnErrors]}
      mutators={{ ...holdMutators }}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Well>
            <WellHeader key="header">
              <FormStepHeader title="Apply For Rental Loan" subtitle="Step 1 out of 3" current={1} total={3} />
            </WellHeader>

            <WellBody key="body">
              <Fields />
            </WellBody>

            <WellFooter key="footer">
              <NarrowFormFlex justifyContent="space-between">
                <FormBox>
                  <Field
                    name="application.serviceTerms"
                    id="serviceTerms"
                    fz={0.875}
                    type="checkbox"
                    component={FormGroupAdapter}
                    validate={validateAccepted()}
                  >
                    <FormAsterisk>
                      I agree with{' '}
                      <Link to={PRIVACY_POLICY} target="_blank">
                        terms of service
                      </Link>{' '}
                      and the{' '}
                      <Link to={PRIVACY_POLICY} target="_blank">
                        privacy policy
                      </Link>
                    </FormAsterisk>
                  </Field>
                </FormBox>
                <FormBox p={[3, 3, 0]} alignSelf={['flex-end', 'flex-end', 'inherit']}>
                  <Button type="submit" dataName="buttonNext" rounded disabled={submitting}>
                    {submitting ? 'Please wait…' : 'Next'}
                  </Button>
                </FormBox>
              </NarrowFormFlex>
            </WellFooter>
          </Well>
        </form>
      )}
    />
  );
}

function mapStateToProps(state, props) {
  const { contact } = props;

  return {
    isAccessDenied: contact && (contact.isAdmin || contact.isCrestar),
    userContactId: getUserContactId(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendFirstStep: bindActionCreators(actions.createRentalApplication, dispatch),
    dataLayerPush: bindActionCreators(actions.dataLayerPush, dispatch),
  };
}

const enhance = compose(
  WithUser,
  WithIncompleteApp,
  connect(mapStateToProps, mapDispatchToProps),
  withState('initialValues', 'setInitialValues', ({ userContactId }) => R.when(
    () => !userContactId,
    R.pipe(
      setIn(clientId, 'clientId'),
      setIn(clientSecret, 'clientSecret'),
    ),
  )({})),
  lifecycle({
    componentDidMount() {
      const { userContactId, dataLayerPush } = this.props;
      if (userContactId) dataLayerPush({ loanType: 'rental', userId: userContactId, bSc: '0' });
    },
  }),
  pure,
);

Step1.propTypes = propTypes;
Step1.defaultProps = defaultProps;

export default enhance(Step1);
