import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import { Subtitle, Title } from 'components/shared';
import Wrapper from 'components/shared/Wrapper';

const propTypes = {
  staticContext: PropTypes.objectOf(PropTypes.any),
};

const defaultProps = {
  staticContext: {},
};

const NotFound = ({ staticContext }) => {
  if (staticContext) {
    // eslint-disable-next-line no-param-reassign
    staticContext.status = 404;
  }

  return (
    <Fragment>
      <Helmet>
        <title>404</title>
      </Helmet>
      <StyledWrapper>
        <Title level={0.75}>404 Not Found</Title>
        <Subtitle>Sorry, this page does not exist. Please, try another page.</Subtitle>
      </StyledWrapper>
    </Fragment>
  );
};

NotFound.propTypes = propTypes;
NotFound.defaultProps = defaultProps;

const StyledWrapper = styled(Wrapper)`
  margin-top: 40px;
  margin-bottom: 100px;
`;

export default NotFound;
