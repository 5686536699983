import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers, withProps } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from 'actions';

import { ToDo, Button } from 'components/shared';
import Drawer from 'components/shared/Drawer';
import New from 'components/Application/ContactsPage/New';

const propTypes = {
  no: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
};

function InsuranceCompanyToDo({ no, callback }) {
  return (
    <ToDo>
      <ToDo.Title>Do you have a property insurance company?</ToDo.Title>
      <ToDo.Body>If yes, you will be able to fill form to submit information.</ToDo.Body>
      <ToDo.Actions>
        <Drawer
          content={({ togglePanel }) => (
            <New callback={callback(togglePanel)} defaultValues={{ contact: { type: 'insurance_company' } }} />
          )}
        >
          {({ togglePanel }) => (
            <Button sm onClick={togglePanel}>
              Yes
            </Button>
          )}
        </Drawer>
        <Button sm onClick={no}>
          No
        </Button>
      </ToDo.Actions>
    </ToDo>
  );
}

InsuranceCompanyToDo.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
  return {
    applicationsTodo: bindActionCreators(actions.applicationsTodo, dispatch),
    fetchApplication: bindActionCreators(actions.fetchApplication, dispatch),
  };
}

const enhance = compose(
  withRouter,
  withProps(({ match }) => {
    const { token } = match.params;
    return { token };
  }),
  connect(
    null,
    mapDispatchToProps,
  ),
  withHandlers({
    no: ({ applicationsTodo, token }) => () => applicationsTodo(token, { todo: { insuranceCompany: false } }),
    callback: ({ fetchApplication, token }) => (togglePanel) => async () => {
      await fetchApplication(token);
      togglePanel();
    },
  }),
  pure,
);

export default enhance(InsuranceCompanyToDo);
