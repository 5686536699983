import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ServerStateProvider } from 'react-router-server';
import TagManager from 'react-gtm-module';

import { configureStore } from 'store/configureStore'; // import api from 'utils/api';
import { isProd } from 'config';

import { GTM_DATA_LAYER_NAME, GTM_ID } from 'constants/others';

import App from 'App';

import * as serviceWorker from './serviceWorker';

require('dotenv').config();

const serverState = window.__SERVER_STATE__;
const initialState = window.__INITIAL_STATE__;

const store = configureStore(initialState);
if (isProd) TagManager.initialize({ gtmId: GTM_ID, dataLayerName: GTM_DATA_LAYER_NAME });

const renderApp = () => (
  <ServerStateProvider state={serverState}>
    <Provider store={store}>
      <App />
    </Provider>
  </ServerStateProvider>
);

const root = document.getElementById('root');
render(renderApp(), root);

serviceWorker.unregister();

if (module.hot) {
  module.hot.accept('App', () => {
    require('App');
    render(renderApp(), root);
  });
}
