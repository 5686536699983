import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ifProp, palette } from 'styled-tools';

import checkIco from './check.png';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  fz: PropTypes.number,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  inline: PropTypes.bool,
  palette: PropTypes.string,
  label: PropTypes.node,
};

const defaultProps = {
  className: null,
  children: null,
  id: null,
  inline: false,
  palette: 'grayscale',
  fz: undefined,
  label: null,
};

const fontSize = ({ fz }) => (fz ? `${fz}rem` : 'inherit');

const Checkbox = ({ children, className, id, inline, name, label, ...props }) => (
  <Container inline={inline} className={className}>
    <StyledInput id={id || name} name={name} type="checkbox" {...props} />
    <StyledLabel htmlFor={id || name}>{label || children || ''}</StyledLabel>
  </Container>
);

const StyledInput = styled.input`
  &:indeterminate + label:before {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    margin-top: -0.625rem;
    left: 0;
    background: ${palette('primary', 0)};
    border: 0.0625rem solid #504f4d;
    font-size: 1.125rem;
    border-radius: 0.1875rem;
    width: 1.25rem;
    height: 1.25rem;
    transition: all 0.2s;
    background-repeat: no-repeat;
    background-position: 0.5rem 0.4375rem;
  }
  &:not(:checked),
  &:checked {
    position: absolute;
    left: -9999rem;
    transition: all 0.2s ease-in-out;
  }
  &:not(:checked) + label,
  &:checked + label {
    position: relative;
    display: block;
    margin-bottom: 0.3125rem;
    padding-left: 1.9375rem;
    min-height: 1.25rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-weight: 300;
    font-size: ${fontSize};
  }
  &:not(:checked) + label:before,
  &:checked + label:before {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    top: 50%;
    margin-top: -0.625rem;
    width: 1.1875rem;
    height: 1.1875rem;
    font-size: 1.125rem;
    border: 0.125rem solid #a3a6a8;
    border-radius: 0.1875rem;
    box-shadow: none;
    background-repeat: no-repeat;
    background-position: 0 0.875rem;
  }
  &:checked + label:before {
    transition: border-color 0.2s;
    border: 0.125rem solid ${palette('primary', 0)};
  }
  &:not(:checked) + label:after,
  &:checked + label:after {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    margin-top: -0.625rem;
    left: 0;
    width: 1.3125rem;
    height: 1.3125rem;
    transition: all 0.2s;
    background-image: url(${checkIco});
    background-repeat: no-repeat;
    background-position: 0.3125rem 0.3125rem;
  }
  &:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }
  &:checked + label:after {
    opacity: 1;
    transform: scale(1);
    animation-duration: 0.2s;
  }
  &:disabled {
    &:not(:checked) + label:before {
      box-shadow: none;
      border-color: #bbb;
      background-color: #ddd;
    }
    &:checked + label {
      &:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #ddd;
      }
      &:after {
        color: #999;
      }
    }
    + label {
      color: #aaa;
    }
  }
`;

const StyledLabel = styled.label``;

const Container = styled.div`
  position: relative;
  display: ${ifProp('inline', 'inline-block', 'block')};
  & + & {
    margin-top: ${ifProp('inline', '0', '1.25rem')};
    margin-left: ${ifProp('inline', '1.25rem', '0')};
  }
`;

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;
export default Checkbox;
