import { createSelector } from 'reselect';
import { identity } from 'ramda';

export const initialState = [];

function conditionsSelector(state) {
  return state.conditions || initialState;
}

export const getConditions = createSelector(
  conditionsSelector,
  identity,
);
