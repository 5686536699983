import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { spring, Motion } from 'react-motion';
import * as R from 'ramda';

const portalRoot = document.getElementById('modalPortal');

const backdropStyle = {
  position: 'fixed',
  zIndex: 909,
  background: 'rgba(0, 0, 0, 0.3)',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
};

class ModalPortal extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    show: PropTypes.bool.isRequired,
    title: PropTypes.string,
  };

  static defaultProps = {
    title: '',
  };

  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    portalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    portalRoot.removeChild(this.el);
  }

  handleBackdropClick = (e) => {
    const { handleTogglePortal } = this.props;
    if (
      handleTogglePortal &&
      R.not(R.is(Object, e.target.className)) &&
      (e.target.className.includes('backdrop') ||
        e.target.className.includes('modal-close') ||
        e.target.className.includes('modal-close-span'))
    ) {
      handleTogglePortal();
    }
  };

  render() {
    const { show, title } = this.props;

    return ReactDOM.createPortal(
      <Motion
        style={{
          opacity: spring(show ? 1 : 0),
        }}
      >
        {(currentStyles) =>
          show && (
            <div
              className="backdrop"
              style={{
                ...backdropStyle,
                opacity: currentStyles.opacity,
              }}
              onClick={(e) => this.handleBackdropClick(e)}
            >
              <StyledModalDialog>
                <StyledModalClose
                  className="modal-close"
                  data-dismiss="modal"
                  onClick={(e) => this.handleBackdropClick(e)}
                >
                  <span className="modal-close-span">×</span>
                </StyledModalClose>
                <StyledModalContent>
                  <StyledModalHeader>
                    {title && (
                      <StyledModalTitle>
                        <strong>{title}</strong>
                      </StyledModalTitle>
                    )}
                  </StyledModalHeader>
                  <StyledModalBody>{this.props.children}</StyledModalBody>
                </StyledModalContent>
              </StyledModalDialog>
            </div>
          )
        }
      </Motion>,
      this.el,
    );
  }
}

// const StyledModal = styled.div`
//   position: fixed;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   z-index: 910;
//   -webkit-overflow-scrolling: touch;
//   outline: 0;
//   overflow-x: hidden;
//   overflow-y: auto;
// `;

const StyledModalDialog = styled.div`
  position: relative;
  width: 750px;
  margin: 30px auto;
  align-self: flex-start;
`;

const StyledModalClose = styled.div`
  position: absolute;
  z-index: 920;
  top: -13px;
  right: -13px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  text-align: center;
  line-height: 25px;
  font-size: 20px;
  color: #fff;
  background: #000;
  border: 2px solid #fff;
  border-radius: 50%;
`;

const StyledModalContent = styled.div`
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  position: relative;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0;
`;

const StyledModalHeader = styled.div`
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  min-height: 16.42857px;
`;

const StyledModalTitle = styled.h4`
  margin: 0;
  line-height: 1.42857;
  font-weight: bold;
`;

const StyledModalBody = styled.div`
  position: relative;
  padding: 15px;
`;

ModalPortal.StyledModalDialog = StyledModalDialog;
ModalPortal.StyledModalBody = StyledModalBody;

export default ModalPortal;
