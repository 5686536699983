import * as actionTypes from '../constants/actionTypes';
import * as requestTypes from '../constants/requestTypes';
import setRequestInProcess from '../actions/request';

import api from '../utils/api';

export const fetchDocumentCategories = (token) => (dispatch) => {
  const requestType = requestTypes.DOCUMENT_CATEGORIES;
  const url = `/applications/${token}/documents/categories.json`;

  dispatch(setRequestInProcess(true, requestType));
  return api
    .get(url)
    .then(({ data }) => {
      dispatch(setRequestInProcess(false, requestType));
      dispatch(fetchDocumentCategoriesSuccess(data));
    })
    .catch((response) => {
      dispatch(setRequestInProcess(false, requestType));
      console.log('response', response);
    }); // FIXME to think about how to handle the error correctly
};

export const fetchDownlodDocumentCategories = (token) => (dispatch) => {
  const requestType = requestTypes.DOCUMENT_CATEGORIES;
  const url = `/applications/${token}/documents/categories.json`;

  dispatch(setRequestInProcess(true, requestType));
  return api
    .get(url)
    .then(({ data }) => {
      dispatch(setRequestInProcess(false, requestType));
      dispatch(fetchDownloadDocumentCategoriesSuccess(data));
    })
    .catch((response) => {
      dispatch(setRequestInProcess(false, requestType));
      console.log('response', response);
    }); // FIXME to think about how to handle the error correctly
};

export function fetchDocumentCategoriesSuccess(payload) {
  return {
    type: actionTypes.DOCUMENT_CATEGORIES_FETCH_SUCCESS,
    payload,
  };
}

export function fetchDownloadDocumentCategoriesSuccess(payload) {
  return {
    type: actionTypes.DOWNLOAD_DOCUMENT_CATEGORIES_FETCH_SUCCESS,
    payload,
  };
}

export function resetDocumentCategories() {
  return {
    type: actionTypes.DOCUMENT_CATEGORIES_RESET,
  };
}
