import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from 'grid-styled';
import OriginContentLoader from 'react-content-loader';
import { prop } from 'styled-tools';

const propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
};

const defaultProps = {
  className: undefined,
  height: '1rem',
};

function ContentLoader({ className, maxWidth, rectWidth, height, ...props }) {
  return (
    <Box className={className} height={height} {...props}>
      <OriginContentLoader preserveAspectRatio="none" width={100} height={100}>
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
      </OriginContentLoader>
    </Box>
  );
}

const StyledLoader = styled(ContentLoader)`
  position: relative;
  overflow: hidden;
  border-radius: ${prop('borderRadius', '0.1875rem')};
  height: ${prop('height', '1rem')};
  width: ${prop('width', '100%')};
  max-width: ${prop('maxWidth')};
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${prop('height', '1rem')};
    display: block;
  }
`;

ContentLoader.propTypes = propTypes;
ContentLoader.defaultProps = defaultProps;

export default StyledLoader;
