function hold([name, allowNull], state, { getIn, setIn }) {
  let formState = getIn(state, 'formState');
  const value = getIn(formState, `values.${name}`);

  if (!value) {
    return;
  }

  let holdBuffer = getIn(formState, 'holdBuffer') || getIn(formState, 'initialValues') || {};
  holdBuffer = setIn(holdBuffer, name, value);
  formState = setIn(formState, `values.${name}`, allowNull ? null : undefined);
  formState = setIn(formState, 'holdBuffer', holdBuffer);

  state.formState = formState;
}

function unhold([name], state, { getIn, setIn }) {
  let formState = getIn(state, 'formState');

  if (getIn(formState, `values.${name}`)) {
    return;
  }

  let holdBuffer = getIn(formState, 'holdBuffer') || getIn(formState, 'initialValues') || {};

  const value = getIn(holdBuffer, name);

  if (!value) {
    return;
  }

  holdBuffer = setIn(holdBuffer, name, undefined);
  formState = setIn(formState, `values.${name}`, value);
  formState = setIn(formState, 'holdBuffer', holdBuffer);

  state.formState = formState;
}

function resetBuffer(args, state, { getIn, setIn }) {
  let formState = getIn(state, 'formState');

  formState = setIn(formState, 'holdBuffer', {});

  state.formState = formState;
}

function setBuffer([values], state, { getIn, setIn }) {
  let formState = getIn(state, 'formState');

  formState = setIn(formState, 'holdBuffer', values);

  state.formState = formState;
}

export default {
  hold,
  unhold,
  resetBuffer,
  setBuffer,
};
