import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'ramda';

import Title from './components/Title';
import Details from './components/Details';

const propTypes = {
  property: PropTypes.shape({
    address: PropTypes.string,
  }).isRequired,
  details: PropTypes.arrayOf(PropTypes.shape({})),
  isInvalid: PropTypes.bool.isRequired,
};

const defaultProps = {
  details: [],
};

function Preview({ property, details, isInvalid }) {
  const address = prop('address', property);
  return (
    <Container>
      <Title>{address || 'N/A'}</Title>
      <Details isInvalid={isInvalid} property={property} details={details} />
    </Container>
  );
}

const Container = styled.div`
  ${Title} {
    margin-right: 4em;
    margin-bottom: 1.25em;
  }
`;

Preview.propTypes = propTypes;
Preview.defaultProps = defaultProps;

export default Preview;
