import { HUMAN_APP_NAME } from 'constants/views';

export const REFERRED_BY_COMMENT_CONDITION = ['direct_mailing', 'event', 'friend_colleague', 'magazine', 'otherd'];
export const LOAN_OFFICER_ID_CONDITION = ['loan_officer'];
export const BRIDGE_STEP_2_FIELDS_CONDITION = ['purchase', 'refinance_no_cash_out', 'refinance_cash_out'];
export const PROPERTY_COMPLETED_REPAIRS_VALUE_CONDITION = ['refinance_cash_out'];
export const STEP_2_UNITS_COUNT_CONDITION = [
  'few_unit_residential',
  'single_family_residential',
  'manufactured_home',
  'multi_family',
  'condo_warrantable',
  'condo_non_warrantable',
  'townhouse',
  'mixed_use',
  'commercial',
  'new_construction',
  'other',
];

export const ADDRESS_COMPONENTS = [
  {
    name: 'countryCode',
    label: 'Country Code',
    key: 'countryCode',
  },
  {
    name: 'countryName',
    label: 'Country Name',
    key: 'countryName',
  },
  {
    name: 'stateCode',
    label: 'State Code',
    key: 'stateCode',
  },
  {
    name: 'stateName',
    label: 'State Name',
    key: 'stateName',
  },
  {
    name: 'province',
    label: 'Province',
    key: 'province',
  },

  {
    name: 'municipality',
    label: 'Municipality',
    key: 'municipality',
  },
  {
    name: 'streetName',
    label: 'Street Name',
    key: 'streetName',
  },
  {
    name: 'streetNumber',
    label: 'Street Number',
    key: 'streetNumber',
  },
  {
    name: 'postalCode',
    label: 'Postal Code',
    key: 'postalCode',
  },
  {
    name: 'county',
    label: 'County',
    key: 'county',
  },
  {
    name: 'latitude',
    label: 'Latitude',
    key: 'latitude',
  },
  {
    name: 'longitude',
    label: 'Longitude',
    key: 'longitude',
  },
  {
    name: 'premise',
    label: 'Premise',
    key: 'premise',
  },
];

export const BRIDGE_FULL_FORM_STEPS = [
  {
    text: 'Investment Experience',
    key: 'investment-experience',
  },
  {
    text: 'Project Overview',
    key: 'project-overview',
  },
  {
    text: 'Personal Financials',
    key: 'personal-financials',
  },
  {
    text: 'Company Information',
    key: 'company-information',
  },
  {
    text: 'Signature',
    key: 'signature',
  },
];

export const RENTAL_FULL_FORM_STEPS = [
  {
    text: 'Investment Experience',
    key: 'investment-experience',
  },
  {
    text: 'Project Overview',
    key: 'project-overview',
  },
  {
    text: 'Personal Financials',
    key: 'personal-financials',
  },
  {
    text: 'Company Information',
    key: 'company-information',
  },
  {
    text: 'Signature',
    key: 'signature',
  },
];

export const PRE_APPROVAL_FULL_FORM_STEPS = [
  {
    text: 'Investment Experience',
    key: 'investment-experience',
  },
  {
    text: 'Personal Financials',
    key: 'personal-financials',
  },
  {
    text: 'Company Information',
    key: 'company-information',
  },
  {
    text: 'Signature',
    key: 'signature',
  },
];

export const SIGNATURE_INITIAL_VALUES = {
  v1: {
    creditAndBackgroundRealease: `Each party signing this application (each, an “Applicant” and collectively, the “Applicants”) authorizes ${HUMAN_APP_NAME}, LLC, its successors and/or assigns, (the “Lender”) to obtain a credit report, background check, verify past and present mortgage accounts, obtain landlord references, verify income and request, verify and review all information that the Lender, in its sole discretion, deems relevant or otherwise appropriate as to the Applicant or any of its principals and guarantors, in connection with this application or any future review, update, extension, renewal or modification of any credit extended to the Applicant by the Lender as a result of this application, or in connection with the Lender’s collection efforts relating to any such credit. It is understood that a copy of this form, digitally delivered or hard file, will serve as authorization. Each Signer authorizes the Lender to provide credit information about such Signer to any qualified third party requesting such information. If you are executing this document online, by signing the document you consent to the use of electronic signatures. Electronic signatures are legally binding just like a written signature. You may request a copy of the credit report by mailing the address found above.`,
    investmentPurpose:
      'The Applicant represents that the loan is not intended to, and shall not be used for, household, familial, personal or consumer purposes, and shall only be used for investment and commercial purposes.',
    accurateInformation:
      'Each Signer certifies that the information provided in this application and any other documents or information submitted in connection herewith is true and correct as of the date specified beside such Signer’s signature below. Each Signer acknowledges that any misrepresentation, whether intentional or negligent, in this application may result in civil liabilities and criminal penalties, including fine and imprisonment or both, pursuant to 18 U.S. Code § 1014, as well as liability for monetary damages to the Lender and to all agents, assigns and insurers of the Lender, and any others, that suffer any damages as a result of any such misrepresentation. \b\rEach of the undersigned authorizes the Lender to consider this Personal Financial Statement as a continuing statement of financial condition until replaced by a new Personal Financial Statement or until the undersigned specifically notifies the Lender in writing of any change in such financial condition.',
    disclaimer: {
      denialNotice: `Pursuant to the Federal Equal Credit Opportunity Act (“ECOA’), the Applicant has the right to receive a statement from the Lender setting forth the specific reason(s) for denial of a loan / credit facility within 30 days of such denial; provided, however, that the Applicant makes such request in writing within 60 days of receiving notification of such denial from the Lender. Such requests shall be sent via US Mail to ${HUMAN_APP_NAME}, LLC, Attn: Legal, 777 Yamato Road, Suite 510 Boca Raton, FL 33431; phone number: (888) 987-1276.`,
      antiDiscriminationNotice:
        "ECOA prohibits creditors from discriminating against credit applicants (1) on the basis of race, color, religion, national origin, sex, marital status, age (provided the applicant has the capacity to enter into a binding contract); (2) because all or part of the applicant's income derives from any public assistance program; or (3) because the applicant has in good faith exercised any right under the Consumer Credit Protection Act. The Federal agency that administers the Lender’s compliance with this law is the Federal Trade Commission, Equal Credit Opportunity, Washington, DC 20580.",
    },
    waiver:
      'The Lender may order an appraisal or other valuation (the “Appraisal/Valuation”) on the subject real property to determine its value and may charge the Applicant a reasonable fee for the Appraisal/Valuation. The Applicant is entitled to receive a copy of the Appraisal/Valuation promptly upon its completion or 3 business days prior to closing of the loan, whichever is earlier. The Applicant hereby waives this timing requirement and agrees to receive a copy of the Appraisal/Valuation upon closing and funding of the loan, except where otherwise prohibited by law. The Applicant also hereby consents to receive the Appraisal/Valuation and related information in electronic form, and hereby affirms that it can access all such information in electronic form. Upon written request to the Lender at the address found above, the Applicant may withdraw its consent to receive the Appraisal/Valuation and related information in electronic form and instead be provided a hardcopy paper, nonelectronic form of the Appraisal/Valuation. In such event, the Applicant will only be charged for shipping and handling.',
  },
  v2: {
    creditAndBackgroundRealease: `Each party signing this application (each, an “Applicant” or “Signer” and collectively, the “Applicants” or “Signers”) authorizes ${HUMAN_APP_NAME}, LLC, its successors and/or assigns (“${HUMAN_APP_NAME}“ or “Lender”) to obtain a credit report, background check, verify past and present mortgage accounts, obtain landlord references, verify income, and request, verify, and review all information that Lender, in its sole discretion, deems relevant or otherwise appropriate as to the Applicant or any of its principals and guarantors, in connection with this application or any future review, update, extension, renewal or modification of any credit extended to the Applicant by Lender as a result of this application, or in connection with Lender’s collection efforts relating to any such credit. It is understood that a copy of this form, digitally delivered or hard file, will serve as authorization. Each Signer authorizes Lender to provide credit information about such Signer to any qualified third party requesting such information. If  executing this document online, by signing the document each Signer consents to the use of electronic signatures. Electronic signatures are legally binding just like a written signature. Each Signer may request a copy of the credit report by mailing the address found above.`,
    investmentPurpose: `${HUMAN_APP_NAME} only lends to corporate entities for business purpose loans. ${HUMAN_APP_NAME} does not extend credit for personal, family, or household use.  As such, the Applicant represents that the loan is not intended to, and shall not be used for, household, familial, personal or consumer purposes, and shall only be used for investment and commercial purposes.`,
    accurateInformation: `The Applicant certifies that the information provided in this application and any other documents or information submitted in connection with this application is true and correct as of the date on which this application is submitted. The Applicant acknowledges that any misrepresentation, whether intentional or negligent, made in this application may result in civil liabilities and criminal penalties, including fine and imprisonment or both, pursuant to 18 U.S. Code § 1014, as well as liability for monetary damages to ${HUMAN_APP_NAME} and to all agents, assigns and insurers of ${HUMAN_APP_NAME}, and any others, that suffer any damages as a result of any such misrepresentation. The Applicant authorizes ${HUMAN_APP_NAME} to consider this application as a continuing statement of financial condition until the Applicant specifically notifies ${HUMAN_APP_NAME} in writing of any change in such financial condition.`,
    disclaimer: {
      equalCredit: `Pursuant to the Federal Equal Credit Opportunity Act and its implementing regulation, Regulation B, (“ECOA”), ${HUMAN_APP_NAME} hereby makes the following disclosures to the Applicant.`,
      denialNotice: `In accordance with ECOA and ${HUMAN_APP_NAME}'s Notice of Action Taken Policy, upon receipt of a completed version of this application, ${HUMAN_APP_NAME} shall evaluate the creditworthiness of this application and notify the Applicant of action taken with respect to this application within thirty (30) calendar days from the date ${HUMAN_APP_NAME} receives the completed application.\n\n In the event ${HUMAN_APP_NAME} denies this Application, the Applicant has the right to receive a statement from ${HUMAN_APP_NAME} setting forth the specific reason(s) for denial of a loan / credit facility within thirty (30) days of such denial and that the Applicant must make such a request in writing within sixty (60) days of receiving notification of such denial from ${HUMAN_APP_NAME}. Such requests shall be sent via US Mail to ${HUMAN_APP_NAME}, LLC, Attn: Legal, 777 Yamato Road, Suite 510, Boca Raton, FL 33431; phone number: (561) 672-0845.`,
      antiDiscriminationNotice: `ECOA prohibits creditors from discriminating against credit applicants (1) on the basis of race, color, religion, national origin, sex, marital status, age (provided the Applicant has the capacity to enter into a binding contract); (2) because all or part of the Applicant's income derives from any public assistance program; or (3) because the Applicant has in good faith exercised any right under the Consumer Credit Protection Act. The Federal agency that administers ${HUMAN_APP_NAME}'s compliance with this law is the Federal Trade Commission, Equal Credit Opportunity, Washington, DC 20580.`,
    },
    waiver: `${HUMAN_APP_NAME} may order an appraisal or other valuation (the “Appraisal/Valuation”) on the subject real property to determine its value and may charge the Applicant a reasonable fee for the Appraisal/Valuation. The Applicant is entitled to receive a copy of the Appraisal/Valuation promptly upon its completion or 3 business days prior to consummation of the loan, whichever is earlier. The Applicant hereby waives this timing requirement and agrees to receive a copy of the Appraisal/Valuation at closing except where otherwise prohibited by law. The Applicant also hereby consents to receive the Appraisal/Valuation and related information in electronic form, and hereby affirms that it can access all such information in electronic form. The Applicant may withdraw its consent and be provided a hardcopy paper, nonelectronic form of the Appraisal/Valuation upon written request to ${HUMAN_APP_NAME} at the address found above.  In such event, the Applicant may only be charged for shipping and handling.`,
  },
  v3: {
    creditAndBackgroundRealease: `Each party signing this application (each, an “Applicant” or “Signer” and collectively, the “Applicants” or “Signers”) authorizes ${HUMAN_APP_NAME}, LLC, its successors and/or assigns (“${HUMAN_APP_NAME}” or “Lender”) to obtain a credit report, background check, verify past and present mortgage accounts, obtain landlord references, verify income, and request, verify, and review all information that Lender, in its sole discretion, deems relevant or otherwise appropriate as to the Applicant or any of its principals and guarantors, in connection with this application or any future review, update, extension, renewal or modification of any credit extended to the Applicant by Lender as a result of this application, or in connection with Lender’s collection efforts relating to any such credit. It is understood that a copy of this form, digitally delivered or hard file, will serve as authorization. Each Signer authorizes Lender to provide credit information about such Signer to any qualified third party requesting such information. If  executing this document online, by signing the document each Signer consents to the use of electronic signatures. Electronic signatures are legally binding just like a written signature. Each Signer may request a copy of the credit report by mailing the address found above.`,
    investmentPurpose: `${HUMAN_APP_NAME} only lends to corporate entities for business purpose loans. ${HUMAN_APP_NAME} does not extend credit for personal, family, or household use.  As such, the Applicant represents that the loan is not intended to, and shall not be used for, household, familial, personal or consumer purposes, and shall only be used for investment and commercial purposes.`,
    accurateInformation: `The Applicant certifies that the information provided in this application and any other documents or information submitted in connection with this application is true and correct as of the date on which this application is submitted. The Applicant acknowledges that any misrepresentation, whether intentional or negligent, made in this application may result in civil liabilities and criminal penalties, including fine and imprisonment or both, pursuant to 18 U.S. Code § 1014, as well as liability for monetary damages to ${HUMAN_APP_NAME} and to all agents, assigns and insurers of ${HUMAN_APP_NAME}, and any others, that suffer any damages as a result of any such misrepresentation.\n\nThe Applicant authorizes ${HUMAN_APP_NAME} to consider this application as a continuing statement of financial condition until the Applicant specifically notifies ${HUMAN_APP_NAME} in writing of any change in such financial condition.`,
    disclaimer: {
      equalCredit: `Pursuant to the Federal Equal Credit Opportunity Act and its implementing regulation, Regulation B, (“ECOA”), ${HUMAN_APP_NAME} hereby makes the following disclosures to the Applicant.`,
      denialNotice: `In accordance with ECOA and ${HUMAN_APP_NAME}’s Notice of Action Taken Policy, upon receipt of a completed version of this application, ${HUMAN_APP_NAME} shall evaluate the creditworthiness of this application and notify the Applicant of action taken with respect to this application within thirty (30) calendar days from the date ${HUMAN_APP_NAME} receives the completed application.\n\nIn the event ${HUMAN_APP_NAME} denies this Application, the Applicant has the right to receive a statement from ${HUMAN_APP_NAME} setting forth the specific reason(s) for denial of a loan / credit facility within thirty (30) days of such denial and that the Applicant must make such a request in writing within sixty (60) days of receiving notification of such denial from ${HUMAN_APP_NAME}. Such requests shall be sent via US Mail to ${HUMAN_APP_NAME}, LLC, Attn: Legal, 777 Yamato Road, Suite 510, Boca Raton, FL 33431; phone number: (561) 672-0845.`,
      antiDiscriminationNotice: `ECOA prohibits creditors from discriminating against credit applicants (1) on the basis of race, color, religion, national origin, sex, marital status, age (provided the Applicant has the capacity to enter into a binding contract); (2) because all or part of the Applicant's income derives from any public assistance program; or (3) because the Applicant has in good faith exercised any right under the Consumer Credit Protection Act. The Federal agency that administers ${HUMAN_APP_NAME}’s compliance with this law is the Federal Trade Commission, Equal Credit Opportunity, Washington, DC 20580.`,
    },
    waiver: `${HUMAN_APP_NAME} may order an appraisal or other valuation (the “Appraisal/Valuation”) on the subject real property to determine its value and may charge the Applicant a reasonable fee for the Appraisal/Valuation. The Applicant is entitled to receive a copy of the Appraisal/Valuation promptly upon its completion or 3 business days prior to consummation of the loan, whichever is earlier. The Applicant hereby waives this timing requirement and agrees to receive a copy of the Appraisal/Valuation at closing except where otherwise prohibited by law. The Applicant also hereby consents to receive the Appraisal/Valuation and related information in electronic form, and hereby affirms that it can access all such information in electronic form. The Applicant may withdraw its consent and be provided a hardcopy paper, nonelectronic form of the Appraisal/Valuation upon written request to ${HUMAN_APP_NAME} at the address found above.  In such event, the Applicant may only be charged for shipping and handling.`,
  },
};

export const DEFAULT_FILES_WHITE_LIST =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/msword,.doc,.docx,.pdf,.jpg,.jpeg,.png,.tiff,.bmp,.csv,.html,.htm,.xlsx,.xls';

export const CREDIT_CARD_AUTHORIZATION_TERM =
  'By signing this form I authorize Crestar Funding, LLC to charge the credit card indicated in this authorization form according to the terms outlined above. This payment authorization is for items outlined above as part of your loan application, for the amount indicated above only, and is valid for one time use only. I certify that I am an authorized user of this credit card and that I will not dispute the payment with my credit card company; so long as the transaction corresponds to the terms indicated in this form.';

export const DROW_REQUEST_AGREEMENT =
  'The Borrower represents and warrants that the conditions to funding the Holdback in the Loan Agreement has been satisfied. The Borrower also certifies that (i) no Event of Default (as defined in the Loan Agreement), nor any event, circumstance or condition which with notice or the passage of time or both would be an Event of Default, has occurred and is continuing, (ii) Borrower knows of no fact or circumstance which will or could prejudice repayment of the Loan; (iii) Each representation and warranty set forth in Section 6 of the Loan Agreement remains true and correct; and (iv) this Advance Request has been duly authorized by Borrower. Lender is authorized and directed to disburse the Holdback to the account attached here to as';

export const PASSWORD_ERRORS_LIST = [
  {
    name: 'properLength',
    passed: false,
    text: 'Password too short, it has to be 8 characters long',
  },
  {
    name: 'hasCapitalLetter',
    passed: false,
    text: 'Password should include at least 1 capital letter',
  },
  {
    name: 'hasLowerCaseLetter',
    passed: false,
    text: 'Password should include at least 1 lowercase letter',
  },
  {
    name: 'hasSpecialCharacter',
    passed: false,
    text: 'Password should include at least 1 special character',
  },
  {
    name: 'hasDigit',
    passed: false,
    text: 'Password should include at least 1 digit',
  },
  {
    name: 'passwordsMustBeTheSame',
    passed: false,
    text: 'Password and Password Confirmation should be equal',
  },
];

export const REFERRAL_TOKEN_COOKIE_NAME = 'referral_token';

export const TRACK_HIDDEN_FIELDS = [
  'google_analytics_client_id',
  'gclid',
  'msclkid',
  'rdt_cid',
  'fbclid',
  'li_fat_id',
];
