import PropTypes from 'prop-types';

const user = PropTypes.shape({
  id: PropTypes.number,
  contact: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    stateCode: PropTypes.string,
    transactionPurpose: PropTypes.string,
    investmentPropertiesCountSoldLast36Months: PropTypes.number,
    creditScore: PropTypes.string,
    loanOfficerId: PropTypes.string,
    promoCodeToken: PropTypes.string,
    entityId: PropTypes.string,
    referredByType: PropTypes.string,
    referredByComment: PropTypes.string,
    nameOrCode: PropTypes.string,
    serviceTerms: PropTypes.bool,
    incompleteApp: PropTypes.shape({
      token: PropTypes.string,
    }),
  }),
});

export const userPropTypes = {
  user,
};

export const userDefaultProps = {
  user: null,
};
