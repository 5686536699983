import React, { Fragment } from 'react';

import { NarrowFormFlex, FormBox, FormGroupLoader } from 'components/shared/form';

function Loader() {
  return (
    <Fragment>
      <NarrowFormFlex>
        <FormBox width={1}>
          <FormGroupLoader labelWidth="2.375rem" />
        </FormBox>
      </NarrowFormFlex>
    </Fragment>
  );
}

export default Loader;
