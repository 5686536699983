import React from 'react';
import { FormSpy } from 'react-final-form';

const Hoc = (Component) => {
  function WithFinalFormValues(props) {
    return (
      <FormSpy
        subscription={{ values: true }}
        render={({ values, form }) => <Component form={form} values={values} {...props} />}
      />
    );
  }

  return WithFinalFormValues;
};

export default Hoc;
