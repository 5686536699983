import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as R from 'ramda';

import * as actions from 'actions';

import { ModalPortal } from 'components/shared';

import { AUTH_TOKEN, AUTH_TOKEN_EVENT } from 'constants/auth';

import Display from './Display';
import * as handlers from './handlers';

const propTypes = {
  show: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

function LockOutListener({ show, toggleModal, closeModal }) {
  return (
    <ModalPortal show={show} handleTogglePortal={toggleModal} title="Your session is about to expire!">
      <Display closeModal={closeModal} />
    </ModalPortal>
  );
}

LockOutListener.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
  return {
    logout: bindActionCreators(actions.logout, dispatch),
  };
}

const enhance = compose(
  withState('show', 'setShow', false),
  withState('timer', 'setTimer', null),
  withState('modalTimer', 'setModalTimer', null),
  connect(
    null,
    mapDispatchToProps,
  ),
  withHandlers({
    showModal: handlers.showModal,
    closeModal: handlers.closeModal,
    toggleModal: handlers.toggleModal,
  }),
  withHandlers({
    startTimer: handlers.startTimer,
  }),
  lifecycle({
    componentDidMount() {
      const { startTimer } = this.props;
      startTimer();
      window.addEventListener('storage', ({ newValue, key }) => {
        if (key !== AUTH_TOKEN) return;
        if (R.isNil(newValue)) window.location.reload();
        startTimer();
      });
      window.addEventListener(AUTH_TOKEN_EVENT, () => {
        const isIE = /* @cc_on!@ */ false || !!document.documentMode;
        if (isIE) return;
        startTimer();
      });
    },
  }),
  pure,
);

export default enhance(LockOutListener);
