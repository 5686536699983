import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'grid-styled';

import ContentLoader from 'components/shared/ContentLoader';

const propTypes = {
  className: PropTypes.string,
  labelWidth: PropTypes.string,
  inline: PropTypes.bool,
};

const defaultProps = {
  className: undefined,
  labelWidth: '10rem',
  inline: false,
};

function RadioGroupLoader({ className, inline, labelWidth }) {
  return (
    <Flex className={className} flexDirection="column">
      <ContentLoader mb="0.85rem" maxWidth={labelWidth} height="1.3125rem" />
      <Box mb="1rem" width={1}>
        <Flex flexDirection={inline ? 'row' : 'column'}>
          <Box>
            <Flex alignItems="center">
              <ContentLoader width="1.5rem" height="1.5rem" borderRadius="50%" />
              <ContentLoader ml="0.375rem" width="2.5rem" height="1rem" />
            </Flex>
          </Box>
          <Box ml={inline && '1.25rem'} mt={inline || '0.5rem'}>
            <Flex alignItems="center">
              <ContentLoader width="1.5rem" height="1.5rem" borderRadius="50%" />
              <ContentLoader ml="0.375rem" width="2rem" height="1rem" />
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
}

RadioGroupLoader.propTypes = propTypes;
RadioGroupLoader.defaultProps = defaultProps;

export default RadioGroupLoader;
