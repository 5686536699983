import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
// import middlewares from 'middlewares';

import rootReducer from 'reducers/index';

export const history = createBrowserHistory();

export function configureStore(initialState) {
  const state = {
    ...initialState,
  };

  const enhancers = [];
  // const middleware = [thunk, ...middlewares, routerMiddleware(history)];
  const middleware = [thunk, routerMiddleware(history)];

  if (process.env.NODE_ENV === 'development') {
    const { __REDUX_DEVTOOLS_EXTENSION__ } = window;

    if (typeof __REDUX_DEVTOOLS_EXTENSION__ === 'function') {
      enhancers.push(__REDUX_DEVTOOLS_EXTENSION__());
    }
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers,
  );

  const store = createStore(rootReducer(history), state, composedEnhancers);

  return store;
}
