import Payment from 'payment';
import * as R from 'ramda';

const slicing = (issuer) => (value) => {
  if (issuer === 'amex') return `${R.slice(0, 4, value)} ${R.slice(4, 10, value)} ${R.slice(10, 15, value)}`;
  if (issuer === 'dinersclub') return `${R.slice(0, 4, value)} ${R.slice(4, 10, value)} ${R.slice(10, 14, value)}`;
  return `${R.slice(0, 4, value)} ${R.slice(4, 8, value)} ${R.slice(8, 12, value)} ${R.slice(12, 19, value)}`;
};

export function parseCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);

  return R.pipe(R.replace(/\D/g, ''), slicing(issuer), R.trim)(value);
}
