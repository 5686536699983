import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'components/shared';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

const Footer = styled.div`
  position: absolute;
  bottom: 30px;
  left: 0;
  text-align: center;
  width: 100%;
`;

const ProductButton = ({ children }) => (
  <Footer>
    <Button rounded>{children}</Button>
  </Footer>
);

ProductButton.propTypes = propTypes;
export default ProductButton;
