import React from 'react';
import PropTypes from 'prop-types';
import DropzoneComponent from 'react-dropzone';

import { DEFAULT_FILES_WHITE_LIST } from 'constants/forms';

import Template from './Template';
import './style.css';

const propTypes = {
  filesWhitelist: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  template: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.any)]),
};

const defaultProps = {
  filesWhitelist: DEFAULT_FILES_WHITE_LIST,
  multiple: false,
  template: undefined,
  title: undefined,
  value: [],
};

function Dropzone({ value, title, filesWhitelist, multiple, onChange, invalid, template, ...props }) {
  return (
    <DropzoneComponent
      accept={filesWhitelist}
      multiple={multiple}
      className="dropzone"
      disableClick
      maxSize={2.5e7}
      onDrop={onChange}
      {...props}
    >
      {template || (({ open }) => <Template value={value} title={title} open={open} />)}
    </DropzoneComponent>
  );
}

Dropzone.propTypes = propTypes;
Dropzone.defaultProps = defaultProps;

export default Dropzone;
