import PropTypes from 'prop-types';
import styled from 'styled-components';
import { palette } from 'styled-tools';

const propTypes = {
  palette: PropTypes.string,
};

const defaultProps = {
  palette: 'grayscale',
};

const Label = styled.label`
  color: ${palette(1)};
  font-size: 1rem;
  line-height: 1.4em;
`;

Label.propTypes = propTypes;
Label.defaultProps = defaultProps;
export default Label;
