import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContentLoader from 'react-content-loader';

import * as actions from 'actions';
import { requestInProcess } from 'selectors';

import { Link } from 'components/shared';

import * as requestTypes from 'constants/requestTypes';
import { applicationPropTypesRequired } from 'propTypes';
import { getIn, percentFormat, decimalCurrencyFormat } from 'utils';

import { UpdateLTVForm } from 'components/blocks/LeadForm/shared/Qualified/shared';
import { Table, Th, Tr, Td } from 'components/blocks/LeadForm/shared/Qualified/shared/Table';

const propTypes = {
  ...applicationPropTypesRequired,
  className: PropTypes.string,
  selectQuote: PropTypes.func.isRequired,
};

const defaultProps = {
  className: null,
};

function Quotes({ application, className, selectQuote, loading }) {
  return (
    <React.Fragment>
      <UpdateLTVForm />
      {loading ? (
        <ContentLoader speed={3}>
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="10" />
          <rect x="0" y="14" rx="5" ry="5" width="100%" height="10" />
          <rect x="0" y="28" rx="5" ry="5" width="100%" height="10" />
          <rect x="0" y="42" rx="5" ry="5" width="100%" height="10" />
        </ContentLoader>
      ) : (
        <Fragment>
          <QuotesHeader>Rate Quote prior to Completed Application</QuotesHeader>
          <Table className={className}>
            <thead>
              <Tr>
                <Th />
                <Th>Loan Amount</Th>
                <Th>Interest Rate</Th>
                <Th>Term</Th>
                <Th>Origination Fee</Th>
                <Th />
              </Tr>
            </thead>
            <tbody>
              {application.quotes &&
                application.quotes.map((quote, index) => (
                  <Tr selected={quote.selected} key={`application-${application.id}-quote-${index}`}>
                    <Td>{quote.product.name}</Td>
                    <Td>
                      <Strong>{decimalCurrencyFormat(quote.totalAmount)}</Strong>
                    </Td>
                    <Td>
                      <Strong>{percentFormat(quote.interestRate)}</Strong>
                    </Td>
                    <Td>
                      <Strong>{quote.term}</Strong>
                    </Td>
                    <Td>
                      <Strong>{percentFormat(quote.originationFeePercent)}</Strong>
                    </Td>
                    <Td>
                      {quote.selected && <span>Selected</span>}
                      {quote.selected || (
                        <Link
                          onClick={() =>
                            selectQuote({
                              token: application.token,
                              quote: { ...quote, productId: getIn('product.id', quote) },
                            })
                          }
                        >
                          Select
                        </Link>
                      )}
                    </Td>
                  </Tr>
                ))}
            </tbody>
          </Table>
        </Fragment>
      )}
    </React.Fragment>
  );
}

const Strong = styled.strong`
  font-weight: bold;
`;

const QuotesHeader = styled.div`
  text-align: center;
  font-weight: 700;
  padding-bottom: 5px;
  border-bottom: 1px solid #d5dde9;
`;

Quotes.propTypes = propTypes;
Quotes.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  const loading = requestInProcess(state, requestTypes.QUOTE);

  return {
    loading,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    selectQuote: bindActionCreators(actions.selectQuote, dispatch),
    updateLTV: bindActionCreators(actions.discussQuote, dispatch),
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  pure,
);

export default enhance(Quotes);
