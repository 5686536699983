import { STATUSES } from 'constants/applications';
import { APPLICATION_PATH, APPLICATION_STEP_PATH, LEAD_PATH } from 'constants/routes';
import { getPath } from 'utils';

export function ratioOfStatuses(operate, first, second) {
  return operate(STATUSES.indexOf(first), STATUSES.indexOf(second));
}

export function statusGreatThan(base, status) {
  const operate = (a, b) => a > b;
  return ratioOfStatuses(operate, status, base);
}

export function statusThisOrGreatThan(base, status) {
  const operate = (a, b) => a >= b;
  return ratioOfStatuses(operate, status, base);
}

export function getApplicationPath(application) {
  const { leadStep, dataEntryStep, token, status } = application;

  if (status === 'application') return getPath(APPLICATION_PATH, { token });

  if (leadStep === 'complete') {
    return getPath(APPLICATION_STEP_PATH, { token, step: dataEntryStep });
  }

  return getPath(LEAD_PATH, { token });
}
