import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withState, withHandlers } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form as FinalForm } from 'react-final-form';
import createFocusDecorator from 'final-form-focus';

import * as actions from 'actions';
import { holdMutators } from 'utils';

import Form from './Form';
import { handlers } from './shared';

const propTypes = {
  initialValues: PropTypes.shape({
    token: PropTypes.string,
    contact: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const focusOnError = createFocusDecorator();

function NewContact({ initialValues, onSubmit }) {
  const setError = ([fieldNames, error], state) => {
    const { fields } = state;

    const touched = !!error;

    fieldNames.forEach((fieldName) => {
      const field = fields[fieldName];

      if (field && field.data) field.data.error = error;
      if (field) field.touched = touched;
    });
  };

  return (
    <FinalForm
      onSubmit={onSubmit}
      decorators={[focusOnError]}
      initialValues={initialValues}
      mutators={{ setError, ...holdMutators }}
      subscription={{ submitting: true, pristine: true }}
      loading={false}
      component={Form}
    />
  );
}

NewContact.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
  return {
    onSubmitAction: bindActionCreators(actions.createContact, dispatch),
  };
}

const enhance = compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps,
  ),
  withHandlers({
    setDefaultInitialValues: handlers.setDefaultInitialValues,
  }),
  withState('initialValues', 'setInitialValues', ({ setDefaultInitialValues, defaultValues }) =>
    setDefaultInitialValues(defaultValues)),
  withHandlers({
    onSubmit: handlers.onSubmit,
  }),
  pure,
);

export default enhance(NewContact);
