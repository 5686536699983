import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormFlex, FormBox } from 'components/shared/form';

const propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

const defaultProps = {
  subtitle: undefined,
};

function Header({ title, subtitle, ...props }) {
  return (
    <FormFlex alignItems="center">
      <FormBox alignSelf={['flex-start', 'flex-start', 'inherit']}>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </FormBox>
    </FormFlex>
  );
}

const Title = styled.div`
  text-align: left;
  font-size: 1.375rem;
  font-weight: 300;
  color: #111;
`;

const Subtitle = styled.div`
  margin: 5px 0 0 0;
  text-align: left;
  font-size: 1rem;
  font-weight: 300;
  color: #666;
`;

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;
export default Header;
