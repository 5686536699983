import React, { useState } from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { Icon } from 'components/shared';
import { useSelector } from 'react-redux';
import {
  getApplicationProperties,
} from 'selectors/applications';
import { dateHumanFormat } from 'utils';


import { Title } from '../shared';
import './style.css';

function AppraisalStatus() {
  const [cardNumber, setCardNumber] = useState(0);
  const applicationProperties = useSelector(getApplicationProperties);
  const applicationPropertiesAmount = applicationProperties.length;

  if (applicationPropertiesAmount === 0) return null;

  const handleNext = () => {
    if (cardNumber === applicationPropertiesAmount - 1) {
      setCardNumber(0);
    } else {
      setCardNumber(cardNumber + 1);
    }
  };
  const handlePrev = () => {
    if (cardNumber === 0) {
      setCardNumber(applicationPropertiesAmount - 1);
    } else {
      setCardNumber(cardNumber - 1);
    }
  };

  const { address, valuation } = applicationProperties[cardNumber];
  const {
    appraisalOrderDate,
    appraisalInspectionDate,
    appraisalDueDate,
    appraisalReceivedDate,
    appraisalApprovedDate,
    appraisalApprovedByUwDate,
    appraisalHoldReason,
    appraisalPaidAt,
  } = valuation;

  return (
    <Container>
      <Title>Appraisal Status</Title>
      <Card>
        <Info>
          <StyledH4>{address}</StyledH4>
          {appraisalPaidAt && (
            <StyledOl className="table table-centered table-condensed">
              <StyledLi>
                Appraisal Payment Date: <strong>{dateHumanFormat(appraisalPaidAt)}</strong>
              </StyledLi>
            </StyledOl>
          )}
          <StyledOl className="table table-centered table-condensed">
            {appraisalOrderDate && (
              <StyledLi>
                Appraisal Order Date: <strong>{dateHumanFormat(appraisalOrderDate)}</strong>
              </StyledLi>
            )}
            {appraisalInspectionDate && (
              <StyledLi>
                Appraisal Inspection Date: <strong>{dateHumanFormat(appraisalInspectionDate)}</strong>
              </StyledLi>
            )}
            {appraisalDueDate && (
              <StyledLi>
                Appraisal Due Date: <strong>{dateHumanFormat(appraisalDueDate)}</strong>
              </StyledLi>
            )}
            {appraisalReceivedDate && (
              <StyledLi>
                Appraisal Received Date: <strong>{dateHumanFormat(appraisalReceivedDate)}</strong>
              </StyledLi>
            )}
            {appraisalApprovedDate && (
              <StyledLi>
                Appraisal Approved Date: <strong>{dateHumanFormat(appraisalApprovedDate)}</strong>
              </StyledLi>
            )}
            {appraisalApprovedByUwDate && (
              <StyledLi>
                Appraisal Approved by UW Date: <strong>{dateHumanFormat(appraisalApprovedByUwDate)}</strong>
              </StyledLi>
            )}
            {appraisalHoldReason && (
              <StyledLi>
                Appraisal Hold Reason: <strong>{appraisalHoldReason}</strong>
              </StyledLi>
            )}
          </StyledOl>
        </Info>
        {applicationPropertiesAmount > 1 && (
          <Buttons>
            <Button onClick={handlePrev}>
              <Icon icon="circleArrow" />
            </Button>
            <Button onClick={handleNext}>
              <Icon icon="circleArrow" className="rotate" />
            </Button>
          </Buttons>
        )}
      </Card>
    </Container>
  );
}

export default AppraisalStatus;

const Container = styled.div`
  padding: 2.1875rem 5.1875rem 2.1875rem 0;

  @media (max-width: ${prop('theme.breakpoints.1')}) {
    padding: 2.1875rem 0;
  }
`;

const Card = styled.div`
  display: flex;
  background: #ffffff;
  border: 0.0625rem solid #ebebeb;
  padding: 1.25rem;
  box-shadow: 0 0.25rem 0.375rem 0 rgb(234 234 234 / 50%);
  font-size: 1rem;
`;

const Info = styled.div`
  width: 100%;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 70px;
`;

const Button = styled.button`
  background: none;
  border: none;
  margin: 0.1em;
  padding: 0;
  width: 24px;
  height: 24px;

  > span {
    width: 24px;
    height: 24px;

    > svg {
      fill: #979797;
    }
  }

  :hover {
    cursor: pointer;
  }
`;

const StyledOl = styled.div`
  margin: 0;
  padding: 0;
`;

const StyledLi = styled.div`
  margin: 0;
  padding: 5px 10px;
  list-style: none;
  border-bottom: 1px solid #e8e8e8;
`;

const StyledH4 = styled.h4`
  margin: 0;
  padding: 5px 10px 0;
  font-weight: bold;
`;
