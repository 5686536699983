import React from 'react';
import styled from 'styled-components';

import { Paragraph, Strong, Wrapper, Well } from 'components/shared';

function SimplifiedLoans() {
  return (
    <Wrapper padded>
      <Well title="Real Estate Investor Loans... Simplified">
        <StyledParagraph>
          We have definitely walked a mile in your shoes, fixing and flipping over 300 homes, which has made us into a
          team of dedicated loan professionals with industry experience.
        </StyledParagraph>
        <List>
          <ListLi>
            <Strong>Easy online process with approval and rate quote in minutes.</Strong>
          </ListLi>
          <ListLi>
            <Strong>Personalized service with your own loan consultant and closing agent.</Strong>
          </ListLi>
          <ListLi>
            <Strong>Fast closings in 10-14 days.</Strong>
          </ListLi>
        </List>
      </Well>
    </Wrapper>
  );
}

const StyledParagraph = styled(Paragraph)`
  font-weight: 300;
  color: #77808b;
`;

const List = styled.ol`
  margin: 1em 0;
  padding-left: 40px;
  list-style-type: decimal;
`;

const ListLi = styled.li`
  font-size: 1.375rem;
  color: #77808b;
`;

export default SimplifiedLoans;
