import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Button } from 'components/shared';
import { FormBox, FormStepHeader, NarrowFormFlex } from 'components/shared/form';
import Well, { WellBody, WellHeader, WellFooter } from 'components/shared/Well';

import { LEAD_PROPERTY_PATH } from 'constants/routes';
import { getPath } from 'utils';

import Fields from './Fields';
import Loader from './Loader';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

function Form({ match, handleSubmit, loading, submitting }) {
  const { token } = match.params;
  return (
    <form>
      <Well>
        <WellHeader key="header">
          <FormStepHeader
            title="Apply For Rental Loan"
            subtitle="Your Instant Rate Quote is Only 1 Step Away"
            current={3}
            total={3}
          />
        </WellHeader>

        <WellBody key="body">
          {loading && <Loader />}
          {!loading && <Fields />}
        </WellBody>

        <WellFooter key="footer">
          <NarrowFormFlex alignItems="center" justifyContent="space-between">
            <FormBox pl={3} pr={3}>
              <Button to={getPath(LEAD_PROPERTY_PATH, { token })} transparent disabled={submitting}>
                Back
              </Button>
            </FormBox>
            <FormBox p={[3, 3, 0]} alignSelf={['flex-end', 'flex-end', 'inherit']}>
              <Button onClick={handleSubmit} dataName="buttonNext" rounded disabled={loading || submitting}>
                {submitting ? 'Please wait…' : 'Next'}
              </Button>
            </FormBox>
          </NarrowFormFlex>
        </WellFooter>
      </Well>
    </form>
  );
}

Form.propTypes = propTypes;

export default withRouter(Form);
