import React from 'react';
import PropTypes from 'prop-types';
import NavLink from 'react-router-dom/NavLink';
import styled, { css } from 'styled-components';
import { ifProp, prop, palette as toolPalette } from 'styled-tools';

const propTypes = {
  bs: PropTypes.oneOf(['dashed', 'dotted']),
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fz: PropTypes.number,
  palette: PropTypes.string,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.objectOf(PropTypes.any),
    }),
    PropTypes.func,
  ]),
  underline: PropTypes.bool,
};

const defaultProps = {
  bs: undefined,
  children: undefined,
  className: undefined,
  disabled: false,
  fz: undefined,
  palette: 'primary',
  to: undefined,
  underline: false,
};

const fontSize = ({ fz }) => (fz ? `${fz}rem` : 'inherit');

const Link = ({ children, bs, className, disabled, fz, palette, to, underline, ...props }) => {
  if (to) {
    return (
      <NavLink className={className} to={to} {...props}>
        {children}
      </NavLink>
    );
  }
  return (
    <a as="button" className={className} {...props}>
      {children}
    </a>
  );
};

const disableStyle = css`
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
  pointer-events: none;
`;

const styles = css`
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  font-size: ${fontSize};
  color: ${toolPalette('primary', 0)};
  cursor: pointer;
  &:hover {
    text-decoration: ${({ underline }) => (!underline ? 'underline' : 'none')};
  }
  ${ifProp(
    'bs',
    css`
      position: relative;
      text-decoration: none;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0, 0625rem;
        border-bottom: 1px ${prop('bs')} ${toolPalette('primary', 0)};
      }
      &:hover:after {
        width: 0;
      }
    `,
  )};
  ${ifProp('disabled', disableStyle)};
`;

const StyledLink = styled(Link).attrs({
  palette: ({ palette }) => palette || 'primary',
  disabled: ({ disabled }) => disabled || false,
  underline: ({ underline }) => underline || false,
})`
  ${styles};
`;

Link.propTypes = propTypes;
Link.defaultProps = defaultProps;

export default StyledLink;
