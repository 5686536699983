import styled from 'styled-components';
import media from 'styled-media-query';

const Title = styled.h1`
  margin-bottom: 10px;
  text-align: center;
  font-size: 2.75rem;
  line-height: 125%;
  font-weight: 300;
  letter-spacing: 0.9px;

  ${media.lessThan('medium')`
    font-size: 2rem;
    line-height: 125%;
  `};
`;

export default Title;
