import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { isEmpty } from 'ramda';

import { CheckList } from 'components/shared';
import { Title } from '../shared';

const propTypes = {
  collection: PropTypes.arrayOf(PropTypes.object),
};

const defaultProps = {
  collection: [],
};

function Conditions({ collection }) {
  if (isEmpty(collection)) {
    return (
      <Container>
        <Title>Conditions</Title>
        <Em>No Conditions</Em>
      </Container>
    );
  }

  return (
    <Container>
      <Title>
        Conditions
        <Description>Please contact your Project Coordinator to discuss Conditions</Description>
      </Title>
      <CheckList>
        {collection.map(({ id, isCompleted, description }) => (
          <CheckList.Item key={id} checked={isCompleted}>
            {description}
          </CheckList.Item>
        ))}
      </CheckList>
    </Container>
  );
}

const Container = styled.div`
  padding: 2.1875rem 5.1875rem 2.1875rem 0;

  @media (max-width: ${prop('theme.breakpoints.1')}) {
    padding: 2.1875rem 0;
  }
`;

const Em = styled.em`
  font-style: italic;
`;

const Description = styled.p`
  display: block;
  font-size: 12px;
  margin-top: 10px;
`;

Conditions.propTypes = propTypes;
Conditions.defaultProps = defaultProps;

export default Conditions;
