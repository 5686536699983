import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { Field, useField } from 'react-final-form';

import { getBridgeSecondStepPropertyRefs } from 'selectors/refs';

import {
  AddressFormGroupAdapter,
  Condition,
  FormBox,
  FormGeocoderProvider,
  FormGroupAdapter,
  NarrowFormFlex,
} from 'components/shared/form';
import { AddressFields } from 'components/blocks/LeadForm/shared';
import { utils } from 'components/shared';

import { PROPERTY_COMPLETED_REPAIRS_VALUE_CONDITION, STEP_2_UNITS_COUNT_CONDITION } from 'constants/forms';
import { validateRequired } from 'utils';

const propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  refs: PropTypes.shape({
    actualTypesCollection: PropTypes.arrayOf(PropTypes.object),
  }),
};

const defaultProps = {
  refs: {
    actualTypesCollection: [],
  },
};

function Property({ name, refs, index }) {
  const propertyTypeField = useField(`${name}.type`);

  return (
    <Fragment>
      <FormGeocoderProvider namespace={name}>
        <NarrowFormFlex>
          <FormBox width={1}>
            <Field
              name={`${name}.address`}
              required
              label="Address"
              validate={validateRequired()}
              component={AddressFormGroupAdapter}
            />
          </FormBox>
        </NarrowFormFlex>
        <AddressFields />
      </FormGeocoderProvider>

      <NarrowFormFlex>
        <FormBox width={1}>
          <Field
            name={`${name}.type`}
            type="select"
            options={refs.actualTypesCollection}
            required
            label="Property Type"
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </NarrowFormFlex>

      <Condition when={`${name}.type`} is={STEP_2_UNITS_COUNT_CONDITION}>
        <NarrowFormFlex>
          <FormBox width={1}>
            <Field
              name={`${name}.unitsCount`}
              required={utils.isUnitsCountRequired(propertyTypeField.input.value)}
              label="# of Units"
              typeOfMask="number"
              validate={(value, allValues) => utils.unitsCountValidate(value, allValues, index)}
              component={FormGroupAdapter}
            />
          </FormBox>
        </NarrowFormFlex>
      </Condition>

      <NarrowFormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name={`${name}.purchasePrice`}
            required
            label="Purchase Price"
            typeOfMask="currency"
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>

        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name={`${name}.repairValue`}
            required
            label="Repair Cost"
            typeOfMask="currency"
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </NarrowFormFlex>

      <NarrowFormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name={`${name}.afterRepairValue`}
            label="Market Value After Repairs (ARV)"
            typeOfMask="currency"
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <Condition when="application.transactionPurpose" is={PROPERTY_COMPLETED_REPAIRS_VALUE_CONDITION}>
          <FormBox width={[1, 1, 1 / 2]}>
            <Field
              name={`${name}.completedRepairsValue`}
              label="Repairs Completed"
              typeOfMask="currency"
              required
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </Condition>
      </NarrowFormFlex>
    </Fragment>
  );
}

Property.propTypes = propTypes;
Property.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    refs: getBridgeSecondStepPropertyRefs(state),
  };
}

const enhance = compose(
  connect(mapStateToProps),
  pure,
);

export default enhance(Property);
