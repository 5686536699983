import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import DocContainer from 'components/blocks/Documents/DocFiles';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.node,
};

const defaultProps = {
  children: undefined,
  className: undefined,
  title: undefined,
};

function DocPanel({ className, children, title }) {
  return (
    <StyledContainer className={className}>
      {title && <Title>{title}</Title>}
      {children}
    </StyledContainer>
  );
}

const Title = styled.h2`
  margin-top: 0;
  margin-bottom: 0.75rem;
  padding-bottom: 0.5625rem;
  font-size: 1.125rem;
  font-weight: normal;
`;

const StyledContainer = styled.div`
  box-shadow: 0 0.125rem 0.4375rem rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  padding: 1rem 2rem;

  & ${DocContainer} {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  & ${Title} + ${DocContainer}} {
    border-top: 0.0625rem solid #dadbdb;
    padding-top: 0.875rem;
  }
`;

DocPanel.propTypes = propTypes;
DocPanel.defaultProps = defaultProps;

export default DocPanel;
