import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure, withHandlers, withState } from 'recompose';

import PanelRightPortal from '../components/shared/PanelRightPortal';

function WithPanelRightPortal(Component) {
  const propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    open: PropTypes.bool.isRequired,
    text: PropTypes.string,
    to: PropTypes.string,
    togglePanel: PropTypes.func.isRequired,
  };

  const defaultProps = {
    children: undefined,
    className: undefined,
    text: undefined,
    to: undefined,
  };
  function Action({ className, children, open, text, togglePanel, ...props }) {
    const extendedChildren = Children.map(children, (child) => cloneElement(child, { handleTogglePanel: togglePanel }));

    return (
      <Container className={className}>
        <Component onClick={togglePanel} {...props}>
          {text}
        </Component>
        <PanelRightPortal show={open} onHide={togglePanel} key="documentsChecklistPanel">
          {extendedChildren}
        </PanelRightPortal>
      </Container>
    );
  }

  const Container = styled.div`
    display: inline-block;
  `;

  Action.propTypes = propTypes;
  Action.defaultProps = defaultProps;

  const enhance = compose(
    withState('open', 'setOpen', false),
    withHandlers({
      togglePanel: ({ open, setOpen }) => () => {
        setOpen(!open);
      },
    }),
    pure,
  );

  return enhance(Action);
}

export default WithPanelRightPortal;
