import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { isNil, prop } from 'ramda';

import { getDocumentCategoryById } from 'selectors/documentCategories';

const propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  metaDescription: PropTypes.string,
};

const defaultProps = {
  className: undefined,
  description: undefined,
  metaDescription: undefined,
};

function Description({ className, description, metaDescription }) {
  if (isNil(description) && isNil(metaDescription)) return null;

  return (
    <Container className={className}>
      {description && <div>{description}</div>}
      {metaDescription && <div>{metaDescription}</div>}
    </Container>
  );
}

const Container = styled.div``;

Description.propTypes = propTypes;
Description.defaultProps = defaultProps;

function mapStateToProps(state, { categoryId }) {
  return {
    category: getDocumentCategoryById(state, categoryId),
  };
}

const enhance = compose(
  connect(mapStateToProps),
  withProps(({ category }) => ({
    description: prop('description', category),
    metaDescription: prop('metaDescription', category),
  })),
  pure,
);

export default enhance(Description);
