import React from 'react';
import PropTypes from 'prop-types';
import { equals } from 'ramda';

import AppraisalPaymentToDo from './components/AppraisalPaymentToDo';
import DrawRequestToDo from './components/DrawRequestToDo';
import InsuranceCompanyToDo from './components/InsuranceCompanyToDo';
import MobileClosingToDo from './components/MobileClosingToDo';
import RealEstateExperienceToDo from './components/RealEstateExperienceToDo';
import TitleCompanyToDo from './components/TitleCompanyToDo';

const propTypes = {
  toDoKey: PropTypes.string.isRequired,
};

const isAppraisalPayment = equals('appraisal_payment');
const isDrawRequest = equals('draw_request');
const isInsuranceCompany = equals('insurance_company');
const isMobileClosing = equals('mobile_closing');
const isRealEstateExperience = equals('real_estate_experience');
const isTitleCompany = equals('title_company');

function ToDo({ toDoKey, ...props }) {
  if (isAppraisalPayment(toDoKey)) {
    return <AppraisalPaymentToDo {...props} />;
  }

  if (isDrawRequest(toDoKey)) {
    return <DrawRequestToDo {...props} />;
  }

  if (isInsuranceCompany(toDoKey)) {
    return <InsuranceCompanyToDo {...props} />;
  }

  if (isMobileClosing(toDoKey)) {
    return <MobileClosingToDo {...props} />;
  }

  if (isRealEstateExperience(toDoKey)) {
    return <RealEstateExperienceToDo {...props} />;
  }

  if (isTitleCompany(toDoKey)) {
    return <TitleCompanyToDo {...props} />;
  }

  return null;
}

ToDo.propTypes = propTypes;

export default ToDo;
