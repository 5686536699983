function decorateEntityHandler({ rejectEntity, entities, form }) {
  const { change } = form;
  return (id) => {
    const entity = entities.find((e) => e.id === id);
    if (!entity) {
      rejectEntity();
      return;
    }
    change('application.entity', entity);
  };
}

function rejectEntityHandler({ form }) {
  const { change } = form;
  return () => change('application.entity', { id: null });
}

export { decorateEntityHandler as decorateEntity, rejectEntityHandler as rejectEntity };
