import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withContext, withHandlers, withProps, withState } from 'recompose';
import * as R from 'ramda';

import { getAddressComponents, getIn } from 'utils';

import hoc from './hoc';

const propTypes = {
  children: PropTypes.node.isRequired,
};

function Provider({ children }) {
  return children;
}

Provider.propTypes = propTypes;

const enhance = compose(
  hoc,
  withState('isGoogleAvailable', 'setIsGoogleAvailabel', false),
  withState('areComponentsRendered', 'setAreComponentsRendered', false),
  withState('hasSubmitErrors', 'setHasSubmitErrors', false),
  withProps(({ namespace, form }) => ({
    namespace: namespace || null,
    components: getAddressComponents(namespace),
    batch: form.batch,
    change: form.change,
  })),
  withHandlers({
    setComponent: ({ change }) => ({ value = null, name }) => {
      change(name, value);
    },
    unsetComponent: ({ change }) => ({ name }) => {
      change(name, null);
    },
    checkSubmitErrors: ({ setHasSubmitErrors, submitErrors, components }) => () => {
      if (!submitErrors) {
        setHasSubmitErrors(false);
        return;
      }

      const length = R.filter(
        R.pipe(
          getIn('name'),
          getIn(R.__, submitErrors),
          R.isNil,
          R.not,
        ),
        components,
      );
      if (length === 0) {
        setHasSubmitErrors(false);
        return;
      }
      setHasSubmitErrors(true);
    },
    checkGoogleAvailability: ({ setAreComponentsRendered, setIsGoogleAvailabel }) => (google) => {
      if (!google) {
        setAreComponentsRendered(true);
        setIsGoogleAvailabel(false);
        return;
      }
      if (!google.maps) {
        setAreComponentsRendered(true);
        setIsGoogleAvailabel(false);
        return;
      }

      const geocoder = new google.maps.Geocoder();
      const isStatusOk = R.equals(google.maps.GeocoderStatus.OK);
      geocoder.geocode({ address: '1600 Amphitheatre Parkway, Mountain View' }, (results, status) => {
        setIsGoogleAvailabel(isStatusOk(status));
      });
    },
  }),
  withHandlers({
    setAddressComponents: ({ batch, setComponent }) => (args) => {
      batch(() => R.forEach(setComponent, args));
    },
    unsetAddressComponents: ({ unsetComponent, batch }) => (args) => {
      batch(() => R.forEach(unsetComponent, args));
    },
    submitErrorsListener: ({ submitErrors, checkSubmitErrors }) => (prev) => {
      if (R.equals(submitErrors, prev.submitErrors)) return;
      checkSubmitErrors();
    },
    renderListener: ({ isGoogleAvailable, setAreComponentsRendered, hasSubmitErrors }) => (prev) => {
      function gard() {
        if (!R.equals(hasSubmitErrors, prev.hasSubmitErrors)) return false;
        if (!R.equals(isGoogleAvailable, prev.isGoogleAvailable)) return false;
        return true;
      }

      if (gard()) return;

      if (!isGoogleAvailable) {
        setAreComponentsRendered(true);
        return;
      }
      if (hasSubmitErrors) {
        setAreComponentsRendered(true);
        return;
      }
      setAreComponentsRendered(false);
    },
  }),
  lifecycle({
    UNSAFE_componentWillMount() {
      const { checkGoogleAvailability, checkSubmitErrors } = this.props;
      checkGoogleAvailability(window.google);
      checkSubmitErrors();
    },
    componentDidUpdate(prev) {
      const { renderListener, submitErrorsListener } = this.props;
      submitErrorsListener(prev);
      renderListener(prev);
    },
  }),
  withContext(
    {
      areComponentsRendered: PropTypes.bool,
      setAreComponentsRendered: PropTypes.func,
      components: PropTypes.arrayOf(PropTypes.object),
      isGoogleAvailable: PropTypes.bool,
      namespace: PropTypes.string,
      setAddressComponents: PropTypes.func,
      unsetAddressComponents: PropTypes.func,
    },
    ({ areComponentsRendered, setAreComponentsRendered, isGoogleAvailable, namespace, setAddressComponents, unsetAddressComponents }) => {
      const components = getAddressComponents(namespace);

      return {
        areComponentsRendered,
        setAreComponentsRendered,
        components,
        isGoogleAvailable,
        namespace,
        setAddressComponents,
        unsetAddressComponents,
      };
    },
  ),
  pure,
);

export default enhance(Provider);
