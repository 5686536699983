import { FORM_ERROR } from 'final-form';
import moment from 'moment';
import objectToFormData from 'object-to-formdata';
import * as R from 'ramda';

import { errorNotification } from "utils/notifications";
import { ADDRESS_COMPONENTS } from 'constants/forms';
import { decamelizeKeys, camelizeKeys } from 'utils/humps';
import {
  currencyMask,
  decimalCurrencyMask,
  decimalNumberMask,
  numberMask,
  percentMask,
  decimalPercentMask,
  phoneMask,
  dateMask,
  ssnMask,
  cvvMask,
  phoneExtensionMask,
} from 'utils/masks';
import { getIn } from 'utils/ramda';

export function throwSubmissionError(response) {
  if (response.status === 500) {
    return undefined;
  }
  const { message, source } = getIn('errors', response) || {};
  let errors = {};

  // FIXME Source should not be a string
  if (message && typeof source !== 'string') {
    errors = { ...errors, [FORM_ERROR]: message };
  }

  if (source && typeof source === 'string') {
    errors = { ...errors, [FORM_ERROR]: source };
    // errorNotification(source);
  }

  if (source && typeof source !== 'string') {
    const camelizedSource = camelizeKeys(source);
    errors = { ...errors, ...camelizedSource };
  }

  if (source && source["flash"]) {
    errorNotification(source["flash"])
  }

  return errors;
}

export const getName = (nameSpace, name) => (nameSpace ? `${nameSpace}.${name}` : name);

export const yearsRangeCollection = (length, start = new Date()) => {
  const direction = length > 0;
  const date = Number(moment(start).format('YYYY'));
  return R.range(0, Math.abs(length)).map((i) => {
    const index = direction ? i : 0 - i;
    const newDate = date + index;
    return { value: newDate, label: newDate };
  });
};

export const monthCollection = R.range(0, 12).map((index) => ({ value: index + 1, label: index + 1 }));

export const percentParse = (v) => (parseFloat(v) >= 100 ? '100%' : v);
export const upperLettersOnlyParse = R.pipe(
  R.toUpper,
  R.replace(/((?![A-Z]).)/g, ''),
);

export const getMask = (mask, typeOfMask) => {
  if (mask) {
    if (typeof mask === 'string') {
      const arrMask = mask.split('').map((char) => {
        if (char === '9') return /\d/;
        if (char === 'a') return /[a-zA-Z]/;
        return char;
      });
      return arrMask;
    }
    return mask;
  }

  switch (typeOfMask) {
    case 'currency':
      return currencyMask;
    case 'decimalCurrency':
      return decimalCurrencyMask;
    case 'decimalNumber':
      return decimalNumberMask;
    case 'number':
      return numberMask;
    case 'percent':
      return percentMask;
    case 'decimalPercent':
      return decimalPercentMask;
    case 'phone':
      return phoneMask;
    case 'phoneExtension':
      return phoneExtensionMask;
    case 'date':
      return dateMask;
    case 'ssn':
      return ssnMask;
    case 'cvv':
      return cvvMask;
    default:
      return undefined;
  }
};

export const getPlaceholder = (placeholder, typeOfMask) => {
  if (placeholder) return placeholder;
  switch (typeOfMask) {
    case 'phone':
      return '(234) 999-9999';
    case 'currency':
      return '$';
    case 'decimalCurrency':
      return '$';
    case 'percent':
      return '0%';
    case 'date':
      return '__/__/____';
    case 'ssn':
      return '__-__-____';

    default:
      return undefined;
  }
};

export const getAddressComponent = (nameSpace, component) => {
  if (!nameSpace) return component;
  return {
    ...component,
    name: getName(nameSpace, component.name),
  };
};

export const getAddressComponents = (nameSpace, components = ADDRESS_COMPONENTS) =>
  components.map((component) => getAddressComponent(nameSpace, component));

export const getAddressComponentsWithValue = (components) => (input) =>
  R.map((component) => {
    const value = R.pipe(
      getIn('key'),
      getIn(R.__, input),
      R.or(R.__, undefined),
    )(component);
    return { value, ...component };
  }, components);

export const properLengthPred = R.pipe(
  R.length,
  R.gte(R.__, 8),
);
export const hasCapitalLetterPred = R.test(/[A-Z]+/);
export const hasLowerCaseLetterPred = R.test(/[a-z]+/);
export const hasSpecialCharacterPred = R.test(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+/);
export const hasDigitPred = R.test(/[0-9]+/);
export const passwordsMustBeTheSamePred = R.equals;

export const decamelizeObjectToFormData = (obj) => objectToFormData(decamelizeKeys(obj));

export const downloadResponseFile = (fileName, response) => {
  response.blob().then((blob) => {
    const fileUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = fileUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  });
};

export function preparedGeoCodedResults(result) {
  if (!result) return undefined;
  const res = {
    address: result.formattedAddress,
    latitude: `${result.geometry.location.lat()}`,
    longitude: `${result.geometry.location.lng()}`,
  };
  R.forEach((component) => {
    R.forEach((type) => {
      if (type === 'route') {
        res.streetName = component.longName;
      }
      if (type === 'street_number') {
        res.streetNumber = component.longName;
      }
      if (type === 'locality' || type === 'sublocality_level_1' || type === 'neighborhood') {
        res.municipality = component.longName;
      }
      if (type === 'administrative_area_level_2') {
        res.county = component.longName;
      }
      if (type === 'administrative_area_level_1') {
        res.stateName = component.longName || ' ';
      }
      if (type === 'administrative_area_level_1') {
        res.stateCode = component.shortName;
      }
      if (type === 'country') {
        res.countryName = component.longName;
      }
      if (type === 'country') {
        res.countryCode = component.shortName;
      }
      if (type === 'premise' || type === 'subpremise') {
        res.premise = component.longName;
      }
      if (type === 'postal_code') {
        res.postalCode = component.longName;
      }
    }, component.types);
  }, result.addressComponents);
  return res;
}

export function conditionalPredicat(when, is, reverse = false) {
  return (value) => {
    let check;

    if (R.is(Array, is)) {
      check = R.any((item) => value.toString() === item.toString(), is);
    } else {
      check = is.toString() === value.toString();
    }

    if (reverse) check = !check;

    return check;
  };
}

export function getOptionLabelByValue(collection, value) {
  const label = R.pipe(
    R.find(R.propEq('value', value)),
    R.prop('label'),
  )(collection);

  return label;
}

export function resolveServerImportErrors(errors) {
  const lengthEq = R.curry((eqTo, arr) => R.equals(eqTo, R.length(arr)));

  if (R.is(String, errors)) {
    return R.compose(
      R.when(lengthEq(1), R.head),
      R.split(';'),
    )(errors);
  }

  if (R.is(Object, errors)) {
    return R.map(resolveServerImportErrors, errors);
  }

  return errors;
}
