import { compose, lifecycle, pure, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { getApplicationProperties } from 'selectors';

import { FormGroupAdapter } from 'components/shared/form';

import { WithFinalForm } from 'hocs';

import * as handlers from './handlers';

function mapStateToProps(state) {
  const properties = getApplicationProperties(state);

  return {
    properties,
  };
}

const enhance = compose(
  WithFinalForm,
  connect(mapStateToProps),
  withHandlers({
    setDefaultPropertyId: handlers.setDefaultPropertyId,
  }),
  lifecycle({
    componentDidUpdate() {
      const { setDefaultPropertyId } = this.props;
      setDefaultPropertyId();
    },
  }),
  pure,
);

export default enhance(FormGroupAdapter);
