import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ifProp, palette } from 'styled-tools';

const propTypes = {
  block: PropTypes.bool,
  palette: PropTypes.string,
  wrapped: PropTypes.bool,
};

const defaultProps = {
  block: true,
  palette: 'grayscale',
  wrapped: true,
};

const PreformattedText = styled.pre`
  display: ${ifProp('block', 'block', 'inline')};
  color: ${palette('grayscale', 1)};
  background-color: ${palette('grayscale', -1)};
  padding: ${ifProp('block', '1em', '0 0.5em')};
  white-space: ${ifProp('wrapped', 'pre-wrap', 'nowrap')};
  overflow: auto;
  line-height: 150%;
`;

PreformattedText.propTypes = propTypes;
PreformattedText.defaultProps = defaultProps;
export default PreformattedText;
