import React from 'react';
import PropTypes from 'prop-types';

import { RadioGroup } from 'components/shared/form';

const propTypes = {
  input: PropTypes.objectOf(PropTypes.any).isRequired,
  meta: PropTypes.objectOf(PropTypes.any).isRequired,
};

function FormRadioAdapter({ input, meta: { error, touched, submitError }, ...rest }) {
  return (
    <RadioGroup error={error || submitError} invalid={!!((error && touched) || submitError)} {...input} {...rest} />
  );
}

FormRadioAdapter.propTypes = propTypes;

export default FormRadioAdapter;
