import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { compose, pure } from 'recompose';
import { ifProp, palette } from 'styled-tools';
import { equals } from 'ramda';

import Icon from 'components/shared/Icon';

import { styleUtils } from 'utils';

import nodeProps from './shared/hocs/nodeProps';

const propTypes = {
  body: PropTypes.node,
  checkedCount: PropTypes.number,
  children: PropTypes.node,
  count: PropTypes.number,
  expanded: PropTypes.bool,
  showIcon: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  status: PropTypes.string,
  sub: PropTypes.bool.isRequired,
  title: PropTypes.node.isRequired,
  toggle: PropTypes.func.isRequired,
  withCounter: PropTypes.bool,
  withLineThrough: PropTypes.bool,
  renderIcon: PropTypes.func,
  isAnotherDocs: PropTypes.bool,
};

const defaultProps = {
  body: undefined,
  checkedCount: 0,
  children: undefined,
  count: 0,
  showIcon: true,
  size: 'default',
  status: 'pending',
  withCounter: false,
  withLineThrough: false,
  renderIcon: undefined,
  expanded: false,
  isAnotherDocs: false,
};

function getCheckedIcon({ status }) {
  if (equals('success', status)) return 'checkboxActive';
  if (equals('danger', status)) return 'checkboxCross';
  return 'checkbox';
}

function getCheckedPalette({ status }) {
  switch (status) {
    case 'pending':
      return 'grayscale';
    case 'danger':
      return 'danger';
    case 'warning':
      return 'alert';
    case 'success':
      return 'success';
    default:
      return 'grayscale';
  }
}

function getCheckedTone({ status }) {
  if (equals('pending', status)) {
    return 3;
  }

  return 1;
}

function DocumentsTreeNode({
  body,
  checkedCount,
  children,
  count,
  expanded,
  showIcon,
  size,
  status,
  sub,
  title,
  toggle,
  withCounter,
  withLineThrough,
  renderIcon,
  isAnotherDocs,
}) {
  return (
    <NodeTree sub={sub} expanded={expanded} size={size}>
      <Title size={size}>
        {showIcon && renderIcon && renderIcon({ status, size })}
        {showIcon && !renderIcon && <CheckIcon status={status} size={size} />}
        <TitleText lineThrough={withLineThrough && equals('success', status)} size={size}>
          {title}
          {withCounter && sub && (
            <Counter>
              {' '}
              {checkedCount}/{count}
            </Counter>
          )}
        </TitleText>
        {sub && !isAnotherDocs && (
          <Swither size={size}>
            <SwitcherIcon size={size} />
            <SwithcherHandle onClick={toggle} />
          </Swither>
        )}
      </Title>
      {body && <Body size={size}>{body}</Body>}
      {sub && (
        <SubTree expanded={expanded} size={size}>
          {children}
        </SubTree>
      )}
    </NodeTree>
  );
}

const lineCommonStile = css`
  content: '';
  position: absolute;
  z-index: -1;
  background: ${palette('grayscale', 4)};
`;

const verticalLineCommonStile = css`
  ${lineCommonStile};
  width: ${styleUtils.getSizeValue('0.0625')};
`;

const horizontalLineCommonStile = css`
  ${lineCommonStile};
  height: ${styleUtils.getSizeValue('0.0625')};
`;

const CheckIcon = styled(Icon).attrs({
  icon: getCheckedIcon,
  palette: getCheckedPalette,
  tone: getCheckedTone,
})`
  position: relative;
  display: block;
  background: ${palette('white', 0)};
  border-radius: 50%;
  flex-shrink: 0;
  margin: 0;
  font-size: ${styleUtils.getSizeValue([1.375, 1.375, 1.375, 1.375, 1.375])};
  color: ${palette()};
`;

const SwitcherIcon = styled(Icon).attrs({
  icon: ({ icon }) => icon || 'angle',
})`
  display: block;
  flex-shrink: 0;
  font-size: ${styleUtils.getSizeValue([0.49125, 0.49125, 0.6875, 0.6875, 0.6875])};
  color: ${palette('grayscale', 3)};
`;

const SwithcherHandle = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: visible;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: 0;
  background: transparent;
  border: none;
  cursor: pointer;
`;

const Swither = styled.div`
  overflow: visible;
  margin: 0;
  margin-left: ${styleUtils.getSizeValue([0.625, 0.625, 0.625, 0.625, 0.625])};
  padding: 0;
  width: auto;
  outline: 0;
`;

const Title = styled.span`
  position: relative;
  display: flex;
  align-items: center;
`;

const TitleText = styled.div`
  font-size: ${styleUtils.getSizeValue([1, 1, 1.4375, 1.4375, 1.4375])};
  font-weight: bold;
  margin-left: ${styleUtils.getSizeValue([0.625, 0.625, 0.625, 0.625, 0.625])};
  text-decoration: ${ifProp('lineThrough', 'line-through')};
  & * {
    text-decoration: ${ifProp('lineThrough', 'line-through')};
  }
`;

const Body = styled.div`
  margin-left: ${styleUtils.getSizeValue([2, 2, 2, 2, 2])};
  font-size: ${styleUtils.getSizeValue([0.875, 0.875, 0.875, 0.875, 0.875])};
`;

const Counter = styled.span`
  color: ${palette('grayscale', 3)};
  font-weight: normal;
`;

const SubTree = styled.ul`
  list-style: none;
  display: ${ifProp('expanded', 'block', 'none')}
  padding-left: ${styleUtils.getSizeValue([2, 2, 2, 2, 2])};

  & & {
    &::before {
      ${verticalLineCommonStile};
      top: 0;
      left: -${styleUtils.getSizeValue([1.35, 1.35, 1.35, 1.35, 1.35])};
      height: 100%;
    }
  }
`;

const NodeTree = styled.li`
  position: relative;
  display: block;
  padding-bottom: ${styleUtils.getSizeValue(2.1875)};

  ${ifProp(
    'sub',
    css`
      & & {
        & > ${Title} {
          &::after {
            ${verticalLineCommonStile};
            left: ${styleUtils.getSizeValue([0.65, 0.65, 0.65, 0.65, 0.65])};
            bottom: 0;
            height: 50%;
          }
        }
      }
    `,
  )}

  &:last-child {
    margin-bottom: 0;

    &::before {
      display: none;
    }

    ${SubTree} {
      li:last-child {
        &::after {
          display: none;
        }
      }

      li:last-child > ${Title} {
        &::before {
          height: 50%;
        }
      }

      li:last-child > ${Body} {
        &::before {
          display: none;
        }
      }

      & & {
        &:before {
          display: none;
        }
      }
    }
  }

  &:last-child > ${Title} {
    &::before {
      height: ${ifProp('expanded', '100%', '50%')};
    }
  }

  &:only-child > ${Title} {
    &::before {
      display: ${ifProp('expanded', 'block', 'none')};
    }
  }

  &:last-child > ${Body} {
    &::before {
      display: ${ifProp('expanded', 'block', 'none')};
    }
  }

  &:first-child > ${Title} {
    &::before {
      top: auto;
      bottom: 0;
      height: 50%;
    }
  }

  &::before {
    ${verticalLineCommonStile};
    bottom: 0;
    left: ${styleUtils.getSizeValue([0.65, 0.65, 0.65, 0.65, 0.65])};
    height: ${styleUtils.getSizeValue(2.1875)};
  }

  ${Title} {
    position: relative;

    &::before {
      ${verticalLineCommonStile};
      top: 0;
      left: ${styleUtils.getSizeValue([0.65, 0.65, 0.65, 0.65, 0.65])};
      height: 100%;
    }
  }

  ${Body} {
    position: relative;

    &::before {
      ${verticalLineCommonStile};
      top: 0;
      left: -${styleUtils.getSizeValue([1.35, 1.35, 1.35, 1.35, 1.35])};
      height: 100%;
    }
  }

  ${SwitcherIcon} {
    transform: ${ifProp('expanded', null, 'rotate(180deg)')};
    transition: transform 0.15s ease-in-out;
  }

  ${SubTree} &:first-child {
    ${Title} {
      &::before {
        top: 0;
        bottom: auto;
        height: 100%;
      }
    }
  }

  ${SubTree} &:last-child {
    &::before {
      display: block;
    }

    ${Body} {
      &::before {
        display: block;
      }
    }
  }

  ${SubTree} ${SubTree} &:last-child {
    &::after {
      display: none;
    }

    ${Title} {
      &::before {
        height: ${ifProp('sub', '100%', '50%')};
      }
    }
  }

  ${SubTree} & {
    padding-top: ${styleUtils.getSizeValue(0.875)};
    padding-bottom: ${styleUtils.getSizeValue(0.9375)};

    &::before {
      ${verticalLineCommonStile};
      top: 0;
      left: -${styleUtils.getSizeValue([1.35, 1.35, 1.35, 1.35, 1.35])};
      height: ${styleUtils.getSizeValue(0.875)};
    }

    &::after {
      ${verticalLineCommonStile};
      bottom: 0;
      left: -${styleUtils.getSizeValue([1.35, 1.35, 1.35, 1.35, 1.35])};
      height: ${styleUtils.getSizeValue(0.9375)};
    }

    ${CheckIcon} {
      &::before {
        ${horizontalLineCommonStile};
        top: 50%;
        left: -${styleUtils.getSizeValue([1.3125, 1.3125, 1.3125, 1.3125, 1.3125])};
        width: ${styleUtils.getSizeValue([2, 2, 2, 2, 2])};
      }
    }

    ${Title} {
      &::before {
        top: 0;
        left: -${styleUtils.getSizeValue([1.35, 1.35, 1.35, 1.35, 1.35])};
        display: block;
        height: 100%;
      }
    }

    ${Body} {
      &::before {
        left: -${styleUtils.getSizeValue([3.35, 3.35, 3.35, 3.35, 3.35])};
      }
    }

    ${TitleText} {
      margin-left: ${styleUtils.getSizeValue([0.4375, 0.4375, 0.4375, 0.4375, 0.4375])};
      font-size: ${styleUtils.getSizeValue('1')};
      font-weight: normal;
      color: ${palette('primary')};
    }
  }
`;

DocumentsTreeNode.propTypes = propTypes;
DocumentsTreeNode.defaultProps = defaultProps;

const enhance = compose(
  nodeProps,
  pure,
);

export default enhance(DocumentsTreeNode);
