import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';

import { getApplicationType } from 'selectors';

import Wrapper from 'components/shared/Wrapper';
import { UserHeader } from 'components/blocks/User';
import { UserTemplate } from 'components/templates';

import { ScrollToTopOnMount } from 'hocs';

import Bridge from './Bridge';
import PreApproval from './PreApproval';
import Rental from './Rental';

import { Menu } from './shared';
import { ApplicationHeader } from '../shared';

const propTypes = {
  applicationType: PropTypes.string,
};

const defaultProps = {
  applicationType: undefined,
};

function ApplicationStepsPage({ applicationType }) {
  return (
    <UserTemplate header={<UserHeader />}>
      <ApplicationHeader />
      <Main>
        <MenuContainer>
          <Menu />
        </MenuContainer>
        <FormContainer>
          <StyledWrapper>
            {applicationType && applicationType === 'bridge' && <Bridge />}
            {applicationType && applicationType === 'rental' && <Rental />}
            {applicationType && applicationType === 'pre_approval' && <PreApproval />}
            {applicationType && applicationType === 'credit' && <PreApproval />}
          </StyledWrapper>
        </FormContainer>
      </Main>
    </UserTemplate>
  );
}

const Main = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;

const MenuContainer = styled.div`
  box-sizing: border-box;
  padding-top: 2.5rem;
  padding-left: 1.25rem;
  width: 15rem;
  border-right: 0.0625rem solid #d7dde9;
`;

const FormContainer = styled.div`
  padding-top: 2.5rem;
  flex: 1;
`;

const StyledWrapper = styled(Wrapper)`
  padding: 1.875rem 2.5rem;
  text-align: left;
  width: 100%;
`;

ApplicationStepsPage.propTypes = propTypes;
ApplicationStepsPage.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  const applicationType = getApplicationType(state);
  return { applicationType };
};

const enhance = compose(
  connect(mapStateToProps),
  ScrollToTopOnMount,
  pure,
);

export default enhance(ApplicationStepsPage);
