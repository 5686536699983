import * as R from 'ramda';

import { setInDefaultValue, setInDefaultNotEmptyValue } from 'decorators';
import { getIn, setIn } from 'utils';

function setDefultPropertyValues(input = {}) {
  return R.pipe(
    setInDefaultValue(false, 'repairsCompleted'),
    setInDefaultValue('vacant', 'occupancy'),
    setInDefaultValue('sell', 'exitStrategy'),
    setInDefaultValue(0, 'completedRepairsValue'),
  )(input);
}

function setDefaultProperties(input = {}) {
  const properties = R.pipe(
    setInDefaultNotEmptyValue([{}], 'application.properties'),
    getIn('application.properties'),
    R.map(setDefultPropertyValues),
  )(input);

  return setIn(properties, 'application.properties', input);
}

function setDefaultInitialValues(input = {}) {
  return R.pipe(setDefaultProperties)(input);
}

export { setDefultPropertyValues, setDefaultInitialValues };
