import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withContext, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import createFocusDecorator from 'final-form-focus';
import * as R from 'ramda';

import * as actions from 'actions';

import { holdMutators, warnNotification } from 'utils';
import * as fp from 'utils/ramda';

import Form from './Form';
import { handlers, utils } from './shared';

const propTypes = {
  fetchInitialValues: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    application: PropTypes.shape({
      dataEntryStep: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      description: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      properties: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
      refinanceProceedsPurpose: PropTypes.string,
      requestedAmount: PropTypes.string,
      requiredClosingDate: PropTypes.string,
      token: PropTypes.string,
      transactionPurpose: PropTypes.string,
      type: PropTypes.string,
    }),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const focusOnError = createFocusDecorator();

function FullFormProjectOverview({ fetchInitialValues, initialValues, loading, onSubmit }) {
  return (
    <FinalForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{ ...holdMutators, ...arrayMutators }}
      subscription={{ values: false, submitting: true, pristine: true }}
      decorators={[focusOnError]}
      loading={loading}
      fetchInitialValues={fetchInitialValues}
      component={Form}
    />
  );
}

FullFormProjectOverview.propTypes = propTypes;

function mapDispatchToProps(dispatch, { setInitialValues }) {
  const fetchApplicationSecondStep = bindActionCreators(actions.fetchApplicationSecondStep, dispatch);
  const updateApplicationSecondStep = bindActionCreators(actions.updateApplicationSecondStep, dispatch);

  const onSubmit = (values) => {
    const propertiesPath = ['application', 'properties'];
    const visibleProperties = R.compose(
      R.reject(R.prop('_hidden')),
      R.pathOr([], propertiesPath),
    )(values);

    if (fp.isNotDefined(visibleProperties)) {
      warnNotification('You must add at least one property!');
      R.compose(
        setInitialValues,
        utils.setDefaultInitialValues,
        R.assocPath(propertiesPath, [{}]),
      )(values);
      return undefined;
    }

    return R.compose(
      updateApplicationSecondStep,
      R.tap(setInitialValues),
      R.assocPath(propertiesPath, visibleProperties),
    )(values);
  };

  return {
    onSubmit,
    fetchApplicationSecondStep,
  };
}

const enhance = compose(
  withRouter,
  withState('initialValues', 'setInitialValues', utils.setDefaultInitialValues()),
  connect(
    null,
    mapDispatchToProps,
  ),
  withState('loading', 'setLoading', false),
  withState('isFormDisabled', 'setIsFormDisabled', false),
  withState('isEditAddress', 'setIsEditAddress', true),
  withHandlers({
    isFormDisabledSetter: handlers.isFormDisabledSetter,
  }),
  withHandlers({
    isEditAddressSetter: handlers.isEditAddressSetter,
  }),
  withHandlers({
    mergeInitialValues: handlers.mergeInitialValues,
  }),
  withHandlers({
    fetchInitialValues: ({ match, mergeInitialValues, setLoading, fetchApplicationSecondStep }) => async () => {
      const { token } = match.params;
      setLoading(true);
      await fetchApplicationSecondStep(token).then(mergeInitialValues);
      setLoading(false);
    },
  }),
  lifecycle({
    async componentDidMount() {
      const { fetchInitialValues } = this.props;
      fetchInitialValues();
    },
  }),
  withContext(
    {
      isFormDisabled: PropTypes.bool,
      isEditAddress: PropTypes.bool,
    },
    ({ isFormDisabled, isEditAddress }) => ({ isFormDisabled, isEditAddress }),
  ),
  pure,
);

export default enhance(FullFormProjectOverview);
