import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';

import Icon from 'components/shared/Icon';

import { styleUtils } from 'utils';

import Item, { ItemBody } from './Item';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      checked: PropTypes.bool,
      children: PropTypes.node,
    }),
  ),
};

const defaultProps = {
  children: undefined,
  className: undefined,
  list: undefined,
};

function itemMaper(item, index) {
  return <Item isFirst={index === 1} {...item} />;
}

function CheckList({ children, list, className }) {
  if (children) {
    return <ul className={className}>{children}</ul>;
  }

  if (list) {
    return <ul className={className}>{list.map(itemMaper)}</ul>;
  }

  return <Empty>no items.</Empty>;
}

const StyledCheckList = styled(CheckList).attrs({
  size: ({ size }) => size || 'default',
})`
  padding: 0;
  margin: 0;
  list-style-type: none;
  font-size: ${styleUtils.getSizeValue(1)};
  ${space};

  ${Item} {
    display: flex;
  }

  ${Icon} {
    font-size: ${styleUtils.getSizeValue(2)};
  }

  ${ItemBody} {
    margin-left: ${styleUtils.getSizeValue(1)};
  }
`;

const Empty = styled.span``;

CheckList.propTypes = propTypes;
CheckList.defaultProps = defaultProps;

StyledCheckList.Item = Item;

export default StyledCheckList;
