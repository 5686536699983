import { createSelector } from 'reselect';

export const initialState = {};

const schedulesSelector = (state = initialState) => state.schedules;

export const getSchedules = createSelector(schedulesSelector, (schedules) => schedules);

export const getSchedulesCollection = createSelector([getSchedules], (schedules) => {
  return schedules;
});

const Group = (_, props) => props.group;
export const getSchedulesCollectionByGroup = createSelector([getSchedules, Group], (schedules, group) => {
  return schedules.properties && schedules.properties.filter((property) => property.group === group);
});

export const isAtLeastOneAvailableToDeleteByGroup = createSelector([getSchedules, Group], (schedules, group) => {
  return (
    schedules.properties &&
    schedules.properties.filter((property) => property.group === group && property.meta.availableToDestroy)
  );
});
