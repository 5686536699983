import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ifProp, palette } from 'styled-tools';

const propTypes = {
  right: PropTypes.bool,
  error: PropTypes.bool,
};

const defaultProps = {
  right: undefined,
  error: undefined,
};

function Hint({ children, ...props }) {
  return <StyledHint {...props}>{children}</StyledHint>;
}

const StyledHint = styled.div`
  position: relative;
  display: block;
  margin: 0;
  padding: 2px 20px 3px 6px;
  text-align: ${ifProp('right', 'right')};
  font-size: 0.75rem;
  color: ${ifProp('error', palette('danger', 0), '#6d6b69')};
`;

Hint.propTypes = propTypes;
Hint.defaultProps = defaultProps;
export default Hint;
