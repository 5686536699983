import React, { Fragment } from 'react';

import { FormFlex, FormBox, FormGroupLoader, FormLegendLoader, RadioGroupLoader } from 'components/shared/form';

function Loader() {
  return (
    <Fragment>
      <FormFlex>
        <FormBox width={1}>
          <FormGroupLoader labelWidth="10rem" />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <FormGroupLoader labelWidth="10rem" />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <FormGroupLoader labelWidth="8.75rem" inputHeight="4.5rem" />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <FormLegendLoader mb="1rem" width="5rem" />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <FormGroupLoader labelWidth="3.5rem" />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <FormGroupLoader labelWidth="6rem" />
        </FormBox>

        <FormBox width={[1, 1, 1 / 3]}>
          <FormGroupLoader labelWidth="10rem" />
        </FormBox>

        <FormBox width={[1, 1, 1 / 3]}>
          <FormGroupLoader labelWidth="4.375rem" />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <RadioGroupLoader inline labelWidth="44rem" />
        </FormBox>
      </FormFlex>
    </Fragment>
  );
}

export default Loader;
