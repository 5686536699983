import TagManager from 'react-gtm-module';
import at from 'lodash.at';
import * as R from 'ramda';

import { isProd } from 'config';
import { GTM_DATA_LAYER_NAME } from 'constants/others';
import { decamelizeKeys, throwSubmissionError } from 'utils';

const LOAN_TYPE = {
  rental: 'Rental Loan',
  bridge: 'Fix/Flip or Bridge Loan',
  pre_approval: 'Pre Approval',
};
const INSTANT_QUOTE_STEPS = {
  [undefined]: 'General Info',
  property: 'Property Info',
  borrower: 'Borrower Info',
};
const APPLICATION_STEPS = [
  'Investment Experience', 'Project Overview',
  'Personal Financials', 'Company Information', 'Signature',
];
const TRANSACTION_PURPOSES = {
  purchase: 'Purchase',
  refinance_cash_out: 'Refinance - Cash Out',
  refinance_no_cash_out: 'Refinance - No Cash Out',
};

const dataLayerPush = (dataLayer) => () => {
  if (!isProd) console.log('Tag Manager | New Event:', decamelizeKeys(dataLayer));

  const tagManagerArgs = { dataLayer: decamelizeKeys(dataLayer), dataLayerName: GTM_DATA_LAYER_NAME };
  if (isProd) TagManager.dataLayer(tagManagerArgs);
};

const formErrorEvent = ({ formName, applicationStep, registeredFields, error }) => {
  const filteredRegisteredFields = R.filter(
    (field) => R.pipe(
      R.reject(R.equals(field)),
      R.any(R.anyPass([
        R.includes(`${field}.`),
        R.includes(`${field}[`),
      ])),
      R.not,
    )(registeredFields),
    registeredFields,
  );

  const errorWithoutFlash = R.dissocPath(['errors', 'source', 'flash'], error);

  const errorIndexes = at(throwSubmissionError(errorWithoutFlash), filteredRegisteredFields)
    .reduce((acc, field, i) => ([...acc, ...(field ? [i] : [])]), []);

  const errorFields = filteredRegisteredFields.filter((_, i) => errorIndexes.includes(i));

  if (!errorFields.length) return () => { };

  return dataLayerPush({
    event: 'application_error',
    data: errorFields.map((field) => ({
      formName, applicationStep, errorFieldName: field,
    })),
  });
};

const instantQuoteStepEvent = ({ type, stepName, stepNumber }) => dataLayerPush({
  event: 'instant_quote_step',
  data: [{
    loanType: LOAN_TYPE[type],
    stepName,
    stepNumber,
  }],
});

export const createInstantQuoteEvent = ({ type }) => (dispatch) =>
  dispatch(instantQuoteStepEvent({ type, stepName: 'General Info', stepNumber: '1' }));

export const updateInstantQuoteEvent = ({ type, step }) => (dispatch) =>
  dispatch(instantQuoteStepEvent({
    type,
    stepName: step === 'property' ? 'Property Info' : 'Borrower Info',
    stepNumber: step === 'property' ? '2' : '3',
  }));
export const instantQuoteCompleteEvent = ({ type, status }) => dataLayerPush({
  event: 'instant_quote_complete',
  data: [{
    loanType: LOAN_TYPE[type],
    loanStatus: status,
  }],
});
export const instantQuoteErrorEvent = ({ step, type, form, error }) => formErrorEvent({
  formName: `Apply For ${LOAN_TYPE[type]} Loan`,
  applicationStep: INSTANT_QUOTE_STEPS[step],
  registeredFields: form.getRegisteredFields(),
  error,
});


export const applicationFlowEvent = ({ type, step }) => dataLayerPush({
  event: 'application_flow',
  data: [{
    loanType: LOAN_TYPE[type],
    stepName: APPLICATION_STEPS[step - 1],
    stepNumber: `${step}`,
  }],
});
export const appplicationCompleteEvent = ({ type, transactionPurpose }) => dataLayerPush({
  event: 'application_complete',
  data: [{
    loanType: LOAN_TYPE[type],
    loanStatus: 'Approved',
    loanVariant: TRANSACTION_PURPOSES[transactionPurpose],
  }],
});
export const applicationErrorEvent = ({ step, type, form, error }) => formErrorEvent({
  formName: `Update ${LOAN_TYPE[type]} Loan`,
  applicationStep: APPLICATION_STEPS[step - 1],
  registeredFields: form.getRegisteredFields(),
  error,
});


export default dataLayerPush;
