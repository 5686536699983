import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  right: PropTypes.bool,
};

const defaultProps = {
  className: null,
  right: false,
};

function MenuContainer({ children, className, right }) {
  return (
    <Container right={right} className={className}>
      {children}
    </Container>
  );
}

const Container = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: block;
  margin: -0.0625rem 0 0 0;
  padding: 0.3125rem 0;
  min-width: 10rem;
  list-style: none;
  font-size: 0.875rem;
  text-align: left;
  background-color: #fff;
  border: 0.06225rem solid rgba(0, 0, 0, 0.15);
  border-radius: 0.1875rem;
  box-shadow: 0.125rem 0.125rem 0.3125rem rgba(0, 0, 0, 0.2);
  ${ifProp(
    'right',
    css`
      left: -100%;
    `,
  )};
`;

MenuContainer.propTypes = propTypes;
MenuContainer.defaultProps = defaultProps;

export default MenuContainer;
