import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { prop } from 'ramda';

function useAsyncAction(action, dependeces = [], { onSuccess = () => {}, onError = () => {} } = {}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const asyncAction = useCallback(
    (...args) => {
      async function callback() {
        setLoading(true);
        try {
          const res = await dispatch(action(...args));
          const response = prop('response', res);
          if (response) {
            setIsError(true);
            onError(res);
          } else {
            setIsError(false);
            onSuccess(res);
          }
          setLoading(false);
          return res;
        } catch (e) {
          setLoading(false);
          setIsError(true);
          onError(e);
          return e;
        }
      }
      return callback();
    },
    [action, ...dependeces],
  );

  return [asyncAction, loading, isError];
}

export default useAsyncAction;
