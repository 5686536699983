import { createSelector } from 'reselect';

export const initialState = [];

export const getEntities = (state = initialState) => state.entities;

export const getEntitiesCollection = createSelector(
  getEntities,
  (entities) => entities.map((entity) => entity.legalName && { value: entity.id, label: entity.legalName }),
);

export const getEntitiesCollectionWithEmptyItem = createSelector(
  getEntitiesCollection,
  (entities) => entities && [{ value: null, label: 'New Entity' }, ...entities],
);
