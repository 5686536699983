import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Card from './Card';

const propTypes = {
  application: PropTypes.objectOf(PropTypes.any).isRequired,
};

function LoanOfficer(props) {
  const { application } = props;

  if (!application.loanOfficer) { return null; }

  return (
    <StyledLoanOfficer {...props}>
      <Title>I would like to introduce you to your Loan Officer</Title>
      <Card loanOfficer={application.loanOfficer} />
    </StyledLoanOfficer>
  );
}

const StyledLoanOfficer = styled.div`
  text-align: center;
`;

const Title = styled.div`
  font-size: 1.25rem;
`;

LoanOfficer.propTypes = propTypes;

export default LoanOfficer;
