import * as R from 'ramda';
import Payment from 'payment';
import { validate } from 'validate.js';

import { dateIsValid } from 'utils/format';
import { getIn } from 'utils/ramda';

export const validateRequired = ({ message = 'must be filled' } = {}) => (value) =>
  (R.isNil(value) ? message : undefined);

export const validateEmpty = ({ message = 'must be filled' } = {}) => (value) =>
  (R.isEmpty(value) ? message : undefined);

export const validateRequiredNotStrict = ({ message = 'must be filled' } = {}) => (value) =>
  (value === undefined ? message : undefined);

export function validateAccepted({ message = 'must be accepted' } = {}) {
  return (value) => (value === true || value === 'true' ? undefined : message);
}

export const validateEquality = ({ message, base = true } = {}) => (value) => {
  const mess = message || `must be equal ${base.toString()}`;
  return value === base ? undefined : mess;
};

export function validateEmail({ message = 'is invalid' } = {}) {
  return (email) => validate.single(email, { email: { message } });
}

export const validatePhone = ({ message = 'is invalid' } = {}) => (phone) => {
  const onlyNums = phone ? phone.replace(/[^\d]/g, '') : undefined;

  return onlyNums && onlyNums.length !== 10 && message;
};

export const validateDate = ({ message = 'is invalid' } = {}) => (date) => {
  if (dateIsValid(date)) return undefined;
  return message;
};

export const validateCardNumber = ({ message = 'Card number is invalid' } = {}) => (cardNumber) => {
  if (Payment.fns.validateCardNumber(cardNumber)) return undefined;
  return message;
};

export const validateCardCVV = ({ message = 'Card CVV is invalid', cardNumberPath } = {}) => (cardCVV, allValues) => {
  let issuer = null;
  if (cardNumberPath) {
    const cardNumber = getIn(cardNumberPath, allValues);
    issuer = Payment.fns.cardType(cardNumber);
  }
  if (Payment.fns.validateCardCVC(cardCVV, issuer)) return undefined;
  return message;
};

export const validateCardExpiryMonth = ({ message = 'Card expiry is invalid', yearPath } = {}) => (
  month,
  allValues,
) => {
  let year = null;
  if (yearPath) {
    year = getIn(yearPath, allValues);
  }
  if (Payment.fns.validateCardExpiry(month, year)) return undefined;
  return message;
};

export const validateCardExpiryYear = ({ message = 'Card expiry is invalid', monthPath } = {}) => (year, allValues) => {
  let month = null;
  if (monthPath) {
    month = getIn(monthPath, allValues);
  }
  if (Payment.fns.validateCardExpiry(month, year)) return undefined;
  return message;
};

export const composeValidators = (...validators) => (...args) =>
  validators.reduce((error, validator) => error || validator(...args), undefined);

export const validateRequiredByConditon = ({ message = 'must be filled', isRequired } = {}) => (value) =>
  (isRequired && R.isNil(value) ? message : undefined);
