import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, getContext, pure } from 'recompose';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { getBridgeFullFormSecondStepRefs } from 'selectors';

import { FormFlex, FormBox, FormGroupAdapter } from 'components/shared/form';
import Condition from 'components/shared/form/ConditionNew';

import { validateRequired } from 'utils';

import ArrayFields from './ArrayFields';
import TransactionPurpose from './TransactionPurpose';

const propTypes = {
  isFormDisabled: PropTypes.bool.isRequired,
  fetchInitialValues: PropTypes.func.isRequired,
  refs: PropTypes.shape({
    loanTransactionPurposesCollection: PropTypes.arrayOf(PropTypes.object),
    questionRefinanceCollection: PropTypes.arrayOf(PropTypes.object),
  }),
};

const defaultProps = {
  refs: {
    loanTransactionPurposesCollection: [],
    questionRefinanceCollection: [],
  },
};

function Fields({ isFormDisabled, fetchInitialValues, refs }) {
  return (
    <Fragment>
      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.transactionPurpose"
            label="Purchase or Refinance?"
            required
            isDisabled={isFormDisabled}
            options={refs.loanTransactionPurposesCollection}
            validate={validateRequired()}
            fetchInitialValues={fetchInitialValues}
            component={FormGroupAdapter}
            inputComponent={TransactionPurpose}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.requiredClosingDate"
            label="Required Closing Date"
            type="date"
            required
            disabled={isFormDisabled}
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="application.description"
            label="Project Description"
            type="textarea"
            disabled={isFormDisabled}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <Condition
        when="application.transactionPurpose"
        is="refinance"
        names={['application.refinanceProceedsPurpose']}
      >
        <FormFlex>
          <FormBox width={1}>
            <Field
              name="application.refinanceProceedsPurpose"
              label="What will you be using the refinance proceeds for?"
              type="select"
              isDisabled={isFormDisabled}
              options={refs.questionRefinanceCollection}
              required
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </FormFlex>

        <Condition
          when="application.refinanceProceedsPurpose"
          is="other"
          names={['application.refinanceProceedsPurposeComment']}
        >
          <FormFlex>
            <FormBox width={1}>
              <Field
                name="application.refinanceProceedsPurposeComment"
                label="Please Explain:"
                type="textarea"
                required
                validate={validateRequired()}
                disabled={isFormDisabled}
                component={FormGroupAdapter}
              />
            </FormBox>
          </FormFlex>
        </Condition>
      </Condition>

      <FieldArray
        subscription={{ values: false, initial: true }}
        name="application.properties"
        component={ArrayFields}
      />
    </Fragment>
  );
}

Fields.propTypes = propTypes;
Fields.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    refs: getBridgeFullFormSecondStepRefs(state),
  };
}

const enhance = compose(
  connect(mapStateToProps),
  getContext({ isFormDisabled: PropTypes.bool }),
  pure,
);

export default enhance(Fields);
