import React, { Fragment } from 'react';

import { ContentLoader } from 'components/shared';
import { FormBox, FormFlex, FormLegendLoader, CheckboxLoader } from 'components/shared/form';

function Loader() {
  return (
    <Fragment>
      <FormFlex flexDirection="column">
        <FormBox width={1}>
          <FormLegendLoader mb="0.5rem" maxWidth="13.625rem" />
        </FormBox>

        <FormBox width={1}>
          <ContentLoader mb="1rem" height="1.25rem" maxWidth="33.3125rem" />
        </FormBox>

        <FormBox width={1}>
          <ContentLoader mb="0.3rem" height="9.375rem" />
        </FormBox>

        <FormBox width={1}>
          <CheckboxLoader marginBottom="1.25rem" labelWidth="3.3125rem" />
        </FormBox>

        <FormBox width={1}>
          <FormLegendLoader mb="1rem" maxWidth="11.25rem" />
        </FormBox>

        <FormBox width={1}>
          <ContentLoader mb="0.3rem" height="6.25rem" />
        </FormBox>

        <FormBox width={1}>
          <CheckboxLoader marginBottom="1.25rem" labelWidth="2.5rem" />
        </FormBox>

        <FormBox width={1}>
          <FormLegendLoader mb="1rem" maxWidth="26rem" />
        </FormBox>

        <FormBox width={1}>
          <ContentLoader mb="0.3rem" height="14.125rem" />
        </FormBox>

        <FormBox width={1}>
          <CheckboxLoader marginBottom="1.25rem" labelWidth="3.3125rem" />
        </FormBox>
      </FormFlex>
    </Fragment>
  );
}

export default Loader;
