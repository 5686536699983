import styled from 'styled-components';

import Wrapper from 'components/shared/Wrapper';

const FormWrapper = styled(Wrapper)`
  margin-top: 1.25rem;
  margin-bottom: 2.5rem;
`;

export default FormWrapper;
