import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';
import { withRouter } from 'react-router-dom';
import ContentLoader from 'react-content-loader';

import { applicationPropTypes, applicationDefaultProps } from 'propTypes';

import { getApplication, requestInProcess } from 'selectors';

import * as requestTypes from 'constants/requestTypes';
import { FormWrapper } from 'components/shared/form';
import { SecurityStandards } from 'components/shared';

import LeadForm from '../LeadForm';

const propTypes = {
  ...applicationPropTypes,
  loading: PropTypes.bool,
};

const defaultProps = {
  ...applicationDefaultProps,
  loading: false,
};

function Application({ application, loading }) {
  return (
    <Fragment>
      <FormWrapper>{loading || !application ? <ContentLoader /> : <LeadForm application={application} />}</FormWrapper>
      <SecurityStandards />
    </Fragment>
  );
}

Application.propTypes = propTypes;
Application.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  const loading = requestInProcess(state, requestTypes.APPLICATION);
  const application = getApplication(state);

  return {
    application,
    loading,
  };
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
  pure,
);

export default enhance(Application);
