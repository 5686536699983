import * as actionTypes from 'constants/actionTypes';

import { initialState } from 'selectors/searchParams';

import { resetApplicationParams } from 'utils/searchParams';

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.APPLICATION_SEARCH_PARAMS_RESET:
      return resetApplicationParams(state);
    case actionTypes.SEARCH_PARAMS_SET:
      return { ...state, ...payload };
    default:
      return state;
  }
};
