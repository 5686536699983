import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose, pure } from 'recompose';
import { withRouter } from 'react-router-dom';
import { copyTextToClipboard } from 'utils/others';
import { palette } from 'styled-tools';
import ContentLoader from 'react-content-loader';
import { getIn } from 'utils';

import * as actions from 'actions';

import { WithUser } from 'hocs';
import { Icon, Strong, Wrapper, Button } from 'components/shared';
import { UserTemplate } from 'components/templates';
import { UserHeader } from 'components/blocks/User';

const propTypes = {
  contact: PropTypes.shape({
    referralUrl: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
};

function ReferralLinkPage({ loading, contact }) {
  const referralUrl = getIn('referralUrl', contact);
  if (loading) {
    return <ContentLoader />;
  }
  return (
    <UserTemplate header={<UserHeader />}>
      <StyledWrapper>
        <Strong>Referral Link</Strong>
        <Inputgroup>
          <Input readOnly="true" value={referralUrl} />
          <Button type="button" onClick={copyTextToClipboard(`${referralUrl}`)}>
            <StyledIcon palette="grayscale" icon="copy" />
          </Button>
        </Inputgroup>
      </StyledWrapper>
    </UserTemplate>
  );
}

const StyledWrapper = styled(Wrapper)`
  width: 100%;
  max-width: 90rem;
  margin-top: 20px;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  height: 32px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  color: #6d6b69;
  background-color: #e8e8e8;
  background-image: none;
  border: 1px solid #d3dbe2;
  border-radius: 2px;
  transition: border-color cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
  outline: 0;
  cursor: not-allowed;
`;

const Inputgroup = styled.div`
  display: flex;
`;

const StyledIcon = styled(Icon)`
  display: block;
  cursor: pointer;
  font-size: 1rem;
  color: #fff;
  transition color 150ms ease;

  &:hover {
    color: ${palette(3)};
  }

  .react-select__control--is-focused & {
    color: ${palette(1)};
  }
`;

function mapStateToProps(state, props) {
  const { userIsLoading } = props;

  return {
    loading: userIsLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUser: bindActionCreators(actions.fetchUser, dispatch),
  };
}

ReferralLinkPage.propTypes = propTypes;

const enhance = compose(WithUser, withRouter, connect(mapDispatchToProps, mapStateToProps), pure);

export default enhance(ReferralLinkPage);
