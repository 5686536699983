import PropTypes from 'prop-types';
import { prop, identity } from 'ramda';

const propTypes = {
  index: PropTypes.number.isRequired,
  property: PropTypes.isRequired,
  propertyKey: PropTypes.string,
  format: PropTypes.func,
  render: PropTypes.func,
};

const defaultProps = {
  propertyKey: undefined,
  format: identity,
  render: undefined,
};

function Render({ index, property, propertyKey, format, render }) {
  if (render) return render({ property, format, propertyKey, index });
  const value = prop(propertyKey, property);
  return format(value) || 'N/A';
}

Render.propTypes = propTypes;
Render.defaultProps = defaultProps;

export default Render;
