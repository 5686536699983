import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import {
  APPLICATION_STEP_PATH,
  LEAD_PATH,
} from 'constants/routes';
import { getPath } from 'utils';

const propTypes = {
  application: PropTypes.shape({
    leadStep: PropTypes.string,
    dataEntryStep: PropTypes.number,
  }),
  token: PropTypes.string.isRequired,
};

const defaultProps = {
  application: undefined,
};

function ApplicationCurrentStepRedirect({ application, token }) {
  const { leadStep, dataEntryStep } = application;

  if (leadStep === 'complete') {
    return <Redirect to={getPath(APPLICATION_STEP_PATH, { token, step: dataEntryStep })} />;
  }
  return <Redirect to={getPath(LEAD_PATH, { token })} />;
}

ApplicationCurrentStepRedirect.propTypes = propTypes;
ApplicationCurrentStepRedirect.defaultProps = defaultProps;

export default ApplicationCurrentStepRedirect;
