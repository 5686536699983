import styled from 'styled-components';

const Title = styled.div`
  font-size: 2rem;
  font-weight: 300;
  text-align: center;
  color: #000000;
  letter-spacing: 0.5px;
`;

export default Title;
