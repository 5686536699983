import { hasPath, pipe, prop, tap, ifElse } from 'ramda';

import * as actionTypes from '../constants/actionTypes';

import { getReferralTokenCookie, setReferralTokenCookie } from '../utils';
import { getSearchParams } from '../utils/searchParams';

export function setSearchParams() {
  return (dispatch) => {
    const searchParams = getSearchParams(window) || {};

    const referralTokenFlow = ifElse(
      hasPath(['referralToken']),
      pipe(
        prop('referralToken'),
        tap(setReferralTokenCookie),
      ),
      getReferralTokenCookie,
    );

    dispatch(
      setSearchParamsSuccess({
        ...searchParams,
        referralToken: referralTokenFlow(searchParams),
      }),
    );
  };
}

export function resetApplicationSearchParams() {
  return {
    type: actionTypes.APPLICATION_SEARCH_PARAMS_RESET,
  };
}

function setSearchParamsSuccess(payload) {
  return {
    type: actionTypes.SEARCH_PARAMS_SET,
    payload,
  };
}
