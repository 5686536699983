import createDecorator from 'final-form-calculate';

const primaryDecorator = createDecorator({
  field: /application\.borrowers\[\d\]\.primary/,
  updates: (value, fieldName, { application }) => {
    if (!value) return {};
    const { borrowers } = application;
    const others = borrowers
      .map((_, index) => `application.borrowers[${index}].primary`)
      .filter((name) => name !== fieldName)
      .reduce((acc, name) => ({ ...acc, [name]: false }), {});
    return others;
  },
});

export default primaryDecorator;
