import React from 'react';
import PropTypes from 'prop-types';

import { FormFlex } from 'components/shared/form';

const propTypes = {
  name: PropTypes.string.isRequired,
};

function PurchaseFields({ name }) {
  return (
    <FormFlex>
    </FormFlex>
  );
}

PurchaseFields.propTypes = propTypes;

export default PurchaseFields;
