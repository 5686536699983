import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DEFAULT_FILES_WHITE_LIST } from 'constants/forms';

import Template from './Template';
import Dropzone from '../Dropzone';

const propTypes = {
  className: PropTypes.string,
  filesWhitelist: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

const defaultProps = {
  className: undefined,
  filesWhitelist: DEFAULT_FILES_WHITE_LIST,
};

function Files({ className, filesWhitelist, name, onChange, value }) {
  return (
    <Container className={className}>
      <Dropzone
        accept={filesWhitelist}
        multiple={false}
        onDrop={(files) => onChange([...value, ...files])}
        disableClick
        style={{}}
        template={({ open }) => <Template name={name} open={open} onChange={onChange} value={value} />}
      />
    </Container>
  );
}

const Container = styled.div``;

Files.propTypes = propTypes;
Files.defaultProps = defaultProps;

export default Files;
