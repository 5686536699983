import React, { Fragment } from 'react';
import { compose, lifecycle, pure, withProps, withHandlers } from 'recompose';
import { Field } from 'react-final-form';

import { FormFlex, FormBox, FormGroupAdapter, FormLegend } from 'components/shared/form';

import { WithFinalForm, WithFinalFormValue } from 'hocs';

import * as handlers from './handlers';

function Documents() {
  return (
    <Fragment>
      <FormFlex>
        <FormBox mb="1rem" width={1}>
          <FormLegend>Supporting Documents / Pictures</FormLegend>
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field name="drawRequest.documents" type="files" component={FormGroupAdapter} />
        </FormBox>
      </FormFlex>
    </Fragment>
  );
}

const enhance = compose(
  WithFinalForm,
  withProps({ name: 'drawRequest.documentsCategoryId' }),
  WithFinalFormValue('name'),
  withHandlers({
    setDocumentsCategoryId: handlers.setDocumentsCategoryId,
  }),
  lifecycle({
    componentDidMount() {
      const { setDocumentsCategoryId } = this.props;
      setDocumentsCategoryId();
    },
    componentDidUpdate() {
      const { setDocumentsCategoryId } = this.props;
      setDocumentsCategoryId();
    },
  }),
  pure,
);

export default enhance(Documents);
