import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { IconButton } from 'components/shared';
import { FormBox, FormFlex } from 'components/shared/form';

import Fields from './Fields';

const propTypes = {
  fields: PropTypes.objectOf(PropTypes.any).isRequired,
};

function ArrayFields({ fields }) {
  return (
    <div>
      {fields.map((name, index) => (
        <Fields index={index} fields={fields} key={name} name={name} />
      ))}
      <Add>
        <FormBox>
          <IconButton icon="plus" rounded sm onClick={() => fields.push({})}>
            Add another wiring instruction
          </IconButton>
        </FormBox>
      </Add>
    </div>
  );
}

const Add = styled(FormFlex)`
  margin-bottom: 1.25rem;
`;

ArrayFields.propTypes = propTypes;

export default ArrayFields;
