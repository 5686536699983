import React from 'react';

import { PageTemplate } from 'components/templates';
import { Bridge } from 'components/blocks';

import { ScrollToTopOnMount } from 'hocs';

function BridgePage() {
  return (
    <PageTemplate>
      <Bridge />
    </PageTemplate>
  );
}

export default ScrollToTopOnMount(BridgePage);
