import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';

import { getExpensesCollectionRefs } from 'selectors';

import { Asterisk, Hr } from 'components/shared';
import { Condition, FormFlex, FormBox, FormGroupAdapter, FormLegend } from 'components/shared/form';
import { Label, Textarea } from 'components/shared/formControls';

import { CREDIT_CARD_AUTHORIZATION_TERM } from 'constants/forms';
import {
  composeValidators,
  monthCollection,
  parseCardNumber,
  upperLettersOnlyParse,
  validateAccepted,
  validateCardCVV,
  validateCardExpiryMonth,
  validateCardExpiryYear,
  validateCardNumber,
  validateEmail,
  validatePhone,
  validateRequired,
  yearsRangeCollection,
} from 'utils';

const propTypes = {
  refs: PropTypes.shape({
    countriesCollection: PropTypes.arrayOf(PropTypes.object),
    statesCollection: PropTypes.arrayOf(PropTypes.object),
  }),
};

const defaultProps = {
  refs: {
    statesCollection: [],
    countriesCollection: [],
  },
};

const yearsCollection = yearsRangeCollection(10);

function Expenses({ refs }) {
  return (
    <Fragment>
      <FormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="extension.expense.firstName"
            label="First Name"
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="extension.expense.lastName"
            label="Last Name"
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="extension.expense.email"
            label="Email"
            required
            validate={composeValidators(validateRequired(), validateEmail())}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="extension.expense.phone"
            label="Phone"
            required
            validate={composeValidators(validateRequired(), validatePhone())}
            typeOfMask="phone"
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <StyledLegend>Billing Address</StyledLegend>
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="extension.expense.billingStreetAddress"
            label="Street Address"
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="extension.expense.billingCountryCode"
            label="Country"
            required
            type="select"
            options={refs.countriesCollection}
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name="extension.expense.billingCity"
            label="City"
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>

        <Condition reverse when="extension.expense.billingCountryCode" is="US">
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              isConditional
              name="extension.expense.billingProvince"
              label="State/Province"
              component={FormGroupAdapter}
            />
          </FormBox>

          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              name="extension.expense.billingPostalCode"
              label="Postal Code"
              required
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </Condition>

        <Condition when="extension.expense.billingCountryCode" is="US">
          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              isConditional
              name="extension.expense.billingStateCode"
              label="State"
              type="select"
              required
              validate={validateRequired()}
              options={refs.statesCollection}
              component={FormGroupAdapter}
            />
          </FormBox>

          <FormBox width={[1, 1, 1 / 3]}>
            <Field
              name="extension.expense.billingPostalCode"
              label="ZIP Code"
              required
              validate={validateRequired()}
              component={FormGroupAdapter}
            />
          </FormBox>
        </Condition>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <StyledLegend>Credit Card</StyledLegend>
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="extension.expense.creditCard.number"
            label="Card Number"
            placeholder="•••• •••• •••• ••••"
            parse={parseCardNumber}
            required
            validate={composeValidators(validateCardNumber(), validateRequired())}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox>
          <Field
            name="extension.expense.creditCard.verificationValue"
            label="CVV"
            typeOfMask="cvv"
            placeholder="•••"
            required
            validate={composeValidators(
              validateCardCVV({ cardNumberPath: 'extension.expense.creditCard.number' }),
              validateRequired(),
            )}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name="extension.expense.creditCard.firstName"
            label="First Name"
            required
            parse={upperLettersOnlyParse}
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 3]}>
          <Field
            name="extension.expense.creditCard.lastName"
            label="Last Name"
            parse={upperLettersOnlyParse}
            required
            validate={validateRequired()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <StyledLabel htmlFor="extension.expense.creditCard.month">
            <StyledAsterisk />
            Expiration Date
          </StyledLabel>
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="extension.expense.creditCard.month"
            required
            validate={composeValidators(
              validateCardExpiryMonth({ yearPath: 'extension.expense.creditCard.year' }),
              validateRequired(),
            )}
            type="select"
            options={monthCollection}
            component={FormGroupAdapter}
          />
        </FormBox>
        <FormBox width={[1, 1, 1 / 2]}>
          <Field
            name="extension.expense.creditCard.year"
            required
            validate={composeValidators(
              validateCardExpiryYear({ monthPath: 'extension.expense.creditCard.month' }),
              validateRequired(),
            )}
            type="select"
            options={yearsCollection}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Hr />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <StyledLegend>Credit Card Authorization Terms</StyledLegend>
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <StyledTextarea rows={8} disabled value={CREDIT_CARD_AUTHORIZATION_TERM} />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox width={1}>
          <Field
            name="extension.expense.agree"
            type="checkbox"
            label="Agree"
            inlineGroup
            labelOrder={1}
            errorOrder={2}
            required
            validate={validateAccepted()}
            component={FormGroupAdapter}
          />
        </FormBox>
      </FormFlex>

      <FormFlex>
        <FormBox>
          <Field
            name="extension.expense.totalAmount"
            render={({ input }) => (
              <Total>
                <Strong>TOTAL</Strong> <span>${input.value}</span>
              </Total>
            )}
          />
        </FormBox>
      </FormFlex>
    </Fragment>
  );
}

const StyledAsterisk = styled(Asterisk)`
  position: absolute;
  top: 0;
  left: -10px;
  font-size: 65%;
`;

const StyledLegend = styled(FormLegend)`
  margin-bottom: 1rem;
`;

const Strong = styled.strong`
  font-weight: bold;
`;

const Total = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-size: 1.125rem;
  text-align: left;

  strong {
    font-weight: bold;
  }
`;

const StyledTextarea = styled(Textarea)`
  margin-bottom: 0.5rem;
`;

const StyledLabel = styled(Label)`
  position: relative;
  display: block;
  box-sizing: border-box;
  margin: 0 0 3px 3px;
  font-size: 0.9375rem;
  color: #6d6b69;
`;

Expenses.propTypes = propTypes;
Expenses.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    refs: getExpensesCollectionRefs(state),
  };
}

const enhance = compose(
  connect(mapStateToProps),
  pure,
);

export default enhance(Expenses);
