import * as R from 'ramda';

import { getIn, setIn } from 'utils';

import { utils } from '../';

function setDisabledByInitValuesHandler({ setDisabled }) {
  const setter = R.pipe(
    R.of,
    R.ap([getIn('meta.isDisabled'), getIn('application.borrower.isSigned')]),
    R.any(R.identity),
    setDisabled,
  );

  return R.tap(setter);
}

function setCheckHandler() {
  return (path) => R.ifElse(getIn('application.borrower.isSigned'), setIn(true, path), R.identity);
}

function mergeInitialValuesHandler({ setInitialValues, setCheck, setDisabledByInitValues }) {
  return R.pipe(
    setCheck('application.borrower.waiver'),
    setCheck('application.borrower.disclaimer'),
    setCheck('application.borrower.investmentPurpose'),
    setCheck('application.borrower.creditAndBackgroundRealease'),
    setCheck('application.borrower.accurateInformation'),
    setDisabledByInitValues,
    utils.setDefaultInitialValues,
    setInitialValues,
  );
}

export {
  mergeInitialValuesHandler as mergeInitialValues,
  setDisabledByInitValuesHandler as setDisabledByInitValues,
  setCheckHandler as setCheck,
};
