import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, lifecycle, pure } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import ContentLoader from 'react-content-loader';

import { getContacts, requestInProcess } from 'selectors';

import * as actions from 'actions';

import Contact from './Contact';

const propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  loading: PropTypes.bool,
};

const defaultProps = {
  contacts: [],
  loading: false,
};

function Contacts({ loading, contacts }) {
  if (loading) {
    return <ContentLoader />;
  }

  if (contacts.length === 0) {
    return (
      <div>
        <span>No contacts found</span>
      </div>
    );
  }

  return (
    <Table>
      <thead>
        <tr>
          <Th>Type / Name</Th>
          <Th>Email / Phone</Th>
          <Th />
        </tr>
      </thead>
      <tbody>
        {contacts.map(({ id }) => (
          <Contact key={`contact-${id}`} id={id} />
        ))}
      </tbody>
    </Table>
  );
}

const Table = styled.table`
  width: 100%;
  max-width: 100%;
  margin-bottom: 1.25rem;
  font-size: 0.875rem;
`;

const Th = styled.th`
  padding: 0.3125rem;
  vertical-align: bottom;
  font-weight: bold;
  border-bottom: 2px solid #ddd;
`;

Contacts.propTypes = propTypes;
Contacts.defaultProps = defaultProps;

function mapStateToProps(state, props) {
  const { token } = props.match.params;
  const contactsIsLoading = requestInProcess(state, 'CONTACTS');
  return {
    loading: contactsIsLoading,
    contacts: getContacts(state, token),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchContacts: bindActionCreators(actions.fetchContacts, dispatch),
    fetchContactTypes: bindActionCreators(actions.fetchContactTypes, dispatch),
  };
}

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  lifecycle({
    componentDidMount() {
      const { fetchContacts, fetchContactTypes, match } = this.props;
      const { token } = match.params;
      fetchContacts(token);
      fetchContactTypes(token);
    },
  }),
  pure,
);

export default enhance(Contacts);
