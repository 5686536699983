import * as R from 'ramda';

import { getIn } from 'utils';

import { toStringInitialValue } from 'decorators';

import { utils } from '../';

function isFormDisabledSetterHandler({ setIsFormDisabled }) {
  const setter = R.pipe(
    getIn('meta.isDisabled'),
    R.unless(R.isNil, setIsFormDisabled),
  );

  return R.tap(setter);
}

function mergeInitialValuesHandler({ setInitialValues, isFormDisabledSetter }) {
  return R.pipe(
    isFormDisabledSetter,
    toStringInitialValue('application.borrower.permanentResident'),
    toStringInitialValue('application.borrower.usCitizen'),
    utils.setDefaultInitialValues,
    setInitialValues,
  );
}

export { mergeInitialValuesHandler as mergeInitialValues, isFormDisabledSetterHandler as isFormDisabledSetter };
