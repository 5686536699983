import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from 'components/shared/Icon';
import Input from 'components/shared/formControls/Input';

class TextInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    rate: PropTypes.number,
  };

  static defaultProps = {
    className: 'ant-input',
    rate: 26,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value || '',
      initialValue: this.props.value || '',
      editable: this.props.editable ? this.props.editable : false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value && !this.props.editable) {
      this.setState({
        ...this.state,
        value: nextProps.value,
        initialValue: nextProps.value,
        editable: false,
      });
    }
  }
  handleChange = (e) => {
    const { value } = e.target;
    this.setState({ value });
  };

  handleBlur = (e) => {
    const { value } = e.target;
    const { onChange } = this.props;
    const { initialValue } = this.state;
    this.setState({ editable: false });
    if (onChange && value !== initialValue) {
      onChange(value);
    }
  };
  check = () => {
    this.setState({ editable: false });
    if (this.props.onChange) {
      this.props.onChange(this.state.value);
    }
  };
  edit = () => {
    this.setState({ editable: true });
  };
  render() {
    const { value, editable } = this.state;
    const { className, readOnly, type, rate, writeOnly } = this.props;
    return (
      <div className={`reschedule_table__cell ${className}`}>
        {writeOnly && (
          <div className="reschedule_input__wrappper">
            <Input
              value={value}
              onChange={this.handleChange}
              rate={rate}
              type={type}
              autoFocus
              onPressEnter={this.check}
              onBlur={this.handleBlur}
            />
          </div>
        )}
        {!writeOnly &&
          !readOnly && (
            <div className="editable-cell">
              {editable ? (
                <div className="editable-cell-input-wrapper">
                  <Input
                    value={value}
                    onChange={this.handleChange}
                    rate={26}
                    type={type}
                    autoFocus
                    onPressEnter={this.check}
                    onBlur={this.handleBlur}
                  />
                  <Icon icon="angle" className="editable-cell-icon-check" onClick={this.check} />
                </div>
              ) : (
                <div>
                  {value ? (
                    <div>
                      {value}
                      <span className="editable-cell-indicator">Editable</span>
                    </div>
                  ) : (
                    <span className="click_to_edit">click to edit</span>
                  )}
                  <Anchor className="editable-cell-link" onClick={this.edit} />
                </div>
              )}
            </div>
          )}
        {!writeOnly && readOnly && <div>{value || <span className="click_to_edit">N/A</span>}</div>}
      </div>
    );
  }
}

const Anchor = styled.a``;

export default TextInput;
