import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ContentLoader from 'react-content-loader';

import { getApplicationsCollectionLength } from 'selectors';

import { WithUser } from 'hocs';
import { FOOTER_LINKS, HEADER_LINKS, MY_APPLICATIONS_PATH, SIGN_IN_PATH } from 'constants/routes';
import { userDefaultProps, userPropTypes } from 'propTypes';
import { getPath } from 'utils';

import { NavigationItem, NavigationLink, NavigationDropdown } from './shared';

const propTypes = {
  ...userPropTypes,
  applicationsCollectionLength: PropTypes.number.isRequired,
  userIsLoading: PropTypes.bool,
  footer: PropTypes.bool,
  location: PropTypes.objectOf(PropTypes.any),
};

const defaultProps = {
  ...userDefaultProps,
  footer: false,
  userIsLoading: false,
  allApplications: { collection: [] },
};

function Navigation({ applicationsCollectionLength, footer, user, userIsLoading, location }) {
  const links = footer ? FOOTER_LINKS : HEADER_LINKS;

  if (userIsLoading) {
    return (
      <ContentLoader height={10} speed={3} width={150} style={{ width: '150px' }}>
        <rect x="0" y="0" rx="5" ry="5" width="150" height="10" />
      </ContentLoader>
    );
  }

  const linksComponents = links.map(({ text, ...link }) => (
    <NavigationItem key={`nav-item-${link.url}`}>
      <NavigationLink {...link} key={`nav-link-${link.url}`}>
        {text}
      </NavigationLink>
    </NavigationItem>
  ));

  if (footer) {
    return <NavigationContainer>{linksComponents}</NavigationContainer>;
  }

  return (
    <NavigationContainer>
      {!user && linksComponents}
      {user && (
        <NavigationItem>
          <NavigationLink url={getPath(MY_APPLICATIONS_PATH, { id: user.id, status: 'active' })}>
            My Applications
          </NavigationLink>
        </NavigationItem>
      )}
      <NavigationItem>
        {user ? (
          <NavigationDropdown />
        ) : (
          <NavigationLink url={{ pathname: SIGN_IN_PATH, state: { from: location } }}>Sign In</NavigationLink>
        )}
      </NavigationItem>
    </NavigationContainer>
  );
}

const NavigationContainer = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
`;

Navigation.propTypes = propTypes;
Navigation.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    applicationsCollectionLength: getApplicationsCollectionLength(state),
  };
}

const enhance = compose(
  withRouter,
  WithUser,
  connect(mapStateToProps),
  pure,
);

export default enhance(Navigation);
