import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { palette } from 'styled-tools';
import { identity } from 'ramda';

import Render from './Render';

const propTypes = {
  format: PropTypes.func,
  index: PropTypes.number.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  property: PropTypes.shape({}).isRequired,
  propertyKey: PropTypes.string,
  render: PropTypes.func,
  title: PropTypes.node.isRequired,
};

const defaultProps = {
  format: identity,
  propertyKey: undefined,
  render: undefined,
};

function Detail({ isInvalid, index, render, format, title, propertyKey, property }) {
  return (
    <Container>
      <Title isInvalid={isInvalid}>{title}</Title>
      <Render index={index} render={render} format={format} property={property} propertyKey={propertyKey} />
    </Container>
  );
}

const Container = styled.div`
  flex-shrink: 0;
`;

const Title = styled.div`
  margin-bottom: 0.625em;
  font-size: 0.875em;
  color: #bebebe;

  ${({ isInvalid }) =>
    isInvalid &&
    css`
      color: ${palette('danger', 2)};
    `}
`;

Detail.propTypes = propTypes;
Detail.defaultProps = defaultProps;
Detail.Title = Title;

export default Detail;
