function getCurrentTime() {
  return Date.now() - getLocalOffset();
}

function resetCurrentTime() {
  return localStorage.removeItem('time_offset');
}

// this method calculates the offset between local computer time and server time
function getLocalOffset() {
  const time_zone = `${new Date().getTimezoneOffset() / 60}`;

  if (localStorage.getItem('time_zone') !== time_zone) resetCurrentTime();

  const time_offset_from_storage = parseInt(localStorage.getItem('time_offset'));

  if (!isNaN(time_offset_from_storage)) return time_offset_from_storage;

  const time_offset = Date.now() - getServerTime();

  localStorage.setItem('time_zone', time_zone);
  localStorage.setItem('time_offset', time_offset);

  return time_offset;
}

function getServerTime() {
  try {
    const request = new XMLHttpRequest();
    request.open("GET", "/current_time", false);
    request.send(null);

    const server_time = parseInt(request.responseText);

    return isNaN(server_time) ? Date.now() : server_time * 1000
  } catch {
    return Date.now()
  }
}

export { getCurrentTime, resetCurrentTime };
