import React from 'react';
import styled from 'styled-components';
import { compose, pure } from 'recompose';
import { withRouter } from 'react-router-dom';

import { Button } from 'components/shared';
import Drawer from 'components/shared/Drawer';

import New from './New';

function Menu() {
  return (
    <Container>
      <Drawer content={({ togglePanel }) => <New callback={togglePanel} />}>
        {({ togglePanel }) => (
          <Button rounded sm icon="plus" onClick={togglePanel}>
            Add new contact
          </Button>
        )}
      </Drawer>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 1.25rem;
  padding-bottom: 0.625rem;
  margin-bottom: 0.5rem;
  text-align: right;
  border-bottom: 0.0625rem solid #d5dde9;
`;

const enhance = compose(
  withRouter,
  pure,
);

export default enhance(Menu);
