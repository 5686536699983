import React from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withState, withHandlers } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form as FinalForm } from 'react-final-form';
import createFocusDecorator from 'final-form-focus';
import createDecorator from 'final-form-calculate';
import arrayMutators from 'final-form-arrays';
import * as R from 'ramda';

import * as actions from 'actions';

import { holdMutators } from 'utils';

import Form from './Form';
import { handlers } from './shared';

const propTypes = {
  initialValues: PropTypes.shape({
    drawRequest: PropTypes.objectOf(PropTypes.any),
    token: PropTypes.stirng,
  }).isRequired,
  loading: PropTypes.isRequired,
  updateDrawRequest: PropTypes.func.isRequired,
};

const focusOnError = createFocusDecorator();
const calculateDecorator = createDecorator({
  field: 'drawRequest.isGeneralContractorApplied',
  updates: (value) => {
    if (R.equals('false', value) || R.equals(false, value)) return { 'drawRequest.contractor': undefined };
    return {};
  },
});

function Edit({ initialValues, loading, updateDrawRequest }) {
  return (
    <FinalForm
      onSubmit={updateDrawRequest}
      initialValues={initialValues}
      decorators={[calculateDecorator, focusOnError]}
      mutators={{ ...holdMutators, ...arrayMutators }}
      subscription={{ submitting: true, pristine: true }}
      loading={loading}
      component={Form}
    />
  );
}

Edit.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
  return {
    fetchEditDrawRequest: bindActionCreators(actions.fetchEditDrawRequest, dispatch),
    updateDrawRequest: bindActionCreators(actions.updateDrawRequest, dispatch),
  };
}

const enhance = compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps,
  ),
  withHandlers({
    setDefaultInitialValues: handlers.setDefaultInitialValues,
  }),
  withState('loading', 'setLoading', false),
  withState('initialValues', 'setInitialValues', ({ setDefaultInitialValues }) => setDefaultInitialValues()),
  withHandlers({
    mergeInitialValues: handlers.mergeInitialValues,
  }),
  lifecycle({
    async componentDidMount() {
      const { match, fetchEditDrawRequest, mergeInitialValues, setLoading } = this.props;
      const { token, id } = match.params;
      setLoading(true);
      await fetchEditDrawRequest(token, id).then(mergeInitialValues);
      setLoading(false);
    },
  }),
  pure,
);

export default enhance(Edit);
