import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { pure, compose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import createFocusDecorator from 'final-form-focus';

import * as actions from 'actions';

import { Wrapper } from 'components/shared';

import { throwSubmissionError } from 'utils';

import Form from './Form';

const propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  success: PropTypes.bool,
};

const defaultProps = {
  className: undefined,
  success: false,
};

const focusOnError = createFocusDecorator();

function FormAuthResetPass({ className, onSubmit, setSuccess, success }) {
  return (
    <StyledResetPass className={className}>
      <Wrapper>
        <FinalForm
          onSubmit={onSubmit}
          decorators={[focusOnError]}
          subscription={{ submitting: true, pristine: true, values: true }}
          component={Form}
          success={success}
          setSuccess={setSuccess}
        />
      </Wrapper>
    </StyledResetPass>
  );
}

const StyledResetPass = styled.section`
  padding-top: 20px;
  padding-bottom: 40px;
`;

FormAuthResetPass.propTypes = propTypes;
FormAuthResetPass.defaultProps = defaultProps;

function mapDispatchToProps(dispatch) {
  return {
    resetPassword: bindActionCreators(actions.resetPassword, dispatch),
  };
}

const enhance = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withState('success', 'setSuccess', false),
  withHandlers({
    setSuccessDone: ({ setSuccess }) => () => setSuccess(true),
  }),
  withHandlers({
    onSubmit: ({ resetPassword, setSuccessDone }) => (values) =>
      resetPassword(values)
        .then(setSuccessDone)
        .catch(throwSubmissionError),
  }),
  pure,
);

export default enhance(FormAuthResetPass);
